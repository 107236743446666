
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as dayjs from "dayjs";
import { Link } from "react-router-dom";
import { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import CardBox from "components/CardBox/index";
import { callApi, getAdverseEventList } from "api/apiList";
import Table, { getTextLabels, defaultCol } from "components/Form/Table";
import { setLoader } from "actions/Base";
import { AdverseEventStateList } from "constants/Enums";

const PharmacovigilanceHome = (props) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [data, setData] = useState([]); 

  const adverseEventStateList = AdverseEventStateList();
  const txtOnTime = IntlGetText("adverseEventTime.onTime");
  const txtDelayed = IntlGetText("adverseEventTime.delayed");
  const txtFollowUp = IntlGetText("adverseEvent.followUp");

  useEffect(() => {
    callApi(
      getAdverseEventList,
      setData,
      fnSetLoader
    );
  }, []);
  
  const getObj = (tableMeta) => data.find(x=>x.id === tableMeta.rowData[0]);
  
  var columns =  [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display:"excluded"
      },
    },
    {
      name: "document",
      label: IntlGetText("core.documentOnly"),
      options: {
        filter: true,
        searchable: true,
        sort: true,
        display: 'excluded',
        customSearch: (value, rowData) => {
          const documentValue = rowData[3];
          return documentValue.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        },
      }
    },
    {
      name: "document",
      label: IntlGetText("core.documentOnly"),
      options: {
        filter: true,
        searchable: true,
        sort: true,
        display: false,
      },
    },
    defaultCol("patient", "core.patient"),
    {
      name: "adverseEventType",
      label: IntlGetText("core.type"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          var followUp = !rowObj.followUpId ? "" : " ("+txtFollowUp+")";
          return <div className={" badge text-uppercase text-white bg-"+rowObj.adverseEventTypeColor}>{value+followUp}</div>;
        }
      }
    },
    {
      name: "state",
      label: IntlGetText("core.state"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          const state = adverseEventStateList.find(x=>x.id === rowObj.state);
          return (
            <div className={`badge text-uppercase text-white bg-${state.color}`}>
              {state.name}
            </div>
          );
        }
      }
    },
    {
      name: "isOnTime",
      label: IntlGetText("adverseEvent.isOnTime"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //return value === null ? "null" : (value ? "true" : "false");
          if(value === null) return "";
          return value 
            ? <div className={" badge text-uppercase text-white bg-green"}>{txtOnTime}</div>
            : <div className={" badge text-uppercase text-white bg-red"}>{txtDelayed}</div>;
        }
      }
    },
    {
      name: "creationDate",
      label: IntlGetText("core.startDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD hh:mm a") : "";
        },
      },
    },
    {
      name: "productLine",
      label: IntlGetText("core.productLine"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return <div className={" badge text-uppercase text-white bg-"+rowObj.productLineColor}>{value}</div>;
        }
      }
    },
    defaultCol("nurse", "role.nurse"),
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return (              
            <Button
              component={Link} 
              to={{ pathname: "/app/adverseEvent/detail", adverseEventId: rowObj.id }}
              color="primary">
                <i className="zmdi zmdi-eye"></i>
            </Button>
          );
        }
      }
    }
  ];

  const options = {
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    textLabels: getTextLabels(),
  };

  return (
    <div className="app-wrapper">
      <div className="row">
        <CardBox styleName="col-12" cardStyle="p-0">
        <Table
          title={IntlGetText("psp.menu.adverseEvents")}
          className="border-dialog-card"
          data={data}
          columns={columns}
          options={options} />
        </CardBox>
      </div>
    </div>
  );
};

export default PharmacovigilanceHome;