import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import {
  callApi,
  postSuspendedOrDeceasedFile,
  postSuspendedOrDeceased,
} from "api/apiList";
import { setLoader } from "actions/Base";
import { downloadFile } from "services/fileService";
import Table, { defaultOptions, defaultCol } from "components/Form/Table";

function SuspendedPatients() {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [data, setData] = useState([]);

  const downloadReport = () => {
    callApi(
      () => postSuspendedOrDeceasedFile(),
      (data) => downloadFile(data),
      fnSetLoader
    );
  };
  useEffect(() => {
    callApi(
      () => postSuspendedOrDeceased(),
      (data) => setData(data),
      fnSetLoader
    );
  }, []);

  const getObj = (tableMeta) =>
    data.find((x) => x.code === tableMeta.rowData[0]);
  const columns = [
    {
      name: "code",
      label: IntlGetText("core.code"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return (
            <Link
              to={{
                pathname: "/app/patient/detail",
                prescriptionId: rowObj.prescriptionId,
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    defaultCol("fullName", "PatientSuspended.Header.PatientName"),
    defaultCol(
      "accessProfessional",
      "core.city"
    ),
    defaultCol("doctor", "PatientSuspended.Header.Doctor"),
    defaultCol("eps", "PatientSuspended.Header.Eps"),
    defaultCol("ips", "PatientSuspended.Header.Ips"),
    {
      name: "treatmentStartDate",
      label: IntlGetText("PatientSuspended.Header.TreatmentStartDate"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    defaultCol("dosage", "PatientSuspended.Header.Dosage"),
    {
      name: "findingDate",
      label: IntlGetText("PatientSuspended.Header.FindingDate"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    defaultCol("patientState", "PatientSuspended.Header.PatientType"),
    defaultCol(
      "medicalSuspensionReason",
      "PatientSuspended.Header.SuspensionReason"
    ),
    defaultCol("comment", "PatientSuspended.Header.Comment"),
  ];

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-2 mb-5">
      <div className="text-left mt-5 mb-4">
        <Button onClick={downloadReport} variant="contained" color="secondary">
          <IntlMessages id="core.download" />
        </Button>
      </div>
      <div className="mt-5 mb-5">
        <Table
          title={IntlGetText("psp.menu.suspendedPatients")}
          className="border-dialog-card col-12"
          data={data}
          columns={columns}
          options={defaultOptions()}
        />
      </div>
    </div>
  );
}

export default SuspendedPatients;
