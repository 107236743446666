import {
    SET_LOADER,
  } from 'constants/ActionTypes';
  
  export const setLoader = (loader) => {
    return {
      type: SET_LOADER,
      payload: loader
    };
  };
  