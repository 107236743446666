import React, { useEffect } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import Map from "./map";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const PatientMap = (props) => {
  const mapProps = {
    center:
      props.geoInfo.lat || props.geoInfo.lat
        ? {
            lat: props.geoInfo.lat,
            lng: props.geoInfo.lng,
          }
        : {
            lat: 4.666389,
            lng: -74.111575,
          },
    zoom: props.geoInfo.lat || props.geoInfo.lat ? 15 : 12,
  };

  const render = (status) => {
    return <h1>{status}</h1>;
  };

  return (
    <Wrapper
      apiKey={
        GOOGLE_MAPS_API_KEY
          ? `${GOOGLE_MAPS_API_KEY}`
          : ""
      }
      render={render}
    >
      <Map Mapcenter={mapProps.center} Mapzoom={mapProps.zoom} {...props}></Map>
    </Wrapper>
  );
};

export default PatientMap;
