import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Grid, Button } from "@material-ui/core";
import Widget from "components/Widget";

import FormInput from "../../../../components/Form/Controls/input";
import FormSelectAutoComplete from "../../../../components/Form/Controls/select-autocomplete";
import FormDatePicker from "../../../../components/Form/Controls/datepicker";
import FormCheckbox from "../../../../components/Form/Controls/checkbox";
import FormSwitch from "../../../../components/Form/Controls/switch";
import Auxiliary from "../../../../util/Auxiliary";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";

import { getAllCity } from "../../../../api/apiList";

const validationSchema = yup.object().shape({
  name: yup.string().required("Field is Required"),
  lastName: yup.string().required("Field is Required"),
  cboCity: yup
    .object()
    .required("Field is Required")
    .nullable(),
});

function PatientTest(props) {
  //const [dataForm, setDataForm] = useState({});
  const [cities, setCity] = useState([]);
  // const [cityValue, setCityValue] = useState(null);

  const methods = useForm({    
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, setValue, watch, errors } = methods;

  const cityValue = watch("cboCity");

  const onSubmit = async (data, e) => {
    data.cityId = data.cboCity.value; //parseFloat(data.cityId);
    return;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await getAllCity();
        setCity(data);
      } catch (e) {

      }

    }

    fetchData();
  }, []);

  const handleChange = e => { 
    setValue("cboCity", e);
  }

  return (
    <div className="app-wrapper">
      <Auxiliary>
        <div className="jr-profile-banner">
          <div className="jr-profile-container">
            <div
              className="jr-profile-banner-top"
              style={{ marginBottom: "0px" }}
            >
              <div className="jr-profile-banner-top-left">
                <div className="jr-profile-banner-avatar-info">
                  <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                    Crear Paciente
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="jr-profile-content">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
                <div className="card-header">Nuevo Paciente</div>
                <div className="jr-tabs-classic">
                  <div className="jr-tabs-content jr-task-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                        <div style={{ padding: "10px" }}>
                          <FormProvider {...methods}>
                            <form>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <FormInput
                                    name="name"
                                    label="Nombre"
                                    required={true}
                                    errorobj={errors}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormInput
                                    name="lastName"
                                    label="Apellido"
                                    required={true}
                                    errorobj={errors}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormSelectAutoComplete
                                  label="asd"
                                  options={cities}
                                  required={true}
                                  name="cboCity"
                                  value={cityValue}                           
                                  onChange={handleChange} 
                                  errorobj={errors}                                    
                                />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormInput name="address" label="Dirección" />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormDatePicker
                                    name="birthday"
                                    label="Fecha de nacimiento"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormCheckbox
                                    name="isHospitalized"
                                    label="Hospitalizado"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormSwitch
                                    name="isHospitalized2"
                                    label="Hospitalizado 2"
                                  />
                                </Grid>
                                {/* <Grid item xs={6}>
                                  <FormSelect
                                    name="sel"
                                    label="Numbers"
                                    options={numberData}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormDatePicker
                                    name="txtDate"
                                    label="Mui Date"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormDatePicker
                                    name="txtDateV"
                                    label="Mui Date Validation"
                                    required={true}
                                    errorobj={errors}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormCheckbox
                                    name="chk"
                                    label="Mui Checkbox"
                                  />
                                </Grid> */}
                              </Grid>
                            </form>
                          </FormProvider>
                          <div className="row">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleSubmit(onSubmit)}
                            >
                              SUBMIT
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Widget>
            </div>
          </div>
        </div>
      </Auxiliary>
    </div>
  );
}

export default PatientTest;
