import React from "react";
import * as dayjs from "dayjs";
import { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import Table, { getTextLabels } from "components/Form/Table";
import { QueryAdverseEventStateList } from "constants/Enums";

const CommentTable = ({data, setCurrentRowObj, readOnly}) =>{
  const queryAdverseEventStateList = QueryAdverseEventStateList();

  const getObj = (tableMeta) => data.find(x=>x.id === tableMeta.rowData[0]);

  const handleClick = (tableMeta) => {
    setCurrentRowObj(!tableMeta ? null : getObj(tableMeta));
  };

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: getTextLabels(),
    pagination:false
  };


  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display:"excluded"
      }
    },
    {
      name: "creationDate",
      label: IntlGetText("core.date"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD hh:mm a") : "";
        },
      },
    },
    {
      name: "userName",
      label: IntlGetText("alert.userName"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "queryState",
      label: IntlGetText("queryAdverseEventComment.queryState"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const state = queryAdverseEventStateList.find(x=>x.id == value); 
          return !state ? "" : state.name;
        }
      },
    },
    {
      name: "comment",
      label: IntlGetText("core.comment"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowObj = getObj(tableMeta);
          if (!rowObj.editable || readOnly) {
            return "";
          }

          return (
            <div>
              <Button
                onClick={() => handleClick(tableMeta)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-edit"></i>
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <React.Fragment>
      <Table
        className="border-dialog-card mt-2 mb-2"
        data={data}
        columns={columns}
        options={options} />
    </React.Fragment>   
  );
};

export default CommentTable;