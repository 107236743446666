import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import { Link } from "react-router-dom";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import {
  callApi,
  getControlPanelReport,
  getControlPanelReportFile,
} from "api/apiList";
import { downloadFile } from "services/fileService";
import Table, { defaultOptions, defaultCol, dateColumn } from "components/Form/Table";
import { ProductBehavior } from "constants/Enums";
import DlgNurses from "components/DlgNurses";

const getObj = (tableMeta, data) =>
  data.find((x) => x.code === tableMeta.rowData[0]);

const dateFormat = "YYYY/MM/DD";

const getIclusigCols = (data) => {
  return [
    {
      name: "code",
      label: IntlGetText("core.code"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta, data);
          return (
            <Link
              to={{
                pathname: "/app/patient/detail",
                prescriptionId: rowObj.prescriptionId,
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    defaultCol("patient", "core.patientName"),
    {
      name: "nurse",
      label: IntlGetText("core.nurse"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta, data);
          return <DlgNurses nurse={value} nurses={rowObj.nurses}/>;
        }
      },
    },
    defaultCol("residenceCity", "core.residenceCity"),
    defaultCol("eps", "core.insurance"),
    defaultCol("regime", "core.regime"),
    defaultCol("doctor", "core.attendingDoctor"),
    defaultCol("ips", "core.institution"),
    defaultCol("pathology", "core.pathology"),
    defaultCol("age", "core.age"),
    defaultCol("olf", "insuranceAuthorization.marketChain"),
    dateColumn("treatmentStartDate", "additionalMedications.treatmentStartDate"),
    defaultCol("patientState", "core.status"),
    defaultCol("formulatedDose", "core.formulatedDose"),
    defaultCol("actualDose", "core.actualDose"),
    defaultCol("formulationDate", "core.formulationDate"),
    defaultCol("formulaDuration", "core.formulaDuration"),
    defaultCol("prescriptionState", "core.prescriptionState"),
    dateColumn("lastProcessingDate", "core.lastProcessingDate"),
    dateColumn("olfRadicacionDate", "core.olfRadicacionDate"),
    dateColumn("previousDeliveryDate", "core.previousDeliveryDate"),
    dateColumn("actualDeliveryDate", "core.actualDeliveryDate"),    
    defaultCol("currentDeliveryNumber", "core.currentDeliveryNumber"),
    defaultCol("numberScheduledDeliveries", "core.numberScheduledDeliveries"),
    defaultCol("medicineDeliverd", "core.medicineDeliverd"),
    defaultCol("lastNoteNursing", "morisKySurvey.comment"),
    defaultCol("lastNoteNursingTracing", "core.lastNoteNursingTracing"),
  ];
};

const getGalafoldCols = (data) => {
  return [
    {
      name: "code",
      label: IntlGetText("core.code"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta, data);
          return (
            <Link
              to={{
                pathname: "/app/patient/detail",
                prescriptionId: rowObj.prescriptionId,
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    defaultCol("patient", "core.patientName"),
    defaultCol("stateProvince", "core.stateProvince"),
    defaultCol("residenceCity", "core.residenceCity"),
    defaultCol("nurse", "role.nurse"),
    defaultCol("eps", "core.insurance"),
    defaultCol("regime", "core.regime"),
    defaultCol("olf", "insuranceAuthorization.marketChain"),
    defaultCol("patientState", "core.status"),
    defaultCol("treatmentStartDate", "core.treatmentStartDate"),
    defaultCol("formulationDate", "core.formulationDate"),
    defaultCol("formulaDuration", "core.formulationPeriod"),
    defaultCol("expirationDateFormula", "core.expirationDateFormula"),
    defaultCol("prescriptionState", "core.prescriptionState"),
    {
      name: "lastProcessingDate",
      label: IntlGetText("core.lastProcessingDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    defaultCol("olfRadicacionDate", "core.olfRadicacionDate"),
    defaultCol("doctor", "core.attendingDoctor"),
    {
      name: "previousDeliveryDate",
      label: IntlGetText("controlPanelGl.previousDeliveryDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    {
      name: "actualDeliveryDate",
      label: IntlGetText("controlPanelGl.actualDeliveryDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    defaultCol("deliveriesMade", "core.deliveriesMade"),
    defaultCol("scheduledDeliveries", "core.scheduledDeliveries"),
    defaultCol("blisterStartDate", "controlPanelGl.blisterStartDate"),
    defaultCol("lastNoteNursing", "morisKySurvey.comment"),
    defaultCol("formulaDuration", "core.formulaDuration"),
    defaultCol("lastNoteNursingTracing", "core.lastNoteNursingTracing")
  ];
};

const getRemodulinCols = (data) => {
  return [
    {
      name: "code",
      label: IntlGetText("core.code"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta, data);
          return (
            <Link
              to={{
                pathname: "/app/patient/detail",
                prescriptionId: rowObj.prescriptionId,
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    defaultCol("patient", "core.patientName"),
    defaultCol("documentType", "core.documentType"),
    defaultCol("document", "core.document"),
    defaultCol("gender", "adverseEvent.gender"),
    defaultCol("age", "core.age"),
    defaultCol("city", "core.city"),
    {
      name: "nurse",
      label: IntlGetText("core.nurse"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta, data);
          return <DlgNurses nurse={value} nurses={rowObj.nurses}/>;
        }
      },
    },
    defaultCol("doctor", "core.attendingDoctor"),
    defaultCol("eps", "core.insurance"),
    defaultCol("regime", "core.regime"),
    defaultCol("ips", "core.institution"),
    defaultCol("olf", "insuranceAuthorization.marketChain"),
    dateColumn("treatmentStartDate", "additionalMedications.treatmentStartDate"),
    defaultCol("patientState", "core.status"),
    defaultCol("actualWeight", "core.weight"),
    defaultCol("actualDose", "controlPanelRm.dose"),
    defaultCol("infusionRate", "formula.infusionRate"),
    defaultCol("concentration", "controlPanelRm.concentration"),
    defaultCol("formulationDate", "core.formulationDate"),
    defaultCol("medicineAmount", "core.medicineAmountFormulated"),
    defaultCol(
      "prescriptionDescription",
      "controlPanelRm.prescriptionDescription"
    ),
    defaultCol("prescriptionState", "core.prescriptionState"),
    {
      name: "lastProcessingDate",
      label: IntlGetText("core.lastProcessingDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    defaultCol("olfRadicacionDate", "core.olfRadicacionDate"),
    {
      name: "previousDeliveryDate",
      label: IntlGetText("core.previousDeliveryDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    {
      name: "actualDeliveryDate",
      label: IntlGetText("core.actualDeliveryDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    defaultCol("totalDeliveredMonth", "core.totalDeliveredMonth"),
    defaultCol("currentDeliveryNumber", "core.currentDeliveryNumber"),
    defaultCol("numberScheduledDeliveries", "core.numberScheduledDeliveries"),
    defaultCol("lastNoteNursing", "morisKySurvey.comment"),
    defaultCol("lastNoteNursingTracing", "core.lastNoteNursingTracing"),
  ];
};

const ControlPanelReport = ({ productBehavior }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [reportData, setReportData] = useState([]);

  const downloadReport = () => {
    callApi(
      () => getControlPanelReportFile(productBehavior),
      (data) => downloadFile(data),
      fnSetLoader
    );
  };

  useEffect(() => {
    if (!productBehavior && productBehavior !== 0) return;

    callApi(
      () => getControlPanelReport(productBehavior),
      (data) => setReportData(data),
      fnSetLoader
    );
  }, [productBehavior]);

  let columns = [];
  if (productBehavior === ProductBehavior.remodulin)
    columns = getRemodulinCols(reportData);
  else if (productBehavior === ProductBehavior.iclusig)
    columns = getIclusigCols(reportData);
  else if (productBehavior === ProductBehavior.galafold)
    columns = getGalafoldCols(reportData);

  return (
    <>
      <div className="col-lg-12 col-md-12 col-xs-12 text-left mt-2 mb-4">
        <Button onClick={downloadReport} variant="contained" color="secondary">
          <IntlMessages id="core.download" />
        </Button>
      </div>

      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-5 mb-5">
        <Table
          className="border-dialog-card mt-2 mb-2"
          data={reportData}
          columns={columns}
          options={defaultOptions()}
        />
      </div>
    </>
  );
};

export default ControlPanelReport;
