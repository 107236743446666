import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  callApi,
  postAddKnownAdverseEvent
} from "api/apiList";

const AddKnownAdverseEvent = ({adverseEventId, callBack}) =>{
  const [openCRU, setOpenCRU] = useState(false);
  
  const handleRequestClose = () => setOpenCRU(false);

  const defaultValues = { name: "" };

  const validationSchema = yup.object().shape({
    name: yup.string().required(<IntlMessages id="core.fieldRequired" />)
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset, errors } = methods;

  const onSubmit = async (d, e) => {
    var obj = {
      adverseEventId: adverseEventId,
      name: d.name,
    };

    callApi(
      () => postAddKnownAdverseEvent(obj),
      (data) => {
        callBack(data);
        handleRequestClose();
      }
    );
  };

  return (
    <>
      <IconButton
        type="button"
        className="icon-btn"
        color="primary"
        onClick={() => setOpenCRU(true)}
      >
        <i className="zmdi zmdi-plus-circle zmdi-hc-fw" />
      </IconButton>      
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openCRU}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="core.add" />
        </DialogTitle>
        <DialogContent>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <FormProvider {...methods}>
              <form className="row" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormInput
                      name="name"
                      label={<IntlMessages id="foream.knownAdverseEvent" />}
                      margin="normal"
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonAsync onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
          >
            {<IntlMessages id="core.save" />}
          </ButtonAsync>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddKnownAdverseEvent;