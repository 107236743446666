import { IntlGetText } from "util/IntlMessages";
import Highcharts from "highcharts";
import { currencyFormat } from "services/formatNumber";

const formatCurrency = (total, decimals) => {
  let value = total;
  if (value == 0)
      return "$ 0";

  let unit = "";
  if (value < 1000000)
  {
      unit = "K";
      value = value / 1000;
  }
  else if (value < 1000000000000)
  {
      unit = "M";
      value = value / 1000000;
  }
  else
  {
      unit = "bn";
      value = value / 1000000000000;
  }

  value = Math.round(value, decimals);
  return `$ ${value}${unit}`;
};

export const totalizationChart = (locale, keyValuePairData) => {
  const titleText = IntlGetText("dashboard.charTitle.accumulatedRecovery");
  const stateTypeText = IntlGetText("core.stateType");
  const totalizationText = IntlGetText("dashboard.totalization");
  const totalText = IntlGetText("core.total");

  const data = keyValuePairData.map((x) => [x.Key, x.Value]);
  const total = keyValuePairData.map((item) => item.Value)
  .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: "pie" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ' $' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    plotOptions: {
      pie: {
        innerSize: 150,
        depth: 45,
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    // tooltip: {
    //   pointFormat:
    //     "Instilación: <b>{point.y}</b><br/>EVA: {point.x}",
    // },
    tooltip: {
      formatter: function() {
        return (
          stateTypeText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale,this.y) +
          " (" +
          Highcharts.numberFormat(this.percentage, 2) +
          "%)" +
          "</b>"
        );
      },
    },
    series: [{ name: stateTypeText, data: data }],
  };
};

export const totalizationByDateChart = (locale, keyValuePairData) => {
  const titleText = IntlGetText("dashboard.charTitle.recoveryByDate");
  const stateTypeText = IntlGetText("core.stateType");
  const totalizationText = IntlGetText("dashboard.totalization");
  const totalText = IntlGetText("core.total");

  const data = keyValuePairData.map((x) => [x.Key, x.Value]);
  const total = keyValuePairData.map((item) => item.Value)
  .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: "pie" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ' $' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    plotOptions: {
      pie: {
        innerSize: 150,
        depth: 45,
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    // tooltip: {
    //   pointFormat:
    //     "Instilación: <b>{point.y}</b><br/>EVA: {point.x}",
    // },
    tooltip: {
      formatter: function() {
        return (
          stateTypeText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale,this.y) +
          " (" +
          Highcharts.numberFormat(this.percentage, 2) +
          "%)" +
          "</b>"
        );
      },
    },
    series: [{ name: stateTypeText, data: data }],
  };
};

export const detailEffectiveChart = (locale, keyValuePairData) => {
  const titleText = IntlGetText("dashboard.charTitle.effective");
  const stateTypeText = IntlGetText("core.monitoringStatus");
  const totalizationText = IntlGetText("dashboard.totalization");
  const totalText = IntlGetText("core.total");

  const data = keyValuePairData.map((x) => [x.Key, x.Value]);
  const total = keyValuePairData.map((item) => item.Value)
  .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: "pie" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ' $' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    plotOptions: {
      pie: {
        innerSize: 150,
        depth: 45,
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    tooltip: {
      formatter: function() {
        return (
          stateTypeText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale,this.y) +
          " (" +
          Highcharts.numberFormat(this.percentage, 2) +
          "%)" +
          "</b>"
        );
      },
    },
    series: [{ name: stateTypeText, data: data }],
  };
};

export const causalNotEffectiveChart = (locale, keyValuePairData, titleText) => {
  const monitoringStatusText = IntlGetText("core.monitoringStatus");
  const totalizationText = IntlGetText("dashboard.totalization");
  const totalText = IntlGetText("core.total");
    
  const data = keyValuePairData.map((x) => [x.Key, x.Value]);
  const total = keyValuePairData.map((item) => item.Value)
  .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: { text: titleText },
    subtitle: {
      text: totalText + ' $' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.percentage:.2f} %",
        },
      },
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      x: 0,
      y: 100,
    },
    tooltip: {
      formatter: function() {
        return (
          monitoringStatusText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale,this.y) +
          " (" +
          Highcharts.numberFormat(this.percentage, 2) +
          "%)" +
          "</b>"
        );
      },
    },
    series: [
      {
        name: "Brands",
        colorByPoint: true,
        data: data
      },
    ],
  };
};

export const productLineChart = (locale, keyValuePairData) => {
  const titleText = IntlGetText("dashboard.charTitle.byProduct");
  const familyText = IntlGetText("core.family");
  const totalizationText = IntlGetText("dashboard.totalization");
  const totalText = IntlGetText("core.total");

  const categories = keyValuePairData.map((x) => x.Key);
  const data = keyValuePairData.map((x) => x.Value);
  const total = keyValuePairData.map((item) => item.Value)
    .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: 'column' },
    title: { text: titleText },
    subtitle: {
      text: totalText + ' $' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    xAxis: [{
        categories: categories
    }],
    yAxis:{ visible: false },
    legend: { enabled: false },
    // yAxis: [{ // Secondary yAxis
    //     title: {
    //         text: 'Rainfall',
    //         // style: {
    //         //     color: Highcharts.getOptions().colors[0]
    //         // }
    //     },
    //     labels: {
    //         format: '{value} mm',
    //         // style: {
    //         //     color: Highcharts.getOptions().colors[0]
    //         // }
    //     }
    // }],
    // tooltip: {
    //     shared: true
    // },
    // legend: {
    //     layout: 'vertical',
    //     align: 'left',
    //     x: 120,
    //     verticalAlign: 'top',
    //     y: 100,
    //     floating: true,
    //     backgroundColor:
    //         Highcharts.defaultOptions.legend.backgroundColor || // theme
    //         'rgba(255,255,255,0.25)'
    // },
    // plotOptions: {
    //   column: {
    //       stacking: 'normal',
    //       dataLabels: {
    //           enabled: true
    //       }
    //   }
    // },
    // plotOptions: {
    //   column: {
    //       pointPadding: 0,
    //       borderWidth: 0
    //   }
    // },
    // plotOptions: {
    //   series: {
    //       pointWidth: 35
    //   }
    // },
    tooltip: {
      formatter: function() {
        return (
          familyText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale,this.y) +
          "</b>"
        );
      },
    },
    series: [{
        name: 'Rainfall',
        type: 'column',
        data: data,
        // tooltip: {
        //     valueSuffix: ' mm'
        // },
        dataLabels: {
          enabled: true,
          // rotation: -90,
          // color: '#FFFFFF',
          //align: 'right',
          //format: '{point.y:.1f}', // one decimal
          formatter: function() {
            return formatCurrency(this.y,0);
          },
          y: 0, // 10 pixels down from the top
          // style: {
          //     fontSize: '13px',
          //     fontFamily: 'Verdana, sans-serif'
          // }
      },
    }],
  };
};

export const causalNotEffectiveMoneyChart = (locale, keyValuePairData, titleText) => {
  const monitoringStatusText = IntlGetText("core.monitoringStatus");
  const totalizationText = IntlGetText("dashboard.totalization");
  const totalText = IntlGetText("core.total");

  const categories = keyValuePairData.map((x) => x.Key);
  const data = keyValuePairData.map((x) => x.Value);
  const total = keyValuePairData.map((item) => item.Value)
  .reduce((prev, curr) => prev + curr, 0);

  return {
    // lang: {
    //   thousandsSep: '.',
    //   decimalPoint: ','
    // },
    credits: { enabled: false },
    chart: { type: 'bar' },
    title: { text: titleText },
    subtitle: {
      text: totalText + ' $' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    xAxis: [{ categories: categories }],
    yAxis:{ visible: false },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function() {
        return (
          monitoringStatusText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale,this.y) +
          "</b>"
        );
      },
    },
    series: [{
        name: '',
        type: 'column',
        data: data,
        // tooltip: {
        //     valueSuffix: ' mm'
        // },
        dataLabels: {
          enabled: true,
          // rotation: -90,
          // color: '#FFFFFF',
          //align: 'right',
          //format: '{point.y:.1f}', // one decimal
          formatter: function() {
            return formatCurrency(this.y,0);
          },
          y: 0, // 10 pixels down from the top
          // style: {
          //     fontSize: '13px',
          //     fontFamily: 'Verdana, sans-serif'
          // }
      },
    }],
  };
};

export const deliveryModeChart = (locale, keyValuePairData) => {
  const titleText = IntlGetText("dashboard.charTitle.deliveryMode");
  const amountText = IntlGetText("core.amount");
    
  const data = keyValuePairData.map((x) => [x.Key, x.Value]);

  return {
    credits: { enabled: false },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: { text: titleText },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.percentage:.2f} %",
        },
      },
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      x: 0,
      y: 100,
    },
    tooltip: {
      formatter: function() {
        return (
          titleText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          amountText +
          ": <b>" +
          currencyFormat(locale,this.y) +
          " (" +
          Highcharts.numberFormat(this.percentage, 2) +
          "%)" +
          "</b>"
        );
      },
    },
    series: [
      {
        name: "Brands",
        colorByPoint: true,
        data: data,
      },
    ],
  };
};

export const marketchainChart = (locale, keyValuePairData) => {
  const titleText = IntlGetText("dashboard.charTitle.byMarketchain");
  const clientText = IntlGetText("core.client");
  const totalizationText = IntlGetText("dashboard.totalization");
  const totalText = IntlGetText("core.total");

  const categories = keyValuePairData.map((x) => x.Key);
  const data = keyValuePairData.map((x) => x.Value);
  const total = keyValuePairData.map((item) => item.Value)
    .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: 'column' },
    title: { text: titleText },
    subtitle: {
      text: totalText + ' $' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    xAxis: [{
        categories: categories
    }],
    yAxis:{ visible: false },
    legend: { enabled: false },
    tooltip: {
      formatter: function() {
        return (
          clientText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale,this.y) +
          "</b>"
        );
      },
    },
    series: [{
        name: 'Rainfall',
        type: 'column',
        data: data,
        dataLabels: {
          enabled: true,
          //format: '{point.y:.1f}', // one decimal
          formatter: function() {
            return formatCurrency(this.y,0);
          },
          y: 0,
      },
    }],
  };
};

export const stateTrendChart = () => {
  const titleText = IntlGetText("dashboard.charTitle.stateTrend");
  const xAxisTitle = IntlGetText("dashboard.stateTrend.xTile");
  const totalizationText = IntlGetText("dashboard.totalization");

  var categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec','Jan 1', 'Feb 1', 'Mar 1', 'Apr 1', 'May 1', 'Jun 1', 'Jul 1', 'Aug 1', 'Sep 1', 'Oct 1', 'Nov 1', 'Dec 1','Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var series = [{
    name: 'Tokyo',
    data: [7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6,7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6,7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6]
  }, {
      name: 'London',
      data: [3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8,3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8,3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8]
  }];

  return {
    credits: { enabled: false },
    chart: { 
      type: 'line', 
      scrollablePlotArea: {
        minWidth: 2000
      } 
    },
    title: { text: titleText },
    xAxis: {
      categories: categories,
      title: { text: xAxisTitle }
    },
    yAxis:{ visible: false },
    plotOptions: {
        line: {
            dataLabels: {
                enabled: true
            }
        }
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">'+xAxisTitle+' <b>{point.key}</b></span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    series: series
  };
};

export const stateTrendBarChart = () => {
  const titleText = IntlGetText("dashboard.charTitle.stateTrend");
  const xAxisTitle = IntlGetText("dashboard.stateTrend.xTile");
  const stateTypeText = IntlGetText("core.stateType");
  const totalizationText = IntlGetText("dashboard.totalization");

  var categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec','Jan 1', 'Feb 1', 'Mar 1', 'Apr 1', 'May 1', 'Jun 1', 'Jul 1', 'Aug 1', 'Sep 1', 'Oct 1', 'Nov 1', 'Dec 1','Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var series = [{
    name: 'Tokyo',
    data: [7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6,7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6,7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6]
  }, {
      name: 'London',
      data: [3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8,3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8,3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8]
  }];

  return {
    credits: { enabled: false },
    chart: {
      type: 'column',
      scrollablePlotArea: {
        minWidth: 2000
      } 
    },
    title: { text: titleText },
    yAxis:{ visible: false },
    xAxis: {
        categories: categories
    },
    // yAxis: {
    //     min: 0,
    //     title: {
    //         text: 'Total fruit consumption'
    //     },
    //     stackLabels: {
    //         enabled: true,
    //         style: {
    //             fontWeight: 'bold',
    //             color: ( // theme
    //                 Highcharts.defaultOptions.title.style &&
    //                 Highcharts.defaultOptions.title.style.color
    //             ) || 'gray'
    //         }
    //     }
    // },
    // legend: {
    //     align: 'right',
    //     x: -30,
    //     verticalAlign: 'top',
    //     y: 25,
    //     floating: true,
    //     backgroundColor:
    //         Highcharts.defaultOptions.legend.backgroundColor || 'white',
    //     borderColor: '#CCC',
    //     borderWidth: 1,
    //     shadow: false
    // },
    // tooltip: {
    //     headerFormat: '<b>{point.x}</b><br/>',
    //     pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    // },
    // tooltip: {
    //   headerFormat: '<span style="font-size:10px">'+xAxisTitle+' <b>{point.key}</b></span><table>',
    //   pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
    //       '<td style="padding:0"><b>{point.y}</b></td></tr>',
    //   footerFormat: '</table>',
    //   shared: true,
    //   useHTML: true
    // },
    tooltip: {
      formatter: function() {      
        return (
          xAxisTitle +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          stateTypeText +
          ": <b>" +
          this.series.name +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          this.y +
          " (" +
          Highcharts.numberFormat(this.percentage, 2,',','.') +
          "%)" +
          "</b>"
        );
      },
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: true,
                format: "<b>{point.percentage:.2f} %",
            }
        }
    },
    series: series
  };
};