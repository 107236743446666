import React from "react";
import CardBox from "components/CardBox/index";

import { doctors, datatableConfig, doctorColumns } from "./../data";

import MUIDataTable from "mui-datatables";

class DoctorList extends React.Component {
  render() {
    const columns = doctorColumns;
    const data = doctors;

    const options = {
      filterType: "dropdown",
      download: false,
      print: false,
      selectableRows: "none",
      textLabels: datatableConfig.textLabels,
    };

    return (
      <div className="app-wrapper">
        <div className="row">
          <CardBox styleName="col-12" cardStyle="p-0">
            <MUIDataTable
              title={"Médicos"}
              data={data}
              columns={columns}
              options={options}
            />
          </CardBox>
        </div>
      </div>
    );
  }
}

export default DoctorList;
