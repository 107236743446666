import { IntlGetText } from "util/IntlMessages";
import Highcharts from "highcharts";
import { currencyFormat } from "services/formatNumber";

const formatCurrency = (total, decimals) => {
  let value = total;
  if (value == 0)
      return "$ 0";

  let unit = "";
  if (value < 1000000)
  {
      unit = "K";
      value = value / 1000;
  }
  else if (value < 1000000000000)
  {
      unit = "M";
      value = value / 1000000;
  }
  else
  {
      unit = "bn";
      value = value / 1000000000000;
  }

  value = Math.round(value, decimals);
  return `$ ${value}${unit}`;
};

export const eficaciaDetailChart = (locale, keyValuePairData) => {
  const titleText = IntlGetText("dashboardMigration.eficaciaRecoveries");
  const stateTypeText = IntlGetText("core.stateType");
  const totalizationText = IntlGetText("dashboard.totalization");
  const totalText = IntlGetText("core.total");

  const data = keyValuePairData.map((x) => [x.Key, x.Value]);
  const total = keyValuePairData.map((item) => item.Value)
  .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: "pie" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ' $' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    plotOptions: {
      pie: {
        innerSize: 150,
        depth: 45,
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          //format: "<b>{point.name}</b>: {point.y} ({point.percentage:.1f} %)",
          formatter: function() {
            return "<b>$ " +
              currencyFormat(locale,this.y) +
              " (" +
              Highcharts.numberFormat(this.percentage, 1) +
              "%)" +
              "</b>";
          },
        },
      },
    },
    // tooltip: {
    //   pointFormat:
    //     "Instilación: <b>{point.y}</b><br/>EVA: {point.x}",
    // },
    tooltip: {
      formatter: function() {
        return (
          stateTypeText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale,this.y) +
          " (" +
          Highcharts.numberFormat(this.percentage, 2) +
          " %)" +
          "</b>"
        );
      },
    },
    series: [{ name: stateTypeText, data: data }],
  };
};

export const totalChart = (locale, keyValuePairData) => {
  const titleText = IntlGetText("core.total");
  const stateTypeText = IntlGetText("core.stateType");
  const totalizationText = IntlGetText("dashboard.totalization");
  const totalText = IntlGetText("core.total");

  const data = keyValuePairData.map((x) => [x.Key, x.Value]);
  const total = keyValuePairData.map((item) => item.Value)
  .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: "pie" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ' $' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    plotOptions: {
      pie: {
        innerSize: 150,
        depth: 45,
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          //format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          formatter: function() {
            return "<b>$ " +
              currencyFormat(locale,this.y) +
              " (" +
              Highcharts.numberFormat(this.percentage, 1) +
              "%)" +
              "</b>";
          },
        },
      },
    },
    // tooltip: {
    //   pointFormat:
    //     "Instilación: <b>{point.y}</b><br/>EVA: {point.x}",
    // },
    tooltip: {
      formatter: function() {
        return (
          stateTypeText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale,this.y) +
          " (" +
          Highcharts.numberFormat(this.percentage, 2) +
          " %)" +
          "</b>"
        );
      },
    },
    series: [{ name: stateTypeText, data: data }],
  };
};

export const causalChart = (locale, keyValuePairData) => {
  const titleText = IntlGetText("dashboardMigration.valueByCausal");
  const causalText = IntlGetText("core.causal");
  const totalizationText = IntlGetText("patientDetail.value");
  const totalText = IntlGetText("core.total");

  // const categories = keyValuePairData.map((x) => x.Key);
  // const data = keyValuePairData.map((x) => x.Value);
  const data = keyValuePairData.map((x) => [x.Key, x.Value]);
  const total = keyValuePairData.map((item) => item.Value)
    .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: { text: titleText },
    subtitle: {
      text: totalText + ' $' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.percentage:.2f} %",
        },
      },
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      x: 0,
      y: 100,
    },
    tooltip: {
      formatter: function() {
        return (
          causalText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale,this.y) +
          "</b>"
        );
      },
    },
    series: [
      {
        name: "Brands",
        colorByPoint: true,
        data: data
      },
    ],
  };
};

export const operatorChart = (locale, keyValuePairData) => {
  const titleText = IntlGetText("dashboardMigration.valueByOperator");
  const causalText = IntlGetText("radicadoOLF.cardSubTitle");
  const totalizationText = IntlGetText("patientDetail.value");
  const totalText = IntlGetText("core.total");

  const categories = keyValuePairData.map((x) => x.Key);
  const data = keyValuePairData.map((x) => x.Value);
  const total = keyValuePairData.map((item) => item.Value)
    .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: 'bar' },
    title: { text: titleText },
    subtitle: {
      text: totalText + ' $' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    xAxis: [{ categories: categories }],
    yAxis:{ visible: false },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function() {
        return (
          causalText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale,this.y) +
          "</b>"
        );
      },
    },
    series: [{
        name: '',
        type: 'column',
        data: data,
        color: '#90ED7D',
        dataLabels: {
          enabled: true,
          formatter: function() {
            return formatCurrency(this.y,0);
          },
          y: 0,
      },
    }],
  };
};

export const productLineChart = (locale, keyValuePairData) => {
  const titleText = IntlGetText("dashboardMigration.valueByProductLine");
  const causalText = IntlGetText("kpi.productLine");
  const totalizationText = IntlGetText("patientDetail.value");
  const totalText = IntlGetText("core.total");

  const categories = keyValuePairData.map((x) => x.Key);
  const data = keyValuePairData.map((x) => x.Value);
  const total = keyValuePairData.map((item) => item.Value)
    .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: 'bar' },
    title: { text: titleText },
    subtitle: {
      text: totalText + ' $' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    xAxis: [{ categories: categories }],
    yAxis:{ visible: false },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function() {
        return (
          causalText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale,this.y) +
          "</b>"
        );
      },
    },
    series: [{
        name: '',
        type: 'column',
        data: data,
        dataLabels: {
          enabled: true,
          formatter: function() {
            return formatCurrency(this.y,0);
          },
          y: 0,
      },
    }],
  };
};

export const recoveriesChart = (locale, keyValuePairData) => {
  const titleText = IntlGetText("dashboardMigration.recoveries");
  const causalText = IntlGetText("core.causal");
  const totalizationText = IntlGetText("patientDetail.value");
  const totalText = IntlGetText("core.total");

  const categories = keyValuePairData.map((x) => x.Key);
  const data = keyValuePairData.map((x) => x.Value);
  const total = keyValuePairData.map((item) => item.Value)
    .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: 'bar' },
    title: { text: titleText },
    subtitle: {
      text: totalText + ' $' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    xAxis: [{ categories: categories }],
    yAxis:{ visible: false },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function() {
        return (
          causalText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale,this.y) +
          "</b>"
        );
      },
    },
    series: [{
        name: '',
        type: 'column',
        data: data,
        color: '#434348',
        dataLabels: {
          enabled: true,
          formatter: function() {
            return formatCurrency(this.y,0);
          },
          y: 0,
      },
    }],
  };
};