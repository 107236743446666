import {
    SET_LOADER
  } from "constants/ActionTypes";
  
  const INIT_STATE = {
    loader: false,
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_LOADER: {
        return {
          ...state,
          loader: action.payload
        };
      }     
      default:
        return state;
    }
  };
  