import React, { useState ,useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { Button, Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions 
} from "@material-ui/core";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import FormDateTimePicker from "components/Form/Controls/dateTimePicker";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";

const DialogRescheduleImpact = ({open, handleRequestClose, callRescheduleImpact, commentsList, prescription2 }) => {
  
    const msgValidation = IntlGetText("rescheduleImpact.breachValidation");
  
    const defaultValues = { 
        planningDate: null,
        breachId: null,
        breachComment: null
    };
  
    const [prescription, setPrescription] = useState({});

    const methods = useForm({ defaultValues });
    const { handleSubmit, watch, setValue, errors } = methods;
  
    const planningDateVlue = watch("planningDate");
  
    const isBreach = (prescription) => {
      return prescription && prescription.firstImpactDate       
        && dayjs(prescription.firstImpactDate).format("YYYY-MM-DD") == dayjs().format("YYYY-MM-DD");
    }

    const loadData = () => {
      setValue("planningDate",dayjs(prescription.firstImpactDate));
    };

    useEffect(()=>setPrescription(prescription2),[prescription2]);
    useEffect(()=>{
      if(open){
        setTimeout(loadData,150);
      }
    },[open,setValue,prescription]);
    

    const cboHandleChange = (name) => (event) => {
        setValue(name, event);
    };

    const onSubmit = async (data, e) => {
      if(isBreach(prescription) && ( !data.breachId && !data.breachComment)){
        NotificationManager.warning(msgValidation);
        return;
      }
  
      const obj = {
        impactId: prescription.impactId,
        planningDate: dayjs(data.planningDate).format("YYYY-MM-DDTHH:mm:ss"),
        breachId: !data.breachId ? data.breachId : data.breachId.value,
        breachComment: data.breachComment
      };
      callRescheduleImpact(obj);
    };
  
    return (
        <Dialog open={open} fullWidth={true} maxWidth="xs" 
          disableBackdropClick={true} onClose={handleRequestClose} >
            <DialogTitle>{<IntlMessages id="impact.rescheduleImpact" />}</DialogTitle>
            <DialogContent className="new-patient-dialog-content">
                <FormProvider {...methods}>
                    <form className="row" noValidate autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                                <FormDateTimePicker
                                    name="planningDate"
                                    label={IntlGetText("core.dateAndTime")}  
                                    format="YYYY-MM-DD hh:mm A" 
                                    disablePast={true}
                                    required={true} 
                                    errorobj={errors}  
                                />
                        </Grid>
                        { isBreach(prescription) &&
                          <Grid item xs={12} md={12} lg={12}>
                            <FormSelectAutoComplete
                              name="breachId"
                              label={<IntlMessages id="impact.BreachReason" />}
                              options={commentsList}
                              value={watch("breachId")}
                              onChange={cboHandleChange("breachId")}
                              margin="normal"
                              required={true}
                              errorobj={errors}
                            />
                          </Grid>
                        }                      
                        { isBreach(prescription) &&
                          <Grid item md={12}>
                              <FormInput         
                                  name="breachComment"         
                                  label={<IntlMessages id="core.other" />}                                
                                  multiline
                                  rows={5}
                                  variant="outlined"
                                  required={true}
                                  errorobj={errors}
                              />
                          </Grid>
                        }
                        </Grid>
                    </form>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRequestClose} color="secondary">
                    {<IntlMessages id="core.cancel" />}
                </Button>
                <Button onClick={handleSubmit(onSubmit)} color="primary">
                    {<IntlMessages id="core.save" />}
                </Button>
            </DialogActions>
        </Dialog>
    );
  };

  export default DialogRescheduleImpact;