import React, { useEffect } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import {genericTheme,spintPlusTheme} from "./themes/cyanTheme";
import AppLocale from "../lngProvider";
import { NotificationContainer } from "react-notifications";
import Progress from "../components/Progress";
import SignIn from "./SignIn";
import PatientsTimeline from "../app/routes/psp/timeline";
import { setInitUrl } from "../actions/Auth";
import asyncComponent from "util/asyncComponent";
import AppLayout from "./AppLayout";
import { getUserInfo, isRole } from "app/routes/psp/services/userService";
import { getInitURL } from "constants/setupMenu";
import { RoleEnum } from "constants/Enums";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const App = (props) => {
  const dispatch = useDispatch();
  const { locale } = useSelector(
    ({ settings }) => settings
  );
  const { authUser, initURL } = useSelector(({ auth }) => auth);
  const { match, location } = props;

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === "") {
      dispatch(setInitUrl(props.history.location.pathname));
    }
  }, [
    dispatch,
    initURL,
    props.history.location.pathname,
    props.location.search,
  ]);

  const isSpintPlus = () => window.location.href.includes('spintplus');
  let theme = isSpintPlus() ? spintPlusTheme : genericTheme;
  let applyTheme = createMuiTheme(theme);
  document.title = theme.pageTitle;
  const favicon = document.getElementById("favicon");
  favicon.href = theme.favicon;

  if(location.pathname === "/app/relogin" && getUserInfo() && !isRole(RoleEnum.superAdmin)){
    return <Redirect to="/" />;
  }
  
  if (location.pathname === "/") {
    if (authUser === null) {
      return <Redirect to={"/signin"} />;
    } else if (initURL === "" || initURL === "/" || initURL === "/signin" || initURL === "/unauthorized") {
      return <Redirect to={getInitURL(getUserInfo())} />;
    } else {
      return <Redirect to={initURL} />;
    }
  }

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
            <div className="app-main">
              <Switch>
                <RestrictedRoute
                  path={`${match.url}app`}
                  authUser={authUser}
                  component={AppLayout}
                />
                <Route path="/signin" component={SignIn} />
                <Route path="/patientprocess" component={PatientsTimeline} />
                <Route
                  component={asyncComponent(() =>
                    import("app/routes/extraPages/routes/404")
                  )}
                />
              </Switch>
            </div>
        </IntlProvider>
      </MuiPickersUtilsProvider>
      <NotificationContainer />
      <Progress />
    </ThemeProvider>
  );
};

export default App;
