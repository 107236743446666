import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button, Grid } from "@material-ui/core";
import {Card, CardHeader} from 'reactstrap';
import { FileUploadWithOriginalName } from "components/Form/file-upload/index";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import { callActionApi } from "api/apiList";
import { ImpactFileTypeList } from "constants/Enums";

const clearFile = () => {
    const elementFile =  document.getElementById("fm-file");
    if (elementFile)
        elementFile.value = "";
};

const UploadFileForm = ({ callBack, actionApi, actionApiInfo }) => {
  const [fileResult, setFileResult] = useState({fileName:"", originalName:""});
  const handleFilenameChange = (filenameResult) => { setFileResult(filenameResult); };

  const msgSuccess = IntlGetText("core.SuccessSave");
  const msgFileWarning = IntlGetText("core.fileNotFound");
  const impactFileTypeList = ImpactFileTypeList();

  const defaultValues = { impactFileType: null , comment: "" };
  const validationSchema = { 
    impactFileType: yup .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable()
  };
  const methods = useForm({ defaultValues, resolver: yupResolver(yup.object().shape(validationSchema)) });
  const { handleSubmit, reset, errors} = methods;

  const clear = () => {
    clearFile();
    reset({ impactFileType: null, comment: "" });
    setFileResult({fileName:"", originalName:""});
  };

  const onSubmit = async (d, e) => {    
    if (fileResult.fileName) {
      var obj = { 
        ...actionApiInfo, 
        tempFilename: fileResult.fileName, 
        comment: d.comment,
        impactFileType: Number(d.impactFileType.value)
      };
      callActionApi(
        () => actionApi(obj),
        async (fileResult) => {
          NotificationManager.success(msgSuccess);
          clear();          
          callBack(fileResult);         
        },
        (error) => {
          if (typeof error === "string") NotificationManager.error(error);
        } //, (data) => dispatch(setLoader(data))
      );
    } else {
      NotificationManager.warning(msgFileWarning);
    }
  };

  return (
    <Card className="shadow border-0 mt-4">
    <CardHeader>
      <FormProvider {...methods}>
        <form className="row" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <FileUploadWithOriginalName id="fm-file" setResult={handleFilenameChange} >                
                <Button color="secondary" aria-label="add" component="span" className="mt-1">
                  <i className="zmdi zmdi-upload mr-2"></i> <IntlMessages id="core.fileUpload" />
                </Button>
              </FileUploadWithOriginalName>
              <span className="ml-2 mr-2">{fileResult.originalName}</span>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                    <FormSelectAutoComplete
                      name="impactFileType"
                      label={<IntlMessages id="core.type" />}
                      options={impactFileTypeList}
                      margin="normal"
                      required={true}
                      errorobj={errors}
                    />
            </Grid>
            <Grid item xs={12} md={7} lg={7}>
              <FormInput
                name="comment"
                label={<IntlMessages id="core.comment" />}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={1} lg={1}>
              <Button style={{ minWidth: 'auto'}}
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                  className="mt-3"
                >
                  {<i className="zmdi zmdi-floppy"></i>}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </CardHeader>
    </Card>  
  );
};

export default UploadFileForm;