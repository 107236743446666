import React, { useState } from "react";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CardBox from "components/CardBox/index";
import MediaModal from "./mediaModal";

const TableCE = ({ data, handleOpenCRU, setCurrentRowObj, loadTable }) => {
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({});

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = (obj) => {
    setCurrentRow(obj);
    setOpen(true);
  };

  const datatableConfig = {
    textLabels: {
      body: {
        noMatch: "No hay resultados para la búsqueda actual",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordernar por ${column.label}`
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Items por pagina:",
        displayRows: "de"
      },
      toolbar: {
        search: "Busqueda",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtro"
      },
      filter: {
        all: "Todos",
        title: "FILTRO",
        reset: "LIMPIAR"
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas"
      },
      selectedRows: {
        text: "item(s) seleccionados",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas"
      }
    }
  };

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: datatableConfig.textLabels
  };

  const handleClick = (tableMeta) => {
    handleOpenCRU();
    setCurrentRowObj(
      !tableMeta ? null : tableMeta.tableData[tableMeta.rowIndex]
    );
  };

  const getObj = (tableMeta) => {
    return data.find((x) => x.id === tableMeta.rowData[0]);
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded"
      }
    },
    {
      name: "examDate",
      label: IntlGetText("ControlExam.ControlExamDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("DD/MM/YYYY") : "";
        }
      }
    },
    {
      name: "controlExamTx",
      label: IntlGetText("ControlExam.ControlExamName"),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "result",
      label: IntlGetText("ControlExam.result"),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "controlExamUnitTypeTx",
      label: IntlGetText("ControlExam.units"),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "actions",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return (
            <div className="HContainer">
              <Button
                onClick={() => handleClick(tableMeta)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-edit"></i>
              </Button>

              <Button
                onClick={() => openModal(rowObj)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-file"></i>
              </Button>
            </div>
          );
        }
      }
    }
  ];

  return (
    <div className="row">
      <CardBox styleName="col-12" cardStyle="p-0 border-dialog-card">
        <MUIDataTable
          className="border-dialog-card"
          data={data}
          columns={columns}
          options={options}
        />
      </CardBox>
      <div className="col-lg-12 col-md-12 col-xs-12 mt-4 text-center">
        <Button
          onClick={() => handleClick(null)}
          variant="contained"
          color="primary"
        >
          {<IntlMessages id="core.add" />}
        </Button>
      </div>

      <MediaModal
        open={open}
        loadTable={loadTable}
        handleRequestClose={closeModal}
        rowObj={currentRow}
      />
    </div>
  );
};

export default TableCE;
