import React from "react";
import { useSelector } from "react-redux";
import ScrollContent from "../../../management/scrollContent";

const TableBox = ({children, tableStyle}) => { //({children, ...props})
    const { width } = useSelector(({ settings }) => settings);
    return (
        <div className="table-responsive-material table-userdetail-mmin">                 
        <ScrollContent 
            width={width}
            classParent="-"
            heightMax = "220px"
            heightMin = "220px"
            >
            <table className={`default-table table-sm table full-table mb-0 ${tableStyle}`}>
                <tbody>
                {children}
                </tbody>
            </table>
        </ScrollContent>
        </div>
    );
};

export default TableBox;