import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { getUserInfo } from "app/routes/psp/services/userService";
import IntlMessages from "util/IntlMessages";
import { getMenuItems, TestMenu } from "constants/setupMenu";


const LiMenu = ({userInfo}) => {
  const data = getMenuItems(userInfo);
  
  return data.map((x,index)=>{
    return(
    <li className="nav-item" key={index}>
      <NavLink className="prepend-icon" to={x.link}>
        <span className="nav-text">
          <IntlMessages id={x.name} />
        </span>
      </NavLink>
    </li>
    );
  })
}

class Menu extends Component {
  constructor() {
    super();
    this.state = {
       role: ""
    };
  }


  async componentDidMount() {
    const { history } = this.props;

    const pathname = `#${history.location.pathname}`; // get current path
    const mainMenu = document.getElementsByClassName("nav-item");
    for (let i = 0; i < mainMenu.length; i++) {
      mainMenu[i].onclick = function() {
        for (let j = 0; j < mainMenu.length; j++) {
          if (mainMenu[j].classList.contains("active")) {
            mainMenu[j].classList.remove("active");
          }
        }
        this.classList.toggle("active");
      };
    }
    const subMenuLi = document.getElementsByClassName("nav-arrow");
    for (let i = 0; i < subMenuLi.length; i++) {
      subMenuLi[i].onclick = function() {
        for (let j = 0; j < subMenuLi.length; j++) {
          if (subMenuLi[j].classList.contains("active")) {
            subMenuLi[j].classList.remove("active");
          }
        }
        this.classList.toggle("active");
      };
    }
    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("active");
      } else {
        this.closest(activeLi, "li").classList.add("active");
      }
      const parentNav = this.closest(activeNav, ".nav-item");
      if (parentNav) {
        parentNav.classList.add("active");
      }
    } catch (e) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    return(
      <div className="app-main-menu d-none d-md-block">
        {/* <TestMenu/> */}
        <ul className="navbar-nav navbar-nav-mega">
          <LiMenu userInfo={getUserInfo()}/>
        </ul>
      </div>
    );
    // if(getUserInfo() && getUserInfo().shardKey === ShardKeyEnum.ialuril){
    //   return(
    //     <div className="app-main-menu d-none d-md-block">          
    //       <ul className="navbar-nav navbar-nav-mega">
    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/patientPA">
    //             <span className="nav-text">
    //               <IntlMessages id="psp.menu.patients" />
    //             </span>
    //           </NavLink>
    //         </li>
    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/impactPlanning">
    //             <span className="nav-text">
    //               <IntlMessages id="psp.menu.impactPlanning" />
    //             </span>
    //           </NavLink>
    //         </li>
    //       </ul>
    //     </div>
    //   )
    // }
    // return (
    //   <div className="app-main-menu d-none d-md-block">
    //     {this.state.role === "nurse" && (
    //       <ul className="navbar-nav navbar-nav-mega">
    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/home">
    //             <span className="nav-text">
    //               <IntlMessages id="psp.menu.home" />
    //             </span>
    //           </NavLink>
    //         </li>
    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/management">
    //             <span className="nav-text">
    //               <IntlMessages id="psp.menu.manage" />
    //             </span>
    //           </NavLink>
    //         </li>
    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/impactPlanning">
    //             <span className="nav-text">
    //               <IntlMessages id="psp.menu.impactPlanning" />
    //             </span>
    //           </NavLink>
    //         </li>
    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/patient">
    //             <span className="nav-text">
    //               <IntlMessages id="psp.menu.patients" />
    //             </span>
    //           </NavLink>
    //         </li>
            {/* <li className="nav-item">
              <NavLink className="prepend-icon" to="/app/patient0">
                <span className="nav-text">
                  <IntlMessages id="psp.menu.patients" />
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="prepend-icon" to="/app/doctor">
                <span className="nav-text">
                  <IntlMessages id="psp.menu.doctors" />
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="prepend-icon" to="/app/patient2/List">
                <span className="nav-text">Pacientes2</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="prepend-icon" to="/app/patient2/Add2">
                <span className="nav-text">Add Patient</span>
              </NavLink>
            </li> */}
    //       </ul>
    //     )}

    //     {this.state.role === "pharma" && (
    //       <ul className="navbar-nav navbar-nav-mega">
    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/pharmacovigilant">
    //             <span className="nav-text">
    //               <IntlMessages id="psp.menu.home" />
    //             </span>
    //           </NavLink>
    //         </li>
    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/managepharma">
    //             <span className="nav-text">
    //               <IntlMessages id="psp.menu.manage" />
    //             </span>
    //           </NavLink>
    //         </li>
    //       </ul>
    //     )}

    //     {this.state.role === "manager" && (
    //       <ul className="navbar-nav navbar-nav-mega">
    //         <li className="nav-item">
    //           <span className="nav-link">
    //             <IntlMessages id="kpi.title" />
    //           </span>

    //           <ul className="sub-menu">
    //             <li>
    //               <NavLink to="/app/kpi/productLine">
    //                 <i className="zmdi zmdi-star-circle zmdi-hc-fw" />
    //                 <span className="nav-text">
    //                   <IntlMessages id="kpi.productLine" />
    //                 </span>
    //               </NavLink>
    //             </li>

    //             <li>
    //               <NavLink to="/app/kpi/impacts">
    //                 <i className="zmdi zmdi-chart zmdi-hc-fw" />
    //                 <span className="nav-text">
    //                   <IntlMessages id="kpi.impacts" />
    //                 </span>
    //               </NavLink>
    //             </li>

    //             <li>
    //               <NavLink to="/app/kpi/pharmacoVigilance">
    //                 <i className="zmdi zmdi-shield-check zmdi-hc-fw" />
    //                 <span className="nav-text">
    //                   <IntlMessages id="kpi.impacts.PharmacoVigilance" />
    //                 </span>
    //               </NavLink>
    //             </li>
    //           </ul>
    //         </li>

    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/doctor">
    //             <span className="nav-text">Médicos</span>
    //           </NavLink>
    //         </li>
    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/patient/">
    //             <span className="nav-text">Pacientes</span>
    //           </NavLink>
    //         </li>
    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/manager-calendar/">
    //             <span className="nav-text">Calendario</span>
    //           </NavLink>
    //         </li>
    //       </ul>
    //     )}
    //     {this.state.role === "pharmacovigilance" && 
    //       <ul className="navbar-nav navbar-nav-mega">
    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/adverseEvent">
    //             <span className="nav-text">
    //               <IntlMessages id="psp.menu.adverseEvents" />
    //             </span>
    //           </NavLink>
    //         </li>
    //       </ul>
    //     }
    //     {this.state.role === "psp_manager" && 
    //       <ul className="navbar-nav navbar-nav-mega">
    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/patient">
    //             <span className="nav-text">
    //               <IntlMessages id="psp.menu.patients" />
    //             </span>
    //           </NavLink>
    //         </li>
    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/impactPlanning">
    //             <span className="nav-text">
    //               <IntlMessages id="psp.menu.impactPlanning" />
    //             </span>
    //           </NavLink>
    //         </li>
    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/reports">
    //             <span className="nav-text">
    //               <IntlMessages id="menu.reports" />
    //             </span>
    //           </NavLink>
    //         </li>
    //         <li className="nav-item">
    //           <NavLink className="prepend-icon" to="/app/patient/management">
    //             <span className="nav-text">
    //               <IntlMessages id="menu.patientManagement" />
    //             </span>
    //           </NavLink>
    //         </li>
    //       </ul>
    //     }
    //   </div>
    // );
  }
}

export default withRouter(Menu);