import React, { useEffect, useState } from "react";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import { isRole } from "app/routes/psp/services/userService";
import AddPatient from "./addPatient";
import AddIcon from '@material-ui/icons/Add';
import { 
  callApi, 
  getUserByRole, 
  getRepList,
  getPatientPAListToSync
} from "api/apiList";
import { RoleEnum } from 'constants/Enums';


const getUserList = (data) =>{
  return data.map(x => ({
    id: x.id,
    name: x.fullName
  }));
}

const BtnAddPatient = ({loadData}) =>{  
  const [openDlg, setOpenDlg] = useState(false);
  const [nurseList, setNurseList] = useState([]);
  const [repList, setRepList] = useState([]);
  const [patients, setPatients] = useState([]);
  const handleRequestClose = () => setOpenDlg(false);

  const addTest = IntlGetText("core.add");

  const loadPatients =()=>{
    callApi(getPatientPAListToSync, 
      (data)=>{
        setPatients([...data,{id:-1,name:addTest}])
      });
  };

  useEffect(()=>{
    if(isRole(RoleEnum.rep)){
      callApi(
        () => getUserByRole(RoleEnum.nurse),
        (data) => setNurseList(getUserList(data))
      );
    }
    if(isRole(RoleEnum.nurse)){
      callApi(getRepList, setRepList);
    }
    loadPatients();
  },[]);

  return (
    <>
      <div>
        <Button
          onClick={()=>setOpenDlg(true)}
          variant="contained"
          color="primary"
          className="ml-2"
        >
          <AddIcon/>
        </Button>
      </div>
      <AddPatient 
        open={openDlg} 
        handleRequestClose={handleRequestClose}
        nurseList={nurseList}
        repList={repList}
        patients={patients}
        callBack={()=>{
          handleRequestClose();
          loadData();
          loadPatients();
          handleRequestClose();
        }}/>
    </>
  );
};

export default BtnAddPatient;