import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "actions/Base";
import { useForm, FormProvider } from "react-hook-form";
import * as dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import AppLocale from "lngProvider";
import { Button, Grid } from "@material-ui/core";
import FormSelectAutoComplete, {
  getCboValue,
  getCboValues,
} from "components/Form/Controls/select-autocomplete";
import CustomChart from "components/CustomChart";
import FormDatePicker from "components/Form/Controls/datepicker";
import { YesNoChart, QuestionChart, LineChart } from "./optionsCharts";
import {
  callApi,
  getDashboardClinical,
  getCyclesQuery,
  getInstitutionsList,
} from "api/apiList";

const getPropArray = (obj, prop) => {
  if (!obj) return [];
  return obj[prop];
};
const getProp = (obj, prop) => {
  if (!obj) return 0;
  return obj[prop];
};

const getQuestionTitle = (questionNumber) => {
  switch (questionNumber) {
    case 1:
      return "1. ¿Presenta dolor pélvico y/o ardor al orinar?";
    case 11:
      return "1.1 Califique de 0 a 10 su nivel de dolor según la Escala Visual Análoga";
    case 2:
      return "2. ¿Cuántas veces va a orinar durante el día?";
    case 3:
      return "3. ¿Cuántas veces va a orinar en la noche luego de haber conciliado el sueño?";
    case 4:
      return "4. ¿Cuándo siente el deseo de orinar debe ir rápidamente o puede aguantar?";
    case 5:
      return "5. ¿Ha presentado usted fiebre superior a 38 °C, asociado con alguno de los síntomas anteriores?";
    case 6:
      return "6. ¿Ha presentado usted sangrado en la orina asociado con alguno de los síntomas anteriores?";
    case 7:
      return "7. ¿Ha presentado usted algún otro síntoma relacionado con los descritos anteriormente?";
    case 71:
      return "7.1. Por favor señale cuál de los siguientes síntomas ha presentado";
    case 8:
      return "8. ¿Ha presentado alguna manifestación o molestia asociado con la instilación de ialuril® Prefill?";
    case 81:
      return "8.1. Por favor señale cuál de los siguientes síntomas ha presentado";
    default:
      return "";
  }
};

const isConsecutive = (numberArray) => {
  var min = Math.min.apply(Math, numberArray);
  numberArray.sort((a, b) => a - b);
  var anyFalse = numberArray
    .map((x, index) => x - min == index)
    .find((x) => x === false);

  return anyFalse === undefined;
};

const validateFilter = (instillations) => {
  if (instillations && instillations.length > 0) {
    const list = instillations.map((x) => x.value);

    var inst = list.filter((x) => !x.includes("T.M.")).map((x) => Number(x));
    if (inst.length > 0 && !isConsecutive(inst)) return false;

    var instM = list
      .filter((x) => x.includes("T.M."))
      .map((x) => Number(x.replace("T.M.", "")));
    if (instM.length > 0 && !isConsecutive(instM)) return false;

    return true;
  }

  return true;
};

const DashboardClinical = () => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const { locale } = useSelector(({ settings }) => settings);
  const currentAppLocale = AppLocale[locale.locale];
  const currentLocale = currentAppLocale.locale;

  const [totalInfo, setTotalInfo] = useState(null);
  const [cycleList, setCycleList] = useState([]);
  const [institutionList, setInstitutionList] = useState([]);
  const [instillationList, setInstillationList] = useState([]);
  const [currentCycle, setCurrentCycle] = useState("Ciclo 1");
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
  });

  const msgFilterNoValid = IntlGetText("patientPA.instillationFilterNoValid");

  const questionList = [
    { id: 1, name: getQuestionTitle(1) },
    { id: 2, name: getQuestionTitle(2) },
    { id: 3, name: getQuestionTitle(3) },
    { id: 4, name: getQuestionTitle(4) },
    { id: 5, name: getQuestionTitle(5) },
    { id: 6, name: getQuestionTitle(6) },
    { id: 7, name: getQuestionTitle(7) },
    { id: 8, name: getQuestionTitle(8) },
  ];

  const contextList = [
    { id: 0, name: IntlGetText("KPIFilters.Institution") },
    { id: 1, name: IntlGetText("KPIFilters.Patient") },
  ];

  const defaultValues = {
    startDate: null,
    endDate: null,
    cycleId: null,
    questionId: null,
    context: null,
  };

  const validationSchema = yup.object().shape({
    startDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate")),
    endDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate")),
    cycleId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    questionId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
  });
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, errors, setValue, trigger, watch } = methods;

  const cycleIdValue = watch("cycleId");
  const questionIdValue = watch("questionId");
  const contextValue = watch("context") ? watch("context") : { id: 1, name: IntlGetText("KPIFilters.Patient") };

  const loadCycleList = (obj, isFirstRender) => {
    callApi(
      () => getCyclesQuery(obj),
      (response) => {
        const cycles = response.cycles.map((x) => ({ id: x, name: x }));
        const instillations = response.instillations.map((x) => ({
          id: x,
          name: x,
        }));

        setCycleList(cycles);
        setInstillationList(instillations);
        setDateFilter(obj);

        if (isFirstRender) {
          setValue("cycleId", getCboValue(cycles, "Ciclo 1"));
          setValue("context", getCboValue(contextList, 1));
          var s = instillations.filter((a, idx) => idx < 3).map((x) => x.id);
          setValue("instillation", getCboValues(instillations, s));
        }
      },
      fnSetLoader
    );
  };

  const loadInstitutionList = () => {
    callApi(
      () => getInstitutionsList(),
      (response) => {
        setInstitutionList(response);
      }
    );
  };


  useEffect(() => {
    callApi(
      () =>
        getDashboardClinical({
          questionNumber: 1,
          instillations: ["1", "2", "3"],
          cycle: 'Ciclo 1',
          contextId: 1,
          institutionId: null,
        }),
      (data) => {
        setValue("questionId", getCboValue(questionList, 1));
        setTotalInfo(data);
        loadCycleList({ startDate: null, endDate: null }, true);
      },
      fnSetLoader
    );
    loadInstitutionList();
  }, []);

  const onFilter = handleSubmit(async (d) => {
    if (d.startDate || d.endDate) {
      if (!(await trigger())) return;
    }
    if (!validateFilter(d.instillation)) {
      NotificationManager.warning(msgFilterNoValid);
      return;
    }

    var obj = {
      questionNumber: !d.questionId ? 1 : Number(d.questionId.value),
      startDate: !d.startDate
        ? null
        : dayjs(d.startDate).format("YYYY-MM-DDT00:00:00"),
      endDate: !d.endDate
        ? null
        : dayjs(d.endDate).format("YYYY-MM-DDT00:00:00"),
      instillations: !d.instillation
        ? null
        : d.instillation.map((x) => x.value),
      cycle: !d.cycleId ? null : d.cycleId.value,
      contextId: d.context.value,
      institutionId: d.institutionId == null ? null : d.institutionId.value,
    };
    callApi(
      () => getDashboardClinical(obj),
      (data) => {
        setTotalInfo(data);
        setCurrentQuestion(questionIdValue.value);
        setCurrentCycle(cycleIdValue.value);

        if (
          obj.startDate !== dateFilter.startDate ||
          obj.endDate !== dateFilter.endDate
        )
          loadCycleList({ startDate: obj.startDate, endDate: obj.endDate });
      },
      fnSetLoader
    );
  });

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
      <FormProvider {...methods}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} lg={3}>
            <FormDatePicker
              name="startDate"
              label={<IntlMessages id="core.startDate" />}
              format="YYYY-MM-DD"
              margin="normal"
              errorobj={errors}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <FormDatePicker
              name="endDate"
              label={<IntlMessages id="core.endDate" />}
              format="YYYY-MM-DD"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <FormSelectAutoComplete
              name="context"
              label={<IntlMessages id="KPIFilters.context" />}
              options={contextList}
              margin="normal"
              required={true}
              errorobj={errors}
            />
          </Grid>
          {contextValue.value !== 0 ? (
            <></>
          ) : (
            <Grid item xs={12} md={3} lg={3}>
              <FormSelectAutoComplete
                name="institutionId"
                label={<IntlMessages id="KPIFilters.Institution" />}
                options={institutionList}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} lg={3}>
            <FormSelectAutoComplete
              name="questionId"
              label={<IntlMessages id="core.question" />}
              options={questionList}
              margin="normal"
              required={true}
              errorobj={errors}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <FormSelectAutoComplete
              name="instillation"
              label={<IntlMessages id="patientPA.instillation" />}
              options={instillationList}
              margin="normal"
              isMulti
              required={true}
              errorobj={errors}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <FormSelectAutoComplete
              name="cycleId"
              label={<IntlMessages id="patientPA.cycle" />}
              options={cycleList}
              margin="normal"
              required={true}
              errorobj={errors}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Button
              onClick={onFilter}
              className="mt-3"
              variant="contained"
              color="primary"
            >
              <IntlMessages id="core.filter" />
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
      {(currentQuestion === 1 ||
        currentQuestion === 5 ||
        currentQuestion === 6 ||
        currentQuestion === 7 ||
        currentQuestion === 8) && (
        <div className="row mt-3">
          <div className="col-xl-12 col-lg-12 col-12">
            <CustomChart
              options={YesNoChart(
                getQuestionTitle(currentQuestion),
                "role.visitor",
                currentLocale,
                getPropArray(totalInfo, "yesNoQuestion"),
                currentCycle,
                currentQuestion,
                getProp(totalInfo, "patientCount")
              )}
            />
          </div>
        </div>
      )}
      {currentQuestion === 1 && (
        <div className="row mt-3">
          <div className="col-xl-12 col-lg-12 col-12">
            <CustomChart
              options={LineChart(
                getQuestionTitle(11),
                "role.visitor",
                currentLocale,
                getPropArray(totalInfo, "question"),
                currentCycle,
                11,
                getProp(totalInfo, "patientCount")
              )}
            />
          </div>
        </div>
      )}
      {(currentQuestion === 2 ||
        currentQuestion === 3 ||
        currentQuestion === 4) && (
        <div className="row mt-3">
          <div className="col-xl-12 col-lg-12 col-12">
            <CustomChart
              options={QuestionChart(
                getQuestionTitle(currentQuestion),
                "role.visitor",
                currentLocale,
                getPropArray(totalInfo, "question"),
                currentCycle,
                currentQuestion,
                getProp(totalInfo, "patientCount")
              )}
            />
          </div>
        </div>
      )}
      {currentQuestion === 7 && (
        <div className="row mt-3">
          <div className="col-xl-12 col-lg-12 col-12">
            <CustomChart
              options={QuestionChart(
                getQuestionTitle(71),
                "role.visitor",
                currentLocale,
                getPropArray(totalInfo, "question"),
                currentCycle,
                71,
                getProp(totalInfo, "patientCount")
              )}
            />
          </div>
        </div>
      )}
      {currentQuestion === 8 && (
        <div className="row mt-3">
          <div className="col-xl-12 col-lg-12 col-12">
            <CustomChart
              options={QuestionChart(
                getQuestionTitle(81),
                "role.visitor",
                currentLocale,
                getPropArray(totalInfo, "question"),
                currentCycle,
                81,
                getProp(totalInfo, "patientCount")
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardClinical;
