import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as dayjs from "dayjs";
import IntlMessages from "util/IntlMessages";
import { Grid } from "@material-ui/core";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import FormDatePicker from "components/Form/Controls/datepicker";
import {
  genderList,
  educationalLevelList,
  CivilStatusList,
  RelationshipList
} from "constants/Enums";
import {
  callActionApi,
  getCitiesByStateProvinceId
} from "api/apiList";


const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const calculateAge = (brithday) =>
  !brithday || !dayjs(brithday).isValid()
    ? ""
    : dayjs().diff(dayjs(brithday), "year");

const TutorInfo = ({
    stateProvinceList,
    documentTypeList,
    tutorData
  }) => {
    const relationshipList = RelationshipList();
    const civilStatusItems = CivilStatusList();
    const genderItems = genderList();
    const educationalLevelItems = educationalLevelList();

    const callApi = (apiFunc, setData) => {
      callActionApi(
        apiFunc,
        setData,
        (error) => {
          if (typeof error === "string") NotificationManager.error(error);
        } //, (data) => dispatch(setLoader(data))
      );
    };
  
    const defaultValues = {
      name: !tutorData.name ? "" : tutorData.name,
      lastName: !tutorData.lastName ? "" : tutorData.lastName,
      birthday: !tutorData
        ? null
        : tutorData.birthday
        ? dayjs(tutorData.birthday)
        : null,
      age: calculateAge(
        !tutorData
          ? dayjs()
          : tutorData.birthday
          ? dayjs(tutorData.birthday)
          : dayjs()
      ),
      birthstateProvinceId: !tutorData.birthstateProvinceId ? "" : tutorData.birthstateProvinceId,
      birthCityId: !tutorData.birthCityId ? "" : tutorData.birthCityId,
      gender: !tutorData
        ? 0
        : isNaN(parseInt(tutorData.gender))
        ? 0
        : getCboValue(genderList(), tutorData.gender),
      documentTypeId: !tutorData.documentTypeId ? "" : tutorData.documentTypeId,
      document: !tutorData.document ? "" : tutorData.document,
      phone: !tutorData.phone ? "" : tutorData.phone,
      cellphone: !tutorData.cellphone ? "" : tutorData.cellphone,
      email: !tutorData.email ? "" : tutorData.email,
      stateProvinceId: !tutorData.stateProvinceId ? "" : tutorData.stateProvinceId,
      cityId: !tutorData.cityId ? "" : tutorData.cityId,
      address: !tutorData.address ? "" : tutorData.address,
      educationalLevel: !tutorData
        ? 0
        : isNaN(parseInt(tutorData.educationalLevel))
        ? 0
        : getCboValue(educationalLevelList(), tutorData.educationalLevel),
      profession: !tutorData.profession ? "" : tutorData.profession,
      civilStatus: !tutorData
        ? ""
        : isNaN(parseInt(tutorData.civilStatus))
        ? 0
        : getCboValue(CivilStatusList(), tutorData.civilStatus),
      relationship: !tutorData
        ? ""
        : isNaN(parseInt(tutorData.relationship))
        ? 0
        : getCboValue(RelationshipList(), tutorData.relationship),
      stratum: !tutorData.stratum ? "" : tutorData.stratum,
    };
    
    const methods = useForm({ defaultValues });
    const { watch, setValue, errors } = methods;
  
    const documentTypeIdValue = watch("documentTypeId");
    const birthstateProvinceValue = watch("birthstateProvinceId");
    const stateProvinceIdValue = watch("stateProvinceId");
    const birthCityIdValue = watch("birthCityId");
    const cityIdValue = watch("cityId");
    const ageValue = watch("age");
  
    const [birthCityList, setBirthCityList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [loadForm, setLoadForm] = useState({
      documentTypeListLoaded: false,
      stateProvinceListLoaded: false,
      birthCityListLoaded: false,
      cityListLoaded: false,
    });

    const loadData = (tutorData) => {
      setValue("name", tutorData.name);
      setValue("lastName", tutorData.lastName);
      setValue("birthday", tutorData.birthday ? dayjs(tutorData.birthday) : null);
      setValue("age", calculateAge(
        tutorData.birthday ? dayjs(tutorData.birthday) : dayjs()
      ));
      setValue("birthstateProvinceId", tutorData.birthstateProvinceId);
      setValue(" birthCityId", tutorData.birthCityId);
      setValue("gender", isNaN(parseInt(tutorData.gender))
        ? 0
        : getCboValue(genderItems, tutorData.gender));
      setValue("documentTypeId", tutorData.documentTypeId);
      setValue("document", !tutorData.document ? "" : tutorData.document);
      setValue("phone", tutorData.phone);
      setValue("cellphone", tutorData.cellphone);
      setValue("email", !tutorData.email ? "" : tutorData.email);
      setValue("stateProvinceId", tutorData.stateProvinceId);
      setValue("cityId", tutorData.cityId);
      setValue("address", !tutorData.address ? "" : tutorData.address);
      setValue("educationalLevel", isNaN(parseInt(tutorData.educationalLevel))
        ? 0
        : getCboValue(educationalLevelItems, tutorData.educationalLevel));
      setValue("profession", !tutorData.profession ? "" : tutorData.profession);
      setValue("civilStatus", isNaN(parseInt(tutorData.civilStatus))
        ? 0
        : getCboValue(civilStatusItems, tutorData.civilStatus));
      setValue("stratum", !tutorData ? "" : tutorData.stratum);
      setValue("relationship", !tutorData
        ? ""
        : isNaN(parseInt(tutorData.relationship))
        ? 0
        : getCboValue(relationshipList, tutorData.relationship));
    };

    useEffect(() => {
      if(tutorData && tutorData.id){
        loadData(tutorData);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tutorData]);
  
  
    useEffect(() => {
      if (!loadForm.stateProvinceListLoaded && stateProvinceList.length > 0) {
        setValue(
          "birthstateProvinceId",
          getCboValue(stateProvinceList, birthstateProvinceValue)
        );
        setValue(
          "stateProvinceId",
          getCboValue(stateProvinceList, stateProvinceIdValue)
        );
        setLoadForm({ ...loadForm, stateProvinceListLoaded: true });
        return;
      }
      if (!loadForm.cityListLoaded && cityList.length > 0) {
        setValue("cityId", getCboValue(cityList, defaultValues.cityId));
        setLoadForm({ ...loadForm, cityListLoaded: true });
        return;
      }
      if (!loadForm.birthCityListLoaded && birthCityList.length > 0) {
        setValue(
          "birthCityId",
          getCboValue(birthCityList, defaultValues.birthCityId)
        );
        setLoadForm({ ...loadForm, birthCityListLoaded: true });
        return;
      }
      if (!loadForm.documentTypeListLoaded && documentTypeList.length > 0) {
        setValue(
          "documentTypeId",
          getCboValue(documentTypeList, documentTypeIdValue)
        );
        setLoadForm({ ...loadForm, documentTypeListLoaded: true });
        return;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityList, birthCityList, stateProvinceList, documentTypeList]);
  
    useEffect(() => {
      setValue("birthCityId", null);
      if (!birthstateProvinceValue || !birthstateProvinceValue.value) {
        setBirthCityList([]);
        return;
      }
      callApi(
        () => getCitiesByStateProvinceId(birthstateProvinceValue.value),
        setBirthCityList
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [birthstateProvinceValue]);
    useEffect(() => {
      setValue("cityId", null);
      if (!stateProvinceIdValue || !stateProvinceIdValue.value) {
        setCityList([]);
        return;
      }
      callApi(
        () => getCitiesByStateProvinceId(stateProvinceIdValue.value),
        setCityList
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateProvinceIdValue]);
  
    const cboHandleChange = (name) => (event) => {
      setValue(name, event);
    };
    const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);
    const dtpHandleChange = (name) => (e) => {
      setValue(name, e);
      setValue("age", calculateAge(e));
    };
  

    var disabled = true;
    return (
      <div className="col-xl-12 col-lg-12 col-md-12 col-12">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="name"
                  label={<IntlMessages id="core.name" />}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="lastName"
                  label={<IntlMessages id="core.lastName" />}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormDatePicker
                  name="birthday"
                  label={<IntlMessages id="core.birthday" />}
                  format="YYYY-MM-DD"
                  margin="normal"
                  value={watch("birthday")}
                  onChange={dtpHandleChange("birthday")}
                  disableFuture={true}
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
  
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="age"
                  label={<IntlMessages id="core.age" />}
                  value={ageValue}
                  onChange={(value) => {
                    setValue("age", value);
                  }}
                  disabled={true}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="birthstateProvinceId"
                  label={<IntlMessages id="core.birthstateProvince" />}
                  options={stateProvinceList}
                  value={birthstateProvinceValue}
                  onChange={cboHandleChange("birthstateProvinceId")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="birthCityId"
                  label={<IntlMessages id="core.birthCity" />}
                  options={birthCityList}
                  value={birthCityIdValue}
                  onChange={cboHandleChange("birthCityId")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="gender"
                  label={<IntlMessages id="core.gender" />}
                  options={genderList()}
                  value={watch("gender")}
                  onChange={cboHandleChange("gender")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="civilStatus"
                  label={<IntlMessages id="core.civilStatus" />}
                  options={CivilStatusList()}
                  value={watch("civilStatus")}
                  onChange={cboHandleChange("civilStatus")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="documentTypeId"
                  label={<IntlMessages id="core.documentType" />}
                  options={documentTypeList}
                  value={documentTypeIdValue}
                  onChange={cboHandleChange("documentTypeId")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="document"
                  label={<IntlMessages id="core.document" />}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="phone"
                  label={<IntlMessages id="core.phone" />}
                  margin="normal"
                  disabled={disabled}
                  required={false}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="cellphone"
                  label={<IntlMessages id="core.cellphone" />}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormInput
                  name="email"
                  label={<IntlMessages id="core.email" />}
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="address"
                  label={<IntlMessages id="core.address" />}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="stateProvinceId"
                  label={<IntlMessages id="core.stateProvince" />}
                  options={stateProvinceList}
                  value={stateProvinceIdValue}
                  onChange={cboHandleChange("tstateProvinceId")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="cityId"
                  label={<IntlMessages id="core.city" />}
                  options={cityList}
                  value={cityIdValue}
                  onChange={cboHandleChange("cityId")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="educationalLevel"
                  label={<IntlMessages id="core.educationalLevel" />}
                  options={educationalLevelList()}
                  value={watch("educationalLevel")}
                  onChange={cboHandleChange("educationalLevel")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="profession"
                  label={<IntlMessages id="core.profession" />}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="relationship"
                  label={<IntlMessages id="core.relationship" />}
                  options={RelationshipList()}
                  value={watch("relationship")}
                  onChange={cboHandleChange("relationship")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
    );
  };

export default TutorInfo;