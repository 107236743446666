import React, { useState } from "react";
import { useSelector } from "react-redux";
import ProcessTable from "./ProcessTable";
import DoctorTable from "./DoctorTable";
import CardBox from "components/CardBox/index";
import Auxiliary from "../../../../../util/Auxiliary";
import Widget from "components/Widget";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { contactList } from "./../data";
import NewPatientDialog from "./NewPatientDialog";

const DoctorProfile = () => {
  const [value, setValue] = useState(0);
  const { userInfo } = useSelector(({ auth }) => auth);

  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <div className="app-wrapper">
      <Auxiliary>
        <div className="jr-profile-banner">
          <div className="jr-profile-container">
            <div
              className="jr-profile-banner-top"
              style={{ marginBottom: "0px" }}
            >
              <div className="jr-profile-banner-top-left">
                <div className="jr-profile-banner-avatar-info">
                  <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                    Dra. Julia Ramirez
                  </h2>
                  <p className="mb-0 jr-fs-lg">Oncología</p>
                </div>
              </div>
              <div className="jr-profile-banner-top-right">
                <ul className="jr-follower-list">
                  <li className="mt-1">
                    {userInfo.role === "doctor" && <NewPatientDialog />}
                  </li>
                  <li>
                    <span className="jr-follower-title jr-fs-lg jr-font-weight-medium">
                      7
                    </span>
                    <span className="jr-fs-sm">Pacientes</span>
                  </li>
                  <li>
                    <span className="jr-follower-title jr-fs-lg jr-font-weight-medium">
                      1
                    </span>
                    <span className="jr-fs-sm">Proceso</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="jr-profile-content">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-7 col-12">
              <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
                <div className="card-header">
                  <h4 className="card-title mb-0">Información</h4>
                </div>
                <div className="jr-tabs-classic">
                  <Tabs
                    className="jr-tabs-up"
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab className="jr-tabs-label" label="Procesos" />
                    <Tab className="jr-tabs-label" label="Pacientes" />
                  </Tabs>
                  <div className="jr-tabs-content jr-task-list">
                    <div className="row">
                      {value === 0 && (
                        <CardBox
                          styleName="col-12"
                          cardStyle="p-0"
                          headerOutside
                        >
                          <ProcessTable />
                        </CardBox>
                      )}
                      {value === 1 && (
                        <CardBox
                          styleName="col-12"
                          cardStyle="p-0"
                          headerOutside
                        >
                          <DoctorTable />
                        </CardBox>
                      )}
                    </div>
                  </div>
                </div>
              </Widget>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-5 col-12">
              <Widget
                title="Información de contacto"
                styleName="jr-card-profile-sm"
              >
                {contactList.map((data, index) => (
                  <div
                    key={index}
                    className="media align-items-center flex-nowrap jr-pro-contact-list"
                  >
                    <div className="mr-3">
                      <i
                        className={`zmdi zmdi-${data.icon} jr-fs-xxl text-grey`}
                      />
                    </div>
                    <div className="media-body">
                      <span className="mb-0 text-grey jr-fs-sm">
                        {data.title}
                      </span>
                      <p className="mb-0">{data.desc}</p>
                    </div>
                  </div>
                ))}
              </Widget>
            </div>
          </div>
        </div>
      </Auxiliary>
    </div>
  );
};

export default DoctorProfile;
