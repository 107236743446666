import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormDatePicker from "components/Form/Controls/datepicker";
import CardFileUpload from "components/Form/CardFileUpload";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  callActionApi,
  getPharmacyByMarketChain,
  postAddFileInsuranceAuthorization,
  postAddPharmacy,
} from "api/apiList";
import { RadicadoInsuranceState } from "constants/Enums";
import FormAddNewPharmacy from "./FormAddNewPharmacy"

const getMedicineAmount = (authorizations, prescriptionMedicineAmount) => {
  var authorized = authorizations
    .filter(
      (x) =>
        x.radicadoInsuranceState !==
          RadicadoInsuranceState.authorizationWithInconsistencies &&
        x.radicadoInsuranceState !== RadicadoInsuranceState.noAuthorized
    )
    .map((item) => item.medicineAmount)
    .reduce((prev, curr) => prev + curr, 0);

  return prescriptionMedicineAmount - authorized;
};

const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const FormCRU = ({
  rowObj,
  impactId,
  openCRU,
  handleRequestClose,
  marketChainList,
  handleCreateUpdate,
  authorizations,
  setAuthorizations,
}) => {
  const { selectedPrescription } = useSelector(({ management }) => management);

  const [formLoaded, setFormLoaded] = useState(false);
  const [visitTargetList, setVisitTargetList] = useState([]);
  const [file, setFile] = useState(null);

  const [isAddPharmacy, setIsAddPharmacy] = useState(false);
  const [openAddNewPharmacy, setOpenAddNewPharmacy] = useState(false);

  const defaultValues = {
    marketChainId: "",
    visitTargetId: "",
    authorizationDate: null,
    code: "",
    medicineAmount: "",
    startDate: null,
    expirationDate: null,
    newPharmacyName: "",
  };

  const validationSchema = yup.object().shape({
    marketChainId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    visitTargetId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    authorizationDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate")),
      //.required(<IntlMessages id="core.fieldRequired" />),
    code: yup
      .string()
      .required(<IntlMessages id="core.fieldRequired" />)
      .matches(/^[a-z0-9_-]+$/i, IntlGetText("yup.alphanumericValidation")),
    medicineAmount: yup
      .number()
      .typeError(IntlGetText("yup.numericValidation"))
      .min(0, IntlGetText("yup.numericValidation"))
      .max(50000, IntlGetText("yup.invalidInput"))
      .required(<IntlMessages id="core.fieldRequired" />),
    startDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    expirationDate: yup
      .date()
      .nullable(true)
      .min(
        yup.ref("startDate"),
        IntlGetText("insuranceAuthorization.expirationDateValid")
      )
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    newPharmacyName: yup.string().when("marketChainId", (marketChainId) => {
      if (isAddPharmacy) {
        return yup.string().required(<IntlMessages id="core.fieldRequired" />);
      }
    }),
  });
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, watch, setValue, reset, errors, getValues } = methods;

  const marketChainIdValue = watch("marketChainId");
  const newPharmacyNameValue = watch("newPharmacyName");

  useEffect(() => {
    if (!openCRU) {
      setFormLoaded(false);
    } else {
      setTimeout(loadData, 150);
    }
  }, [openCRU]);

  const loadData = () => {
    setValue(
      "marketChainId",
      !rowObj ? "" : getCboValue(marketChainList, rowObj.marketChainId)
    );
    setValue("visitTargetId", !rowObj ? "" : rowObj.visitTargetId);
    setValue("authorizationDate", !rowObj || !rowObj.authorizationDate ? null : dayjs(rowObj.authorizationDate));
    setValue("code", !rowObj ? "" : rowObj.code);
    setValue(
      "medicineAmount",
      !rowObj
        ? getMedicineAmount(authorizations, selectedPrescription.medicineAmount)
        : rowObj.medicineAmount
    );
    setValue("startDate", !rowObj ? null : rowObj.startDate);
    setValue("expirationDate", !rowObj ? null : rowObj.expirationDate);
    setFile(!rowObj ? null : rowObj.file);
  };

  useEffect(() => {
    setValue("visitTargetId", null);
    if (!marketChainIdValue || !marketChainIdValue.value) {
      setVisitTargetList([]);
      return;
    }
    callActionApi(
      () => getPharmacyByMarketChain(marketChainIdValue.value),
      setVisitTargetList,
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketChainIdValue]);

  useEffect(() => {
    if (!formLoaded && visitTargetList.length > 0) {
      setValue(
        "visitTargetId",
        getCboValue(visitTargetList, !rowObj ? "" : rowObj.visitTargetId)
      );
      setFormLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitTargetList]);

  const cboHandleChange = (name) => (event) => setValue(name, event);

  const updateFileInObj = (objId, file) => {
    let index = authorizations.findIndex((x) => x.id === objId);
    let data = [...authorizations];
    data[index] = { ...data[index], file };

    setAuthorizations(data);
  };

  const onSubmit = async (d, e) => {
    var obj = {
      id: !rowObj ? 0 : rowObj.id,
      impactId: impactId,
      marketChainId: Number(d.marketChainId.value),
      visitTargetId: Number(d.visitTargetId.value),
      authorizationDate: !d.authorizationDate ? null : dayjs(d.authorizationDate).format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      code: d.code,
      medicineAmount: Number(d.medicineAmount),
      startDate: dayjs(d.startDate).format("YYYY-MM-DDTHH:mm:ss"),
      expirationDate: dayjs(d.expirationDate).format("YYYY-MM-DDTHH:mm:ss"),
    };

    await handleCreateUpdate(obj);
  };

  const handleNewPharmacy = () => {
    if (!isAddPharmacy) {
      setIsAddPharmacy(true);
      return;
    }
    if (!newPharmacyNameValue) return;
    var obj = {
      marketChainId: marketChainIdValue.value,
      name: newPharmacyNameValue,
    };

    callActionApi(
      () => postAddPharmacy(obj),
      (data) => {
        setIsAddPharmacy(false);
        setVisitTargetList([...visitTargetList, data]);
        setValue("visitTargetId", { label: data.name, value: data.id });
      },
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  };

  const handleRequestCloseAddNewPharmacy = () => {
    setOpenAddNewPharmacy(false);
  };

  const setValueNamePharmacy = (data) => {
    setValue("visitTargetId", { label: data.name, value: data.id });
  };

  const disabled = rowObj && rowObj.hasRadicacionesOLF;

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!rowObj ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormSelectAutoComplete
                    name="marketChainId"
                    label={
                      <IntlMessages id="insuranceAuthorization.marketChain" />
                    }
                    options={!marketChainList ? [] : marketChainList}
                    value={marketChainIdValue}
                    onChange={cboHandleChange("marketChainId")}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} sm={10} md={10} lg={4}>
                  {!isAddPharmacy && (
                    <FormSelectAutoComplete
                      name="visitTargetId"
                      label={
                        <IntlMessages id="insuranceAuthorization.marketChainBranch" />
                      }
                      options={visitTargetList}
                      margin="normal"
                      disabled={disabled}
                      required={true}
                      errorobj={errors}
                    />
                  )}
                </Grid>
                {!disabled && marketChainIdValue && marketChainIdValue.value && (
                  <Grid item xs={12} sm={2} md={2} lg={2}>
                    <div className="mt-3">
                      <IconButton
                        type="button"
                        className="icon-btn"
                        color="primary"
                        onClick={() => setOpenAddNewPharmacy(true)}
                      >
                        <i className={"zmdi zmdi-plus-circle zmdi-hc-fw"}/>
                      </IconButton>
                    </div>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <FormDatePicker
                    name="authorizationDate"
                    label={
                      <IntlMessages id="insuranceAuthorization.authorizationDate" />
                    }
                    format="YYYY-MM-DD"
                    disableFuture={true}
                    margin="normal"
                    disabled={disabled}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <FormInput
                    name="code"
                    label={<IntlMessages id="insuranceAuthorization.code" />}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="medicineAmount"
                    label={
                      <IntlMessages id="insuranceAuthorization.medicineAmount" />
                    }
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <FormDatePicker
                    name="startDate"
                    label={
                      <IntlMessages id="insuranceAuthorization.startDate" />
                    }
                    format="YYYY-MM-DD"
                    //disablePast={true}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <FormDatePicker
                    name="expirationDate"
                    label={
                      <IntlMessages id="insuranceAuthorization.expirationDate" />
                    }
                    format="YYYY-MM-DD"
                    //disablePast={true}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          {!disabled && (
            <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
              <ButtonAsync onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
                disabled={isAddPharmacy}
              >
                {<IntlMessages id="core.save" />}
              </ButtonAsync>
            </div>
          )}
          {rowObj && (
            <CardFileUpload
              disabled={disabled}
              file={file}
              setFile={(file) => {
                setFile(file);
                updateFileInObj(rowObj.id, file);
              }}
              actionApi={postAddFileInsuranceAuthorization}
              actionApiInfo={{ impactId, id: rowObj.id }}
            />
          )}
        </div>
      
      </DialogContent>
      <FormAddNewPharmacy
            openCRU={openAddNewPharmacy}
            handleRequestClose={handleRequestCloseAddNewPharmacy}
            marketChainIdValue = {marketChainIdValue}
            setVisitTargetList={setVisitTargetList}
            visitTargetList={visitTargetList}
            setValueNamePharmacy= {setValueNamePharmacy}
          />
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormCRU;
