import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

export const doctorColumns = [
  {
    name: "name",
    label: "Nombre",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "company",
    label: "Institución",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "city",
    label: "Ciudad",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "specialty",
    label: "Especialidad",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "email",
    label: "Email",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "phone",
    label: "Teléfono",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "numProcess",
    label: "Procesos",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "numPatient",
    label: "Pacientes",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "actions",
    label: "Acciones",
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const doctors = [
  {
    name: "Victoria Lopez",
    company: "Mederi",
    city: "Bogotá",
    specialty: "Oncología",
    email: "vlopez@ips.com",
    phone: "3152589631",
    numProcess: "1",
    numPatient: "7",
    actions: (
      <Button component={Link} to={"/app/doctor/detail"} color="primary">
        <i class="zmdi zmdi-eye"></i>
      </Button>
    ),
  },
];

export const patientColumns = [
  {
    name: "code",
    label: "Código",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "name",
    label: "Nombre",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "sex",
    label: "Sexo",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "city",
    label: "Ciudad",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "eps",
    label: "EPS",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "doctor",
    label: "Médico tratante",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "product",
    label: "Línea de producto",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "status",
    label: "Estado",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "actions",
    label: "Acciones",
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const patients = [
  {
    code: "RM0001",
    name: "Alicia Muñoz Diaz",
    sex: "F",
    city: "Bogotá",
    eps: "COMPENSAR",
    doctor: "Julia Ramirez",
    product: (
      <div class=" badge text-uppercase text-white bg-pink">Remodulin</div>
    ),
    status: <div class=" badge text-uppercase text-white bg-green">Activo</div>,
    actions: (
      <Button component={Link} to={"/app/patient0/detail"} color="primary">
        <i class="zmdi zmdi-eye"></i>
      </Button>
    ),
  },
  {
    code: "IC0102",
    name: "Rosa González",
    sex: "F",
    city: "Bucaramanga",
    eps: "COOSALUD",
    doctor: "Carlos Rios",
    product: (
      <div class=" badge text-uppercase text-white bg-blue">Iclusig</div>
    ),
    status: <div class=" badge text-uppercase text-white bg-green">Activo</div>,
    actions: (
      <Button component={Link} to={"/app/patient0/detail"} color="primary">
        <i class="zmdi zmdi-eye"></i>
      </Button>
    ),
  },
  {
    code: "RM0016",
    name: "Miriam Saavedra",
    sex: "F",
    city: "Soacha",
    eps: "COMPENSAR",
    doctor: "Mauricio Orozco",
    product: (
      <div class=" badge text-uppercase text-white bg-pink">Remodulin</div>
    ),
    status: <div class=" badge text-uppercase text-white bg-green">Activo</div>,
    actions: (
      <Button component={Link} to={"/app/patient0/detail"} color="primary">
        <i class="zmdi zmdi-eye"></i>
      </Button>
    ),
  },
  {
    code: "IC0104",
    name: "Pedro Sánchez",
    sex: "M",
    city: "Bogotá",
    eps: "ECOPETROL",
    doctor: "Jhon Jairo Araujo",
    product: (
      <div class=" badge text-uppercase text-white bg-blue">Iclusig</div>
    ),
    status: <div class=" badge text-uppercase text-white bg-green">Activo</div>,
    actions: (
      <Button component={Link} to={"/app/patient0/detail"} color="primary">
        <i class="zmdi zmdi-eye"></i>
      </Button>
    ),
  },
  {
    code: "RM0038",
    name: "Alonso Escorcia",
    sex: "M",
    city: "Bucaramanga",
    eps: "SANITAS",
    doctor: "Monica Osorio",
    product: (
      <div class=" badge text-uppercase text-white bg-pink">Remodulin</div>
    ),
    status: <div class=" badge text-uppercase text-white bg-green">Activo</div>,
    actions: (
      <Button component={Link} to={"/app/patient0/detail"} color="primary">
        <i class="zmdi zmdi-eye"></i>
      </Button>
    ),
  },
  {
    code: "RM0068",
    name: "Milena Martínez",
    sex: "F",
    city: "Bogotá",
    eps: "SANITAS",
    doctor: "José Luis Blanco",
    product: (
      <div class=" badge text-uppercase text-white bg-pink">Remodulin</div>
    ),
    status: <div class=" badge text-uppercase text-white bg-green">Activo</div>,
    actions: (
      <Button component={Link} to={"/app/patient0/detail"} color="primary">
        <i class="zmdi zmdi-eye"></i>
      </Button>
    ),
  },
  {
    code: "IC0023",
    name: "Francisco Hernández",
    sex: "M",
    city: "Bucaramanga",
    eps: "SURA",
    doctor: "Ernesto Santiago",
    product: (
      <div class=" badge text-uppercase text-white bg-blue">Iclusig</div>
    ),
    status: <div class=" badge text-uppercase text-white bg-green">Activo</div>,
    actions: (
      <Button component={Link} to={"/app/patient0/detail"} color="primary">
        <i class="zmdi zmdi-eye"></i>
      </Button>
    ),
  },
];

export const datatableConfig = {
  textLabels: {
    body: {
      noMatch: "No hay resultados para la búsqueda actual",
      toolTip: "Ordenar",
      columnHeaderTooltip: (column) => `Ordernar por ${column.label}`,
    },
    pagination: {
      next: "Siguiente",
      previous: "Anterior",
      rowsPerPage: "Items por pagina:",
      displayRows: "de",
    },
    toolbar: {
      search: "Busqueda",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtro",
    },
    filter: {
      all: "Todos",
      title: "FILTRO",
      reset: "LIMPIAR",
    },
    viewColumns: {
      title: "Mostrar columnas",
      titleAria: "Mostrar/Ocultar columnas",
    },
    selectedRows: {
      text: "item(s) seleccionados",
      delete: "Borrar",
      deleteAria: "Borrar filas seleccionadas",
    },
  },
};
