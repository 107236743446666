import React, { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import * as dayjs from "dayjs";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormDateTimePicker from "components/Form/Controls/dateTimePicker";
import ButtonAsync from "components/Form/Controls/ButtonAsync";

const isValidUrl = (url) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

const FormCreateUpdate = ({ openCRU, handleRequestClose, obj, prescriptionId, handleCreateUpdate, readOnly}) => {
  const urlValidationText = IntlGetText("patientPA.linkValidation");
  const commentRequiredText = IntlGetText("core.commentRequired");  
  
  const defaultValues = {    
    planningDate: null,
    startDate: null,
    endDate: null,
    link: "",
    nursingNote: ""
  };
  const validationSchema = {
    startDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />)
  };

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, errors } = methods;

  const setString = (obj, prop) => setValue(prop, !obj || !obj[prop] ? "" : obj[prop]);
  const setDate = (obj, prop) => setValue(prop, !obj || !obj[prop] ? null : dayjs(obj[prop]));

  const loadData = () => {
    setDate(obj,"planningDate");
    setDate(obj,"startDate");
    setDate(obj,"endDate");
    setString(obj,"link");
    setString(obj,"nursingNote");
  };

  useEffect(()=>{
    if(openCRU){
      setTimeout(loadData,150);
    }
  },[openCRU]);


  const onSubmit = async (d, e) => {
    var item = {
      id: !obj ? 0 : obj.id,
      prescriptionId: prescriptionId,
      planningDate: !d.planningDate ? null : dayjs(d.planningDate).format("YYYY-MM-DDTHH:mm:ss"),
      startDate: !d.startDate ? null : dayjs(d.startDate).format("YYYY-MM-DDTHH:mm:ss"),
      endDate: !d.endDate ? null : dayjs(d.endDate).format("YYYY-MM-DDTHH:mm:ss"),
      link: d.link,
      nursingNote: d.nursingNote
    };

    if(item.link && !isValidUrl(item.link)){
      NotificationManager.warning(urlValidationText);
      return;
    }
    if(d.endDate && (!item.nursingNote || item.nursingNote.trim().length === 0)){
      NotificationManager.warning(commentRequiredText);
      return;
    }

    await handleCreateUpdate(item);
  };

  const isDisable = () =>{
    if(!obj) return false;
    return !(obj.endDate === undefined || obj.endDate === null);
  }
  const disabled = isDisable();
  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!obj ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={1}>
                {obj && obj.planningDate &&
                  <Grid item xs={12} md={12} lg={12}>
                    <FormDateTimePicker
                      name="planningDate"
                      label={<IntlMessages id="psp.menu.impactPlanning" />}
                      format="YYYY-MM-DD hh:mm A"
                      //disableFuture={true}
                      disablePast={true}
                      margin="normal"
                      disabled={disabled}
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                }
                <Grid item xs={12} md={12} lg={12}>
                  <FormDateTimePicker
                    name="startDate"
                    label={<IntlMessages id="core.startDate" />}
                    format="YYYY-MM-DD hh:mm A"
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormDateTimePicker
                    name="endDate"
                    label={<IntlMessages id="core.endDate" />}
                    format="YYYY-MM-DD hh:mm A"
                    margin="normal"
                    disabled={disabled}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <FormInput
                    name="link"
                    label={<IntlMessages id="patientPA.link" />}
                    margin="normal"
                    errorobj={errors}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    name="nursingNote"
                    label={<IntlMessages id="core.comment" />}
                    margin="normal"
                    multiline
                    rows={4}
                    disabled={disabled}
                    errorobj={errors}
                    />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
            <ButtonAsync onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
            >
              {<IntlMessages id="core.save" />}
            </ButtonAsync>
          </div>
        </div>  
      </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>  
  );
};
export default FormCreateUpdate;
