import React, { useState } from "react";
import { IntlGetText } from "util/IntlMessages";
import InfoCard from "components/InfoCard/index";
import AddPatient from "../prescriptions/addPatient";

const PatientBox = (props) => {
  const [openDlgPatient, setOpenDlgPatient] = useState(false);

  return (
    <>
      <InfoCard
        data={{
          icon: "book",
          title: IntlGetText("core.data"),
          subTitle: IntlGetText("core.patient"),
          color: "#757575",
        }}
        onClickBox={()=>setOpenDlgPatient(true)}
      />
      <AddPatient 
        openDlg={openDlgPatient} 
        setOpenDlg={setOpenDlgPatient}
        closeDialog = {()=> setOpenDlgPatient(false)}
        {...props}
      />
    </>
  );
}

export default PatientBox;
