import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import FormSelectAutoComplete, {
  getCboValue
} from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import FormDatePicker from "components/Form/Controls/datepicker";
import {
  callApi,
  callApiAsync,
  getDiseasesByProductId,
  getDoctorList,
  postSaveHealthData,
  postFormulaFile,
  getCitiesByStateProvinceId,
  getAllStateProvince,
  getGetHealthDataByPrescriptionId
} from "api/apiList";
import {
  setFormFormulaDone,
  setFormulaMedicineQuantity,
  callReloadConsumptionTracing
} from "actions/Impact";
import { setLoader } from "actions/Base";

import {
  PharmaceuticalUnitList,
  SupplyFrequencyList,
  DurationTreatmentList,
  TreatmentTypeList
} from "constants/Enums";
import CardFileUpload from "components/Form/CardFileUpload";

const ReduceFormula = ({
  currentPrescriptionId,
  openDlg,
  handleRequestClose
}) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [formulaData, setFormulaData] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [visitTargetIdList, setVisitTargetIdList] = useState([]);
  const [diseaseList, setDiseaseList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [unitDurationList, setUnitDurationList] = useState([]);
  const [dosageUnitList, setDosageUnitList] = useState([]);
  const [attentionScopeList, setAttentionScopeList] = useState([]);
  const [loadForm, setLoadForm] = useState({
    stateProvinceListLoaded: false,
    cityListLoaded: false,
    visitTargetIdListLoaded: false,
    diseaseListLoaded: false
  });
  const [stateProvinceList, setStateProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [file, setFile] = useState("");
  const [treatmentType, setTreatmentType] = useState(null);
  const supplyFrequencyList = SupplyFrequencyList();

  const msgSuccess = IntlGetText("core.SuccessSave");

  const defaultValues = {
    companyId: null,
    visitTargetId: null,
    cityId: null,
    stateProvinceId: null,
    diseaseId: null,
    productId: null,
    medicineAmount: "",
    additionalGuidelines: "",
    pharmaceuticalQuantity: "",
    pharmaceuticalUnit: "",
    dosageUnitId: "",
    quantity: "",
    supplyFrequency: "",
    durationTreatment: "",
    unitTime: "",
    period: "",
    deliveryRecord: null,
    treatmentType: null,
    profilaxis: "",
    attentionScopeId: ""
  };

  const validationSchema = {
    expeditionDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    companyId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    stateProvinceId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    cityId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    visitTargetId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    diseaseId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    productId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    durationTreatment: yup
      .number()
      .nullable(true)
      .typeError(<IntlMessages id="core.invalidNumber" />)
      .required(<IntlMessages id="core.fieldRequired" />),
    period: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    supplyFrequency: yup
      .number()
      .nullable(true)
      .typeError(<IntlMessages id="core.invalidNumber" />)
      .required(<IntlMessages id="core.fieldRequired" />),
    unitTime: yup
      .object()
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    attentionScopeId: yup
      .object()
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    deliveryRecord: yup
      .number()
      .required(<IntlMessages id="core.fieldRequired" />)
      .typeError(<IntlMessages id="core.invalidNumber" />)
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema))
  });
  const { handleSubmit, watch, setValue, errors } = methods;

  const productIdValue = watch("productId");
  const medicineAmountValue = watch("medicineAmount");
  const stateProvinceIdValue = watch("stateProvinceId");
  const attentionScopeIdValue = watch("attentionScopeId");
  const cityIdValue = watch("cityId");
  const treatmentList = TreatmentTypeList();
  const getDInfo = (formulaData, prop) => {
    if (!formulaData) return "";

    return formulaData.dosage[prop] ? formulaData.dosage[prop] : "";
  };

  const loadData = () => {
    callApi(
      () => getGetHealthDataByPrescriptionId(currentPrescriptionId),
      (data) => {
        setFormulaData(data);
        setFile(data.file);
        setCompanyList(data.companyList);
        setProductList(data.productList);
        setUnitList(data.dosageUnitTimeList);
        setUnitDurationList(data.dosageDurationUnitTimeList);
        setAttentionScopeList(data.attentionScopeList);
        const dosageUList = !data.dosageUnitList ? [] : data.dosageUnitList;
        setDosageUnitList(dosageUList);

        setValue(
          "medicineAmount",
          !data.medicineAmount ? "" : data.medicineAmount
        );
        setValue(
          "attentionScopeId",
          getCboValue(data.attentionScopeList, data.attentionScopeId)
        );
        setValue(
          "additionalGuidelines",
          !data.additionalGuidelines ? "" : data.additionalGuidelines
        );
        setValue(
          "treatmentType",
          getCboValue(treatmentList, data.treatmentType)
        );
        setTreatmentType(data.treatmentType);
        setValue("companyId", getCboValue(data.companyList, data.companyId));
        setValue("productId", getCboValue(data.productList, data.productId));
        setValue("code", data.code);
        setValue(
          "expeditionDate",
          !data || !data.expeditionDate ? null : dayjs(data.expeditionDate)
        );
        setValue("durationTreatment", data.durationTreatment);
        setValue("supplyFrequency", data.supplyFrequency);
        setValue(
          "unitTime",
          getCboValue(data.dosageUnitTimeList, data.durationTreatmentUnitId)
        );
        setValue(
          "period",
          getCboValue(
            data.dosageDurationUnitTimeList,
            data.supplyFrequencyUnitId
          )
        );
        //Dosage
        setValue(
          "dosageUnitId",
          getCboValue(dosageUList, getDInfo(data, "dosageUnitId"))
        );
        setValue("quantity", getDInfo(data, "quantity"));

        setValue(
          "stateProvinceId",
          getCboValue(data.stateProvinceList, data.stateProvinceId)
        );
        setValue("deliveryRecord", data.numberDeliveries);
        setValue("profilaxis", data.profilaxis);
      },
      fnSetLoader
    );
    callApi(getDoctorList, setVisitTargetIdList);
  };
  useEffect(() => {
    callApi(getAllStateProvince, setStateProvinceList);
  }, []);

  useEffect(() => {
    if (openDlg) loadData();
  }, [openDlg]);

  useEffect(() => {
    setValue("diseaseId", null);
    if (!productIdValue || !productIdValue.value) {
      setDiseaseList([]);
      return;
    }
    callApi(() => getDiseasesByProductId(productIdValue.value), setDiseaseList);

    var product = productList.find((x) => x.id == productIdValue.value);
    setValue(
      "pharmaceuticalUnit",
      getCboValue(PharmaceuticalUnitList(), product.pharmaceuticalUnit)
    );

    // setValue(
    //   "dosageUnitId",
    //   getCboValue(formulaData.dosageUnitList, product.dosageUnitId)
    // );

    updatePharmaceuticalQuantity();
  }, [productIdValue]);

  useEffect(() => {
    if (
      formulaData &&
      !loadForm.visitTargetIdListLoaded &&
      visitTargetIdList.length > 0
    ) {
      setValue(
        "visitTargetId",
        getCboValue(visitTargetIdList, formulaData.visitTargetId)
      );
      setLoadForm({ ...loadForm, visitTargetIdListLoaded: true });
    }
  }, [formulaData, visitTargetIdList]);

  useEffect(() => {
    if (formulaData && !loadForm.diseaseListLoaded && diseaseList.length > 0) {
      setValue("diseaseId", getCboValue(diseaseList, formulaData.diseaseId));
      setLoadForm({ ...loadForm, diseaseListLoaded: true });
      const selectedDisease = diseaseList.find(
        (disease) => disease.id === formulaData.diseaseId
      );
      setValue("offlabel", selectedDisease.offlabel ? "Si" : "No");
    }
    if (!loadForm.cityListLoaded && cityList.length > 0) {
      setValue("cityId", getCboValue(cityList, formulaData.cityId));
      setLoadForm({ ...loadForm, cityListLoaded: true });
      return;
    }
  }, [diseaseList, stateProvinceList, cityList]);

  const updatePharmaceuticalQuantity = () => {
    if (!productIdValue || !productIdValue.value || !medicineAmountValue) {
      setValue("pharmaceuticalQuantity", 0);
      return;
    }

    const product = productList.find((x) => x.id == productIdValue.value);

    setValue(
      "pharmaceuticalQuantity",
      product.pharmaceuticalQuantity * medicineAmountValue
    );
  };

  const cboTreatmentTypeChange = (name) => (event) => {
    setValue(name, event);
    setValue("profilaxis", "");
    setTreatmentType(event ? event.value : null);
  };
  const cboDiseaseChange = (name) => (event) => {
    setValue(name, event);
    const selectedDisease = diseaseList.find(
      (disease) => disease.id === event.value
    );
    setValue("offlabel", selectedDisease.offlabel ? "Si" : "No");
  };

  useEffect(() => {
    setValue("cityId", null);
    if (!stateProvinceIdValue || !stateProvinceIdValue.value) {
      setCityList([]);
      return;
    }
    callApi(
      () => getCitiesByStateProvinceId(stateProvinceIdValue.value),
      setCityList
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProvinceIdValue]);

  useEffect(() => updatePharmaceuticalQuantity(), [medicineAmountValue]);

  const onSubmit = async (d, e) => {
    const obj = {
      id: formulaData.id,
      impactId: formulaData.impactId,
      productId: Number(d.productId.value),
      diseaseId: Number(d.diseaseId.value),
      cityId: Number(d.cityId.value),
      visitTargetId: Number(d.visitTargetId.value),
      companyId: Number(d.companyId.value),
      attentionScopeId: d.attentionScopeId
        ? Number(d.attentionScopeId.value)
        : null,
      durationTreatment: Number(d.durationTreatment),
      medicineAmount: !d.medicineAmount ? null : Number(d.medicineAmount),
      additionalGuidelines: d.additionalGuidelines,
      code: d.code,
      expeditionDate:
        d.expeditionDate != null
          ? dayjs(d.expeditionDate).format("YYYY-MM-DDTHH:mm:ss")
          : null,

      dosageId: Number(getDInfo(formulaData, "id")),
      dosageQuantity: Number(d.quantity),
      supplyFrequency: Number(d.supplyFrequency),
      durationTreatmentUnitId: Number(d.unitTime.value),
      supplyFrequencyUnitId: Number(d.period.value),
      numberDeliveries: Number(d.deliveryRecord),
      treatmentType: d.treatmentType ? Number(d.treatmentType.value) : null,
      profilaxis: d.profilaxis
    };
    await callApiAsync(
      () => postSaveHealthData(obj),
      (data) => {
        NotificationManager.success(msgSuccess);
        dispatch(setFormFormulaDone(data.expeditionDate));
        dispatch(setFormulaMedicineQuantity(data.medicineQuantity));
        dispatch(callReloadConsumptionTracing(true));
        handleRequestClose();
      },
      fnSetLoader
    );
  };

  const cboHandleChange = (name) => (event) => {
    setValue(name, event);
  };

  return (
    <Dialog
      open={openDlg}
      maxWidth="md"
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id="approveFormula.ReduceFormula" />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="code"
                    label="Nro. Prescripción"
                    margin="normal"
                    errorobj={errors}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormDatePicker
                    name="expeditionDate"
                    label="Fecha de Expedición"
                    margin="normal"
                    required={true}
                    errorobj={errors}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="stateProvinceId"
                    label={<IntlMessages id="core.stateProvince" />}
                    options={stateProvinceList}
                    value={stateProvinceIdValue}
                    onChange={cboHandleChange("stateProvinceId")}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="cityId"
                    label={<IntlMessages id="core.city" />}
                    options={cityList}
                    value={cityIdValue}
                    onChange={cboHandleChange("cityId")}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="companyId"
                    label={<IntlMessages id="core.institution" />}
                    options={companyList}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="visitTargetId"
                    label={<IntlMessages id="core.doctor" />}
                    options={visitTargetIdList}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="productId"
                    label={<IntlMessages id="formula.product" />}
                    options={productList}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="medicineAmount"
                    label={<IntlMessages id="formula.medicineAmount" />}
                    margin="normal"
                    errorobj={errors}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="pharmaceuticalQuantity"
                    label={<IntlMessages id="formula.pharmaceuticalQuantity" />}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="pharmaceuticalUnit"
                    label={<IntlMessages id="formula.pharmaceuticalUnit" />}
                    options={PharmaceuticalUnitList()}
                    value={watch("pharmaceuticalUnit")}
                    onChange={cboHandleChange("pharmaceuticalUnit")}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="quantity"
                    label={<IntlMessages id="core.dosage" />}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="dosageUnitId"
                    label={<IntlMessages id="formula.dosageUnit" />}
                    options={dosageUnitList}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="supplyFrequency"
                    label={<IntlMessages id="formula.supplyFrequency" />}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="unitTime"
                    label={<IntlMessages id="formula.unitTime" />}
                    options={unitList}
                    value={watch("unitTime")}
                    onChange={cboHandleChange("unitTime")}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="durationTreatment"
                    label={<IntlMessages id="formula.durationTreatment" />}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="period"
                    label={<IntlMessages id="formula.period" />}
                    options={unitDurationList}
                    value={watch("period")}
                    onChange={cboHandleChange("period")}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="attentionScopeId"
                    label={<IntlMessages id="formula.attentionScope" />}
                    options={attentionScopeList}
                    value={attentionScopeIdValue}
                    onChange={cboHandleChange("attentionScopeId")}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="diseaseId"
                    label={<IntlMessages id="formula.diagnostic" />}
                    value={watch("diseaseId")}
                    onChange={cboDiseaseChange("diseaseId")}
                    options={diseaseList}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  {/* <p>numberDeliveries</p> */}
                  <FormInput
                    name="deliveryRecord"
                    label={<IntlMessages id="formula.deliveryRecord" />}
                    margin="normal"
                    errorobj={errors}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="treatmentType"
                    label={<IntlMessages id="treatmentType.type" />}
                    value={watch("treatmentType")}
                    onChange={cboTreatmentTypeChange("treatmentType")}
                    options={TreatmentTypeList()}
                    margin="normal"
                    errorobj={errors}
                    disabled={true}
                  />
                </Grid>
                {treatmentType == 4 && (
                  <Grid item xs={12} md={12} lg={6}>
                    <FormInput
                      name="profilaxis"
                      label={
                        <IntlMessages id="treatmentType.ProphylaxisFrecuency" />
                      }
                      margin="normal"
                      disabled={true}
                      errorobj={errors}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="offlabel"
                    label={<IntlMessages id="formula.offlabel" />}
                    value={watch("offlabel")}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    name="additionalGuidelines"
                    label={<IntlMessages id="formula.additionalGuidelines" />}
                    margin="normal"
                    multiline
                    rows={3}
                    errorobj={errors}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">

            {(!disabled || file) && formulaData && (
              <CardFileUpload
                disabled={true}
                file={file}
                setFile={setFile}
                actionApi={postFormulaFile}
                actionApiInfo={{ impactId: formulaData.impactId }}
              />
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReduceFormula;
