import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { Button } from "@material-ui/core";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import ScrollContent from "../scrollContent";
import ContactPlanning from "./components/ContactPlanning";
import NameAndLabelsContent from "./components/NameAndLabelsContent";
import ContactInfo from "./components/ContactInfo";
import ContactPatientForm from "./components/ContactPatientForm";
import DialogDoctorAuthorization from "./components/DialogDoctorAuthorization";
import StartEndImpact from "./components/StartEndImpact";
import FirstImpact from "../Impact/FirstImpact";
import GeneralImpact from "../GeneralImpact";
import AdverseEventDetail from "../AdverseEventDetail";
import EventTrainingDetail from "../EventTrainingDetail";
import {
  setPrescriptionState,
  setLoader,
  removeSelectedPrescription,
} from "actions/Management";
import {
  callActionApi,
  postDoctorAuthorization,
  postPatientAuthorization
} from "api/apiList";
import { PrescripStateEnum, FolderType } from "constants/Enums";

const NewPatient = ({ prescription }) => {
  const dispatch = useDispatch();
  const { width } = useSelector(({ settings }) => settings);

  const msgDoctorSuccess = IntlGetText("doctorAuthorization.MsgSuccess");

  const onProcessStateChange = (state) => {
    dispatch(setPrescriptionState(state));
  };

  const [doctorAuthDialog, setDoctorAuthDialog] = useState(false);
  const handleDoctorAuth = (accept) => {
    if (accept) {
      sendDoctorAuth(accept, null);
    } else {
      handleDoctorAuthDialog(true);
    }
  };
  const handleDoctorAuthDialog = (open) => {
    setDoctorAuthDialog(open);
  };
  const sendDoctorAuth = (accept, comment) => {
    callActionApi(
      () =>
        postDoctorAuthorization({
          prescriptionId: prescription.id,
          accept,
          comment,
        }),
      (data) => {
        onProcessStateChange(data);
        if (accept === false) 
          dispatch(removeSelectedPrescription());
        else
          NotificationManager.success(msgDoctorSuccess);
      },
      (error) => {
        if (typeof error === "string")
          dispatch(NotificationManager.error(error));
      },
      setLoader
    );
  };

  const [patientAuthDialog, setPatientAuthDialog] = useState(false);
  const handlePatientAuth = (accept) => {
    if (accept) {
      sendPatientAuth(accept, null);
    } else {
      handlePatientAuthDialog(true);
    }
  };
  const handlePatientAuthDialog = (open) => {
    setPatientAuthDialog(open);
  };
  const sendPatientAuth = (accept, comment) => {
    callActionApi(
      () =>
        postPatientAuthorization({
          prescriptionId: prescription.id,
          accept,
          comment,
        }),
      (data) => {
        onProcessStateChange(data);
        if (accept === false) dispatch(removeSelectedPrescription(null));
      },
      (error) => {
        if (typeof error === "string")
          dispatch(NotificationManager.error(error));
      },
      setLoader
    );
  };

  return (
    <ScrollContent
      width={width}
      classParent="module-detail mail-detail"
      heightMax="calc(100vh - 309px)"
      heightMin="calc(100vh - 288px)"
    >
      <div className="mail-header">
        <NameAndLabelsContent prescription={prescription} />

        {/* Medico autoriza */}
        {prescription.stateId === PrescripStateEnum.pending && (
          <div className="mail-header-actions">
            <IntlMessages id="newPatient.doctorAuthorize" />
            <Button
              onClick={() => {
                handleDoctorAuth(true);
              }}
              variant="contained"
              className="ml-3 jr-btn bg-green text-white"
            >
              <i className="zmdi zmdi-thumb-up zmdi-hc-fw" />
            </Button>
            <Button
              onClick={() => {
                handleDoctorAuth(false);
              }}
              variant="contained"
              className="jr-btn bg-red text-white"
            >
              <i className="zmdi zmdi-thumb-down zmdi-hc-fw" />
            </Button>
          </div>
        )}
        {/* Paciente autoriza */}
        {prescription.stateId === PrescripStateEnum.doctorAccepted && prescription.patient && (
          <div className="mail-header-actions">
            <IntlMessages id="newPatient.patientAuthorize" />
            <Button
              onClick={() => {
                handlePatientAuth(true);
              }}
              variant="contained"
              className="ml-3 jr-btn bg-green text-white"
            >
              <i className="zmdi zmdi-thumb-up zmdi-hc-fw" />
            </Button>
            <Button
              onClick={() => {
                handlePatientAuth(false);
              }}
              variant="contained"
              className="jr-btn bg-red text-white"
            >
              <i className="zmdi zmdi-thumb-down zmdi-hc-fw" />
            </Button>
          </div>
        )}
        {/* Finalizar primer impacto */}
        <StartEndImpact
          prescription={prescription}
        />

        <DialogDoctorAuthorization
          dialogTitle={<IntlMessages id="management.doctorNoAuthorize" />}
          key={0}
          open={doctorAuthDialog}
          sendDoctorAuth={sendDoctorAuth}
          handleDoctorAuthDialog={handleDoctorAuthDialog}
        />
        <DialogDoctorAuthorization
          dialogTitle={<IntlMessages id="management.patientNoAuthorize" />}
          key={1}
          open={patientAuthDialog}
          sendDoctorAuth={sendPatientAuth}
          handleDoctorAuthDialog={handlePatientAuthDialog}
        />
      </div>
      <hr />

      <div className="row first-impact-box-margins">
        {prescription.stateId < PrescripStateEnum.fristImpact && (
          <ContactInfo prescription={prescription} />
        )}
        {(prescription.stateId === PrescripStateEnum.doctorAccepted || prescription.stateId === PrescripStateEnum.patientAccepted) &&
          !prescription.firstImpactDate && (
            <ContactPatientForm prescription={prescription} />
          )}
      </div>

      {prescription.stateId === PrescripStateEnum.patientAccepted && !prescription.firstImpactDate && (
        <ContactPlanning prescriptionId={prescription.id} />
      )}

      {/* PRIMER IMPACTO */}
      {prescription.stateId === PrescripStateEnum.fristImpact && <FirstImpact />}

      {/* {((prescription.stateId >= PrescripStateEnum.formulated && prescription.stateId <= PrescripStateEnum.deliveryNotMade)
        || prescription.stateId === PrescripStateEnum.NewFormulation)
        && <GeneralImpact />} */}
      {(prescription.stateId >= PrescripStateEnum.formulated)
        && <GeneralImpact />}
    </ScrollContent>
  );
};

const ContentDetail = () => {
  const { selectedMenuItem, selectedPrescription } = useSelector(
    ({ management }) => management
  );
  if (
    selectedMenuItem === FolderType.newPatients || selectedMenuItem === FolderType.inProcess || selectedMenuItem === FolderType.tracing
  ) {
    return <NewPatient prescription={selectedPrescription} />;
  }

  if (selectedMenuItem === FolderType.adverseEvents) {
    return <AdverseEventDetail prescription={selectedPrescription} />;
  }

  if (selectedMenuItem === FolderType.trainingsAndEvents) {
    return <EventTrainingDetail prescription={selectedPrescription}/>;
  }
  
  return <div>Hola</div>;
};

export default ContentDetail;
