import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "util/IntlMessages";
import ReportBox from "components/ReportBox/index";
import YearlyProfitChart from "components/dashboard/eCommerce/YearlyProfitChart";
import { callActionApi, getActivePatientsIndicator } from "api/apiList";
import { showErrorMessage } from "actions/Management";
import { setLoader } from "actions/Base";

const NewPatientIndicator = () => {
    const dispatch = useDispatch();
    const { newPatients } = useSelector(({ home }) => home);
    const [patientIndicator, setPatientIndicator] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        callActionApi(
            getActivePatientsIndicator,
        (data)=>{
            setPatientIndicator(data);
            setIsLoaded(true);
            },
        (error) => {
            if(typeof(error) === "string") 
            dispatch(showErrorMessage(error));
        },
        setLoader
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <ReportBox
              styleName="bg-blue text-white p-3"
              icon="accounts-alt"
              price={newPatients.length}
              detail={<IntlMessages id="psp.home.newPatients" />}
              subHeadingColor="text-white"
            >
              {isLoaded && patientIndicator > 0 && <YearlyProfitChart
                chartId="nursepie"
                centerText={patientIndicator}
                height={100}
                chartType="newsDoughnut"
                backgroundColor={["#607d8b", "#FFF"]}
                borderColor={["#607d8b", "#FFF"]}
                hoverBorderColor={["#607d8b", "#FFF"]}
                hoverBorderWidth={[2, 2]}
                textColor="#fff"
                rotation={50}
                labels={["Nuevos", "Existentes"]}
                dataControl={[newPatients.length, patientIndicator - newPatients.length]}
                fontSize={1.4}
              />
                }
            </ReportBox>
    );
};

export default NewPatientIndicator;