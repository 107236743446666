import React from "react";
import IntlMessages from "util/IntlMessages";
import Button from "@material-ui/core/Button";

const DownloadButton = ({ classes, fileLink, keyMsg, color, icon, variant = "contained" }) => {
    const finalIcon = icon ? "mr-2 zmdi " + icon : null;
    const onClick = () => {
      const link = document.createElement("a");
      link.href = fileLink;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
    return (
      <Button className={classes} onClick={onClick} color={color} variant={variant}>
        {finalIcon && <i className={finalIcon}></i>} {keyMsg && <IntlMessages id={keyMsg} />}
      </Button>
    );
};

export const DownloadButton2 = ({ children, ...props }) => {
  const { fileLink, ...buttonProps } = props;
  const onClick = () => {
    const link = document.createElement("a");
    link.href = fileLink;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button onClick={onClick} {...buttonProps}>
      {children}
    </Button>
  );
};

export default DownloadButton;