import React from "react";
import { Grid } from "@material-ui/core";
import DialogGeneric from "components/Form/DialogGeneric";
import DownloadButton from "components/DownloadButton/index";

const DlgDownloadImpactFile = ({
  openDlg,
  closeDialog,
  impactInfo
}) => {
  return (
    <DialogGeneric
      open={openDlg}
      closeDialog={closeDialog}
      titleTextId={"institution.ending"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <DownloadButton
            variant="contained"
            color="primary"
            fileLink={impactInfo && impactInfo.impactFile}
            keyMsg="core.download"
          />
        </Grid>
      </Grid>
    </DialogGeneric>
  );
};

export default DlgDownloadImpactFile;
