import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import * as dayjs from "dayjs";
import Divider from "@material-ui/core/Divider";
import { Button } from "@material-ui/core";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { isRole } from "app/routes/psp/services/userService";
import { RoleEnum } from "constants/Enums";
import PatientData from "./cards/patientData";
import Assistance from "./cards/assistance";
import Procedure from "./cards/procedure";
import Ending from "./cards/ending";
import NextFollowUp from "./cards/nextFollowUp";
import {
  callApi,
  getInstitutionImpactInfo
} from "api/apiList";
import { PatientInstitutionStateEnum } from "constants/Enums";

const InstitutionDetails = (props) => {
  const { impactRow } = props.location;

  let history = useHistory();
  const [impactCompleted, setimpactCompleted] = useState(false);
  const [impactInfo, setImpactInfo] = useState(null);
  const [stateFinished, setStateFinished] = useState(false);
  
  const isInstitutionManager = isRole(RoleEnum.institutionManager);

  useEffect(()=>{
    if(!impactRow){
      setImpactInfo(null);
      setStateFinished(false);
      return;
    }

    setStateFinished(impactRow.stateId === PatientInstitutionStateEnum.finished);
    callApi(
      () => getInstitutionImpactInfo(impactRow.id),
      setImpactInfo
    );
  },[impactRow]);

  if(!impactRow){
    history.push("/app/institutionHome");
    return <></>;
  }

  const impactCompletedFunct = () => {
    setimpactCompleted(true)
  }

  const getDate = (impactInfo) => {
      if(!impactInfo)
        return "";
      if(impactInfo.planningDate && !impactInfo.startDate)
        return dayjs(impactInfo.planningDate).format("YYYY/MM/DD");
      if(impactInfo.startDate)
        return dayjs(impactInfo.startDate).format("YYYY/MM/DD");
      return "";
  };

  return (
    <div className="herida-individual">
      <div className="herida-container-btn">
        <a className="herida-back-btn" href="javascript: history.go(-1)">
          <i className="zmdi zmdi-arrow-back"></i>
        </a>
        <div>
          <h1 className="herida-title"><IntlMessages id="institution.detailTitle" /></h1>
        </div>
      </div>
      <Divider />
      <div className="herida-container">
        <h2 className="user-name mb-0">{impactInfo && impactInfo.patient} - {getDate(impactInfo)}</h2>
      </div>
      <div>
        <div className="herida-content">
          <div className="row first-impact-box-margins">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <PatientData impactInfo={impactInfo}/>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <Assistance allowEdit={!isInstitutionManager && impactInfo && !impactInfo.endDate} 
                impactInfo={impactInfo} 
                setImpactInfo={setImpactInfo}/>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <Procedure allowEdit={!isInstitutionManager && impactInfo && !impactInfo.endDate}
                impactInfo={impactInfo} 
                setImpactInfo={setImpactInfo} />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <Ending allowEnd={true}
                impactInfo={impactInfo}
                impactRow={impactRow}
                setStateFinished={setStateFinished}
                setImpactInfo={setImpactInfo} 
                isInstitutionManager = {isInstitutionManager}/>
            </div>
            { !stateFinished &&
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <NextFollowUp allowPlanning={impactInfo && impactInfo.startDate && !impactInfo.anyPlanned} 
                  impactInfo={impactInfo} 
                  setImpactInfo={setImpactInfo}
                  isInstitutionManager = {isInstitutionManager}/>
              </div>
            }
          </div>
          {impactInfo && impactInfo.impactFile && 
            <div className="col-lg-12 text-center mt-5">
              <Button
                onClick={()=>{history.push("/app/institutionHome");}}
                variant="contained"
                color="primary"
                className="ml-2"
              >
                {<IntlMessages id="core.goBack" />}
              </Button>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default InstitutionDetails;
