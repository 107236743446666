import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { 
    Button,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormDatePicker from "components/Form/Controls/datepicker";

const FormCreateUpdate = ({ obj, handleCreateUpdate, setCurrentRowObj, readOnly}) => {
  const defaultValues = {    
    lotNumber: "",
    expirationDate: null,
  };
  const validationSchema = { 
    lotNumber: yup
      .string()
      .required(<IntlMessages id="core.fieldRequired" />)
      .matches(/^[a-z0-9_-]+$/i, IntlGetText("yup.alphanumericValidation")),
    expirationDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />)    
  };

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, reset, errors } = methods;

  const loadData = () => {
    setValue("lotNumber", !obj || !obj.lotNumber ? "" : obj.lotNumber);
    setValue("expirationDate", !obj || !obj.expirationDate ? null : dayjs(obj.expirationDate));    
  };

  useEffect(()=>{
    setTimeout(loadData,150);
  },[obj]);


  const onSubmit = async (d, e) => {
    var current = !obj ? { id:0 } : obj;
    var item = {
      //id: !obj || !obj.id ? 0 : obj.id,
      ...current,
      lotNumber: d.lotNumber,
      expirationDate: dayjs(d.expirationDate).format("YYYY-MM-DDTHH:mm:ss"),
    };
    handleCreateUpdate(item);
    reset(defaultValues);
  };

  const handleUndo = () => setCurrentRowObj(null);

  const disabled = readOnly;
  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-5">
      <FormProvider {...methods}>
        <form className="row" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} lg={5}>
              <FormInput
                name="lotNumber"
                label={<IntlMessages id="medicineDelivery.lotNumber" />}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <FormDatePicker
                name="expirationDate"
                label={
                  <IntlMessages id="medicineDelivery.expirationDate" />
                }
                format="YYYY-MM-DD"
                //disablePast={true}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Button
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
                className="mt-3"
              >
                {<IntlMessages id="core.save" />}
              </Button>
              {obj &&
                <Button
                  onClick={handleUndo}
                  variant="contained"
                  className="jr-btn ml-2 mt-3"
                >
                  <i className="zmdi zmdi-undo"></i>
                </Button>
              }
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};
export default FormCreateUpdate;
