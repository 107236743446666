import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import { IntlGetText } from "util/IntlMessages";
import ScrollContent from "../scrollContent";
import PrescriptionList from "./PrescriptionList";
import ContentDetail from "../PrescriptionDetail/prescriptionDetail";
import { setSelectedPrescription } from "actions/Management";
import { FolderType } from "constants/Enums";

const getHeight = (selectedMenuItem, defaultHeight) => {
  return selectedMenuItem === FolderType.adverseEvents
    ? "calc(100vh - 180px)"
    : "calc(100vh - "+defaultHeight+")";
};

const ArrowBack = () => {
    const dispatch = useDispatch();
    const handlerClick = () => {
      dispatch(setSelectedPrescription(null));
    };
    return (
      <div className="module-box-topbar">
        <IconButton className="icon-btn" onClick={handlerClick}>
          <i className="zmdi zmdi-arrow-back" />
        </IconButton>
      </div>
    );
  };
  
  const ManageContent = () => {
    const { width } = useSelector(({ settings }) => settings);
    const { prescriptionsByMenuItem, selectedPrescription, selectedMenuItem } = useSelector(
      ({ management }) => management
    );
  
    const noContentFoundMessage = IntlGetText("core.noContentFound"); //"No hay pacientes en este espacio";
  
    return (
      <div className="module-box-column">
        {!selectedPrescription ? (
          prescriptionsByMenuItem.length === 0 ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                height:
                  width >= 1200 ? "calc(100vh - 259px)" : "calc(100vh - 238px)",
              }}
            >
              {noContentFoundMessage}
            </div>
          ) : (
            <ScrollContent
              width={width}
              heightMax="calc(100vh - 200px)"
              heightMin="calc(100vh - 177px)"
            >
              <PrescriptionList />
            </ScrollContent>
          )
        ) : (
          <div>
            <ArrowBack />
            <ScrollContent
              width={width}
              heightMax={getHeight(selectedMenuItem, "259px")}
              heightMin={getHeight(selectedMenuItem, "238px")}
            >
              <ContentDetail />
            </ScrollContent>
          </div>
        )}
      </div>
    );
  };

  export default ManageContent;