import http from "../services/httpService";

const patientApiUrl = `${process.env.REACT_APP_API_URL}/Patient`;

export function getAllPatient() {
  return http.get(patientApiUrl);
}

export function addPatient(patient) {
  return http.post(patientApiUrl, patient);
}

export function updatePatient(patient) {
  http.put(patientApiUrl + "/" + patient.id, patient);
}

export function deletePatient(patient) {
  return http.delete(patientApiUrl + "/" + patient.id);
}
