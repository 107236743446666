import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import FormDateTimePicker from "components/Form/Controls/dateTimePicker";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { callActionApi, getImpactTypeList } from "api/apiList";

const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const DialogPlanningImpact = ({
  open,
  handleRequestClose,
  prescription,
  callPlanImpact,
}) => {
  const { selectedMenuItem } = useSelector(({ management }) => management);
  const [impactTypeList, setImpactTypeList] = useState([]);

  const defaultValues = {
    impactTypeId: "",
    planningDate: null,
  };
  const validationSchema = {
    impactTypeId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    planningDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, errors } = methods;

  const loadData = () => {
    setValue(
      "impactTypeId",
      !prescription
        ? ""
        : getCboValue(impactTypeList, prescription.impactTypeId)
    );
    setValue(
      "planningDate",
      !prescription || !prescription.planningDate
        ? null
        : prescription.planningDate
    );
  };

  useEffect(() => {
    callActionApi(
      getImpactTypeList,
      setImpactTypeList,
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  }, []);

  useEffect(() => {
    if (open) {
      setTimeout(loadData, 150);
    }
  }, [open]);

  const onSubmit = async (data, e) => {
    const obj = {
      prescriptionId: prescription.id,
      planningDate: dayjs(data.planningDate).format("YYYY-MM-DDTHH:mm:ss"),
      impactTypeId: Number(data.impactTypeId.value),
    };
    await callPlanImpact(obj);
  };

  const filterImpactTypeList = (folderType) => 
    impactTypeList.filter(x=>x.folderType == folderType);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="xs"
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        {<IntlMessages id="impact.PlanningImpactTitle" />}
      </DialogTitle>
      <DialogContent className="new-patient-dialog-content">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <FormSelectAutoComplete
                  name="impactTypeId"
                  label={<IntlMessages id="impact.ImpactType" />}
                  options={filterImpactTypeList(selectedMenuItem)}
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormDateTimePicker
                  name="planningDate"
                  label={IntlGetText("core.dateAndTime")}
                  format="YYYY-MM-DD hh:mm A"
                  disablePast={true}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          {<IntlMessages id="core.cancel" />}
        </Button>
        <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
          {<IntlMessages id="core.save" />}
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  );
};

export default DialogPlanningImpact;
