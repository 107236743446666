import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormDatePicker from "components/Form/Controls/datepicker";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { GeneralStatusList } from "constants/Enums";

const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const FormCreateUpdate = ({
  openCRU,
  impactId,
  obj,
  handleCreateUpdate,
  readOnly,
}) => {
  const generalStatusList = GeneralStatusList();

  const evaScaleList = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
    { id: 9, name: "9" },
    { id: 10, name: "10" },
  ];

  const defaultValues = {
    catheterStatus: "",
    insertionDate: null,
    location: "",
    evapainScale: "",
    infusionSystemStatus: "",
    infusionPumpStatus: "",
    comment: "",
  };
  const validationSchema = {
    location: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    comment: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    insertionDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    catheterStatus: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    evapainScale: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    infusionSystemStatus: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    infusionPumpStatus: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, reset, errors } = methods;

  const loadData = () => {
    setValue("location", !obj || !obj.location ? "" : obj.location);
    setValue("comment", !obj || !obj.comment ? "" : obj.comment);
    setValue(
      "insertionDate",
      !obj || !obj.insertionDate ? null : dayjs(obj.insertionDate)
    );
    setValue(
      "catheterStatus",
      !obj || (!obj.catheterStatus && obj.catheterStatus !== 0)
        ? null
        : getCboValue(generalStatusList, obj.catheterStatus)
    );
    setValue(
      "infusionSystemStatus",
      !obj || (!obj.infusionSystemStatus && obj.infusionSystemStatus !== 0)
        ? null
        : getCboValue(generalStatusList, obj.infusionSystemStatus)
    );
    setValue(
      "infusionPumpStatus",
      !obj || (!obj.infusionPumpStatus && obj.infusionPumpStatus !== 0)
        ? null
        : getCboValue(generalStatusList, obj.infusionPumpStatus)
    );
    setValue(
      "evapainScale",
      !obj || (!obj.evapainScale && obj.evapainScale !== 0)
        ? null
        : getCboValue(evaScaleList, obj.evapainScale)
    );
  };

  useEffect(() => {
    if (openCRU) {
      setTimeout(loadData, 150);
    }
  }, [openCRU]);

  const onSubmit = async (d, e) => {
    var item = {
      id: !obj ? 0 : obj.id,
      impactId: impactId,
      catheterStatus: Number(d.catheterStatus.value),
      insertionDate: dayjs(d.insertionDate),
      location: d.location,
      evapainScale: Number(d.evapainScale.value),
      infusionSystemStatus: Number(d.infusionSystemStatus.value),
      infusionPumpStatus: Number(d.infusionPumpStatus.value),
      comment: d.comment,
    };
    await handleCreateUpdate(item);
  };

  const disabled = readOnly;
  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
      <FormProvider {...methods}>
        <form className="row" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="catheterStatus"
                label={
                  <IntlMessages id="infusionTracking.catheterStatus" />
                }
                options={generalStatusList}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormDatePicker
                name="insertionDate"
                label={
                  <IntlMessages id="infusionTracking.insertionDate" />
                }
                format="YYYY-MM-DD"
                //disablePast={true}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="location"
                label={<IntlMessages id="infusionTracking.location" />}
                required={true}
                multiline
                rows={3}
                variant="outlined"
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <FormSelectAutoComplete
                name="evapainScale"
                label={
                  <IntlMessages id="infusionTracking.evapainScale" />
                }
                options={evaScaleList}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <FormSelectAutoComplete
                name="infusionSystemStatus"
                label={
                  <IntlMessages id="infusionTracking.infusionSystemStatus" />
                }
                options={generalStatusList}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <FormSelectAutoComplete
                name="infusionPumpStatus"
                label={
                  <IntlMessages id="infusionTracking.infusionPumpStatus" />
                }
                options={generalStatusList}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="comment"
                label={<IntlMessages id="core.comment" />}
                required={true}
                multiline
                rows={4}
                variant="outlined"
                margin="normal"
                errorobj={errors}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      {!disabled && (
        <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
          <ButtonAsync
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
          >
            {<IntlMessages id="core.save" />}
          </ButtonAsync>
        </div>
      )}
    </div>
  );
};
export default FormCreateUpdate;
