import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages from "util/IntlMessages";
import { Grid, IconButton } from "@material-ui/core";
import FormSelectAutoComplete, {
  getCboValue,
} from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import FormDatePicker from "components/Form/Controls/datepicker";
import DialogAsync from "components/Form/DialogAsync";
import {
  callApi,
  callApiAsync,
  postAddPharmacy,
  getPharmacyByMarketChain,
  postDeliveryAdd
} from "api/apiList";
import { DeliveryModeList } from "constants/Enums";

const AddDelivery = ({
  openDlg,
  requestCloseDlg,
  callBack,
  impact,
  marketchainId,
  delivery
}) => {
  const [isAddPharmacy, setIsAddPharmacy] = useState(false);
  const [visitTargetList, setVisitTargetList] = useState([]);
  const [formLoaded, setFormLoaded] = useState(false);
  const deliveryModeList = DeliveryModeList();

  const defaultValues = {
    marketChainId: null,
    visitTargetId: null,
    medicineAmount: "",
    deliveryDate: null,
    planningDate: null,
    deliveryMode: null,
    comment: "",

    newPharmacyName: "",
  };

  const validationSchema = {
    visitTargetId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    deliveryMode: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    medicineAmount: yup
      .string()
      .required(<IntlMessages id="core.fieldRequired" />)
    // deliveryDate: yup
    //   .date()
    //   .nullable(true)
    //   .typeError(IntlGetText("yup.InvalidDate"))
    //   .required(<IntlMessages id="core.fieldRequired" />),
    // planningDate: yup
    //   .date()
    //   .nullable(true)
    //   .typeError(IntlGetText("yup.InvalidDate"))
    //   .required(<IntlMessages id="core.fieldRequired" />),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, errors } = methods;
  
  const newPharmacyNameValue = watch("newPharmacyName");

  const setString = (obj, prop) =>
    setValue(prop, !obj || !obj[prop] ? "" : obj[prop]);
  const setDate = (obj, prop) =>
    setValue(prop, !obj || !obj[prop] ? null : dayjs(obj[prop]));

  const loadData = (obj) => {
    setValue("visitTargetId",
      !obj ? null : getCboValue(visitTargetList, obj.dispensingPointId)
    );
    setValue("deliveryMode",
      !obj ? null : getCboValue(deliveryModeList, obj.deliveryMode)
    );
    setDate(obj, "deliveryDate");
    setDate(obj, "planningDate");
    setString(obj, "comment");
    setString(obj, "medicineAmount");
  };
  
  useEffect(() => {
    setFormLoaded(false);
    setTimeout(()=>loadData(delivery),130);
  }, [openDlg]);

  useEffect(() => {
    setValue("visitTargetId", null);
    if (!marketchainId || !marketchainId) {
      setVisitTargetList([]);
      return;
    }
    callApi(
      () => getPharmacyByMarketChain(marketchainId),
      setVisitTargetList
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketchainId]);

  useEffect(() => {
    if (!formLoaded && visitTargetList.length > 0) {
      setValue(
        "visitTargetId",
        getCboValue(visitTargetList, !delivery ? "" : delivery.dispensingPointId)
      );
      setFormLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitTargetList]);
  
  const handleNewPharmacy = () => {
    if (!isAddPharmacy) {
      setIsAddPharmacy(true);
      return;
    }

    if (!newPharmacyNameValue) return;

    var obj = {
      marketChainId: marketchainId,
      name: newPharmacyNameValue,
    };

    callApi(
      () => postAddPharmacy(obj),
      (data) => {
        setIsAddPharmacy(false);
        setVisitTargetList([...visitTargetList, data]);
        setValue("visitTargetId", { label: data.name, value: data.id });
      }
    );
  };

  const onSubmitAsync = handleSubmit(async (d) => {
    const obj = {
      id: delivery ? delivery.id : 0,
      impactId: impact.id,
      medicineAmount:Number(d.medicineAmount),
      planningDate: !d.planningDate
        ? null
        : dayjs(d.planningDate).format("YYYY-MM-DDTHH:mm:ss"),
      deliveryDate: !d.deliveryDate
        ? null
        : dayjs(d.deliveryDate).format("YYYY-MM-DDTHH:mm:ss"),
      deliveryMode: Number(d.deliveryMode.value),
      comment: d.comment,
      dispensingPointId: Number(d.visitTargetId.value)
    };

    await callApiAsync(
      () => postDeliveryAdd(obj),
      () => {
        if (callBack) callBack();
        requestCloseDlg();
      }
    );
  });

  const disabled = false;

  return (
    <div>

      <DialogAsync
        open={openDlg}
        closeDialog={requestCloseDlg}
        onSubmitAsync={onSubmitAsync}
        titleTextId="core.add"
      >
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={10} md={10} lg={10}>
                {!isAddPharmacy && (
                  <FormSelectAutoComplete
                    name="visitTargetId"
                    label={
                      <IntlMessages id="insuranceAuthorization.marketChainBranch" />
                    }
                    options={visitTargetList}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                )}
                {isAddPharmacy && (
                  <FormInput
                    name="newPharmacyName"
                    label={
                      <IntlMessages id="insuranceAuthorization.newPharmacyName" />
                    }
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                )}
              </Grid>
              {!disabled && (
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <div className="mt-3">
                    <IconButton
                      type="button"
                      className="icon-btn"
                      color="primary"
                      onClick={handleNewPharmacy}
                    >
                      <i
                        className={
                          isAddPharmacy
                            ? "zmdi zmdi-save zmdi-hc-fw"
                            : "zmdi zmdi-plus-circle zmdi-hc-fw"
                        }
                      />
                    </IconButton>
                    {isAddPharmacy && (
                      <IconButton
                        type="button"
                        className="icon-btn"
                        color="primary"
                        onClick={() => setIsAddPharmacy(false)}
                      >
                        <i className="zmdi zmdi-undo zmdi-hc-fw" />
                      </IconButton>
                    )}
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="deliveryMode"
                  label={<IntlMessages id="core.deliveryMode" />}
                  options={deliveryModeList}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="medicineAmount"
                  label={
                    <IntlMessages id="core.medicineAmountShort" />
                  }
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <FormDatePicker
                  name="planningDate"
                  label={
                    <IntlMessages id="core.deliveryScheduledDate" />
                  }
                  format="YYYY-MM-DD"
                  disablePast={true}
                  margin="normal"
                  disabled={disabled}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <FormDatePicker
                  name="deliveryDate"
                  label={
                    <IntlMessages id="radicadoOLF.deliveryDate" />
                  }
                  format="YYYY-MM-DD"
                  disableFuture={true}
                  margin="normal"
                  disabled={disabled}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormInput
                  name="comment"
                  label={<IntlMessages id="core.comment" />}
                  multiline
                  rows={4}
                  variant="outlined"
                  margin="normal"
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogAsync>
    </div>
  );
};

export default AddDelivery;
