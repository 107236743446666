import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button, Tooltip } from "@material-ui/core";
import Table, { minimalOptions, defaultCol, dateColumn } from "components/Form/Table";
import DialogGeneric from "components/Form/DialogGeneric";
import DlgCoachComment from "../components/dlgCoachComment";
import { callApi, getImpactsByPrescriptionV2 } from "api/apiList";

const DlgImpacts = ({openDlg, requestCloseDlg, prescriptionId}) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [impactList, setImpactList] = useState([]);
  const [currentImpact, setCurrentImpact] = useState(null);
  const [openDlgComment, setOpenDlgComment] = useState(false);

  const loadData =()=>{
    if(!prescriptionId){
      setImpactList([]);
      return;
    }

    callApi(
      ()=>getImpactsByPrescriptionV2(prescriptionId),
      setImpactList,
      fnSetLoader
    );
  };

  useEffect(loadData, [openDlg]);

  const getObj = (tableMeta) =>
    impactList.find((x) => x.id === tableMeta.rowData[0]);

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("impactType", "impact.ImpactType"),
    dateColumn("startDate", "core.startDate","YYYY/MM/DD hh:mm a"),
    dateColumn("endDate", "core.endDate","YYYY/MM/DD hh:mm a"),
    defaultCol("nursingNote", "core.comment"),
    {
      name: "actions",
      label: IntlGetText("table.coordinatorComment"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          if(!rowObj.coordComment)
            return "";

          var tooltipTitle = "reports.coordinatorComment";
          var bottonIcon = "zmdi zmdi-eye";
            
          return (
            <div>
              <Tooltip title={<IntlMessages id={tooltipTitle} />} 
                placement="bottom-end">
                <Button
                  onClick={() => {
                    setCurrentImpact(rowObj);
                    setOpenDlgComment(true);
                  }}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className={bottonIcon}></i>
                </Button>
              </Tooltip>
            </div>
          );
        },
      },
    }
  ];

  return(
    <DialogGeneric
      open={openDlg}
      closeDialog={requestCloseDlg}
      titleTextId="core.impacts"
      maxWidth="md"
    >
      <DlgCoachComment 
        openDlg={openDlgComment} 
        closeDialog={()=>{
          setCurrentImpact(null);
          setOpenDlgComment(false);
        }}
        impact={currentImpact}
        isManager={false}/>
      <Table
        className="border-dialog-card mt-2 mb-2"
        data={impactList}
        columns={columns}
        options={minimalOptions()}
      />
    </DialogGeneric>
  );
};

export default DlgImpacts;