import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormDatePicker from "components/Form/Controls/datepicker";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { SupplyActionTypeList, ShardKeyEnum } from "constants/Enums";
import { getUserInfo } from "app/routes/psp/services/userService";

const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const FormCreateUpdate = ({ openCRU, handleRequestClose, impactId, obj, handleCreateUpdate, supplyTypeList, readOnly}) => {
  const isPhg= getUserInfo().shardKey === ShardKeyEnum.phg;
  const supplyActionTypeList = SupplyActionTypeList(isPhg);

  const defaultValues = {    
    supplyTypeId: null,
    date: null,
    amount: "",
    tipo: null,
    comment:""
  };
  const validationSchema = { 
    supplyTypeId: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    date: yup.date()
      .nullable()
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    amount: yup.number()
      .typeError(IntlGetText("yup.numericValidation"))
      .nullable().transform((v, o) => o === '' ? null : v)
      .positive()
      .required(<IntlMessages id="core.fieldRequired" />),
    tipo: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable()
  };

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, errors } = methods;

  const loadData = () => {
    setValue("supplyTypeId", !obj || !obj.supplyTypeId ? null : getCboValue(supplyTypeList,obj.supplyTypeId));
    setValue("date", !obj || !obj.date ? null : dayjs(obj.date));
    setValue("amount", !obj || !obj.amount ? "" : obj.amount);
    setValue("tipo", !obj ? null : getCboValue(supplyActionTypeList,obj.tipo));
    setValue("comment", !obj || !obj.comment ? "" : obj.comment);
  };

  useEffect(()=>{
    if(openCRU){
      setTimeout(loadData,150);
    }
  },[openCRU]);

  const onSubmit = async (d, e) => {
    var item = {
      id: !obj ? 0 : obj.id,
      impactId: impactId,
      supplyTypeId: Number(d.supplyTypeId.value),
      date: dayjs(d.date).format("YYYY-MM-DDTHH:mm:ss"),
      amount: Number(d.amount),
      tipo: Number(d.tipo.value),
      comment: d.comment
    };
    await handleCreateUpdate(item);
  };

  const disabled = readOnly;
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!obj ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <FormDatePicker
                    name="date"
                    label={<IntlMessages id="core.date" />}
                    format="YYYY-MM-DD"
                    margin="normal"
                    disableFuture={true}
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />                
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormSelectAutoComplete
                    name="tipo"
                    label={<IntlMessages id="core.type" />}
                    options={supplyActionTypeList}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormSelectAutoComplete
                    name="supplyTypeId"
                    label={<IntlMessages id="healingMaterial.supplyType" />}
                    options={supplyTypeList}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <FormInput
                    name="amount"
                    label={<IntlMessages id="core.amount" />}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    name="comment"
                    label={<IntlMessages id="core.comment" />}
                    required={true}
                    multiline
                    rows={3}
                    variant="outlined"
                    margin="normal"
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          { !disabled &&
            <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
              <ButtonAsync onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
              >
                {<IntlMessages id="core.save" />}
              </ButtonAsync>
            </div>
          }
        </div>  
      </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>  
  );
};
export default FormCreateUpdate;
