import React from "react";

export default [
  {
    id: "15453ba60d3baa5daaf",
    hasPatient: false,
    time: "2020/07/06",
    from: {
      name: "Nuevo",
      lastname: "Paciente",
      avatar: "",
      email: "victoria.lopez@outlook.com",
      doctorName: "Dra. Victoria Lopez",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              victoria.lopez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Dra. Victoria Lopez",
    date: "",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [5],
    states: [],
    selected: false,
    folder: 0,
  },
  {
    id: "15453ba60d3baa584df",
    hasPatient: true,
    time: "2020/07/14",
    from: {
      name: "Alicia",
      lastname: "Muñoz",
      avatar: "",
      email: "alex.rodriguez@outlook.com",
      doctorName: "Dr. Alex Rodriguez",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              alex.rodriguez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-3205864578"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Dr. Alex Rodriguez",
    date: "2020/07/21",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [5, 0],
    states: [0, 7, 8],
    selected: false,
    folder: 0,
  },
  {
    id: "154asda0d3baa5daaf",
    hasPatient: false,
    time: "2020/07/17",
    from: {
      name: "Nuevo",
      lastname: "Paciente",
      avatar: "",
      email: "julia.ramirez@outlook.com",
      doctorName: "Dra. Julia Ramirez",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              julia.ramirez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Dra. Victoria Lopez",
    date: "",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [6],
    states: [],
    selected: false,
    folder: 0,
  },
  // En tramite
  {
    id: "157697ba60d3baa5daaf",
    hasPatient: true,
    time: "2020/07/13",
    from: {
      name: "Helena",
      lastname: "Ruiz",
      avatar: "",
      email: "victoria.lopez@outlook.com",
      doctorName: "Dra. Victoria Lopez",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              victoria.lopez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Dra. Victoria Lopez",
    date: "2020",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [5, 4],
    states: [0, 7, 8],
    selected: false,
    folder: 1,
  },
  {
    id: "157697ba665763baa5daaf",
    hasPatient: true,
    time: "2020/07/16",
    from: {
      name: "Juan",
      lastname: "Diaz",
      avatar: "",
      email: "victoria.lopez@outlook.com",
      doctorName: "Dra. Victoria Lopez",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              victoria.lopez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Dra. Victoria Lopez",
    date: "2020",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [6, 10],
    states: [0, 7, 8],
    selected: false,
    folder: 1,
  },
  {
    id: "157697b5d3baa5daaf",
    hasPatient: true,
    time: "2020/07/21",
    from: {
      name: "Jose",
      lastname: "Caicedo",
      avatar: "",
      email: "victoria.lopez@outlook.com",
      doctorName: "Dra. Victoria Lopez",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              victoria.lopez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Dra. Victoria Lopez",
    date: "2020",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [5, 11],
    states: [0, 7, 8],
    selected: false,
    folder: 1,
  },
  // SEGUIMIENTOS
  {
    id: "1575468656d3baa5daaf",
    hasPatient: true,
    time: "2020/07/22",
    from: {
      name: "Martha",
      lastname: "Perez",
      avatar: "",
      email: "victoria.lopez@outlook.com",
      doctorName: "Dra. Victoria Lopez",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              victoria.lopez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Dra. Victoria Lopez",
    date: "2020",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [5, 16],
    states: [0, 7, 8],
    selected: false,
    folder: 2,
  },
  {
    id: "157656d3baa5daaf",
    hasPatient: true,
    time: "2020/07/22",
    from: {
      name: "Andres",
      lastname: "Garcia",
      avatar: "",
      email: "victoria.lopez@outlook.com",
      doctorName: "Dra. Victoria Lopez",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              victoria.lopez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Dra. Victoria Lopez",
    date: "2020",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [5, 14],
    states: [0, 7, 8],
    selected: false,
    folder: 2,
  },
  {
    id: "15765665776aa5daaf",
    hasPatient: true,
    time: "2020/07/22",
    from: {
      name: "Raul",
      lastname: "Hernandez",
      avatar: "",
      email: "victoria.lopez@outlook.com",
      doctorName: "Dra. Victoria Lopez",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              victoria.lopez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Dra. Victoria Lopez",
    date: "2020",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [6, 15],
    states: [0, 7, 8],
    selected: false,
    folder: 2,
  },
  // EVENTOS ADVERSOS
  {
    id: "2342342342gedf",
    hasPatient: true,
    time: "2020/07/10",
    from: {
      name: "Luis",
      lastname: "Dominguez",
      avatar: "",
      email: "victoria.lopez@outlook.com",
      doctorName: "Dra. Victoria Lopez",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              victoria.lopez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Dra. Victoria Lopez",
    date: "2020",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [5, 18],
    states: [0, 7, 8],
    selected: false,
    folder: 3,
  },
  {
    id: "157666f96aa5daaf",
    hasPatient: true,
    time: "2020/07/15",
    from: {
      name: "Ignacio",
      lastname: "Andrade",
      avatar: "",
      email: "victoria.lopez@outlook.com",
      doctorName: "Dra. Victoria Lopez",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              victoria.lopez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Dra. Victoria Lopez",
    date: "2020",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [6, 18],
    states: [0, 7, 8],
    selected: false,
    folder: 3,
  },
  {
    id: "1576355ad521daaf",
    hasPatient: true,
    time: "2020/07/21",
    from: {
      name: "Sergio",
      lastname: "Perez",
      avatar: "",
      email: "victoria.lopez@outlook.com",
      doctorName: "Dra. Victoria Lopez",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              victoria.lopez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Dra. Victoria Lopez",
    date: "2020",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [5, 18],
    states: [0, 7, 8],
    selected: false,
    folder: 3,
  },
  // CAPACITACIÓN
  {
    id: "adad4543asd2",
    hasPatient: true,
    time: "2020/07/24",
    from: {
      name: "Actualizaciones del",
      lastname: "medicamento",
      avatar: "",
      email: "victoria.lopez@outlook.com",
      doctorName: "Dra. Victoria Lopez",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              victoria.lopez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "",
    date: "2020",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [6, 20],
    states: [0, 7, 8],
    selected: false,
    folder: 4,
  },
  {
    id: "ddddds545444s",
    hasPatient: true,
    time: "2020/07/29",
    from: {
      name: "Actualizaciones del",
      lastname: "medicamento",
      avatar: "",
      email: "victoria.lopez@outlook.com",
      doctorName: "Dra. Victoria Lopez",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              victoria.lopez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "",
    date: "2020",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [5, 20],
    states: [0, 7, 8],
    selected: false,
    folder: 4,
  },
  {
    id: "dddddsd454ghnh144s",
    hasPatient: true,
    time: "2020/07/31",
    from: {
      name: "Encuentro de",
      lastname: "salud",
      avatar: "",
      email: "victoria.lopez@outlook.com",
      doctorName: "Dra. Victoria Lopez",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              victoria.lopez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "",
    date: "2020",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [19],
    states: [0, 7, 8],
    selected: false,
    folder: 4,
  },
];
