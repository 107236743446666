import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { yupResolver } from "@hookform/resolvers";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import DownloadButton from "components/DownloadButton/index";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import PdfViewer from "components/PdfViewer";
import Form, { defaultValues, validationSchema, getDataForm } from "./Form";
import { SketchField, Tools } from "react-sketch";
import { NotificationManager } from "react-notifications";
import { getUserInfo } from "app/routes/psp/services/userService";
import { ShardKeyEnum } from "constants/Enums";
import {
  setConsentPath,
  callStartImpact,
  callReloadPatientData
} from "actions/Impact";
import { setLoader } from "actions/Base";
import {
  callApiAsync,
  postConsentSaveSign,
  postConsentAddExisting
} from "api/apiList";
import CardFileUpload from "components/Form/CardFileUpload";
import AudioForm from "./audioForm";

const ConsentDialog = () => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const { selectedPrescription } = useSelector(({ management }) => management);
  const {
    referralConsentFile,
    impactStartDate,
    consentPath,
    ciDisplayable,
    audioUrl,
    consentSigned
  } = useSelector(({ impact }) => impact);
  const msgValidation = IntlGetText("consent.validation");
  const msgSuccess = IntlGetText("core.SuccessSave");
  const [open, setOpen] = useState(false);
  const [sketch, setSketch] = useState(false);
  const [reSign, setReSign] = useState(false);
  const [reSignReload, setReSignReload] = useState(false);
  const [loadCi, setLoadCi] = useState(true);
  const [file, setFile] = useState(null);
  const defaultValues = {
    audioUri: null
  };
  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema)
  });

  const { handleSubmit, watch, setValue, errors } = methods;
  //const { handleSubmit } = methods;

  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
    setReSign(false);
    setLoadCi(true);
    setReSignReload(false);
  };

  const onSubmit = async (d) => {
    if (!sketch.canUndo()) {
      NotificationManager.warning(msgValidation);
      return;
    }

    let obj = getDataForm(d);
    obj.prescriptionId = selectedPrescription.id;
    obj.sign = sketch.toDataURL();

    await callApiAsync(
      () => postConsentSaveSign(obj),
      (data) => {
        NotificationManager.success(msgSuccess);
        dispatch(setConsentPath(data));
        dispatch(callReloadPatientData(true));
        handleRequestClose();
      },
      fnSetLoader
    );
  };

  const isGeneric = () => {
    if (getUserInfo().shardKey === ShardKeyEnum.pintPharma) {
      return false;
    }

    return true;
  };

  return (
    <div>
      <InfoCard
        data={{
          icon: "assignment-check",
          title: IntlGetText("ci.cardTitle"),
          subTitle: IntlGetText("ci.cardSubtitle"),
          color: !consentSigned ? "#E53935" : "#20c997"
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="ci.dlgTitle" />
        </DialogTitle>
        {(!consentPath || reSign) && !loadCi && (
          <DialogContent>
            <DownloadButton
              fileLink={referralConsentFile}
              keyMsg="consent.referralFile"
            />
            <br></br>
            <br></br>
            {!isGeneric() && (
              <>
                <DialogContentText>
                  De manera libre y voluntaria manifiesto mi consentimiento para
                  participar en el programa de educación y apoyo a pacientes de
                  la compañía Pint Pharma Colombia SAS, con el fin de
                  beneficiarme de los servicios de dicho programa, en
                  concordancia con el tratamiento ordenado por mi médico
                  tratante.
                </DialogContentText>
                <DialogTitle className="text-grey">
                  A. Introducción y propósito del Programa de Apoyo a Pacientes:
                </DialogTitle>
                <DialogContentText>
                  El programa de apoyo a pacientes es un programa creado para
                  brindar educación, seguimiento y acompañamiento a los
                  pacientes con diagnóstico de enfermedad crónica, huérfana,
                  ruinosa y/o catastrófica y sus cuidadores, que busca el
                  bienestar de sus beneficiarios por medio de la generación de
                  estrategias de educación, afrontamiento y empoderamiento de su
                  estilo de vida, ayudándoles a alcanzar sus metas.
                </DialogContentText>
                <DialogTitle className="text-grey">
                  B. Descripción del Programa de Apoyo a Pacientes:
                </DialogTitle>
                <DialogContentText>
                  El Programa de Apoyo a Pacientes incluye las siguientes
                  actividades: (i) Acompañar, educar, guiar, asesorar y apoyar
                  al paciente en el proceso de aplicación de su tratamiento;
                  (ii) Despejar dudas e inquietudes y educar a los pacientes, su
                  familia y/o cuidadores en temas relacionados con la
                  medicamento; (iii) Permitir el proceso de adaptación del
                  paciente, su familia y/o cuidadores a las diferentes etapas
                  correspondientes a la evolución de la enfermedad; (iv)
                  Fortalecer en el paciente y su familia la aplicación de
                  estrategias de afrontamiento y manejo de la enfermedad; (v)
                  Fortalecer en los pacientes los comportamientos relacionados
                  con el cumplimiento de los parámetros de administración de las
                  dosis referidas por el médico tratante y en general las pautas
                  de adhesión al tratamiento; (iv) Organización y desarrollo de
                  actividades con fin de promover el bienestar y calidad de vida
                  de los pacientes; (vii) Reporte de eventos adversos.
                </DialogContentText>
                <DialogTitle className="text-grey">
                  C. Duración del Programa:
                </DialogTitle>
                <DialogContentText>
                  El Programa de Educación y Apoyo a Pacientes durará el término
                  durante el cual el Paciente permanezca en tratamiento.
                </DialogContentText>
                <DialogTitle className="text-grey">
                  D. Habeas Data y Confidencialidad:
                </DialogTitle>
                <DialogContentText>
                  Pint Pharma Colombia S.A.S se obliga a cumplir y a hacer
                  cumplir a sus trabajadores, subordinados y contratistas todas
                  las disposiciones legales en materia de Habeas Data y
                  protección de la información, establecidas en la Ley 1581 de
                  2012 y en el Decreto Reglamentario 1377 de 2013, o aquellas
                  normas que las modifiquen, subroguen o sustituyan. El manejo
                  de datos sensibles y datos personales recibirá un tratamiento
                  confidencial con el fin de garantizar la protección de la
                  información de las personas que consten en bases de datos
                  manejadas por Pint Pharma Colombia S.A.S, quienes se
                  comprometen a no transferir ni transmitir dicha información a
                  terceros sin el previo consentimiento del paciente y siendo
                  esta siempre de carácter estadístico estadístico o, con fines
                  de{" "}
                  <b>
                    investigación científica y/o publicación de artículos de
                    investigación
                  </b>
                  . De ninguna manera se incluirán datos que permitan la
                  identificación de pacientes, familiares y/o cuidadores.
                </DialogContentText>
                <DialogContentText>
                  Expresamente reconozco que el Programa de Apoyo a Pacientes
                  constituye un simple complemento al tratamiento ordenado por
                  mi médico tratante. Teniendo en cuenta lo anterior, de manera
                  libre y voluntaria expreso:
                </DialogContentText>
                <DialogContentText>
                  1. Que he decidido suministrar información personal y datos de
                  contacto al Programa de Apoyo a Pacientes de Pint Pharma
                  Colombia S.A.S
                </DialogContentText>
                <DialogContentText>
                  2. Que autorizo expresamente que dicho Programa haga uso de la
                  citada información, tanto para efectos estadísticos como para
                  fines de,{" "}
                  <b>
                    investigación científica y, publicación de artículos de
                    investigación
                  </b>
                  , como para mantener un contacto permanente conmigo y para
                  adelantar las labores de carácter administrativo que sean
                  necesarias para procurar el cumplimiento del tratamiento. Para
                  que, además, bajo los términos estipulados en este
                  consentimiento dicha información pueda ser transmitida a
                  terceros debidamente autorizados únicamente con fines
                  estadísticos o de{" "}
                  <b>
                    investigación científica y publicación de artículos de
                    investigación
                  </b>
                  , siempre y cuando se observe la obligación de no incluir
                  datos que permitan la identificación de mi persona, familiares
                  y/o cuidadores.
                </DialogContentText>
                <DialogContentText>
                  3. Que autorizo expresamente al Programa de Apoyo a Pacientes
                  para que, en caso de ser necesario, contacte a mi médico
                  tratante.
                </DialogContentText>
                <DialogContentText>
                  4. Que autorizo a Pint Pharma Colombia S.A.S. para que, de ser
                  necesario, se ponga en contacto con mi médico tratante, en
                  caso de que se presente algún tipo de evento adverso y a
                  realizar el reporte debido a acuerdo a la legislación local
                  vigente.
                </DialogContentText>
                <DialogContentText>
                  5. Que entiendo y acepto que la adhesión al Programa de Apoyo
                  a Pacientes es voluntaria y, asimismo, el retiro de este puede
                  hacerse voluntariamente y en cualquier momento.
                </DialogContentText>
                <DialogContentText>
                  En señal de comprensión y aceptación de todos y cada uno de
                  los aspectos arriba enunciados, suscribo el presente documento
                  de liberación de información y consentimiento informado.
                </DialogContentText>
              </>
            )}
            {isGeneric() && ciDisplayable && (
              <PdfViewer src={ciDisplayable} height="400" width="100%" />
            )}

            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-4 mb-4">
              <Form
                methods={methods}
                prescriptionId={selectedPrescription.id}
              />
            </div>
            <DialogContentText>
              <IntlMessages id="ci.patientSignature" />{" "}
            </DialogContentText>
            <div
              className="text-center"
              style={{ border: "1px solid lightgray" }}
            >
              <SketchField
                ref={(c) => setSketch(c)}
                width="100%"
                height="200px"
                tool={Tools.Pencil}
                lineColor="black"
                lineWidth={2}
              />
            </div>
          </DialogContent>
        )}
        {(consentPath || audioUrl) && !reSignReload && (
          <DialogContent>
            <Grid container spacing={2}>
              {consentPath && (
                <Grid item xs={12} md={12} lg={12}>
                  <DownloadButton
                    variant="contained"
                    color="primary"
                    fileLink={consentPath}
                    keyMsg="core.Download"
                  />
                </Grid>
              )}
              {audioUrl && (
                <Grid item xs={12} md={12} lg={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.open(audioUrl, "_blank")}
                    startIcon={<i className="zmdi zmdi-link" />}
                  >
                    <IntlMessages id="core.openAudio" />
                  </Button>
                </Grid>
              )}
              {referralConsentFile && (
                <Grid item xs={12} md={12} lg={12}>
                  <DownloadButton
                    fileLink={referralConsentFile}
                    keyMsg="consent.referralFile"
                    variant="outlined"
                    color="primary"
                  />
                </Grid>
              )}
              <Grid item xs={12} md={12} lg={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<i className="zmdi zmdi-edit" />}
                  onClick={() => setReSignReload(true)}
                >
                  <IntlMessages id="ci.reSign" />
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        {((!consentPath && !audioUrl) || reSignReload) && !reSign && loadCi && (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<i className="zmdi zmdi-edit" />}
                  onClick={() => {
                    setLoadCi(false);
                    setReSign(true);
                  }}
                >
                  <IntlMessages id="ci.sign" />
                </Button>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <AudioForm
                  handleRequestClose={handleRequestClose}
                  selectedPrescription={selectedPrescription}
                  fnSetLoader={fnSetLoader}
                  methods={methods}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CardFileUpload
                  disabled={false}
                  file={file}
                  setFile={(file) => {
                    setFile(file);
                    dispatch(setConsentPath(file));
                    dispatch(callReloadPatientData(true));
                    handleRequestClose();
                  }}
                  actionApi={postConsentAddExisting}
                  actionApiInfo={{ PrescriptionId: selectedPrescription.id }}
                  showDownload={false}
                />
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          {((!consentPath && !audioUrl) || reSign) && !loadCi && (
            <>
              <Button onClick={handleRequestClose} color="secondary">
                <IntlMessages id="core.cancel" />
              </Button>
              <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
                <IntlMessages id="core.save" />
              </ButtonAsync>
            </>
          )}
          {(loadCi || (!reSign && consentPath)) && (
            <Button onClick={handleRequestClose} color="secondary">
              <IntlMessages id="core.close" />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConsentDialog;
