import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormCheckbox from "components/Form/Controls/checkbox";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { ShardKeyEnum } from "constants/Enums";
import { getUserInfo } from "app/routes/psp/services/userService";


const getDefaultQuestions = () => [
  {
    questionKey: 1,
    id: 0,
    educationLevelSurveyId: 0,
    answer: false,
    comment: "",
  },
  {
    questionKey: 2,
    id: 0,
    educationLevelSurveyId: 0,
    answer: false,
    comment: "",
  },
  {
    questionKey: 3,
    id: 0,
    educationLevelSurveyId: 0,
    answer: false,
    comment: "",
  },
  {
    questionKey: 4,
    id: 0,
    educationLevelSurveyId: 0,
    answer: false,
    comment: "",
  },
  {
    questionKey: 5,
    id: 0,
    educationLevelSurveyId: 0,
    answer: false,
    comment: "",
  },
  {
    questionKey: 6,
    id: 0,
    educationLevelSurveyId: 0,
    answer: false,
    comment: "",
  },
  {
    questionKey: 7,
    id: 0,
    educationLevelSurveyId: 0,
    answer: false,
    comment: "",
  },
  {
    questionKey: 8,
    id: 0,
    educationLevelSurveyId: 0,
    answer: false,
    comment: "",
  },
  {
    questionKey: 9,
    id: 0,
    educationLevelSurveyId: 0,
    answer: false,
    comment: "",
  },
  {
    questionKey: 10,
    id: 0,
    educationLevelSurveyId: 0,
    answer: false,
    comment: "",
  },
];

const getItemValue = (item) => {
  if (item.answer === true) return true;
  return false;
};

const FormCreateUpdate = ({
  openCRU,
  handleRequestClose,
  impactId,
  obj,
  handleCreateUpdate,
  readOnly,
}) => {

  
  const isBoidor = getUserInfo().shardKey === ShardKeyEnum.boidor;
  let defaultValues = {
    finding: !obj || !obj.finding ? "" : obj.finding,
  };
  
  if (!obj) {
    obj = { educationLevelSurveyDetail: getDefaultQuestions() };
  }

  obj.educationLevelSurveyDetail = obj.educationLevelSurveyDetail.map(
    (item) => {
      if(isBoidor &&  item.questionKey == 1){
        return {
          ...item,
          name: IntlGetText("educationLevelSurvey.question" + item.questionKey + "Lemax"),
        }
      }
      return {
        ...item,
        name: IntlGetText("educationLevelSurvey.question" + item.questionKey),
      };
    }
  );

  obj.educationLevelSurveyDetail.map((item) => {
    defaultValues["item" + item.questionKey] = getItemValue(item);
    defaultValues["comment" + item.questionKey] = !item.comment
      ? ""
      : item.comment;
  });

  const methods = useForm({ defaultValues });
  const { handleSubmit, setValue, errors } = methods;

  const loadData = () => {
    setValue("finding", !obj || !obj.finding ? "" : obj.finding);
    obj.educationLevelSurveyDetail.map((item) => {
      setValue("item" + item.questionKey, getItemValue(item));
      setValue("comment" + item.questionKey, !item.comment ? "" : item.comment);
    });
  };

  useEffect(() => {
    if (openCRU) {
      setTimeout(loadData, 150);
    }
  }, [openCRU]);

  const onSubmit = async (d, e) => {
    var survey = {
      id: !obj || !obj.id ? 0 : obj.id,
      impactId: impactId,
      finding: d.finding,
    };

    var questionList = [];
    var questionKeyList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    questionKeyList.map((itemKey) => {
      let itemData = obj.educationLevelSurveyDetail.find(
        (x) => x.questionKey === itemKey
      );
      questionList.push({
        ...itemData,
        answer: d["item" + itemKey],
        comment: d["comment" + itemKey],
      });
    });
    survey.educationLevelSurveyDetail = questionList;

    await handleCreateUpdate(survey);
  };

  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);

  const allowEdit = () => {
    if (!obj || !obj.id) return true;

    if (readOnly) return false;

    return obj.impactId == impactId;
  };
  const disabled = !allowEdit();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!obj.id ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                {obj.educationLevelSurveyDetail.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormCheckbox
                          name={"item" + item.questionKey}
                          label={item.name}
                          onChange={chkHandleChange("item" + item.questionKey)}
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormInput
                          name={"comment" + item.questionKey}
                          label={<IntlMessages id="core.comment" />}
                          multiline
                          rows={2}
                          variant="outlined"
                          margin="normal"
                          disabled={disabled}
                          errorobj={errors}
                        />
                      </Grid>
                    </React.Fragment>
                  );
                })}
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    name="finding"
                    label={<IntlMessages id="educationLevelSurvey.findings" />}
                    multiline
                    rows={4}
                    variant="outlined"
                    margin="normal"
                    disabled={disabled}
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          {!disabled && (
            <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
              <ButtonAsync onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
              >
                {<IntlMessages id="core.save" />}
              </ButtonAsync>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FormCreateUpdate;
