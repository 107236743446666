import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox } from "@material-ui/core";

function FormCheckBox(props) {
  const { control } = useFormContext();
  const { className, name, label, defaultValue, ...chkProps } = props;

  return (
    <FormControlLabel
      control={
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue} 
          render={({ onBlur, onChange, value }) => (
            <Checkbox
              type="checkbox"
              color="primary"
              //onChange={(e) => onChange(e.target.checked)}
              onChange={(value)=>{
                onChange(value);
                if(props.onChange)
                  props.onChange(value);
              }}            
              checked={value}
              className={className}
              {...chkProps}
            />
          )}
        />
      }
      label={label}
    />
  );
}

export default FormCheckBox;
