import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormCheckbox from "components/Form/Controls/checkbox";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  MedicineTypeList,
} from "constants/Enums";
const FormCRU =({ rowObj2, impactId, unitList, unitDurationList, prescription, openCRU, handleRequestClose, handleCreateUpdate })=>{
    const msgSuccess = IntlGetText("core.SuccessSave");
  
    const [ rowObj, setCurrentObj] = useState(null);
    const [medicineType, setMedicineType] = useState("1");
    const [disabled, setDisabled] = useState(false);
  
    const defaultValues = {
      formulaCode: "",
      formulaStartDate: null,
      startDate: null,
      quantity: "",
      supplyFrequency: "",
      durationTreatment: "",
      supplyFrequencyUnitId: null,
      durationTreatmentUnitId: null,
    };
  
    const validationSchema = {
      startDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
      quantity: yup.string()
      .matches(/^[0-9]+$/, IntlGetText("yup.numericValidation"))
      .test("is-valid-range",  IntlGetText("yup.invalidInput"), (value) => {
          const number = Number(value);
          return number > 0 && number < 1001;
      })
      .required("Required"),
      supplyFrequency: yup.string()
      .matches(/^[0-9]+$/, IntlGetText("yup.numericValidation"))
      .test("is-valid-range",  IntlGetText("yup.invalidInput"), (value) => {
          const number = Number(value);
          return number > 0 && number < 1001;
      })
      .required("Required"),
      durationTreatment: yup.string()
      .matches(/^[0-9]+$/, IntlGetText("yup.numericValidation"))
      .test("is-valid-range",  IntlGetText("yup.invalidInput"), (value) => {
          const number = Number(value);
          return number > 0 && number < 1001;
      })
      .required("Required"),
      durationTreatmentUnitId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
      supplyFrequencyUnitId:yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    };
  
    const methods = useForm({ 
      defaultValues,
      resolver: yupResolver(yup.object().shape(validationSchema)),
    });
    const { handleSubmit, watch, setValue, reset, errors } = methods;
    
    const lifeTimeValue = watch("lifeTime");
    const cboMedicineTypeChange = (name) => (event) => {
      setValue(name, event);
      setValue("productId", "");
      setValue("activePrinciple", "");
      setMedicineType(event ? event.value : null);
    };
    const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);
    const cboHandleChange = (name) => (event) => {
      setValue(name, event);
    };
    const onSubmit = async (d, e) => {
      var obj = {
        id: !rowObj ? 0 : rowObj.id,
        impactId: !rowObj ? impactId : rowObj.impactId,
        quantity: Number(d.quantity),
        supplyFrequency: Number(d.supplyFrequency),
        supplyFrequencyUnitId: Number(d.supplyFrequencyUnitId.value),
        durationTreatment: Number(d.durationTreatment),
        durationTreatmentUnitId: Number(d.durationTreatmentUnitId.value),
        startDate: !d.startDate ? null : dayjs(d.startDate).format("YYYY-MM-DDTHH:mm:ss"),
      };
      await handleCreateUpdate(obj);
      handleRequestClose();
    };
  
    useEffect(()=>{  
      setCurrentObj(rowObj2);
      if ( rowObj2 ) {
        setDisabled(true);
      }
    },[rowObj2]);
  
    const loadData = () => {
      //ssetUnitList(rowObj.dosageUnitTimeList);
      setValue("formulaCode", !rowObj ? prescription.code : rowObj.formulaCode);
      setValue("formulaStartDate", !rowObj ? prescription.expeditionDate : rowObj.formulaStartDate);
      setValue("startDate", !rowObj ? "" : rowObj.startDate);
      setValue("quantity", !rowObj ? "" : rowObj.quantity);
      setValue("supplyFrequency", !rowObj ? "" : rowObj.supplyFrequency);
      setValue("durationTreatment", !rowObj ? "" : rowObj.durationTreatment);
      setValue("supplyFrequencyUnitId", getCboValue(unitList, !rowObj ? null :rowObj.supplyFrequencyUnitId));
      setValue("durationTreatmentUnitId", getCboValue(unitList, !rowObj ? null :rowObj.durationTreatmentUnitId));
      //setValue("unitTime", getCboValue(rowObj.dosageUnitTimeList, rowObj.durationTreatmentUnitId));
      //setValue("period", getCboValue(rowObj.dosageUnitTimeList, rowObj.supplyFrequencyUnitId));
    };
    const getCboValue = (items, id) => {
      if (typeof id == "object") {
        return id;
      }
      const result = items.find((x) => x.id === id);
      return result ? { label: result.name, value: result.id } : "";
    };
    useEffect(()=>{
      if(openCRU){
        setTimeout(loadData,150);
      }
    },[rowObj,openCRU]);
  
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openCRU}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id={!rowObj ? "core.add" : "core.edit"} />
        </DialogTitle>
        <DialogContent>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <FormProvider {...methods}>
              <form className="row" noValidate autoComplete="off">
                <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="formulaCode"
                    label={<IntlMessages id="formula.FormulaCode" />}
                    margin="normal"
                    disabled = {true}
                    //required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormDatePicker
                    name="formulaStartDate"
                    label={<IntlMessages id="core.expeditionDate" />}
                    format="YYYY-MM-DD"
                    margin="normal"
                    disabled = {true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormDatePicker
                    name="startDate"
                    label={<IntlMessages id="additionalMedications.treatmentStartDate" />}
                    format="YYYY-MM-DD"
                    disableFuture={true}
                    margin="normal"
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <FormInput
                      name="quantity"
                      label={<IntlMessages id="core.dosage" />}
                      margin="normal"
                      disabled={false}
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="supplyFrequency"
                    label={<IntlMessages id="formula.supplyFrequency" />}
                    margin="normal"
                    disabled={false}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="supplyFrequencyUnitId"
                    label={<IntlMessages id="formula.unitTime" />}
                    options={unitList}
                    value={watch("unitTime")}
                    onChange={cboHandleChange("unitTime")}
                    margin="normal"
                    disabled={false}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="durationTreatment"
                    label={<IntlMessages id="formula.durationTreatment" />}
                    margin="normal"
                    disabled={false}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="durationTreatmentUnitId"
                    label={<IntlMessages id="formula.period" />}
                    options={unitDurationList}
                    value={watch("unitTime")}
                    onChange={cboHandleChange("unitTime")}
                    margin="normal"
                    disabled={false}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
              </form>
            </FormProvider>
            <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
              <ButtonAsync onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
              >
                {<IntlMessages id="core.save" />}
              </ButtonAsync>
            </div>
          </div>  
        </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color="secondary">
              <IntlMessages id="core.close" />
            </Button>
          </DialogActions>
        </Dialog>  
    );
  };

  export default FormCRU;