import React from "react";
import IntlMessages, {IntlGetText} from "util/IntlMessages";
import * as dayjs from "dayjs";
import {
  Grid,
} from "@material-ui/core";

const Indicator =({ indicatorInfo, isRemodulin })=>{
  const msgDays = IntlGetText("medicineConsumption.days");
  
  const getIndicador = (prop) => {
    if (!indicatorInfo) return "";
    var p = indicatorInfo[prop];
    if (!p && p !== 0) return "";
    return p;
  };
  const getEndDate = () => {
    if (!indicatorInfo) return "";
    var p = indicatorInfo["endDate"];
    if (!p && p !== 0) return "";
    return dayjs(p).format("YYYY/MM/DD");
  };
  const getIndicadorProjectedConsumption = () => {
    if (!indicatorInfo) return "";

    const quantity = Math.ceil(indicatorInfo.projectedConsumption);
    const days = indicatorInfo.projectedConsumptionDays;

    return quantity + ` (${days} ${msgDays})`;
  };

  return (
    <Grid container spacing={2} className="mb-3">
      {!isRemodulin &&
        <React.Fragment>
          <Grid item xs={12} md={6} lg={6}>
            <label>
              <strong>
                <IntlMessages id="medicineDelivery.realConsumption" />
                {":"}
              </strong>
              {"    " + getIndicador("realConsumption")}
            </label>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <label>
              <strong>
                <IntlMessages id="medicineConsumption.indicator.projectedConsumption" />
                {":"}
              </strong>
              {"    " + getIndicadorProjectedConsumption()}
            </label>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <label>
              <strong>
                <IntlMessages id="medicineConsumption.indicator.remainder" />
                {":"}
              </strong>
              {"    " + getIndicador("remainder")}
            </label>
          </Grid>      
        </React.Fragment>
      }
      <Grid item xs={12} md={6} lg={6}>
        <label>
          <strong>
            <IntlMessages id="medicineConsumption.indicator.endDate" />
            {":"}
          </strong>
          {"    " + getEndDate() }
        </label>
      </Grid>
    </Grid>
  );
};

export default Indicator;