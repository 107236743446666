import React, { useState } from "react";
import { IntlGetText } from "util/IntlMessages";
import InfoCard from "components/InfoCard/index";
import DlgStates from "../dlgStates";

const PrescriptionStateBox = (props) => {
  const [openDlg, setOpenDlg] = useState(false);
  const requestCloseDlg = () => setOpenDlg(false);

  return (
    <>
      <InfoCard
        data={{
          icon: "book",
          title: IntlGetText("core.states"),
          subTitle: IntlGetText("alert.cardSubTitle"),
          color: "#757575",
        }}
        onClickBox={() => setOpenDlg(true)}
      />
      <DlgStates
        openDlg={openDlg}
        requestCloseDlg={requestCloseDlg}
        {...props}
      />
    </>
  );
};

export default PrescriptionStateBox;
