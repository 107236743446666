import {
  PHARMA_FETCH_ALL_MAIL,
  PHARMA_GET_ALL_MAIL,
  PHARMA_GET_IMPORTANT_MAIL,
  PHARMA_GET_NAV_FILTERS,
  PHARMA_GET_NAV_FOLDER,
  PHARMA_GET_NAV_LABELS,
  PHARMA_GET_READ_MAIL,
  PHARMA_GET_STARRED_MAIL,
  PHARMA_GET_UNIMPORTANT_MAIL,
  PHARMA_GET_UNREAD_MAIL,
  PHARMA_GET_UNSELECTED_ALL_MAIL,
  PHARMA_GET_UNSTARRED_MAIL,
  PHARMA_HANDLE_REQUEST_CLOSE,
  PHARMA_ON_ALL_MAIL_SELECT,
  PHARMA_ON_COMPOSE_MAIL,
  PHARMA_ON_DELETE_MAIL,
  PHARMA_ON_FOLDER_MENU_ITEM_SELECT,
  PHARMA_ON_FOLDER_SELECT,
  ON_HIDE_LOADER,
  PHARMA_ON_IMPORTANT_SELECT,
  PHARMA_ON_LABEL_MENU_ITEM_SELECT,
  PHARMA_ON_LABEL_SELECT,
  PHARMA_ON_MAIL_CHECKED,
  PHARMA_ON_MAIL_SELECT,
  PHARMA_ON_MAIL_SEND,
  PHARMA_ON_OPTION_MENU_ITEM_SELECT,
  PHARMA_ON_OPTION_MENU_SELECT,
  PHARMA_ON_START_SELECT,
  ON_TOGGLE_DRAWER,
  PHARMA_SEARCH_MAIL,
  PHARMA_SET_CURRENT_MAIL_NULL,
  UPDATE_SEARCH,
  PHARMA_PROCESS_STATE_CHANGE,
  PHARMA_PATIENT_CONTACT_SAVE,
} from "../constants/ActionTypes";
import {
  PHARMA_FETCH_ALL_MAIL_SUCCESS,
  SHOW_MESSAGE,
} from "constants/ActionTypes";

export const fetchMails = () => {
  return {
    type: PHARMA_FETCH_ALL_MAIL,
  };
};

export const fetchMailsSuccess = (mails) => {
  return {
    type: PHARMA_FETCH_ALL_MAIL_SUCCESS,
    payload: mails,
  };
};
export const showMailMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const onFolderMenuItemSelect = (folderId) => {
  return {
    type: PHARMA_ON_FOLDER_MENU_ITEM_SELECT,
    payload: folderId,
  };
};

export const onMailLabelMenuItemSelect = (label) => {
  return {
    type: PHARMA_ON_LABEL_MENU_ITEM_SELECT,
    payload: label,
  };
};

export const onProcessStateChange = (label) => {
  return {
    type: PHARMA_PROCESS_STATE_CHANGE,
    payload: label,
  };
};

export const onPatientContactSave = (patient) => {
  return {
    type: PHARMA_PATIENT_CONTACT_SAVE,
    payload: patient,
  };
};

export const handleMailRequestClose = () => {
  return {
    type: PHARMA_HANDLE_REQUEST_CLOSE,
  };
};

export const getMailNavFilters = (filter) => {
  return {
    type: PHARMA_GET_NAV_FILTERS,
    payload: filter,
  };
};
export const onFolderSelect = () => {
  return {
    type: PHARMA_ON_FOLDER_SELECT,
  };
};
export const onMailLabelSelect = () => {
  return {
    type: PHARMA_ON_LABEL_SELECT,
  };
};
export const onMailOptionMenuSelect = () => {
  return {
    type: PHARMA_ON_OPTION_MENU_SELECT,
  };
};
export const onOptionMenuItemSelect = (option) => {
  return {
    type: PHARMA_ON_OPTION_MENU_ITEM_SELECT,
    payload: option,
  };
};
export const getAllMail = () => {
  console.log("getAllMail");
  return {
    type: PHARMA_GET_ALL_MAIL,
  };
};
export const getUnselectedAllMail = () => {
  return {
    type: PHARMA_GET_UNSELECTED_ALL_MAIL,
  };
};
export const getReadMail = () => {
  return {
    type: PHARMA_GET_READ_MAIL,
  };
};
export const getUnreadMail = () => {
  return {
    type: PHARMA_GET_UNREAD_MAIL,
  };
};
export const getStarredMail = () => {
  return {
    type: PHARMA_GET_STARRED_MAIL,
  };
};

export const getUnStarredMail = () => {
  return {
    type: PHARMA_GET_UNSTARRED_MAIL,
  };
};
export const getImportantMail = () => {
  return {
    type: PHARMA_GET_IMPORTANT_MAIL,
  };
};
export const getUnimportantMail = () => {
  return {
    type: PHARMA_GET_UNIMPORTANT_MAIL,
  };
};
export const getMailNavLabels = (label) => {
  return {
    type: PHARMA_GET_NAV_LABELS,
    payload: label,
  };
};
export const onSearchMail = (searchText) => {
  return {
    type: PHARMA_SEARCH_MAIL,
    payload: searchText,
  };
};
export const onMailChecked = (data) => {
  return {
    type: PHARMA_ON_MAIL_CHECKED,
    payload: data,
  };
};

export const onAllMailSelect = () => {
  return {
    type: PHARMA_ON_ALL_MAIL_SELECT,
  };
};
export const onStartSelect = (data) => {
  return {
    type: PHARMA_ON_START_SELECT,
    payload: data,
  };
};
export const onImportantSelect = (data) => {
  return {
    type: PHARMA_ON_IMPORTANT_SELECT,
    payload: data,
  };
};
export const onMailSend = (data) => {
  return {
    type: PHARMA_ON_MAIL_SEND,
    payload: data,
  };
};
export const onMailSelect = (mail) => {
  return {
    type: PHARMA_ON_MAIL_SELECT,
    payload: mail,
  };
};
export const getNavFolders = (folder) => {
  return {
    type: PHARMA_GET_NAV_FOLDER,
    payload: folder,
  };
};

export const updateMailSearch = (searchText) => {
  return {
    type: UPDATE_SEARCH,
    payload: searchText,
  };
};
export const onMailToggleDrawer = () => {
  return {
    type: ON_TOGGLE_DRAWER,
  };
};
export const onDeleteMail = () => {
  return {
    type: PHARMA_ON_DELETE_MAIL,
  };
};
export const hideMailLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
export const setCurrentMailNull = () => {
  return {
    type: PHARMA_SET_CURRENT_MAIL_NULL,
  };
};
export const onComposeMail = () => {
  return {
    type: PHARMA_ON_COMPOSE_MAIL,
  };
};
