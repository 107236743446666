import React from "react";
import CalendarPlanning from "./Calendar";

const ImpactPlanning = () => {
  return (
      <div className="app-wrapper">
        <div className="animated slideInUpTiny animation-duration-3">
          <div className="app-module">
            <CalendarPlanning/>
          </div>
        </div>
      </div>
  );
};    

export default ImpactPlanning;