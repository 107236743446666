import React, { useState, useEffect }  from "react";
import { NotificationManager } from "react-notifications";
import { IntlGetText } from "util/IntlMessages";
import * as dayjs from "dayjs";
import MUIDataTable from "mui-datatables";
import {
  callActionApi,
  getAdditionalMedications
} from "api/apiList";

const AdditionalMedicationsTable = ({ options, lastPrescriptionId }) => { 
  const [data, setData] = useState(null);
  
  const getObj = (tableMeta) => data.find(x=>x.id === tableMeta.rowData[0]);

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display:"excluded"
      }
    },
    {
      name: "activePrinciple",
      label: IntlGetText("core.activePrinciple"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "dosage",
      label: IntlGetText("core.dosage"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return rowObj.measurementUnit ? value + " " + rowObj.measurementUnit : value;
        }
      }
    },
    {
      name: "administrationRoute",
      label: IntlGetText("formula.administrationRoute"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "supplyFrequency",
      label: IntlGetText("formula.supplyFrequency"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "treatmentStartDate",
      label: IntlGetText("additionalMedications.treatmentStartDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    {
      name: "treatmentEndDate",
      label: IntlGetText("additionalMedications.treatmentEndDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          if(rowObj.lifeTime)
            return "De por vida";
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    }
  ];
  
    
  useEffect(() => {
    if(!lastPrescriptionId)
      return;

    callActionApi(
      () => getAdditionalMedications(lastPrescriptionId),
      setData,
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  }, [lastPrescriptionId]);

  return (
    <MUIDataTable
      className="border-dialog-card mt-1"
      data={data ? data : []}
      columns={columns}
      options={options}
    />
  );
};

export default AdditionalMedicationsTable;