import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Grid, Button } from "@material-ui/core";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import DownloadReportFile from "components/Reports/downloadReportFile";
import PrescripctionsReport from "./prescriptions"
import DeliveriesReport from "./deliveries"
import ImpactsReport from "./impacts"
import PrescripctionsTotalReport from "./prescriptionsTotal"
import { 
  getPrescriptionReportFile,
  getDeliveriesReportFile,
  getImpactsReportFile,
  GetPrescriptionsTotalReportFile
} from "api/apiList";
import {
  ReportList,
  ReportListEnum
} from "constants/Enums";

const Reports = () => {
  const [dateRange, setDateRange] = useState(null);
  const msgEndDate =
    IntlGetText("clinicalFindingState.endDateInvalid") +
    IntlGetText("core.startDate");

  const defaultValues = {
    startDate: null
  };

  const validationSchema = {
    startDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />)
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { errors, trigger, getValues, watch } = methods;

  const reportValue = watch("report");

  const showReport = (reportId) =>
    reportValue && reportValue.value === reportId;

  const getAction=(reportItem)=>{
    if(!reportItem)
      return;
    const reportId = reportItem.value;
    switch(reportId){
      case ReportListEnum.prescriptions: return getPrescriptionReportFile;
      case ReportListEnum.deliveries: return getDeliveriesReportFile;
      case ReportListEnum.impacts: return getImpactsReportFile;
      case ReportListEnum.callAgent: return GetPrescriptionsTotalReportFile;
    }
    return null;
  }

  const showDateRange = (reportId) => {
    if (!reportValue) return true;

    return true;
    // var reportId = reportValue.value;
    // return (
    //   reportId !== ReportEnum.controlPanelRemodulin &&
    //   reportId !== ReportEnum.controlPanelIclusig &&
    //   reportId !== ReportEnum.controlPanelGalafold &&
    //   reportId !== ReportEnum.pendingRSVariables &&
    //   reportId !== ReportEnum.suspendedPatients &&
    //   reportId !== ReportEnum.externalReports
    // );
  };

  const getReportList = () => ReportList();

  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-4">
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} lg={3}>
              <FormSelectAutoComplete
                name="report"
                label={<IntlMessages id="core.report" />}
                options={getReportList()}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            {showDateRange() && (
              <>
                <Grid item xs={12} md={3} lg={3}>
                  <FormDatePicker
                    name="startDate"
                    label={<IntlMessages id="core.startDate" />}
                    format="YYYY-MM-DD"
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <FormDatePicker
                    name="endDate"
                    label={<IntlMessages id="core.endDate" />}
                    format="YYYY-MM-DD"
                    margin="normal"
                    errorobj={errors}
                  />
                </Grid>

                <Grid item xs={12} md={1} lg={1}>
                  <Button
                    onClick={async () => {
                      if (!(await trigger())) return;
                      var range = getValues();
                      var rangeFormated = {
                        startDate: dayjs(range.startDate).format(
                          "YYYY-MM-DDTHH:mm:ss"
                        ),
                        endDate: !range.endDate ? 
                          dayjs().format("YYYY-MM-DDTHH:mm:ss"): 
                          dayjs(range.endDate).format("YYYY-MM-DDTHH:mm:ss"),
                      };
                      setDateRange(rangeFormated);
                    }}
                    className="mt-3"
                    variant="contained"
                    color="primary"
                  >
                    <IntlMessages id="core.search" />
                  </Button>
                </Grid>
                <Grid item xs={12} md={1} lg={1}>
                  <DownloadReportFile
                    dateRange={dateRange}
                    actionApi={getAction(reportValue)}
                    className="mt-3"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </FormProvider>
      </div>
      
      {showReport(ReportListEnum.prescriptions) && (
        <PrescripctionsReport dateRange={dateRange} />
      )}
      {showReport(ReportListEnum.deliveries) && (
        <DeliveriesReport dateRange={dateRange} />
      )}
      {showReport(ReportListEnum.impacts) && (
        <ImpactsReport dateRange={dateRange} />
      )}
      {showReport(ReportListEnum.callAgent) && (
        <PrescripctionsTotalReport dateRange={dateRange} />
      )}
    </>
  );
};

export default Reports;
