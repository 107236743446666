import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages from "util/IntlMessages";
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormCheckbox from "components/Form/Controls/checkbox";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import FormSelectAutoComplete, { getCboValue } from "components/Form/Controls/select-autocomplete";
import CommentTable from "./CommentTable";
import {
  callApi,
  callApiAsync,
  getCommentsByQuery,
  postCreateUpdateCommentToQuery
} from "api/apiList";
import { 
  QueryAdverseEventTypeList, 
  QueryAdverseEventStateList,
  RoleEnum
} from "constants/Enums";
import { getRoleUser } from "app/routes/psp/services/userService";

const FormComment =({ query, openCRU, handleRequestClose, readOnly, reloadQueries, setCurrentRowObj})=>{
  const [commentList, setCommentList] = useState([]);
  const [currentComment, setCurrentComment] = useState(null);

    const queryAdverseEventStateList = QueryAdverseEventStateList();
    const queryAdverseEventTypeList = QueryAdverseEventTypeList();
    const userInfo = getRoleUser();
    const isNurseProfile = () =>{
      const role = userInfo.role.toUpperCase();
      return role === RoleEnum.nurse;
    };
  
    const defaultValues = {
      creationDate: null,
      type: null,
      state: null,
      description: null,
      comment: "",
      assignedToNurse: false
    };
  
    const validationSchema = {      
      comment: yup.string().required(<IntlMessages id="core.fieldRequired" />),
      state: yup .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable()
    };
  
    const methods = useForm({ 
      defaultValues,
      resolver: yupResolver(yup.object().shape(validationSchema)),
    });
    const { handleSubmit, setValue, reset, errors } = methods;
    
    const loadComments = (queryId) => {      
      callApi(() => getCommentsByQuery(queryId), setCommentList);
    };

    useEffect(()=>{
      if(openCRU){
        setTimeout(loadData,150);
        loadComments(query.id);
      }
    },[query,openCRU]);
  
    const setString = (obj, prop) => setValue(prop, !obj || !obj[prop] ? "" : obj[prop]);
    const setDate = (obj, prop) => setValue(prop, !obj || !obj[prop] ? null : dayjs(obj[prop]));
    const setCboValue = (obj, prop, list) => setValue(prop, !obj ? null : getCboValue(list, obj[prop]));
    const setBool = (obj, prop) => setValue(prop, !obj || !obj[prop] ? false : obj[prop]);

    const loadData = () => {
      setDate(query,"creationDate");
      setCboValue(query, "type", queryAdverseEventTypeList);
      setCboValue(query, "state", queryAdverseEventStateList);
      setString(query, "description");
      setBool(query, "assignedToNurse");      
    };
  
    const setEditComment = (objComment) => {
      setCurrentComment(objComment);
      setValue("comment", !objComment ? "" : objComment.comment);
    };

    const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);

    const onSubmit = async (d, e) => {
      var obj = {
        id: currentComment ? currentComment.id : 0,
        queryAdverseEventId: query.id,
        comment: d.comment,
        queryState: Number(d.state.value),
        assignedToNurse: d.assignedToNurse
      };
      await callApiAsync(
        () => postCreateUpdateCommentToQuery(obj),
        () => {
          loadComments(query.id);
          reset({ ...d, comment: "" });
          setCurrentComment(null);
          reloadQueries();
          setCurrentRowObj({...query, state: obj.queryState, assignedToNurse: obj.assignedToNurse});
        }
      );
    };
  
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openCRU}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id={!query ? "core.add" : "core.edit"} />
        </DialogTitle>
        <DialogContent>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <FormProvider {...methods}>
              <form className="row" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormSelectAutoComplete
                      name="type"
                      label={<IntlMessages id="core.type" />}
                      options={queryAdverseEventTypeList}
                      margin="normal"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormDatePicker
                      name="creationDate"
                      label={<IntlMessages id="core.creationDate" />}
                      format="YYYY-MM-DD"
                      margin="normal"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormInput
                      label={<IntlMessages id="core.description" />}
                      name="description"
                      disabled={true}
                      multiline
                      rows={2}
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormSelectAutoComplete
                      name="state"
                      label={<IntlMessages id="core.state" />}
                      options={queryAdverseEventStateList}
                      margin="normal"
                      disabled={readOnly}
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                  {!isNurseProfile() && 
                    <Grid item xs={12} md={12} lg={12}>
                      <FormCheckbox
                        name="assignedToNurse"
                        label={<IntlMessages id="queryAdverseEvent.assignedToNurse" />}
                        onChange={chkHandleChange("assignedToNurse")}
                        disabled={readOnly}
                      />
                    </Grid>
                  }
                  { !readOnly && 
                    <Grid item xs={12} md={12} lg={12}>
                      <FormInput
                        label={<IntlMessages id="core.comment" />}
                        name="comment"
                        required={true}
                        multiline
                        rows={4}
                        variant="outlined"
                        margin="normal"
                        errorobj={errors}
                      />
                    </Grid>
                  }         
                </Grid>
              </form>
            </FormProvider>
            {!readOnly &&
              <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
                <ButtonAsync onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                >
                  {<IntlMessages id="core.save" />}
                </ButtonAsync>
              </div>
            }
            <CommentTable
              data={commentList}
              setCurrentRowObj={setEditComment}
              readOnly={readOnly}
            />
          </div>  
        </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color="secondary">
              <IntlMessages id="core.close" />
            </Button>
          </DialogActions>
        </Dialog>  
    );
  };

export default FormComment;