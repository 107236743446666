import React, { useState } from "react";
import FollowUpTable from "./FollowUpTable";
import ClinicHistoryTable from "./ClinicHistoryTable";
import Auxiliary from "../../../../../util/Auxiliary";
import Widget from "components/Widget";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";

const PatientProfile = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <div className="app-wrapper">
      <Auxiliary>
        <div className="jr-profile-banner">
          <div className="jr-profile-container">
            <div
              className="jr-profile-banner-top"
              style={{ marginBottom: "0px" }}
            >
              <div className="jr-profile-banner-top-left">
                <div className="jr-profile-banner-avatar-info">
                  <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                    Alicia Muñoz Diaz
                  </h2>
                </div>
              </div>
              {/*<div className="jr-profile-banner-top-right">
                <ul className="jr-follower-list">
                  <li>
                    <span className="jr-follower-title jr-fs-lg jr-font-weight-medium">
                      7
                    </span>
                    <span className="jr-fs-sm">Pacientes</span>
                  </li>
                  <li>
                    <span className="jr-follower-title jr-fs-lg jr-font-weight-medium">
                      1
                    </span>
                    <span className="jr-fs-sm">Proceso</span>
                  </li>
                </ul>
            </div>*/}
            </div>
          </div>
        </div>
        {/*  */}
        <div className="jr-profile-content">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
                <div className="card-header"></div>
                <div className="jr-tabs-classic">
                  <Tabs
                    className="jr-tabs-up"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      className="jr-tabs-label"
                      label="Datos de identificación"
                    />
                    <Tab
                      className="jr-tabs-label"
                      label="Info Sociodemográfica"
                    />
                    <Tab className="jr-tabs-label" label="Historia Clínica" />
                    <Tab className="jr-tabs-label" label="Seguimiento" />
                  </Tabs>
                  <div className="jr-tabs-content jr-task-list">
                    <div className="row">
                      {value === 0 && (
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                          <form className="row" noValidate autoComplete="off">
                            <div className="col-md-12 col-12">
                              <TextField
                                id="name"
                                label="Nombre"
                                margin="normal"
                                value={"Alicia Muñoz Diaz"}
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="code"
                                label="Código"
                                value={"RM0001"}
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="city"
                                label="Lugar de residencia"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-12 col-12">
                              <TextField
                                id="address"
                                label="Dirección"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-4 col-12">
                              <TextField
                                id="dateBirth"
                                label="Fecha de Nacimiento"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-4 col-12">
                              <TextField
                                id="age"
                                label="Edad"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-4 col-12">
                              <TextField
                                id="sex"
                                label="Sexo"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="idType"
                                label="Tipo de Documento"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="idNumber"
                                label="Número de Documento de Identidad"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-12 col-12">
                              <TextField
                                id="stratum"
                                label="Estrato Socioeconómico"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="doctor"
                                label="Médico tratante"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="specialty"
                                label="Especialidad"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-12 col-12">
                              <TextField
                                id="eps"
                                label="EPS"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="regimen"
                                label="Régimen"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="mt-2">
                                <FormHelperText className="text-grey">
                                  <h5>Tipo de Afiliación</h5>
                                </FormHelperText>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedA"
                                    />
                                  }
                                  label="Beneficiario"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Cotizante"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="email"
                                label="Correo electrónico"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="phone"
                                label="Teléfono"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-12 col-12">
                              <div className="mt-4 mb-2">
                                <FormHelperText className="text-grey">
                                  <h5>Nivel de Ocupación</h5>
                                </FormHelperText>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedA"
                                    />
                                  }
                                  label="Horas"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Medio Tiempo"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Tiempo Completo"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Independiente"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Dependiente"
                                />
                              </div>
                            </div>

                            <div className="col-md-12 col-12">
                              <div className="mt-2 mb-2">
                                <FormHelperText className="text-grey">
                                  <h5>Escolaridad</h5>
                                </FormHelperText>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedA"
                                    />
                                  }
                                  label="Sin escolaridad"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Primaria"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Secundaria"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Técnico"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Universitario"
                                />
                              </div>
                            </div>

                            <div className="col-md-12 col-12 mt-5 text-grey">
                              <h4>CARACTERÍSTICAS DEL CUIDADOR</h4>
                            </div>
                            <div className="col-md-12 col-12">
                              <TextField
                                id="name"
                                label="Nombre"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="address"
                                label="Dirección"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="age"
                                label="Edad"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-12 col-12">
                              <TextField
                                id="sex"
                                label="Sexo"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="phone"
                                label="Teléfono"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="email"
                                label="Correo electrónico"
                                margin="normal"
                                fullWidth
                              />
                            </div>

                            <div className="col-md-12 col-12">
                              <div className="mt-4 mb-2">
                                <FormHelperText className="text-grey">
                                  <h5>Parentesco</h5>
                                </FormHelperText>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedA"
                                    />
                                  }
                                  label="Pareja"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Padre"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Hijo"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Hermano"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Otros"
                                />
                              </div>
                            </div>

                            <div className="col-md-12 col-12">
                              <div className="mt-2 mb-2">
                                <FormHelperText className="text-grey">
                                  <h5>Escolaridad</h5>
                                </FormHelperText>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedA"
                                    />
                                  }
                                  label="Sin escolaridad"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Primaria"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Secundaria"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Universitario"
                                />
                              </div>
                            </div>

                            <div className="col-md-12 col-12">
                              <div className="mt-2 mb-2">
                                <FormHelperText className="text-grey">
                                  <h5>Nivel de Ocupación</h5>
                                </FormHelperText>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedA"
                                    />
                                  }
                                  label="Horas"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Medio Tiempo"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Tiempo Completo"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Independiente"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Dependiente"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                      {value === 1 && (
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                          <form className="row" noValidate autoComplete="off">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                              <div className="mt-4 mb-2">
                                <FormHelperText className="text-grey">
                                  <h4>
                                    CARACTERÍSTICAS DEL BARRIO O ZONA DE
                                    RESIDENCIA
                                  </h4>
                                </FormHelperText>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedA"
                                    />
                                  }
                                  label="Area urbana"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Area rural"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Zona de tolerancia"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Zona dispersa"
                                />
                              </div>
                            </div>
                            <br></br>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                              <div className="mt-2 mb-2">
                                <FormHelperText className="text-grey">
                                  <h4>SERVICIOS PÚBLICOS Y SALUBRIDAD</h4>
                                </FormHelperText>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedA"
                                    />
                                  }
                                  label="Acueducto"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Alcantarillado"
                                />

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedC"
                                    />
                                  }
                                  label="Luz"
                                />

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedC"
                                    />
                                  }
                                  label="Unidad sanitaria"
                                />

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedC"
                                    />
                                  }
                                  label="Recolección de residuos"
                                />
                              </div>
                            </div>
                            <br></br>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                              <div className="mt-2 mb-2">
                                <FormHelperText className="text-grey">
                                  <h4>
                                    NÚMERO DE HABITACIONES EN LA VIVIENDA Y
                                    DISTRIBUCIÓN
                                  </h4>
                                </FormHelperText>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedA"
                                    />
                                  }
                                  label="Área para preparación de alimentos"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Habitaciones"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedC"
                                    />
                                  }
                                  label="Baño (ducha, lavamanos y sanitario)"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedC"
                                    />
                                  }
                                  label="Área para el manejo de la ropa y elementos de aseo"
                                />
                              </div>
                            </div>
                            <br></br>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                              <div className="mt-2 mb-2">
                                <FormHelperText className="text-grey">
                                  <h4>RED DE APOYO</h4>
                                </FormHelperText>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedA"
                                    />
                                  }
                                  label="Buena"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Regular"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedC"
                                    />
                                  }
                                  label="Mala"
                                />
                              </div>
                            </div>
                            <br></br>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                              <div className="mt-2 mb-2">
                                <FormHelperText className="text-grey">
                                  <h4>TIEMPO AL SERVICIO DE URGENCIAS</h4>
                                </FormHelperText>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedA"
                                    />
                                  }
                                  label="<1 Hora"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="1-2 Horas"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedC"
                                    />
                                  }
                                  label="2 a 3 Horas"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedC"
                                    />
                                  }
                                  label="3 a 4 Horas"
                                />
                              </div>
                            </div>
                            <br></br>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                              <div className="mt-2 mb-2">
                                <FormHelperText className="text-grey">
                                  <h4>
                                    PERCEPCIÓN DE LA ENFERMEDAD POR PARTE DEL
                                    PACIENTE Y CUIDADOR
                                  </h4>
                                </FormHelperText>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedA"
                                    />
                                  }
                                  label="Buena"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="Regular"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedC"
                                    />
                                  }
                                  label="Mala"
                                />
                              </div>
                            </div>
                            <br></br>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                              <div className="mt-2 mb-2">
                                <FormHelperText className="text-grey">
                                  <h4>
                                    CONVIVE CON ANIMALES DOMÉSTICOS EN
                                    INADECUADAS CONDICIONES SANITARIAS
                                  </h4>
                                </FormHelperText>
                                <FormControlLabel
                                  className="mt-2"
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedA"
                                    />
                                  }
                                  label="Sí"
                                />
                                <FormControlLabel
                                  className="mt-2"
                                  control={
                                    <Checkbox
                                      color="primary"
                                      value="checkedB"
                                    />
                                  }
                                  label="No"
                                />
                                <TextField
                                  className="mr-4"
                                  id="pet"
                                  label="Tipo de animal"
                                  margin="none"
                                />
                                <TextField
                                  id="petNumber"
                                  label="Cantidad"
                                  margin="none"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                      {value === 2 && (
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                          <form className="row" noValidate autoComplete="off">
                            <div className="col-md-6 col-12">
                              <TextField
                                required
                                id="doctor"
                                label="Médico tratante"
                                value={"Dra. Julia Ramirez"}
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="specialty"
                                label="Especialidad"
                                value={"Oncología"}
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-12 col-12">
                              <TextField
                                id="diagnostic"
                                label="Diagnóstico principal"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-12 col-12">
                              <TextField
                                id="otherDiagnostic"
                                label="Otros diagnósticos"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-12 col-12">
                              <TextField
                                id="treatment"
                                label="Tratamiento principal"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="treatmentDate"
                                label="Fecha de inicio tratamiento"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <TextField
                                id="otherTreatment"
                                label="Otros tratamientos"
                                margin="normal"
                                fullWidth
                              />
                            </div>
                          </form>
                          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="mt-5 text-uppercase text-grey">
                              <h3>Archivos adjuntos</h3>
                            </div>
                            <div className="mt-4">
                              <ClinicHistoryTable />
                            </div>
                          </div>
                        </div>
                      )}
                      {value === 3 && (
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                          <div className="mt-2">
                            <FollowUpTable />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Widget>
            </div>
          </div>
        </div>
      </Auxiliary>
    </div>
  );
};

export default PatientProfile;
