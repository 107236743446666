import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import FormCRU from "./FormCRU";
import TableAM from "./TableAM";
import {
  callApi,
  callApiAsync,
  getAdditionalMedications,
  postCreateUpdateAdditionalMedication,
  deleteAdditionalMedication,
  getMedication,
} from "api/apiList";
import { callStartImpact } from "actions/Impact";

const AdditionalMedicationsDialog = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentSigned, isCIRequired } = useSelector(({ impact }) => impact);

  const [open, setOpen] = useState(false);
  const [openCRU, setOpenCRU] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentRowObj, setCurrentRowObj] = useState(null);
  const [additionalMedications, setAdditionalMedications] = useState([]);
  const [additionalMedicationsList, setAdditionalMedicationsList] = useState([]);

  const msgStartImpactRequired = IntlGetText("impact.startImpactRequired");
  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const handleClickOpen = () => {
    if(!impactStartDate){
      dispatch(callStartImpact(true));
      return;
    }
    if(isCIRequired && !consentSigned){
        NotificationManager.warning(msgConsentRequired);
        return;
    }
    setOpen(true);
  };
  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleOpenCRU = () => {
    setOpenCRU(true);
  };
  const handleRequestCloseCRU = () => {
    setOpenCRU(false);
  };
  const handleRequestCloseDel = () => {
    setOpenDeleteDialog(false);
  };

  useEffect(() => {
    callApi(
      () => getAdditionalMedications(selectedPrescription.id),
      setAdditionalMedications
    );
    callApi(
      () => getMedication(),
      setAdditionalMedicationsList
    );
  }, []);

  const handleCreateUpdate = async (data) => {
    await callApiAsync(
      () => postCreateUpdateAdditionalMedication(data),
      setAdditionalMedications
    );
  };

  const handleDelete = () => {
    callApi(
      () => deleteAdditionalMedication(currentRowObj.id),
      (data) => {
        setAdditionalMedications(data);
        handleRequestCloseDel();
      }
    );
  };

  return (
    <div>
      <InfoCard
        data={{
          icon: "collection-plus",
          title: IntlGetText("additionalMedications.Medications"),
          subTitle: IntlGetText("additionalMedications.additionals"),
          color: "#757575",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="additionalMedications.dialogTitle" />
        </DialogTitle>
        <DialogContent>
          <TableAM
            data={additionalMedications}
            handleOpenCRU={handleOpenCRU}
            handleOpenDel={() => setOpenDeleteDialog(true)}
            setCurrentRowObj={setCurrentRowObj}
          />
          <FormCRU
            openCRU={openCRU}
            handleRequestClose={handleRequestCloseCRU}
            rowObj2={currentRowObj}
            impactId={selectedPrescription.impactId}
            handleCreateUpdate={handleCreateUpdate}
            additionalMedicationsList ={additionalMedicationsList}
          />
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={openDeleteDialog}
            disableBackdropClick={true}
            onClose={handleRequestCloseDel}
          >
            <DialogTitle>
              <IntlMessages id="additionalMedications.MsgDelConfirmation" />
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleRequestCloseDel} color="secondary">
                <IntlMessages id="core.cancel" />
              </Button>
              <Button onClick={handleDelete} color="primary">
                <IntlMessages id="core.accept" />
              </Button>
            </DialogActions>
          </Dialog>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdditionalMedicationsDialog;
