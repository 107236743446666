import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import { useForm, FormProvider } from "react-hook-form";
import * as dayjs from "dayjs";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormDatePicker from "components/Form/Controls/datepicker";
import {
  callApi,
  getInstitutionPatientInfo
} from "api/apiList";

const PatientDataForm = ({ openDlg, closeDialog, impactInfo, allowEdit = true }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));

  const defaultValues = {
    name: "",
    lastName: "",
    birthdayDate: null,
    age: "",
    phone: "",
    regime: "",
    eps: "",
    treatment: "",
  };

  const loadData = () => {
    callApi(
      () => getInstitutionPatientInfo(impactInfo.patientId),
      (obj) => {
        setValue("name", !obj || !obj.name ? "" : obj.name);
        setValue("lastName", !obj || !obj.lastName ? "" : obj.lastName);
        setValue("birthdayDate", !obj || !obj.birthday ? null : dayjs(obj.birthday));
        setValue("age", !obj || !obj.age ? "" : obj.age);
        setValue("phone", !obj || !obj.phone ? "" : obj.phone);
        setValue("regime", !obj || !obj.regime ? "" : obj.regime);
        setValue("eps", !obj || !obj.insurance ? "" : obj.insurance);
        setValue("treatment", !obj || !obj.treatment ? "" : obj.treatment);
      },
      fnSetLoader
    );
  };

  useEffect(() => {
    if(!openDlg)
      return;

    loadData();
  }, [openDlg]);

  const methods = useForm({
    defaultValues,
  });
  const {setValue, errors } = methods;

  const disabled = !allowEdit;

  return (
    <div>
      <Dialog
        open={openDlg}
        disableBackdropClick={true}
        onClose={closeDialog}
      >
        <DialogTitle>
          <IntlMessages id={"institution.patientData"} />
        </DialogTitle>

        <DialogContent className="new-patient-dialog-content">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="name"
                    label={<IntlMessages id="core.name" />}
                    margin="normal"
                    errorobj={errors}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="lastName"
                    label={<IntlMessages id="core.lastName" />}
                    margin="normal"
                    errorobj={errors}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormDatePicker
                    name="birthdayDate"
                    label={<IntlMessages id="core.birthday" />}
                    format="YYYY-MM-DD"
                    margin="normal"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="age"
                    label={<IntlMessages id="core.age" />}
                    margin="normal"
                    errorobj={errors}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="phone"
                    label={<IntlMessages id="core.phone" />}
                    margin="normal"
                    errorobj={errors}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="regime"
                    label={<IntlMessages id="core.regime" />}
                    margin="normal"
                    errorobj={errors}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="eps"
                    label={<IntlMessages id="radicacionInsurance.Insurance" />}
                    margin="normal"
                    errorobj={errors}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="treatment"
                    label={<IntlMessages id="core.treatment" />}
                    margin="normal"
                    errorobj={errors}
                    disabled
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions className="modal-actions">
          <Button onClick={closeDialog} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PatientDataForm;
