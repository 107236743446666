import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Base from "./Base";
import Settings from "./Settings";
import Mail from "./Mail";
import Auth from "./Auth";
import PharmaMail from "./PharmaMail";
import Prescription from "./Prescription";
import Management from "./Management";
import Home from "./Home";
import Impact from "./Impact";
import Institution from "./Institution";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    base: Base,
    settings: Settings,
    mail: Mail,
    auth: Auth,
    pharma: PharmaMail,
    prescription: Prescription,
    management: Management,
    home:Home,
    impact:Impact,
    institution: Institution
  });
