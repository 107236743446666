import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Grid, Button } from "@material-ui/core";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import ControlPanelRepKam from "./controlPanelRepKam";
import ProcessDetail from './ProcessDetail'
import { ProductBehavior } from "constants/Enums";

const ReportList = () => [
  { id: 2, name: IntlGetText("reports.controlPanelRemodulin") },
  { id: 3, name: IntlGetText("reports.controlPanelIclusig") },
  { id: 4, name: IntlGetText("reports.controlPanelGalafold") }
];
const ReportEnum = {
  controlPanelRemodulin:2,
  controlPanelIclusig:3,
  controlPanelGalafold:4
};

const Reports = () => {
  const [openDlgDetail, setOpenDlgDetail] = useState(false);
  const [currentPatientId, setCurrentPatientId] = useState(null);

  const handleRequestClose = () => {
    setOpenDlgDetail(false);
    setCurrentPatientId(null);
  }

  useEffect(()=>{
    if(currentPatientId !== null)
      setOpenDlgDetail(true);
  },[currentPatientId]);

  const methods = useForm();
  const { errors, watch } = methods;

  const reportValue = watch("report");

  const showReport = (reportId) =>
    reportValue && reportValue.value === reportId;

  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-5">
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} lg={3}>
              <FormSelectAutoComplete
                name="report"
                label={<IntlMessages id="core.report" />}
                options={ReportList()}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </div>
      {showReport(ReportEnum.controlPanelRemodulin) && (
        <ControlPanelRepKam productBehavior={ProductBehavior.remodulin} setCurrentPatientId={setCurrentPatientId}/>
      )}
      {showReport(ReportEnum.controlPanelIclusig) && (
        <ControlPanelRepKam productBehavior={ProductBehavior.iclusig} setCurrentPatientId={setCurrentPatientId}/>
      )}
      {showReport(ReportEnum.controlPanelGalafold) && (
        <ControlPanelRepKam productBehavior={ProductBehavior.galafold} setCurrentPatientId={setCurrentPatientId}/>
      )}
      <ProcessDetail patientId={currentPatientId} openDlg={openDlgDetail} handleRequestClose={handleRequestClose}/>
    </>
  );
};

export default Reports;
