import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { IconButton } from "@material-ui/core";
import { callApi, getPatientPAInfo } from "api/apiList";
import PatientInfo from "./patientInfo";
import Impacts from "./impacts";
import ScrollContent from "../../management/scrollContent";

const PatientPADetail = (props) => {
    const { id } = props.location;
    let history = useHistory();
    const [patientData, setPatientData] = useState(null);
    
    const loadData = () => {
        if(id == null || id == undefined){
            history.push("/app/patientPA");
            return;
        }
        callApi(() => getPatientPAInfo(id), setPatientData);
    };
    
    useEffect(loadData,[]);

    const patientName = IntlGetText("core.newPatient");
    const getPatientName = (patient) => {
      return !patient.name ? `${patient.code} - ${patientName}` : `${patient.code} - ${patient.name} ${patient.lastName}`;                
    }

    const getProp = (patientData, prop) => {
        if(!patientData) return "";
        return patientData[prop];
    }

    return(
        <div style={{backgroundColor:'#fff'}}>
            <div className="module-box-topbar">
                <IconButton className="icon-btn" onClick={()=> history.push('/app/patientPA')}>
                    <i className="zmdi zmdi-arrow-back" />
                </IconButton>
            </div>
            <ScrollContent
              width={window.innerWidth}
              heightMax="calc(100vh - 200px)"
              heightMin="calc(100vh - 177px)"
            >
                <div className="mail-header-content col pl-0">
                    <div className="subject" style={{padding:20}}>
                        <strong><IntlMessages id="core.patient"/>: </strong> {patientData && getPatientName(patientData)}
                        <strong > | <IntlMessages id="core.med"/>: </strong> {getProp(patientData,"doctor")}
                        {/* <strong > | <IntlMessages id="core.rep"/>: </strong> {getProp(patientData,"visitor")} */}
                    </div>
                </div>
                <hr />
                <div className="row first-impact-box-margins" style={{padding:20}}>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <PatientInfo patientData={patientData}/>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <Impacts patientData={patientData}/>
                    </div>
                </div>
            </ScrollContent>            
        </div>
    )
};

export default PatientPADetail;