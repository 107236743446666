import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import InfoCard from "components/InfoCard/index";

class RadFileDialog extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <Button
          variant="contained"
          onClick={this.handleClickOpen}
          className="jr-btn jr-btn-xs text-white bg-indigo"
        >
          <i className={`zmdi zmdi-file-text zmdi-hc-fw`} />
        </Button>
        <Dialog open={this.state.open} onClose={this.handleRequestClose}>
          <DialogTitle>Archivos Adjuntos</DialogTitle>
          <DialogContent>
            <form className="row" noValidate autoComplete="off">
              <div className="col-md-6 col-12">
                <InfoCard
                  data={{
                    icon: "file-text",
                    title: "Formulario",
                    subTitle: "Radicación",
                    color: "#3f51b5",
                  }}
                  onClickBox={this.handleClickOpen}
                />
              </div>

              <div className="col-md-6 col-12">
                <InfoCard
                  data={{
                    icon: "file-text",
                    title: "Evidencia",
                    subTitle: "1",
                    color: "#3f51b5",
                  }}
                  onClickBox={this.handleClickOpen}
                />
              </div>

              <div className="col-md-6 col-12">
                <InfoCard
                  data={{
                    icon: "file-text",
                    title: "Evidencia",
                    subTitle: "2",
                    color: "#3f51b5",
                  }}
                  onClickBox={this.handleClickOpen}
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default RadFileDialog;
