import EducationLevelSurvey from "app/routes/psp/management/GeneralImpact/EducationLevelSurvey";
import React, { useState, createRef, useEffect, useRef } from "react";

const Map = ({ Mapcenter, Mapzoom, geoInfo, setGeoInfo, queryView }) => {
  const ref = useRef(null);
  const [map, setMap] = useState();
  const [zoom, setZoom] = useState(Mapzoom); // initial zoom
  const [center, setCenter] = useState(Mapcenter);
  const [markers, setMarkers] = useState([]);

  function setMapOnAll(map) {
    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(map);
    }
  }

  function deleteMarkers() {
    setMapOnAll(null);
    setMarkers([]);
  }

  function placeMarkerAndPanTo(latLng, map) {
    deleteMarkers();

    var marker = new window.google.maps.Marker({
      position: latLng,
      map: map,
    });
    setMarkers([...markers, marker]);
    map.panTo(latLng);
  }

  const onClick = (e) => {
    if (queryView) {
      return;
    }
    setGeoInfo(e.latLng.toJSON());
    placeMarkerAndPanTo(e.latLng, map);
  };

  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center: center,
          zoom: zoom,
        })
      );
    }
    if (geoInfo) {
      var marker = new window.google.maps.Marker({
        position: geoInfo,
        map: map,
      });
      setMarkers([...markers, marker]);
    }
  }, [ref, map]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );
      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div className="geoMap" ref={ref} id="map"></div>
    </>
  );
};

export default Map;
