import React from "react";
import IntlMessages from "util/IntlMessages";
import DialogAsync from "components/Form/DialogAsync";
import Table, { minimalOptions, defaultCol } from "components/Form/Table";

const DlgDetail = ({openDlg, closeDialog, data}) => {
  const columns = [
    defaultCol("line", "core.line"),
    defaultCol("amount", "insuranceAuthorization.medicineAmountDelivery")
  ];
  
  const total = data.length === 0 ? 0 : data.map(x=>x.amount).reduce((prev, curr) => prev + curr, 0);

  return(
    <DialogAsync
      open={openDlg}
      closeDialog={closeDialog}
      titleTextId="core.dashboardDoctorDetail"
      allowEdit={false}
    > 
      <Table
        className="border-dialog-card mt-2 mb-2"
        data={data}
        columns={columns}
        options={minimalOptions()}
      />
      <div className="text-right mt-1 mb-1">
        <h3><IntlMessages id="core.total" />: {total}</h3>
      </div>
    </DialogAsync>
  );
};

export default DlgDetail;