import React, { useState } from "react";
import IntlMessages from "util/IntlMessages";
import { Tabs, Tab } from "@material-ui/core";
import Pharmacovigilance from "./AdverseEvent/Pharmacovigilance";
import Technovigilance from "./AdverseEvent/Technovigilance";
import FormDetail from "./FormDetail";
import AddAdverseEvent from "./AddAdverseEvent";
import { isOnlyTracing } from "app/routes/psp/services/userService";
import { AdverseEventTypeEnum, AdverseEventStateEnum } from "constants/Enums";

const ComponentTabs = ({ children, ...props }) => {
  const { rowObj, generateAdverseEventReload, customProductBehaviorId } = props;

  const [currentTab, setCurrentTab] = useState(0);  
  const handleChange = (event, value) => setCurrentTab(value);

  return (
    <div>
      <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
        <Tab
          className="jr-tabs-label"
          label={<IntlMessages id="clinicalFindingState.clinicalFindingComments" />}
        />
        {rowObj && rowObj.adverseEvent && rowObj.adverseEventStatus !== AdverseEventStateEnum.annulled  && <Tab
          className="jr-tabs-label"
          label={<IntlMessages id="core.adverseEvent" />}
        />
        }
      </Tabs>
      <div className="jr-tabs-content jr-task-list">
        <div className="row">
          {currentTab === 0 && (
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="mt-2">
                {!isOnlyTracing() && rowObj && !rowObj.adverseEvent && 
                  <AddAdverseEvent obj={rowObj}          
                    generateAdverseEventReload={generateAdverseEventReload}
                    customProductBehaviorId={customProductBehaviorId}
                  />
                }
                <FormDetail {...props} />
              </div>
            </div>
          )}
          {currentTab === 1 && (
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="mt-2">
                {rowObj && rowObj.adverseEvent && rowObj.adverseEventTypeId === AdverseEventTypeEnum.pharmacovigilance &&
                  <Pharmacovigilance obj={rowObj}/>
                }
                {rowObj && rowObj.adverseEvent && rowObj.adverseEventTypeId === AdverseEventTypeEnum.technovigilance &&
                  <Technovigilance obj={rowObj}/>
                }
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ComponentTabs;