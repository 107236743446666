import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import Form from "./Form";
import Table from "./Table";
import {
  callApi,
  callApiAsync,
  getRemodulinPumpList,
  postCreateUpdateRemodulinPump,
  deleteRemodulinPump,
} from "api/apiList";
import { callStartImpact } from "actions/Impact";
import { getUserInfo } from "app/routes/psp/services/userService";
import { ShardKeyEnum } from "constants/Enums";

const existItemsInImpact = (impactId, dataList) =>
  dataList.find((x) => x.impactId == impactId) != undefined;

const InfusionPumps = () => {
  const dispatch = useDispatch();
  const isOperando = getUserInfo().shardKey === ShardKeyEnum.operando;

  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentSigned, isCIRequired } = useSelector(({ impact }) => impact);

  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const [remodulinPumpList, setRemodulinPumpList] = useState([]);
  const [currentRowObj, setCurrentRowObj] = useState(null);

  const [open, setOpen] = useState(false);
  const [openCRU, setOpenCRU] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleRequestClose = () => setOpen(false);
  const handleOpenCRU = () => setOpenCRU(true);
  const handleRequestCloseCRU = () => setOpenCRU(false);
  const handleRequestCloseDel = () => setOpenDeleteDialog(false);

  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    if (isCIRequired &&  !consentSigned) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }
    setOpen(true);
  };

  const loadData = () => {
    callApi(
      () => getRemodulinPumpList(selectedPrescription.id),
      setRemodulinPumpList
    );
  };

  useEffect(() => loadData(), []);

  const handleCreateUpdate = async (data) => {
    await callApiAsync(
      () => postCreateUpdateRemodulinPump(data),
      () => {
        handleRequestCloseCRU();
        loadData();
      }
    );
  };

  const handleDelete = (data) => {
    callApi(
      () => deleteRemodulinPump(currentRowObj.id),
      () => {
        handleRequestCloseDel();
        setCurrentRowObj(null);
        loadData();
      }
    );
  };

  var readOnly = false;

  return (
    <div>
      <InfoCard
        data={{
          icon: "hospital-alt",
          title: isOperando
            ? IntlGetText("infusionPumps.cardTitleTransmitter")
            : IntlGetText("infusionPumps.cardTitle"),
          subTitle: isOperando ? IntlGetText("infusionPumps.cardSubTitleTransmitter") : IntlGetText("infusionPumps.cardSubTitle"),
          color: existItemsInImpact(
            selectedPrescription.impactId,
            remodulinPumpList
          )
            ? "#20c997"
            : "#757575",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id={isOperando? "infusionPumps.dlgTitleTransmitter": "infusionPumps.dlgTitle"} />
        </DialogTitle>
        <DialogContent>
          <Table
            data={remodulinPumpList}
            handleOpenCRU={handleOpenCRU}
            handleOpenDel={() => setOpenDeleteDialog(true)}
            setCurrentRowObj={setCurrentRowObj}
            readOnly={readOnly}
          />
          <Form
            obj={currentRowObj}
            impactId={selectedPrescription.impactId}
            openCRU={openCRU}
            handleRequestClose={handleRequestCloseCRU}
            handleCreateUpdate={handleCreateUpdate}
            readOnly={readOnly}
          />
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={openDeleteDialog}
            disableBackdropClick={true}
            onClose={handleRequestCloseDel}
          >
            <DialogTitle>
              <IntlMessages id="additionalMedications.MsgDelConfirmation" />
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleRequestCloseDel} color="secondary">
                <IntlMessages id="core.cancel" />
              </Button>
              <Button onClick={handleDelete} color="primary">
                <IntlMessages id="core.accept" />
              </Button>
            </DialogActions>
          </Dialog>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InfusionPumps;
