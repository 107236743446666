import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getRoleUser, getUserInfo } from "app/routes/psp/services/userService";

const isRoleUser = (role, userInfo) => {
    if(!userInfo) return false;

    return role.find(x=>userInfo.roles.find(y=>y===x));
}

const ProtectedRoute = ({ component: Component, role, ...rest }) => {
    const r = getRoleUser().role.toUpperCase();
    return (
        <>
            {/* {(!role || role.includes(r))
                ? <Route {...rest} render={ props => <Component {...rest} {...props} /> } />
                : <Redirect to="/unauthorized" />
            } */}
            {(!role || isRoleUser(role, getUserInfo()))
                ? <Route {...rest} render={ props => <Component {...rest} {...props} /> } />
                : <Redirect to="/unauthorized" />
            }
        </>
    )
}

export default ProtectedRoute;