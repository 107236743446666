import React, { useState } from "react";

import { Card, CardBody, CardTitle } from "reactstrap";

import ManageStatus from "./data";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";

const PatientsTimeline = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="app-wrapper">
      <div className="row">
        <div className="col-lg-12 col-12">
          <Card>
            <CardBody>
              <form className="row" noValidate autoComplete="off">
                <div className="col-md-4 col-6 mb-2">
                  <FormControl className="textField">
                    <InputLabel htmlFor="standard-adornment-password">
                      Buscar
                    </InputLabel>
                    <Input
                      id="standard-adornment-password"
                      type="text"
                      value={"001224657"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setOpen(true)}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </form>
              <div style={{ display: open ? "initial" : "none" }}>
                <CardTitle className="mt-3">
                  <h3>
                    Seguimiento al proceso del paciente:{" "}
                    <strong>Alicia Muñoz Diaz</strong>
                  </h3>
                </CardTitle>
                <ManageStatus />
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PatientsTimeline;
