import React from "react";
import { IntlGetText } from "util/IntlMessages";
import * as dayjs from "dayjs";
import MUIDataTable from "mui-datatables";
import { DownloadButton2 } from "components/DownloadButton/index";
import { VariableGroupTypeList } from "constants/Enums";

const FileTable = ({ options, data }) => { 
    const variableGroupTypeList = VariableGroupTypeList();
  
    const columns = [
      {
        name: "variableGroupType",
        label: IntlGetText("core.type"),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const type = variableGroupTypeList.find(x=>x.id === value);
            return !type ? value : type.name;
          },
        }
      },
      {
        name: "variableGroup",
        label: IntlGetText("core.variableGroup"),
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "date",
        label: IntlGetText("core.impact"),
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value ? dayjs(value).format("YYYY/MM/DD") : "";
          },
        },
      },
      {
        name: "file",
        label: IntlGetText("core.file"),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <DownloadButton2                
                variant="contained"
                className="jr-btn jr-btn-xs"
                color="primary"
                fileLink={value}
              >
                <i className="zmdi zmdi-download"></i>
              </DownloadButton2>
            );
          },
        },
      }
    ];
  
    return (
      <MUIDataTable
        className="border-dialog-card mt-1"
        data={data}
        columns={columns}
        options={options}
      />
    );
  };

  export default FileTable;