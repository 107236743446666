export default [
  {
    title: "Acompañamiento a cita de control - Paciente RM0021",
    allDay: true,
    start: new Date(2020, 6, 3),
    end: new Date(2020, 6, 3),
  },
  {
    title: "Acompañamiento a cita de control - Paciente RM0021",
    allDay: true,
    start: new Date(2020, 6, 1),
    end: new Date(2020, 6, 1),
  },
  {
    title: "Llamada de Seguimiento - Paciente RM0015",
    allDay: true,
    start: new Date(2020, 6, 2),
    end: new Date(2020, 6, 2),
  },
  {
    title: "Visita domiciliaria - Paciente RM0044",
    allDay: true,
    start: new Date(2020, 6, 6),
    end: new Date(2020, 6, 6),
  },
  {
    title: "Gestión OLF - Paciente RM0014",
    allDay: true,
    start: new Date(2020, 6, 6),
    end: new Date(2020, 6, 6),
  },
  {
    title: "Gestión IPS - Paciente RM0037",
    allDay: true,
    start: new Date(2020, 6, 7),
    end: new Date(2020, 6, 7),
  },
  {
    title: "Gestión EPS - Paciente RM0008",
    allDay: true,
    start: new Date(2020, 6, 9),
    end: new Date(2020, 6, 9),
  },
  {
    title: "Acompañamiento cita de control - Paciente RM0011",
    allDay: true,
    start: new Date(2020, 6, 9),
    end: new Date(2020, 6, 9),
  },
  {
    title: "Gestión IPS - Paciente IC0055",
    allDay: true,
    start: new Date(2020, 6, 2),
    end: new Date(2020, 6, 2),
  },
  {
    title: "Gestión IPS - Paciente RM0037",
    start: new Date(2020, 6, 21),
    end: new Date(2020, 6, 21),
  },
  {
    title: "Visita domiciliaria - Paciente IC0032",
    start: new Date(2020, 6, 8),
    end: new Date(2020, 6, 8),
  },
  {
    title: "Visita domiciliaria - Paciente IC0014",
    start: new Date(2020, 6, 14),
    end: new Date(2020, 6, 14),
  },
  {
    title: "Acompañamiento cita de control - Paciente RM0019",
    allDay: true,
    start: new Date(2020, 6, 14),
    end: new Date(2020, 6, 14),
  },
  {
    title: "Visita intrahospitalaria - Paciente RM0068",
    start: new Date(2020, 6, 21),
    end: new Date(2020, 6, 21),
  },
  {
    title: "Llamada de Seguimiento - Paciente RM0046",
    start: new Date(2020, 6, 10),
    end: new Date(2020, 6, 10),
  },
  {
    title: "Llamada de Seguimiento - Paciente RM0020",
    start: new Date(2020, 6, 13),
    end: new Date(2020, 6, 13),
  },
  {
    title: "Entrega de medicamento - Paciente RM0029",
    allDay: true,
    start: new Date(2020, 6, 22),
    end: new Date(2020, 6, 22),
  },
  {
    title: "Gestión EPS - Paciente RM0039",
    start: new Date(2020, 6, 13),
    end: new Date(2020, 6, 13),
  },
  {
    title: "Gestión EPS - Paciente RM0033",
    start: new Date(2020, 6, 17),
    end: new Date(2020, 6, 17),
  },
  {
    title: "Gestión EPS - Paciente RM0012",
    start: new Date(2020, 6, 22),
    end: new Date(2020, 6, 22),
  },
  {
    title: "Primer Impacto - Paciente RM0042",
    start: new Date(2020, 6, 15),
    end: new Date(2020, 6, 15),
  },
  {
    title: "Gestión OLF - Paciente RM0026",
    start: new Date(2020, 6, 23),
    end: new Date(2020, 6, 23),
  },
  {
    title: "Gestión OLF - Paciente RM0075",
    start: new Date(2020, 6, 7),
    end: new Date(2020, 6, 7),
  },
  {
    title: "Primer Impacto - Paciente IC0051",
    start: new Date(2020, 6, 23),
    end: new Date(2020, 6, 23),
  },
  {
    title: "Llamada de Seguimiento - Paciente RM0084",
    start: new Date(2020, 6, 16),
    end: new Date(2020, 6, 16),
  },
  {
    title: "Capacitación Iclusig",
    start: new Date(2020, 6, 24),
    end: new Date(2020, 6, 24),
    detail: true,
  },
  {
    title: "Capacitación Remodulin",
    start: new Date(2020, 6, 29),
    end: new Date(2020, 6, 29),
    detail: true,
  },
  {
    title: "Gestión OLF - Paciente IC0026",
    start: new Date(2020, 6, 27),
    end: new Date(2020, 6, 27),
  },
  {
    title: "Gestión IPS - Paciente RM0036",
    start: new Date(2020, 6, 28),
    end: new Date(2020, 6, 28),
  },
  {
    title: "Gestión EPS - Paciente IC0020",
    start: new Date(2020, 6, 29),
    end: new Date(2020, 6, 29),
  },
  {
    title: "Gestión EPS - Paciente RM0039",
    start: new Date(2020, 6, 30),
    end: new Date(2020, 6, 30),
  },
  {
    title: "Llamada de Seguimiento - Paciente IC0039",
    start: new Date(2020, 6, 28),
    end: new Date(2020, 6, 28),
  },
  {
    title: "Primer Impacto - Paciente RM0022",
    start: new Date(2020, 6, 27),
    end: new Date(2020, 6, 27),
  },
  {
    title: "Visita domiciliaria - Paciente RM0032",
    start: new Date(2020, 6, 24),
    end: new Date(2020, 6, 24),
  },
  {
    title: "Visita domiciliaria - Paciente IC0033",
    start: new Date(2020, 6, 30),
    end: new Date(2020, 6, 30),
  },
  {
    title: "Encuentro de Salud",
    start: new Date(2020, 6, 31),
    end: new Date(2020, 6, 31),
    detail: true,
  },
  {
    title: "Conference",
    start: new Date(2015, 3, 11),
    end: new Date(2015, 3, 13),
    desc: "Big conference for important people",
  },
  {
    title: "Meeting",
    start: new Date(2015, 3, 12, 10, 30, 0, 0),
    end: new Date(2015, 3, 12, 12, 30, 0, 0),
    desc: "Pre-meeting meeting, to prepare for the meeting",
  },
  {
    title: "Lunch",
    start: new Date(2015, 3, 12, 12, 0, 0, 0),
    end: new Date(2015, 3, 12, 13, 0, 0, 0),
    desc: "Power lunch",
  },
  {
    title: "Meeting",
    start: new Date(2015, 3, 12, 14, 0, 0, 0),
    end: new Date(2015, 3, 12, 15, 0, 0, 0),
  },
  {
    title: "Happy Hour",
    start: new Date(2015, 3, 12, 17, 0, 0, 0),
    end: new Date(2015, 3, 12, 17, 30, 0, 0),
    desc: "Most important meal of the day",
  },
  {
    title: "Dinner",
    start: new Date(2015, 3, 12, 20, 0, 0, 0),
    end: new Date(2015, 3, 12, 21, 0, 0, 0),
  },
  {
    title: "Birthday Party",
    start: new Date(2015, 3, 13, 7, 0, 0),
    end: new Date(2015, 3, 13, 10, 30, 0),
  },
  {
    title: "Birthday Party 2",
    start: new Date(2015, 3, 13, 7, 0, 0),
    end: new Date(2015, 3, 13, 10, 30, 0),
  },
  {
    title: "Birthday Party 3",
    start: new Date(2015, 3, 13, 7, 0, 0),
    end: new Date(2015, 3, 13, 10, 30, 0),
  },
  {
    title: "Late Night Event",
    start: new Date(2015, 3, 17, 19, 30, 0),
    end: new Date(2015, 3, 18, 2, 0, 0),
  },
  {
    title: "Multi-day Event",
    start: new Date(2015, 3, 20, 19, 30, 0),
    end: new Date(2015, 3, 22, 2, 0, 0),
  },
];
