import React from "react";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import LabelsSection from "../../labelsSection";
import { PrescripStateEnum } from "constants/Enums";

const NameAndLabelsContent = ({ prescription }) => {
    const patientName = IntlGetText("core.newPatient");
    const getPatientName = (patient) => {
      return !patient ? patientName : `${patient.code} - ${patient.name} ${patient.lastName}`;                
    }

    return (
        <div className="mail-header-content col pl-0">
            <div className="subject">
                {<strong><IntlMessages id="core.patient"/>: </strong>}
                    {getPatientName(prescription.patient)}                    
                {prescription.doctor && <strong > | <IntlMessages id="core.med"/>: </strong>} {prescription.doctor.name}                
            </div>
            { prescription.stateId <= PrescripStateEnum.fristImpact &&
                <div className="subject">
                    {prescription.visitor &&
                        <strong ><IntlMessages id="core.rep"/>: </strong>} {prescription.visitor}
                    {prescription.msl &&
                    <strong> | <IntlMessages id="core.msl" />: </strong>} {prescription.msl}
                    {prescription.stateId == PrescripStateEnum.fristImpact &&
                        prescription.impact && prescription.impact.contactPlace &&
                        <strong> | {IntlGetText("patientManagement.place")}: </strong>}  {prescription.impact && prescription.impact.contactPlace}
            
                </div>
            }            
            <LabelsSection prescription={prescription}/>
        </div>
    );
};

export default NameAndLabelsContent;