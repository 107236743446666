import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import FormDateTimePicker from "components/Form/Controls/dateTimePicker";
import FormInput from "components/Form/Controls/input";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import {
  callApi,
  callApiAsync,
  postStartImpact,
  getImpactTypeList,
  postEndImpact
} from "api/apiList";

const DialogEndImpact = ({
  open,
  handleRequestClose,
  callEndImpact,
  impactStartDate,
}) => {
  const defaultValues = {
    endDate: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
    nursingNote: "",
  };
  const validationSchema = {
    nursingNote: yup
      .string()
      .required(<IntlMessages id="core.fieldRequired" />),
    endDate: yup
      .date()
      .typeError(IntlGetText("yup.InvalidDate"))
      .min(
        dayjs(impactStartDate).format("YYYY-MM-DDTHH:mm:ss"),
        IntlGetText("impact.invalidEndDate")
      )
      .required(<IntlMessages id="core.fieldRequired" />),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, errors } = methods;

  useEffect(() => {
    if (open) {
      setTimeout(
        () => setValue("endDate", dayjs().format("YYYY-MM-DDTHH:mm:ss")),
        150
      );
    }
  }, [open]);

  const onSubmit = async (data, e) => {
    await callEndImpact(
      dayjs(data.endDate).format("YYYY-MM-DDTHH:mm:ss"),
      data.nursingNote
    );
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="xs"
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>{<IntlMessages id="impact.endImpactText" />}</DialogTitle>
      <DialogContent className="new-patient-dialog-content">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item md={12}>
                <FormDateTimePicker
                  name="endDate"
                  label={IntlGetText("core.dateAndTime")}
                  format="YYYY-MM-DD hh:mm A"
                  disableFuture={true}
                  minDate={dayjs(impactStartDate).format("YYYY-MM-DD")}
                  leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                  rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item md={12}>
                <FormInput
                  name="nursingNote"
                  label={<IntlMessages id="core.comment" />}
                  multiline
                  rows={5}
                  variant="outlined"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          {<IntlMessages id="core.cancel" />}
        </Button>
        <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
          {<IntlMessages id="core.save" />}
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  );
};

const DialogStartImpact = ({ open, handleRequestClose, callStartImpact }) => {
  const [impactTypeList, setImpactTypeList] = useState([]);
  
  useEffect(()=> callApi(getImpactTypeList, setImpactTypeList), []);

  const defaultValues = { 
    startDate: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
    impactTypeId: null
  };
  const validationSchema = {
    impactTypeId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable()
  };
  const methods = useForm({ defaultValues,  resolver: yupResolver(yup.object().shape(validationSchema)) });
  const { handleSubmit, setValue, errors } = methods;

  useEffect(() => {
    if (open) {
      setTimeout(
        () => setValue("startDate", dayjs().format("YYYY-MM-DDTHH:mm:ss")),
        150
      );
    }
  }, [open]);

  const onSubmit = async (data, e) => {
    await callStartImpact(
      dayjs(data.startDate).format("YYYY-MM-DDTHH:mm:ss"),
      Number(data.impactTypeId.value));
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="xs"
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>{<IntlMessages id="impact.startImpactText" />}</DialogTitle>
      <DialogContent className="new-patient-dialog-content">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <FormSelectAutoComplete
                  name="impactTypeId"
                  label={<IntlMessages id="impact.ImpactType" />}
                  options={impactTypeList}
                  margin="normal"
                  required={true}
                  errorobj={errors}
                  />
              </Grid>
              <Grid item md={12}>
                <FormDateTimePicker
                  name="startDate"
                  label={IntlGetText("core.dateAndTime")}
                  format="YYYY-MM-DD hh:mm A"
                  disableFuture={true}
                  leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                  rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          {<IntlMessages id="core.cancel" />}
        </Button>
        <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
          {<IntlMessages id="core.save" />}
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  );
};

const StartEndImpact = ({prescriptionId, currentImpact, setCurrentImpact, requestCloseDlg }) => {
  const [open, setOpen] = useState(false);
  const [impactStartDate, setImpactStartDate] = useState(null);
  const [impactEndDate, setImpactEndDate] = useState(null);

  const msgSuccess = IntlGetText("core.SuccessSave");

  useEffect(()=>{
    setImpactStartDate(currentImpact && currentImpact.startDate ? currentImpact.startDate : null);
    setImpactEndDate(currentImpact && currentImpact.endDate ? currentImpact.endDate : null);
  },[currentImpact]);
  
  const handleRequestClose = () => setOpen(false);
  
  const callStartImpact = async (startDate, impactTypeId) => {
    await callApiAsync(
      () =>
        postStartImpact({
          id: currentImpact && currentImpact.id ? currentImpact.id : 0,
          prescriptionId: prescriptionId,
          startDate,
          impactTypeId,
        }),
      (data) => {
        handleRequestClose();
        NotificationManager.success(msgSuccess);
        setCurrentImpact(data);
      }
    );
  };

  const callEndImpact = async (endDate, nursingNote) => {
    await callApiAsync(
      () =>
        postEndImpact({
          id: currentImpact.id,
          endDate,
          nursingNote
        }),
      (data) => {
        handleRequestClose();
        NotificationManager.success(msgSuccess);
        requestCloseDlg();
      }
    );
  };

  return (
    <div>
     {!impactStartDate && (
        <div className="mail-header-actions">
          <IntlMessages id="impact.startImpact" />
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            className="ml-3 jr-btn bg-green text-white"
          >
            <i className="zmdi zmdi-thumb-up zmdi-hc-fw" />
          </Button>
          <DialogStartImpact
            open={open}
            handleRequestClose={handleRequestClose}
            callStartImpact={callStartImpact}
          />
        </div>
      )}

      {impactStartDate && !impactEndDate && (
        <div className="mail-header-actions">
          <IntlMessages id="impact.endImpact" />
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            className="ml-3 jr-btn bg-green text-white"
          >
            <i className="zmdi zmdi-thumb-up zmdi-hc-fw" />
          </Button>
          <DialogEndImpact
            open={open}
            handleRequestClose={handleRequestClose}
            callEndImpact={callEndImpact}
            impactStartDate={impactStartDate}
          />
        </div>
      )}
    </div>
  );
};
export default StartEndImpact;
