import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import events from "./Components/events";
import AddEventDialog from "./Components/AddEventDialog";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const localizer = momentLocalizer(moment);

const ManagerCalendar = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentEvt, setCurrentEvent] = useState({ title: "" });

  let nurses = [
    "Dorian Jaimes",
    "Gabriel Gutierrez",
    "Ramón Bolaños",
    "Pedro Pérez",
  ];

  return (
    <div className="app-wrapper">
      <div className="app-calendar app-cul-calendar animated slideInUpTiny animation-duration-3">
        <div className="row">
          <div className="col-md-6 col-12">
            <h3 className="callout">
              <label>Enfermeros:</label>{" "}
              <select
                className="form-control"
                style={{ width: 200, display: "inline-block" }}
                defaultValue={"Dorian Jaimes"}
                // onChange={(e) => setNurse(e.target.value)}
              >
                {nurses.map((c, idx) => (
                  <option key={idx} value={c}>
                    {c}
                  </option>
                ))}
              </select>
            </h3>
          </div>
          <div className="col-md-6 col-12 text-right">
            <AddEventDialog />
          </div>

          <div className="col-md-12 col-12">
            <Calendar
              localizer={localizer}
              events={events}
              defaultDate={new Date(2020, 6, 22)}
              messages={{
                date: "Fecha",
                time: "Hora",
                event: "Evento",
                allDay: "Todo el dia",
                week: "Semana",
                work_week: "Semana laboral",
                day: "Dia",
                month: "Mes",
                previous: "Anterior",
                next: "Siguiente",
                yesterday: "Yesterday",
                tomorrow: "Mañana",
                today: "Hoy",
                agenda: "Agenda",
                noEventsInRange: "No hay eventos",
                showMore: function(e) {
                  return "+" + e + " mas";
                },
              }}
              onSelectEvent={(event) => {
                if (event.detail) {
                  setOpenDialog(true);
                  setCurrentEvent(event);
                }
              }}
            />
            <Dialog open={openDialog} onClose={(e) => setOpenDialog(false)}>
              <DialogTitle>{currentEvt.title}</DialogTitle>
              <DialogContent>
                <form className="row" noValidate autoComplete="off">
                  <div className="col-md-6 col-6">
                    <TextField
                      required
                      id="name"
                      label="Nombre del evento"
                      margin="normal"
                      value={currentEvt.title}
                      fullWidth
                    />
                  </div>
                  <div className="col-md-6 col-6">
                    <FormControl className="w-100 MuiFormControl-marginNormal MuiFormControl-fullWidth">
                      <InputLabel htmlFor="theme-helper">Tema</InputLabel>
                      <Select value={10} input={<Input id="theme-helper" />}>
                        <MenuItem value="">
                          <em>Ninguno</em>
                        </MenuItem>
                        <MenuItem value={10}>Remodulin</MenuItem>
                        <MenuItem value={20}>Iclusig</MenuItem>
                        <MenuItem value={30}>PSP</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-12 col-12">
                    <TextField
                      required
                      multiline
                      id="comment"
                      label="Descripción"
                      margin="normal"
                      fullWidth
                    />
                  </div>
                  <div className="col-md-12 col-12">
                    <Button
                      variant="contained"
                      className="jr-btn jr-btn-sm mt-4 text-white bg-cyan"
                    >
                      ADJUNTAR ARCHIVO
                      <i className={`zmdi zmdi-upload zmdi-hc-fw`} />
                    </Button>
                  </div>
                  <div className="col-md-12 col-12">
                    <TextField
                      required
                      id="url"
                      label="URL"
                      margin="normal"
                      fullWidth
                    />
                  </div>
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={(e) => setOpenDialog(false)} color="secondary">
                  Cancelar
                </Button>
                <Button onClick={(e) => setOpenDialog(false)} color="primary">
                  Guardar
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerCalendar;
