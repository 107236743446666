function init() {
  // Init Raven or Library used
}

function log(error) {
  console.log(error);
}

export default {
  init,
  log,
};
