import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Tooltip
} from "@material-ui/core";
import IntlMessages from "util/IntlMessages";
import { DialogConfirmAsync } from "components/Form/Controls/DialogConfirm";
import {
  callApiAsync,
  postPrescriptionNewFormulation,
  getPrescriptions,
} from "api/apiList";
import { loadPrescriptions, setSelectedPrescription } from "actions/Management";
import { PrescripStateEnum, FolderType } from "constants/Enums";
import DialogEndImpact from "./dialogEndImpact";

const BtnCancelFormula = ({stateToAssign}) => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, impactEndDate } = useSelector(({ impact }) => impact);

  const [openDlg, setOpenDlg] = useState(false);
  const [openDlgEndImpact, setOpenDlgEndImpact] = useState(false);

  const onNewFormulationAsync = async (nursingNote) => {
    await callApiAsync(
      () =>
        postPrescriptionNewFormulation({
          prescriptionId: selectedPrescription.id,
          impactId: selectedPrescription.impactId,
          state: stateToAssign,
          nursingNote
        }),
      async () => {
        await callApiAsync(
          () => getPrescriptions(FolderType.inProcess),
          (data) => {
            setOpenDlg(false);
            dispatch(loadPrescriptions(data, FolderType.inProcess));
            dispatch(setSelectedPrescription(null));
          }
        );
      }
    );
  };

  const getInfo = ()=>{
    if(stateToAssign === PrescripStateEnum.cancelled){
      return {
        toolTip: "prescription.cancelDlgTitle",
        btnClass: "ml-1 jr-btn bg-red text-white",
        btnIconClass: "zmdi zmdi-delete zmdi-hc-fw",
        dlgTitle: "prescription.cancelDlgTitle",
        dlgText: "prescription.cancelDlgConfirmText"
      }
    }
    
    return {
      toolTip: "prescription.forcedTerminationDlgTitle",
      btnClass: "ml-1 jr-btn bg-amber text-white",
      btnIconClass: "zmdi zmdi-delete zmdi-hc-fw",
      dlgTitle: "prescription.forcedTerminationDlgTitle",
      dlgText: "prescription.forcedTerminationDlgConfirmText"
    }
  }
  const info = getInfo();

    return (
      <>
        { selectedPrescription && selectedPrescription.stateId > PrescripStateEnum.formulated &&
          <>
            <Tooltip title={<IntlMessages id={info.toolTip} />} placement="bottom-end">
              <Button
                onClick={() => setOpenDlg(true)}
                variant="contained"
                className={info.btnClass}
              >
                <i className={info.btnIconClass} />
              </Button>
            </Tooltip>
            <DialogConfirmAsync
              openDialog={openDlg}
              title={<IntlMessages id={info.dlgTitle} />}
              text={<IntlMessages id={info.dlgText} />}
              handleRequestClose={() => setOpenDlg(false)}
              onAcceptClickAsync={async ()=>{
                if(impactStartDate && !impactEndDate){
                  setOpenDlgEndImpact(true);
                  return;
                }
                await onNewFormulationAsync("");
              }}
            />
            <DialogEndImpact 
              open={openDlgEndImpact} 
              handleRequestClose={() => setOpenDlgEndImpact(false)} 
              callEndImpact={onNewFormulationAsync}
            />
          </>
        }
      </>
    );
};

export default BtnCancelFormula;
