import React, { useState } from "react";
import { IntlGetText } from "util/IntlMessages";
import InfoCard from "components/InfoCard/index";
import AddPrescription from "../prescriptions/addPrescription";

const PrescriptionBox = (props) => {
  const [openDlgPrescription, setOpenDlgPrescription] = useState(false);

  return (
    <>
      <InfoCard
        data={{
          icon: "book",
          title: IntlGetText("core.edit"),
          subTitle: IntlGetText("core.prescription"),
          color: "#757575",
        }}
        onClickBox={()=> setOpenDlgPrescription(true)}
      />
      <AddPrescription 
        openDlg = {openDlgPrescription} 
        closeDialog = {()=>setOpenDlgPrescription(false)}
        {...props}
        />
    </>
  );
}

export default PrescriptionBox;
