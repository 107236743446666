import React from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import FormInput from "components/Form/Controls/input";
import Widget from "components/Widget";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { setPatient } from "actions/Management";
import { callApiAsync, postAddContactDetails } from "api/apiList";

const ContactPatientForm = ({prescription}) => {
    const dispatch = useDispatch();

    const validationSchema = yup.object().shape({
        name: yup.string().required(<IntlMessages id="core.fieldRequired" />),
        lastName: yup.string().required(<IntlMessages id="core.fieldRequired" />),
        phone: yup.string().required(<IntlMessages id="core.fieldRequired" />)
    });

    const defaultValues = {
        name: prescription.patient ? prescription.patient.name : "",
        lastName: prescription.patient ? prescription.patient.lastName : "",
        phone: prescription.patient ? prescription.patient.phone : "",
        cellphone: prescription.patient ? prescription.patient.cellphone : "",
    };

    const methods = useForm({ defaultValues, resolver: yupResolver(validationSchema) });
    const { handleSubmit, errors } = methods;
    
    const successSaveMsg = IntlGetText("core.SuccessSave");
    const onSubmit = async (data, e) => { 
        const contactInfo = {...data, prescriptionId: prescription.id };

        await callApiAsync(
            () => postAddContactDetails(contactInfo),
            (data)=>{
                dispatch(setPatient(data));
                NotificationManager.success(successSaveMsg);
            }
        );
    };

    return (
        <div className="col-xl-8 col-lg-8 col-md-12 col-12">
            <Widget
                title="Datos de contacto paciente"
                styleName="jr-card-profile-sm"
            >
                <FormProvider {...methods}>
                    <form className="row" noValidate autoComplete="off">
                        <div className="col-md-6 col-12">
                            <FormInput
                                label="Nombres"
                                name="name"
                                required={true}
                                margin="normal"
                                errorobj={errors}
                                />
                        </div>
                        <div className="col-md-6 col-12">
                            <FormInput
                                label="Apellidos"
                                name="lastName"
                                required={true}
                                margin="normal"
                                errorobj={errors}
                                />
                        </div>
                        <div className="col-md-6 col-12">
                            <FormInput
                                label={<IntlMessages id="core.phone" />}
                                name="phone"
                                required={true}
                                margin="normal"
                                errorobj={errors}
                                />
                        </div>
                        <div className="col-md-6 col-12">
                            <FormInput
                                label={<IntlMessages id="core.cellphone" />}
                                name="cellphone"
                                required={false}
                                margin="normal"
                                errorobj={errors}
                                />
                        </div>
                    </form>
                </FormProvider>                
                <div className="col-12 text-center">
                    <ButtonAsync onClick={handleSubmit(onSubmit)} 
                        variant="contained"
                        className="jr-btn bg-green text-white"
                        >
                    Guardar
                    </ButtonAsync>
                </div>
            </Widget>
        </div>
    );
};

export default ContactPatientForm;