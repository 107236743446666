import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class ClinicHistoryFile extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <Button
          variant="contained"
          onClick={this.handleClickOpen}
          className="jr-btn jr-btn-xs text-white bg-indigo"
        >
          <i className={`zmdi zmdi-file zmdi-hc-fw`} />
        </Button>
        <Dialog open={this.state.open} onClose={this.handleRequestClose}>
          <DialogTitle>Archivo</DialogTitle>
          <DialogContent>
            <DialogContentText>
              De manera libre y voluntaria manifiesto mi consentimiento para
              participar en el programa de educación y apoyo a pacientes de la
              compañía, con el fin de beneficiarme de
              los servicios de dicho programa, en concordancia con el
              tratamiento ordenado por mi médico tratante.
            </DialogContentText>
            <DialogTitle className="text-grey">
              A. Introducción y propósito del Programa de Apoyo a Pacientes:
            </DialogTitle>
            <DialogContentText>
              El programa de apoyo a pacientes es un programa creado para
              brindar educación, seguimiento y acompañamiento a los pacientes
              con diagnóstico de enfermedad crónica, huérfana, ruinosa y/o
              catastrófica y sus cuidadores, que busca el bienestar de sus
              beneficiarios por medio de la generación de estrategias de
              educación, afrontamiento y empoderamiento de su estilo de vida,
              ayudándoles a alcanzar sus metas.
            </DialogContentText>
            <DialogTitle className="text-grey">
              B. Descripción del Programa de Apoyo a Pacientes:
            </DialogTitle>
            <DialogContentText>
              El Programa de Apoyo a Pacientes incluye las siguientes
              actividades: (i) Acompañar, educar, guiar, asesorar y apoyar al
              paciente en el proceso de aplicación de su tratamiento; (ii)
              Despejar dudas e inquietudes y educar a los pacientes, su familia
              y/o cuidadores en temas relacionados con la medicamento; (iii)
              Permitir el proceso de adaptación del paciente, su familia y/o
              cuidadores a las diferentes etapas correspondientes a la evolución
              de la enfermedad; (iv) Fortalecer en el paciente y su familia la
              aplicación de estrategias de afrontamiento y manejo de la
              enfermedad; (v) Fortalecer en los pacientes los comportamientos
              relacionados con el cumplimiento de los parámetros de
              administración de las dosis referidas por el médico tratante y en
              general las pautas de adhesión al tratamiento; (iv) Organización y
              desarrollo de actividades con fin de promover el bienestar y
              calidad de vida de los pacientes; (vii) Reporte de eventos
              adversos.
            </DialogContentText>
            <DialogTitle className="text-grey">
              C. Duración del Programa:
            </DialogTitle>
            <DialogContentText>
              El Programa de Educación y Apoyo a Pacientes durará el término
              durante el cual el Paciente permanezca en tratamiento.
            </DialogContentText>
            <DialogTitle className="text-grey">
              D. Habeas Data y Confidencialidad:
            </DialogTitle>
            <DialogContentText>
              La compañia se obliga a cumplir y a hacer cumplir a
              sus trabajadores, subordinados y contratistas todas las
              disposiciones legales en materia de Habeas Data y protección de la
              información, establecidas en la Ley 1581 de 2012 y en el Decreto
              Reglamentario 1377 de 2013, o aquellas normas que las modifiquen,
              subroguen o sustituyan. El manejo de datos sensibles y datos
              personales recibirá un tratamiento confidencial con el fin de
              garantizar la protección de la información de las personas que
              consten en bases de datos manejadas por la compañia, 
              quienes se comprometen a no transferir ni transmitir dicha
              información a terceros sin el previo consentimiento del paciente y
              siendo esta siempre de carácter estadístico estadístico o, con fines de <b>investigación científica y/o 
              publicación de artículos de investigación</b>. De ninguna manera se
              incluirán datos que permitan la identificación de pacientes,
              familiares y/o cuidadores.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ClinicHistoryFile;
