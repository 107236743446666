import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

let id = 0;

function createData(name, medicin, status) {
  id += 1;
  return { id, name, medicin, status };
}

const data = [
  createData(
    "Alicia Muñoz Diaz",
    <div className=" badge text-uppercase text-white bg-pink">Remodulin</div>,
    <div className=" badge text-uppercase text-white bg-green">Activo</div>
  ),
  createData(
    "Andres Perez",
    <div className=" badge text-uppercase text-white bg-pink">Remodulin</div>,
    <div className=" badge text-uppercase text-white bg-green">Activo</div>
  ),
  createData(
    "Luis Piñeres",
    <div className=" badge text-uppercase text-white bg-blue">Iclusig</div>,
    <div className=" badge text-uppercase text-white bg-green">Activo</div>
  ),
  createData(
    "Adriana Mesa",
    <div className=" badge text-uppercase text-white bg-pink">Remodulin</div>,
    <div className=" badge text-uppercase text-white bg-green">Activo</div>
  ),
  createData(
    "Lucía Medina",
    <div className=" badge text-uppercase text-white bg-pink">Remodulin</div>,
    <div className=" badge text-uppercase text-white bg-green">Activo</div>
  ),
  createData(
    "Cristian Ricaurte",
    <div className=" badge text-uppercase text-white bg-blue">Iclusig</div>,
    <div className=" badge text-uppercase text-white bg-green">Activo</div>
  ),
  createData(
    "Hernán Soto",
    <div className=" badge text-uppercase text-white bg-pink">Remodulin</div>,
    <div className=" badge text-uppercase text-white bg-amber">En suspensión</div>
  ),
];

export function DoctorTable() {
  return (
    <div className="table-responsive-material">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" width="10%">
              Estado
            </TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>Medicamento</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((n) => {
            return (
              <TableRow key={n.id}>
                <TableCell align="center">{n.status}</TableCell>
                <TableCell>{n.name}</TableCell>
                <TableCell>{n.medicin}</TableCell>
                <TableCell align="center">
                  <Button
                    component={Link}
                    to={"/app/patient/detail"}
                    color="primary"
                  >
                    <i className="zmdi zmdi-eye"></i>
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default DoctorTable;
