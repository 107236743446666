import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as dayjs from "dayjs";
import { setLoader } from "actions/Base";
import { IntlGetText } from "util/IntlMessages";
import DlgNurses from "components/DlgNurses";
import { callApi, getExpirationReport } from "api/apiList";
import Table, { defaultOptions, defaultCol } from "components/Form/Table";
import { ExpirationReportTypeEnum } from "constants/Enums";

const getObj = (tableMeta, data) =>
  data.find((x) => x.code === tableMeta.rowData[0]);

const getDateCol = (name, textId) => {
  return {
    name: name,
    label: IntlGetText(textId),
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
  };
};

const getBaseCols = (data) => {
  return [
    {
      name: "code",
      label: IntlGetText("core.code"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta, data);
          return (
            <Link
              to={{
                pathname: "/app/patient/detail",
                prescriptionId: rowObj.prescriptionId,
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    defaultCol("patient", "core.patientName"),
    defaultCol("patientState", "core.status"),
    {
      name: "nurse",
      label: IntlGetText("core.nurse"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta, data);
          return <DlgNurses nurse={value} nurses={rowObj.nurses}/>;
        }
      },
    },
    defaultCol("doctor", "core.attendingDoctor"),
    defaultCol("eps", "core.insurance"),
    defaultCol("regime", "core.regime"),
    defaultCol("ips", "core.institution"),
    defaultCol("olf", "insuranceAuthorization.marketChain"),
    defaultCol("pathology", "core.pathology"),
    defaultCol("formulationDate", "core.formulationDate"),
    defaultCol("prescriptionState", "core.prescriptionState"),
  ];
};

const ExpirationReport = ({ dateRange, type }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    if (!dateRange) return;

    callApi(
      () => getExpirationReport(type, dateRange.startDate, dateRange.endDate),
      (data) => setReportData(data),
      fnSetLoader
    );
  }, [dateRange]);

  const columns = getBaseCols(reportData);
  if (type === ExpirationReportTypeEnum.formula) {
    columns.push(getDateCol("endDate", "core.expirationDateFormula"));
  } else if (type === ExpirationReportTypeEnum.authorization) {
    columns.push(
      getDateCol(
        "authorizationDate",
        "insuranceAuthorization.authorizationDate"
      )
    );
    columns.push(
      defaultCol("authorizationCode", "insuranceAuthorization.code")
    );
    columns.push(defaultCol("medicineAmount", "formula.medicineAmount"));
    columns.push(
      getDateCol("expirationDate", "insuranceAuthorization.expirationDate")
    );
  } else if (type === ExpirationReportTypeEnum.delivery) {
    columns.push(getDateCol("lasDeliveryDate", "reports.lasDeliveryDate"));
    columns.push(defaultCol("medicineAmount", "formula.medicineAmount"));
  }
  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-5 mb-5">
        <Table
          className="border-dialog-card mt-2 mb-2"
          data={reportData}
          columns={columns}
          options={defaultOptions()}
        />
      </div>
    </>
  );
};

export default ExpirationReport;
