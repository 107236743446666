import React from "react";
import IntlMessages from "util/IntlMessages";

const PdfViewer = ({ src, width, height }) =>{  

  return (
    <iframe
            src= {src}
            width= {width}
            height= {height}
        >
        <p><IntlMessages id="pdfViewer.pdfNotSupported" /></p>
    </iframe>
  );
};
export default PdfViewer;