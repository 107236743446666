import React, { useState } from "react";
import InfoCard from "components/InfoCard/index";
import { IntlGetText } from "util/IntlMessages";
import ProcedureForm from "../modalForm/procedureForm";

const Procedure = (props) => {
  const [openDlgPatient, setOpenDlgPatient] = useState(false);
  const { impactInfo } = props;
  return (
    <>
      <InfoCard
        data={{
          icon: "hospital",
          title: IntlGetText("institution.procedure"),
          color: !impactInfo || !impactInfo.process
              ? "#757575"
              : "#20c997"
        }}
        onClickBox={() => setOpenDlgPatient(true)}
      />

      <ProcedureForm
        openDlg={openDlgPatient}
        setOpenDlg={setOpenDlgPatient}
        closeDialog={() => setOpenDlgPatient(false)}
        {...props}
      />
    </>
  );
};

export default Procedure;
