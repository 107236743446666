import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import { NotificationManager } from "react-notifications";
import CustomChart from "components/CustomChart";
import * as dayjs from "dayjs";
import { Grid } from "@material-ui/core";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { useForm, FormProvider } from "react-hook-form";
import { patientDistributionByEpsAndStateChart } from "./optionsCharts";
import { callApi, getPatientDistributionByEpsAndState } from "api/apiList";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";

const PatientByEpsAndStateGraph = ({
  epsOptions,
  minDate,
  maxDate,
  data,
  setData,
  resetSelectEps,
  top,
}) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));

  var startOf = dayjs().startOf("month");
  var endOf = dayjs().endOf("month");

  const msgWarning = IntlGetText("warning.maxEpsList");

  const defaultValues = {
    startDate: startOf,
    endDate: endOf,
  };

  const validationSchema = {
    epsList: yup.array().max(10, "No puedes seleccionar más de 10 elementos"),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { errors, watch, setValue } = methods;

  const onChangeEpsFilter = () => {
    if (!epsFilter || epsFilter.length === 0) {
        return;
      }
    if (epsFilter.length > 10) {
      NotificationManager.warning(msgWarning);
      return;
    }
    const valuesIdList = epsFilter.map((item) => item.value);

    var objFilter = {
      epsIds: valuesIdList,
      minDate: minDate,
      maxDate: maxDate,
    };

    callApi(
      () => getPatientDistributionByEpsAndState(objFilter),
      (x) => {
        setData(x);
      },
      fnSetLoader
    );
  };

  const epsFilter = watch("epsList");

  const resetSelect = () => {
    setValue("epsList", top);
  };
  useEffect(() => {
    onChangeEpsFilter();
  }, [epsFilter]);

  useEffect(() => {
    resetSelect();
  }, [resetSelectEps]);

  return (
    <>
      <div className="row mb-2">
        <div className="col-12">
          <div className="col-xl-12 col-lg-12 col-12">
            <div
              className="col-xl-12 col-lg-12 col-md-12 col-12 mt-2 pb-3"
              style={{ backgroundColor: "white" }}
            >
              <FormProvider {...methods}>
                <Grid container spacing={2}>
                  <>
                    <Grid item xs={12} md={6} lg={6}>
                      <FormSelectAutoComplete
                        name="epsList"
                        label={
                          <IntlMessages id="radicacionInsurance.Insurance" />
                        }
                        options={epsOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        margin="normal"
                        errorobj={errors}
                      />
                    </Grid>
                  </>
                </Grid>
              </FormProvider>
            </div>
            <CustomChart
              options={patientDistributionByEpsAndStateChart(data)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientByEpsAndStateGraph;
