import React from "react";
import { useDispatch } from "react-redux";
import IntlMessages from "util/IntlMessages";
import { Button } from "@material-ui/core";
import upload from "services/blobService";
import { setLoader } from "actions/Base";

const FileUpload  = ({id, setResult}) => {
    const dispatch = useDispatch();
    const fnSetLoader = (data) => dispatch(setLoader(data));

    const finalId = id ? id : new Date().getMilliseconds();
    const handleFileChange = async event => { 
        fnSetLoader(true);
        const file = event.target.files[0];
        await upload(file, async (fileName) => {
            setResult(fileName);
            fnSetLoader(false);
        });  
      };

    return (
        <label htmlFor={finalId}>
            <input id={finalId} type="file" name="file" style={{ display: 'none' }} onChange={handleFileChange}/>
            <Button
                color="secondary" aria-label="add" component="span"
            >
                <i className="zmdi zmdi-upload mr-2"></i> <IntlMessages id="core.uploadFile" />
            </Button>
        </label>
       )
}

export const FileUploadWithOriginalName  = ({children, ...props}) => {
    const dispatch = useDispatch();
    const fnSetLoader = (data) => dispatch(setLoader(data));

    const {id, setResult} = props;
    const finalId = id ? id : new Date().getMilliseconds();
    const handleFileChange = async event => { 
        fnSetLoader(true);
        const file = event.target.files[0];
        await upload(file, async (fileName, originalName) => {
            setResult({fileName, originalName});
            fnSetLoader(false);
        });  
      };

    return (
        <label htmlFor={finalId}>
            <input id={finalId} type="file" name="file" style={{ display: 'none' }} onChange={handleFileChange}/>
            {children}
        </label>
       )
}

export default FileUpload;