import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import { Link } from "react-router-dom";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import {
  callApi,
  getControlPanelReportRepKam,
  getControlPanelReportFileRepKam,
} from "api/apiList";
import { downloadFile } from "services/fileService";
import Table, { defaultOptions, defaultCol } from "components/Form/Table";
import { ProductBehavior } from "constants/Enums";
import DlgNurses from "components/DlgNurses";

const getObj = (tableMeta, data) =>
  data.find((x) => x.code === tableMeta.rowData[0]);

const dateFormat = "YYYY/MM/DD";

const getIclusigCols = (data, setCurrentPatientId) => {
  return [
    {
      name: "code",
      label: IntlGetText("core.code"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta, data);
          return (
            <Link onClick={()=>{
              setCurrentPatientId(rowObj.patientId);
              }}>
              {value}
            </Link>
          );
        },
      },
    },
    defaultCol("city", "core.city"),
    {
      name: "nurse",
      label: IntlGetText("core.nurse"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta, data);
          return <DlgNurses nurse={value} nurses={rowObj.nurses}/>;
        }
      },
    },
    defaultCol("doctor", "core.attendingDoctor"),
    defaultCol("eps", "core.insurance"),
    defaultCol("regime", "core.regime"),
    defaultCol("ips", "core.institution"),
    defaultCol("olf", "insuranceAuthorization.marketChain"),
    defaultCol("patientState", "core.status"),
    defaultCol("formulatedDose", "core.formulatedDose"),
    defaultCol("actualDose", "core.actualDose"),
    defaultCol("formulationDate", "core.formulationDate"),
    defaultCol("formulaDuration", "core.formulaDuration"),
    defaultCol(
      "prescriptionDescription",
      "controlPanelRm.prescriptionDescription"
    ),
    defaultCol("prescriptionState", "core.prescriptionState"),
    {
      name: "lastProcessingDate",
      label: IntlGetText("core.lastProcessingDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    {
      name: "olfRadicacionDate",
      label: IntlGetText("core.olfRadicacionDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    {
      name: "previousDeliveryDate",
      label: IntlGetText("core.previousDeliveryDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    {
      name: "actualDeliveryDate",
      label: IntlGetText("core.actualDeliveryDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    {
      name: "proyectionDeliveryDate",
      label: IntlGetText("core.currentMonthProjection"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    defaultCol("currentDeliveryNumber", "core.currentDeliveryNumber"),
    defaultCol("numberScheduledDeliveries", "core.numberScheduledDeliveries"),
    defaultCol("lastNoteNursing", "morisKySurvey.comment")
  ];
};

const getGalafoldCols = (data, setCurrentPatientId) => {
  return [
    {
      name: "code",
      label: IntlGetText("core.code"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta, data);
          return (
            <Link onClick={()=>{
              setCurrentPatientId(rowObj.patientId);
              }}>
              {value}
            </Link>
          );
        },
      },
    },
    defaultCol("city", "core.city"),
    {
      name: "nurse",
      label: IntlGetText("core.nurse"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta, data);
          return <DlgNurses nurse={value} nurses={rowObj.nurses}/>;
        }
      },
    },
    defaultCol("doctor", "core.attendingDoctor"),
    defaultCol("eps", "core.insurance"),
    defaultCol("regime", "core.regime"),
    defaultCol("ips", "core.institution"),
    defaultCol("olf", "insuranceAuthorization.marketChain"),
    defaultCol("patientState", "core.status"),
    defaultCol("formulationDate", "core.formulationDate"),
    defaultCol("formulaDuration", "core.formulationPeriod"),
    defaultCol(
      "prescriptionDescription",
      "controlPanelRm.prescriptionDescription"
    ),
    defaultCol("prescriptionState", "core.prescriptionState"),
    {
      name: "lastProcessingDate",
      label: IntlGetText("core.lastProcessingDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    defaultCol("olfRadicacionDate", "core.olfRadicacionDate"),
    {
      name: "previousDeliveryDate",
      label: IntlGetText("controlPanelGl.previousDeliveryDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    {
      name: "actualDeliveryDate",
      label: IntlGetText("controlPanelGl.actualDeliveryDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    {
      name: "proyectionDeliveryDate",
      label: IntlGetText("core.currentMonthProjection"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    defaultCol("deliveriesMade", "core.deliveriesMade"),
    defaultCol("scheduledDeliveries", "core.scheduledDeliveries"),
    defaultCol("blisterStartDate", "controlPanelGl.blisterStartDate"),
    defaultCol("lastNoteNursing", "morisKySurvey.comment")
  ];
};

const getRemodulinCols = (data, setCurrentPatientId) => {
  return [
    {
      name: "code",
      label: IntlGetText("core.code"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta, data);
          return (
            <Link onClick={()=>{
              setCurrentPatientId(rowObj.patientId);
              }}>
              {value}
            </Link>
          );
        },
      },
    },
    defaultCol("city", "core.city"),
    {
      name: "nurse",
      label: IntlGetText("core.nurse"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta, data);
          return <DlgNurses nurse={value} nurses={rowObj.nurses}/>;
        }
      },
    },
    defaultCol("doctor", "core.attendingDoctor"),
    defaultCol("eps", "core.insurance"),
    defaultCol("regime", "core.regime"),
    defaultCol("ips", "core.institution"),
    defaultCol("olf", "insuranceAuthorization.marketChain"),
    defaultCol("patientState", "core.status"),
    defaultCol("actualDose", "controlPanelRm.dose"),
    //defaultCol("infusionRate", "formula.infusionRate"),
    defaultCol("concentration", "controlPanelRm.concentration"),
    defaultCol("formulationDate", "core.formulationDate"),
    defaultCol(
      "prescriptionDescription",
      "controlPanelRm.prescriptionDescription"
    ),
    defaultCol("formulaDuration", "core.formulaDuration"),
    defaultCol("prescriptionState", "core.prescriptionState"),
    {
      name: "lastProcessingDate",
      label: IntlGetText("core.lastProcessingDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    defaultCol("olfRadicacionDate", "core.olfRadicacionDate"),
    {
      name: "previousDeliveryDate",
      label: IntlGetText("core.previousDeliveryDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    {
      name: "actualDeliveryDate",
      label: IntlGetText("core.actualDeliveryDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    {
      name: "proyectionDeliveryDate",
      label: IntlGetText("core.currentMonthProjection"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format(dateFormat) : "";
        },
      },
    },
    defaultCol("currentDeliveryNumber", "core.currentDeliveryNumber"),
    defaultCol("numberScheduledDeliveries", "core.numberScheduledDeliveries"),
    defaultCol("lastNoteNursing", "morisKySurvey.comment"),
  ];
};

const ControlPanelReport = ({ productBehavior, setOpenDlgDetail, setCurrentPatientId }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [reportData, setReportData] = useState([]);

  const downloadReport = () => {
    callApi(
      () => getControlPanelReportFileRepKam(productBehavior),
      (data) => downloadFile(data),
      fnSetLoader
    );
  };

  useEffect(() => {
    if (!productBehavior && productBehavior !== 0) return;

    callApi(
      () => getControlPanelReportRepKam(productBehavior),
      (data) => setReportData(data),
      fnSetLoader
    );
  }, [productBehavior]);

  let columns = [];
  if (productBehavior === ProductBehavior.remodulin)
    columns = getRemodulinCols(reportData, setCurrentPatientId);
  else if (productBehavior === ProductBehavior.iclusig)
    columns = getIclusigCols(reportData, setCurrentPatientId);
  else if (productBehavior === ProductBehavior.galafold)
    columns = getGalafoldCols(reportData, setCurrentPatientId);

  return (
    <>
      <div className="col-lg-12 col-md-12 col-xs-12 text-left mt-2 mb-4">
        <Button onClick={downloadReport} variant="contained" color="secondary">
          <IntlMessages id="core.download" />
        </Button>
      </div>

      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-5 mb-5">
        <Table
          className="border-dialog-card mt-2 mb-2"
          data={reportData}
          columns={columns}
          options={defaultOptions()}
        />
      </div>
    </>
  );
};

export default ControlPanelReport;
