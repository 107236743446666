import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "actions/Base";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import Button from "@material-ui/core/Button";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { useForm, FormProvider } from "react-hook-form";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import { showAuthLoader, reloginSignIn, setInitUrl } from "actions/Auth";
import CircularProgress from "@material-ui/core/CircularProgress";
import { callApi, getReloginInfo } from "api/apiList";


const Relogin = () => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const { loader, alertMessage, showMessage } = useSelector(
    ({ auth }) => auth
  );

  const [company, setCompany] = useState([])
  const [companyFilter, setCompanyFilter] = useState("")
  const [emailTotalList, setEmailTotalList] = useState([])
  const [emailList, setEmailList] = useState([])

  const msgError = IntlGetText("core.operationError");
  const msgUserUnauthorized = IntlGetText("core.userUnauthorized");

  const defaultValues = {};
  const validationSchema = {
    company: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    email: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, errors } = methods;

  const onSubmit = handleSubmit((data) => {
    const obj = {
      username: data.email.value,
    };
    dispatch(setInitUrl("/"));
    dispatch(showAuthLoader());
    dispatch(reloginSignIn(obj));
  });

  useEffect(() => {
    callApi(getReloginInfo,
      (data) => {
        setCompany(data.enterprise)
        setEmailTotalList(data.user)
        setEmailList(data.user);
      },
      fnSetLoader
    )
  }, [])

  useEffect(() => {
    if(!companyFilter){
      setEmailList(emailTotalList);
      return;
    }
    let filter = emailTotalList.filter((x) => x.enterpriseKey === companyFilter)
    setEmailList(filter);
  }, [companyFilter])

  return (
    <div
      style={{ height: "75%" }}
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
    >
      <div className="app-login-main-content">
        <div
          className="app-login-form m-4"
          style={{ width: 500, marginTop: 20 }}
        >
          <form>
            <fieldset className="relogin-from">
              <FormProvider {...methods}>
                <FormSelectAutoComplete
                  name="company"
                  label={<IntlMessages id="appModule.company" />}
                  options={company}
                  margin="normal"
                  required={true}
                  onChange={(e) => setCompanyFilter(!e ? null : e.value)}
                  errorobj={errors}
                />
                <br />
                <FormSelectAutoComplete
                  name="email"
                  label={<IntlMessages id="appModule.email" />}
                  options={emailList}
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </FormProvider>

              <div className="mb-3 d-flex align-items-center relogin-btn justify-content-between">
                <Button
                  onClick={onSubmit}
                  style={{ marginTop: 20 }}
                  variant="contained"
                  color="primary"
                >
                  <IntlMessages id="appModule.signIn" />
                </Button>
              </div>
            </fieldset>
          </form>
          {loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && NotificationManager.error(alertMessage === "401" ? msgUserUnauthorized : msgError)}
        </div>
      </div>
    </div>
  );
};

export default Relogin;
