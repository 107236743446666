import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import Form from "./Form";
import Table from "./Table";
import Indicator from "./indicator.js";
import {
  callApi,
  callApiAsync,
  getMarketChainListTD,
  getGetCities,
  getTracingDeliverList,
  postCreateUpdateTracingDelivery,
  getAllStateProvince,
  getCitiesByStateProvinceId,
  getGetCompanies,
  getPharmaAmount,
} from "api/apiList";
import { callStartImpact, callReloadConsumptionTracing } from "actions/Impact";
import { BoolList, BarrierList } from "constants/Enums";

const TracingDelivery = ({ deliverHealthData }) => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const {
    impactStartDate,
    consentSigned,
    isCIRequired,
    reloadConsumptionTracing,
  } = useSelector(({ impact }) => impact);
  const [open, setOpen] = useState(false);
  const [openCRU, setOpenCRU] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleOpenCRU = () => setOpenCRU(true);
  const handleRequestCloseCRU = () => setOpenCRU(false);
  const handleRequestCloseDel = () => setOpenDeleteDialog(false);
  const [setCityList] = useState([]);
  const [oplList, setOplList] = useState([]);
  const [tracingDeliveryList, setTracingDeliveryList] = useState([]);
  const [indicatorInfo] = useState(null);
  const [currentRowObj, setCurrentRowObj] = useState(null);
  const [pharmaAmount, setPharmaAmount] = useState(null);
  const [currentObjEdit, setCurrentObjEdit] = useState(false);
  const [stateProvinceList, setStateProvinceList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const handleRequestClose = () => {
    setOpen(false);
    setCurrentObjEdit(false);
  };

  const msgConsentRequired = IntlGetText("impact.consentRequired");
  const msgSuccess = IntlGetText("core.SuccessSave");

  const handleClickOpen = async () => {
    console.log("deliverHealthData", deliverHealthData);

    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }

    if (!deliverHealthData || deliverHealthData === 0) {
      NotificationManager.warning(
        "Es necesario tener registrado la entrega en formula médica"
      );
      return;
    }

    if (isCIRequired && !consentSigned) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }
    setOpen(true);
  };

  const loadDeliveryData = () => {
    callApi(
      () => getTracingDeliverList(selectedPrescription.id),
      setTracingDeliveryList
    );
  };
  const loadData = () => {
    loadDeliveryData();
    callApiAsync(
      () => getPharmaAmount(selectedPrescription.id),
      setPharmaAmount
    );
    callApi(getGetCities, setCityList);
    callApi(getMarketChainListTD, setOplList);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    callApi(getAllStateProvince, setStateProvinceList);
    callApi(getGetCompanies, setCompanyList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (reloadConsumptionTracing) {
      loadData();
      dispatch(callReloadConsumptionTracing(false));
    }
  }, [reloadConsumptionTracing]);

  const handleCreateUpdate = async (data) => {
    await callApiAsync(
      () => postCreateUpdateTracingDelivery(data),
      () => {
        handleRequestCloseCRU();
        NotificationManager.success(msgSuccess);
        loadData();
      }
    );
  };

  var readOnly = false;

  return (
    <>
      <InfoCard
        data={{
          icon: "hospital",
          title: IntlGetText("deliveryTracing.cardTitle"),
          subTitle: IntlGetText("medicineConsumption.cardSubTitle"),
          color: "#757575"
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="deliveryTracing.cardTitle" />
        </DialogTitle>
        <DialogContent>
          <Indicator indicatorInfo={indicatorInfo} />
          <Table
            data={tracingDeliveryList}
            loadTable={loadDeliveryData}
            handleOpenCRU={handleOpenCRU}
            handleOpenDel={() => setOpenDeleteDialog(true)}
            setCurrentRowObj={setCurrentRowObj}
            currentImpact={selectedPrescription.impactId}
            readOnly={readOnly}
            setCurrentObjEdit={setCurrentObjEdit}
          />
          <Form
            obj={currentRowObj}
            openCRU={openCRU}
            handleRequestClose={handleRequestCloseCRU}
            handleCreateUpdate={handleCreateUpdate}
            stateProvinceList={stateProvinceList}
            companyList={companyList}
            oplList={oplList}
            prescriptionId={selectedPrescription.id}
            currentObjEdit={currentObjEdit}
            getCitiesByStateProvinceId={getCitiesByStateProvinceId}
            callApi={callApi}
            pharmaAmount={pharmaAmount}
            boolList={BoolList()}
            barrier={BarrierList()}
            deliverHealthData={deliverHealthData}
          />
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={openDeleteDialog}
            disableBackdropClick={true}
            onClose={handleRequestCloseDel}
          >
            <DialogTitle>
              <IntlMessages id="additionalMedications.MsgDelConfirmation" />
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleRequestCloseDel} color="secondary">
                <IntlMessages id="core.cancel" />
              </Button>
              {/* <Button onClick={handleDelete} color="primary">
                <IntlMessages id="core.accept" />
              </Button> */}
            </DialogActions>
          </Dialog>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TracingDelivery;
