
import React, { useState, useEffect } from "react";
import * as dayjs from "dayjs";
import { IntlGetText } from "util/IntlMessages";
import Table, { getTextLabels, defaultCol } from "components/Form/Table";
import { callApi, getProductsAndStates } from "api/apiList";


const ImpactTable = ({ data }) => {
  const [impactTypeList,setImpactTypeList] = useState([]);

  useEffect(()=>{
    callApi(
      getProductsAndStates,
      (data)=>{ 
        setImpactTypeList(data.prescriptionStates.filter(x=>x.isImpactType));
      }
    );
  },[]);

  const getObj = (tableMeta) => data.find(x=>x.id === tableMeta.rowData[0]);  

  var columns =  [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display:"excluded"
      },
    },
    {
      name: "impactDate",
      label: IntlGetText("core.impact"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD hh:mm a") : "";
        },
      },
    },
    defaultCol("code", "radicacionInsurance.Code"),
    {
      name: "radicacionDate",
      label: IntlGetText("radicadoOLF.radicadoDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    defaultCol("radicacionPoint", "radicacionInsurance.radicacionPoint")
  ];
  
  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    pagination:false,
    textLabels: getTextLabels()
  };

  return (
    <Table
      className="border-dialog-card"
      data={data}
      columns={columns}
      options={options} />
  );
};

export default ImpactTable;