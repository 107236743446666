import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button, Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions 
} from "@material-ui/core";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import FormDateTimePicker from "components/Form/Controls/dateTimePicker";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import { ImpactStateList, ImpactStateEnum } from "constants/Enums";

const getCboValue = (items, id) => {
    if (typeof id == "object") {
        return id;
    }
    const result = items.find((x) => x.id === id);
    return result ? { label: result.name, value: result.id } : "";
};  

const DialogRescheduleImpact = ({open, handleRequestClose, commentsList, impact }) => {
    const impactStateList = ImpactStateList();
    
    const defaultValues = { 
        planningDate: null,
        breachId: null,
        breachComment: null,
        state: null
    };

    const methods = useForm({ defaultValues });
    const { watch, setValue, errors } = methods;
      
    const isBreach = (impact) => {
      return impact && impact.state == ImpactStateEnum.cancelled;
    }
    
    const loadData = () => {
      setValue("planningDate",dayjs(impact.planningDate));
      setValue("breachId", getCboValue(commentsList, impact.breachId));
      setValue("breachComment", impact.breachComment);
      setValue("state", getCboValue(impactStateList, impact.state));
    };

    useEffect(()=>{
      if(open){
        setTimeout(loadData,150);
      }
    },[open]);
    
    const cboHandleChange = (name) => (event) => {
        setValue(name, event);
    };

    return (
        <Dialog 
          open={open} 
          fullWidth={true} 
          maxWidth="xs"           
          disableBackdropClick={true}
          onClose={handleRequestClose} >
            <DialogTitle>{<IntlMessages id="core.impact" />}</DialogTitle>
            <DialogContent className="new-patient-dialog-content">
                <FormProvider {...methods}>
                    <form className="row" noValidate autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                          <label>{`${impact.patient} (${impact.patientCode}) - ${impact.impactType}`}</label>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                        <FormSelectAutoComplete
                            name="state"
                            label={<IntlMessages id="core.state" />}
                            options={impactStateList}
                            margin="normal"
                            disabled={true}
                            required={true}
                            errorobj={errors}
                        />
                        </Grid>
                        <Grid item md={12}>
                            <FormDateTimePicker
                                name="planningDate"
                                label={IntlGetText("core.dateAndTime")}  
                                format="YYYY-MM-DD hh:mm A"  
                                disabled={true}
                                required={true} 
                                errorobj={errors}  
                            />
                        </Grid>
                        { isBreach(impact) &&
                          <Grid item xs={12} md={12} lg={12}>
                            <FormSelectAutoComplete
                              name="breachId"
                              label={<IntlMessages id="impact.BreachReason" />}
                              options={commentsList}
                              value={watch("breachId")}
                              onChange={cboHandleChange("breachId")}
                              margin="normal"
                              disabled={true}
                              required={true}
                              errorobj={errors}
                            />
                          </Grid>
                        }                      
                        { isBreach(impact) &&
                          <Grid item md={12}>
                              <FormInput         
                                  name="breachComment"         
                                  label={<IntlMessages id="core.other" />}                                
                                  multiline
                                  rows={5}
                                  variant="outlined"
                                  disabled={true}
                                  required={true}
                                  errorobj={errors}
                              />
                          </Grid>
                        }
                        </Grid>
                    </form>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRequestClose} color="secondary">
                    {<IntlMessages id="core.close" />}
                </Button>
            </DialogActions>
        </Dialog>
    );
  };

  export default DialogRescheduleImpact;