import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Grid } from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete, { getCboValue } from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { SwitchYesNo } from "components/Form/Controls/switchYesNo";
import { setLoader } from "actions/Base";
import {
  callApi,
  callApiAsync,
  getAdverseEventByClinicalFinding,
  postAdverseEventPartialSave
} from "api/apiList";
import {  
  ProductBehavior, 
  FormulaType,
  ForeiaDetectionList,
  ForeiaClassificationList,
  ForeiaDenouementList,
  AdverseEventStateEnum,
  ForeiaDenouementEnum
} from "constants/Enums";

const Technovigilance = ({obj}) =>{
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  
  const [adverseEvent, setAdverseEvent] = useState(null);

  const foreiaDetectionList = ForeiaDetectionList();
  const foreiaClassificationList = ForeiaClassificationList();
  const foreiaDenouementList = ForeiaDenouementList();
  
  const msgSuccess = IntlGetText("core.SuccessSave");

  const defaultValues = {    
    creationDate: null,
    adverseEventType: "",
    dosage: "",
    eventDate: "",
    infusionRate: "",
    supplyFrequency: "",

    adverseEventStartDate: null,
    descriptionAdverseEvent: "",

    operatingArea: "",
    usedMoreThanOnce: false,
    detection: null,
    classification: null,
    denouement: null,
    denouementOther: "",
    actions: "",
    reportedToManufacturer: false,
    reportedToManufacturerDate: null,
    availableForEvaluation: false,
    sentToManufacturer: false,
    sentToManufacturerDate: null,
    authorizesDisclosure: false
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, setValue, watch, errors } = methods;

  const reportedToManufacturerValue = watch("reportedToManufacturer");
  const sentToManufacturerValue = watch("sentToManufacturer");
  const denouementValue = watch("denouement");

  useEffect(()=>{
    if(!reportedToManufacturerValue)
      setValue("reportedToManufacturerDate", null);
  },[reportedToManufacturerValue]);
  useEffect(()=>{
    if(!sentToManufacturerValue)
      setValue("sentToManufacturerDate", null);
  },[sentToManufacturerValue]);
  useEffect(()=>{
    if(!denouementValue || denouementValue.value !== ForeiaDenouementEnum.other)
      setValue("denouementOther", "");
  },[denouementValue]);

  const loadData = (data) => {
    setAdverseEvent(data);
    setValue("creationDate", !data || !data.creationDate ? null : dayjs(data.creationDate));
    setValue("adverseEventType", !data || !data.adverseEventType? "" : data.adverseEventType);
    setValue("dosage", !data || !data.dosage ? "" : data.dosage);
    setValue("infusionRate", !data || !data.infusionRate ? "" : data.infusionRate);
    setValue("supplyFrequency", !data || !data.supplyFrequency ? "" : data.supplyFrequency);
    
    setValue("adverseEventStartDate", !data || !data.adverseEventStartDate ? null : dayjs(data.adverseEventStartDate));
    setValue("descriptionAdverseEvent", !data || !data.descriptionAdverseEvent ? "" : data.descriptionAdverseEvent);
    
    const obj = !data ? null : data.technovigilance;
    setValue("operatingArea", !obj || !obj.operatingArea ? "" : obj.operatingArea);
    setValue("usedMoreThanOnce", !obj || !obj.usedMoreThanOnce ? false : obj.usedMoreThanOnce);
    setValue("detection", !obj ? "" : getCboValue(foreiaDetectionList, obj.detection));
    setValue("classification", !obj ? "" : getCboValue(foreiaClassificationList, obj.classification));
    setValue("denouement", !obj ? "" : getCboValue(foreiaDenouementList, obj.denouement));
    setValue("denouementOther", !obj || !obj.denouementOther ? "" : obj.denouementOther);
    setValue("actions", !obj || !obj.actions ? "" : obj.actions);
    setValue("reportedToManufacturer", !obj || !obj.reportedToManufacturer ? false : obj.reportedToManufacturer);
    setValue("reportedToManufacturerDate", !obj || !obj.reportedToManufacturerDate ? null : dayjs(obj.reportedToManufacturerDate));
    setValue("availableForEvaluation", !obj || !obj.availableForEvaluation ? false : obj.availableForEvaluation);
    setValue("sentToManufacturer", !obj || !obj.sentToManufacturer ? false : obj.sentToManufacturer);
    setValue("sentToManufacturerDate", !obj || !obj.sentToManufacturerDate ? null : dayjs(obj.sentToManufacturerDate));
    setValue("authorizesDisclosure", !obj || !obj.authorizesDisclosure ? false : obj.authorizesDisclosure);
  };

  useEffect(()=>{
    if(obj && obj.adverseEvent){
      callApi(
        ()=>getAdverseEventByClinicalFinding(obj.id),
        (data)=>loadData(data),
        fnSetLoader
      );
    }
  },[obj]);

  const onSubmit = async (d, e) => {
    let result0 = !adverseEvent ? {} : {...adverseEvent};
    var result = {
      ...result0,
      adverseEventStartDate: !d.adverseEventStartDate ? null : dayjs(d.adverseEventStartDate).format("YYYY-MM-DDTHH:mm:ss"),
      descriptionAdverseEvent: d.descriptionAdverseEvent,
      
      technovigilance: {
        operatingArea: d.operatingArea,
        usedMoreThanOnce: d.usedMoreThanOnce,
        detection: !d.detection? null : Number(d.detection.value),
        classification: !d.classification? null : Number(d.classification.value),
        denouement: !d.denouement? null : Number(d.denouement.value),
        denouementOther: d.denouementOther,
        actions: d.actions,
        reportedToManufacturer: d.reportedToManufacturer,
        reportedToManufacturerDate: !d.reportedToManufacturerDate ? null : dayjs(d.reportedToManufacturerDate).format("YYYY-MM-DDTHH:mm:ss"),
        availableForEvaluation: d.availableForEvaluation,
        sentToManufacturer: d.sentToManufacturer,
        sentToManufacturerDate: !d.sentToManufacturerDate ? null : dayjs(d.sentToManufacturerDate).format("YYYY-MM-DDTHH:mm:ss"),
        authorizesDisclosure: d.authorizesDisclosure
      }
    };    

    await callApiAsync(
      ()=> postAdverseEventPartialSave(result),
      ()=> NotificationManager.success(msgSuccess),
      fnSetLoader
    );
  };

  const disabled = !(adverseEvent && (adverseEvent.status === AdverseEventStateEnum.pending || adverseEvent.status === AdverseEventStateEnum.inconsistentReport));
  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
      <FormProvider {...methods}>
        <form className="row" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <FormDatePicker
                name="creationDate"
                label={<IntlMessages id="core.date" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="adverseEventType"
                label={<IntlMessages id="core.type" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="dosage"
                label={<IntlMessages id="core.dosage" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            {(!obj || obj.productBehaviorId === ProductBehavior.remodulin || obj.productBehaviorId === ProductBehavior.dacepton ) &&
              <Grid item xs={12} md={6} lg={6}>
                <FormInput
                  name="infusionRate"
                  label={<IntlMessages id="formula.infusionRate" />}
                  variant="outlined"
                  margin="normal"
                  disabled={true}
                />
              </Grid>
            }
            { (!obj || (obj.formulaType === FormulaType.MIPRES &&
              obj.productBehaviorId !== ProductBehavior.remodulin &&
              obj.productBehaviorId !== ProductBehavior.dacepton)) &&
              <Grid item xs={12} md={6} lg={6}>
                <FormInput
                  name="supplyFrequency"
                  label={<IntlMessages id="formula.supplyFrequency" />}
                  variant="outlined"
                  margin="normal"
                  disabled={true}
                />
              </Grid>
            }
            <Grid item xs={12} md={6} lg={6}>
              <FormDatePicker
                name="adverseEventStartDate"
                label={<IntlMessages id="adverseEvent.adverseEventStartDate" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="descriptionAdverseEvent"
                label={<IntlMessages id="foreia.description" />}
                variant="outlined"
                multiline
                rows={4}
                margin="normal"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="operatingArea"
                label={<IntlMessages id="foreia.operatingArea" />}
                variant="outlined"
                multiline
                rows={2}
                margin="normal"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <label><IntlMessages id="foreia.usedMoreThanOnce" /></label>
              <SwitchYesNo name="usedMoreThanOnce" className="ml-5" disabled={disabled}/>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="detection"
                label={<IntlMessages id="foreia.detection" />}
                options={foreiaDetectionList}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="classification"
                label={<IntlMessages id="foreia.classification" />}
                options={foreiaClassificationList}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="denouement"
                label={<IntlMessages id="foreia.denouement" />}
                options={foreiaDenouementList}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="denouementOther"
                label={<IntlMessages id="foreia.denouementOther" />}
                variant="outlined"
                margin="normal"
                required={!(disabled || !denouementValue || denouementValue.value !== ForeiaDenouementEnum.other)}
                disabled={disabled || !denouementValue || denouementValue.value !== ForeiaDenouementEnum.other}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="actions"
                label={<IntlMessages id="foreia.actions" />}
                variant="outlined"
                multiline
                rows={3}
                margin="normal"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <label><IntlMessages id="foreia.reportedToManufacturer" /></label>
              <SwitchYesNo name="reportedToManufacturer" className="ml-5" disabled={disabled}/>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormDatePicker
                name="reportedToManufacturerDate"
                label={<IntlMessages id="foreia.reportedToManufacturerDate" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={disabled || !reportedToManufacturerValue}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <label><IntlMessages id="foreia.availableForEvaluation" /></label>
              <SwitchYesNo name="availableForEvaluation" className="ml-5" disabled={disabled}/>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <label><IntlMessages id="foreia.sentToManufacturer" /></label>
              <SwitchYesNo name="sentToManufacturer" className="ml-5" disabled={disabled}/>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormDatePicker
                name="sentToManufacturerDate"
                label={<IntlMessages id="foreia.sentToManufacturerDate" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={disabled || !sentToManufacturerValue}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <label><IntlMessages id="foreia.authorizesDisclosure" /></label>
              <SwitchYesNo name="authorizesDisclosure" className="ml-5" disabled={disabled}/>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      {!disabled && (
        <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
          <ButtonAsync onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
          >
            {<IntlMessages id="core.save" />}
          </ButtonAsync>
        </div>
      )}
    </div>      
  );
};

export default Technovigilance;