import React, { PureComponent } from "react";
import {
  Bar,
  ComposedChart,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { data1, data2, data3 } from "./data";

import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import CardBox from "components/CardBox";


class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text
        x={x}
        y={y}
        dy={-10}
        fill={stroke}
        fontSize={10}
        textAnchor="middle"
      >
        {value}
      </text>
    );
  }
}

const remodulin = (props) => {
  return (
    <div className="app-wrapper">
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <ContainerHeader
          match={props.match}
          title={<IntlMessages id="kpi.impacts" />}
        />
        <div className="row">
          <div className="col-md-6">
            <CardBox
              heading={<IntlMessages id="kpi.impacts.totalImpacts" />}
              styleName="text-center"
            >
              <ResponsiveContainer width="100%" height={200}>
                <LineChart
                  data={data1}
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                >
                  <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Line
                    dataKey="uv"
                    stroke="#3367d6"
                    fill="#3367d6"
                    name={<IntlMessages id="kpi.impacts" />}
                    label={<CustomizedLabel />}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardBox>

            <CardBox
              heading={<IntlMessages id="kpi.impacts.averageImpactNurseDay" />}
              styleName="text-center"
            >
              <ResponsiveContainer width="100%" height={200}>
                <LineChart
                  data={data2}
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                >
                  <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Line
                    dataKey="uv"
                    stroke="#3367d6"
                    fill="#3367d6"
                    name={<IntlMessages id="kpi.impacts" />}
                    label={<CustomizedLabel />}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardBox>
          </div>
          <div className="col-md-6">
            <CardBox
              heading={<IntlMessages id="kpi.impacts.monthImpacts" />}
              styleName="text-center"
            >
              <ResponsiveContainer width="100%" height={530}>
                <ComposedChart
                  data={data3}
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                >
                  <XAxis dataKey="name" padding={{ left: 40, right: 40 }} />
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign="top" />
                  <CartesianGrid stroke="#f5f5f5" />
                  <Bar
                    dataKey="b1"
                    barSize={30}
                    fill="#3367d6"
                    label={<CustomizedLabel />}
                    name={
                      <IntlMessages id="kpi.averageImpactNurseDay.chartData1" />
                    }
                  />
                  <Bar
                    dataKey="b2"
                    barSize={30}
                    fill="#0088FE"
                    label={<CustomizedLabel />}
                    name={
                      <IntlMessages id="kpi.averageImpactNurseDay.chartData2" />
                    }
                  />
                  <Line
                    dataKey="l1"
                    fill="#ffc658"
                    stroke="#ffc658"
                    label={<CustomizedLabel />}
                    name={
                      <IntlMessages id="kpi.averageImpactNurseDay.chartData3" />
                    }
                  />
                  <Line
                    dataKey="l2"
                    stroke="#59AA2B"
                    fill="#59AA2B"
                    label={<CustomizedLabel />}
                    name={
                      <IntlMessages id="kpi.averageImpactNurseDay.chartData4" />
                    }
                  />
                  <Line
                    dataKey="l3"
                    stroke="#59AA2B"
                    fill="#59AA2B"
                    label={<CustomizedLabel />}
                    name={
                      <IntlMessages id="kpi.averageImpactNurseDay.chartData5" />
                    }
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </CardBox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default remodulin;
