import {
    CURRENT_MENU_ITEM_ID,    
    ON_HIDE_LOADER,
    ON_TOGGLE_DRAWER,
    MANAGE_SEARCH_PRESCRIPTIONS,
    LOAD_MANAGE_MODULE,
    SELECT_PRODUCT_LINE,
    SELECT_PRESCRIPTION_STATE,
    SET_PRESCRIPTION_STATE,
    LOAD_PRESCRIPTIONS,
    SELECT_PRESCRIPTION,
    SET_LOADER_MANAGE_MODULE,
    SHOW_ALERT_MESSAGE,
    LOAD_PATIENT_INFO,
    UPDATE_SELECTED_PRESCRIPTION,
    REMOVE_SELECTED_PRESCRIPTION,
    ADD_PRESCRIPTION_GO_TRACING
  } from "../constants/ActionTypes";

  export const setSelectedMenuItem = (menuItemId) => {
    return {
      type: CURRENT_MENU_ITEM_ID,
      payload: { menuItemId },
    };
  };
  export const hideManageLoader = () => {
    return {
      type: ON_HIDE_LOADER,
    };
  };
  export const onToggleDrawer = () => {
    return {
      type: ON_TOGGLE_DRAWER,
    };
  };
  export const updateManageSearch = (searchText) => {
    return {
      type: MANAGE_SEARCH_PRESCRIPTIONS,
      payload: searchText,
    };
  };
  export const loadManageModule = (productLines, prescriptionStates) => {
    return {
      type: LOAD_MANAGE_MODULE,
      payload: {
          productLines,
          prescriptionStates
        }
    };
  };
  export const setSelectedProductLine = (productLineId) => {
    return {
      type: SELECT_PRODUCT_LINE,
      payload: productLineId,
    };
  };
  export const setSelectedPrescriptionState = (prescriptionStateId) => {
    return {
      type: SELECT_PRESCRIPTION_STATE,
      payload: prescriptionStateId,
    };
  };
  export const setPrescriptionState = (state) => {
    return {
      type: SET_PRESCRIPTION_STATE,
      payload: state,
    };
  };
//   export const loadPrescriptions = (prescriptions) => {
//     return {
//       type: LOAD_PRESCRIPTIONS,
//       payload: prescriptions,
//     };
//   };
  
  export const loadPrescriptions = (prescriptions, menuItemId) => {
    return {
      type: LOAD_PRESCRIPTIONS,
      payload: { prescriptions, menuItemId}
    };
  };

  export const setSelectedPrescription = (prescription) => {
    return {
      type: SELECT_PRESCRIPTION,
      payload: prescription,
    };
  };
  export const setLoader = (loader) => {
    return {
      type: SET_LOADER_MANAGE_MODULE,
      payload: loader,
    };
  };
  export const showInfoMessage = (message) => {
    return {
      type: SHOW_ALERT_MESSAGE,
      payload: {message, alertType: "info"},
    };
  };
  export const showSuccessMessage = (message) => {
    return {
      type: SHOW_ALERT_MESSAGE,
      payload: {message, alertType: "success"},
    };
  };
  export const showWarningMessage = (message) => {
    return {
      type: SHOW_ALERT_MESSAGE,
      payload: {message, alertType: "warning"},
    };
  };
  export const showErrorMessage = (message) => {
    return {
      type: SHOW_ALERT_MESSAGE,
      payload: { message, alertType: "error"},
    };
  };
  export const setPatient = (patient) => {
    return {
      type: LOAD_PATIENT_INFO,
      payload: patient,
    };
  };
  export const updateSelectedPrescription = (prescription) => {
    return {
      type: UPDATE_SELECTED_PRESCRIPTION,
      payload: prescription,
    };
  };
  export const removeSelectedPrescription = () => {
    return { type: REMOVE_SELECTED_PRESCRIPTION };
  };
  export const addPrescriptionAndGoToTracing = (prescription, menuItemId) => {
    return {
      type: ADD_PRESCRIPTION_GO_TRACING,
      payload: { prescription, menuItemId }
    };
  };
  
  
  
  