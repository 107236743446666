import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages from "util/IntlMessages";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import Table, {
  minimalOptions,
  defaultCol,
  dateColumn,
} from "components/Form/Table";
import DialogGeneric from "components/Form/DialogGeneric";
import FormSelectAutoComplete, { getCboValue } from "components/Form/Controls/select-autocomplete";
import { callApi,
  callApiAsync,
  getPrescriptionStateLog,
  getPrescriptionStatusList,
  postPrescriptionChangeState
} from "api/apiList";

const DlgDeliveries = ({ openDlg, requestCloseDlg, prescriptionId, statusId, allowEdit, callBack }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [stateLogList, setStateLogList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const loadData = () => {
    if (!prescriptionId) {
      setStateLogList([]);
      return;
    }

    callApi(
      () => getPrescriptionStateLog(prescriptionId),
      setStateLogList,
      fnSetLoader
    );
  };

  const defaultValues = { statusId: null };
  const validationSchema = {
    statusId: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable()
  };
  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, errors, setValue } = methods;

  useEffect(loadData, [openDlg]);
  useEffect(()=>{
    callApi(getPrescriptionStatusList, setStatusList);
  }, []);

  useEffect(()=>{
    if(!openDlg)return;
    setTimeout(() => {
      setValue("statusId", !statusId ? null : getCboValue(statusList, statusId));
    }, 130);
  }, [openDlg]);

  const onSubmit = handleSubmit(async (d)=>{
    const obj = {
      id: prescriptionId,
      statusId: !d.statusId ? null : Number(d.statusId.value)
    };

    if(obj.statusId === statusId)
      return;

    await callApiAsync(
      ()=> postPrescriptionChangeState(obj),
      ()=> {
        loadData();
        if(callBack)
          callBack();
      }
    );
  });

  const columns = [
    dateColumn("creationDate", "core.date"),
    defaultCol("prescriptionStatus", "core.state")
  ];

  return (
    <DialogGeneric
      open={openDlg}
      closeDialog={requestCloseDlg}
      titleTextId="core.states"
    >
      { allowEdit &&
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} lg={9}>
                  <FormSelectAutoComplete
                    name="statusId"
                    label={<IntlMessages id="core.state" />}
                    options={statusList}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <div className="mt-3">
                    <Tooltip title={<IntlMessages id="core.accept" />} placement="bottom-end">
                      <IconButton
                        type="button"
                        className="icon-btn"
                        color="primary"
                        onClick={onSubmit}
                      >
                        <i className="zmdi zmdi-save zmdi-hc-fw" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </div>
      }
      <Table
        className="border-dialog-card mt-2 mb-2"
        data={stateLogList}
        columns={columns}
        options={minimalOptions()}
      />
    </DialogGeneric>
  );
};

export default DlgDeliveries;
