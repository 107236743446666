import React from "react";
import * as dayjs from "dayjs";
import { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CardBox from "components/CardBox/index";

const TableComment = ({ data, setCurrentRowObj, currentImpact, readOnly }) => {
  const textYes = IntlGetText("core.yes");
  const textNo = IntlGetText("button.no");

  const datatableConfig = {
    textLabels: {
      body: {
        noMatch: "No hay resultados para la búsqueda actual",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordernar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Items por pagina:",
        displayRows: "de",
      },
      toolbar: {
        search: "Busqueda",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtro",
      },
      filter: {
        all: "Todos",
        title: "FILTRO",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas",
      },
      selectedRows: {
        text: "item(s) seleccionados",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    },
  };

  const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    search: false,
    viewColumns: false,
    textLabels: datatableConfig.textLabels,
  };
  
  const handleClick = (obj) => {
    setCurrentRowObj(
      !obj ? null : obj
    );
  };
  const getRowObj = (tableMeta) => tableMeta.tableData[tableMeta.rowIndex];

  const columns = [
    {
      name: "creationDate",
      label: IntlGetText("core.creationDate"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    {
      name: "comment",
      label: IntlGetText("alert.comment"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "contactDoctor",
      label: IntlGetText("clinicalFindingState.contactDoctor"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? textYes : textNo;
        },
      },
    },
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {         
          var rowObj = getRowObj(tableMeta);
          if (rowObj.impactId != currentImpact || readOnly) {
            return "";
          }

          return (
            <div>
              <Button
                onClick={() => handleClick(rowObj)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-edit"></i>
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="row">
      <CardBox styleName="col-12" cardStyle="p-0 border-dialog-card">
        <MUIDataTable
          className="border-dialog-card mt-4"
          data={data}
          columns={columns}
          options={options}
        />
      </CardBox>
    </div>
  );
};

export default TableComment;
