import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import { callApi, callApiAsync, getControlExamTable, saveControlExamRecord } from "api/apiList";
import { callStartImpact } from "actions/Impact";
import TableCE from "./tableCE";
import FormCE from "./formCE";

export const getNameEnumById = (id) => {
  const confirmationTypes = [
    { id: 1, name: IntlGetText("Diagnosis.clinic") },
    { id: 2, name: IntlGetText("Diagnosis.laboratory") }
  ];
  const confirmation = confirmationTypes.find((item) => item.id === id);
  return confirmation ? confirmation.name : undefined;
};

const ControlExam = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentSigned, isCIRequired } = useSelector(
    ({ impact }) => impact
  );

  const [open, setOpen] = useState(false);
  const [openCE, setOpenCE] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentRowObj, setCurrentRowObj] = useState(null);
  const [controlExamTable, setControlExamTable] = useState([]);

  const loadTable = () => {
    callApi(
      () => getControlExamTable(selectedPrescription.patient.id),
      (data) => setControlExamTable(data)
    );
  };

  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    if (isCIRequired && !consentSigned) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }
    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleOpenCRU = () => {
    setOpenCE(true);
  };
  const handleRequestCloseCRU = () => {
    setOpenCE(false);
  };
  const handleRequestCloseDel = () => {
    setOpenDeleteDialog(false);
  };

  useEffect(() => {
    loadTable();
  }, []);

  const handleCreateUpdate = async (data) => {
    await callApiAsync(() => saveControlExamRecord(data));
    loadTable();
  };

  const handleDelete = () => {
    console.log("AQUI ELIMINAMOS UN REGISTRO");
  };

  return (
    <div>
      <InfoCard
        data={{
          icon: "file-text",
          title: IntlGetText("ControlExam.ControlExam"),
          subTitle: "",
          color: "#757575"
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="ControlExam.ControlExam" />
        </DialogTitle>
        <DialogContent>
          <TableCE
            data={controlExamTable}
            handleOpenCRU={handleOpenCRU}
            handleOpenDel={() => setOpenDeleteDialog(true)}
            setCurrentRowObj={setCurrentRowObj}
            loadTable={loadTable}
          />
          <FormCE
            openCE={openCE}
            handleRequestClose={handleRequestCloseCRU}
            rowObj2={currentRowObj}
            impactId={selectedPrescription.impactId}
            handleCreateUpdate={handleCreateUpdate}
          />

          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={openDeleteDialog}
            disableBackdropClick={true}
            onClose={handleRequestCloseDel}
          >
            <DialogTitle>
              <IntlMessages id="Diagnosis.MsgDelConfirmation" />
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleRequestCloseDel} color="secondary">
                <IntlMessages id="core.cancel" />
              </Button>
              <Button onClick={handleDelete} color="primary">
                <IntlMessages id="core.accept" />
              </Button>
            </DialogActions>
          </Dialog>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ControlExam;
