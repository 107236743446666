import React from "react";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import ButtonAsync from "components/Form/Controls/ButtonAsync";

const DlgAsync = ({children, ...props}) => {
  const { open, closeDialog, onSubmitAsync,
    titleTextId,
    maxWidth="sm",
    allowEdit=true
    } = props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={maxWidth}
        disableBackdropClick={true}
        onClose={closeDialog}
      >
        <DialogTitle><IntlMessages id={titleTextId} /></DialogTitle>
        <DialogContent className="new-patient-dialog-content">
          {children}
        </DialogContent>
        <DialogActions className="modal-actions">
        {allowEdit &&
          <ButtonAsync onClick={onSubmitAsync}
            variant="contained"
            color="primary"
          >
            {<IntlMessages id="core.save" />}
          </ButtonAsync>
        }
        <Button onClick={closeDialog} color="secondary">
          {<IntlMessages id={allowEdit ? "core.cancel" : "core.close"} />}
        </Button>
      </DialogActions>
    </Dialog>
    );
};

export default DlgAsync;