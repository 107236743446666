import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import FormInput from "components/Form/Controls/input";
import React, { useState } from "react";
import IntlMessages from "util/IntlMessages";
import { callApiAsync, postConsentAddAudio } from "api/apiList";
import { useForm, FormProvider } from "react-hook-form";
import { callReloadPatientData, setConsentUrl } from "actions";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { Card, CardHeader } from "reactstrap";

const AudioForm = ({
  handleRequestClose,
  selectedPrescription,
  fnSetLoader
}) => {
  const dispatch = useDispatch();

  const defaultValues = {
    audioUri: ""
  };
  const validationSchema = {
    audioUri: yup.string().required(<IntlMessages id="core.fieldRequired" />)
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema))
  });

  const { handleSubmit, watch, setValue, errors } = methods;
  const onSubmit2 = async (d) => {
    const obj = {
      prescriptionId: selectedPrescription.id,
      urlAudio: d.audioUri
    };
    await callApiAsync(
      () => postConsentAddAudio(obj),
      (data) => {
        dispatch(setConsentUrl(data));
        dispatch(callReloadPatientData(true));
        handleRequestClose();
      },
      fnSetLoader
    );
  };
  return (
    <div>
      <Card className="shadow border-0 mt-4">
        <CardHeader>
          <Grid container spacing={1}>
            <Grid item xs={11} md={11} lg={11}>
              <FormProvider {...methods}>
                <FormInput
                  name="audioUri"
                  label={<IntlMessages id="core.ExternalUri" />}
                  margin="normal"
                  errorobj={errors}
                />
              </FormProvider>
            </Grid>
            <Grid item xs={1} md={1} lg={1} className="HContainer a-center">
              <ButtonAsync
                style={{ minWidth: "auto" }}
                onClick={handleSubmit(onSubmit2)}
                variant="contained"
                color="primary"
              >
                {<i className="zmdi zmdi-floppy"></i>}
              </ButtonAsync>
            </Grid>
          </Grid>
        </CardHeader>
      </Card>
    </div>
  );
};

export default AudioForm;
