import React, { useState } from "react";
import { Button } from "@material-ui/core";

const ButtonAsync = ({children, ...props}) => {
    const [executing, setExecuting] = useState(false);
    
    const {
        disabled,
        onClick,
        ...otherProps
    } = props;

    const onRealClick = async (event) => {
        setExecuting(true);
        try {
            await onClick();
        } finally {
            setExecuting(false);
        }
    };

    return (
    <Button
        onClick={onRealClick}
        disabled={executing || disabled}
        {...otherProps}
      >
        {children}
      </Button>
    );
};

export default ButtonAsync;