import React from "react";
import Widget from "components/Widget";

const InfoItem = ({index, icon, title, description}) => {
    return (
        <div
            key={index}
            className="media align-items-center flex-nowrap jr-pro-contact-list"
            >
            <div className="mr-3">
                <i
                className={`zmdi zmdi-${icon} jr-fs-xxl text-grey`}
                />
            </div>
            <div className="media-body">
                <span className="mb-0 text-grey jr-fs-sm">
                    {title}
                </span>
                <p className="mb-0">{description}</p>
            </div>
        </div>
    );
}; 

const ContactInfo=({ prescription })=>{
    const {email, institution, cellphone } = prescription.doctor;
    return (
        <div className="col-xl-4 col-lg-4 col-md-12 col-10">
            <Widget
                title="Información de contacto"
                styleName="jr-card-profile-sm"
                >
                <InfoItem index={0}
                    icon="email"
                    title="Email"
                    description={email.length > 0 && email[email.length - 1]}/>
                <InfoItem index={1}
                    icon="city-alt"
                    title="Institución"
                    description={institution}/>
                <InfoItem index={2}
                    icon="phone"
                    title="Teléfono"
                    description={cellphone.length > 0 && cellphone[cellphone.length - 1]}/>
            </Widget>
        </div>
    );
};

export default ContactInfo;