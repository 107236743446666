import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Grid } from "@material-ui/core";
import FormSelectAutoComplete, { getCboValue } from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import DialogAsync from "components/Form/DialogAsync";
import RegexConstants from "constants/regexConstants";
import { callApi, callApiAsync, getPatientInfoV2, postPatientAdd } from "api/apiList";

const AddPrescription = ({callBack, openDlg, closeDialog, patientId, allowEdit=true}) =>{ 
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  
  const defaultValues = {
    document:"",
    documentTypeId:null,
    name:"",
    lastName:"",
    email:"",
    cityId:null,
    phone:"",
    cellphone:""
  };

  const [patientInfo, setPatientInfo] = useState({
    ...defaultValues,
    documentTypes: [],
    cities: [],
  });

  const validationSchema = {
    documentTypeId: yup.object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable(),
    document: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    email: yup.string().matches(RegexConstants.email, {message:IntlGetText("yup.emailInvalid"),excludeEmptyString:true})
  };

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, errors } = methods;

  const loadData=(data)=>{
    setValue("document", !data || !data.document ? "" : data.document);
    setValue("name", !data || !data.name ? "" : data.name);
    setValue("lastName", !data || !data.lastName ? "" : data.lastName);
    setValue("email", !data || !data.email ? "" : data.email);
    setValue("phone", !data || !data.phone ? "" : data.phone);
    setValue("cellphone", !data || !data.cellphone ? "" : data.cellphone);
    setValue("documentTypeId", !data ? null : getCboValue(data.documentTypes, data.documentTypeId));
    setValue("cityId", !data ? null : getCboValue(data.cities, data.cityId));
  };

  useEffect(() => {
    if(!openDlg) return;

    callApi(
      ()=>getPatientInfoV2(patientId),
      (data)=>{
        setPatientInfo(data);
        loadData(data);
      },
      fnSetLoader
    );
  }, [openDlg]);


  const onSubmitAsync = handleSubmit(async (d)=>{
    const patient = {
      id: patientInfo.id ? patientInfo.id : 0,
      name:d.name,
      lastName:d.lastName,
      documentTypeId:Number(d.documentTypeId.value),
      document:d.document,
      email:d.email,
      cityId: d.cityId && d.cityId.value ? Number(d.cityId.value) : null,
      phone:d.phone,
      cellphone:d.cellphone
    };

    await callApiAsync(
      ()=>postPatientAdd(patient),
      ()=>{
        if(callBack)
          callBack();
        closeDialog();
      }
    );
  });

  const disabled = !allowEdit;

  return (
    <div>
      <DialogAsync
        open={openDlg}
        closeDialog={closeDialog}
        onSubmitAsync={onSubmitAsync}
        titleTextId={patientId === 0 ? "core.newPatient" : "core.EditPatient"}
        allowEdit={allowEdit}
      >
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="documentTypeId"
                  label={<IntlMessages id="core.documentType" />}
                  options={patientInfo.documentTypes}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="document"
                  label={<IntlMessages id="core.documentShort" />}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="name"
                  label={<IntlMessages id="core.name" />}
                  margin="normal"
                  disabled={disabled}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="lastName"
                  label={<IntlMessages id="core.lastName" />}
                  margin="normal"
                  disabled={disabled}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="email"
                  label={<IntlMessages id="core.email" />}
                  margin="normal"
                  disabled={disabled}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="cityId"
                  label={<IntlMessages id="core.city" />}
                  options={patientInfo.cities}
                  margin="normal"
                  disabled={disabled}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="phone"
                  label={<IntlMessages id="core.phone" />}
                  margin="normal"
                  disabled={disabled}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="cellphone"
                  label={<IntlMessages id="core.cellphone" />}
                  margin="normal"
                  disabled={disabled}
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>            
      </DialogAsync>
    </div>
  );
};

export default AddPrescription;