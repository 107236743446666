import React, { useState, useEffect} from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import FormInput from "components/Form/Controls/input";
import {
  callApi,
  postAddUpdateFeedback
} from "api/apiList";

const Feedback = ({eventTrainingInfo, refreshEventTrainingInfo}) => {
  const [open, setOpen] = useState(false);
  const msgFeedbackValidation = IntlGetText("eventTraining.feedbackValidation")

  const handleClickOpen = () => {
    if(!eventTrainingInfo || dayjs(eventTrainingInfo.trainingDate) > dayjs()){
      NotificationManager.warning(msgFeedbackValidation);
      return;
    }
    setOpen(true)
  }
  const handleRequestClose = () => setOpen(false)
  
  const defaultValues = { comment: "" };
  const validationSchema = { 
    comment: yup.string().required(<IntlMessages id="core.fieldRequired" />)
  };

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, errors } = methods;

  useEffect(()=>{
    if(!open) return
    setTimeout(()=>{
      setValue('comment',!eventTrainingInfo || !eventTrainingInfo.comment ? "" : eventTrainingInfo.comment)
    },150)      
  },[open])

  const onSubmit = (d, e) => {
    var obj = {
      eventTrainingId: !eventTrainingInfo ? 0 : eventTrainingInfo.id,
      comment: d.comment
    };
    callApi(
      ()=>postAddUpdateFeedback(obj),
      ()=>{        
        refreshEventTrainingInfo()
        handleRequestClose()
      })
  };
  
  const disabled = false;

  return (
    <div>
      <InfoCard
        data={{
          icon: "comment-list",
          title: IntlGetText("core.feedback"),
          color: !eventTrainingInfo || !eventTrainingInfo.comment
            ? "#757575"
            : "#20c997",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="core.feedback" />
        </DialogTitle>
        <DialogContent>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <FormProvider {...methods}>
              <form className="row" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormInput
                      name="comment"
                      label={<IntlMessages id="core.feedback" />}
                      margin="normal"
                      multiline
                      rows={4}
                      disabled={disabled}
                      required={true}
                      errorobj={errors}
                      />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
            { !disabled &&
              <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
                <Button onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary">
                  {<IntlMessages id="core.save" />}
                </Button>
              </div>
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Feedback;
