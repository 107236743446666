import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import CustomTable from "./table";
import FormCreateUpdate from "./form";
import { callApi, putExternalReport, patchExternalReport } from "api/apiList";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";

const ExternalReports = () => {
    const [currentRowObj, setCurrentRowObj] = useState(null);
    const [openCRU, setOpenCRU] = useState(false);
    const [openDlgDelete, setOpenDlgDelete] = useState(false);
    const dispatch = useDispatch();
    const fnSetLoader = (data) => {
        dispatch(setLoader(data))
        setOpenCRU(false)
    };
    const [data, setData] = useState();

    const handleRequestCloseCRU = () => {
        setOpenCRU(false);
        setCurrentRowObj(null);
    }
    const handleCreateUpdate = async (report) => {
        if (report.id < 1)
        {
            callApi(
                ()=>putExternalReport(report),
                (data)=> setData(data),
                fnSetLoader);
        }
        else
        {
            callApi(
                ()=>patchExternalReport(report),
                (data)=> setData(data),
                fnSetLoader);
        }
    };

    return (
        <>
            <div className="col-lg-12 col-md-12 col-xs-12 text-right mt-1">
                <IconButton
                    type="button"
                    className="icon-btn"
                    color="primary"
                    onClick={()=>setOpenCRU(true)}
                >
                    <i className="zmdi zmdi-plus-square"/>
                </IconButton>
            </div>
            <CustomTable 
                handleOpenCRU={() => setOpenCRU(true)}
                data={data}
                setData={setData}
                setCurrentRowObj={setCurrentRowObj}
            />
            <FormCreateUpdate 
                obj={currentRowObj}
                openCRU={openCRU}
                handleRequestClose={handleRequestCloseCRU}
                handleCreateUpdate={handleCreateUpdate}
            />
        </>
    );
};

export default ExternalReports;