import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "actions/Base";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import AppLocale from "lngProvider";
import { Button, Grid } from "@material-ui/core";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import CardTotal from "components/CardTotal";
import CustomChart from "components/CustomChart";
import {
  totalizationChart,
  totalizationByDateChart,
  causalNotEffectiveChart,
  productLineChart,
  causalNotEffectiveMoneyChart,
  deliveryModeChart,
  marketchainChart,
  detailEffectiveChart,
  stateTrendChart,
  stateTrendBarChart
} from "./optionsCharts";
import { callApi, getDashboardFilters, postDashboard } from "api/apiList";


const getProp = (obj, prop) => {
  if (!obj) return "";
  return obj[prop];
};

const getPropArray = (obj, prop) => {
  if (!obj) return [];
  return obj[prop];
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const { locale } = useSelector(({ settings }) => settings);
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const currentAppLocale = AppLocale[locale.locale];
  const currentLocale = currentAppLocale.locale;

  const [olfList, setOlfList] = useState([]);
  const [insuranceList, setInsuranceList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [executiveActionList, setExecutiveActionList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [totalInfo, setTotalInfo] = useState(null);

  const filterDateType = [
    {id:1,name:IntlGetText('core.impact')},
    {id:2,name:IntlGetText('medicineDelivery.cardTitle')}
  ];

  const chartCausalNotEffectiveTitle = IntlGetText("dashboard.charTitle.causalNotEffective");
  const chartCausalNotEffectiveMoneyTitle = IntlGetText("dashboard.charTitle.causalNotEffectiveMoney");
  const chartRecoverableTitle = IntlGetText("dashboard.charTitle.recoverable");
  const chartRecoverableMoneyTitle = IntlGetText("dashboard.charTitle.recoverableMoney");
  
  const defaultValues = {
    startDate: null,
    endDate: null,
    olfId: null,
    insuranceId: null,
    stateId: null,
    userId: null,
    executiveActionId: null,
    productId: null,
  };
  const [filterTable, setFilterTable] = useState(defaultValues);

  const validationSchema = {};

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, errors, trigger, getValues, watch } = methods;

  useEffect(() => {
    callApi(getDashboardFilters, (filters) => {
      setOlfList(filters.marketchains);
      setInsuranceList(filters.insurances);
      setStateList(filters.states);
      setUserList(filters.users);
      setExecutiveActionList(filters.executiveActions);
      setProductList(filters.products);
      onFilter();
    });
  }, []);

  const onFilter = handleSubmit((d) => {
    var obj = {
      filterDateTypeIds: !d.filterDateTypeId ? [] : d.filterDateTypeId.map((x) => Number(x.value)),
      startDate: !d.startDate
        ? null
        : dayjs(d.startDate).format("YYYY-MM-DDT00:00:00"),
      endDate: !d.endDate
        ? null
        : dayjs(d.endDate).format("YYYY-MM-DDT00:00:00"),
      marketchainIds: !d.olfId ? [] : d.olfId.map((x) => Number(x.value)),
      insuranceIds: !d.insuranceId
        ? []
        : d.insuranceId.map((x) => Number(x.value)),
      stateIds: !d.stateId ? [] : d.stateId.map((x) => Number(x.value)),
      userIds: !d.userId ? [] : d.userId.map((x) => x.value),
      executiveActionIds: !d.executiveActionId
        ? []
        : d.executiveActionId.map((x) => Number(x.value)),
      productIds: !d.productId ? [] : d.productId.map((x) => Number(x.value)),
    };

    callApi(() => postDashboard(obj), setTotalInfo, fnSetLoader);
  });

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
      <FormProvider {...methods}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={4}>
            <FormSelectAutoComplete
              name="filterDateTypeId"
              label={<IntlMessages id="core.date" />}
              options={filterDateType}
              margin="normal"
              isMulti
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormDatePicker
              name="startDate"
              label={<IntlMessages id="core.startDate" />}
              format="YYYY-MM-DD"
              margin="normal"
              required={true}
              errorobj={errors}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormDatePicker
              name="endDate"
              label={<IntlMessages id="core.endDate" />}
              format="YYYY-MM-DD"
              margin="normal"
              errorobj={errors}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormSelectAutoComplete
              name="olfId"
              label={<IntlMessages id="radicadoOLF.cardSubTitle" />}
              options={olfList}
              margin="normal"
              isMulti
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormSelectAutoComplete
              name="insuranceId"
              label={<IntlMessages id="core.insurance" />}
              options={insuranceList}
              margin="normal"
              isMulti
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormSelectAutoComplete
              name="stateId"
              label={<IntlMessages id="core.state" />}
              options={stateList}
              margin="normal"
              isMulti
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormSelectAutoComplete
              name="userId"
              label={<IntlMessages id="core.executive" />}
              options={userList}
              margin="normal"
              isMulti
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormSelectAutoComplete
              name="executiveActionId"
              label={<IntlMessages id="core.executiveAction" />}
              options={executiveActionList}
              margin="normal"
              isMulti
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormSelectAutoComplete
              name="productId"
              label={<IntlMessages id="core.product" />}
              options={productList}
              margin="normal"
              isMulti
            />
          </Grid>
          <Grid container justify="center">
          <Button
              onClick={onFilter}
              className="mt-2"
              variant="contained"
              color="primary"
            >
              <IntlMessages id="core.filter" />
            </Button>
          </Grid>
            

        </Grid>
      </FormProvider>

      <div className="row mt-3">
        <div className="col-xl-6 col-lg-6 col-12">
        <CardTotal
          title={getProp(totalInfo, "totalContactCalls")}
          subtitleId="dashboard.totalContactCalls"
        />
        </div>
        <div className="col-xl-6 col-lg-6 col-12">
        <CardTotal
          title={getProp(totalInfo, "totalContactsLoadData")}
          subtitleId="dashboard.totalContactsLoadData"
        />
        </div>
      </div>

      <div className="row">
          <div className="col-xl-3 col-lg-3 col-12">
          <CardTotal
          title={getProp(totalInfo, "total")}
          subtitleId="dashboard.totalization"
        />
          </div>
          <div className="col-xl-3 col-lg-3 col-12">
          <CardTotal
          title={getProp(totalInfo, "effective")}
          subtitleId="dashboard.effective"
        />
          </div>
          <div className="col-xl-3 col-lg-3 col-12">
          <CardTotal
          title={getProp(totalInfo, "ineffectiveDelivery")}
          subtitleId="dashboard.notEffective"
        />
          </div>
          <div className="col-xl-3 col-lg-3 col-12">
          <CardTotal
          title={getProp(totalInfo, "inProcess")}
          subtitleId="dashboard.process"
        />
          </div>
      </div>
      
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart options={totalizationChart(currentLocale, getPropArray(totalInfo, "chartTotalizationData"))} />
        </div>
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart options={totalizationByDateChart(currentLocale, getPropArray(totalInfo, "chartTotalizationByDateData"))} />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart options={detailEffectiveChart(currentLocale, getPropArray(totalInfo, "chartDetailEffective"))} />
        </div>
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart options={deliveryModeChart(currentLocale, getPropArray(totalInfo, "deliveryModeData"))} />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart options={causalNotEffectiveChart(currentLocale, getPropArray(totalInfo, "chartRecoverableData"), chartRecoverableTitle)} />
        </div>
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart options={causalNotEffectiveMoneyChart(currentLocale, getPropArray(totalInfo, "chartRecoverableData"), chartRecoverableMoneyTitle)} />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-xl-6 col-lg-6 col-12">
        <CustomChart options={causalNotEffectiveChart(currentLocale, getPropArray(totalInfo, "causalNotEffectiveData"), chartCausalNotEffectiveTitle)} />
        </div>
        <div className="col-xl-6 col-lg-6 col-12">
        <CustomChart options={causalNotEffectiveMoneyChart(currentLocale, getPropArray(totalInfo, "causalNotEffectiveData"), chartCausalNotEffectiveMoneyTitle)} />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <CustomChart options={productLineChart(currentLocale, getPropArray(totalInfo, "productLineData"))} />
        </div>        
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <CustomChart options={marketchainChart(currentLocale, getPropArray(totalInfo, "marketchainData"))} />
        </div>        
      </div>

      <div className="row mt-3">
        <div className="col-12">
        </div>        
      </div>

      {/* <Grid item xs={12} md={6} lg={6}>
        <CustomChart options={stateTrendChart()} />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <CustomChart options={stateTrendBarChart()} />
      </Grid> */}
    </div>
  );
};

export default Dashboard;
