import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages from "util/IntlMessages";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import FormDatePicker from "components/Form/Controls/datepicker";
const FormCreateUpdate = ({ openCRU, handleRequestClose, obj, handleCreateUpdate}) => {  

  const defaultValues = {
    date: new Date(),
    description: "",
    link: ""
  };
  const validationSchema = { 
    description: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    link: yup.string().required(<IntlMessages id="core.fieldRequired" />)
  };

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, reset, errors, getValues } = methods;

  const loadData = () => {
    setValue("date", !obj || !obj.date ? "" : obj.date);
    setValue("description", !obj || !obj.description ? "" : obj.description);
    setValue("link", !obj || !obj.link ? "" : obj.link);
  };

  useEffect(()=>{
    if(openCRU){
      setTimeout(loadData,150);
    }
  },[openCRU]);


  const onSubmit = async (d, e) => {
    var item = {
      id: !obj ? 0 : obj.id,
      date: d.date == "" ? new Date() : d.date,
      description: d.description,
      link: d.link
    };
    await handleCreateUpdate(item);
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!obj ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <FormDatePicker
                          name="date"
                          label={<IntlMessages id="core.date" />}
                          format="DD-MM-YYYY"
                          margin="normal"
                          errorobj={errors}
                      />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <FormInput
                    name="description"
                    label={<IntlMessages id="core.description" />}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <FormInput
                    name="link"
                    label={<IntlMessages id="core.url" />}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                    />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
            <ButtonAsync onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
            >
              {<IntlMessages id="core.save" />}
            </ButtonAsync>
          </div>
        </div>  
      </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>  
  );
};
export default FormCreateUpdate;
