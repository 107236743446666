import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  callApi,
  getCitiesByStateProvinceId,
  getAllStateProvince,
  callActionApi,
  postAddPharmacy,
} from "api/apiList";
import { NotificationManager } from "react-notifications";

const FormAddNewPharmacy = ({
  openCRU,
  handleRequestClose,
  marketChainIdValue,
  setVisitTargetList,
  visitTargetList,
  setValueNamePharmacy,
}) => {
  const [cityList, setCityList] = useState([]);
  const [stateProvinceList, setStateProvinceList] = useState([]);
  const defaultValues = {
    stateProvinceId: "",
    cityId: "",
    name: "",
  };

  const validationSchema = yup.object().shape({
    stateProvinceId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    cityId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    name: yup
      .string()
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
  });
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, watch, setValue, errors } = methods;
  const stateProvinceIdValue = watch("stateProvinceId");
  const cityIdValue = watch("cityId");
  const cboHandleChange = (name) => (event) => {
    setValue(name, event);
  };

  useEffect(() => {
    callApi(getAllStateProvince, setStateProvinceList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue("cityId", null);
    if (!stateProvinceIdValue || !stateProvinceIdValue.value) {
      setCityList([]);
      return;
    }
    callApi(
      () => getCitiesByStateProvinceId(stateProvinceIdValue.value),
      setCityList
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProvinceIdValue]);

  const onSubmit = async (d, e) => {
    const obj = {
      name: d.name,
      marketChainId: marketChainIdValue.value,
      cityId: Number(d.cityId.value),
    };
    await callActionApi(
      () => postAddPharmacy(obj),
      (data) => {
        setVisitTargetList([...visitTargetList, data]);
        setValueNamePharmacy(data);
        handleRequestClose();
      },
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={"insuranceAuthorization.newPharmacy"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="stateProvinceId"
                    label={<IntlMessages id="core.stateProvince" />}
                    options={stateProvinceList}
                    value={stateProvinceIdValue}
                    onChange={cboHandleChange("stateProvinceId")}
                    margin="normal"
                    disabled={false}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="cityId"
                    label={<IntlMessages id="core.city" />}
                    options={cityList}
                    value={cityIdValue}
                    onChange={cboHandleChange("cityId")}
                    margin="normal"
                    disabled={false}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="name"
                    label={
                      <IntlMessages id="insuranceAuthorization.newPharmacyName" />
                    }
                    margin="normal"
                    disabled={false}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
            <ButtonAsync
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
            >
              {<IntlMessages id="core.save" />}
            </ButtonAsync>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormAddNewPharmacy;
