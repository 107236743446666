import React from "react";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CardBox from "components/CardBox/index";

const TableAM = ({ data, handleOpenCRU, handleOpenDel, setCurrentRowObj }) => {
  const datatableConfig = {
    textLabels: {
      body: {
        noMatch: "No hay resultados para la búsqueda actual",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordernar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Items por pagina:",
        displayRows: "de",
      },
      toolbar: {
        search: "Busqueda",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtro",
      },
      filter: {
        all: "Todos",
        title: "FILTRO",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas",
      },
      selectedRows: {
        text: "item(s) seleccionados",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    },
  };

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: datatableConfig.textLabels,
  };

  const handleClick = (tableMeta) => {
    handleOpenCRU();
    setCurrentRowObj(
      !tableMeta ? null : tableMeta.tableData[tableMeta.rowIndex]
    );
  };
  const handleClickDelete = (tableMeta) => {
    handleOpenDel();
    setCurrentRowObj(tableMeta.tableData[tableMeta.rowIndex]);
  };

  const columns = [
    {
      name: "radicadoInsuranceCode",
      label: IntlGetText("insuranceAuthorization.radicadoInsuranceCode"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = tableMeta.tableData[tableMeta.rowIndex];
          return rowObj.radicadoInsuranceCode 
            ? rowObj.radicadoInsuranceCode 
            : dayjs(rowObj.radicadoInsuranceDate).format("YYYY/MM/DD");
        }
      }
    },
    {
      name: "code",
      label: IntlGetText("insuranceAuthorization.codeNumber"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "marketChain",
      label: IntlGetText("insuranceAuthorization.marketChain"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "authorizationDate",
      label: IntlGetText("insuranceAuthorization.authDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    {
      name: "medicineAmount",
      label: IntlGetText("insuranceAuthorization.medicineAmountDelivery"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "deliveries",
      label: IntlGetText("insuranceAuthorization.deliveries"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = tableMeta.tableData[tableMeta.rowIndex];
          return rowObj.deliveryNumber+"/"+rowObj.scheduledDeliveries;
        }
      },
    },
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = tableMeta.tableData[tableMeta.rowIndex];
          if (rowObj.hasRadicacionesOLF) {
            return (
              <div>
                <Button
                  onClick={() => handleClick(tableMeta)}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className="zmdi zmdi-eye"></i>
                </Button>
              </div>
            );
          }
          return (
            <div>
              <Button
                onClick={() => handleClick(tableMeta)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-edit"></i>
              </Button>
              <Button
                onClick={() => handleClickDelete(tableMeta)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-delete"></i>
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="row">
      <CardBox styleName="col-12" cardStyle="p-0 border-dialog-card">
        <MUIDataTable
          className="border-dialog-card"
          data={data}
          columns={columns}
          options={options}
        />
      </CardBox>
      <div className="col-lg-12 col-md-12 col-xs-12 mt-4 text-center">
        <Button
          onClick={() => handleClick(null)}
          variant="contained"
          color="primary"
        >
          {<IntlMessages id="core.add" />}
        </Button>
      </div>
    </div>
  );
};

export default TableAM;
