import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import IntlMessages from "util/IntlMessages";
import CardFileUpload from "components/Form/CardFileUpload";
import { postControlExamFile } from "api/apiList";

const MediaModal = ({ rowObj, open, handleRequestClose, loadTable }) => {
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id={"core.multimedia"} />
        </DialogTitle>
        <DialogContent>
          <div>
            <CardFileUpload
              file={rowObj?.filePath}
              setFile={() => {
                if (loadTable) {
                  loadTable();
                }
                handleRequestClose();
              }}
              actionApi={postControlExamFile}
              actionApiInfo={{ id: rowObj?.id }}
              disabled={false}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MediaModal;
