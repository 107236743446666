import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  callApiAsync,
  postAdverseEventReject
} from "api/apiList";
import { QueryAdverseEventStateEnum, QueryAdverseEventTypeEnum } from "constants/Enums";

const DialogReject = ({ open, closeDialog, callBack, adverseEvent }) => {
  const defaultValues = { description: "" };
  const validationSchema = {
    description: yup.string().required(<IntlMessages id="core.fieldRequired" />)
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, errors } = methods;

  const onSubmit = async (d, e) => {
    var obj = {
      adverseEventId: adverseEvent.id,
      type: QueryAdverseEventTypeEnum.pharmacovigilance,
      state: QueryAdverseEventStateEnum.neww,
      description: d.description
    };
    await callApiAsync(
      ()=> postAdverseEventReject(obj),
      ()=> {
        callBack();
        closeDialog();
      }
    );
  };  

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      disableBackdropClick={true}
      onClose={closeDialog}
    >
      <DialogTitle><IntlMessages id="dlgReject.title" /></DialogTitle>
      <DialogContent className="new-patient-dialog-content">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid item xs={12} md={12} lg={12}>
              <Grid item xs={12} md={12} lg={12}>
                <FormInput
                  label={<IntlMessages id="dlgReject.description" />}
                  name="description"
                  multiline
                  rows={4}
                  variant="outlined"
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="secondary">
          {<IntlMessages id="core.cancel" />}
        </Button>
        <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
          {<IntlMessages id="core.save" />}
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  );
};

export default DialogReject;