import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { Grid } from "@material-ui/core";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { updateSelectedPrescription } from "actions/Management";
import FormSelectAutoComplete, {
  getCboValue,
} from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import DialogGeneric from "components/Form/DialogGeneric";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  callApiAsync,
  callApi,
  postPatientAssignState,
  getPatientStateList,
} from "api/apiList";
import BarriersModal from "./barriersModal";
import { ShardKeyEnum } from "constants/Enums";
import { getUserInfo, isDirectTracing } from "app/routes/psp/services/userService";

const FormPatientState = ({
  openDlg,
  closeDialog,
  currentRowObj,
  currentImpactId,
  callBack,
  allowEdit = true,
  lastState,
}) => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const [patientStateParentList, setPatientStateParentList] = useState([]);
  const [patientStateList, setPatientStateList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [openBarrierModal, setOpenBarrierModal] = useState(false);
  const [lastStateParent, setLastStateParent] = useState(null);

  const msgSuccess = IntlGetText("core.SuccessSave");

  const isDirectTracingConfig = isDirectTracing();

  const defaultValues = {
    stateParent: null,
    state: null,
    comment: "",
  };

  useEffect(() => {
    callApi(getPatientStateList, (data) => {
      setPatientStateParentList(data.parentStates);
      setPatientStateList(data.states);
    });
  }, []);

  useEffect(() => {
    if (lastState == null) return;
    setLastStateParent(getParentIdById(lastState));
  }, [lastState, patientStateList]);

  const getParentIdById = (id) => {
    if (id == null) return;
    const state = patientStateList.find((state) => state.id === id);
    return state ? state.parentId : null;
  };

  const validationSchema = {
    state: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, errors } = methods;

  const stateParentValue = watch("stateParent");

  useEffect(() => {
    if (isLoaded) setValue("state", null);
  }, [stateParentValue]);

  useEffect(() => {
    if (!openDlg) {
      setIsLoaded(false);
      return;
    }

    setTimeout(() => {
      setValue(
        "comment",
        !currentRowObj || !currentRowObj.comment ? "" : currentRowObj.comment
      );

      const hasState = currentRowObj && currentRowObj.state;
      if (hasState) {
        const state = patientStateList.find(
          (x) => x.id === currentRowObj.state
        );
        setValue(
          "stateParent",
          !hasState ? null : getCboValue(patientStateParentList, state.parentId)
        );
        setValue(
          "state",
          !hasState ? null : getCboValue(patientStateList, state.id)
        );
        setIsLoaded(true);
      } else {
        setIsLoaded(true);
      }
    }, 200);
  }, [openDlg, currentRowObj]);

  const isBehringer = getUserInfo().shardKey === ShardKeyEnum.behringer;
  // const isCelltrion = getUserInfo().shardKey === ShardKeyEnum.celltrion;\

  const onSubmit = handleSubmit(async (d) => {
    if (isDirectTracingConfig || isBehringer) {
      handleOpenBarrierModal();
    } else {
      onSubmitCallBack();
    }
  });

  const onSubmitCallBack = handleSubmit(async (d) => {
    var obj = {
      patientStatusId: !currentRowObj ? 0 : currentRowObj.patientStatusId,
      impactId: currentImpactId,
      state: Number(d.state.value),
      comment: d.comment,
    };

    await callApiAsync(
      () => postPatientAssignState(obj),
      () => {
        NotificationManager.success(msgSuccess);
        const prescription = {
          ...selectedPrescription,
          patient: {
            ...selectedPrescription.patient,
            state: obj.state,
          },
        };
        dispatch(updateSelectedPrescription(prescription));

        if (callBack) callBack();
        closeDialog();
      }
    );
  });

  const getPatientStates = (parentId) => {
    if (!parentId) return [];

    return patientStateList.filter((x) => x.parentId === parentId.value);
  };
  const handleOpenBarrierModal = () => {
    setOpenBarrierModal(true);
  };
  const handleCloseBarrierModal = () => {
    setOpenBarrierModal(false);
  };

  return (
    <DialogGeneric
      open={openDlg}
      closeDialog={closeDialog}
      titleTextId={"jaasModule.assignState"}
    >
      <div className="col-xl-12 col-lg-12 col-md-12 col-12">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <FormSelectAutoComplete
                  name="stateParent"
                  label={<IntlMessages id="core.patientState" />}
                  options={patientStateParentList}
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormSelectAutoComplete
                  name="state"
                  label={<IntlMessages id="core.subState" />}
                  options={getPatientStates(stateParentValue)}
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormInput
                  name="comment"
                  label={<IntlMessages id="core.comment" />}
                  multiline
                  rows={2}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4 mb-4">
          <ButtonAsync onClick={onSubmit} variant="contained" color="primary">
            {<IntlMessages id="core.save" />}
          </ButtonAsync>
        </div>
      </div>
      <BarriersModal
        openDlg={openBarrierModal}
        closeDialog={handleCloseBarrierModal}
        currentRowObj={currentRowObj}
        currentImpactId={currentImpactId}
        callBack={onSubmitCallBack}
        lastState={lastStateParent ? lastStateParent : null}
        newState={
          stateParentValue && stateParentValue.value
            ? stateParentValue.value
            : null
        }
      />
    </DialogGeneric>
  );
};

export default FormPatientState;
