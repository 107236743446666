import React, { useState } from "react";
import InfoCard from "components/InfoCard/index";
import { IntlGetText } from "util/IntlMessages";
import AssistanceForm from "../modalForm/assistanceForm";

const Assistance = (props) => {
  const [openDlgPatient, setOpenDlgPatient] = useState(false);
  const { impactInfo } = props;
  return (
    <>
      <InfoCard
        data={{
          icon: "file-text",
          title: IntlGetText("institution.assistance"),
          color: !impactInfo || !impactInfo.startDate
              ? "#757575"
              : "#20c997"
          //color: "#60c1d7",
        }}
        onClickBox={() => setOpenDlgPatient(true)}
      />

      <AssistanceForm
        openDlg={openDlgPatient}
        setOpenDlg={setOpenDlgPatient}
        closeDialog={() => setOpenDlgPatient(false)}
        {...props}
      />
    </>
  );
};

export default Assistance;
