import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Widget from "components/Widget";
import TextField from "@material-ui/core/TextField";
import InfoCard from "components/InfoCard/index";
import ConsentDialog from "./ConsentDialog";
import FormulaDialog from "./FormulaDialog";
import ClinicalHistory from "./ClinicalHistory";

import labels from "app/routes/psp/manage/data/labels";
import CustomScrollbars from "util/CustomScrollbars";
import { DateTimePicker } from "@material-ui/pickers";

class MailDetail extends React.Component {
  state = {
    anchorEl: undefined,
    open: false,
    showDetail: false,
    selectedDate: new Date(),
  };

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleClickCIBox = () => {

  };

  handleClickFMBox = () => {

  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  render() {
    const {
      mail,
      onStartSelect,
      onImportantSelect,
      onProcessStateChange,
      onSavePatientContact,
      onGoBack,
      width,
    } = this.props;
    const { selectedDate } = this.state;
    const options = ["Reply", "Forward", "Print"];

    // Nuevo paciente
    if (mail.folder === 0 || mail.folder === 1 || mail.folder === 2) {
      return (
        <div className="module-detail mail-detail">
          <CustomScrollbars
            className="module-list-scroll scrollbar"
            style={{
              height:
                width >= 1200 ? "calc(100vh - 309px)" : "calc(100vh - 288px)",
            }}
          >
            <div className="mail-header">
              <div className="mail-header-content col pl-0">
                <div className="subject">
                  {mail.hasPatient &&
                    "Paciente: " +
                      mail.from.name +
                      " " +
                      mail.from.lastname +
                      " | "}
                  {mail.from.doctorName}
                </div>

                <div className="labels">
                  {labels.map((label, index) => {
                    return (
                      mail.labels.includes(label.id) && (
                        <div
                          key={index}
                          className={`badge text-uppercase text-white bg-${label.color}`}
                        >
                          {label.title}
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
              {/* Medico autoriza */}
              {mail.states.length === 0 && (
                <div className="mail-header-actions">
                  ¿Médico Autoriza?
                  <Button
                    onClick={() => {
                      onProcessStateChange(7);
                    }}
                    variant="contained"
                    className="ml-3 jr-btn bg-green text-white"
                  >
                    <i className="zmdi zmdi-thumb-up zmdi-hc-fw" />
                  </Button>
                  <Button
                    variant="contained"
                    className="jr-btn bg-red text-white"
                  >
                    <i className="zmdi zmdi-thumb-down zmdi-hc-fw" />
                  </Button>
                </div>
              )}
              {/* Paciente autoriza */}
              {mail.labels.includes(7) &&
                !mail.states.includes(8) &&
                mail.hasPatient && (
                  <div className="mail-header-actions">
                    ¿Paciente Autoriza?
                    <Button
                      onClick={() => {
                        onProcessStateChange(7);
                        onProcessStateChange(8);
                      }}
                      variant="contained"
                      className="ml-3 jr-btn bg-green text-white"
                    >
                      <i className="zmdi zmdi-thumb-up zmdi-hc-fw" />
                    </Button>
                    <Button
                      variant="contained"
                      className="jr-btn bg-red text-white"
                    >
                      <i className="zmdi zmdi-thumb-down zmdi-hc-fw" />
                    </Button>
                  </div>
                )}
              {/* Finalizar primer impacto */}
              {(mail.labels.includes(0) ||
                mail.labels.includes(1) ||
                mail.labels.includes(3) ||
                mail.labels.includes(9) ||
                mail.labels.includes(11)) && (
                <div className="mail-header-actions">
                  {mail.labels.includes(0)
                    ? "¿Finalizar visita?"
                    : "¿Tramite exitoso?"}
                  <Button
                    onClick={() => {
                      const currentState = mail.labels.includes(0)
                        ? 0
                        : mail.labels.includes(1)
                        ? 1
                        : mail.labels.includes(3)
                        ? 3
                        : mail.labels.includes(9)
                        ? 9
                        : mail.labels.includes(11)
                        ? 11
                        : null;
                      const nextState = mail.labels.includes(0)
                        ? 1
                        : mail.labels.includes(1)
                        ? 3
                        : mail.labels.includes(3)
                        ? 9
                        : mail.labels.includes(9)
                        ? 11
                        : mail.labels.includes(11)
                        ? 17
                        : null;
                      let patient = {};
                      if (mail.labels.includes(0) || mail.labels.includes(11)) {
                        patient = {
                          folder: mail.labels.includes(0) ? 1 : 2,
                        };
                      }
                      onProcessStateChange(currentState);
                      onProcessStateChange(nextState);
                      if (patient.folder) {
                        onSavePatientContact(patient);
                      }
                      onGoBack();
                    }}
                    variant="contained"
                    className="ml-3 jr-btn bg-green text-white"
                  >
                    <i className="zmdi zmdi-thumb-up zmdi-hc-fw" />
                  </Button>
                </div>
              )}
            </div>
            <hr />

            {!mail.states.includes(0) && (
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 col-10">
                  <Widget
                    title="Información de contacto"
                    styleName="jr-card-profile-sm"
                  >
                    {mail.from.contactList.map((data, index) => (
                      <div
                        key={index}
                        className="media align-items-center flex-nowrap jr-pro-contact-list"
                      >
                        <div className="mr-3">
                          <i
                            className={`zmdi zmdi-${data.icon} jr-fs-xxl text-grey`}
                          />
                        </div>
                        <div className="media-body">
                          <span className="mb-0 text-grey jr-fs-sm">
                            {data.title}
                          </span>
                          <p className="mb-0">{data.desc}</p>
                        </div>
                      </div>
                    ))}
                  </Widget>
                </div>
                {mail.states.includes(7) && (
                  <div className="col-xl-8 col-lg-8 col-md-12 col-12">
                    <Widget
                      title="Datos de contacto paciente"
                      styleName="jr-card-profile-sm"
                    >
                      <form className="row" noValidate autoComplete="off">
                        <div className="col-md-6 col-12">
                          <TextField
                            required
                            id="name"
                            label="Nombres"
                            margin="normal"
                            fullWidth
                            value={mail.hasPatient ? mail.from.name : undefined}
                          />
                        </div>
                        <div className="col-md-6 col-12">
                          <TextField
                            required
                            id="lastname"
                            label="Apellidos"
                            margin="normal"
                            fullWidth
                            value={
                              mail.hasPatient ? mail.from.lastname : undefined
                            }
                            onChange={(evt) => {}}
                          />
                        </div>
                        <div className="col-md-6 col-12">
                          <TextField
                            required
                            id="city"
                            label="Teléfono"
                            margin="normal"
                            fullWidth
                          />
                        </div>
                        <div className="col-md-6 col-12">
                          <TextField
                            id="age"
                            label="Celular"
                            margin="normal"
                            fullWidth
                          />
                        </div>
                        <div className="col-12 text-center">
                          <Button
                            onClick={() => {
                              const name = document.getElementById("name")
                                .value;
                              const lastname = document.getElementById(
                                "lastname"
                              ).value;
                              const patient = {
                                name,
                                lastname,
                                hasPatient: true,
                              };
                              onSavePatientContact(patient);
                            }}
                            variant="contained"
                            className="jr-btn bg-green text-white"
                          >
                            Guardar
                          </Button>
                        </div>
                      </form>
                    </Widget>
                  </div>
                )}
              </div>
            )}
            {mail.states.includes(8) && mail.hasPatient && !mail.date && (
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 col-10">
                  <Widget
                    title="Fecha primer impacto"
                    styleName="jr-card-profile-sm"
                  >
                    <div key="datetime_default" className="picker">
                      <DateTimePicker
                        fullWidth
                        value={selectedDate}
                        id="date-first-visit"
                        showTabs={false}
                        onChange={this.handleDateChange}
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={
                          <i className="zmdi zmdi-arrow-forward" />
                        }
                      />
                    </div>
                    <div className="col-12 text-center">
                      <Button
                        onClick={() => {
                          const date = document.getElementById(
                            "date-first-visit"
                          ).value;
                          const patient = {
                            date: date,
                          };
                          onProcessStateChange(8);
                          onProcessStateChange(0);
                          onSavePatientContact(patient);
                          onGoBack();
                        }}
                        variant="contained"
                        className="jr-btn bg-green text-white mt-2"
                      >
                        Guardar
                      </Button>
                    </div>
                  </Widget>
                </div>
              </div>
            )}

            {/* PRIMER IMPACTO */}
            {mail.states.includes(0) && (
              <div>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <ConsentDialog handleClickCIBox={this.handleClickCIBox} />
                  </div>
                  <div className="col-sm-6 col-12">
                    <FormulaDialog />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <ClinicalHistory />
                  </div>
                  <div className="col-sm-6 col-12">
                    <InfoCard
                      data={{
                        icon: "home",
                        title: "Condiciones",
                        subTitle: "vivienda",
                        color: "#A2ADB8",
                      }}
                      onClickBox={() => {
                        return false;
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  {mail.labels.includes(5) && (
                    <div className="col-sm-6 col-12">
                      <InfoCard
                        data={{
                          icon: "hospital",
                          title: "Condiciones",
                          subTitle: "Remodulin",
                          color: "#e91e63",
                        }}
                        onClickBox={() => {
                          return false;
                        }}
                      />
                    </div>
                  )}
                  <div className="col-sm-6 col-12">
                    <InfoCard
                      data={{
                        icon: "book-image",
                        title: "Educación",
                        subTitle: "paciente",
                        color: "#A2D471",
                      }}
                      onClickBox={() => {
                        return false;
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </CustomScrollbars>
        </div>
      );
    }

    if (mail.folder === 3) {
      return (
        <div className="module-detail mail-detail">
          <CustomScrollbars
            className="module-list-scroll scrollbar"
            style={{
              height:
                width >= 1200 ? "calc(100vh - 309px)" : "calc(100vh - 288px)",
            }}
          >
            <div className="mail-header">
              <div className="mail-header-content col pl-0">
                <div className="subject">{mail.subject}</div>

                <div className="labels">
                  {labels.map((label, index) => {
                    return (
                      mail.labels.includes(label.id) && (
                        <div
                          key={index}
                          className={`badge text-white bg-${label.color}`}
                        >
                          {label.title}
                        </div>
                      )
                    );
                  })}
                </div>
              </div>

              <div className="mail-header-actions"></div>
            </div>
            <hr />

            <div className="row">
              <div className="col-sm-6 col-12">
                <InfoCard
                  data={{
                    icon: "mail-reply",
                    title: "Responder",
                    subTitle: "Query",
                    color: "#ffc107",
                  }}
                  onClickBox={() => {
                    return false;
                  }}
                />
              </div>
              <div className="col-sm-6 col-12">
                <InfoCard
                  data={{
                    icon: "attachment-alt",
                    title: "Adjuntos",
                    subTitle: "",
                    color: "#A2ADB8",
                  }}
                  onClickBox={() => {
                    return false;
                  }}
                />
              </div>
            </div>
          </CustomScrollbars>
        </div>
      );
    }
    return (
      <div className="module-detail mail-detail">
        <CustomScrollbars
          className="module-list-scroll scrollbar"
          style={{
            height:
              width >= 1200 ? "calc(100vh - 309px)" : "calc(100vh - 288px)",
          }}
        >
          <div className="mail-header">
            <div className="mail-header-content col pl-0">
              <div className="subject">{mail.subject}</div>

              <div className="labels">
                {labels.map((label, index) => {
                  return (
                    mail.labels.includes(label.id) && (
                      <div
                        key={index}
                        className={`badge text-white bg-${label.color}`}
                      >
                        {label.title}
                      </div>
                    )
                  );
                })}
              </div>
            </div>

            <div className="mail-header-actions">
              ¿Registrar?
              <Button
                onClick={() => {
                  return false;
                }}
                variant="contained"
                className="ml-3 jr-btn bg-green text-white"
              >
                <i className="zmdi zmdi-thumb-up zmdi-hc-fw" />
              </Button>
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-sm-6 col-12">
              <InfoCard
                data={{
                  icon: "collection-video",
                  title: "Ver",
                  subTitle: "Presentación",
                  color: "#A2ADB8",
                }}
                onClickBox={() => {
                  return false;
                }}
              />
            </div>
            <div className="col-sm-6 col-12">
              <InfoCard
                data={{
                  icon: "comment-list",
                  title: "Feedback",
                  subTitle: "",
                  color: "#673ab7 ",
                }}
                onClickBox={() => {
                  return false;
                }}
              />
            </div>
          </div>
        </CustomScrollbars>
      </div>
    );
  }
}

export default MailDetail;
