import React, { useState } from "react";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle
} from "@material-ui/core";
import Form from "./Form";
import Table from "./Table";

const DeliveryDetail = ({impactId, deliveryDetailList, setDeliveryDetailList, readOnly}) => {
  const [currentRowObj, setCurrentRowObj] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleCreateUpdate = (item) => {
    var isNew = item.id === 0;
    let data = [];
    if(isNew){
      item.id = 't'+ deliveryDetailList.length;
      data = [...deliveryDetailList, item];
    }else{
      let index = deliveryDetailList.findIndex((x)=> x.id == item.id);
      data = [...deliveryDetailList];
      data[index] = item;
    }
    setDeliveryDetailList(data);
    setCurrentRowObj(null);
  };

  const handleRequestCloseDel = () => setOpenDeleteDialog(false);
  const removeItemInList = (item) => {
    let index = deliveryDetailList.findIndex((x)=> x.id == item.id);
    let data = [...deliveryDetailList];
    data.splice(index,1);
    return data;
  };
  const handleDelete = () => {
    handleRequestCloseDel();
    setDeliveryDetailList(removeItemInList(currentRowObj));
    setCurrentRowObj(null);
  };
  
  return (
    <div>
      {!readOnly &&
        <Form
          obj={currentRowObj}
          handleCreateUpdate={handleCreateUpdate}
          setCurrentRowObj={setCurrentRowObj}
          readOnly={readOnly}/>
      }
      <Table
        data={deliveryDetailList}
        handleOpenDel={() => setOpenDeleteDialog(true)}
        setCurrentRowObj={setCurrentRowObj}
        currentImpact={impactId}
        readOnly={readOnly}
      />
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openDeleteDialog}
        disableBackdropClick={true}
        onClose={handleRequestCloseDel}
      >
        <DialogTitle>
          <IntlMessages id="additionalMedications.MsgDelConfirmation" />
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleRequestCloseDel} color="secondary">
            <IntlMessages id="core.cancel" />
          </Button>
          <Button onClick={handleDelete} color="primary">
            <IntlMessages id="core.accept" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeliveryDetail;