import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button, FormHelperText } from "@material-ui/core";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {Card, CardHeader} from 'reactstrap';
import { FileUploadWithOriginalName } from "components/Form/file-upload/index";
import DownloadButton from "components/DownloadButton/index";
import { callApiAsync } from "api/apiList";
import { setLoader } from "actions/Base";

const clearFile = () => {
    const elementFile =  document.getElementById("fm-file");
    if (elementFile)
        elementFile.value = "";
};

const CardFileUpload = ({name, file, fileResult, setFileResult, errors }) => {
  const handleFilenameChange = (filenameResult) => { setFileResult(filenameResult); };

  const resetControlFile = () =>{
    clearFile();
    setFileResult({fileName:"", originalName:""}); 
  };

  useEffect(() => {
    return () => {
      resetControlFile();
    }
  }, [])

  const isError = () => errors && errors.hasOwnProperty(name);
  const disabled = false;
  return (
    <React.Fragment>
    { (!disabled || file) && 
      <Card className="shadow border-0 mt-4">
        <CardHeader>          
          { !disabled && 
            <FileUploadWithOriginalName id="fm-file" setResult={handleFilenameChange} >                
              <Button color="secondary" aria-label="add" component="span" className="mt-1">
                <i className="zmdi zmdi-upload mr-2"></i> <IntlMessages id="core.uploadFile" />
              </Button>
            </FileUploadWithOriginalName>
          }
          <span className="ml-2 mr-2">{fileResult.originalName}</span>
          {!fileResult.originalName && file && <DownloadButton
                fileLink={file}
                keyMsg="core.download"
                color="default"
                icon="zmdi-download"
                variant="text"
                classes="float-right"
              />}
          <FormHelperText error={isError()}>{!isError() ? "" : errors[name].message}</FormHelperText>
        </CardHeader>
      </Card>  
    }
    </React.Fragment>
  );
};

export default CardFileUpload;