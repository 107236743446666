import React, { useState } from "react";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CardBox from "components/CardBox/index";
import DialogConfirm from "components/Form/Controls/DialogConfirm";

const CustomTable = ({
  data,
  handleOpenCRU,
  handleOpenDel,
  setCurrentRowObj,
  handleClickContinue,
  showBtnCotinue
}) => {
  const datatableConfig = {
    textLabels: {
      body: {
        noMatch: "No hay resultados para la búsqueda actual",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordernar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Items por pagina:",
        displayRows: "de",
      },
      toolbar: {
        search: "Busqueda",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtro",
      },
      filter: {
        all: "Todos",
        title: "FILTRO",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas",
      },
      selectedRows: {
        text: "item(s) seleccionados",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    },
  };

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    search: false,
    viewColumns: false,
    textLabels: datatableConfig.textLabels,
  };

  const [openDlgConfirm, setOpenDlgConfirm] = useState(false);

  const handleClick = (tableMeta) => {
    handleOpenCRU();
    setCurrentRowObj(
      !tableMeta ? null : tableMeta.tableData[tableMeta.rowIndex]
    );
  };
  const handleClickDelete = (tableMeta) => {
    handleOpenDel();
    setCurrentRowObj(tableMeta.tableData[tableMeta.rowIndex]);
  };

  const columns = [
    {
      name: "radicacionDate",
      label: IntlGetText("radicacionInsurance.RadicacionDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    {
      name: "code",
      label: IntlGetText("radicacionInsurance.Code"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "radicacionPoint",
      label: IntlGetText("radicacionInsurance.radicacionPoint"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = tableMeta.tableData[tableMeta.rowIndex];
          if (rowObj.hasAuthorizations) {
            return (
              <div>
                <Button
                  onClick={() => handleClick(tableMeta)}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className="zmdi zmdi-eye"></i>
                </Button>
              </div>
            );
          }
          return (
            <div>
              <Button
                onClick={() => handleClick(tableMeta)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-edit"></i>
              </Button>
              <Button
                onClick={() => handleClickDelete(tableMeta)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-delete"></i>
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="row">
      <CardBox styleName="col-12" cardStyle="p-0 border-dialog-card">
        <MUIDataTable
          className="border-dialog-card"
          data={data}
          columns={columns}
          options={options}
        />
      </CardBox>
      <div className="col-lg-12 col-md-12 col-xs-12 mt-4 text-center">
        <Button
          onClick={() => handleClick(null)}
          variant="contained"
          color="primary"
        >
          {<IntlMessages id="core.add" />}
        </Button>
        { showBtnCotinue &&
          <Button
                onClick={()=>setOpenDlgConfirm(true)}
                variant="outlined"
                color="primary"
                className="ml-4"
              >
                {<IntlMessages id="radicadoEPS.btnContinue" />}
          </Button>
        }
        { showBtnCotinue &&
          <DialogConfirm
            openDialog={openDlgConfirm}
            title={<IntlMessages id="radicadoEPS.dialogConfirmTitle" />}
            text={<IntlMessages id="radicadoEPS.dialogConfirmText" />}
            handleRequestClose={()=>setOpenDlgConfirm(false)}
            onAcceptClick={handleClickContinue}
          />
        }
      </div>
    </div>
  );
};

export default CustomTable;
