import {
    INSTITUTION_SET_CI_INFO
  } from "constants/ActionTypes";
  
const INIT_STATE = {
    ciInstitution: null, 
    ciSignedDocument: null,
  };
 
export default (state = INIT_STATE, {type, payload}) => {
    switch (type) {
        case INSTITUTION_SET_CI_INFO: {
            return { ...state, 
                ciInstitution: payload.ciInstitution,
                ciSignedDocument: payload.ciSignedDocument
            };
        }
        
        default:
        return state;
    }
};