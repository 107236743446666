import React, { useState } from "react";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";
import IntlMessages from "util/IntlMessages";
import Table, { getTextLabels, defaultCol } from "components/Form/Table";


const DlgNurses = ({nurse, nurses}) => {
  const [openDlg, setOpenDlg] = useState(false);

  if(!nurses || nurses.length < 2){
    return (<>{nurse}</>);
  }

  const columns = [
    defaultCol("name", "core.name"),
    defaultCol("lastName", "core.lastName")
  ];

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: getTextLabels(),
    pagination:false
  };

  return (
    <>
      <div style={{ cursor: "pointer" }} 
        onClick={()=>setOpenDlg(true)}>
        {nurse+" ..."}
      </div>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={openDlg}
        disableBackdropClick={true}
        onClose={()=>setOpenDlg(false)}
      >
        <DialogTitle>
          <IntlMessages id="core.nurses" />
        </DialogTitle>
        <DialogContent>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <Table
              className="border-dialog-card mt-2 mb-2"
              data={nurses}
              columns={columns}
              options={options}
            />
          </div>  
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpenDlg(false)} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DlgNurses;