import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, Redirect } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLoader } from "actions/Base";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button, Tooltip, Grid } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import SearchIcon from "@material-ui/icons/Search";
import {
  callApi,
  getPatientPAList,
  patchActivatePatient,
  getRepList,
  getPatientPAReportList,
} from "api/apiList";
import Table, {
  defaultOptions,
  defaultCol,
  dateColumn,
} from "components/Form/Table";
import DialogConfirm from "components/Form/Controls/DialogConfirm";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete, {
  getCboValue,
} from "components/Form/Controls/select-autocomplete";
import FormCheckbox from "components/Form/Controls/checkbox";
import { isRole } from "app/routes/psp/services/userService";
import { UserStatePAEnum, ShardKeyEnum, RoleEnum } from "constants/Enums";
import DialogGeneric from "components/Form/DialogGeneric";
import BtnAddPatient from "./addPatient";
import AddPatient from "./addPatient/addPatient";
import PatientComments from "./patientComment";
import { getUserInfo } from "app/routes/psp/services/userService";
import { downloadFile } from "services/fileService";

const getYears = () => {
  var max = dayjs().get("year");
  var min = 2022;
  var years = [];

  for (var i = min; i <= max; i++) {
    years.push(i);
  }
  return years.map((x) => ({ id: x, name: x }));
};

const getMonths = () => {
  return [
    { id: 1, name: "Enero" },
    { id: 2, name: "Febrero" },
    { id: 3, name: "Marzo" },
    { id: 4, name: "Abril" },
    { id: 5, name: "Mayo" },
    { id: 6, name: "Junio" },
    { id: 7, name: "Julio" },
    { id: 8, name: "Agosto" },
    { id: 9, name: "Septiembre" },
    { id: 10, name: "Octubre" },
    { id: 11, name: "Noviembre" },
    { id: 12, name: "Diciembre" },
  ];
};

const PatientPAList = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [data, setData] = useState([]);
  const [repList, setRepList] = useState([]);
  const [currentRowObj, setCurrentRowObj] = useState(null);
  const [openDlgActive, setOpenDlgActive] = useState(false);
  const [openDlgEdit, setOpenDlgEdit] = useState(false);
  const [openDlgComments, setOpenDlgComments] = useState(false);
  const [openDlgReport, setOpenDlgReport] = useState(false);

  const yearList = getYears();
  const monthList = getMonths();

  const isRep = isRole(RoleEnum.rep);
  const isNurse = isRole(RoleEnum.nurse);
  const isConsult = isRole(RoleEnum.consultation);

  let msgActivateText = IntlGetText("patientPA.activatePatientMsg");
  const activatePatientText = IntlGetText("patient.activatePatient");
  const editText = IntlGetText("core.edit");
  const detailText = IntlGetText("core.detail");
  const commentText = IntlGetText("core.comment");
  const yearRequiredText = IntlGetText("core.yearRequired");
  const montRequiredText = IntlGetText("core.montRequired");

  const dateTypeList = [
    { id: 1, name: IntlGetText("core.creationDate") },
    //{ id: 2, name: IntlGetText("additionalMedications.treatmentStartDate") },
    //{ id: 3, name: IntlGetText("patientPA.nextInstillationDate") },
  ];
  const contactableList = [
    { id: 1, name: IntlGetText("core.datatable.filter.all") },
    { id: 2, name: IntlGetText("button.yes") },
    { id: 3, name: IntlGetText("button.no") },
  ];
  const institutionList = [
    { id: 1, name: IntlGetText("core.datatable.filter.all") },
    { id: 2, name: IntlGetText("intitution.institutional") },
    { id: 3, name: IntlGetText("intitution.noInstitutional") },
  ];

  const defaultValues = {
    dateType: dateTypeList[0].id,
    startDate: dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DDT00:00:00"),
    endDate: null,
    contactable: null,
    institutional: null,
    unreadComments: false,
  };
  const validationSchema = {
    startDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate")),
    endDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate")),
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, errors, setValue, trigger, watch } = methods;

  const loadData = (obj = {}) =>
    callApi(() => getPatientPAList(obj), setData, fnSetLoader);

  useEffect(() => {
    loadData({
      dateType: dateTypeList[0].id,
      startDate: dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DDT00:00:00"),      
    });
    if (isNurse) {
      callApi(getRepList, setRepList);
    }
    setValue("contactable", getCboValue(contactableList, 1));
    setValue("institutional", getCboValue(institutionList, 1));
  }, []);

  useEffect(() => {
    if (location && location.state && location.state.reloadPage) loadData();
  }, [location]);

  const getObj = (tableMeta) =>
    data.find(
      (x) =>
        (x.id > 0 && x.id === tableMeta.rowData[0]) ||
        (x.externalId > 0 && x.externalId === tableMeta.rowData[1])
    );

  const handleClick = (rowObj) => {
    setOpenDlgActive(true);
    setCurrentRowObj(rowObj);
  };

  const handleClickActive = () => {
    callApi(
      () => patchActivatePatient(currentRowObj.id),
      () => {
        setCurrentRowObj(null);
        setOpenDlgActive(false);
        loadData();
      },
      fnSetLoader
    );
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    {
      name: "externalId",
      label: "ExternalId",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("visitor", "role.visitor"),
    dateColumn("creationDate", "core.creationDate", "YYYY/MM/DD hh:mm a"),
    dateColumn("startTreament", "additionalMedications.treatmentStartDate"),
    {
      name: "code",
      label: IntlGetText("core.code"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          if (isRep) return value;
          return (
            <Link
              to={{
                pathname: "/app/patientPA/detail",
                id: rowObj.id,
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    defaultCol("fullName", "core.name"),
    defaultCol("city", "core.city"),
    defaultCol("eps", "core.insurance"),
    defaultCol("doctor", "core.doctor"),
    defaultCol("patientState", "patientPA.patientState"),
    defaultCol("currentCycle", "patientPA.cycle"),
    defaultCol("currentInstillation", "patientPA.currentInstillation"),
    dateColumn("nextInstillationDate", "patientPA.nextInstillationDate"),
    defaultCol("cycleProccesState", "patientPA.cycleProccesState"),
    defaultCol("instillationsNumber", "patientPA.instillationsNumber"),
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          if (isRep) {
            return (
              <Tooltip title={commentText} placement="bottom-end">
                <Button
                  onClick={() => {
                    setOpenDlgComments(true);
                    setCurrentRowObj(rowObj);
                  }}
                  variant="contained"
                  className={
                    "jr-btn jr-btn-xs" +
                    (rowObj.hasPendingComments ? " bg-yellow" : "")
                  }
                >
                  <i className="zmdi zmdi-comment-more"></i>
                </Button>
              </Tooltip>
            );
          }
          return (
            <>
              {rowObj.patientStateId ===
                UserStatePAEnum.pendingVerification && (
                <Tooltip title={activatePatientText} placement="bottom-end">
                  <Button
                    onClick={() => handleClick(rowObj)}
                    variant="contained"
                    className={"jr-btn jr-btn-xs bg-green text-white"}
                  >
                    <i className="zmdi zmdi-assignment-account"></i>
                  </Button>
                </Tooltip>
              )}
              <Tooltip
                title={isConsult ? detailText : editText}
                placement="bottom-end"
              >
                <Button
                  onClick={() => {
                    setOpenDlgEdit(true);
                    setCurrentRowObj(rowObj);
                  }}
                  variant="contained"
                  className={"jr-btn jr-btn-xs"}
                >
                  <i
                    className={isConsult ? "zmdi zmdi-eye" : "zmdi zmdi-edit"}
                  ></i>
                </Button>
              </Tooltip>
              {rowObj.hasComments && (
                <Tooltip title={commentText} placement="bottom-end">
                  <Button
                    onClick={() => {
                      setOpenDlgComments(true);
                      setCurrentRowObj(rowObj);
                    }}
                    variant="contained"
                    className={
                      "jr-btn jr-btn-xs" +
                      (rowObj.hasPendingComments ? " bg-yellow" : "")
                    }
                  >
                    <i className="zmdi zmdi-comment-more"></i>
                  </Button>
                </Tooltip>
              )}
            </>
          );
        },
      },
    },
  ];
  const onDownload = handleSubmit(async (d) => {
    if (!d.year) {
      NotificationManager.warning(yearRequiredText);
      return;
    }
    if (!d.month) {
      NotificationManager.warning(montRequiredText);
      return;
    }

    var obj = {
      year: Number(d.year.value),
      month: Number(d.month.value),
    };

    callApi(
      () => getPatientPAReportList(obj),
      (data) => downloadFile(data),
      fnSetLoader
    );
  });

  const onFilter = handleSubmit(async (d) => {
    if (d.startDate || d.endDate) {
      if (!(await trigger())) return;
    }

    var obj = {
      dateType: !d.dateType ? null : Number(d.dateType.value),
      startDate: !d.startDate
        ? null
        : dayjs(d.startDate).format("YYYY-MM-DDT00:00:00"),
      endDate: !d.endDate
        ? null
        : dayjs(d.endDate).format("YYYY-MM-DDT00:00:00"),
      contactable: !d.contactable ? null : Number(d.contactable.value),
      institutional: !d.institutional ? null : Number(d.institutional.value),
      unreadComments: d.unreadComments === true ? true : false,
    };

    loadData(obj);
  });

  if (getUserInfo() && getUserInfo().shardKey !== ShardKeyEnum.ialuril && getUserInfo().shardKey !== ShardKeyEnum.seranestcr) {
    return <Redirect to="/unauthorized" />;
  }

  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);

  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-3 mb-5">
        <div className="patientPa-filter-container">
          <FormProvider {...methods}>
            <Grid container spacing={1}>
              {/* <Grid item xs={12} md={2} lg={2}>
                <FormSelectAutoComplete
                  name="dateType"
                  label={<IntlMessages id="core.type" />}
                  options={dateTypeList}
                  defaultValue={dateTypeList[0]}
                  margin="normal"
                />
              </Grid> */}
              <Grid item xs={12} md={2} lg={2}>
                <FormDatePicker
                  name="startDate"
                  label={<IntlMessages id="core.startDate" />}
                  format="YYYY-MM-DD"
                  margin="normal"
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <FormDatePicker
                  name="endDate"
                  label={<IntlMessages id="core.endDate" />}
                  format="YYYY-MM-DD"
                  margin="normal"
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <FormSelectAutoComplete
                  name="contactable"
                  label={<IntlMessages id="patientPA.contactable" />}
                  options={contactableList}
                />
              </Grid>
              {/* <Grid item xs={12} md={2} lg={2}>
                <FormSelectAutoComplete
                  name="institutional"
                  label={<IntlMessages id="intitution.institutional" />}
                  options={institutionList}
                />
              </Grid> */}
              <Grid item xs={12} md={2} lg={2}>
                <div className="mt-3">
                  <FormCheckbox
                    name="unreadComments"
                    label={<IntlMessages id="patientPA.unreadComments" />}
                    value={watch("unreadComments")}
                    onChange={chkHandleChange("unreadComments")}
                  />
                </div>
              </Grid>
            </Grid>
            <div className="patientPa-btn-filter-container">
              <div style={{ display: "flex" }}>
                <div className="mt-3">
                  <Button
                    onClick={onFilter}
                    variant="contained"
                    color="primary"
                  >
                    <SearchIcon />
                  </Button>
                </div>

                <div className="mt-3" style={{ margin: "0 0 0 10px" }}>
                  <Button
                    onClick={() => setOpenDlgReport(true)}
                    variant="contained"
                    color="secondary"
                  >
                    <GetAppIcon />
                  </Button>
                </div>
              </div>

              {!isConsult && (
                <div className="mt-3">
                  <BtnAddPatient loadData={loadData} />
                </div>
              )}
            </div>
          </FormProvider>
        </div>
        <Table
          className="border-dialog-card mt-2 mb-2"
          data={data}
          columns={columns}
          options={defaultOptions()}
        />
        <DialogConfirm
          openDialog={openDlgActive}
          title={<IntlMessages id="patientPA.activatePatientTitle" />}
          text={msgActivateText.replace(
            "[0]",
            currentRowObj && currentRowObj.code
          )}
          handleRequestClose={() => setOpenDlgActive(false)}
          onAcceptClick={handleClickActive}
        />
        <PatientComments
          openDlg={openDlgComments}
          requestCloseDlg={() => {
            if (currentRowObj && currentRowObj.hasPendingComments) loadData();
            setOpenDlgComments(false);
            setCurrentRowObj(null);
          }}
          patient={currentRowObj}
          isRep={isRep}
          isConsult={isConsult}
        />
        <AddPatient
          open={openDlgEdit}
          handleRequestClose={() => {
            setOpenDlgEdit(false);
            setCurrentRowObj(null);
          }}
          patient={currentRowObj}
          nurseList={[]}
          repList={repList}
          patients={[]}
          callBack={() => {
            setOpenDlgEdit(false);
            setCurrentRowObj(null);
            loadData();
          }}
          isConsult={isConsult ? true : false}
        />
        <DialogGeneric
          open={openDlgReport}
          closeDialog={() => setOpenDlgReport(false)}
          titleTextId="reports.patients"
        >
          <div>
            <FormProvider {...methods}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={5} lg={5}>
                  <FormSelectAutoComplete
                    name="year"
                    label={<IntlMessages id="core.year" />}
                    options={yearList}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={5} lg={5}>
                  <FormSelectAutoComplete
                    name="month"
                    label={<IntlMessages id="calendar.messages.month" />}
                    options={monthList}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={2} md={1} lg={1}>
                  <div className="mt-3">
                    <Button
                      onClick={onDownload}
                      variant="contained"
                      color="secondary"
                    >
                      <GetAppIcon />
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </FormProvider>
          </div>
        </DialogGeneric>
      </div>
    </>
  );
};

export default PatientPAList;
