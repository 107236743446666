import React from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import configureStore, {history} from './store';
import App from './containers/App';

import axios from "axios";
import { userSignOut } from "actions/Auth";
import logger from "services/logService";
import { getUserInfo, setUserInfo } from './app/routes/psp/services/userService';

export const store = configureStore();

const MainApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App}/>
      </Switch>
    </ConnectedRouter>
  </Provider>;


const API_URL = process.env.REACT_APP_API_URL;
const {dispatch} = store;

// function getTokenInfo(){
//   var userInfo = localStorage.getItem("userInfo");
//   if(userInfo){
//     userInfo = JSON.parse(userInfo);
//     return { token : userInfo.token, refreshToken : userInfo.refreshToken};
//   }
//   return { token : "", refreshToken : ""};
// }

let requestsToRefresh = [];
let isRefreshRequesting = false;

// Handling unexpected errors globally
axios.interceptors.response.use(undefined, (error) => {
  const originalRequest = error.config;
  
  if (originalRequest.url.includes(process.env.REACT_APP_SAS_GENERATOR))
    return;

  if (error.response && (error.response.status === 403 || error.response.status === 401) && !originalRequest._retry) {
    
    if(!isRefreshRequesting){
      isRefreshRequesting = true;

      originalRequest._retry = true;
      const tokenInfo = getUserInfo();
      return axios.post(API_URL+'/token/refresh',
              tokenInfo
            ).then(res => {
                if (res.status === 200) {
                    setUserInfo({...tokenInfo, ...res.data});
                    //localStorage.setItem("userInfo", JSON.stringify(res.data));
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getUserInfo().token;
                    
                    requestsToRefresh.forEach((cb) => cb(true));
                    return axios(originalRequest);
                }
            }).catch((error23) => {
              requestsToRefresh.forEach((cb) => cb(false));
              dispatch(userSignOut());
            })
            .finally(() => {
              requestsToRefresh = [];
              isRefreshRequesting = false;
            })
    }

    return new Promise((resolve, reject) => {
      requestsToRefresh.push((token) => {
        if (token) {
          resolve(axios(originalRequest));
        }
        reject(error);
      });
    });
  }

  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) logger.log(error);

  return Promise.reject(error);
});

axios.interceptors.request.use(function(config) {
  
  if (config.url.includes(process.env.REACT_APP_SAS_GENERATOR))
    return config;

  const userInfo = getUserInfo();
  if (userInfo && userInfo.token) {
    config.headers.Authorization = `bearer ${userInfo.token}`;
  }
  return config;
},
error => {
  Promise.reject(error)
});

export default MainApp;