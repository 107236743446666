
import React, { useState } from "react";
import * as dayjs from "dayjs";
import { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import Table, { getTextLabels, defaultCol } from "components/Form/Table";
import { DownloadButton2 } from "components/DownloadButton/index";
import DeliveryDetail from "./DeliveryDetail";

const ImpactTable = ({ data }) => {
  const [openDlg, setOpenDlg] = useState(false);
  const [deliveryDetailList, setDeliveryDetailList] = useState([]);

  const getObj = (tableMeta) => data.find(x=>x.id === tableMeta.rowData[0]);  

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    pagination:false,
    textLabels: getTextLabels()
  };

  const handleClick = (tableMeta) => {
    setOpenDlg(true);
    setDeliveryDetailList(getObj(tableMeta).deliveryDetail);
  };

  var columns =  [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display:"excluded"
      },
    },
    {
      name: "impactDate",
      label: IntlGetText("core.impact"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD hh:mm a") : "";
        },
      },
    },
    defaultCol("radicadoOLFCode", "radicadoOLF.pendDate"),
    {
      name: "deliveryDate",
      label: IntlGetText("radicadoOLF.dlvrDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    {
      name: "medicineAmount",
      label: IntlGetText("medicineDelivery.medAmountDlvr"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "filePath",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <React.Fragment>
              <Button
                onClick={() => handleClick(tableMeta)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-eye"></i>
              </Button>
              {value &&
                <DownloadButton2
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                  color="primary"
                  fileLink={value}
                >
                  <i className="zmdi zmdi-download"></i>
                </DownloadButton2>
              }
            </React.Fragment>
          );
        },
      },
    }
  ];  

  return (
    <React.Fragment>
    <Table
      className="border-dialog-card"
      data={data}
      columns={columns}
      options={options} />
    <DeliveryDetail 
      data={deliveryDetailList} 
      openDlg={openDlg} 
      handleRequestClose={()=>setOpenDlg(false)}/>
    </React.Fragment>
  );
};

export default ImpactTable;