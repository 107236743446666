import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import FormSelectAutoComplete, {
  getCboValue,
} from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import FormDatePicker from "components/Form/Controls/datepicker";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import RegexConstants from "constants/regexConstants";
import {
  callApi,
  callApiAsync,
  getPatientEdit,
  postAddPatientPA,
  patchUpdateExternalId,
  getDoctorPAList,
  getInsurancePAList,
} from "api/apiList";
import { PatientOperationEnum } from "constants/Enums";

const GenreList = () => [
  { id: 1, name: IntlGetText("core.male") },
  { id: 2, name: IntlGetText("core.feminine") },
];

const GetDocumentTypeList = () => [
  { id: 1, name: "CC" },
  { id: 2, name: "CE" },
  { id: 3, name: "TI" },
  { id: 4, name: "OTRO" },
];

const GetTypeList = () => [
  { id: 1, name: IntlGetText("core.new") },
  { id: 2, name: IntlGetText("core.mantenance") },
  { id: 3, name: IntlGetText("core.started") },
];

const GetContextList = () => [
  { id: 1, name: IntlGetText("patient.contextNotInstitution") },
  { id: 2, name: IntlGetText("patient.contextInstitution") },
];
const GetOperationList = () => [
  { id: 1, name: IntlGetText("patient.AddOperationAdd") },
  { id: 2, name: IntlGetText("patient.AddOperationCreate") },
];

const AddPatient = (props) => {
  const GetPatientList = (patients, contextValue) => {
    if (patients.length == 0 || contextValue == undefined) {
      return [];
    }
    const result = patients.filter(
      (patient) => patient.institutionState == contextValue.value
    );
    return result;
  };

  const {
    open,
    handleRequestClose,
    patient,
    nurseList,
    repList,
    patients,
    callBack,
  } = props;

  const [isAddNewPatient, setIsAddNewPatient] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [insuranceList, setInsuranceList] = useState([]);

  const genderrList = GenreList();
  const documentTypeList = GetDocumentTypeList();
  const typeList = GetTypeList();
  const contextList = GetContextList();
  const operationList = GetOperationList();

  const contactableList = [
    { id: 1, name: IntlGetText("button.yes") },
    { id: 2, name: IntlGetText("button.no") },
  ];

  const defaultValues = {
    type: { label: typeList[0].name, value: typeList[0].id },
    managementDate: dayjs(),
    name: "",
    lastName: "",
    documentType: null,
    document: "",
    gender: null,
    patientBirthDate: null,
    phone: "",
    email: "",
    doctorId: null,
    insuranceId: null,
    contactable: null,
    operation: { label: operationList[0].name, value: operationList[0].id },
  };

  let validationSchema = {};
  if (isAddNewPatient || patient) {
    validationSchema = {
      name: yup.string().required(<IntlMessages id="core.fieldRequired" />),
      lastName: yup.string().required(<IntlMessages id="core.fieldRequired" />),
      documentType: yup
        .object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable(),
      document: yup
        .string()
        .matches(RegexConstants.numeric, IntlGetText("yup.numericValidation"))
        .required(<IntlMessages id="core.fieldRequired" />),
      gender: yup
        .object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable(),
      doctorId: yup
        .object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable(),
      contactable: yup
        .object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable(),
      email: yup
        .string()
        .matches(RegexConstants.email, IntlGetText("yup.emailInvalid"))
        .nullable(),
      patient: yup
        .object()
        .nullable()
        .notRequired(),
    };
  }
  if (!isAddNewPatient && !patient) {
    validationSchema.patient = yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable();
  }
  if (nurseList.length > 0) {
    validationSchema.nurseId = yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable();
  }
  validationSchema.type = yup
    .object()
    .shape({ value: yup.string().required() })
    .required(<IntlMessages id="core.fieldRequired" />)
    .nullable();
  validationSchema.managementDate = yup
    .date()
    .nullable(true)
    .typeError(IntlGetText("yup.InvalidDate"))
    .required(<IntlMessages id="core.fieldRequired" />);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, errors, setValue, watch } = methods;

  const repIdValue = watch("repId");
  const operationValue =
    watch("operation") != undefined ? watch("operation").value : null;

  const contextValue = watch("context");
  var patientList = GetPatientList(patients, contextValue);

  useEffect(() => {
    loadDoctorList(!repIdValue ? "" : repIdValue.value);
  }, [repIdValue]);

  const loadDoctorList = (repId) =>
    callApi(() => getDoctorPAList({ repId }), setDoctorList);

  useEffect(() => {
    if (open && patient) {
      callApi(
        () => getPatientEdit(patient.id),
        (info) => {
          setValue("name", !info.name ? "" : info.name);
          setValue("lastName", !info.lastName ? "" : info.lastName);
          setValue(
            "documentType",
            !info.documentType
              ? null
              : getCboValue(documentTypeList, info.documentType)
          );
          setValue("document", !info.document ? "" : info.document);
          setValue(
            "gender",
            !info.gender ? null : getCboValue(genderrList, info.gender)
          );
          setValue(
            "patientBirthDate",
            !info.patientBirthDate ? null : dayjs(info.patientBirthDate)
          );
          setValue("email", !info.email ? "" : info.email);
          setValue("phone", !info.phone ? "" : info.phone);
          setValue(
            "contactable",
            !info.contactable
              ? getCboValue(contactableList, 2)
              : getCboValue(contactableList, 1)
          );
          if (repList.length > 0)
            setValue(
              "repId",
              !info.repId ? null : getCboValue(repList, info.repId)
            );
          setValue(
            "doctorId",
            !info.doctorId ? null : getCboValue(doctorList, info.doctorId)
          );

          setValue(
            "type",
            !info.type ? null : getCboValue(typeList, info.type)
          );
          setValue(
            "managementDate",
            !info.managementDate ? null : dayjs(info.managementDate)
          );

          callApi(getInsurancePAList, (d) => {
            setInsuranceList(d);
            setValue(
              "insuranceId",
              !info.insuranceId ? null : getCboValue(d, info.insuranceId)
            );
          });
        }
      );
    }

    if (open && !patient) {
      if (nurseList && nurseList.length === 1) {
        setTimeout(() => {
          setValue("nurseId", getCboValue(nurseList, nurseList[0].id));
        }, 200);
      }
    }

    if (open) {
      loadDoctorList("");
      if (!patient) callApi(getInsurancePAList, setInsuranceList);
    }
  }, [open, patient]);

  const onSubmit = handleSubmit(async (d) => {
    if (!(isAddNewPatient || patient)) {
      await updateExternalIdHandle(d);
      return;
    }
    await addNewPatientHandle(d);
  });

  useEffect(() => {
    if (isAddNewPatient) {
      setTimeout(() => {
        setValue("contactable", getCboValue(contactableList, 1));
      }, 200);
    }
  }, [isAddNewPatient]);

  useEffect(() => {
    if (operationValue == PatientOperationEnum.add) {
      setIsAddNewPatient(false);
    } else {
      setIsAddNewPatient(true);
      setValue("patient", -1);
    }
  }, [operationValue]);

  const updateExternalIdHandle = async (d) => {
    const patient = {
      externalId: !d.patient ? null : Number(d.patient.value),
      nurseId: !d.nurseId ? null : d.nurseId.value,
      repId: !d.repId ? null : d.repId.value,
      type: Number(d.type.value),
      managementDate: dayjs(d.managementDate).format("YYYY-MM-DDTHH:mm:ss"),
    };

    await callApiAsync(
      () => patchUpdateExternalId(patient),
      () => {
        callBack();
      }
    );
  };

  const addNewPatientHandle = async (d) => {
    const obj = {
      id: !patient ? 0 : patient.id,
      type: Number(d.type.value),
      managementDate: dayjs(d.managementDate).format("YYYY-MM-DDTHH:mm:ss"),
      name: d.name,
      lastName: d.lastName,
      documentTypeId: Number(d.documentType.value),
      document: d.document,
      gender: Number(d.gender.value),
      birthday: !d.patientBirthDate
        ? null
        : dayjs(d.patientBirthDate).format("YYYY-MM-DDTHH:mm:ss"),
      email: d.email,
      phone: d.phone,
      nurseId: !d.nurseId ? null : d.nurseId.value,
      repId: !d.repId ? null : d.repId.value,
      doctorId: !d.doctorId ? null : Number(d.doctorId.value),
      insuranceId: !d.insuranceId ? null : Number(d.insuranceId.value),
      contactable: Number(d.contactable.value) === 1,
    };

    await callApiAsync(
      () => postAddPatientPA(obj),
      () => {
        callBack();
      }
    );
  };
  const disabled = patient && patient.email ? true : false;

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="patientInfo.titleDlg" />
        </DialogTitle>
        <DialogContent>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <FormProvider {...methods}>
              <form className="row" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  {nurseList.length > 0 && (
                    <Grid item xs={12} md={12} lg={12}>
                      <FormSelectAutoComplete
                        name="nurseId"
                        label={<IntlMessages id="role.callAgent" />}
                        options={nurseList}
                        margin="normal"
                        required={true}
                        errorobj={errors}
                      />
                    </Grid>
                  )}
                  {repList.length > 0 && (
                    <Grid item xs={12} md={12} lg={12}>
                      <FormSelectAutoComplete
                        name="repId"
                        label={<IntlMessages id="role.visitor" />}
                        options={repList}
                        margin="normal"
                      />
                    </Grid>
                  )}
                  {!patient && (
                    <>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormSelectAutoComplete
                          name="operation"
                          label={<IntlMessages id="patient.AddOperation" />}
                          options={operationList}
                          margin="normal"
                          errorobj={errors}
                          required={true}
                        />
                      </Grid>
                    </>
                  )}
                  {operationValue == PatientOperationEnum.add && !patient && (
                    <>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormSelectAutoComplete
                          name="context"
                          label={<IntlMessages id="core.patientTypeContext" />}
                          options={contextList}
                          margin="normal"
                          errorobj={errors}
                          onChange={() => {
                            setValue("patient", null);
                          }}
                        />
                      </Grid>
                      {patients.length > 0 && (
                        <Grid item xs={12} md={12} lg={12}>
                          <FormSelectAutoComplete
                            name="patient"
                            label={<IntlMessages id="core.patient" />}
                            options={patientList}
                            margin="normal"
                            errorobj={errors}
                            required={true}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item xs={12} md={12} lg={12}>
                    <FormSelectAutoComplete
                      name="type"
                      label={<IntlMessages id="core.type" />}
                      options={typeList}
                      margin="normal"
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormDatePicker
                      name="managementDate"
                      label={<IntlMessages id="patientPA.managementDate" />}
                      format="YYYY-MM-DD"
                      margin="normal"
                      disableFuture={true}
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                  {(isAddNewPatient ||
                    patient ||
                    operationValue == PatientOperationEnum.create) && (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormInput
                          name="name"
                          label={<IntlMessages id="core.name" />}
                          margin="normal"
                          required={true}
                          errorobj={errors}
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormInput
                          name="lastName"
                          label={<IntlMessages id="core.lastName" />}
                          margin="normal"
                          required={true}
                          errorobj={errors}
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormSelectAutoComplete
                          name="documentType"
                          label={<IntlMessages id="core.documentType" />}
                          options={documentTypeList}
                          margin="normal"
                          required={true}
                          errorobj={errors}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormInput
                          name="document"
                          label={<IntlMessages id="core.document" />}
                          margin="normal"
                          required={true}
                          errorobj={errors}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormSelectAutoComplete
                          name="gender"
                          label={<IntlMessages id="adverseEvent.gender" />}
                          options={genderrList}
                          margin="normal"
                          required={true}
                          errorobj={errors}
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormDatePicker
                          name="patientBirthDate"
                          label={
                            <IntlMessages id="adverseEvent.patientBirthDate" />
                          }
                          format="YYYY-MM-DD"
                          margin="normal"
                          disableFuture={true}
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormInput
                          name="phone"
                          label={<IntlMessages id="core.phone" />}
                          margin="normal"
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormInput
                          name="email"
                          label={<IntlMessages id="core.email" />}
                          errorobj={errors}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormSelectAutoComplete
                          name="doctorId"
                          label={<IntlMessages id="core.doctor" />}
                          options={doctorList}
                          margin="normal"
                          required={true}
                          errorobj={errors}
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormSelectAutoComplete
                          name="insuranceId"
                          label={
                            <IntlMessages id="PatientSuspended.Header.Eps" />
                          }
                          options={insuranceList}
                          margin="normal"
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormSelectAutoComplete
                          name="contactable"
                          label={<IntlMessages id="patientPA.contactable" />}
                          options={contactableList}
                          margin="normal"
                          required={true}
                          errorobj={errors}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <br></br>
              </form>
            </FormProvider>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.cancel" />
          </Button>
          <ButtonAsync
            className={props.isConsult ? "noDisplay" : ""}
            onClick={onSubmit}
            color="primary"
          >
            {<IntlMessages id="core.save" />}
          </ButtonAsync>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddPatient;
