import React, { useState } from "react";
import { IntlGetText } from "util/IntlMessages";
import InfoCard from "components/InfoCard/index";
import DlgImpacts from "../impacts";

const DeliveryBox = (props) => {
  const [openDlgImpacts, setOpenDlgImpacts] = useState(false);

  return (
    <>
      <InfoCard
        data={{
          icon: "book",
          title: IntlGetText("core.impacts"),
          subTitle: IntlGetText("core.patient"),
          color: "#757575",
        }}
        onClickBox={() => setOpenDlgImpacts(true)}
      />
      <DlgImpacts 
        openDlg={openDlgImpacts} 
        requestCloseDlg={()=> setOpenDlgImpacts(false)}
        {...props}
      />
    </>
  );
};

export default DeliveryBox;