import React, { PureComponent } from "react";
import {
  Bar,
  BarChart,
  ComposedChart,
  LabelList,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { data1, data7, data } from "./data";

import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import CardBox from "components/CardBox";

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text
        x={x}
        y={y}
        dy={-10}
        fill={stroke}
        fontSize={10}
        textAnchor="middle"
      >
        {value}
      </text>
    );
  }
}

const remodulin = (props) => {
  return (
    <div className="app-wrapper">
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <ContainerHeader
          match={props.match}
          title={<IntlMessages id="kpi.impacts.PharmacoVigilance" />}
        />
        <div className="row">
          <div className="col-md-12">
            <CardBox
              heading={<IntlMessages id="kpi.PharmacoVigilance.2020Events" />}
              styleName="text-center"
            >
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  data={data}
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                >
                  <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                  <YAxis />
                  <CartesianGrid strokeDasharray="0 0" />
                  <Tooltip />
                  <Line
                    dataKey="value"
                    stroke="#3367d6"
                    fill="#3367d6"
                    label={<CustomizedLabel />}
                    name={<IntlMessages id="kpi.impacts" />}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardBox>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <CardBox
              heading={
                <IntlMessages id="kpi.PharmacoVigilance.eventCausality" />
              }
              styleName="text-center"
            >
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={data1}
                  margin={{ top: 20, right: 0, left: -25, bottom: 0 }}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="uv"
                    fill="#3367d6"
                    barSize={40}
                    name={<IntlMessages id="psp.activePatients" />}
                  >
                    <LabelList dataKey="uv" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardBox>
          </div>
          <div className="col-md-6">
            <CardBox
              heading={
                <IntlMessages id="kpi.PharmacoVigilance.eventByProductLine" />
              }
              styleName="text-center"
            >
              <ResponsiveContainer width="100%" height={300}>
                <ComposedChart
                  layout="vertical"
                  width={500}
                  height={400}
                  data={data7}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 40,
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis type="number" />
                  <YAxis
                    dataKey="name"
                    type="category"
                    padding={{ top: 30, bottom: 30 }}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="uv"
                    barSize={20}
                    fill="#413ea0"
                    name={<IntlMessages id="core.total" />}
                  >
                    <LabelList dataKey="uv" position="right" />
                  </Bar>
                </ComposedChart>
              </ResponsiveContainer>
            </CardBox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default remodulin;
