import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import Form from "./Form";
import Table from "./Table";
import { 
  callActionApi,
  getAlertList
} from "api/apiList";
import { AlertStateEnum } from "constants/Enums";
import { callReloadAlerts } from "actions/Impact";

const AlertDialog = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { reloadAlerts } = useSelector(({ impact }) => impact);

  const [open, setOpen] = useState(false);
  const [openCRU, setOpenCRU] = useState(false);
  const [currentRowObj, setCurrentRowObj] = useState({});
  const [alertList, setAlertList] = useState([]);
  
  const callApi = (apiFunc, setData) => {
    callActionApi(
      apiFunc,
      setData,
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  };

  const loadAlerts = (prescriptionId) => {
    callApi(() => getAlertList(prescriptionId), setAlertList);
  };

  useEffect(() => loadAlerts(selectedPrescription.id), []);
  useEffect(() =>{
    if(reloadAlerts){
      loadAlerts(selectedPrescription.id);
      dispatch(callReloadAlerts(false));
    }
  },[reloadAlerts]);
  
  useEffect(() => {
    if (open) loadAlerts(selectedPrescription.id);
  }, [open]);

  const handleClickOpen = () => setOpen(true);
  
  const handleRequestClose = () => setOpen(false);
  const handleOpenCRU = () => setOpenCRU(true);
  const handleRequestCloseCRU = () => {
    setOpenCRU(false);
    loadAlerts(selectedPrescription.id);
  };
  
  const isAlertsDone = () => alertList.find(x=>x.state === AlertStateEnum.pending) === undefined;

  return (
    <div>
      <InfoCard
        data={{
          icon: "comment-alert",
          title: IntlGetText("alert.cardTitle"),
          subTitle: IntlGetText("alert.cardSubTitle"),
          color: isAlertsDone() ? "#20c997" : "#E53935"
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="alert.dlgTitle" />
        </DialogTitle>
        <DialogContent>
          <Table
            data={alertList}
            handleOpenCRU={handleOpenCRU}
            setCurrentRowObj={setCurrentRowObj}
          />
          <Form
            rowObj={currentRowObj}
            openCRU={openCRU}
            handleRequestClose={handleRequestCloseCRU}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
