import React, { useState, useEffect } from "react";
import IntlMessages from "util/IntlMessages";
import { Tabs, Tab, TextField, Grid } from "@material-ui/core";

const getProp = (patientData, prop) => {
  if(!patientData) return "";
  return patientData[prop] ?? "";
}
const getPropDashboard = (patientData, prop) => {
  if(!patientData) return "";
  if(!patientData.dashBoard) return "";
  return patientData.dashBoard[prop] ?? "";
}

const CustomInput = ({obj, name, labelId, isDashboard }) =>{
  return (
    <Grid item xs={12} md={6} lg={6}>
      <TextField
        disabled
        id={name}
        name={name}
        label={<IntlMessages id={labelId} />}
        value={!isDashboard ? getProp(obj, name) : getPropDashboard(obj, name) }
        variant="outlined"
        size="small"
        fullWidth={true}
      />
    </Grid>
  )
}


const CustomTabs = ({patientData}) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (event, value) => setCurrentTab(value);

  return (
    <>
      <Tabs          
        value={currentTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab className="jr-tabs-label"
          label={<IntlMessages id="patientInfo.titleTab1" />} />
        <Tab className="jr-tabs-label"
          label={<IntlMessages id="patientInfo.titleTab2" />} />
        <Tab className="jr-tabs-label"
          label={<IntlMessages id="patientInfo.titleTab3" />} />
        <Tab className="jr-tabs-label"
          label={<IntlMessages id="patientInfo.titleTab4" />} />
      </Tabs>
      <div className="jr-tabs-content jr-task-list">
        <div className="row">          
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="mt-3">
              {currentTab === 0 && (
                <Grid container spacing={2}>
                  <CustomInput isDashboard={true} obj={patientData} name="cycle" labelId="patientPA.currentCycle"/>
                  <CustomInput isDashboard={true} obj={patientData} name="instillation" labelId="patientPA.currentInstillation"/>
                  <CustomInput isDashboard={true} obj={patientData} name="nextInstillation" labelId="patientPA.nextInstillation"/>
                  <CustomInput isDashboard={true} obj={patientData} name="processState" labelId="patientPA.cycleProccesState"/>
                  <CustomInput isDashboard={true} obj={patientData} name="progress" labelId="patientPA.progress"/>
                  <CustomInput obj={patientData} name="doctor" labelId="patientPA.doctor"/>
                </Grid>
              )}
              {currentTab === 1 && (
                <Grid container spacing={2}>
                  <CustomInput obj={patientData} name="name" labelId="core.name"/>
                  <CustomInput obj={patientData} name="lastName" labelId="core.lastName"/>
                  <CustomInput obj={patientData} name="genre" labelId="core.gender"/>
                  <CustomInput obj={patientData} name="birthday" labelId="core.birthday"/>
                  <CustomInput obj={patientData} name="email" labelId="core.email"/>
                  <CustomInput obj={patientData} name="patientState" labelId="core.state"/>
                </Grid>
              )}
              {currentTab === 2 && (
                <Grid container spacing={2}>
                  <CustomInput obj={patientData} name="city" labelId="core.city"/>
                  <CustomInput obj={patientData} name="address" labelId="core.address"/>
                  <CustomInput obj={patientData} name="phone" labelId="core.phone"/>
                  <CustomInput obj={patientData} name="cellPhone" labelId="core.cellphone"/>
                </Grid>
              )}
              {currentTab === 3 && (
                <Grid container spacing={2}>
                  <CustomInput obj={patientData} name="regime" labelId="core.regime"/>
                  <CustomInput obj={patientData} name="affiliationType" labelId="core.affiliationType"/>
                  
                  {getProp(patientData, "eps") && <CustomInput obj={patientData} name="eps" labelId="core.insurance"/> }
                  {getProp(patientData, "supplementalPlan") && <CustomInput obj={patientData} name="supplementalPlan" labelId="core.supplementalPlan"/> }
                  {getProp(patientData, "prepaidMedicine") && <CustomInput obj={patientData} name="prepaidMedicine" labelId="core.prepaidMedicine"/> }
                  {getProp(patientData, "healthPolicy") && <CustomInput obj={patientData} name="healthPolicy" labelId="core.healthPolicy"/> }

                  <CustomInput obj={patientData} name="entity" labelId="patientPA.entity"/>
                  <CustomInput obj={patientData} name="doctor" labelId="core.doctor"/>
                  <CustomInput obj={patientData} name="placeAttention" labelId="patientPA.placeAttention"/>
                  <CustomInput obj={patientData} name="whichPlaceAttention" labelId="patientPA.whichPlaceAttention"/>
                  <CustomInput obj={patientData} name="companionName" labelId="patientPA.companionName"/>
                  <CustomInput obj={patientData} name="companionPhone" labelId="patientPA.companionPhone"/>
                  <CustomInput obj={patientData} name="pathology" labelId="patientPA.pathology"/>
                  <CustomInput obj={patientData} name="dispenser" labelId="patientPA.dispenser"/>

                  <CustomInput obj={patientData} name="isPrivatePurchase" labelId="patientPA.isPrivatePurchase"/>
                </Grid>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomTabs;