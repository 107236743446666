import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./psp/home";
import Relogin from "containers/Relogin";
import DoctorProfile from "./psp/doctor/Components/DoctorProfile";
import DoctorList from "./psp/doctor/";
import PatientProfile from "./psp/patient/Components/PatientProfile";
import PatientList from "./psp/patient";
import PatientList3 from "./psp/patient3";
import PatientDetail from "./psp/patient3/detail";
import PatientList2 from "./psp/patient2";
import PatientAdd from "./psp/patient2/create";
import PatientAdd2 from "./psp/patient2/create2";
import PatientTest from "./psp/patient2/test";
import Manage from "./psp/manage";
import Management from "./psp/management";
import ManagePharma from "./psp/managepharma";
import ProductLine from "./psp/kpi/productLine";
import Remodulin from "./psp/kpi/productLine/remodulin";
import Iclusig from "./psp/kpi/productLine/iclusig";
import KpiImpacts from "./psp/kpi/impacts";
import KpiPharmacoVigilance from "./psp/kpi/pharmacoVigilance";
import Pharmacovigilant from "./psp/pharmacovigilant";
import ManagerCalendar from "./psp/managerCalendar";
import ImpactPlanning from "./psp/impactPlanning";
import AdverseEvent from "./psp/adverseEvent";
import AdverseEventDetail from "./psp/adverseEvent/detail";
import asyncComponent from "util/asyncComponent";
import Reports from "./psp/reports";
import ReportList from "./pspGeneric/reports";
import ReportsRepKam from "./psp/reports/indexRepKam";
import PatientPA from "./psp/patientPA";
import PatientPADetail from "./psp/patientPA/detail";
import PatientManagement from "./psp/patient-management";
import Prescriptions from "./pspGeneric/prescriptions";
import Dashboard from "./pspGeneric/dashboard";
import DashboardMigration from "./pspGeneric/dashboard-migration";
import DashboardNurse from "./psp/dashboard";
import DashboardPA from "./psp/patientPA/DashboardPA";
import DashboardOperative from "./psp/patientPA/dashboardOperative";
import DashboardClinical from "./psp/patientPA/dashboardClinical";

// import Kpi from "./psp/kpi";
import { withRouter } from "react-router";
import ProtectedRoute from "components/ProtectedRoute";
import { RoleEnum } from "constants/Enums";
import InstitutionHome from "./Institution/home";
import InstitutionDetails from "./Institution/details";

const Routes = ({ match }) => (
  <Switch>
    <ProtectedRoute path={`${match.url}/relogin`} role={[RoleEnum.superAdmin]} component={Relogin} />
    <ProtectedRoute path={`${match.url}/home`} role={[RoleEnum.nurse, RoleEnum.nurse_procedure]} component={asyncComponent(() => import("./psp/home"))} />
    <ProtectedRoute path={`${match.url}/doctor/detail`} role={[RoleEnum.nurse, RoleEnum.manager]} component={DoctorProfile} />
    <ProtectedRoute path={`${match.url}/doctor`} role={[RoleEnum.nurse, RoleEnum.manager]} component={DoctorList} />
    <ProtectedRoute path={`${match.url}/patient0/detail`} role={[RoleEnum.nurse, RoleEnum.nurse_procedure, RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.manager]} component={PatientProfile} />
    <ProtectedRoute path={`${match.url}/patient0`} role={[RoleEnum.nurse, RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.manager]} component={PatientList} />
    <ProtectedRoute path={`${match.url}/patient/detail`} role={[RoleEnum.nurse, RoleEnum.nurse_procedure, RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.manager, RoleEnum.msl]} component={PatientDetail} />
    <ProtectedRoute path={`${match.url}/patient/management`} role={[RoleEnum.nurse, RoleEnum.nurse_procedure, RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.manager, RoleEnum.patient_assignment]} component={PatientManagement} />
    <ProtectedRoute path={`${match.url}/patientPA/clinicalDashboard`} role={[RoleEnum.nurse, RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.manager, RoleEnum.consultation, RoleEnum.kpi_survey]} component={DashboardClinical} />
    <ProtectedRoute path={`${match.url}/patientPA/dashboard2`} role={[RoleEnum.nurse, RoleEnum.nurse_procedure, RoleEnum.rep, RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.manager, RoleEnum.consultation]} component={DashboardPA} />
    <ProtectedRoute path={`${match.url}/patientPA/dashboard`} role={[RoleEnum.nurse,RoleEnum.nurse_procedure, RoleEnum.rep, RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.manager, RoleEnum.consultation]} component={DashboardOperative} />
    <ProtectedRoute path={`${match.url}/patientPA/detail`} role={[RoleEnum.nurse,RoleEnum.nurse_procedure, RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.manager, RoleEnum.consultation]} component={PatientPADetail} />
    <ProtectedRoute path={`${match.url}/patientPA`} role={[RoleEnum.nurse, RoleEnum.rep, RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.manager, RoleEnum.consultation]} component={PatientPA} />
    <ProtectedRoute path={`${match.url}/patient`} role={[RoleEnum.nurse, RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.manager, RoleEnum.msl, RoleEnum.nursemanagerconsultant]} component={PatientList3} />
    <ProtectedRoute path={`${match.url}/patient2/List`} role={[RoleEnum.nurse,RoleEnum.nurse_procedure, RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.manager]} component={PatientList2} />
    <ProtectedRoute path={`${match.url}/patient2/Add`} role={[RoleEnum.nurse,RoleEnum.nurse_procedure, RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.manager]} component={PatientAdd} />
    <ProtectedRoute path={`${match.url}/patient2/Add2`} role={[RoleEnum.nurse,RoleEnum.nurse_procedure, RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.manager]} component={PatientAdd2} />
    <ProtectedRoute path={`${match.url}/patient2/Test`} role={[RoleEnum.nurse,RoleEnum.nurse_procedure, RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.manager]} component={PatientTest} />
    <ProtectedRoute path={`${match.url}/manage`} role={[RoleEnum.nurse, RoleEnum.nurse_procedure]} component={Manage} />
    <ProtectedRoute path={`${match.url}/management`} role={[RoleEnum.nurse, RoleEnum.nurse_procedure, RoleEnum.psp_manager, RoleEnum.psp_coordinator]} component={Management} />
    <ProtectedRoute path={`${match.url}/kpi/productline`} role={[RoleEnum.manager]} component={ProductLine} />
    <ProtectedRoute path={`${match.url}/productline/remodulin`} role={[RoleEnum.manager]} component={Remodulin} />
    <ProtectedRoute path={`${match.url}/productline/iclusig`} role={[RoleEnum.manager]} component={Iclusig} />
    <ProtectedRoute path={`${match.url}/kpi/Impacts`} role={[RoleEnum.manager]} component={KpiImpacts} />
    <ProtectedRoute path={`${match.url}/impactPlanning`} role={[RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.nurse,RoleEnum.nurse_procedure, RoleEnum.consultation]} component={ImpactPlanning} />
    <ProtectedRoute path={`${match.url}/adverseEvent/detail`} role={[RoleEnum.nurse, RoleEnum.pharmacovigilance]} component={AdverseEventDetail} />
    <ProtectedRoute path={`${match.url}/adverseEvent`} role={[RoleEnum.nurse, RoleEnum.pharmacovigilance]} component={AdverseEvent} />
    <ProtectedRoute path={`${match.url}/reportsRepKam`} role={[RoleEnum.psp_manager, RoleEnum.visitor, RoleEnum.kam]} component={ReportsRepKam} />
    <ProtectedRoute path={`${match.url}/reportList`} role={[RoleEnum.callAgent, RoleEnum.callAgentManager]} component={ReportList} />
    <ProtectedRoute path={`${match.url}/reports`} role={[RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.visitor, RoleEnum.kam, RoleEnum.msl, RoleEnum.patient_assignment]} component={Reports} />
    <ProtectedRoute path={`${match.url}/prescriptions`} role={[RoleEnum.callAgent, RoleEnum.callAgentManager]} component={Prescriptions} />
    <ProtectedRoute path={`${match.url}/dashboardKPI`} role={[RoleEnum.nurse, RoleEnum.psp_manager, RoleEnum.nursemanagerconsultant]} component={DashboardNurse} />
    <ProtectedRoute path={`${match.url}/dashboard-migration`} role={[RoleEnum.callAgent, RoleEnum.callAgentManager]} component={DashboardMigration} />
    <ProtectedRoute path={`${match.url}/dashboard`} role={[RoleEnum.callAgent, RoleEnum.callAgentManager]} component={Dashboard} />
    <ProtectedRoute path={`${match.url}/institutionHome`} role={[RoleEnum.institutionManager, RoleEnum.institution]} component={InstitutionHome} />
    <ProtectedRoute path={`${match.url}/institutionDetails`} role={[RoleEnum.institutionManager, RoleEnum.institution]} component={InstitutionDetails} />
    <ProtectedRoute
      path={`${match.url}/kpi/pharmacoVigilance`}
      role={[RoleEnum.manager]}
      component={KpiPharmacoVigilance}
    />
    <ProtectedRoute
      path={`${match.url}/pharmacovigilant`}
      role={[RoleEnum.pharmacovigilance]}
      component={Pharmacovigilant}
    />
    <ProtectedRoute path={`${match.url}/managepharma`} role={[RoleEnum.pharmacovigilance]} component={ManagePharma} />
    <ProtectedRoute path={`${match.url}/manager-calendar`} role={[RoleEnum.manager]} component={ManagerCalendar} />
    {/* <Route path={`${match.url}/kpi/productline`} component={Kpi} /> */}
   
    {/* <Route
      path={`${match.url}/to-do`}
      component={asyncComponent(() => import("./todo/basic"))}
    /> */}    
  </Switch>
);

export default withRouter(Routes);
