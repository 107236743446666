import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button, Tooltip, Grid, IconButton } from "@material-ui/core";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import Table, { defaultCol, dateColumn, getTextLabels } from "components/Form/Table";
import AddPrescription from "./addPrescription";
import ScheduleImpact from './scheduleImpact';
import ImpactDetail from '../impactDetail';
import { RoleEnum } from 'constants/Enums';
import { isRole } from "app/routes/psp/services/userService";
import { 
  callApi, 
  postPrescriptionsList, 
  getProductList,
  getPrescriptionStatusList,
  getUserByRole
} from "api/apiList";


const getUserList = (data) =>{
  return data.map(x => ({
    id: x.id,
    name: x.fullName
  }));
}

const Prescriptions = () => {
    const dispatch = useDispatch();
    const fnSetLoader = (data) => dispatch(setLoader(data));
    const [currentPrescription, setCurrentPrescription] = useState(null);
    const [openDlgPrescription, setOpenDlgPrescription] = useState(false);
    const [openDlgAddImpact, setOpenDlgAddImpact] = useState(false);
    const [openDlgScheduleImpact, setOpenDlgScheduleImpact] = useState(false);
    const [state, setState] = useState({
      page: 0,
      count: 0,
      data: [],
      rowsPerPage: 10
    });
    const [productList, setProductList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [userList, setUserList] = useState([]);    
    const filterDateType = [
      {id:1,name:IntlGetText('core.expeditionDateShort')},
      {id:2,name:IntlGetText('core.expirationDate')},
      {id:3,name:IntlGetText('core.lastImpact')},
      {id:4,name:IntlGetText('core.nextFollowUp')},
      {id:5,name:IntlGetText('core.deliveryScheduledDate')}
    ];
    

    const defaultValues = {
      userId: null,
      stateId: null,
      productId: null,
      filterDateTypeId: null,
      startDate: null,
      endDate: null,
      search:""
    };
    const [filterTable, setFilterTable] = useState(defaultValues);
  
    const validationSchema = {
      startDate: yup
        .date()
        .nullable(true)
        .typeError(IntlGetText("yup.InvalidDate"))
        .required(<IntlMessages id="core.fieldRequired" />)
    };
  
    const methods = useForm({
      defaultValues,
      resolver: yupResolver(yup.object().shape(validationSchema)),
    });
    const { errors, trigger, getValues, watch, setValue } = methods;

    const getObj = (tableMeta) =>
      state.data.find((x) => x.id === tableMeta.rowData[0]);

    const columns = [
      {
        name: "id",
        label: "id",
        options: {
          filter: true,
          sort: false,
          searchable: false,
          display: "excluded"
        },
      },
      defaultCol("document", "core.patient"),
      defaultCol("code", "core.mipres"),
      dateColumn("expeditionDate", "core.expeditionDateShort"),
      dateColumn("expirationDate", "core.expirationDate"),
      defaultCol("product", "core.product"),
      defaultCol("status", "core.state"),
      defaultCol("quantityText", "core.amount"),
      dateColumn("lastImpactDate", "core.lastImpact"),
      dateColumn("datePlanningImpact", "core.nextFollowUp"),
      dateColumn("planningDate", "core.deliveryScheduledDate"),
      {
        name: "actions",
        label: IntlGetText("core.actions"),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            var rowObj = getObj(tableMeta);
            return (
              <div>
                <Tooltip title={<IntlMessages id={rowObj.impactEditId ? 'core.edit' : 'impact.startImpactText'} />} 
                  placement="bottom-end">
                  <Button
                    onClick={() => {
                      setCurrentPrescription(rowObj);
                      setOpenDlgAddImpact(true);
                    }}
                    variant="contained"
                    className="jr-btn jr-btn-xs"
                  >
                    <i className={rowObj.impactEditId ? "zmdi zmdi-edit" : "zmdi zmdi-plus"}></i>
                  </Button>
                </Tooltip>
                <Tooltip title={<IntlMessages id='impact.PlanningImpactTitle' />} 
                  placement="bottom-end">
                  <Button
                    onClick={() => {
                      setCurrentPrescription(rowObj);
                      setOpenDlgScheduleImpact(true);
                    }}
                    variant="contained"
                    className="jr-btn jr-btn-xs"
                  >
                    <i className={rowObj.datePlanningImpact ? "zmdi zmdi-calendar-check" : "zmdi zmdi-calendar-close"}></i>
                  </Button>
                </Tooltip>
              </div>
            );
          },
        },
      }
    ];

    const xhrRequest = (page, sortOrder, filterTable, rowsPerPage) => {
      callApi(
        ()=> postPrescriptionsList({page, sortOrder, filterTable, rowsPerPage}),
        (d)=>{
          setState({
            ...state,
            data: d.data,
            count: d.count,
            page: page,
            rowsPerPage: rowsPerPage
          });
        },
        fnSetLoader
      );
    };

    const loadData =()=>{
      xhrRequest(state.page, undefined, filterTable, state.rowsPerPage);
    };

    const changePage = (page, sortOrder, rowsPerPage) => {
      xhrRequest(page, sortOrder, filterTable, rowsPerPage);
    };

    useEffect(()=>{
      loadData();
      callApi(getProductList,setProductList);
      callApi(getPrescriptionStatusList,setStateList);
      callApi(
        ()=>getUserByRole(RoleEnum.callAgent),
        (data) => {
          setUserList(getUserList(data));
        }
      );
    }, []);

  const getOptions=({ data, page, count, rowsPerPage })=> {
      return {
      filter: false,
      viewColumns: false,
      rowsPerPage: rowsPerPage,
      rowsPerPageOptions: [10,50,100],
      download: false,
      print: false,
      search: false,
      selectableRows: "none",

      serverSide: true,
      count: count,
      page:page,
      onTableChange: (action, tableState) => {
        if (action === "changePage") {
          changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
         }
      },
      textLabels: getTextLabels()
    };
  };

  const filterSubmit = async () => {
    const d = getValues();
    const obj = {
      userId: !d.userId ? null : d.userId.value,
      stateId: !d.stateId ? [] : d.stateId.map(x=>Number(x.value)),
      productId: !d.productId ? [] : d.productId.map(x=>Number(x.value)),
      filterDateTypeId: !d.filterDateTypeId ? null : Number(d.filterDateTypeId.value),
      startDate: !d.startDate ? null : dayjs(d.startDate).format("YYYY-MM-DDTHH:mm:ss"),
      endDate: !d.endDate ? null : dayjs(d.endDate).format("YYYY-MM-DDTHH:mm:ss"),
      search: !d.search ? null : d.search
    }
    setFilterTable(obj);
    xhrRequest(state.page, undefined, obj, state.rowsPerPage);
  };

  const isManager = isRole(RoleEnum.callAgentManager);
  const getWith = (isManager) => isManager ? 4 : 6;

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
      {!isManager && 
        <div className="text-right mt-1 mb-1">
          <Button
            onClick={()=>setOpenDlgPrescription(true)}
            variant="contained"
            color="primary"
            className="ml-2"
          >
            {<IntlMessages id="core.add" />}
          </Button>
        </div>
      }
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-2">
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            { isManager && 
              <Grid item xs={12} md={4} lg={4}>
                <FormSelectAutoComplete
                  name="userId"
                  label={<IntlMessages id="role.callAgent" />}
                  options={userList}
                  margin="normal"
                />
              </Grid>
            }
            <Grid item xs={12} md={getWith(isManager)} lg={getWith(isManager)}>
              <FormSelectAutoComplete
                name="stateId"
                label={<IntlMessages id="core.state" />}
                options={stateList}
                margin="normal"
                isMulti
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={getWith(isManager)} lg={getWith(isManager)}>
              <FormSelectAutoComplete
                name="productId"
                label={<IntlMessages id="core.product" />}
                options={productList}
                margin="normal"
                isMulti
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormInput
                name="search"
                label={<IntlMessages id="core.search" />}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormSelectAutoComplete
                name="filterDateTypeId"
                label={<IntlMessages id="core.date" />}
                options={filterDateType}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            
            <Grid item xs={12} md={2} lg={2}>
              <FormDatePicker
                name="startDate"
                label={<IntlMessages id="core.startDate" />}
                format="YYYY-MM-DD"
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <FormDatePicker
                name="endDate"
                label={<IntlMessages id="core.endDate" />}
                format="YYYY-MM-DD"
                margin="normal"
                errorobj={errors}
              />
            </Grid>            

            <Grid item xs={12} md={2} lg={2}>
              <Tooltip title={IntlGetText("core.search")} placement="bottom-end">
                <Button
                  onClick={filterSubmit}
                  className="mt-3"
                  variant="contained"
                  color="primary"
                >
                  <IntlMessages id="core.search" />
                </Button>
              </Tooltip>
              <Tooltip title={IntlGetText("core.resetFilters")} placement="bottom-end">
                <IconButton
                  type="button"
                  className="icon-btn mt-3"
                  color="primary"
                  onClick={() => {
                    setValue("search",'');
                    setValue("stateId",null);
                    setValue("productId",null);
                    setValue("filterDateTypeId",null);
                    setValue("startDate",null);
                    setValue("endDate",null);
                    setValue("userId",null);
                    filterSubmit();
                  }}
                >
                  <i className="zmdi zmdi-refresh" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </FormProvider>
      </div>
      <AddPrescription 
        openDlg = {openDlgPrescription} 
        closeDialog = {()=>{
          setOpenDlgPrescription(false);
          setCurrentPrescription(null);
        }}
        callBack={filterSubmit}
        prescriptionId={currentPrescription ? currentPrescription.id : 0}
        />
      <ScheduleImpact 
        openDlg = {openDlgScheduleImpact} 
        closeDialog = {()=>{
          setOpenDlgScheduleImpact(false);
          setCurrentPrescription(null);
        }}
        callBack={loadData}
        prescriptionId={currentPrescription ? currentPrescription.id : 0}
        idPlannedImpact={currentPrescription ? currentPrescription.idPlannedImpact : 0}
        datePlanningImpact={currentPrescription ? currentPrescription.datePlanningImpact : null}
        />
      <ImpactDetail
        openDlg={openDlgAddImpact}
        prescription={currentPrescription}
        impactId={currentPrescription && currentPrescription.impactEditId }
        isManager={isManager}
        requestCloseDlg={()=>{
          setOpenDlgAddImpact(false);
          setCurrentPrescription(null);
          filterSubmit();
        }}/>
      <Table
        className="border-dialog-card mt-2 mb-2"
        data={state.data}
        columns={columns}
        options={getOptions(state)}
      />
    </div>
  );
};

export default Prescriptions;