const API_URL = process.env.REACT_APP_API_URL;

function getTokenInfo(){
  var userInfo = localStorage.getItem("userInfo");
  if(userInfo){
    userInfo = JSON.parse(userInfo);
    return { token : userInfo.token, refreshToken : userInfo.refreshToken};
  }
  return { token : "", refreshToken : ""};
}

function post(uri, data) {
  const { token } = getTokenInfo();

  return fetch(uri, {
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  }).then((response) => response.json());
}

function get(uri, data) {
  const { token } = getTokenInfo();

  return fetch(uri, {
    method: "GET",
    body: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  }).then((response) => response.json());
}

export function getToken(username, password) {
  const uri = `${API_URL}/Token/`;

  return fetch(uri, {
    method: "POST",
    body: JSON.stringify({
      username,
      password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
}

export function relogin(username) {
  const uri = `${API_URL}/Relogin/`;
  return post(uri, JSON.stringify({ username }));
}

export function changePassword(oldPassword, newPassword) {
  const uri = `${API_URL}/changepassword`;
  return fetch(uri, {
    method: "POST",
    body: JSON.stringify({
      oldPassword,
      newPassword,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
}

export function revokeToken() {
  const uri = `${API_URL}/Token/revoke`;
  return post(uri);
}

export function getPatients() {
  const uri = `${API_URL}/Patient`;
  return get(uri);
}
