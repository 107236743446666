import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

class NewPatientDialog extends React.Component {
  state = {
    open: false,
    urgency: "",
    product: "",
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <Button
          variant="contained"
          onClick={this.handleClickOpen}
          className="jr-btn jr-btn-sm text-white bg-cyan"
        >
          NUEVO PACIENTE
          <i className={`zmdi zmdi-account-add zmdi-hc-fw`} />
        </Button>
        <Dialog open={this.state.open} onClose={this.handleRequestClose}>
          <DialogTitle>Nuevo paciente</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Seleccione la información para reportar
            </DialogContentText>
            <form className="row" noValidate autoComplete="off">
              <div className="col-md-6 col-6">
                <FormControl className="w-100">
                  <InputLabel htmlFor="urgency-helper">Urgencia</InputLabel>
                  <Select
                    value={this.state.urgency}
                    onChange={this.handleChange("urgency")}
                    input={<Input id="urgency-helper" />}
                  >
                    <MenuItem value={10}>Alta</MenuItem>
                    <MenuItem value={20}>Normal</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-6 col-6">
                <FormControl className="w-100">
                  <InputLabel htmlFor="product-helper">Medicamento</InputLabel>
                  <Select
                    value={this.state.product}
                    onChange={this.handleChange("product")}
                    input={<Input id="product-helper" />}
                  >
                    <MenuItem value={10}>Remodulin</MenuItem>
                    <MenuItem value={20}>Iclusig</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.handleRequestClose} color="primary">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default NewPatientDialog;
