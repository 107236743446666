import React from "react";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button, Tooltip } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CardBox from "components/CardBox/index";

const TableD = ({
  data,
  handleOpenCRU,
  handleOpenDel,
  setCurrentRowObj,
  handleOpenFamiliar,
  handleOpenTestDisease
}) => {
  const lifeTimeText = IntlGetText("additionalMedications.lifeTime");

  const datatableConfig = {
    textLabels: {
      body: {
        noMatch: "No hay resultados para la búsqueda actual",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordernar por ${column.label}`
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Items por pagina:",
        displayRows: "de"
      },
      toolbar: {
        search: "Busqueda",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtro"
      },
      filter: {
        all: "Todos",
        title: "FILTRO",
        reset: "LIMPIAR"
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas"
      },
      selectedRows: {
        text: "item(s) seleccionados",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas"
      }
    }
  };

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: datatableConfig.textLabels
  };

  const handleClick = (tableMeta) => {
    handleOpenCRU();
    setCurrentRowObj(
      !tableMeta ? null : tableMeta.tableData[tableMeta.rowIndex]
    );
  };
  const openModalFamiliar = (tableMeta) => {
    handleOpenFamiliar();
    setCurrentRowObj(tableMeta.tableData[tableMeta.rowIndex]);
  };
  const openModalTestDisease = (tableMeta) => {
    handleOpenTestDisease();
    setCurrentRowObj(tableMeta.tableData[tableMeta.rowIndex]);
  };

  const columns = [
    {
      name: "diagnosisDate",
      label: IntlGetText("Diagnosis.DiagnosisDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("DD/MM/YYYY") : "";
        }
      }
    },
    {
      name: "diseaseTx",
      label: IntlGetText("Diagnosis.Diagnosis"),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "isConfirmedTx",
      label: IntlGetText("Diagnosis.DiagnosisConfirmed"),
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "orphanDiseaseTx",
      label: IntlGetText("Diagnosis.OrphanDisease"),
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "actions",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "4px"
              }}
            >
              <Tooltip title={"Editar"} placement="bottom-end">
                <Button
                  onClick={() => handleClick(tableMeta)}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className="zmdi zmdi-edit"></i>
                </Button>
              </Tooltip>
              <Tooltip title={"Antecedente familiar del diagnóstico"} placement="bottom-end">
                <Button
                  onClick={() => openModalFamiliar(tableMeta)}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className="zmdi zmdi-accounts"></i>
                </Button>
              </Tooltip>
              <Tooltip title={"Prueba confirmatoria de la enfermedad"} placement="bottom-end">
                <Button
                  onClick={() => openModalTestDisease(tableMeta)}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className="zmdi zmdi-file-text"></i>
                </Button>
              </Tooltip>
            </div>
          );
        }
      }
    }
  ];

  return (
    <div className="row">
      <CardBox styleName="col-12" cardStyle="p-0 border-dialog-card">
        <MUIDataTable
          className="border-dialog-card"
          // title={IntlGetText("additionalMedications.Medications")}
          data={data}
          columns={columns}
          options={options}
        />
      </CardBox>
      <div className="col-lg-12 col-md-12 col-xs-12 mt-4 text-center">
        <Button
          onClick={() => handleClick(null)}
          variant="contained"
          color="primary"
        >
          {<IntlMessages id="core.add" />}
        </Button>
      </div>
    </div>
  );
};

export default TableD;
