import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages from "util/IntlMessages";
import { Grid } from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import DialogAsync from "components/Form/DialogAsync";
import { callApiAsync, postCoordinatorComment } from "api/apiList";

const DlgCoachComment = ({openDlg, closeDialog, callBack, impact, isManager }) =>{ 
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [disabled, setDisabled] = useState(false);
  
  const defaultValues = { comment:"" };

  const validationSchema = {
    comment: yup.string().required(<IntlMessages id="core.fieldRequired" />)
  };

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, errors } = methods;

  const isEditable = (impact) =>{
    if(!isManager)
      return false;

    if(impact && !impact.coordComment)
      return true;

    var isEditable = impact && impact.coordCommentDate && dayjs(impact.coordCommentDate).format("YYYY/MM/DD") === dayjs().format("YYYY/MM/DD");
    return isEditable;
  }

  const loadData=(data)=>{
    setDisabled(!isEditable(data));
    setValue("comment", !data || !data.coordComment ? "" : data.coordComment);
    setValue("coordinator", !data || !data.coordinator ? "" : data.coordinator);
  };

  useEffect(() => {
    if(!openDlg) return;
    setTimeout(() => {
      loadData(impact);
    }, 300);
  }, [openDlg,impact]);

  const onSubmitAsync = handleSubmit(async (d)=>{
    const coordComment = {
      impactId: impact.id,
      comment: d.comment
    };

    await callApiAsync(
      ()=>postCoordinatorComment(coordComment),
      ()=>{
        if(callBack)
          callBack();
        closeDialog();
      },
      fnSetLoader
    );
  });

  return (
    <div>
      <DialogAsync
        open={openDlg}
        closeDialog={closeDialog}
        onSubmitAsync={onSubmitAsync}
        titleTextId={"reports.coordinatorComment"}
        allowEdit={isEditable(impact)}
      >
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              { impact && impact.coordinator && 
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    name="coordinator"
                    label={<IntlMessages id="core.coordinator" />}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
              }
              <Grid item xs={12} md={12} lg={12}>
                <FormInput
                  name="comment"
                  label={<IntlMessages id="core.comment" />}
                  multiline
                  rows={5}
                  variant="outlined"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>            
      </DialogAsync>
    </div>
  );
};

export default DlgCoachComment;