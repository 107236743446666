import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "actions/Base";
import {
  callApi,
  GetPrescriptionsTotalReportFile
} from "api/apiList";
import Table, { defaultOptions, defaultCol, dateColumn, numberCol } from "components/Form/Table";
import { currencyFormat } from "services/formatNumber";
import AppLocale from "lngProvider";

const PrescripctionsTotalReport = ({ dateRange }) => {
  const dispatch = useDispatch();
  const { locale } = useSelector(({ settings }) => settings);
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [reportData, setReportData] = useState([]);

  const currentAppLocale = AppLocale[locale.locale];
  const currentLocale = currentAppLocale.locale;

  useEffect(() => {
    if (!dateRange) return;

    callApi(
      () => GetPrescriptionsTotalReportFile(dateRange.startDate, dateRange.endDate, false),
      (data) => setReportData(data.dataTable),
      fnSetLoader
    );
  }, [dateRange]);

  const columns = [
    defaultCol("mipres", "core.mipres"),
    dateColumn("creationDate", "core.creationDate"),
    defaultCol("causal", "core.causal"),
    defaultCol("classificationId", "foreia.classification"),
    defaultCol("dataState", "core.state"),
    defaultCol("amount", "core.totalAmount"),
    defaultCol("unitValue", "core.unitValue", currentLocale, currencyFormat),
    defaultCol("product", "core.product"),
    defaultCol("agent", "role.callAgent"),
    defaultCol("olf", "reports.marketChain"),
    defaultCol("eps", "core.insurance"),
  ];

  return (
    <>        
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-2 mb-5">
        <Table
          className="border-dialog-card mt-2 mb-2"
          data={reportData}
          columns={columns}
          options={defaultOptions()}
        />
      </div>
    </>
  );
};

export default PrescripctionsTotalReport;
