export const menuItems = [
  {
    id: 0,
    handle: "inbox",
    title: "Nuevos Pacientes",
    icon: "accounts-alt",
  },
  {
    id: 1,
    handle: "sent",
    title: "En Trámite",
    icon: "hourglass-alt",
  },
  {
    id: 2,
    handle: "drafts",
    title: "Seguimiento",
    icon: "folder",
  },
  {
    id: 3,
    handle: "spam",
    title: "Eventos Adversos",
    icon: "alert-octagon",
  },
  {
    id: 4,
    handle: "trash",
    title: "Educación / Eventos",
    icon: "book",
  },
  
];

// export const prescriptions = [
//   {
//     id:1,
//     creationDate:"2020/11/17",
//     productLineId: 5,
//     stateId:0,
//     firstImpactDate:"",
//     doctor: {
//       name:"Dra. Victoria Lopez",
//       email:"victoria.lopez@outlook.com",
//       institution:"Institución",
//       cellphone:"+57-314225458",
//     },
//     patient:{
//       id:1,
//       code: "RE0001",
//       name: "Jorge",
//       lastName: "Perez",
//     }
//   },
//   {
//     id:2,
//     creationDate:"2020/11/17",
//     productLineId: 6,
//     stateId:1,
//     firstImpactDate:"",
//     doctor: {
//       name:"Dra. Victoria Lopez",
//       email:"victoria.lopez@outlook.com",
//       institution:"Institución",
//       cellphone:"+57-314225458",
//     },
//     patient:{
//       id:2,
//       code: "RE0002",
//       name: "Uriel",
//       lastName: "Roa",
//     }
//   }
// ];

// export const productLines = [
//   {
//     id: 5,
//     title: "REMODULIN",
//     color: "pink"
//   },
//   {
//     id: 6,
//     title: "ICLUSIG",
//     color: "blue",
//   }
// ];

// // Tipo de acuerdo al menuItem
// // 0-> paciente nuevo
// // 1-> Trámite
// // 2-> seguimiento (tipo impacto)
// // 3-> Tipo evento adverso (farmacovigilancia/Tecnovigilancia)
// export const prescriptionStates = [
//   {
//     id: 1,
//     handle: "doctorok",
//     title: "Méd. autorizó",
//     color: "green",
//     type: 0,
//   },
//   {
//     id: 2,
//     handle: "pacienteok",
//     title: "Pac. autorizó",
//     color: "green",
//     type: 0,
//   },
//   {
//     id: 3,
//     handle: "first-impact",
//     title: "Primer impacto",
//     color: "light-blue",
//     type: 0,
//   },
//   {
//     id: 4,
//     handle: "formulation",
//     title: "Formulado",
//     color: "green",
//     type: 1,
//   },
//   {
//     id: 5,
//     handle: "formulation-errors",
//     title: "Form. con errores",
//     color: "red",
//     type: 1,
//   },
//   {
//     id: 6,
//     handle: "eps-radication",
//     title: "RAD. EPS",
//     color: "green",
//     type: 1,
//   },
//   {
//     id: 7,
//     handle: "eps-not-effective",
//     title: "Rad. no efectiva",
//     color: "red",
//     type: 1,
//   },

//   {
//     id: 8,
//     handle: "authorized",
//     title: "Autorizado",
//     color: "green",
//     type: 1,
//   },
//   {
//     id: 9,
//     handle: "not-authorized",
//     title: "No autorizado",
//     color: "red",
//     type: 1,
//   },
//   {
//     id: 10,
//     handle: "olf-radication",
//     title: "Rad. EN OLF",
//     color: "green",
//     type: 1,
//   },
//   {
//     id: 11,
//     handle: "olf-not-effective",
//     title: "OLF no efectiva",
//     color: "red",
//     type: 1,
//   },
//   {
//     id: 12,
//     handle: "active",
//     title: "Activo",
//     color: "green",
//     type: 1,
//   },
//   {
//     id: 13,
//     handle: "visit-ih",
//     title: "Visita intrahospitalaria",
//     color: "green lighten-2",
//     type: 2,
//   },
//   {
//     id: 14,
//     handle: "home-visit",
//     title: "Visita domiciliaria",
//     color: "green lighten-2",
//     type: 2,
//   },
//   {
//     id: 15,
//     handle: "follow-up",
//     title: "Llamada seguimiento",
//     color: "green lighten-2",
//     type: 2,
//   },
//   {
//     id: 16,
//     handle: "company-appointment",
//     title: "ACOMP. CITA",
//     color: "green lighten-2",
//     type: 2,
//   },
//   {
//     id: 17,
//     handle: "query",
//     title: "Farmacovigilancia",
//     color: "amber",
//     type: 3,
//   },
//   {
//     id: 18,
//     handle: "query",
//     title: "Tecnovigilancia",
//     color: "deep-purple",
//     type: 3,
//   },
// ];
