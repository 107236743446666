import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button, Tooltip } from "@material-ui/core";
import {
  callApi,
  getImpactsReportFile
} from "api/apiList";
import Table, { defaultOptions, defaultCol, dateColumn } from "components/Form/Table";
import { RoleEnum } from 'constants/Enums';
import { isRole } from "app/routes/psp/services/userService";
import DlgCoachComment from "../components/dlgCoachComment";

const ImpactsReport = ({ dateRange }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [reportData, setReportData] = useState([]);
  const [currentImpact, setCurrentImpact] = useState(null);
  const [openDlgComment, setOpenDlgComment] = useState(false);
  
  const isManager = isRole(RoleEnum.callAgentManager);

  const loadTable = () => {
    callApi(
      () => getImpactsReportFile(dateRange.startDate, dateRange.endDate, false),
      (data) => setReportData(data.dataTable),
      fnSetLoader
    );
  };

  useEffect(() => {
    if (!dateRange) return;

    loadTable();
  }, [dateRange]);

  const getObj = (tableMeta) =>
    reportData.find((x) => x.id === tableMeta.rowData[0]);

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("nurse", "role.callAgent"),
    defaultCol("marketchain", "reports.marketChain"),
    defaultCol("city", "core.city"),
    defaultCol("patientDocument", "core.patient"),
    defaultCol("prescriptionCode", "core.mipres"),
    defaultCol("impactType", "impact.ImpactType"),
    dateColumn("startDate", "core.startDate"),
    dateColumn("endDate", "core.endDate"),
    defaultCol("nursingNote", "core.comment"),
    {
      name: "actions",
      label: IntlGetText("table.coordinatorComment"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          if(!isManager && !rowObj.coordComment)
            return "";

          var tooltipTitle = "reports.coordinatorComment";
          var isEditable = rowObj.coordCommentDate && dayjs(rowObj.coordCommentDate).format("YYYY/MM/DD") === dayjs().format("YYYY/MM/DD");
          var bottonIcon = isManager ? (!rowObj.coordComment ? "zmdi zmdi-plus" : ( isEditable ? "zmdi zmdi-edit" : "zmdi zmdi-eye")) : "zmdi zmdi-eye";// "zmdi zmdi-plus" 
            
          return (
            <div>
              <Tooltip title={<IntlMessages id={tooltipTitle} />} 
                placement="bottom-end">
                <Button
                  onClick={() => {
                    setCurrentImpact(rowObj);
                    setOpenDlgComment(true);
                  }}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className={bottonIcon}></i>
                </Button>
              </Tooltip>
            </div>
          );
        },
      },
    }
  ];

  return (
    <>
      <DlgCoachComment 
        openDlg={openDlgComment} 
        closeDialog={()=>{
          setCurrentImpact(null);
          setOpenDlgComment(false);
        }}
        callBack={loadTable}
        impact={currentImpact}
        isManager={isManager}/>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-2 mb-5">
        <Table
          className="border-dialog-card mt-2 mb-2"
          data={reportData}
          columns={columns}
          options={defaultOptions()}
        />
      </div>
    </>
  );
};

export default ImpactsReport;
