import React from "react";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CardBox from "components/CardBox/index";

const TableFamiliar = ({ data, handleOpenDel, setCurrentRowObj }) => {
  const datatableConfig = {
    textLabels: {
      body: {
        noMatch: "No hay resultados para la búsqueda actual",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordernar por ${column.label}`
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Items por pagina:",
        displayRows: "de"
      },
      toolbar: {
        search: "Busqueda",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtro"
      },
      filter: {
        all: "Todos",
        title: "FILTRO",
        reset: "LIMPIAR"
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas"
      },
      selectedRows: {
        text: "item(s) seleccionados",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas"
      }
    }
  };

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: datatableConfig.textLabels
  };

  const handleClickDelete = (tableMeta) => {
    handleOpenDel();
    setCurrentRowObj(tableMeta.tableData[tableMeta.rowIndex]);
  };

  const columns = [
    {
      name: "familyHistoryTx",
      label: IntlGetText("Diagnosis.Familiar"),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "actions",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Button
                onClick={() => handleClickDelete(tableMeta)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-delete"></i>
              </Button>
            </div>
          );
        }
      }
    }
  ];

  return (
    <div className="row">
      <CardBox styleName="col-12" cardStyle="p-0 border-dialog-card">
        <MUIDataTable
          className="border-dialog-card"
          data={data}
          columns={columns}
          options={options}
        />
      </CardBox>
    </div>
  );
};

export default TableFamiliar;
