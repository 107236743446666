import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import Dlg from "./Dlg";
import FormAdd from "./FormAdd";
import Table from "./Table";
import { callActionApi, getClinicalFinding } from "api/apiList";
import { callStartImpact } from "actions/Impact";

const existItemsInImpact = (impactId, dataList) =>
  dataList.find((x) => x.impactId == impactId) != undefined;

const ClinicalFinding = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentSigned, isCIRequired } = useSelector(({ impact }) => impact);

  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const [open, setOpen] = useState(false);
  const [clinicalFindingList, setClinicalFindingList] = useState([]);
  const [openCRU, setOpenCRU] = useState(false);
  const [openDlgAdd, setOpenDlgAdd] = useState(false);
  const [currentRowObj, setCurrentRowObj] = useState({});

  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    if (isCIRequired &&  !consentSigned) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }
    setOpen(true);
  };

  const callApi = (apiFunc, setData) => {
    callActionApi(
      apiFunc,
      setData,
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  };

  const loadData = () => {
    callApi(
      () => getClinicalFinding(selectedPrescription.id),
      (data) => {
        setClinicalFindingList(data);
      }
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleRequestClose = () => setOpen(false);
  const handleOpenCRU = () => setOpenCRU(true);
  const handleRequestCloseCRU = () => setOpenCRU(false);

  const generateAdverseEventReload = (clinicalFindingId) => {
    callApi(
      () => getClinicalFinding(selectedPrescription.id),
      (data) => {
        setClinicalFindingList(data);
        setCurrentRowObj(data.find((x) => x.id == clinicalFindingId));
      }
    );
  };

  return (
    <div>
      <InfoCard
        data={{
          icon: "alert-triangle",
          title: IntlGetText("clinicalFinding.cardTitle"),
          subTitle: IntlGetText("clinicalFinding.cardSubTitle"),
          color: existItemsInImpact(
            selectedPrescription.impactId,
            clinicalFindingList
          )
            ? "#20c997"
            : "#757575",
        }}
        onClickBox={handleClickOpen}
      />

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="core.clinicalFinding" />
        </DialogTitle>
        <DialogContent>
          <Table
            data={clinicalFindingList}
            handleOpenCRU={handleOpenCRU}
            handleOpenDlgAdd={() => setOpenDlgAdd(true)}
            setCurrentRowObj={setCurrentRowObj}
            currentImpact={selectedPrescription.impactId}
            readOnly={false}
          />
          <FormAdd
            impactId={selectedPrescription.impactId}
            openDlgAdd={openDlgAdd}
            handleRequestClose={() => setOpenDlgAdd(false)}
            reloadData={loadData}
            selectedPrescription={selectedPrescription}
          />          
          <Dlg
            rowObj={currentRowObj}
            impactId={selectedPrescription.impactId}
            customProductBehaviorId={selectedPrescription.customProductBehaviorId}
            openCRU={openCRU}
            handleRequestClose={handleRequestCloseCRU}
            readOnly={false}
            reloadData={loadData}
            generateAdverseEventReload={generateAdverseEventReload}
            selectedPrescription={selectedPrescription}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClinicalFinding;
