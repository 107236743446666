import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import * as dayjs from "dayjs";
import {
  callApi,
  getMoriskyExtended,
  getMoriskyExtendedReport,
} from "api/apiList";
import { setLoader } from "actions/Base";
import { Button } from "@material-ui/core";
import { downloadFile } from "services/fileService";
import Table, { defaultOptions, defaultCol } from "components/Form/Table";

function MoriskyInfo(props) {
  const { dateRange } = props;
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [data, setData] = useState([]);

  const downloadReport = () => {
    callApi(
      () => getMoriskyExtendedReport(dateRange.startDate, dateRange.endDate),
      (data) => downloadFile(data),
      fnSetLoader
    );
  };
  useEffect(() => {
    if (!dateRange) return;

    callApi(
      () => getMoriskyExtended(dateRange.startDate, dateRange.endDate),
      (data) => setData(data),
      fnSetLoader
    );
  }, [dateRange]);

  const getObj = (tableMeta) =>
    data.find((x) => x.patientCode === tableMeta.rowData[0]);

  const columns = [
    {
      name: "patientCode",
      label: IntlGetText("core.patient"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return (
            <Link
              to={{
                pathname: "/app/patient/detail",
                prescriptionId: rowObj.prescriptionId,
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    {
      name: "impactDate",
      label: IntlGetText("core.date"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    defaultCol("nurse", "core.nurse"),
    defaultCol("doctor", "core.doctor"),
    defaultCol("eps", "core.insurance"),
    defaultCol("regime", "core.regime"),
    defaultCol("olf", "insuranceAuthorization.marketChain"),
    defaultCol("patology", "core.patology"),
    defaultCol("city", "core.city"),
    defaultCol("prescribedDosage", "core.prescribedDosage"),
    defaultCol("currentDosage", "core.currentDosage"),
    {
      name: "question",
      label: IntlGetText("core.question"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const key = "morisky.question" + value;
          return <IntlMessages id={key} />;
        },
      },
    },
    defaultCol("answerScore", "core.answer"),
    defaultCol("result", "morisKySurvey.result"),
    defaultCol("comment", "core.comment"),
  ];

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-2 mb-5">
      <div className="text-left mt-5 mb-4">
        <Button
          onClick={downloadReport}
          variant="contained"
          color="secondary"
          disabled={dateRange === null}
        >
          <IntlMessages id="core.download" />
        </Button>
      </div>
      <div className="mt-5 mb-5">
        <Table
          title={IntlGetText("psp.menu.moriskyInfo")}
          className="border-dialog-card col-12"
          data={data}
          columns={columns}
          options={defaultOptions()}
        />
      </div>
    </div>
  );
}

export default MoriskyInfo;
