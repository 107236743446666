import React from "react";
import { Bar, BarChart, ResponsiveContainer } from "recharts";

import {
  eventsInProgress,
  newEvents,
  chartData,
  suspensionPatients,
  newArticlesData,
} from "./data";

import ReportBox from "components/ReportBox/index";
import ContainerHeader from "components/ContainerHeader/index";
import CardHeader from "components/dashboard/Common/CardHeader/index";
import UserDetailTable from "components/dashboard/Common/UserDetailTable";
import IntlMessages from "util/IntlMessages";
import YearlyProfitChart from "components/dashboard/eCommerce/YearlyProfitChart";
import CustomLineChart from "components/CustomLineChart/index";

const Pharmacovigilant = (props) => {
  return (
    <div className="app-wrapper">
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <ContainerHeader
          match={props.match}
          title={<IntlMessages id="psp.home.title" />}
        />

        <div className="row">
          <div className="col-lg-4 col-sm-6 col-12">
            <ReportBox
              styleName="bg-light-green text-white p-3"
              icon="accounts-alt"
              price="3"
              detail={<IntlMessages id="psp.home.newAdverseEvents" />}
              subHeadingColor="text-white"
            >
              <YearlyProfitChart
                chartId="pharmapie"
                centerText="15"
                height={100}
                chartType="newsDoughnut"
                backgroundColor={["#607d8b", "#FFF"]}
                borderColor={["#607d8b", "#FFF"]}
                hoverBorderColor={["#607d8b", "#FFF"]}
                hoverBorderWidth={[2, 2]}
                textColor="#fff"
                rotation={50}
                labels={["Nuevos", "Existentes"]}
                dataControl={[3, 12]}
                fontSize={1.4}
              />
            </ReportBox>
          </div>

          <div className="col-lg-4 col-sm-6 col-12">
            <ReportBox
              styleName="bg-amber text-white p-3"
              price="9"
              icon="hourglass-alt"
              detail={<IntlMessages id="psp.home.ongoingAdverseEvents" />}
              subHeadingColor="text-white"
            >
              <ResponsiveContainer width="100%" height={100}>
                <BarChart
                  data={chartData}
                  maxBarSize={4}
                  margin={{ left: 0, right: 0, top: 10, bottom: 10 }}
                >
                  <Bar dataKey="amt" fill="white" />
                </BarChart>
              </ResponsiveContainer>
            </ReportBox>
          </div>

          <div className="col-lg-4 col-sm-6 col-12">
            <ReportBox
              styleName="bg-pink text-white p-3"
              icon="folder"
              price="5"
              detail={<IntlMessages id="psp.home.suspensionPatients" />}
              subHeadingColor="text-white"
            >
              <CustomLineChart
                chartData={newArticlesData.chartData}
                labels={newArticlesData.labels}
                borderColor="#FFF"
                borderWidth={3}
                pointBackgroundColor="#FFF"
                pointBorderWidth={2}
                pointRadius={0}
                height={100}
                pointHoverBorderColor="#F29100"
                lineTension={0}
                gridLinesDisplay={true}
                gridLineWidth={3}
                shadowColor="rgba(0,0,0,0.6)"
              />
            </ReportBox>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-lg-5 col-12">
            <div className="jr-card jr-full-card">
              <CardHeader
                heading={<IntlMessages id="psp.home.eventsTableTitle" />}
              />

              <div className="jr-card-body">
                <UserDetailTable data={newEvents} />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-5 col-12">
            <div className="jr-card jr-full-card">
              <CardHeader
                heading={<IntlMessages id="psp.home.eventsInProgressTitle" />}
              />

              <div className="jr-card-body">
                <UserDetailTable data={eventsInProgress} />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-5 col-12">
            <div className="jr-card jr-full-card">
              <CardHeader
                heading={<IntlMessages id="psp.home.suspensionPatientsTitle" />}
              />

              <div className="jr-card-body">
                <UserDetailTable data={suspensionPatients} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pharmacovigilant;
