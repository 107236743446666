import React, { useState } from "react";
import InfoCard from "components/InfoCard/index";
import { IntlGetText } from "util/IntlMessages";
import NextFollowUpForm from "../modalForm/nextFollowUp";
import { NotificationManager } from "react-notifications";

const NextFollowUp = (props) => {
  const [openDlgPatient, setOpenDlgPatient] = useState(false);
  const { allowPlanning, isInstitutionManager } = props;

  if(!allowPlanning)
    return <></>;

  return (
    <>
      <InfoCard
        data={{
          icon: "calendar",
          title: IntlGetText("institution.nextFollowUp"),
          /*   color:
            !eventTrainingInfo || !eventTrainingInfo.comment
              ? "#757575"
              : "#20c997", */
          color: "#60c1d7",
        }}
        onClickBox={() => {
          if(isInstitutionManager) 
            return;
          setOpenDlgPatient(true);
        }}
      />

      <NextFollowUpForm
        openDlg={openDlgPatient}
        setOpenDlg={setOpenDlgPatient}
        closeDialog={() => setOpenDlgPatient(false)}
        {...props}
      />
    </>
  );
};

export default NextFollowUp;
