import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { NotificationManager } from "react-notifications";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  callApiAsync,
  callActionApi,
  getAdverseEventTypeList,
  postGenerateAdverseEvent
} from "api/apiList";
import { ProductBehavior, AdverseEventTypeEnum } from "constants/Enums";


const DialogAdd = ({ open, dialogTitle, callBack, closeDialog }) => {
  const [adverseEventTypeList, setAdverseEventTypeList] = useState([]);

  const defaultValues = { adverseEventTypeId: null };
  const validationSchema = yup.object().shape({
    adverseEventTypeId: yup .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable()
  });
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, errors, reset } = methods;

  useEffect(()=>{
    callActionApi(
      getAdverseEventTypeList,
      setAdverseEventTypeList,
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  },[])

  const onSubmit = async (data, e) => {
    await callBack(Number(data.adverseEventTypeId.value));
  };

  const handleRequestClose = () => {
    reset(defaultValues);
    closeDialog();
  };
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent className="new-patient-dialog-content">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid item xs={12} md={12} lg={12}>
                <FormSelectAutoComplete
                    name="adverseEventTypeId"
                    label={<IntlMessages id="core.type" />}
                    options={adverseEventTypeList}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                />
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          {<IntlMessages id="core.cancel" />}
        </Button>
        <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
          {<IntlMessages id="core.save" />}
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  );
};

const AddAdverseEvent = ({ obj, generateAdverseEventReload, customProductBehaviorId }) =>{  
  const [openDlg, setOpenDlg] = useState(false);

  const callBack = async (adverseEventTypeId) => {
    var item = {
      clinicalFindingId: obj.id,
      adverseEventTypeId
    };
    await callApiAsync(
      ()=>postGenerateAdverseEvent(item),
      ()=>generateAdverseEventReload(obj.id)
    );
  };

  const onClickHandler = async () => {
    if(customProductBehaviorId === ProductBehavior.remodulin || customProductBehaviorId === ProductBehavior.dacepton){
      setOpenDlg(true);
      return;
    }
    await callBack(AdverseEventTypeEnum.pharmacovigilance);
  };

  return (
    <div>
      <div className="text-right mt-1 mb-1">
      <ButtonAsync
        onClick={onClickHandler}
        variant="contained"
        color="primary"
        className="ml-2"
      >
        {<IntlMessages id="clinicalFindingState.btnAdverseEvent" />}
      </ButtonAsync>
      </div>
      <DialogAdd
        open={openDlg}
        dialogTitle={<IntlMessages id="clinicalFindingState.btnAdverseEvent" />}
        callBack={callBack}
        closeDialog={()=>setOpenDlg(false)}
      />
    </div>
  );
};

export default AddAdverseEvent;