import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoCard from "components/InfoCard/index";

class FormulaDialog extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <InfoCard
          data={{
            icon: "file-text",
            title: "Formula",
            subTitle: "Médica",
            color: "#3f51b5",
          }}
          onClickBox={this.handleClickOpen}
        />
        <Dialog
          open={this.state.open}
          maxWidth="md"
          onClose={this.handleRequestClose}
        >
          <DialogTitle>Fórmula Médica</DialogTitle>
          <DialogContent>
            <form className="row" noValidate autoComplete="off">
              <div className="col-md-6 col-12">
                <TextField
                  required
                  id="date"
                  label="Departamento"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-6 col-12">
                <TextField
                  required
                  id="hour"
                  label="Municipio"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-6 col-12">
                <TextField
                  required
                  multiline
                  id="comment"
                  label="Fecha de Expedición"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-6 col-12">
                <TextField
                  multiline
                  id="comment"
                  label="Nro. Prescripción"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-12 col-12">
                <TextField
                  multiline
                  id="comment"
                  label="Nombre del prestador"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-6 col-12">
                <TextField
                  multiline
                  id="comment"
                  label="Ámbito de atención"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-6 col-12">
                <TextField
                  required
                  multiline
                  id="comment"
                  label="Tipo de prestación"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-12 col-12">
                <TextField
                  required
                  multiline
                  id="comment"
                  label="Nombre del medicamento"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-4 col-12">
                <TextField
                  required
                  multiline
                  id="comment"
                  label="Dosis"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-4 col-12">
                <TextField
                  required
                  multiline
                  id="comment"
                  label="Unidad"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-4 col-12">
                <TextField
                  required
                  multiline
                  id="comment"
                  label="Vía"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-6 col-12">
                <TextField
                  required
                  multiline
                  id="comment"
                  label="Frecuencia"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-6 col-12 text-grey">
                <FormControlLabel
                  className="mt-4"
                  control={<Checkbox color="primary" value="checkedB" />}
                  label="Continua"
                />
              </div>
              <div className="col-md-12 col-12">
                <TextField
                  required
                  multiline
                  id="comment"
                  label="Duración del tratamiento"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-6 col-12">
                <TextField
                  required
                  multiline
                  id="comment"
                  label="Cantidad Farmacéutica"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-6 col-12">
                <TextField
                  required
                  multiline
                  id="comment"
                  label="Unidad Farmacéutica"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-12 col-12">
                <TextField
                  required
                  multiline
                  id="comment"
                  label="Médico"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-12 col-12">
                <TextField
                  required
                  multiline
                  id="comment"
                  label="Especialidad"
                  margin="normal"
                  fullWidth
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.handleRequestClose} color="primary">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default FormulaDialog;
