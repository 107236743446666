import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import IntlMessages from "util/IntlMessages";
import { Tabs, Tab } from "@material-ui/core";
import QueryAdverseEvent from "components/AdverseEvent/QueryAdverseEvent";
import Pharmacovigilance from "./Pharmacovigilance";
import Technovigilance from "../../../../../components/AdverseEvent/Technovigilance";
import { setLoader } from "actions/Base";
import {
  callApi,
  getAdverseEventGetDetail,
  getQueryAdverseEventList
} from "api/apiList";
import { AdverseEventTypeEnum } from "constants/Enums";

const AdverseEventDetail = ({ prescription }) =>{ 
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));

  const [currentTab, setCurrentTab] = useState(0);
  const [adverseEvent, setAdverseEvent] = useState(null);
  const [queryAdverseEventList,setQueryAdverseEventList] = useState([]);

  const reloadQueries = () => {
    callApi(
      ()=>getQueryAdverseEventList(prescription.adverseEvent.id),
      setQueryAdverseEventList
    );
  };

  const reloadAdverseEvent = () => {
    callApi(
      ()=>getAdverseEventGetDetail(prescription.adverseEvent.id),
      setAdverseEvent,
      fnSetLoader
    );
  };

  useEffect(()=>{
    if(prescription){
      reloadAdverseEvent();
      reloadQueries();
    }
  },[prescription]);

  const handleChange = (event, value) => setCurrentTab(value);

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
    <Tabs          
        value={currentTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
      <Tab
        className="jr-tabs-label"
        label={<IntlMessages id="adverseEventDetail.tabForm" />}
      />
      { queryAdverseEventList.length !== 0 && 
        <Tab
          className="jr-tabs-label"
          label={<IntlMessages id="adverseEventDetail.tabQuery" />}
        />
      }
    </Tabs>
      <div className="jr-tabs-content jr-task-list">
        <div className="row">
          {currentTab === 0 && (
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="mt-2">
                { adverseEvent && adverseEvent.adverseEventTypeId === AdverseEventTypeEnum.pharmacovigilance &&
                  <Pharmacovigilance 
                    adverseEvent={adverseEvent} 
                    reloadAdverseEvent={reloadAdverseEvent}/>
                }
                { adverseEvent && adverseEvent.adverseEventTypeId === AdverseEventTypeEnum.technovigilance &&
                  <Technovigilance obj={adverseEvent}/>
                }
              </div>
            </div>
          )}
          {currentTab === 1 && (
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="mt-2">      
                <QueryAdverseEvent 
                  adverseEvent={adverseEvent}
                  queryAdverseEventList={queryAdverseEventList}
                  reloadQueries={()=>{
                    reloadAdverseEvent();
                    reloadQueries();
                  }}
                  />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>      
  );
};

export default AdverseEventDetail;