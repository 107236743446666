import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Grid } from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete, { getCboValue } from "components/Form/Controls/select-autocomplete";
import FormCheckbox from "components/Form/Controls/checkbox";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { setLoader } from "actions/Base";
import {
  callApi,
  callApiAsync,
  getAdverseEventByClinicalFinding,
  postAdverseEventPartialSave
} from "api/apiList";
import {  
  ProductBehavior, 
  FormulaType,
  ForeamSeriousnessList,
  ForeamDenouementList,
  YesNoNotKnowList,
  YesNoNAList,
  AdverseEventStateEnum
} from "constants/Enums";

const GetQuestionYesNo = () =>{
  const questionKeys = [1, 2, 3, 4, 5];
  return questionKeys.map(x => ({
    id: x,
    answer: null,
    name: "questionYesNo"+x,
    label: IntlGetText("foream.questionYesNo.q"+x)
  }));
};

const getReactionList = () =>{
  const reactionKeys = [1, 2, 3, 4, 5, 6];
  return reactionKeys.map(x => ({
    id: x,
    answer: false,
    name: "reaction"+x,
    label: IntlGetText("cioms.reaction"+x)
  }));
};

const Farmacovigilance = ({obj}) =>{ 
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));

  const foreamSeriousnessList = ForeamSeriousnessList();
  const foreamDenouementList = ForeamDenouementList();
  const yesNoNotKnowList = YesNoNotKnowList();
  const yesNoNAList = YesNoNAList();

  const questionsYesNo = GetQuestionYesNo();
  const reactionList = getReactionList();

  const [adverseEvent, setAdverseEvent] = useState(null);

  const msgSuccess = IntlGetText("core.SuccessSave");

  let defaultValues = {    
    creationDate: null,
    adverseEventType: "",
    dosage: "",
    infusionRate: "",
    supplyFrequency: "",
    adverseEventStartDate: null,
    descriptionAdverseEvent: "",
    denouement: null,
    seriousness: null,
    //questionsYesNo:
    //adverseReaction:
    reactionDecreased:null,
    reactionReappeared:null,
    anotherRelevantStory:""
  };

  questionsYesNo.map((x)=>{
    defaultValues[x.name] = x.answer;
  });
  reactionList.map((x)=>{
    defaultValues[x.name] = x.answer;
  });

  const methods = useForm({ defaultValues });
  const { handleSubmit, setValue, errors } = methods;
  

  const loadData = (adverseEvent) => {
    setAdverseEvent(adverseEvent);
    setValue("creationDate", !adverseEvent || !adverseEvent.creationDate ? null : dayjs(adverseEvent.creationDate));
    setValue("adverseEventType", !adverseEvent || !adverseEvent.adverseEventType? "" : adverseEvent.adverseEventType);
    setValue("dosage", !adverseEvent || !adverseEvent.dosage ? "" : adverseEvent.dosage);
    setValue("infusionRate", !adverseEvent || !adverseEvent.infusionRate ? "" : adverseEvent.infusionRate);
    setValue("supplyFrequency", !adverseEvent || !adverseEvent.supplyFrequency ? "" : adverseEvent.supplyFrequency);

    setValue("adverseEventStartDate", !adverseEvent || !adverseEvent.adverseEventStartDate ? null : dayjs(adverseEvent.adverseEventStartDate));
    setValue("descriptionAdverseEvent", !adverseEvent || !adverseEvent.descriptionAdverseEvent ? "" : adverseEvent.descriptionAdverseEvent);
    
    const pharmacovigilance = !adverseEvent ? null : adverseEvent.pharmacovigilance;
    setValue("denouement", !pharmacovigilance ? "" : getCboValue(foreamDenouementList, pharmacovigilance.denouement));
    setValue("seriousness", !pharmacovigilance ? "" : getCboValue(foreamSeriousnessList, pharmacovigilance.seriousness));
    if(pharmacovigilance && pharmacovigilance.questionsYesNo){
      pharmacovigilance.questionsYesNo.map((x)=>{
        setValue("questionYesNo"+x.id, getCboValue(yesNoNotKnowList, x.answer));
      });
    }
    if(pharmacovigilance && pharmacovigilance.adverseReaction){
      pharmacovigilance.adverseReaction.map((x)=>{
        setValue("reaction"+x, true);
      });
    }
    setValue("reactionDecreased", !pharmacovigilance ? "" : getCboValue(yesNoNAList, pharmacovigilance.reactionDecreased));
    setValue("reactionReappeared", !pharmacovigilance ? "" : getCboValue(yesNoNAList, pharmacovigilance.reactionReappeared));
    setValue("anotherRelevantStory", !pharmacovigilance || !pharmacovigilance.anotherRelevantStory ? "" : pharmacovigilance.anotherRelevantStory);
  };

  useEffect(()=>{
    if(obj && obj.adverseEvent){
      callApi(
        ()=>getAdverseEventByClinicalFinding(obj.id),
        (data)=>loadData(data)
      );
    }
  },[obj]);

  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);

  const onSubmit = async (d, e) => {
    let result0 = !adverseEvent ? {} : {...adverseEvent};
    var result = {      
      ...result0,
      adverseEventType: "",
      dosage: "",
      infusionRate: "",
      supplyFrequency: "",
      adverseEventStartDate: !d.adverseEventStartDate ? null : dayjs(d.adverseEventStartDate).format("YYYY-MM-DDTHH:mm:ss"),
      descriptionAdverseEvent: d.descriptionAdverseEvent      
    };
    result.pharmacovigilance.denouement = !d.denouement ? null : Number(d.denouement.value);
    result.pharmacovigilance.seriousness = !d.seriousness ? null : Number(d.seriousness.value);
    result.pharmacovigilance.reactionDecreased =  !d.reactionDecreased ? null : Number(d.reactionDecreased.value);
    result.pharmacovigilance.reactionReappeared = !d.reactionReappeared ? null : Number(d.reactionReappeared.value);
    result.pharmacovigilance.anotherRelevantStory = d.anotherRelevantStory;    

    const questionsYesNoAnswered = questionsYesNo.map((x)=>({
      id: x.id,
      answer: !d[x.name] ? null : Number(d[x.name].value)
    }));
    result.pharmacovigilance.questionsYesNo = questionsYesNoAnswered.filter(x=>x.answer !== null);

    const reactionsSelected = reactionList.map((x)=>{
      if(d[x.name])
        return x.id;
    });
    result.pharmacovigilance.adverseReaction = reactionsSelected.filter(x=>x !== undefined);

    await callApiAsync(
      ()=> postAdverseEventPartialSave(result),
      ()=> NotificationManager.success(msgSuccess),
      fnSetLoader
    );
  };

  const disabled = !(adverseEvent && (adverseEvent.status === AdverseEventStateEnum.pending || adverseEvent.status === AdverseEventStateEnum.inconsistentReport));

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
      <FormProvider {...methods}>
        <form className="row" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <FormDatePicker
                name="creationDate"
                label={<IntlMessages id="core.date" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="adverseEventType"
                label={<IntlMessages id="core.type" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="dosage"
                label={<IntlMessages id="core.dosage" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            {(!obj || obj.productBehaviorId === ProductBehavior.remodulin || obj.productBehaviorId === ProductBehavior.dacepton ) &&
              <Grid item xs={12} md={6} lg={6}>
                <FormInput
                  name="infusionRate"
                  label={<IntlMessages id="formula.infusionRate" />}
                  variant="outlined"
                  margin="normal"
                  disabled={true}
                />
              </Grid>
            }
            { (!obj || (obj.formulaType === FormulaType.MIPRES &&
              obj.productBehaviorId !== ProductBehavior.remodulin &&
              obj.productBehaviorId !== ProductBehavior.dacepton)) &&
              <Grid item xs={12} md={6} lg={6}>
                <FormInput
                  name="supplyFrequency"
                  label={<IntlMessages id="formula.supplyFrequency" />}
                  variant="outlined"
                  margin="normal"
                  disabled={true}
                />
              </Grid>
            }
            <Grid item xs={12} md={6} lg={6}>
              <FormDatePicker
                name="adverseEventStartDate"
                label={<IntlMessages id="adverseEvent.adverseEventStartDate" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="descriptionAdverseEvent"
                label={<IntlMessages id="adverseEvent.descriptionFOREAM" />}
                variant="outlined"
                multiline
                rows={4}
                margin="normal"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="denouement"
                label={<IntlMessages id="foream.denouement" />}
                options={foreamDenouementList}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="seriousness"
                label={<IntlMessages id="foream.seriousness" />}
                options={foreamSeriousnessList}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
           
            {questionsYesNo.map((x, index) => {
              return (
                <Grid key={index} item xs={12} md={6} lg={6}>
                  <FormSelectAutoComplete
                    name={x.name}
                    label={x.label}
                    options={yesNoNotKnowList}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
              );
            })}
             <Grid item xs={12} md={12} lg={12}>
            </Grid>
            {reactionList.map((x, index) => {
              return (
                <Grid key={index} item xs={12} md={6} lg={6}>
                  <FormCheckbox
                    name={x.name}
                    label={x.label}
                    onChange={chkHandleChange(x.name)}
                    disabled={disabled}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="reactionDecreased"
                label={<IntlMessages id="cioms.reactionDecreased" />}
                options={yesNoNAList}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="reactionReappeared"
                label={<IntlMessages id="cioms.reactionReappeared" />}
                options={yesNoNAList}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="anotherRelevantStory"
                label={<IntlMessages id="cioms.anotherRelevantStory" />}
                variant="outlined"
                multiline
                rows={4}
                margin="normal"
                disabled={disabled}
              />
            </Grid> 
          </Grid>
        </form>
      </FormProvider>
      {!disabled && (
        <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
          <ButtonAsync onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
          >
            {<IntlMessages id="core.save" />}
          </ButtonAsync>
        </div>
      )}
    </div>      
  );
};

export default Farmacovigilance