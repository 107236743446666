import React, { useState } from "react";
import CardMenu from "../CardMenu";

const CardHeader = (props) => {
  const [menuState, setMenuState] = useState(false);

  const handleRequestClose = () => {
    setMenuState(false);
  };

  const { heading, subHeading } = props;
  let { styleName } = props;

  return (
    <div className={`jr-card-header d-flex align-items-start ${styleName}`}>
      <div className="mr-auto">
        <h3 className="card-heading">{heading}</h3>
        {subHeading && <p className="sub-heading">{subHeading}</p>}
      </div>

      <CardMenu
        menuState={menuState}
        handleRequestClose={handleRequestClose}
      />
    </div>
  );
};

export default CardHeader;
CardHeader.defaultProps = {
  styleName: "",
  subHeading: "",
};
