import React, { useState } from "react";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import Form from "./Form";
import Table, { getTextLabels } from "components/Form/Table";
import {
  callApiAsync,
  postConcomitantMedicationSave
} from "api/apiList";
import { SciList } from "constants/Enums";

const Farmacovigilance = ({readOnly, concomitantMedicationList, loadConcomitants}) =>{ 
  const [currentRowObj, setCurrentRowObj] = useState(null);
  const [openCRU, setOpenCRU] = useState(false);

  const sciList = SciList();
  const handleRequestCloseCRU = () => setOpenCRU(false);

  const msgLifeTime = IntlGetText("additionalMedications.lifeTime");
  
  const handleCreateUpdateAsync = async (data) => {
    await callApiAsync(
      () => postConcomitantMedicationSave(data),
      loadConcomitants
    );
  };

  const getObj = (tableMeta) => concomitantMedicationList.find(x=>x.id === tableMeta.rowData[0]);

  const handleClick = (tableMeta) => {
    setOpenCRU(true);
    setCurrentRowObj(!tableMeta ? null : getObj(tableMeta));
  };

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: getTextLabels(),
    pagination:false
  };


  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display:"excluded"
      }
    },
    {
      name: "sci",
      label: IntlGetText("concomitantMedication.sci"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowObj = getObj(tableMeta);
          const sci = sciList.find(x=>x.id == value); 
          return !sci ? "" : sci.name;
        }
      }
    },
    {
      name: "medicine",
      label: IntlGetText("core.activePrinciple"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "indication",
      label: IntlGetText("concomitantMedication.indication"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "dosage",
      label: IntlGetText("core.dosage"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return rowObj.measurementUnit ? value + " " + rowObj.measurementUnit : value;
        }
      }
    },
    {
      name: "administrationRoute",
      label: IntlGetText("formula.administrationRoute"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "supplyFrequency",
      label: IntlGetText("formula.supplyFrequency"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "startDate",
      label: IntlGetText("additionalMedications.treatmentStartDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    {
      name: "endDate",
      label: IntlGetText("additionalMedications.treatmentEndDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          if(rowObj.lifeTime)
            return msgLifeTime;
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (readOnly) {
            return (
              <div>
                <Button
                  onClick={() => handleClick(tableMeta)}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className="zmdi zmdi-eye"></i>
                </Button>
              </div>
            );
          }

          return (
            <div>
              <Button
                onClick={() => handleClick(tableMeta)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-edit"></i>
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="mt-5 text-uppercase text-grey">
        <h3><IntlMessages id="additionalMedications.dialogTitle" /></h3>
      </div>
      <Form
        rowObj={currentRowObj}
        openCRU={openCRU}
        handleRequestClose={handleRequestCloseCRU}
        handleCreateUpdateAsync={handleCreateUpdateAsync}
        readOnly={readOnly}
      />
      <Table
        className="border-dialog-card mt-2 mb-2"
        data={concomitantMedicationList}
        columns={columns}
        options={options} />
    </React.Fragment>   
  );
};

export default Farmacovigilance