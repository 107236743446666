import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { NotificationManager } from "react-notifications";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormInput from "components/Form/Controls/input";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { callApiAsync, postGenerateAlert } from "api/apiList";
import { PrescripStateEnum } from "constants/Enums";
import { setPrescriptionState } from "actions/Management";
import { callReloadAlerts } from "actions/Impact";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const DialogReject = ({ open, dialogTitle, callBack, closeDialog }) => {
  const validationSchema = yup.object().shape({
    comment: yup.string().required(<IntlMessages id="core.fieldRequired" />),
  });

  const defaultValues = { comment: "" };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, errors, reset } = methods;

  const onSubmit = async (data, e) => {
    await callBack(data.comment);
  };

  const handleRequestClose = () => {
    reset(defaultValues);
    closeDialog();
  };
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent className="new-patient-dialog-content">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                label={<IntlMessages id="core.comment" />}
                name="comment"
                required={true}
                multiline
                rows={5}
                variant="outlined"
                margin="normal"
                errorobj={errors}
              />
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          {<IntlMessages id="core.cancel" />}
        </Button>
        <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
          {<IntlMessages id="core.save" />}
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  );
};

const AlertNoRadicado = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);

  const classes = useStyles();

  const [openDialogReject, setOpenDialogReject] = useState(false);

  const handleRequestCloseReject = () => setOpenDialogReject(false);

  const onRejectClick = () => setOpenDialogReject(true);

  const reject = async (comment) => {
    await callApiAsync(
      () =>
        postGenerateAlert({
          prescriptionId: selectedPrescription.id,
          prescriptionState: PrescripStateEnum.radicacionEPSNonEffective,
          comment,
        }),
      () => {
        handleRequestCloseReject();
        dispatch(
          setPrescriptionState(PrescripStateEnum.radicacionEPSNonEffective)
        );
        dispatch(callReloadAlerts(true));
      }
    );
  };

  if (
    selectedPrescription.stateId == PrescripStateEnum.radicacionEPS ||
    selectedPrescription.stateId == PrescripStateEnum.noAuthorized
  ) {
    return (
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-right mb-4">
        <Tooltip
          title={IntlGetText("radicacionInsurance.NoRadicadoButton")}
          placement="bottom-end"
        >
          <Button
            style={{ minWidth: "auto" }}
            onClick={onRejectClick}
            variant="contained"
            className="jr-btn bg-red text-white"
          >
            <i className="zmdi zmdi-comment-alert zmdi-hc-fw" />
          </Button>
        </Tooltip>
        <DialogReject
          open={openDialogReject}
          dialogTitle={
            <IntlMessages id="radicacionInsurance.NoRadicadoDialogTitle" />
          }
          callBack={reject}
          closeDialog={handleRequestCloseReject}
        />
      </div>
    );
  }

  return "";
};

export default AlertNoRadicado;
