export const dataProgramAdherence = [
  { name: "Agosto", uv: 71.6 },
  { name: "Septiembre", uv: 79.5 },
  { name: "Octubre", uv: 95.7 },
  { name: "Noviembre", uv: 94.6 },
  { name: "Diciembre", uv: 94.6 },
  { name: "Enero", uv: 94.5 },
  { name: "Febrero", uv: 93.4 },
  { name: "Marzo", uv: 95.7 },
  { name: "Abril", uv: 95.5 },
  { name: "Mayo", uv: 95.9 },
];

export const dataPharmaceuticalAdherence = [
  { name: "Aug-19", uv: 52, pv: 4, amt: 4 },
  { name: "Sep-19", uv: 51, pv: 2, amt: 1 },
  { name: "Oct-19", uv: 51, pv: 3, amt: 3 },
  { name: "Nov-19", uv: 49, pv: 3, amt: 1 },
  { name: "Dec-19", uv: 48, pv: 2, amt: 1 },
  { name: "Jan-19", uv: 50, pv: 2, amt: 4 },
  { name: "Feb-19", uv: 53, pv: 0, amt: 3 },
  { name: "Mar-19", uv: 53, pv: 1, amt: 1 },
  { name: "Apr-19", uv: 53, pv: 2, amt: 2 },
  { name: "May-19", uv: 57, pv: 0, amt: 4 },
];

export const dataActivePatients = [
  { name: "1 mg", uv: 1 },
  { name: "2,5 mg", uv: 17 },
  { name: "5 mg", uv: 36 },
  { name: "10 mg", uv: 3 },
];
