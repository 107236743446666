import { IntlGetText } from "util/IntlMessages";
import Highcharts from "highcharts";

require("highcharts/modules/exporting")(Highcharts);

export const pieChart = (
  keyValuePairData,
  titleText,
  stateTypeText,
  totalizationText,
  totalText
) => {
  const data = keyValuePairData.map((x) => [x.Key, x.Value]);
  const total = keyValuePairData
    .map((item) => item.Value)
    .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: "pie" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ": " + total,
      align: "right",
      x: -10,
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format:
            "<b>{point.name}</b>: {point.percentage:.1f} % (<b>{point.y}</b>)",
        },
      },
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      labelFormatter: function() {
        return this.name + "  ( " + this.percentage.toFixed(1) + "% )";
      },
      x: 0,
      y: 100,
    },
    tooltip: {
      formatter: function() {
        return (
          stateTypeText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>" +
          this.y +
          " (" +
          Highcharts.numberFormat(this.percentage, 2) +
          "%)" +
          "</b>"
        );
      },
    },
    series: [{ name: stateTypeText, data: data }],
  };
};

export const columnChart = (
  keyValuePairData,
  titleText,
  stateTypeText,
  totalizationText,
  totalText,
  showLegend
) => {
  const data = keyValuePairData.map((x) => [x.Key, x.Value]);
  const total = keyValuePairData
    .map((item) => item.Value)
    .reduce((prev, curr) => prev + curr, 0);

  let xInfo = [];
  data.forEach((x) => xInfo.push(x[0]));

  return {
    credits: { enabled: false },
    chart: { type: "column" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ": " + total,
      align: "right",
      x: -10,
    },
    xAxis: [{ categories: xInfo }],
    yAxis: {
      min: 0,
      title: { text: "" },
    },
    legend: {
      enabled: showLegend ? true : false,
    },
    tooltip: {
      formatter: function() {
        return (
          stateTypeText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>" +
          this.y +
          "</b>"
        );
      },
    },
    series: [
      {
        name: "",
        type: "column",
        data: data,
        dataLabels: {
          enabled: true,
          formatter: function() {
            return this.y;
          },
          y: 0,
        },
      },
    ],
  };
};

export const multiColumnChart = (
  keyValuePairData,
  titleText,
  stateTypeText,
  totalizationText,
  totalText,
  showLegend
) => {
  const data = keyValuePairData;
  const total = data.total ? data.total : 0;

  return {
    credits: { enabled: false },
    chart: { type: "column" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ": " + total,
      align: "right",
      x: -10,
    },
    xAxis: [{ categories: data.categories }],
    yAxis: {
      min: 0,
      title: { text: "" },
    },
    legend: {
      enabled: showLegend ? true : false,
      verticalAlign: "top",
    },
    tooltip: {
      formatter: function() {
        return (
          stateTypeText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>" +
          this.y +
          "</b>"
        );
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          formatter: function() {
            return this.y !== 0 ? this.y : null;
          },
        },
      },
    },
    series: data.multiColumnList,
  };
};

export const patientByNurseChart = (keyValuePairData, typeChart) => {
  const titleText = IntlGetText("dashboard.nurse.charPatientByNurse.title");
  const stateTypeText = IntlGetText("role.nurse");
  const totalizationText = IntlGetText("core.patients");
  const totalText = IntlGetText("core.total");
  if (typeChart) {
    return pieChart(
      keyValuePairData,
      titleText,
      stateTypeText,
      totalizationText,
      totalText
    );
  } else {
    return columnChart(
      keyValuePairData,
      titleText,
      stateTypeText,
      totalizationText,
      totalText
    );
  }
};

export const multiColumnVerticalChart = (
  keyValuePairData,
  titleText,
  stateTypeText,
  totalizationText,
  totalText,
  showLegend
) => {
  const data = keyValuePairData;
  const total = data.total ? data.total : 0;

  return {
    credits: { enabled: false },
    chart: { type: "bar" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ": " + total,
      align: "right",
      x: -10,
    },
    xAxis: [{ categories: data.categories }],
    yAxis: {
      min: 0,
      title: { text: "" },
    },
    legend: {
      enabled: showLegend ? true : false,
      verticalAlign: "top",
    },
    tooltip: {
      formatter: function() {
        return (
          stateTypeText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>" +
          this.y +
          "</b>"
        );
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
      
        },
      },
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          formatter: function() {
            return this.y !== 0 ? this.y : null;
          }
        },
      },
    },
    series: data.multiColumnList,
  };
};


export const productLineChart = (keyValuePairData, typeChart) => {
  const titleText = IntlGetText("dashboard.nurse.charProductLine.title");
  const pointNameText = IntlGetText("core.productLine");
  const pointValueText = IntlGetText("core.prescriptions");
  const totalText = IntlGetText("core.total");

  if (typeChart) {
    return pieChart(
      keyValuePairData,
      titleText,
      pointNameText,
      pointValueText,
      totalText
    );
  } else {
    return columnChart(
      keyValuePairData,
      titleText,
      pointNameText,
      pointValueText,
      totalText
    );
  }
};

export const prescriptionsByStateChart = (keyValuePairData, typeChart) => {
  const titleText = IntlGetText("dashboard.nurse.charPrescriptionState.title");
  const pointNameText = IntlGetText("core.state");
  const pointValueText = IntlGetText("core.prescriptions");
  const totalText = IntlGetText("core.total");

  if (typeChart) {
    return pieChart(
      keyValuePairData,
      titleText,
      pointNameText,
      pointValueText,
      totalText
    );
  } else {
    return columnChart(
      keyValuePairData,
      titleText,
      pointNameText,
      pointValueText,
      totalText
    );
  }
};

export const patientsByStateChart = (keyValuePairData, typeChart) => {
  const titleText = IntlGetText("dashboard.nurse.charPatientState.title");
  const pointNameText = IntlGetText("core.state");
  const pointValueText = IntlGetText("psp.menu.patients");
  const totalText = IntlGetText("core.total");

  if (typeChart) {
    return pieChart(
      keyValuePairData,
      titleText,
      pointNameText,
      pointValueText,
      totalText
    );
  } else {
    return columnChart(
      keyValuePairData,
      titleText,
      pointNameText,
      pointValueText,
      totalText
    );
  }
};

export const distributionPatientsByRegion = (keyValuePairData, typeChart) => {
  const titleText = IntlGetText("dashboard.distributionPatientsByRegion");
  const pointNameText = IntlGetText("core.Region");
  const pointValueText = IntlGetText("core.patients");
  const totalText = IntlGetText("core.total");

  if (typeChart) {
    return pieChart(
      keyValuePairData,
      titleText,
      pointNameText,
      pointValueText,
      totalText
    );
  } else {
    return columnChart(
      keyValuePairData,
      titleText,
      pointNameText,
      pointValueText,
      totalText
    );
  }
};

export const treatmentDeviceChart = (keyValuePairData, typeChart) => {
  const titleText = IntlGetText("dashboard.treatmentDevice");
  const pointNameText = IntlGetText("dashboard.treatmentDevice");
  const pointValueText =  IntlGetText("core.patients");
  const totalText = IntlGetText("core.total");

  if (typeChart) {
    return pieChart(
      keyValuePairData,
      titleText,
      pointNameText,
      pointValueText,
      totalText
    );
  } else {
    return columnChart(
      keyValuePairData,
      titleText,
      pointNameText,
      pointValueText,
      totalText
    );
  }
};

export const distributionPatientStatesandRegionChart = (keyValuePairData) => {
  const titleText = IntlGetText(
    "dashboard.distributionPatientStatesandRegionChart"
  );
  const pointNameText = IntlGetText("core.Region");
  const pointValueText = IntlGetText("core.patients");
  const totalText = IntlGetText("core.total");
  return multiColumnChart(
    keyValuePairData,
    titleText,
    pointNameText,
    pointValueText,
    totalText,
    true
  );
};

export const patientDistributionByEpsAndStateChart = (keyValuePairData) => {
  const titleText = IntlGetText(
    "dashboard.patientDistributionByEpsAndState"
  );
  const pointNameText = IntlGetText("radicacionInsurance.Insurance");
  const pointValueText = IntlGetText("core.patients");
  const totalText = IntlGetText("core.total");
  return multiColumnChart(
    keyValuePairData,
    titleText,
    pointNameText,
    pointValueText,
    totalText,
    true
  );
};

export const logisticChartbyRegional = (keyValuePairData) => {
  const titleText = IntlGetText(
    "dashboard.logisticChartTitle"
  );
  const pointNameText = IntlGetText("dashboard.logisticOperator");
  const pointValueText = IntlGetText("core.patients");
  const totalText = IntlGetText("core.total");
  return multiColumnChart(
    keyValuePairData,
    titleText,
    pointNameText,
    pointValueText,
    totalText,
    true
  );
};

export const getTypeTreatmentDoctor = (keyValuePairData, typeChart) => {
  const titleText = IntlGetText("dashboard.typeTreatmentDoctor");
  const pointNameText = IntlGetText("core.doctor");
  const pointValueText = IntlGetText("core.patients");
  const totalText = IntlGetText("core.total");
  if (typeChart) {
    return pieChart(
      keyValuePairData,
      titleText,
      pointNameText,
      pointValueText,
      totalText
    );
  } else {
    return columnChart(
      keyValuePairData,
      titleText,
      pointNameText,
      pointValueText,
      totalText
    );
  }
};

export const graphicTreatmentDeviceByDoctor = (keyValuePairData) => {
  const titleText = IntlGetText(
    "dashboard.graphicTreatmentDeviceByDoctorTitle"
  );
  const pointNameText = IntlGetText("core.doctor");
  const pointValueText = IntlGetText("core.patients");
  const totalText = IntlGetText("core.total");
  return multiColumnVerticalChart(
    keyValuePairData,
    titleText,
    pointNameText,
    pointValueText,
    totalText,
    true
  );
};
