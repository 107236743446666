import React from "react";

export default [
  //NUEVOS
  {
    //NUEVOS
    id: "15453ba243baa5daaf",
    hasPatient: false,
    time: "2020/07/13",
    from: {
      name: "M.",
      lastname: "L.",
      avatar: "",
      email: "julia.ramirez@outlook.com",
      doctorName: "A M",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              julia.ramirez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Evento adverso",
    date: "",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [5],
    states: [],
    selected: false,
    folder: 0,
  },
  {
    id: "153basd553baa5daaf",
    hasPatient: false,
    time: "2020/07/13",
    from: {
      name: "S.",
      lastname: "R.",
      avatar: "",
      email: "julia.ramirez@outlook.com",
      doctorName: "A M",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              julia.ramirez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Evento adverso",
    date: "",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [5],
    states: [],
    selected: false,
    folder: 0,
  },
  {
    id: "dasda42423ss",
    hasPatient: false,
    time: "2020/07/17",
    from: {
      name: "J.",
      lastname: "O.",
      avatar: "",
      email: "julia.ramirez@outlook.com",
      doctorName: "A M",
      contactList: [
        {
          id: 1,
          title: "Email",
          icon: "email",
          desc: [
            <span className="jr-link" key={1}>
              julia.ramirez@outlook.com
            </span>,
          ],
        },
        {
          id: 2,
          title: "Institución",
          icon: "city-alt",
          desc: ["Institución"],
        },
        {
          id: 3,
          title: "Teléfono",
          icon: "phone",
          desc: ["+57-314225458"],
        },
      ],
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Evento adverso",
    date: "",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [6],
    states: [],
    selected: false,
    folder: 0,
  },
  // En tramite
  {
    id: "dasda42ddas4423ss",
    hasPatient: false,
    time: "2020/07/10",
    from: {
      name: "P.",
      lastname: "R.",
      avatar: "",
      email: "julia.ramirez@outlook.com",
      doctorName: "A M",
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Evento adverso",
    date: "",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [5, 7],
    states: [],
    selected: false,
    folder: 1,
  },
  {
    id: "dasda4hhhr4as4423ss",
    hasPatient: false,
    time: "2020/07/13",
    from: {
      name: "M.",
      lastname: "G.",
      avatar: "",
      email: "julia.ramirez@outlook.com",
      doctorName: "A M",
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Evento adverso",
    date: "",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [6, 4],
    states: [],
    selected: false,
    folder: 1,
  },
  {
    id: "dasda4hhhr4as4423ss",
    hasPatient: false,
    time: "2020/07/16",
    from: {
      name: "J.",
      lastname: "R.",
      avatar: "",
      email: "julia.ramirez@outlook.com",
      doctorName: "A M",
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Evento adverso",
    date: "",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [5, 1],
    states: [],
    selected: false,
    folder: 1,
  },
  // Suspendidos/Fallecidos
  {
    id: "dasda4h454as4423ss",
    hasPatient: false,
    time: "2020/07/10",
    from: {
      name: "L.",
      lastname: "D.",
      avatar: "",
      email: "julia.ramirez@outlook.com",
      doctorName: "A M",
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Evento adverso",
    date: "",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [5, 11],
    states: [],
    selected: false,
    folder: 2,
  },
  {
    id: "aja8nbnd",
    hasPatient: false,
    time: "2020/07/15",
    from: {
      name: "I.",
      lastname: "A.",
      avatar: "",
      email: "julia.ramirez@outlook.com",
      doctorName: "A M",
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Evento adverso",
    date: "",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [6, 10],
    states: [],
    selected: false,
    folder: 2,
  },
  {
    id: "aja8nbndd3d",
    hasPatient: false,
    time: "2020/07/21",
    from: {
      name: "S.",
      lastname: "P.",
      avatar: "",
      email: "julia.ramirez@outlook.com",
      doctorName: "A M",
    },
    to: [
      {
        name: "me",
        email: "robert.johnson@example.com",
      },
    ],
    subject: "Evento adverso",
    date: "",
    message: "",
    read: true,
    starred: false,
    important: false,
    hasAttachments: false,
    labels: [5, 10],
    states: [],
    selected: false,
    folder: 2,
  },
];
