import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Tooltip
} from "@material-ui/core";
import IntlMessages from "util/IntlMessages";
import { DialogConfirmAsync } from "components/Form/Controls/DialogConfirm";
import {
  callApiAsync,
  postAddNewPrescription,
  getPrescriptions,
} from "api/apiList";
import { loadPrescriptions, setSelectedPrescription } from "actions/Management";
import { PrescripStateEnum, FolderType } from "constants/Enums";
import { isDirectTracing } from "app/routes/psp/services/userService";


import DialogEndImpact from "./dialogEndImpact";
const BtnCreatePrescription = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, impactEndDate } = useSelector(({ impact }) => impact);

  const [openDlg, setOpenDlg] = useState(false);
  const [openDlgEndImpact, setOpenDlgEndImpact] = useState(false);
  const isDirectTracingConfig = isDirectTracing();
  const onNewFormulationAsync = async (nursingNote) => {
    await callApiAsync(
      () =>
        postAddNewPrescription({
          prescriptionId: selectedPrescription.id,
          impactId: selectedPrescription.impactId,
          state: PrescripStateEnum.completedSuccessfully,
          nursingNote
        }),
        async () => {
          await callApiAsync(
            () => getPrescriptions(FolderType.tracing),
            (data) => {
              setOpenDlg(false);
              dispatch(loadPrescriptions(data, FolderType.tracing));
              dispatch(setSelectedPrescription(null));
            }
          );
        }
    );
  };

  const getInfo = ()=>{    
    return {
      toolTip: "prescription.createNewPrescriptionDlgTitle",
      btnClass: "ml-1 jr-btn bg-amber text-white",
      btnIconClass: "zmdi zmdi-delete zmdi-hc-fw",
      dlgTitle: "prescription.createNewPrescriptionDlgTitle",
      dlgText: !isDirectTracingConfig ?"prescription.createPrescriptionDlgConfirmText": "prescription.createPrescriptionDlgConfirm"
    }
  }
  const info = getInfo();

    return (
      <>
        { selectedPrescription && selectedPrescription.stateId > PrescripStateEnum.formulated &&
          <>
            <Tooltip title={<IntlMessages id={info.toolTip} />} placement="bottom-end">
              <Button
                onClick={() => setOpenDlg(true)}
                variant="contained"
                className={info.btnClass}
              >
                <i className={'zmdi zmdi-file-plus'} /> 

              </Button>
            </Tooltip>
            <DialogConfirmAsync
              openDialog={openDlg}
              title={<IntlMessages id={info.dlgTitle} />}
              text={<IntlMessages id={info.dlgText} />}
              handleRequestClose={() => setOpenDlg(false)}
              onAcceptClickAsync={async ()=>{
                if(impactStartDate && !impactEndDate){
                  setOpenDlgEndImpact(true);
                  return;
                }
                await onNewFormulationAsync("");
              }}
            />
            <DialogEndImpact 
              open={openDlgEndImpact} 
              handleRequestClose={() => setOpenDlgEndImpact(false)} 
              callEndImpact={onNewFormulationAsync}
            />
          </>
        }
      </>
    );
};

export default BtnCreatePrescription;