import { IntlGetText } from "util/IntlMessages";
import Highcharts from "highcharts";
import { currencyFormat } from "services/formatNumber";

export const YesNoChart = (
  titleText,
  tooltipKey,
  locale,
  yesNoQuestionData,
  cycleIdValue,
  questionIdValue,
  patientCount
) => {
  const totalText = IntlGetText("core.total");
  const instillationText = IntlGetText("patientPA.instillation");
  const data = yesNoQuestionData.filter(
    (x) => x.cycleName === cycleIdValue && x.questionNumber === questionIdValue
  );
  const categories = data.map((x) => x.instillationName);
  const serie1 = data.map((x) => {
    const yes = x.chartData.find((y) => y.Key === "Sí");
    if (yes) return yes.Value;
    return 0;
  });
  const serie2 = data.map((x) => {
    const no = x.chartData.find((y) => y.Key === "No");
    if (no) return no.Value;
    return 0;
  });

  return {
    credits: { enabled: false },
    chart: {
      type: "column",
    },
    title: {
      text: titleText,
    },
    subtitle: {
      text: totalText + ': '+ currencyFormat(locale,patientCount),
      align: 'right',
      x: -10
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      title: { text: instillationText },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Cantidad",
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:10px">Instilación: {point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
      series: {
        dataLabels: {
            enabled: true,
            inside: false
        }
      }
    },
    series: [
      {
        name: "Sí",
        data: serie1,
      },
      {
        name: "No",
        data: serie2,
      },
    ],
  };
};

export const QuestionChart = (
  titleText,
  tooltipKey,
  locale,
  questionData,
  cycleIdValue,
  questionIdValue,
  patientCount
) => {
  const totalText = IntlGetText("core.total");
  const instillationText = IntlGetText("patientPA.instillation");
  const data = questionData.filter(
    (x) => x.cycleName === cycleIdValue && x.questionNumber === questionIdValue
  );
  const categories = !data || data.length === 0 ? [] : data[0].categories;
  const series = !data || data.length === 0 ? [] : data[0].series;

  return {
    credits: { enabled: false },
    chart: { type: "column" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ': '+ currencyFormat(locale,patientCount),
      align: 'right',
      x: -10
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      title: { text: instillationText },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Cantidad",
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:10px">Instilación: {point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
      series: {
        dataLabels: {
            enabled: true,
            inside: false
        }
      }
    },
    series: series,
  };
};

export const LineChart = (
  titleText,
  tooltipKey,
  locale,
  questionData,
  cycleIdValue,
  questionIdValue,
  patientCount
  ) => {

  const instillationText = IntlGetText("patientPA.instillation");
  const totalText = IntlGetText("core.total");
  const data = questionData.filter((x) => x.cycleName === cycleIdValue && x.questionNumber === 11);
  const categories = !data || data.length === 0 ? undefined : data[0].categories;
  const dataChart = !data || data.length === 0 ? [] : data[0].series[0].data;

  return {
    chart: { type: 'line' },
    title: { text: titleText },
    subtitle: {
      text: totalText + ': '+ currencyFormat(locale,patientCount),
      align: 'right',
      x: -10
    },
    xAxis: { categories: categories,
      title: { text: instillationText } },
    legend: {
      enabled: false,
      reversed: false,
    },
    yAxis: {
      labels: {
        formatter: function () {
          return this.value;
        },
      },
      title: {
          text: ''
      },  
    },
    plotOptions: {
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        series: {
          dataLabels: {
              enabled: true,
              inside: false
          }
        }
    },
    series: [{
        name: '',
        data: dataChart
    }],
    credits: {
      enabled: false
    }
  };
};
