import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Switch } from "@material-ui/core";

function FormSwitch(props) {
  const { control } = useFormContext();
  const { name, label } = props;

  return (
    <FormControlLabel
      control={
        <Controller
          control={control}
          name={name}
          defaultValue={false}
          render={({ onChange, value }) => (
            <Switch
              color="primary"
              onChange={(e) => onChange(e.target.checked)}
              checked={value}
            />
          )}
        />
      }
      label={label}
    />
  );
}

export default FormSwitch;
