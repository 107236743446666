import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { FaMapMarkerAlt } from "react-icons/fa";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab
} from "@material-ui/core";
import {
  getUserInfo,
  isOnlyTracing,
  isDirectTracing
} from "app/routes/psp/services/userService";
import InfoCard from "components/InfoCard/index";
import Widget from "components/Widget";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import FormCheckbox from "components/Form/Controls/checkbox";
import FormDatePicker from "components/Form/Controls/datepicker";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { callStartImpact, callReloadPatientData } from "actions/Impact";
import {
  genderList,
  educationalLevelList,
  CivilStatusList,
  RelationshipList,
  HousingTypeList,
  SupportNetList,
  RegimeList,
  AffiliationTypeList,
  StratumList,
  ShardKeyEnum,
  OccupationList,
  FrequentMeansContactList,
  DisposeRemnantsList
} from "constants/Enums";
import {
  callApi,
  callApiAsync,
  getAllStateProvince,
  getCitiesByStateProvinceId,
  getAllDocumentType,
  getPatientInfo,
  postPatientSaveHousing,
  postPatientSave,
  postPatientSaveTutor,
  getInsuranceList
} from "api/apiList";
import PatientMap from "components/patientMap";

function EmptyNumber(typeErrorMessage = "Please enter a valid number") {
  return yup
    .number()
    .transform(function(value, originalValue) {
      if (this.isType(value)) return value;
      if (!originalValue || !originalValue.trim()) {
        return null;
      }
      // we return the invalid original value
      return originalValue;
    })
    .nullable(true)
    .typeError(typeErrorMessage);
}

const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const calculateAge = (brithday) =>
  !brithday || !dayjs(brithday).isValid()
    ? ""
    : dayjs().diff(dayjs(brithday), "year");

const calculateAgeInMonths = (birthday) =>
  !birthday || !dayjs(birthday).isValid()
    ? ""
    : dayjs().diff(dayjs(birthday), "month");

const PatientForm = ({
  stateProvinceList,
  documentTypeList,
  insuranceList,
  setPatientData,
  patientData,
  setInsuranceData,
  insuranceData,
  documentTypeListComplete
}) => {
  const msgSuccess = IntlGetText("core.SuccessSave");
  const isOnlyTrace = isOnlyTracing();
  const isOperando = getUserInfo().shardKey === ShardKeyEnum.operando;
  const defaultValues = {
    code: patientData.code,
    name: patientData.name,
    ods: patientData.ods,
    lastName: patientData.lastName,
    initials: patientData.initials,
    birthday: patientData.birthday ? dayjs(patientData.birthday) : null,
    age: calculateAge(
      patientData.birthday ? dayjs(patientData.birthday) : dayjs()
    ),
    ageMonth: calculateAgeInMonths(
      patientData.birthday ? dayjs(patientData.birthday) : dayjs()
    ),
    birthstateProvinceId: patientData.birthstateProvinceId,
    birthCityId: patientData.birthCityId,
    gender: isNaN(parseInt(patientData.gender))
      ? 0
      : getCboValue(genderList(), patientData.gender),
    documentTypeId: patientData.documentTypeId,
    document: !patientData.document ? "" : patientData.document,
    phone: patientData.phone,
    cellphone: patientData.cellphone,
    email: !patientData.email ? "" : patientData.email,
    stateProvinceId: patientData.stateProvinceId,
    cityId: patientData.cityId,
    address: !patientData.address ? "" : patientData.address,
    neighborhood: !patientData.neighborhood ? "" : patientData.neighborhood,
    educationalLevel: isNaN(parseInt(patientData.educationalLevel))
      ? 0
      : getCboValue(educationalLevelList(), patientData.educationalLevel),
    profession: !patientData.profession ? "" : patientData.profession,
    civilStatus: isNaN(parseInt(patientData.civilStatus))
      ? 0
      : getCboValue(CivilStatusList(), patientData.civilStatus),
    stratum: getCboValue(
      StratumList(),
      !patientData ? "" : patientData.stratum
    ),
    hospitalized: patientData.hospitalized ? patientData.hospitalized : false,
    occupation: isNaN(parseInt(patientData.occupation))
      ? 0
      : getCboValue(OccupationList(), patientData.occupation),
    occupationOther: !patientData.occupationOther
      ? ""
      : patientData.occupationOther,

    frequentMeansContact: isNaN(parseInt(patientData.frequentMeansContact))
      ? 0
      : getCboValue(
          FrequentMeansContactList(),
          patientData.frequentMeansContact
        ),
    disposeRemnants: isNaN(parseInt(patientData.disposeRemnants))
      ? 0
      : getCboValue(DisposeRemnantsList(), patientData.disposeRemnants),
    disposeRemnantsOther: !patientData.disposeRemnantsOther
      ? ""
      : patientData.disposeRemnantsOther,
    insuranceId: insuranceData.insuranceId,
    regime: isNaN(parseInt(insuranceData.regime))
      ? 0
      : getCboValue(RegimeList(), insuranceData.regime),
    affiliationType: isNaN(parseInt(insuranceData.affiliationType))
      ? 0
      : getCboValue(AffiliationTypeList(), insuranceData.affiliationType)
  };

  const validationSchema = {
    name: yup.string().required(<IntlMessages id="core.fieldRequired" />),

    lastName: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    birthday: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    birthstateProvinceId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    birthCityId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    gender: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    documentTypeId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    document: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    phone: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    email: yup.string().email(<IntlMessages id="yup.emailInvalid" />),
    stateProvinceId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    cityId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    address: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    insuranceId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable()
  };
  if (isDirectTracing()) {
    validationSchema.initials = yup
      .string()
      .required(<IntlMessages id="core.fieldRequired" />);
    validationSchema.ods = yup
      .number()
      .typeError(IntlGetText("yup.numericValidation"))
      .min(0, IntlGetText("yup.numericValidation"))
      .max(9999999, IntlGetText("yup.invalidInput"))
      .required(<IntlMessages id="core.fieldRequired" />);
  }

  if (!isOnlyTrace) {
    if (!isOperando) {
      validationSchema.regime = yup
        .object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable();
      if (!isDirectTracing()) {
        validationSchema.affiliationType = yup
          .object()
          .shape({ value: yup.string().required() })
          .required(<IntlMessages id="core.fieldRequired" />)
          .nullable();
      }
    }
    validationSchema.civilStatus = yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable();
    // validationSchema.occupation = yup
    // .object()
    // .shape({ value: yup.string().required() })
    // .required(<IntlMessages id="core.fieldRequired" />)
    // .nullable();
    // validationSchema.frequentMeansContact = yup
    // .object()
    // .shape({ value: yup.string().required() })
    // .required(<IntlMessages id="core.fieldRequired" />)
    // .nullable();
    // validationSchema.disposeRemnants = yup
    // .object()
    // .shape({ value: yup.string().required() })
    // .required(<IntlMessages id="core.fieldRequired" />)
    // .nullable();
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema))
  });
  const { handleSubmit, watch, setValue, reset, errors } = methods;

  const documentTypeIdValue = watch("documentTypeId");
  const birthstateProvinceValue = watch("birthstateProvinceId");
  const stateProvinceIdValue = watch("stateProvinceId");
  const birthCityIdValue = watch("birthCityId");
  const cityIdValue = watch("cityId");
  const ageValue = watch("age");
  const ageMonthValue = watch("ageMonth");
  const insuranceIdValue = watch("insuranceId");
  const occupationValue = watch("occupation");
  const disposeRemnantsValue = watch("disposeRemnants");
  //const frequentMeansContactValue = watch("frequentMeansContact");
  const [birthCityList, setBirthCityList] = useState([]);
  const [openMap, setopenMap] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [isHiddenInputVisible, setHiddenInputVisible] = useState(false);
  const [
    isHiddenInputVisibleRemnants,
    setHiddenInputVisibleRemnants
  ] = useState(false);
  const [loadForm, setLoadForm] = useState({
    documentTypeListLoaded: false,
    stateProvinceListLoaded: false,
    birthCityListLoaded: false,
    cityListLoaded: false,
    insuranceListLoaded: false
  });
  const [geoInfo, setGeoInfo] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    setGeoInfo({
      lat: patientData.geoDataLat ? patientData.geoDataLat : 0,
      lng: patientData.geoDataLng ? patientData.geoDataLng : 0
    });
  }, []);

  useEffect(() => {
    if (!loadForm.stateProvinceListLoaded && stateProvinceList.length > 0) {
      setValue(
        "birthstateProvinceId",
        getCboValue(stateProvinceList, birthstateProvinceValue)
      );
      setValue(
        "stateProvinceId",
        getCboValue(stateProvinceList, stateProvinceIdValue)
      );
      setLoadForm({ ...loadForm, stateProvinceListLoaded: true });
      return;
    }
    if (!loadForm.cityListLoaded && cityList.length > 0) {
      setValue("cityId", getCboValue(cityList, defaultValues.cityId));
      setLoadForm({ ...loadForm, cityListLoaded: true });
      return;
    }
    if (!loadForm.birthCityListLoaded && birthCityList.length > 0) {
      setValue(
        "birthCityId",
        getCboValue(birthCityList, defaultValues.birthCityId)
      );
      setLoadForm({ ...loadForm, birthCityListLoaded: true });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityList, birthCityList, stateProvinceList]);

  useEffect(() => {
    if (!loadForm.documentTypeListLoaded && documentTypeList.length > 0) {
      setValue(
        "documentTypeId",
        getCboValue(documentTypeList, documentTypeIdValue)
      );
      setLoadForm({ ...loadForm, documentTypeListLoaded: true });
      return;
    }
  }, [documentTypeList]);

  useEffect(() => {
    if (!loadForm.insuranceListLoaded && insuranceList.length > 0) {
      setValue(
        "insuranceId",
        getCboValue(insuranceList, defaultValues.insuranceId)
      );
      setLoadForm({ ...loadForm, insuranceListLoaded: true });
      return;
    }
  }, [insuranceList]);

  useEffect(() => {
    setValue("birthCityId", null);
    if (!birthstateProvinceValue || !birthstateProvinceValue.value) {
      setBirthCityList([]);
      return;
    }
    callApi(
      () => getCitiesByStateProvinceId(birthstateProvinceValue.value),
      setBirthCityList
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthstateProvinceValue]);

  useEffect(() => {
    setValue("cityId", null);
    if (!stateProvinceIdValue || !stateProvinceIdValue.value) {
      setCityList([]);
      return;
    }
    callApi(
      () => getCitiesByStateProvinceId(stateProvinceIdValue.value),
      setCityList
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProvinceIdValue]);

  useEffect(() => {
    if (occupationValue && occupationValue.value === 7) {
      setHiddenInputVisible(true);
    } else {
      setHiddenInputVisible(false);
      setValue("occupationOther", null);
    }
  }, [occupationValue]);

  useEffect(() => {
    if (disposeRemnantsValue && disposeRemnantsValue.value === 3) {
      setHiddenInputVisibleRemnants(true);
    } else {
      setHiddenInputVisibleRemnants(false);
      setValue("otherdisposeRemnants", null);
    }
  }, [disposeRemnantsValue]);

  useEffect(() => {
    const name = watch("name") || "";
    const lastName = watch("lastName") || "";

    const getInitials = (text) =>
      text
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join("");

    const nameInitials = getInitials(name);
    const lastNameInitials = getInitials(lastName);
    const initials = nameInitials + lastNameInitials;

    setValue("initials", initials);
  }, [watch("name"), watch("lastName"), setValue]);

  const cboHandleChange = (name) => (event) => {
    setValue(name, event);
  };
  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);

  const dtpHandleChange = (name) => (e) => {
    setValue(name, e);
    setValue("age", calculateAge(e));
    setValue("ageMonth", calculateAgeInMonths(e));
  };

  const handleMapRequestClose = () => {
    setopenMap(false);
  };

  const onSubmit = async (d, e) => {
    const selectedDocumentType = documentTypeListComplete.find(
      (type) => type.id === documentTypeIdValue.value
    );
    const regexText = selectedDocumentType
      ? selectedDocumentType.regularExpression
      : null;

    if (regexText) {
      const messageValidation = selectedDocumentType
        ? selectedDocumentType.validationMessage
        : "";
      let regex = new RegExp(regexText, "i");
      if (!regex.test(d.document)) {
        NotificationManager.warning(messageValidation);
        return;
      }
    }

    let patient = {
      id: patientData.id,
      name: d.name,
      lastName: d.lastName,
      initials: d.initials,
      ods: d.ods,
      birthday: dayjs(d.birthday).format("YYYY-MM-DDTHH:mm:ss"),
      birthstateProvinceId: null,
      birthCityId: parseFloat(d.birthCityId.value),
      gender: Number(d.gender.value),
      documentTypeId: Number(d.documentTypeId.value),
      document: d.document,
      phone: d.phone,
      cellphone: d.cellphone,
      email: d.email,
      stateProvinceId: null,
      cityId: parseFloat(d.cityId.value),
      address: d.address,
      neighborhood: d.neighborhood,
      educationalLevel: !d.educationalLevel
        ? null
        : Number(d.educationalLevel.value),
      profession: d.profession,
      occupation: !d.occupation ? null : Number(d.occupation.value),
      occupationOther: d.occupationOther,
      frequentMeansContact: !d.frequentMeansContact
        ? null
        : Number(d.frequentMeansContact.value),
      disposeRemnants: !d.disposeRemnants
        ? null
        : Number(d.disposeRemnants.value),
      disposeRemnantsOther: d.disposeRemnantsOther,
      civilStatus: !d.civilStatus ? null : Number(d.civilStatus.value),
      stratum: !d.stratum ? null : Number(d.stratum.value),
      hospitalized: d.hospitalized,
      code: "",
      geoDataLat: geoInfo.lat,
      geoDataLng: geoInfo.lng
    };
    let insuranceInfo = {
      prescriptionId: insuranceData.prescriptionId,
      insuranceId: Number(d.insuranceId.value),
      regime: Number(d.regime.value),
      affiliationType: d.affiliationType
        ? Number(d.affiliationType.value)
        : null
    };
    var obj = { patient, insuranceInfo };

    await callApiAsync(
      () => postPatientSave(obj),
      (data) => {
        NotificationManager.success(msgSuccess);
        setLoadForm({
          ...loadForm,
          stateProvinceListLoaded: true,
          cityListLoaded: true,
          birthCityListLoaded: true
        });
        setPatientData(data.patient);
        setInsuranceData(data.insuranceInfo);
      }
    );
  };

  const isRequired = !isOnlyTrace;

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
      <FormProvider {...methods}>
        <form className="row" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="code"
                label={<IntlMessages id="core.code" />}
                disabled={true}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            {isDirectTracing() === true && (
              <>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="initials"
                    label={<IntlMessages id="core.initials" />}
                    required={true}
                    margin="normal"
                    errorobj={errors}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="ods"
                    label={<IntlMessages id="core.ods" />}
                    required={true}
                    margin="normal"
                    errorobj={errors}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="name"
                label={<IntlMessages id="core.name" />}
                margin="normal"
                required={true}
                errorobj={errors}
                disabled={isDirectTracing() === true ? true : false}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="lastName"
                label={<IntlMessages id="core.lastName" />}
                margin="normal"
                required={true}
                errorobj={errors}
                disabled={isDirectTracing() === true ? true : false}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormDatePicker
                name="birthday"
                label={<IntlMessages id="core.birthday" />}
                format="YYYY-MM-DD"
                disableFuture={true}
                onChange={dtpHandleChange("birthday")}
                value={watch("birthday")}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={3}>
              <FormInput
                name="age"
                label={<IntlMessages id="core.ageyears" />}
                value={ageValue}
                onChange={(value) => {
                  setValue("age", value);
                }}
                disabled={true}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={3}>
              <FormInput
                name="ageMonth"
                label={<IntlMessages id="core.agemonths" />}
                value={ageMonthValue}
                onChange={(value) => {
                  setValue("age", value);
                }}
                disabled={true}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="birthstateProvinceId"
                label={<IntlMessages id="core.birthstateProvince" />}
                options={stateProvinceList}
                value={birthstateProvinceValue}
                onChange={cboHandleChange("birthstateProvinceId")}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="birthCityId"
                label={<IntlMessages id="core.birthCity" />}
                options={birthCityList}
                value={birthCityIdValue}
                onChange={cboHandleChange("birthCityId")}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="gender"
                label={<IntlMessages id="core.gender" />}
                options={genderList()}
                value={watch("gender")}
                onChange={cboHandleChange("gender")}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="civilStatus"
                label={<IntlMessages id="core.civilStatus" />}
                options={CivilStatusList()}
                value={watch("civilStatus")}
                onChange={cboHandleChange("civilStatus")}
                margin="normal"
                required={isRequired}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="documentTypeId"
                label={<IntlMessages id="core.documentType" />}
                options={documentTypeList}
                value={documentTypeIdValue}
                onChange={cboHandleChange("documentTypeId")}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="document"
                label={<IntlMessages id="core.document" />}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="phone"
                label={<IntlMessages id="core.phone" />}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="cellphone"
                label={<IntlMessages id="core.cellphone" />}
                margin="normal"
                required={false}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="email"
                label={<IntlMessages id="core.email" />}
                errorobj={errors}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              style={{ display: "flex", alignItems: "baseline" }}
            >
              <div className="VContainer" style={{ width: "100%" }}>
                <div className="HContainer a-items ">
                  <FormInput
                    name="address"
                    label={<IntlMessages id="core.address" />}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                  <Button
                    onClick={() => setopenMap(true)}
                    style={{
                      background:
                        geoInfo.lat || geoInfo.lat ? "#20c997" : "#f6bb43",
                      color: "white",
                      height: "40px",
                      margin: "0 0 0 5px"
                    }}
                  >
                    <FaMapMarkerAlt />
                  </Button>
                </div>
                {isDirectTracing() === true && (
                  <p style={{ fontSize: "11px", color: "#757575" }}>
                    Ingrese la <b>dirección completa</b>. Evite el uso de
                    abreviaturas , y <b>utilice las palabras completas</b> como
                    "Carrera", "Calle" o "Avenida". <b>Ejemplo: </b> Carrera 7
                    número 06 - 12
                  </p>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="stateProvinceId"
                label={<IntlMessages id="core.stateProvinceResidence" />}
                options={stateProvinceList}
                value={stateProvinceIdValue}
                onChange={cboHandleChange("stateProvinceId")}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="cityId"
                label={<IntlMessages id="core.cityResidence" />}
                options={cityList}
                value={cityIdValue}
                onChange={cboHandleChange("cityId")}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="neighborhood"
                label={<IntlMessages id="core.neighborhood" />}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="stratum"
                label={<IntlMessages id="core.stratum" />}
                options={StratumList()}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="educationalLevel"
                label={<IntlMessages id="core.educationalLevel" />}
                options={educationalLevelList()}
                value={watch("educationalLevel")}
                onChange={cboHandleChange("educationalLevel")}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="profession"
                label={<IntlMessages id="core.profession" />}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="occupation"
                label={<IntlMessages id="core.occupation" />}
                options={OccupationList()}
                value={watch("occupation")}
                onChange={cboHandleChange("occupation")}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            {isHiddenInputVisible === true ? (
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="occupationOther"
                  label={<IntlMessages id="core.otherOccupation" />}
                  margin="normal"
                  errorobj={errors}
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="frequentMeansContact"
                label={<IntlMessages id="core.frequentMeansContact" />}
                options={FrequentMeansContactList()}
                value={watch("frequentMeansContact")}
                onChange={cboHandleChange("frequentMeansContact")}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="disposeRemnants"
                label={<IntlMessages id="core.disposeRemnants" />}
                options={DisposeRemnantsList()}
                value={watch("disposeRemnants")}
                onChange={cboHandleChange("disposeRemnants")}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            {isHiddenInputVisibleRemnants && (
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="disposeRemnantsOther"
                  label={<IntlMessages id="core.otherdisposeRemnants" />}
                  margin="normal"
                  errorobj={errors}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="insuranceId"
                label={<IntlMessages id="core.insurance" />}
                options={insuranceList}
                value={insuranceIdValue}
                onChange={cboHandleChange("insuranceId")}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="regime"
                label={<IntlMessages id="core.regime" />}
                options={RegimeList()}
                value={watch("regime")}
                onChange={cboHandleChange("regime")}
                margin="normal"
                required={isOperando ? false : isRequired}
                errorobj={errors}
              />
            </Grid>
            {!isDirectTracing() === true && (
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="affiliationType"
                  label={<IntlMessages id="core.affiliationType" />}
                  options={AffiliationTypeList()}
                  value={watch("affiliationType")}
                  onChange={cboHandleChange("affiliationType")}
                  margin="normal"
                  required={isOperando ? false : isRequired}
                  errorobj={errors}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12} lg={12}>
              <FormCheckbox
                name="hospitalized"
                label={<IntlMessages id="core.hospitalized" />}
                value={watch("hospitalized")}
                onChange={chkHandleChange("hospitalized")}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center mt-4">
        <ButtonAsync
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
        >
          {<IntlMessages id="core.save" />}
        </ButtonAsync>
      </div>
      <Dialog
        maxWidth="md"
        open={openMap}
        disableBackdropClick={true}
        onClose={handleMapRequestClose}
        className="mapDialog"
      >
        <DialogTitle>{<IntlMessages id="core.map" />}</DialogTitle>
        <DialogContent>
          <PatientMap geoInfo={geoInfo} setGeoInfo={setGeoInfo} />
          <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center mt-4">
            <ButtonAsync
              onClick={() => {
                handleMapRequestClose();
              }}
              variant="contained"
              color="primary"
            >
              {<IntlMessages id="core.save" />}
            </ButtonAsync>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleMapRequestClose()} color="secondary">
            {<IntlMessages id="core.close" />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const HousingForm = ({ setHousingData, housingData, patientId }) => {
  const msgSuccess = IntlGetText("core.SuccessSave");

  const defaultValues = {
    housingType: !housingData
      ? 0
      : getCboValue(HousingTypeList(), housingData.housingType),
    aqueduct: !housingData ? false : housingData.aqueduct,
    sewerage: !housingData ? false : housingData.sewerage,
    electricity: !housingData ? false : housingData.electricity,
    toilet: !housingData ? false : housingData.toilet,
    wasteCollection: !housingData ? false : housingData.wasteCollection,
    cookingArea: !housingData ? false : housingData.cookingArea,
    rooms: !housingData ? 0 : housingData.rooms,
    bathRoom: !housingData ? false : housingData.bathRoom,
    laundryArea: !housingData ? false : housingData.laundryArea,
    supportNet: !housingData
      ? 0
      : getCboValue(SupportNetList(), housingData.supportNet),
    petsInUnsuitableConditions: !housingData
      ? false
      : housingData.petsInUnsuitableConditions,
    animalType: !housingData ? "" : housingData.animalType,
    petsNumber: !housingData ? 0 : housingData.petsNumber
  };

  const validationSchema = {
    //rooms: yup.number().integer(<IntlMessages id="core.fieldRequired" />),
    //rooms: EmptyNumber(<IntlMessages id="yup.invalidInput" />).positive(<IntlMessages id="yup.number.positive" />)
    //rooms: yup.number().moreThan(0,<IntlMessages id="yup.invalidInput" />).nullable()
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema))
  });
  const { handleSubmit, watch, setValue, reset, errors } = methods;

  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);
  const cboHandleChange = (name) => (e) => {
    setValue(name, e);
  };

  const onSubmit = async (d, e) => {
    var housing = {
      ...d,
      housingType: Number(d.housingType.value),
      supportNet: Number(d.supportNet.value),
      rooms: Number(d.rooms),
      petsNumber: Number(d.petsNumber),
      id: !housingData ? 0 : housingData.id,
      patientId
    };
    var obj = { housing };

    await callApiAsync(
      () => postPatientSaveHousing(obj),
      (data) => {
        NotificationManager.success(msgSuccess);
        setHousingData(data);
      }
    );
  };

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
      <FormProvider {...methods}>
        <form className="row" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={4} style={{ marginTop: 2 }}>
              <FormSelectAutoComplete
                name="housingType"
                label={<IntlMessages id="sociodemographicInfo.housingType" />}
                options={HousingTypeList()}
                value={watch("housingType")}
                onChange={cboHandleChange("housingType")}
                required={false}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={4} style={{ marginTop: 16 }}>
              <FormInput
                name="rooms"
                label={<IntlMessages id="sociodemographicInfo.rooms" />}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={4} style={{ marginTop: 2 }}>
              <FormSelectAutoComplete
                name="supportNet"
                label={<IntlMessages id="sociodemographicInfo.supportNet" />}
                options={SupportNetList()}
                value={watch("supportNet")}
                onChange={cboHandleChange("supportNet")}
                required={false}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormCheckbox
                name="aqueduct"
                label={<IntlMessages id="sociodemographicInfo.aqueduct" />}
                value={watch("aqueduct")}
                onChange={chkHandleChange("aqueduct")}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormCheckbox
                name="sewerage"
                label={<IntlMessages id="sociodemographicInfo.sewerage" />}
                value={watch("sewerage")}
                onChange={chkHandleChange("sewerage")}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormCheckbox
                name="electricity"
                label={<IntlMessages id="sociodemographicInfo.electricity" />}
                value={watch("electricity")}
                onChange={chkHandleChange("electricity")}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormCheckbox
                name="toilet"
                label={<IntlMessages id="sociodemographicInfo.toilet" />}
                value={watch("toilet")}
                onChange={chkHandleChange("toilet")}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormCheckbox
                name="wasteCollection"
                label={
                  <IntlMessages id="sociodemographicInfo.wasteCollection" />
                }
                value={watch("wasteCollection")}
                onChange={chkHandleChange("wasteCollection")}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormCheckbox
                name="cookingArea"
                label={<IntlMessages id="sociodemographicInfo.cookingArea" />}
                value={watch("cookingArea")}
                onChange={chkHandleChange("cookingArea")}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormCheckbox
                name="bathRoom"
                label={<IntlMessages id="sociodemographicInfo.bathRoom" />}
                value={watch("bathRoom")}
                onChange={chkHandleChange("bathRoom")}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormCheckbox
                name="laundryArea"
                label={<IntlMessages id="sociodemographicInfo.laundryArea" />}
                value={watch("laundryArea")}
                onChange={chkHandleChange("laundryArea")}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormCheckbox
                name="petsInUnsuitableConditions"
                label={
                  <IntlMessages id="sociodemographicInfo.petsInUnsuitableConditions" />
                }
                value={watch("petsInUnsuitableConditions")}
                onChange={chkHandleChange("petsInUnsuitableConditions")}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="animalType"
                label={<IntlMessages id="sociodemographicInfo.animalType" />}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="petsNumber"
                label={<IntlMessages id="sociodemographicInfo.petsNumber" />}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center mt-4">
        <ButtonAsync
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
        >
          {<IntlMessages id="core.save" />}
        </ButtonAsync>
      </div>
    </div>
  );
};

const TutorForm = ({
  stateProvinceList,
  documentTypeList,
  patientId,
  setTutorData,
  tutorData,
  documentTypeListComplete
}) => {
  const msgSuccess = IntlGetText("core.SuccessSave");

  const defaultValues = {
    name: !tutorData || !tutorData.name ? "" : tutorData.name,
    lastName: !tutorData || !tutorData.lastName ? "" : tutorData.lastName,
    birthday: !tutorData
      ? null
      : tutorData.birthday
      ? dayjs(tutorData.birthday)
      : null,
    age: calculateAge(
      !tutorData
        ? dayjs()
        : tutorData.birthday
        ? dayjs(tutorData.birthday)
        : dayjs()
    ),
    birthstateProvinceId:
      !tutorData || !tutorData.birthstateProvinceId
        ? ""
        : tutorData.birthstateProvinceId,
    birthCityId:
      !tutorData || !tutorData.birthCityId ? "" : tutorData.birthCityId,
    gender: !tutorData
      ? 0
      : isNaN(parseInt(tutorData.gender))
      ? 0
      : getCboValue(genderList(), tutorData.gender),
    documentTypeId:
      !tutorData || !tutorData.documentTypeId ? "" : tutorData.documentTypeId,
    document: !tutorData || !tutorData.document ? "" : tutorData.document,
    phone: !tutorData || !tutorData.phone ? "" : tutorData.phone,
    cellphone: !tutorData || !tutorData.cellphone ? "" : tutorData.cellphone,
    email: !tutorData || !tutorData.email ? "" : tutorData.email,
    stateProvinceId:
      !tutorData || !tutorData.stateProvinceId ? "" : tutorData.stateProvinceId,
    cityId: !tutorData || !tutorData.cityId ? "" : tutorData.cityId,
    address: !tutorData || !tutorData.address ? "" : tutorData.address,
    educationalLevel: !tutorData
      ? 0
      : isNaN(parseInt(tutorData.educationalLevel))
      ? 0
      : getCboValue(educationalLevelList(), tutorData.educationalLevel),
    profession: !tutorData || !tutorData.profession ? "" : tutorData.profession,
    civilStatus: !tutorData
      ? ""
      : isNaN(parseInt(tutorData.civilStatus))
      ? 0
      : getCboValue(CivilStatusList(), tutorData.civilStatus),
    relationship: !tutorData
      ? ""
      : isNaN(parseInt(tutorData.relationship))
      ? 0
      : getCboValue(RelationshipList(), tutorData.relationship),
    stratum: !tutorData || !tutorData.stratum ? "" : tutorData.stratum
  };

  const validationSchema = {
    name: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    lastName: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    birthday: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate")),
    email: yup.string().email(<IntlMessages id="yup.emailInvalid" />)
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema))
  });
  const { handleSubmit, watch, setValue, reset, errors } = methods;

  const documentTypeIdValue = watch("documentTypeId");
  const birthstateProvinceValue = watch("birthstateProvinceId");
  const stateProvinceIdValue = watch("stateProvinceId");
  const birthCityIdValue = watch("birthCityId");
  const cityIdValue = watch("cityId");
  const ageValue = watch("age");
  const ageMonthValue = watch("ageMonth");

  const [birthCityList, setBirthCityList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [loadForm, setLoadForm] = useState({
    documentTypeListLoaded: false,
    stateProvinceListLoaded: false,
    birthCityListLoaded: false,
    cityListLoaded: false
  });

  useEffect(() => {
    if (!loadForm.stateProvinceListLoaded && stateProvinceList.length > 0) {
      setValue(
        "birthstateProvinceId",
        getCboValue(stateProvinceList, birthstateProvinceValue)
      );
      setValue(
        "stateProvinceId",
        getCboValue(stateProvinceList, stateProvinceIdValue)
      );
      setLoadForm({ ...loadForm, stateProvinceListLoaded: true });
      return;
    }
    if (!loadForm.cityListLoaded && cityList.length > 0) {
      setValue("cityId", getCboValue(cityList, defaultValues.cityId));
      setLoadForm({ ...loadForm, cityListLoaded: true });
      return;
    }
    if (!loadForm.birthCityListLoaded && birthCityList.length > 0) {
      setValue(
        "birthCityId",
        getCboValue(birthCityList, defaultValues.birthCityId)
      );
      setLoadForm({ ...loadForm, birthCityListLoaded: true });
      return;
    }
    if (!loadForm.documentTypeListLoaded && documentTypeList.length > 0) {
      setValue(
        "documentTypeId",
        getCboValue(documentTypeList, documentTypeIdValue)
      );
      setLoadForm({ ...loadForm, documentTypeListLoaded: true });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityList, birthCityList, stateProvinceList, documentTypeList]);

  useEffect(() => {
    setValue("birthCityId", null);
    if (!birthstateProvinceValue || !birthstateProvinceValue.value) {
      setBirthCityList([]);
      return;
    }
    callApi(
      () => getCitiesByStateProvinceId(birthstateProvinceValue.value),
      setBirthCityList
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthstateProvinceValue]);
  useEffect(() => {
    setValue("cityId", null);
    if (!stateProvinceIdValue || !stateProvinceIdValue.value) {
      setCityList([]);
      return;
    }
    callApi(
      () => getCitiesByStateProvinceId(stateProvinceIdValue.value),
      setCityList
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProvinceIdValue]);

  const cboHandleChange = (name) => (event) => {
    setValue(name, event);
  };
  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);
  const dtpHandleChange = (name) => (e) => {
    setValue(name, e);
    setValue("age", calculateAge(e));
  };

  const onSubmit = async (d, e) => {
    if (d.documentTypeId) {
      const selectedDocumentType = documentTypeListComplete.find(
        (type) => type.id === documentTypeIdValue.value
      );
      const regexText = selectedDocumentType
        ? selectedDocumentType.regularExpression
        : null;

      if (regexText) {
        const messageValidation = selectedDocumentType
          ? selectedDocumentType.validationMessage
          : "";
        let regex = new RegExp(regexText, "i");
        if (!regex.test(d.document)) {
          NotificationManager.warning(messageValidation);
          return;
        }
      }
    }

    let tutor = {
      id: !tutorData ? 0 : tutorData.id,
      name: d.name,
      lastName: d.lastName,
      birthday: !d.birthday
        ? null
        : dayjs(d.birthday).format("YYYY-MM-DDTHH:mm:ss"),
      birthstateProvinceId: null,
      birthCityId: !d.birthCityId ? null : Number(d.birthCityId.value),
      gender: !d.gender ? null : Number(d.gender.value),
      documentTypeId: !d.documentTypeId ? null : Number(d.documentTypeId.value),
      document: d.document,
      phone: d.phone,
      cellphone: d.cellphone,
      email: d.email,
      stateProvinceId: null,
      cityId: !d.cityId ? null : Number(d.cityId.value),
      address: d.address,
      educationalLevel: !d.educationalLevel
        ? null
        : Number(d.educationalLevel.value),
      profession: d.profession,
      civilStatus: !d.civilStatus ? null : Number(d.civilStatus.value),
      stratum: !d.stratum ? null : Number(d.stratum),
      relationship: !d.relationship ? null : Number(d.relationship.value)
    };

    var obj = { tutor, patientId };

    await callApiAsync(
      () => postPatientSaveTutor(obj),
      (data) => {
        NotificationManager.success(msgSuccess);
        setLoadForm({
          ...loadForm,
          stateProvinceListLoaded: true,
          cityListLoaded: true,
          birthCityListLoaded: true
        });
        setTutorData(data);
      }
    );
  };

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
      <FormProvider {...methods}>
        <form className="row" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="name"
                label={<IntlMessages id="core.name" />}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="lastName"
                label={<IntlMessages id="core.lastName" />}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormDatePicker
                name="birthday"
                label={<IntlMessages id="core.birthday" />}
                format="YYYY-MM-DD"
                margin="normal"
                value={watch("birthday")}
                onChange={dtpHandleChange("birthday")}
                disableFuture={true}
                errorobj={errors}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="age"
                label={<IntlMessages id="core.age" />}
                value={ageValue}
                onChange={(value) => {
                  setValue("age", value);
                }}
                disabled={true}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="birthstateProvinceId"
                label={<IntlMessages id="core.birthstateProvince" />}
                options={stateProvinceList}
                value={birthstateProvinceValue}
                onChange={cboHandleChange("birthstateProvinceId")}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="birthCityId"
                label={<IntlMessages id="core.birthCity" />}
                options={birthCityList}
                value={birthCityIdValue}
                onChange={cboHandleChange("birthCityId")}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="gender"
                label={<IntlMessages id="core.gender" />}
                options={genderList()}
                value={watch("gender")}
                onChange={cboHandleChange("gender")}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="civilStatus"
                label={<IntlMessages id="core.civilStatus" />}
                options={CivilStatusList()}
                value={watch("civilStatus")}
                onChange={cboHandleChange("civilStatus")}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="documentTypeId"
                label={<IntlMessages id="core.documentType" />}
                options={documentTypeList}
                value={documentTypeIdValue}
                onChange={cboHandleChange("documentTypeId")}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="document"
                label={<IntlMessages id="core.document" />}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="phone"
                label={<IntlMessages id="core.phone" />}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="cellphone"
                label={<IntlMessages id="core.cellphone" />}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="email"
                label={<IntlMessages id="core.email" />}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="address"
                label={<IntlMessages id="core.address" />}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="stateProvinceId"
                label={<IntlMessages id="core.stateProvince" />}
                options={stateProvinceList}
                value={stateProvinceIdValue}
                onChange={cboHandleChange("tstateProvinceId")}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="cityId"
                label={<IntlMessages id="core.city" />}
                options={cityList}
                value={cityIdValue}
                onChange={cboHandleChange("cityId")}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="educationalLevel"
                label={<IntlMessages id="core.educationalLevel" />}
                options={educationalLevelList()}
                value={watch("educationalLevel")}
                onChange={cboHandleChange("educationalLevel")}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="profession"
                label={<IntlMessages id="core.profession" />}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="relationship"
                label={<IntlMessages id="core.relationship" />}
                options={RelationshipList()}
                value={watch("relationship")}
                onChange={cboHandleChange("relationship")}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center mt-4">
        <ButtonAsync
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
        >
          {<IntlMessages id="core.save" />}
        </ButtonAsync>
      </div>
    </div>
  );
};

const PatientInfo = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const {
    impactStartDate,
    consentSigned,
    reloadPatientData,
    isCIRequired
  } = useSelector(({ impact }) => impact);
  const impact = useSelector(({ impact }) => impact);
  const [stateProvinceList, setStateProvinceList] = useState([]);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [insuranceList, setInsuranceList] = useState([]);

  const [patientData, setPatientData] = useState({});
  const [insuranceData, setInsuranceData] = useState({});
  const [tutorData, setTutorData] = useState({});
  const [housingData, setHousingData] = useState({});

  const [open, setOpen] = useState(false);
  const [value, setValue2] = useState(0);

  const msgStartImpactRequired = IntlGetText("impact.startImpactRequired");
  const msgConsentRequired = IntlGetText("impact.consentRequired");
  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    if (isCIRequired && !consentSigned) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }

    setOpen(true);
  };
  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleChange = (event, value) => {
    setValue2(value);
  };

  const loadPatientData = () => {
    callApi(
      () => getPatientInfo(selectedPrescription.id),
      (data) => {
        setPatientData(data.patient);
        setInsuranceData(data.insuranceInfo);
        setHousingData(data.housing);
        setTutorData(data.tutor);
      }
    );
  };

  useEffect(() => {
    loadPatientData();
    callApi(getAllStateProvince, setStateProvinceList);
    callApi(getAllDocumentType, setDocumentTypeList);
    callApi(getInsuranceList, setInsuranceList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (reloadPatientData) {
      loadPatientData();
      dispatch(callReloadPatientData(false));
    }
  }, [reloadPatientData]);

  return (
    <div>
      <InfoCard
        data={{
          icon: "account-box",
          title: "Datos",
          subTitle: "Paciente",
          color:
            patientData == null || patientData.cityId == null
              ? "#E53935"
              : "#20c997"
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>{<IntlMessages id="core.patientData" />}</DialogTitle>
        <DialogContent>
          {/* <div className="app-wrapper"> */}
          {/* <div className="jr-profile-content"> */}
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <Widget styleName="jr-card-full jr-card-tabs-left jr-card-profile border-dialog-card">
                <div className="card-header"></div>
                <div className="jr-tabs-classic">
                  <Tabs
                    className="jr-tabs-up"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      className="jr-tabs-label"
                      label={<IntlMessages id="patientData.data" />}
                    />
                    <Tab
                      className="jr-tabs-label"
                      label={
                        <IntlMessages id="patientData.sociodemographicInfo" />
                      }
                    />
                    <Tab
                      className="jr-tabs-label"
                      label={<IntlMessages id="patientData.tutor" />}
                    />
                  </Tabs>
                  <div className="jr-tabs-content jr-task-list">
                    <div className="row">
                      {value === 0 && (
                        <PatientForm
                          setPatientData={(data) => setPatientData(data)}
                          patientData={patientData}
                          setInsuranceData={setInsuranceData}
                          insuranceData={insuranceData}
                          stateProvinceList={stateProvinceList}
                          documentTypeList={documentTypeList}
                          insuranceList={insuranceList}
                          documentTypeListComplete={documentTypeList}
                        />
                      )}

                      {value === 1 && (
                        <HousingForm
                          setHousingData={(data) => setHousingData(data)}
                          housingData={housingData}
                          patientId={patientData.id}
                        />
                      )}

                      {value === 2 && (
                        <TutorForm
                          stateProvinceList={stateProvinceList}
                          documentTypeList={documentTypeList}
                          setTutorData={setTutorData}
                          tutorData={tutorData}
                          patientId={patientData.id}
                          documentTypeListComplete={documentTypeList}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Widget>
            </div>
          </div>
          {/* </div> */}
          {/* </Auxiliary> */}
          {/* </div> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            {<IntlMessages id="core.close" />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PatientInfo;
