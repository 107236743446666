
import React from "react";
import * as dayjs from "dayjs";
import { IntlGetText } from "util/IntlMessages";
import Table, { getTextLabels, defaultCol } from "components/Form/Table";
import { DownloadButton2 } from "components/DownloadButton/index";


const ImpactTable = ({ data }) => {
  const getObj = (tableMeta) => data.find(x=>x.id === tableMeta.rowData[0]);  

  var columns =  [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display:"excluded"
      },
    },
    {
      name: "impactDate",
      label: IntlGetText("core.impact"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD hh:mm a") : "";
        },
      },
    },
    defaultCol("authorizationCode", "insuranceAuthorization.codeNumber"),
    defaultCol("code", "radicadoOLF.pendDate"),
    {
      name: "radicadoDate",
      label: IntlGetText("radicadoOLF.radicadoDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    defaultCol("medicineAmount", "core.amount"),
    {
      name: "deliveryDate",
      label: IntlGetText("radicadoOLF.dlvrDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    {
      name: "filePath",
      label: IntlGetText("adverseEventProcess.files"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if(value){
            return (
              <DownloadButton2
                variant="contained"
                className="jr-btn jr-btn-xs"
                color="primary"
                fileLink={value}
              >
                <i className="zmdi zmdi-download"></i>
              </DownloadButton2>
            );
          }
          return "";
        },
      },
    }
  ];
  
  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    pagination:false,
    textLabels: getTextLabels()
  };

  return (
    <Table
      className="border-dialog-card"
      data={data}
      columns={columns}
      options={options} />
  );
};

export default ImpactTable;