import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormCheckbox from "components/Form/Controls/checkbox";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { updateSelectedPrescription } from "actions/Management";
import { getUserInfo } from "app/routes/psp/services/userService";
import { isOnlyTracing, isJaasModule } from "app/routes/psp/services/userService";
import {
  callApi,
  callApiAsync,
  postAddClinicalFinding,
  getPatientStateList
} from "api/apiList";
import {  
  ClinicalFindingTypeList, 
  ClinicalFindingStateList,
  ClinicalFindingStateEnum,
  PatientStateList,
  MedicalSuspensionReasonList,
  PatientStateEnum,
  ShardKeyEnum
} from "constants/Enums";

const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const FormAdd = ({ impactId, openDlgAdd, handleRequestClose, reloadData, selectedPrescription }) => {
  const dispatch = useDispatch();
  const isOnlyTrace = isOnlyTracing();

  const [patientStates, setPatientStates] = useState([]);
  const clinicalFindingTypeList = ClinicalFindingTypeList();
  const clinicalFindingStateList = ClinicalFindingStateList();
  const msgEndDate = IntlGetText("clinicalFindingState.endDateInvalid") + IntlGetText("clinicalFinding.startDate");

  useEffect(() => {
    callApi(
      getPatientStateList,
      (data)=>{
        setPatientStates(data.states);
      }
    );
  }, []);

  const isJaasModuleConfig = isJaasModule();  
  let patientStateList = [];
  
  if(isJaasModuleConfig){
    patientStateList = patientStates;
  }else {
    patientStateList = PatientStateList().filter(x=>
      x.id === PatientStateEnum.medicalSuspension || x.id === PatientStateEnum.death
      || x.id === PatientStateEnum.active || x.id === PatientStateEnum.suspension
      || x.id === PatientStateEnum.voluntarySuspension || x.id === PatientStateEnum.dropout || x.id === PatientStateEnum.formulated
      || x.id === PatientStateEnum.process
    );
    
    if(getUserInfo().shardKey !== ShardKeyEnum.ullkupuy || getUserInfo().shardKey !== ShardKeyEnum.phg)
      patientStateList = patientStateList.filter(x=>x.id !== PatientStateEnum.dropout);

    if(getUserInfo().shardKey !== ShardKeyEnum.phg){
      patientStateList = patientStateList.filter(x=>x.id !== PatientStateEnum.process);
    }

    if(!isOnlyTrace)
      patientStateList = patientStateList.filter(x=>x.id !== PatientStateEnum.formulated);
  }
    
  
  let medicalSuspensionReasonList = MedicalSuspensionReasonList();
  const defaultValues = {    
    startDate: null,
    endDate: null,
    state: null,
    comment: "",
    contactDoctor: false,
    type: null,
    patientState: null,
    medicalSuspensionReason : null
  };

  const validationSchema = {
    startDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    endDate: yup
      .date()
      .nullable(true)
      .min(yup.ref("startDate"), msgEndDate)
      .typeError(IntlGetText("yup.InvalidDate"))
      .when("state", {
        is: (state) => state && Number(state.value) === ClinicalFindingStateEnum.closed,
        then: yup
          .date()
          .nullable(true)
          .min(yup.ref("startDate"), msgEndDate)
          .typeError(IntlGetText("yup.InvalidDate"))
          .required(<IntlMessages id="core.fieldRequired" />)
      }),
    state: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    comment: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    type: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    patientState: yup.object()
      .when("contactDoctor", {
        is: (contactDoctor) => selectedPrescription.patient.state === PatientStateEnum.active,
        then: yup.object()
          .shape({ value: yup.string().required() })
          .required(<IntlMessages id="core.fieldRequired" />)
          .nullable()
      })      
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, errors } = methods;

  const contactDoctorValue = watch("contactDoctor");
  const patientStateValue = watch("patientState");  
  const medicalSuspensionReasonValue = watch("medicalSuspensionReason");
  useEffect(()=>{
    if(openDlgAdd && !patientStateValue){
      setTimeout(()=>
        setValue("patientState", !selectedPrescription ? null : getCboValue(patientStateList, selectedPrescription.patient.state)),
        150
      );
    }
    if(openDlgAdd && !medicalSuspensionReasonValue){
      setTimeout(()=>
        setValue("medicalSuspensionReason", !selectedPrescription ? null : getCboValue(medicalSuspensionReasonList, selectedPrescription.patient.medicalSuspensionReason)),
        150
      );
    }
  },[openDlgAdd]);

  const onSubmit = async (d, e) => {
    var obj = {
      impactId: impactId,
      startDate: dayjs(d.startDate).format("YYYY-MM-DDTHH:mm:ss"),
      endDate: !d.endDate ? null : dayjs(d.endDate).format("YYYY-MM-DDTHH:mm:ss"),
      state: Number(d.state.value),
      comment: d.comment,
      contactDoctor: d.contactDoctor,
      type: Number(d.type.value),
      patientState: !d.patientState ? null : Number(d.patientState.value),
      medicalSuspensionReason: !isJaasModuleConfig && d.patientState && (d.patientState.value == PatientStateEnum.medicalSuspension) ? Number(d.medicalSuspensionReason.value) : null
    };
    await callApiAsync(
      () => postAddClinicalFinding(obj),
      () => {        
        handleRequestClose();
        reloadData();
        const prescription = {
          ...selectedPrescription,
          patient:{
            ...selectedPrescription.patient,
            state: obj.patientState
          }
        }
        dispatch(updateSelectedPrescription(prescription));
      }
    );
  };
  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openDlgAdd}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id="clinicalFindingState.dlgTitleAdd" />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>           
                <Grid item xs={12} md={12} lg={12}>
                  <FormSelectAutoComplete
                    name="patientState"
                    label={<IntlMessages id="core.patientState" />}
                    options={patientStateList}
                    disabled={ (isOnlyTrace || isJaasModuleConfig) ? false : selectedPrescription.patient.state !== PatientStateEnum.active}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                { !isJaasModuleConfig && (watch("patientState") && (watch("patientState").value === PatientStateEnum.medicalSuspension)) && (
                  <Grid item xs={12} md={12} lg={12}>
                    <FormSelectAutoComplete
                      name="medicalSuspensionReason"
                      label={<IntlMessages id="core.medicalSuspensionReason" />}
                      options={medicalSuspensionReasonList}
                      margin="normal"
                      required={watch("patientState") && (watch("patientState").value === PatientStateEnum.medicalSuspension)}
                      errorobj={errors}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6} lg={6}>
                  <FormSelectAutoComplete
                    name="type"
                    label={<IntlMessages id="core.type" />}
                    options={clinicalFindingTypeList}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormSelectAutoComplete
                    name="state"
                    label={<IntlMessages id="core.state" />}
                    options={clinicalFindingStateList}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormDatePicker
                    name="startDate"
                    label={<IntlMessages id="clinicalFinding.startDate" />}
                    format="YYYY-MM-DD"
                    margin="normal"
                    required={true} 
                    disablePast={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormDatePicker
                    name="endDate"
                    label={<IntlMessages id="clinicalFinding.endDate" />}
                    format="YYYY-MM-DD"
                    margin="normal"
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    name="comment"
                    label={<IntlMessages id="core.description" />}
                    multiline
                    rows={2}
                    variant="outlined"
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>          
                <Grid item xs={12} md={12} lg={12}>
                  <FormCheckbox
                    name="contactDoctor"
                    label={<IntlMessages id="clinicalFindingState.contactDoctor" />}
                    value={contactDoctorValue}
                    onChange={chkHandleChange("contactDoctor")}
                  />
                </Grid>   
              </Grid>
            </form>
          </FormProvider>        
          <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4 mb-4">
            <ButtonAsync onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
            >
              {<IntlMessages id="core.save" />}
            </ButtonAsync>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormAdd;
