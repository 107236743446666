import React, { useState } from "react";
import IntlMessages from "util/IntlMessages";
import { Button } from "@material-ui/core";
import Dlg from "./Dlg";

const BtnProcess = (props) =>{  
  const [openDlg, setOpenDlg] = useState(false);

  return (
    <div>
      <div className="text-right mt-1 mb-1">
      <Button
        onClick={()=>setOpenDlg(true)}
        variant="contained"
        color="primary"
        className="ml-2"
      >
        {<IntlMessages id="core.process" />}
      </Button>
      </div>
      <Dlg
        open={openDlg}
        closeDialog={()=>setOpenDlg(false)}
        {...props}
      />
    </div>
  );
};

export default BtnProcess;