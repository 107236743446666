import React from "react";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import CardBox from "components/CardBox/index";
import Table, { getTextLabels } from "components/Form/Table";
import { RadicadoLogisticOperatorState } from "constants/Enums";

const TableAM = ({
  data,
  handleOpenCRU,
  handleOpenDel,
  setCurrentRowObj,
  readOnly,
}) => {
  
  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: getTextLabels(),
  };

  const handleClick = (tableMeta) => {
    handleOpenCRU();
    setCurrentRowObj(
      !tableMeta ? null : tableMeta.tableData[tableMeta.rowIndex]
    );
  };
  const handleClickDelete = (tableMeta) => {
    handleOpenDel();
    setCurrentRowObj(tableMeta.tableData[tableMeta.rowIndex]);
  };

  const columns = [
    {
      name: "authorizationCode",
      label: IntlGetText("radicadoOLF.authorizationCodeNumber"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "radicadoDate",
      label: IntlGetText("radicadoOLF.radDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    {
      name: "code",
      label: IntlGetText("radicadoOLF.pendDate"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "authorizedQuantity",
      label: IntlGetText("radicadoOLF.authorizedQuantity"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "medicineAmount",
      label: IntlGetText("insuranceAuthorization.medicineAmountDelivery"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = tableMeta.tableData[tableMeta.rowIndex];
          return rowObj.deliveries + "/" + value;
        },
      },
    },
    {
      name: "deliveryDate",
      label: IntlGetText("radicadoOLF.dlvrDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = tableMeta.tableData[tableMeta.rowIndex];
          if (readOnly || rowObj.hasDeliveries || rowObj.state === RadicadoLogisticOperatorState.deliveryNotMade) {
            return (
              <div>
                <Button
                  onClick={() => handleClick(tableMeta)}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className="zmdi zmdi-eye"></i>
                </Button>
              </div>
            );
          }

          return (
            <div>
              <Button
                onClick={() => handleClick(tableMeta)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-edit"></i>
              </Button>
              <Button
                onClick={() => handleClickDelete(tableMeta)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-delete"></i>
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="row">
      <CardBox styleName="col-12" cardStyle="p-0 border-dialog-card">
        <Table
          className="border-dialog-card"
          data={data}
          columns={columns}
          options={options}
        />
      </CardBox>
      {!readOnly && (
        <div className="col-lg-12 col-md-12 col-xs-12 mt-4 text-center">
          <Button
            onClick={() => handleClick(null)}
            variant="contained"
            color="primary"
          >
            {<IntlMessages id="core.add" />}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TableAM;
