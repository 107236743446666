import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "actions/Base";
import { useForm, FormProvider } from "react-hook-form";
import * as dayjs from "dayjs";
import IntlMessages from "util/IntlMessages";
import AppLocale from "lngProvider";
import { Button, Grid } from "@material-ui/core";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import CustomChart from "components/CustomChart";
import FormDatePicker from "components/Form/Controls/datepicker";
import { RoleEnum } from "constants/Enums";
import { isRole } from "app/routes/psp/services/userService";
import {
  totalChart,
  pieChart,
  columnChart,
  enrollChart,
} from "./optionsCharts";
import { callApi, getDashboardPA, getRepList } from "api/apiList";

const getPropArray = (obj, prop) => {
  if (!obj) return [];
  return obj[prop];
};

const DashboardPA = () => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const { locale } = useSelector(({ settings }) => settings);
  const currentAppLocale = AppLocale[locale.locale];
  const currentLocale = currentAppLocale.locale;

  const [totalInfo, setTotalInfo] = useState(null);
  const [repList, setRepList] = useState([]);

  const defaultValues = {
    startDate: null,
    endDate: null,
    repId: null
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, errors, setValue, trigger } = methods;

  const onFilter = handleSubmit(async (d) => {
    if (d.startDate || d.endDate) {
      if (!(await trigger())) return;
    }

    var obj = {
      repId: !d.repId ? null : d.repId.value,      
      startDate: !d.startDate
        ? null
        : dayjs(d.startDate).format("YYYY-MM-DDT00:00:00"),
      endDate: !d.endDate
        ? null
        : dayjs(d.endDate).format("YYYY-MM-DDT00:00:00"),
    };

    callApi(() => getDashboardPA(obj), setTotalInfo, fnSetLoader);
  });

  useEffect(() => {
    if (!isRole(RoleEnum.rep)) callApi(getRepList, setRepList);
    onFilter();
  }, []);

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
      <FormProvider {...methods}>
        <Grid container spacing={1}>
          {!isRole(RoleEnum.rep) && (
            <Grid item xs={12} md={4} lg={4}>
              <FormSelectAutoComplete
                name="repId"
                label={<IntlMessages id="role.visitor" />}
                options={repList}
                margin="normal"
              />
            </Grid>
          )}
          <Grid item xs={12} md={2} lg={2}>
            <FormDatePicker
              name="startDate"
              label={<IntlMessages id="core.startDate" />}
              format="YYYY-MM-DD"
              margin="normal"
              errorobj={errors}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <FormDatePicker
              name="endDate"
              label={<IntlMessages id="core.endDate" />}
              format="YYYY-MM-DD"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Button
              onClick={onFilter}
              className="mt-3"
              variant="contained"
              color="primary"
            >
              <IntlMessages id="core.filter" />
            </Button>
          </Grid>
        </Grid>
      </FormProvider>

      <div className="row mt-3">
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart
            options={totalChart(
              "dashboarPA.chartByStateTitle",
              "core.state",
              currentLocale,
              getPropArray(totalInfo, "chartByState")
            )}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart
            options={pieChart(
              "dashboarPA.chartByGenderTitle",
              "core.gender",
              currentLocale,
              getPropArray(totalInfo, "chartByGender")
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart
            options={pieChart(
              "dashboarPA.chartByPatologyTitle",
              "patientPA.pathology",
              currentLocale,
              getPropArray(totalInfo, "chartByPatology")
            )}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart options={pieChart("dashboarPA.chartByContact","core.Contact",currentLocale, getPropArray(totalInfo, "chartByContact"))} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart options={pieChart("dashboarPA.chartTreatmentState","patientPA.treatment",currentLocale, getPropArray(totalInfo, "chartByTreatmentState"))} />
        </div>
        
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={columnChart(
              "patientPA.currentInstillation",
              "patientPA.instillation",
              currentLocale,
              getPropArray(totalInfo, "chartByInstillation")
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={columnChart(
              "dashboarPA.chartByInstillationByMonthTitle",
              "calendar.messages.month",
              currentLocale,
              getPropArray(totalInfo, "chartByInstillationByMonth"),
              "patientPA.instillations"
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={columnChart(
              "dashboarPA.chartByInsuranceTitle",
              "core.insurance",
              currentLocale,
              getPropArray(totalInfo, "chartByInsurance")
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={columnChart(
              "dashboarPA.chartByCityTitle",
              "core.city",
              currentLocale,
              getPropArray(totalInfo, "chartByCity")
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={columnChart(
              "dashboarPA.chartByRepTitle",
              "role.visitor",
              currentLocale,
              getPropArray(totalInfo, "chartByRep")
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={columnChart(
              "dashboarPA.chartByDoctorTitle",
              "core.doctor",
              currentLocale,
              getPropArray(totalInfo, "chartByDoctor")
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={enrollChart(
              currentLocale,
              getPropArray(totalInfo, "chartEnroll")
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardPA;
