import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

let id = 0;

function createData(date, medicin, urgency, status) {
  id += 1;
  return { id, date, medicin, urgency, status };
}

const data = [
  createData(
    "2020/07/22",
    <div className=" badge text-uppercase text-white bg-pink">Remodulin</div>,
    <div className=" badge text-uppercase text-white bg-red">Alta</div>,
    <div className=" badge text-uppercase text-white bg-light-blue">Nuevo</div>
  ),
];

function ProcessTable() {
  return (
    <div className="table-responsive-material">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Estado</TableCell>
            <TableCell align="center">Fecha</TableCell>
            <TableCell align="center">Medicamento</TableCell>
            <TableCell align="center">Urgencia</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((n) => {
            return (
              <TableRow key={n.id}>
                <TableCell align="center">{n.status}</TableCell>
                <TableCell align="center">{n.date}</TableCell>
                <TableCell align="center">{n.medicin}</TableCell>
                <TableCell align="center">{n.urgency}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default ProcessTable;
