export const dataProgramAdherence = [
  { name: "Aug", uv: 71.6 },
  { name: "Sep", uv: 79.5 },
  { name: "Oct", uv: 95.7 },
  { name: "Nov", uv: 94.6 },
  { name: "Dec", uv: 94.6 },
  { name: "Ene", uv: 94.5 },
  { name: "Feb", uv: 93.4 },
  { name: "Mar", uv: 95.7 },
  { name: "Apr", uv: 95.5 },
  { name: "May", uv: 95.9 },
];

export const dataPharmaceuticalAdherence = [
  { name: "Aug-19", uv: 37 },
  { name: "Sep-19", uv: 41 },
  { name: "Oct-19", uv: 48 },
  { name: "Nov-19", uv: 52 },
  { name: "Dec-19", uv: 60 },
  { name: "Jan-19", uv: 58 },
  { name: "Feb-19", uv: 58 },
  { name: "Mar-19", uv: 63 },
  { name: "Apr-19", uv: 67 },
  { name: "May-19", uv: 71 },
];

export const dataActivePatients = [
  { name: "15 mg diarios", uv: 9 },
  { name: "30 mg diarios", uv: 33 },
  { name: "45 mg diarios", uv: 29 },
];

export const data = [
  { name: "ICLUSIG", value: 51.4 },
  { name: "REMODULIN", value: 41.3 },
  { name: "KALBITOR", value: 3.6 },
  { name: "GALAFORD", value: 3.6 },
];

export const data1 = [
  { name: "Contributivo", value: 70 },
  { name: "Subsidiado", value: 30 },
];
