import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip
} from "@material-ui/core";
import {
  callActionApi,
  postActivatePatient
} from "api/apiList";
import FormDatePicker from "components/Form/Controls/datepicker";
import { updateSelectedPrescription } from "actions/Management";
import { isJaasModule } from "app/routes/psp/services/userService";
import { PatientStateEnum, ProductBehavior, FolderType } from "constants/Enums";

const   BtnActivatePatient = () => {
  const dispatch = useDispatch();
  const { selectedPrescription, selectedMenuItem } = useSelector(({ management }) => management);

  const [openDlg, setOpenDlg] = useState(false);
  const handleRequestClose = () => setOpenDlg(false);

  const isJaasModuleConfig = isJaasModule();
  const msgSuccess = IntlGetText("core.SuccessSave");

  const defaultValues = { 
    dosageStartDate: null
  };
  const validationSchema = {
    dosageStartDate: yup.date()
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />)
  };
  const methods = useForm({ defaultValues, resolver: yupResolver(yup.object().shape(validationSchema)) });
  const { handleSubmit, errors } = methods;

  const updatePrescription = () =>{
    const prescription = {
      ...selectedPrescription,
      patient:{
        ...selectedPrescription.patient,
        state: PatientStateEnum.active
      }
    }
    dispatch(updateSelectedPrescription(prescription));
  };

  const onSubmit = (d) => {
    const obj = {
      impactId: selectedPrescription.impactId,
      dosageStartDate: dayjs(d.dosageStartDate).format("YYYY-MM-DDTHH:mm:ss")
    };
    callActionApi(
      ()=>postActivatePatient(obj),
      ()=>{
        updatePrescription();
        NotificationManager.success(msgSuccess);
        handleRequestClose();
      },
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  };
  
  return (
    <div>
    { !isJaasModuleConfig && selectedMenuItem === FolderType.tracing && 
      (selectedPrescription.patient && (selectedPrescription.patient.state === PatientStateEnum.medicalSuspension
          || selectedPrescription.patient.state === PatientStateEnum.suspension || selectedPrescription.patient.state === PatientStateEnum.voluntarySuspension 
          || selectedPrescription.patient.state === PatientStateEnum.process )) &&
      <React.Fragment>
        <div className="text-right">
          <Tooltip title={IntlGetText("patient.activatePatient")} placement="bottom-end">
            <Button
              onClick={() => setOpenDlg(true)}
              variant="contained"
              className="ml-3 jr-btn bg-green text-white"
            >
              <i className="zmdi zmdi-drink zmdi-hc-fw" />
            </Button>
          </Tooltip>
        </div>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={openDlg}
          disableBackdropClick={true}
          onClose={handleRequestClose}
        >
          <DialogTitle>
            <IntlMessages id="patient.activatePatient" />
          </DialogTitle>
          <DialogContent>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <FormProvider {...methods}>
              <form className="row" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormDatePicker
                      name="dosageStartDate"
                      label={IntlGetText("patient.dosageStartDate")}  
                      format="YYYY-MM-DD"  
                      disableFuture={true}
                      required={true}
                      errorobj={errors}  
                    />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color="secondary">
              <IntlMessages id="core.cancel" />
            </Button>
            <Button onClick={handleSubmit(onSubmit)} color="primary">
              <IntlMessages id="core.accept" />
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    }
    </div>
  );
};

export default BtnActivatePatient;