import React from "react";
import CardBox from "components/CardBox/index";

import { patients, datatableConfig, patientColumns } from "./../data";

import MUIDataTable from "mui-datatables";

class PatientList extends React.Component {
  state = {
    patients: patients,
  };

  render() {
    const columns = patientColumns;
    const data = patients;

    const options = {
      filterType: "dropdown",
      download: false,
      print: false,
      selectableRows: "none",
      textLabels: datatableConfig.textLabels,
    };

    return (
      <div className="app-wrapper">
        <div className="row">
          <CardBox styleName="col-12" cardStyle="p-0">
            <MUIDataTable
              title={"Pacientes"}
              data={data}
              columns={columns}
              options={options}
            />
          </CardBox>
        </div>
      </div>
    );
  }
}

export default PatientList;
