import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete, {
  getCboValue
} from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { entityProviderTypeEnum } from "constants/Enums";
import { callApi, getControlExamSelects } from "api/apiList";
import { useSelector } from "react-redux";
import FormInput from "components/Form/Controls/input";

const FormCE = ({
  rowObj2,
  impactId,
  openCE,
  handleRequestClose,
  handleCreateUpdate
}) => {
  const { selectedPrescription } = useSelector(({ management }) => management); //id

  const [rowObj, setCurrentObj] = useState(null);
  //Selects
  const [controlExamSelect, setControlExamSelect] = useState(null);
  const [controlExamUnitTypeSelect, setControlExamUnitTypeSelect] = useState(
    null
  );

  const [disabled, setDisabled] = useState(false);

  const entityProviderTypeList = entityProviderTypeEnum();

  const defaultValues = {
    controlExamDate: null
  };

  const validationSchema = {
    entityProviderType: yup
      .object()
      .shape({ value: yup.string().required("Este campo es obligatorio") })
      .required("Este campo es obligatorio")
      .nullable(),

    padCode: yup.string().when("entityProviderType", {
      is: (entityProviderType) => entityProviderType?.value == 1,
      then: yup.string().required(<IntlMessages id="core.fieldRequired" />),
      otherwise: yup.string().nullable()
    }),

    controlExamUnitType: yup
      .object()
      .shape({ value: yup.string().required("Este campo es obligatorio") })
      .required("Este campo es obligatorio")
      .nullable(),

    controlExamDate: yup
      .date()
      .nullable()
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),

    result: yup
      .string()
      // .typeError("El resultado debe ser un número")
      // .min(1, IntlGetText("yup.numericValidation"))
      // .max(50000, IntlGetText("yup.invalidInput"))
      .required(<IntlMessages id="core.fieldRequired" />),

    units: yup
      .object()
      .shape({ value: yup.string().required("Este campo es obligatorio") })
      .required("Este campo es obligatorio")
      .nullable()
  };

  const loadSelect = () => {
    callApi(getControlExamSelects, (data) => {
      setControlExamSelect(data.controlExamSelect);
      setControlExamUnitTypeSelect(data.controlExamUnitTypeSelect);
    });
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema))
  });
  const { handleSubmit, watch, setValue, reset, errors } = methods;

  const entityProviderTypeValue = watch("entityProviderType");

  const onSubmit = async (d, e) => {
    var obj = {
      id: !rowObj ? 0 : rowObj.id,
      personId: selectedPrescription.patient.id,
      controlExamId: parseInt(d.controlExamUnitType.value),
      entityProviderTypeId: parseInt(d.entityProviderType.value),
      examDate: !d.controlExamDate
        ? null
        : dayjs(d.controlExamDate).format("YYYY-MM-DDTHH:mm:ss"),
      padcode: d.padCode,
      result: d.result,
      controlExamUnitTypeId: parseInt(d.units.value)
    };

    await handleCreateUpdate(obj);
    handleRequestClose();
  };

  const loadData = () => {
    setValue(
      "controlExamUnitType",
      getCboValue(controlExamSelect, !rowObj ? 0 : rowObj.controlExamId)
    );
    setValue("controlExamDate", !rowObj ? null : rowObj.examDate);
    setValue("result", !rowObj ? "" : rowObj.result);

    setValue(
      "entityProviderType",
      getCboValue(
        entityProviderTypeList,
        !rowObj ? 0 : rowObj.entityProviderTypeId
      )
    );
    setValue(
      "units",
      getCboValue(
        controlExamUnitTypeSelect,
        !rowObj ? 0 : rowObj.controlExamUnitTypeId
      )
    );
    setValue("padCode", !rowObj ? "" : rowObj.padCode);
  };

  useEffect(() => {
    loadSelect();
  }, []);

  useEffect(() => {
    setCurrentObj(rowObj2);
    if (rowObj2) {
      setDisabled(true);
    }
  }, [rowObj2]);

  useEffect(() => {
    if (openCE) {
      setTimeout(loadData, 150);
    }
  }, [rowObj, openCE]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCE}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!rowObj ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="entityProviderType"
                    label={<IntlMessages id="ControlExam.Entity" />}
                    options={entityProviderTypeList}
                    value={watch("entityProviderType")}
                    margin="normal"
                    errorobj={errors}
                    required={true}
                  />
                </Grid>

                {entityProviderTypeValue?.value === 1 && (
                  <Grid item xs={12} md={12} lg={6}>
                    <FormInput
                      name="padCode"
                      label={<IntlMessages id="ControlExam.padCode" />}
                      margin="normal"
                      errorobj={errors}
                      required={true}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="controlExamUnitType"
                    label={<IntlMessages id="Diagnosis.controlExamUnitType" />}
                    options={controlExamSelect}
                    value={watch("controlExamUnitType")}
                    margin="normal"
                    errorobj={errors}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormDatePicker
                    name="controlExamDate"
                    label={<IntlMessages id="ControlExam.ControlExamDate" />}
                    format="YYYY-MM-DD"
                    disableFuture={true}
                    margin="normal"
                    errorobj={errors}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="result"
                    label={<IntlMessages id="Diagnosis.result" />}
                    margin="normal"
                    errorobj={errors}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="units"
                    label={<IntlMessages id="ControlExam.units" />}
                    options={controlExamUnitTypeSelect}
                    value={watch("units")}
                    margin="normal"
                    errorobj={errors}
                    required={true}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
            <ButtonAsync
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
            >
              {<IntlMessages id="core.save" />}
            </ButtonAsync>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormCE;
