export default [
  {
    id: 0,
    handle: "first-impact",
    title: "Primer impacto",
    color: "light-blue",
    type: "new-patient",
  },
  {
    id: 7,
    handle: "doctorok",
    title: "Méd. autorizó",
    color: "green",
    type: "new-patient",
  },
  {
    id: 8,
    handle: "pacienteok",
    title: "Pac. autorizó",
    color: "green",
    type: "new-patient",
  },
  {
    id: 5,
    handle: "remodulin",
    title: "REMODULIN",
    color: "pink",
    type: "product-line",
  },
  {
    id: 6,
    handle: "iclusig",
    title: "ICLUSIG",
    color: "blue",
    type: "product-line",
  },
  {
    id: 1,
    handle: "formulation",
    title: "Formulado",
    color: "green",
    type: "state",
  },
  {
    id: 2,
    handle: "formulation-errors",
    title: "Form. con errores",
    color: "red",
    type: "state",
  },
  {
    id: 3,
    handle: "eps-radication",
    title: "RAD. EPS",
    color: "green",
    type: "state",
  },
  {
    id: 4,
    handle: "eps-not-effective",
    title: "Rad. no efectiva",
    color: "red",
    type: "state",
  },

  {
    id: 9,
    handle: "authorized",
    title: "Autorizado",
    color: "green",
    type: "state",
  },
  {
    id: 10,
    handle: "not-authorized",
    title: "No autorizado",
    color: "red",
    type: "state",
  },
  {
    id: 11,
    handle: "olf-radication",
    title: "Rad. EN OLF",
    color: "green",
    type: "state",
  },
  {
    id: 12,
    handle: "olf-not-effective",
    title: "OLF no efectiva",
    color: "red",
    type: "state",
  },
  {
    id: 13,
    handle: "visit-ih",
    title: "Visita intrahospitalaria",
    color: "green lighten-2",
    type: "impact",
  },

  {
    id: 14,
    handle: "home-visit",
    title: "Visita domiciliaria",
    color: "green lighten-2",
    type: "impact",
  },
  {
    id: 15,
    handle: "follow-up",
    title: "Llamada seguimiento",
    color: "green lighten-2",
    type: "impact",
  },
  {
    id: 16,
    handle: "company-appointment",
    title: "ACOMP. CITA",
    color: "green lighten-2",
    type: "impact",
  },
  {
    id: 17,
    handle: "active",
    title: "Activo",
    color: "green",
    type: "state",
  },
  {
    id: 18,
    handle: "query",
    title: "QUERY",
    color: "amber",
    type: "adverse",
  },
  {
    id: 19,
    handle: "event-s",
    title: "EVENTO",
    color: "deep-purple",
    type: "events",
  },
  {
    id: 20,
    handle: "training",
    title: "CAPACITACIÓN",
    color: "purple",
    type: "events",
  },
];
