import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import CustomChart from "components/CustomChart";
import TopDoctorTable from "./topDoctorTable";
import { getUserInfo, isRole } from "app/routes/psp/services/userService";
import { RoleEnum, ShardKeyEnum } from "constants/Enums";
import * as dayjs from "dayjs";
import { Grid, Button } from "@material-ui/core";
import FormDatePicker from "components/Form/Controls/datepicker";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { useForm, FormProvider } from "react-hook-form";
import {
  patientByNurseChart,
  productLineChart,
  patientsByStateChart,
  distributionPatientsByRegion,
  treatmentDeviceChart,
  distributionPatientStatesandRegionChart,
  getTypeTreatmentDoctor,
  graphicTreatmentDeviceByDoctor,
} from "./optionsCharts";
import { callApi, postDashboardNurse } from "api/apiList";
import PatientByEpsAndStateGraph from "./patientByEpsAndStateGraph";
import ChartLogisticOperator from "./chartLogisticOperator";

const getPropArray = (obj, prop) => {
  if (!obj) return [];
  return obj[prop];
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [totalInfo, setTotalInfo] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [typeChart, setTypeChart] = useState(true);
  const [selectEps, setSelectEps] = useState([]);
  const [selectEpsTop, setSelectEpsTop] = useState([]);
  const [selectOpl, setSelectOpl] = useState([]);
  const [selectOplTop, setSelectOplTop] = useState([]);
  const [patientByEpsAndStateData, setPatientByEpsAndStateData] = useState([]);
  const [patientByOplAndRegionData, setPatientByOplAndRegionData] = useState(
    []
  );
  const isNurse = isRole(RoleEnum.nurse);
  const isPhg = getUserInfo().shardKey === ShardKeyEnum.phg;

  const [resetSelectEps, setResetSelectEps] = useState(false);

  var startOf = dayjs().startOf("month");
  var endOf = dayjs().endOf("month");

  const defaultValues = {
    startDate: startOf,
    endDate: endOf,
  };

  const validationSchema = {
    startDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    endDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { errors, trigger, getValues, watch, handleSubmit } = methods;

  const onFilter = (obj) => {
    callApi(
      () => postDashboardNurse(obj),
      (data) => {
        setTotalInfo(data);
        setSelectEps(data.selectEps);
        setPatientByEpsAndStateData(
          data.patientDistributionByEpsAndState?.multiColumnDataCategories || []
        );
        setSelectEpsTop(data.patientDistributionByEpsAndState?.topData || []);
        setSelectOpl(data.selectOpl);
        setPatientByOplAndRegionData(
          data.chartOplByRegional?.multiColumnDataCategories || []
        );
        setSelectOplTop(data.chartOplByRegional?.topData || []);
        setResetSelectEps(!resetSelectEps);
      },
      fnSetLoader
    );
  };

  useEffect(() => {
    const objDate = {
      minDate: dayjs(defaultValues.startDate).format("YYYY-MM-DDTHH:mm:ss"),
      maxDate: dayjs(defaultValues.endDate).format("YYYY-MM-DDTHH:mm:ss"),
    };
    onFilter(objDate);
  }, []);

  const filterSubmit = (d) => {
    const objDate = {
      minDate: dayjs(d.startDate).format("YYYY-MM-DDTHH:mm:ss"),
      maxDate: dayjs(d.endDate).format("YYYY-MM-DDTHH:mm:ss"),
    };
    onFilter(objDate);
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-2">
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <>
              <Grid item xs={12} md={3} lg={3}>
                <FormDatePicker
                  name="startDate"
                  label={<IntlMessages id="core.startDate" />}
                  format="YYYY-MM-DD"
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <FormDatePicker
                  name="endDate"
                  label={<IntlMessages id="core.endDate" />}
                  format="YYYY-MM-DD"
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Button
                  onClick={handleSubmit(filterSubmit)}
                  className="mt-3"
                  variant="contained"
                  color="primary"
                >
                  <IntlMessages id="core.filter" />
                </Button>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Button
                  onClick={() => {
                    setTypeChart(!typeChart);
                  }}
                  className="mt-3"
                  variant="contained"
                  color="primary"
                >
                  <IntlMessages id="core.change" />
                </Button>
              </Grid>
            </>
          </Grid>
        </FormProvider>
      </div>

      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
        <div className="row mb-2">
          <div className="col-12">
            {typeChart && (
              <CustomChart
                options={patientByNurseChart(
                  getPropArray(totalInfo, "chartPatientByNurse"),
                  true
                )}
              />
            )}
            {!typeChart && (
              <CustomChart
                options={patientByNurseChart(
                  getPropArray(totalInfo, "chartPatientByNurse"),
                  false
                )}
              />
            )}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-xl-6 col-lg-6 col-12">
            {typeChart && (
              <CustomChart
                options={productLineChart(
                  getPropArray(totalInfo, "chartProductLine"),
                  typeChart
                )}
              />
            )}
            {!typeChart && (
              <CustomChart
                options={productLineChart(
                  getPropArray(totalInfo, "chartProductLine"),
                  typeChart
                )}
              />
            )}
          </div>
          <div className="col-xl-6 col-lg-6 col-12">
            {typeChart && (
              <CustomChart
                options={patientsByStateChart(
                  getPropArray(totalInfo, "chartPatientByState"),
                  typeChart
                )}
              />
            )}
            {!typeChart && (
              <CustomChart
                options={patientsByStateChart(
                  getPropArray(totalInfo, "chartPatientByState"),
                  typeChart
                )}
              />
            )}
          </div>
        </div>
        {isPhg && (
          <div className="row mb-2">
            <div className="col-xl-6 col-lg-6 col-12">
              {typeChart && (
                <CustomChart
                  options={distributionPatientsByRegion(
                    getPropArray(totalInfo, "chartPatientByRegion"),
                    typeChart
                  )}
                />
              )}
              {!typeChart && (
                <CustomChart
                  options={distributionPatientsByRegion(
                    getPropArray(totalInfo, "chartPatientByRegion"),
                    typeChart
                  )}
                />
              )}
            </div>
            <div className="col-xl-6 col-lg-6 col-12">
              {typeChart && (
                <CustomChart
                  options={treatmentDeviceChart(
                    getPropArray(totalInfo, "treatmentDevice"),
                    typeChart
                  )}
                />
              )}
              {!typeChart && (
                <CustomChart
                  options={treatmentDeviceChart(
                    getPropArray(totalInfo, "treatmentDevice"),
                    typeChart
                  )}
                />
              )}
            </div>
          </div>
        )}
        {isPhg && (
          <>
            <div className="row mb-2">
              <div className="col-12">
                <div className="col-xl-12 col-lg-12 col-12">
                  <CustomChart
                    options={distributionPatientStatesandRegionChart(
                      getPropArray(totalInfo, "chartPatientByStateAndRegion")
                    )}
                  />
                </div>
              </div>
            </div>
            <PatientByEpsAndStateGraph
              epsOptions={selectEps}
              minDate={dayjs(watch("startDate")).format("YYYY-MM-DDTHH:mm:ss")}
              maxDate={dayjs(watch("endDate")).format("YYYY-MM-DDTHH:mm:ss")}
              data={patientByEpsAndStateData}
              setData={setPatientByEpsAndStateData}
              resetSelectEps={resetSelectEps}
              top={selectEpsTop}
            />
            <ChartLogisticOperator
              ipsOptions={selectOpl}
              minDate={dayjs(watch("startDate")).format("YYYY-MM-DDTHH:mm:ss")}
              maxDate={dayjs(watch("endDate")).format("YYYY-MM-DDTHH:mm:ss")}
              data={patientByOplAndRegionData}
              setData={setPatientByOplAndRegionData}
              resetSelectEps={resetSelectEps}
              top={selectOplTop}
            />
            <div className="col-xl-12 col-lg-12 col-12">
              {typeChart && (
                <CustomChart
                  options={getTypeTreatmentDoctor(
                    getPropArray(totalInfo, "treatmentDeviceByDoctor"),
                    typeChart
                  )}
                />
              )}
              {!typeChart && (
                <CustomChart
                  options={getTypeTreatmentDoctor(
                    getPropArray(totalInfo, "treatmentDeviceByDoctor"),
                    typeChart
                  )}
                />
              )}
            </div>
            <div className="row mb-2">
              <div className="col-12">
                <div className="col-xl-12 col-lg-12 col-12">
                  <CustomChart
                    options={graphicTreatmentDeviceByDoctor(
                      getPropArray(totalInfo, "chartTreatmentByDoctorAndDevice")
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="row">
          <div className="col-12">
            {!isNurse && (
              <TopDoctorTable
                data={totalInfo ? totalInfo.topDoctors : []}
                minDate={watch("startDate")}
                maxDate={watch("endDate")}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
