import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Grid } from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete, { getCboValue, getCboValues } from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { SwitchYesNo } from "components/Form/Controls/switchYesNo";
import { DialogConfirmAsync } from "components/Form/Controls/DialogConfirm";
import BtnNullify from "components/AdverseEvent/BtnNullify";
import { setLoader } from "actions/Base";
import {
  callApi,
  callApiAsync,
  postAdverseEventSave,
  postAdverseEventUpdateState,
  getPrescriptions
} from "api/apiList";
import {  
  setSelectedPrescription, 
  loadPrescriptions
} from "actions/Management";
import {
  ForeiaDetectionList,
  ForeiaClassificationList,
  ForeiaDenouementList,
  genderList,
  AdverseEventStateEnum,
  RoleEnum,
  ForeiaDenouementEnum,
  QueryAdverseEventStateEnum
} from "constants/Enums";
import { getRoleUser } from "app/routes/psp/services/userService";

const Technovigilance = ({obj}) =>{
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const { selectedMenuItem } = useSelector(({ management }) => management);
  
  const [causeOptionsList, setCauseOptionsList] = useState([]);
  const [reportedToManufacturerState, setReportedToManufacturerState] = useState(false);
  const [sentToManufacturerState, setSentToManufacturerState] = useState(false);
  const [dlgConfirm, setDlgConfirm] = useState(false);
  const [objSendFormat, setObjSendFormat] = useState(null);

  const foreiaDetectionList = ForeiaDetectionList();
  const foreiaClassificationList = ForeiaClassificationList();
  const foreiaDenouementList = ForeiaDenouementList();
  const genderrList = genderList();
  const userInfo = getRoleUser();
  const isNurseProfile = () =>{
    const role = userInfo.role.toUpperCase();
    return role === RoleEnum.nurse;
  };
  
  const msgSuccess = IntlGetText("core.SuccessSave");

  const defaultValues = {
    //Patient info
    documentType: "",
    document: "",
    gender: null,
    age: "",
    unitAge: "",
    disease:"",

    //medical device
    genericName:"",             //readOnly
    tradename:"",               //readOnly
    healthRegister: "",         //readOnly
    medicineLotNumber: "",
    deviceModel: "",            //readOnly
    deviceReference: "",        //readOnly
    deviceSerial:"",
    manufacturer:"",            //readOnly
    distributorImporter: "",    //readOnly
    operatingArea: "",
    usedMoreThanOnce: false,

    //Adverse event info
    adverseEventStartDate: null,
    creationDate: null, //reportPreparationDate?
    detection: null,
    classification: null,
    descriptionAdverseEvent: "",
    denouement: null,
    denouementOther: "",

    //Management
    causes: null,
    actions: "",
    reportedToManufacturer: false,
    reportedToManufacturerDate: null,
    availableForEvaluation: false,
    sentToManufacturer: false,
    sentToManufacturerDate: null,

    //Reporter info
    reporterName: "",
    reporterProfession: "",
    reporterArea: "",
    address:"",
    phone:"",
    reporterStateProvince: "",
    reporterCity: "",
    email:"",
    notificationDate: null, //Fecha en la que se envía a farmacovigilancia
    authorizesDisclosure: false
  };
  
  //yup array validation https://github.com/jquense/yup/issues/725
  const validationSchema = {
    medicineLotNumber: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    deviceSerial: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    operatingArea: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    adverseEventStartDate: yup.date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    detection: yup .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    classification: yup .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    descriptionAdverseEvent: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    denouement: yup .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    denouementOther: yup.string().when("denouement", {
      is: denouement => denouement && denouementValue.value === ForeiaDenouementEnum.other,
      then: yup.string().required(<IntlMessages id="core.fieldRequired" />)
    }),
    causes: yup.array()
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    actions: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    reportedToManufacturerDate: yup.date().nullable().when("reportedToManufacturer", {
      is: () => reportedToManufacturerState,
      then: yup.date()
        .nullable(true)
        .typeError(IntlGetText("yup.InvalidDate"))
        .required(<IntlMessages id="core.fieldRequired" />)
    }),
    sentToManufacturerDate: yup.date().nullable().when("sentToManufacturer", {
      is: () => sentToManufacturerState,
      then: yup.date()
        .nullable(true)
        .typeError(IntlGetText("yup.InvalidDate"))
        .required(<IntlMessages id="core.fieldRequired" />)
    }),
    notificationDate: yup.date()
    .nullable(true)
    .typeError(IntlGetText("yup.InvalidDate"))
    .required(<IntlMessages id="core.fieldRequired" />)
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, watch, errors, getValues, clearErrors } = methods;

  const reportedToManufacturerValue = watch("reportedToManufacturer");
  const sentToManufacturerValue = watch("sentToManufacturer");
  const denouementValue = watch("denouement");

  useEffect(()=>{
    setReportedToManufacturerState(reportedToManufacturerValue);
    if(!reportedToManufacturerValue)
      setValue("reportedToManufacturerDate", null);
  },[reportedToManufacturerValue]);
  useEffect(()=>{
    setSentToManufacturerState(sentToManufacturerValue);
    if(!sentToManufacturerValue)
      setValue("sentToManufacturerDate", null);
  },[sentToManufacturerValue]);
  useEffect(()=>{
    if(!denouementValue || denouementValue.value !== ForeiaDenouementEnum.other)
      setValue("denouementOther", "");
  },[denouementValue]);

  const setString = (obj, prop) => setValue(prop, !obj || !obj[prop] ? "" : obj[prop]);
  const setDate = (obj, prop) => setValue(prop, !obj || !obj[prop] ? null : dayjs(obj[prop]));
  const setCboValue = (obj, prop, list) => setValue(prop, !obj ? null : getCboValue(list, obj[prop]));
  const setBool = (obj, prop) => setValue(prop, !obj || !obj[prop] ? false : obj[prop]);

  const loadData = (data) => {
    const technovigilance = !data ? null : data.technovigilance;

    if(data){
      setValue("age",`${data.age} (${data.unitAge})`);
      setCauseOptionsList(data.causeOptions);
    }

    setString(data, "documentType");
    setString(data, "document");
    setCboValue(data,"gender",genderrList);
    setString(data, "disease");

    setString(data, "genericName");
    setString(data, "tradename");
    setString(technovigilance, "healthRegister");
    setString(data, "medicineLotNumber");
    setString(technovigilance, "deviceModel");
    setString(technovigilance, "deviceReference");
    setString(technovigilance, "deviceSerial");
    setString(technovigilance, "manufacturer");
    setString(technovigilance, "distributorImporter");
    setString(technovigilance, "operatingArea");
    setBool(technovigilance, "usedMoreThanOnce");

    setDate(data,"adverseEventStartDate");
    setDate(data,"creationDate");
    setCboValue(technovigilance,"detection",foreiaDetectionList);
    setCboValue(technovigilance,"classification",foreiaClassificationList);
    setString(data, "descriptionAdverseEvent");
    setCboValue(technovigilance,"denouement",foreiaDenouementList);
    setString(technovigilance, "denouementOther");
    
    setString(technovigilance, "actions");
    setBool(technovigilance, "reportedToManufacturer");
    setDate(technovigilance,"reportedToManufacturerDate");
    setBool(technovigilance, "availableForEvaluation");
    setBool(technovigilance, "sentToManufacturer");
    setDate(technovigilance,"sentToManufacturerDate");

    setString(technovigilance, "reporterName");
    setString(technovigilance, "reporterProfession");
    setString(technovigilance, "reporterArea");
    setString(technovigilance, "address");
    setString(technovigilance, "phone");
    setString(technovigilance, "reporterStateProvince");
    setString(technovigilance, "reporterCity");
    setString(technovigilance, "email");
    setDate(data,"notificationDate");
    setBool(technovigilance, "authorizesDisclosure");
  };

  useEffect(()=>{
    if(obj){
      loadData(obj);
    }
  },[obj]);

  useEffect(()=>{
    if(causeOptionsList)
      setValue("causes",getCboValues(causeOptionsList, obj.technovigilance.causes));
  },[causeOptionsList]);

  const onSubmit = async (d, e) => {
    if(!d)
      d = getValues();

    let result0 = !obj ? {} : {...obj};
    var result = {
      ...result0,
      adverseEventStartDate: !d.adverseEventStartDate ? null : dayjs(d.adverseEventStartDate).format("YYYY-MM-DDTHH:mm:ss"),
      descriptionAdverseEvent: d.descriptionAdverseEvent,
      medicineLotNumber: d.medicineLotNumber,
      notificationDate: !d.notificationDate ? null : dayjs(d.notificationDate).format("YYYY-MM-DDTHH:mm:ss"),

      technovigilance: {
        deviceSerial: d.deviceSerial,
        operatingArea: d.operatingArea,
        usedMoreThanOnce: d.usedMoreThanOnce,

        detection: !d.detection? null : Number(d.detection.value),
        classification: !d.classification? null : Number(d.classification.value),
        denouement: !d.denouement? null : Number(d.denouement.value),
        denouementOther: d.denouementOther,

        causes: !d.causes ? [] : d.causes.map(x=>Number(x.value)),
        actions: d.actions,
        reportedToManufacturer: d.reportedToManufacturer,
        reportedToManufacturerDate: !d.reportedToManufacturerDate ? null : dayjs(d.reportedToManufacturerDate).format("YYYY-MM-DDTHH:mm:ss"),
        availableForEvaluation: d.availableForEvaluation,
        sentToManufacturer: d.sentToManufacturer,
        sentToManufacturerDate: !d.sentToManufacturerDate ? null : dayjs(d.sentToManufacturerDate).format("YYYY-MM-DDTHH:mm:ss"),
        
        authorizesDisclosure: d.authorizesDisclosure
      }
    };    

    clearErrors();
    await callApiAsync(
      ()=> postAdverseEventSave(result),
      ()=> NotificationManager.success(msgSuccess),
      fnSetLoader
    );
  };
  
  const callSentFormatAsync = async (objData) => {
    await callApiAsync(
      ()=> postAdverseEventUpdateState(objData),
      ()=> {
        callApi(
          ()=> getPrescriptions(selectedMenuItem), 
          (data)=>{
              dispatch(loadPrescriptions(data, selectedMenuItem));
              dispatch(setSelectedPrescription(null));
          }
        );
      },
      fnSetLoader
    );
  };

  const onBtnSendSubmit = async (d, e) => {
    var objData = {      
      id: obj.id,
      state: AdverseEventStateEnum.pendingToVerify
    };

    await onSubmit();
    
    if(isNurseProfile()){
      setDlgConfirm(true);
      setObjSendFormat(objData);
      return;
    }

    await callSentFormatAsync(obj);
  };

  const isDisabled =()=>{
    if(isNurseProfile()){
      if(obj.status === AdverseEventStateEnum.pendingQuery)
        return !(obj.pendingQuery && obj.pendingQuery.state !== QueryAdverseEventStateEnum.resolved && obj.pendingQuery.assignedToNurse)
      else
        return !(obj.status === AdverseEventStateEnum.pending || obj.status === AdverseEventStateEnum.inconsistentReport);
    }
    else {
      if(obj.status === AdverseEventStateEnum.pendingQuery)
        return !(obj.pendingQuery && (!obj.pendingQuery.assignedToNurse || obj.pendingQuery.state === QueryAdverseEventStateEnum.resolved))
      else
        return obj.status !== AdverseEventStateEnum.pendingToVerify;
    }
  };
  const disabled = isDisabled();
  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
      <div className="text-right">
        <BtnNullify adverseEvent={obj}/>
      </div>
      <FormProvider {...methods}>
        <form className="row" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} lg={2}>
              <FormInput
                name="documentType"
                label={<IntlMessages id="core.documentType" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <FormInput
                name="document"
                label={<IntlMessages id="adverseEvent.document" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormSelectAutoComplete
                name="gender"
                label={<IntlMessages id="adverseEvent.gender" />}
                options={genderrList}
                margin="normal"
                disabled={true}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormInput
                name="age"
                label={<IntlMessages id="core.age" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="disease"
                label={<IntlMessages id="foreia.principalDiagnostic" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>


            {/* Medical device */}
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="genericName"
                label={<IntlMessages id="foreia.genericName" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="tradename"
                label={<IntlMessages id="foreia.tradename" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="healthRegister"
                label={<IntlMessages id="foreia.healthRegister" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormInput
                name="medicineLotNumber"
                label={<IntlMessages id="medicineDelivery.lotNumber" />}
                variant="outlined"
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormInput
                name="deviceModel"
                label={<IntlMessages id="foreia.deviceModel" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormInput
                name="deviceReference"
                label={<IntlMessages id="core.reference" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormInput
                name="deviceSerial"
                label={<IntlMessages id="core.serial" />}
                variant="outlined"
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="manufacturer"
                label={<IntlMessages id="foreia.manufacturer" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="distributorImporter"
                label={<IntlMessages id="foreia.distributorImporter" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="operatingArea"
                label={<IntlMessages id="foreia.operatingArea" />}
                variant="outlined"
                multiline
                rows={2}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <label><IntlMessages id="foreia.usedMoreThanOnce" /></label>
              <SwitchYesNo name="usedMoreThanOnce" className="ml-5" disabled={disabled}/>
            </Grid>


            {/* Adverse event info */}
            <Grid item xs={12} md={6} lg={6}>
              <FormDatePicker
                name="adverseEventStartDate"
                label={<IntlMessages id="foreia.adverseEventStartDate" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormDatePicker
                name="creationDate"
                label={<IntlMessages id="foreia.reportDate" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="detection"
                label={<IntlMessages id="foreia.detection" />}
                options={foreiaDetectionList}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="classification"
                label={<IntlMessages id="foreia.classification" />}
                options={foreiaClassificationList}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="descriptionAdverseEvent"
                label={<IntlMessages id="foreia.description" />}
                variant="outlined"
                multiline
                rows={4}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="denouement"
                label={<IntlMessages id="foreia.denouement" />}
                options={foreiaDenouementList}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="denouementOther"
                label={<IntlMessages id="foreia.denouementOther" />}
                variant="outlined"
                margin="normal"
                required={!(disabled || !denouementValue || denouementValue.value !== ForeiaDenouementEnum.other)}
                disabled={disabled || !denouementValue || denouementValue.value !== ForeiaDenouementEnum.other}
                errorobj={errors}
              />
            </Grid>


            {/* Management */}            
            <Grid item xs={12} md={12} lg={12}>
              <FormSelectAutoComplete
                name="causes"
                label={<IntlMessages id="foreia.cause" />}
                isMulti
                options={causeOptionsList}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="actions"
                label={<IntlMessages id="foreia.actions" />}
                variant="outlined"
                multiline
                rows={3}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <label><IntlMessages id="foreia.reportedToManufacturer" /></label>
              <SwitchYesNo name="reportedToManufacturer" className="ml-5" disabled={disabled}/>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormDatePicker
                name="reportedToManufacturerDate"
                label={<IntlMessages id="foreia.reportedToManufacturerDate" />}
                format="YYYY-MM-DD"
                margin="normal"
                required={!(disabled || !reportedToManufacturerValue)}
                disabled={disabled || !reportedToManufacturerValue}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <label><IntlMessages id="foreia.availableForEvaluation" /></label>
              <SwitchYesNo name="availableForEvaluation" className="ml-5" disabled={disabled}/>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <label><IntlMessages id="foreia.sentToManufacturer" /></label>
              <SwitchYesNo name="sentToManufacturer" className="ml-5" disabled={disabled}/>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormDatePicker
                name="sentToManufacturerDate"
                label={<IntlMessages id="foreia.sentToManufacturerDate" />}
                format="YYYY-MM-DD"
                margin="normal"
                required={!(disabled || !sentToManufacturerValue)}
                disabled={disabled || !sentToManufacturerValue}
                errorobj={errors}
              />
            </Grid>


            {/* Reporter info */}
            <Grid item xs={12} md={12} lg={12}></Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="reporterName"
                label={<IntlMessages id="core.name" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="reporterProfession"
                label={<IntlMessages id="core.profession" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="reporterArea"
                label={<IntlMessages id="foreia.reporterArea" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="address"
                label={<IntlMessages id="core.address" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="phone"
                label={<IntlMessages id="core.phone" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="reporterStateProvince"
                label={<IntlMessages id="core.stateProvince" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="reporterCity"
                label={<IntlMessages id="core.city" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="email"
                label={<IntlMessages id="foreia.institutionalEmail" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormDatePicker
                name="notificationDate"
                label={<IntlMessages id="foreia.notificationDate" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <label><IntlMessages id="foreia.authorizesDisclosure" /></label>
              <SwitchYesNo name="authorizesDisclosure" className="ml-5" disabled={disabled}/>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      { !disabled &&
        <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
          <ButtonAsync onClick={ obj.status === AdverseEventStateEnum.pending ? onSubmit : handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
          >
            {<IntlMessages id="core.save" />}
          </ButtonAsync>
          { obj.status === AdverseEventStateEnum.pending && isNurseProfile() &&
            <React.Fragment>
              <ButtonAsync onClick={handleSubmit(onBtnSendSubmit)}
                className="ml-3"
                variant="contained"
                color="primary"
              >
                {<IntlMessages id="adverseEvent.btnSendToPharmacovigilance" />}
              </ButtonAsync>
              <DialogConfirmAsync
                openDialog={dlgConfirm}
                title={<IntlMessages id="adverseEvent.dlgSendFormat.title" />}
                text={<IntlMessages id="adverseEvent.dlgSendFormat.confirmText" />}
                handleRequestClose={() => setDlgConfirm(false)}
                onAcceptClickAsync={async () => {
                  await callSentFormatAsync(objSendFormat);
                  setDlgConfirm(false);
                }}
              />
            </React.Fragment>
          }
        </div>
      }     
    </div>      
  );
};

export default Technovigilance;