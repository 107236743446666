import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormDatePicker from "components/Form/Controls/datepicker";
import ButtonAsync from "components/Form/Controls/ButtonAsync";

const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const FormRemodulin = ({ openCRU, handleRequestClose, impactId, obj, handleCreateUpdate, medicineList, readOnly}) => {
  const [currentMedicine, setCurrentMedicine] = useState(null);

  const defaultValues = {    
    medicineDeliveryDetailId : null,
    comment : "",
    openingDate: null
  };
  const validationSchema = { 
    medicineDeliveryDetailId: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),    
    comment: yup.string(),
    openingDate: yup.date()
      .nullable()
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />)
  };

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, errors } = methods;
  
  const medicineDeliveryDetailIdValue = watch("medicineDeliveryDetailId");

  useEffect(() => {
    if(!medicineDeliveryDetailIdValue){
      setValue("openingDate", null);
       return;
    }

    const item = medicineList.find(x=>x.id === medicineDeliveryDetailIdValue.value);
    setCurrentMedicine(item);    
    setValue("openingDate", !item || !item.openingDate ? null : dayjs(item.openingDate));
  },[medicineDeliveryDetailIdValue]);

  const loadData = () => {
    setValue("medicineDeliveryDetailId", !obj || !obj.medicineDeliveryDetailId ? "" : getCboValue(medicineList,obj.medicineDeliveryDetailId));
    setValue("comment", !obj || !obj.comment ? "" : obj.comment);
    setValue("openingDate", !obj || !obj.openingDate ? null : dayjs(obj.openingDate));
  };

  useEffect(()=>{
    if(openCRU){
      setTimeout(loadData,150);
    }
  },[openCRU]);

  const onSubmit = async (d, e) => {
    var item = {
      id: !obj || !obj.id ? 0 : obj.id,
      impactId: impactId,
      medicineDeliveryDetailId : Number(d.medicineDeliveryDetailId.value),
      comment : !d.comment ? null : d.comment,
      openingDate: dayjs(d.openingDate).format("YYYY-MM-DDTHH:mm:ss")
    };
    await handleCreateUpdate(item);
  };

  const allowEdit = () => {
    if (!obj || !obj.id) return true;

    if (readOnly) return false;

    return obj.impactId == impactId;
  };
  const disabled = !allowEdit();

  const enableDate = () => {
    return disabled || ((obj && obj.impactId != impactId) || (!obj && currentMedicine && currentMedicine.openingDate));
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!obj ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <FormSelectAutoComplete
                    name="medicineDeliveryDetailId"
                    label={<IntlMessages id="core.product" />}
                    options={medicineList}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormDatePicker
                    name="openingDate"
                    label={<IntlMessages id="medicineDelivery.openingDate" />}
                    format="YYYY-MM-DD"
                    margin="normal"
                    disableFuture={true}
                    disabled={enableDate() ? true : false }
                    required={true}
                    errorobj={errors}
                  />                
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    name="comment"
                    label={<IntlMessages id="core.comment" />}
                    margin="normal"
                    multiline
                    rows={3}
                    variant="outlined"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          { !disabled &&
            <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
              <ButtonAsync onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
              >
                {<IntlMessages id="core.save" />}
              </ButtonAsync>
            </div>
          }
        </div>  
      </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>  
  );
};
export default FormRemodulin;
