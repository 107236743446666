import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "actions/Base";
import {
  callApi,
  getDeliveriesReportFile
} from "api/apiList";
import Table, { defaultOptions, defaultCol, dateColumn, numberCol } from "components/Form/Table";
import { currencyFormat } from "services/formatNumber";
import AppLocale from "lngProvider";

const DeliveriesReport = ({ dateRange }) => {
  const dispatch = useDispatch();
  const { locale } = useSelector(({ settings }) => settings);
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [reportData, setReportData] = useState([]);

  const currentAppLocale = AppLocale[locale.locale];
  const currentLocale = currentAppLocale.locale;

  useEffect(() => {
    if (!dateRange) return;

    callApi(
      () => getDeliveriesReportFile(dateRange.startDate, dateRange.endDate, false),
      (data) => setReportData(data.dataTable),
      fnSetLoader
    );
  }, [dateRange]);

  const columns = [
    defaultCol("nurse", "role.callAgent"),
    dateColumn("impactDate", "core.impactDate"),
    defaultCol("patientDocument", "adverseEvent.document"),
    defaultCol("patient", "core.patient"),
    defaultCol("marketchain", "reports.marketChain"),
    defaultCol("prescriptionCode", "core.mipres"),
    dateColumn("expeditionDate", "core.expeditionDateShort"),
    dateColumn("expirationDate", "core.expirationDate"),
    defaultCol("causal", "core.causal"),
    defaultCol("state", "core.state"),
    defaultCol("numberDeliveries", "insuranceAuthorization.deliveryNumber"),
    defaultCol("medicineAmount", "core.totalAmount"),
    numberCol("unitValue", "core.unitValue", currentLocale, currencyFormat),
    defaultCol("product", "core.product"),
    defaultCol("deliveredAmount", "medicineDelivery.medicineAmount"),
    dateColumn("deliveryDate", "radicadoOLF.dlvrDate"),
    dateColumn("planningDate", "reports.planninDate"),
    defaultCol("deliveryMode", "dashboard.charTitle.deliveryMode"),
    // defaultCol("city", "core.city"),
    // defaultCol("insurance", "core.insurance"),
    // defaultCol("point", "insuranceAuthorization.marketChainBranch"),
    // defaultCol("comment", "core.comment"),
  ];

  return (
    <>        
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-2 mb-5">
        <Table
          className="border-dialog-card mt-2 mb-2"
          data={reportData}
          columns={columns}
          options={defaultOptions()}
        />
      </div>
    </>
  );
};

export default DeliveriesReport;
