import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { Grid } from "@material-ui/core";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import FormSelectAutoComplete, { getCboValue } from "components/Form/Controls/select-autocomplete";
import DialogGeneric from "components/Form/DialogGeneric";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {DlgConfirmCustomAsync} from "components/Form/Controls/DialogConfirm";
import { callApiAsync, postUpdateConfig } from "api/apiList";

const FormPatientState = ({openDlg, closeDialog, selectedPrescription, callBack}) => {  
  const [openDlgConfirm, setOpenDlgConfirm] = useState(false);
  const [objInfo, setObjInfo] = useState(null);
  
  const settingList = [
    {id:1, name: IntlGetText("patientSetting.processAndTracing")},
    {id:2, name: IntlGetText("patientSetting.onlyTracing")},
  ];
  
  const msgSuccess = IntlGetText("core.SuccessSave");

  const defaultValues = { setting: null };
  const validationSchema = {
    setting: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable()
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, errors } = methods;

  useEffect(()=>{
    if(!openDlg)
      return;
    
    setTimeout(() => {
      var isOnlyTracing = selectedPrescription.patient.isOnlyTracing;
      setValue("setting", getCboValue(settingList, isOnlyTracing ? 2 : 1));
    }, 200);
  },[openDlg]);

  const onSubmit = handleSubmit(async (d) => {
    var obj = {
      impactId: selectedPrescription.impactId,
      isOnlyTracing: Number(d.setting.value) === 2,
    };

    const isOnlyTracing = selectedPrescription.patient.isOnlyTracing === true;
    if(obj.isOnlyTracing === isOnlyTracing){
      closeDialog();
      return;
    }

    setObjInfo(obj);
    setOpenDlgConfirm(true);
  });

  const postSaveChangeAsync = async () => {
    if(!objInfo)
      return;

    await callApiAsync(
      ()=>postUpdateConfig(objInfo),
      ()=>{
        NotificationManager.success(msgSuccess);
        closeDialog();
        if(callBack)
           callBack();

        // const prescription = {
        //   ...selectedPrescription,
        //   patient:{
        //     ...selectedPrescription.patient,
        //     isOnlyTracing: objInfo.isOnlyTracing
        //   }
        // }
        // dispatch(updateSelectedPrescription(prescription));

        // if(callBack)
        //   callBack();
        // closeDialog();
      }
    );
  };

  return (
    <DialogGeneric
        open={openDlg}
        closeDialog={closeDialog}
        titleTextId={"core.setting"}
      >
      <div className="col-xl-12 col-lg-12 col-md-12 col-12">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <FormSelectAutoComplete
                  name="setting"
                  label={<IntlMessages id="core.setting" />}
                  options={settingList}
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4 mb-4">
          <ButtonAsync onClick={onSubmit}
            variant="contained"
            color="primary">
            {<IntlMessages id="core.save" />}
          </ButtonAsync>
        </div>
      </div>
      <DlgConfirmCustomAsync
        open={openDlgConfirm}
        titleTextId="patientSetting.dlgConfirmTitle"
        handleRequestClose={() => setOpenDlgConfirm(false)}
        onAcceptClickAsync={postSaveChangeAsync}
      >
        <div className="row">
          <IntlMessages id={objInfo && objInfo.isOnlyTracing ? "patientSetting.dlgConfirmMsgOnlyTracing" : "patientSetting.dlgConfirmMsg"} />
        </div>
      </DlgConfirmCustomAsync>
    </DialogGeneric>
  );
};

export default FormPatientState;
