import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, CircularProgress } from "@material-ui/core";
import SideBar from "./sideBar";
import SearchHeader from "./searchHeader";
import ManageContent from "./ManageContent";
import { onToggleDrawer, loadManageModule } from "actions/Management";
import { callApi, getProductsAndStates } from "../../../../api/apiList";
import { setLoader } from "actions/Base";
import { FolderType } from "constants/Enums";

const Management = () => {
    const dispatch = useDispatch();
    const fnSetLoader = (data) => dispatch(setLoader(data));
    const { width } = useSelector(({ settings }) => settings);
    const { loader, drawerState, selectedMenuItem, selectedPrescription } = useSelector(({ management }) => management);

    const handleToggleDrawer = () => dispatch(onToggleDrawer());    
    
    useEffect(() => {
      callApi(
        getProductsAndStates,
        ({productLines,prescriptionStates})=>{dispatch(loadManageModule(productLines,prescriptionStates));},        
        fnSetLoader
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <div className="app-wrapper">
          <div className="animated slideInUpTiny animation-duration-3">
            <div className="app-module">
              <div className="d-block d-xl-none">
                <Drawer open={drawerState} onClose={handleToggleDrawer}>
                  {SideBar()}
                </Drawer>
              </div>
              <div className="app-module-sidenav d-none d-xl-flex">
                {SideBar()}
              </div>    
              <div className="module-box">
                { (!selectedPrescription || selectedMenuItem !== FolderType.adverseEvents)
                  && <SearchHeader/>
                }
                <div className="module-box-content">    
                  {loader ? (
                    <div
                      className="loader-view"
                      style={{
                        height:
                          width >= 1200
                            ? "calc(100vh - 259px)"
                            : "calc(100vh - 238px)",
                      }}
                    >
                      <CircularProgress />
                    </div>
                   ) : 
                  <ManageContent/>}
                </div>              
              </div>            
            </div>
          </div>
        </div>
    );
};    

export default Management;