import {
  DRAWER_TYPE,
  FIXED_DRAWER,
  HORIZONTAL_MENU_POSITION,
  INSIDE_THE_HEADER,
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  HORIZONTAL_NAVIGATION,
  WINDOW_WIDTH,
} from "constants/ActionTypes";
import { CYAN } from "constants/ThemeColors";

const initialSettings = {
  navCollapsed: false,
  drawerType: FIXED_DRAWER,
  themeColor: CYAN,
  darkTheme: false,
  width: window.innerWidth,
  navigationStyle: HORIZONTAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  locale: {
    languageId: "spanish",
    locale: "es",
    name: "Spanish",
    icon: "es",
  },
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        navCollapsed: false,
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: !state.navCollapsed,
      };
    case DRAWER_TYPE:
      return {
        ...state,
        drawerType: action.drawerType,
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload
      };

    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload,
      };

    default:
      return state;
  }
};

export default settings;
