import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import IntlMessages from "util/IntlMessages";
import { Grid, Button } from "@material-ui/core";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import ImpactTable from "./ImpactTable";
import ProcessDetail from "./ProcessDetail";
import FormulaInfo from "./FormulaInfo";
import ReduceFormula from "./ReduceFormula";
import { setLoader } from "actions/Base";
import {
  isOnlyTracing,
  isDirectTracing,
} from "app/routes/psp/services/userService";
import {
  callApi,
  callApiAsync,
  getPrescriptionByPatient,
  getImpactsByPrescription,
} from "api/apiList";

const PatientFollowUp = ({ patient }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));

  const [prescriptionList, setPrescriptionList] = useState([]);
  const [impactList, setImpactList] = useState([]);
  const [openDlg, setOpenDlg] = useState(false);
  const [openDlgFormula, setOpenDlgFormula] = useState(false);
  const [openDlgReduceFormula, setOpenDlgReduceFormula] = useState(false);
  const [currentPrescriptionId, setCurrentPrescriptionId] = useState(null);

  const isOnlyTrace = isOnlyTracing();
  const isDirectTracingConfig = isDirectTracing();

  useEffect(() => {
    callApi(
      () => getPrescriptionByPatient(patient.id),
      (data) => {
        setPrescriptionList(data);
        if (isOnlyTrace) {
          if (data.length > 0) {
            callApi(() => getImpactsByPrescription(data[0].id), setImpactList);
          }
        }
      }
    );
  }, []);

  const defaultValues = { prescriptionId: "" };
  const methods = useForm({ defaultValues });
  const { handleSubmit, getValues } = methods;

  const loadImpacts = async (prescriptionId) => {
    if (!prescriptionId) {
      setImpactList([]);
      return;
    }

    await callApiAsync(
      () => getImpactsByPrescription(prescriptionId),
      (data) => {
        //setCurrentPrescriptionId(Number(d.prescriptionId.value));
        setImpactList(data);
      },
      fnSetLoader
    );
  };

  const onSubmit = async (d, e) => {
    if (!d.prescriptionId) {
      setImpactList([]);
      return;
    }

    await loadImpacts(Number(d.prescriptionId.value));
  };

  const onClickProcessDetail = () => {
    const d = getValues();
    if (!d || !d.prescriptionId) {
      setCurrentPrescriptionId(null);
      return;
    }
    setCurrentPrescriptionId(Number(d.prescriptionId.value));
    setOpenDlg(true);
  };

  const onGetFormulaInfo = () => {
    const d = getValues();
    if (!d || !d.prescriptionId) {
      setCurrentPrescriptionId(null);
      return;
    }
    setCurrentPrescriptionId(Number(d.prescriptionId.value));

    if (isDirectTracingConfig == true) {
      setOpenDlgReduceFormula(true);
      return;
    }

    setOpenDlgFormula(true);
  };

  return (
    <React.Fragment>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              {!isOnlyTrace && (
                <Grid item xs={12} md={8} lg={8}>
                  <FormSelectAutoComplete
                    name="prescriptionId"
                    label={<IntlMessages id="core.prescription" />}
                    options={prescriptionList}
                    onChange={handleSubmit(onSubmit)}
                    margin="normal"
                    className="mr-2"
                  />
                </Grid>
              )}
              <Grid item xs={12} md={4} lg={4}>
                {!isOnlyTrace && (
                  <>
                    <Button
                      onClick={onClickProcessDetail}
                      variant="contained"
                      color="primary"
                      className="mt-3"
                    >
                      {<IntlMessages id="patient.processDetail" />}
                    </Button>
                    {!isDirectTracingConfig && (
                      <Button
                        onClick={onGetFormulaInfo}
                        variant="contained"
                        color="primary"
                        className="mt-3 ml-2"
                      >
                        {
                          <IntlMessages id="approveFormula.dialogConfirmTitle" />
                        }
                      </Button>
                    )}
                  </>
                )}
                <ProcessDetail
                  currentPrescriptionId={currentPrescriptionId}
                  openDlg={openDlg}
                  handleRequestClose={() => setOpenDlg(false)}
                />
                <FormulaInfo
                  currentPrescriptionId={currentPrescriptionId}
                  openDlg={openDlgFormula}
                  handleRequestClose={() => setOpenDlgFormula(false)}
                />
                <ReduceFormula
                  currentPrescriptionId={currentPrescriptionId}
                  openDlg={openDlgReduceFormula}
                  handleRequestClose={() => setOpenDlgReduceFormula(false)}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <br></br>
      <ImpactTable impacts={impactList} />
    </React.Fragment>
  );
};

export default PatientFollowUp;
