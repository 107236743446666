import React from "react";
import Button from "@material-ui/core/Button";

const NotificationItem = ({ notification }) => {
  const { icon, title, time } = notification;
  return (
    <li className="media">
      <div className="media-body align-self-center">
        <p className="sub-heading mb-0">{title}</p>
        <Button
          onClick={() => {
            window.location.href = "/app/doctor";
          }}
          size="small"
          className="jr-btn jr-btn-xs mb-0"
        >
          <i className={`zmdi ${icon} zmdi-hc-fw`} />
        </Button>{" "}
        <span className="meta-date">
          <small>{time}</small>
        </span>
      </div>
    </li>
  );
};

export default NotificationItem;
