import React, { useState } from "react";
import InfoCard from "components/InfoCard/index";
import { IntlGetText } from "util/IntlMessages";
import EndingForm from "../modalForm/endingForm";
import DlgDownloadImpactFile from "../modalForm/dlgDownloadImpactFile";

const Ending = (props) => {
  const { impactInfo, isInstitutionManager } = props;
  const [openDlgPatient, setOpenDlgPatient] = useState(false);
  const [openDlgDownload, setOpenDlgDownload] = useState(false);
  return (
    <>
      <InfoCard
        data={{
          icon: "assignment-check",
          title: IntlGetText("institution.ending"),
          color: !impactInfo || !impactInfo.impactFile
              ? "#757575"
              : "#20c997"
        }}
        onClickBox={() => {
          if(impactInfo && impactInfo.impactFile)
            setOpenDlgDownload(true);
          else{
            if(isInstitutionManager)
              return;
            setOpenDlgPatient(true);
          }
        }}
      />

      <EndingForm
        openDlg={openDlgPatient}
        setOpenDlg={setOpenDlgPatient}
        closeDialog={() => setOpenDlgPatient(false)}
        {...props}
      />
      <DlgDownloadImpactFile
        openDlg={openDlgDownload}
        setOpenDlg={setOpenDlgDownload}
        closeDialog={() => setOpenDlgDownload(false)}
        {...props}/>
    </>
  );
};

export default Ending;
