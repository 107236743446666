import React from "react";
import CustomScrollbars from 'util/CustomScrollbars';

const ScrollContent = ({children, ...props}) => {
    const { width, heightMin, heightMax, classParent} = props;
    const classContent = classParent === undefined ?  "module-list mail-list" : classParent;
    return (
        <div className={classContent}>
            <CustomScrollbars className="module-list-scroll scrollbar"
                                style={{height: width >= 1200 ? heightMax : heightMin}}>
                {children}
            </CustomScrollbars>
        </div>
    );
};

export default ScrollContent;