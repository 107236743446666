import React, { useState } from "react";
import { IntlGetText } from "util/IntlMessages";
import InfoCard from "components/InfoCard/index";
import Deliveries from "../deliveries";

const DeliveryBox = (props) => {
  const { closeDialog, ...remaining} = props;
  const [openDlg, setOpenDlg] = useState(false);
  const requestCloseDlg = () => setOpenDlg(false);

  return (
    <>
      <InfoCard
        data={{
          icon: "book",
          title: IntlGetText("insuranceAuthorization.deliveries"),
          subTitle: IntlGetText("core.patient"),
          color: "#757575",
        }}
        onClickBox={() => setOpenDlg(true)}
      />
      <Deliveries
        openDlg={openDlg}
        requestCloseDlg={()=>{
          if(closeDialog)
            closeDialog();
          requestCloseDlg();
        }} 
        {...remaining}
      />
    </>
  );
};

export default DeliveryBox;
