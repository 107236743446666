import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Redirect } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  callApiAsync,
  postAdverseEventCreateFollowUpQuery
} from "api/apiList";


const DialogAdd = ({ open, handleRequestClose, adverseEvent, setIsDone }) => {
  const defaultValues = { descriptionQuery: "" };
  const validationSchema = {
    descriptionQuery: yup.string().required(<IntlMessages id="core.fieldRequired" />)
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, errors } = methods;

  const onSubmit = async (d, e) => {
    await callApiAsync(
      () =>
        postAdverseEventCreateFollowUpQuery({
          adverseEventId: adverseEvent.id,
          descriptionQuery: d.descriptionQuery,
        }),
      () => {
        handleRequestClose();
        setIsDone(true);
      }
    );
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle><IntlMessages id="adverseEvent.createFollowUp" /></DialogTitle>
      <DialogContent className="new-patient-dialog-content">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <FormInput
                  name="descriptionQuery"
                  label={<IntlMessages id="adverseEvent.descriptionQuery" />}
                  multiline
                  rows={4}
                  variant="outlined"
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          {<IntlMessages id="core.cancel" />}
        </Button>
        <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
          {<IntlMessages id="core.save" />}
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  );
};


const BtnAddFollowUp = (props) =>{  
  const [openDlg, setOpenDlg] = useState(false);
  const [isDone, setIsDone] = useState(false);

  return (
    <div>
      { isDone && <Redirect to={"/app/adverseEvent"} />}
      <div className="text-right mt-1 mb-1">
      <Button
        onClick={()=>setOpenDlg(true)}
        variant="contained"
        color="primary"
        className="ml-2"
      >
        {<IntlMessages id="adverseEvent.createFollowUp" />}
      </Button>
      </div>
      <DialogAdd
        open= {openDlg}
        handleRequestClose={() => setOpenDlg(false)}
        setIsDone={setIsDone}
        {...props}
      />
    </div>
  );
};

export default BtnAddFollowUp;