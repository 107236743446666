export const getUserInfo = () => {
  const userInfo = localStorage.getItem("userInfo");
  if(!userInfo) return null;
  return JSON.parse(userInfo);
};

export const setUserInfo = (userInfo) => {
  localStorage.setItem("userInfo", JSON.stringify(userInfo));
};

export const removeUserInfo = () => {
  localStorage.removeItem("userInfo");
}

export const isRole = (role) => {
  const userInfo = localStorage.getItem("userInfo");
  if(!userInfo) return false;
  return JSON.parse(userInfo).roles.indexOf(role) !== -1;
};

export const getUserRole = async () => {
  var userInfo = getUserInfo();
  if(!userInfo)
    return { role: "", rolename: "", name: "" };
  
  var role = getRole(userInfo.roles);

  role = role ? role.toLowerCase() : "";
  var rolename = role;
  var name = userInfo.userName;

  return { role, rolename, name };
}

export const getRoleUser = () => {
  var userInfo = getUserInfo();
  if(!userInfo)
    return { role: "", rolename: "", name: "" };
  
  var role = getRole(userInfo.roles);
  role = role ? role.toLowerCase() : "";

  return { 
    role: role, 
    rolename: role, 
    name: userInfo.userName };
};

const getRole=(roles)=>{
  const role = roles.find((x) => {
    x = x.toLowerCase();
    if( x === 'psp_manager' || x === 'manager' || x === 'pharmacovigilance' || x === 'pharma' || x === 'nurse'
    || x === 'nurse_procedure')
      return x;
    return '';
  });
  return role;
};

export const getSettingValue = (settingKey) => {
  var userInfo = getUserInfo();
  if(!userInfo) 
    return null;

  if(!userInfo.settings)
    return null;

  var setting = userInfo.settings.find(x=>x.key == settingKey);
  if(!setting)
    return null;

  return setting.value;
}

export const getBoolSetting = (settingKey) => {
  var settingValue = getSettingValue(settingKey);
  return settingValue === "1";
}

export const isOnlyTracing = () => getBoolSetting("PSP_ONLY_TRACING");

export const isDirectTracing = () => getBoolSetting("PSP_DIRECT_TRACING");

export const isJaasModule = () => getBoolSetting("PSP_JAAS_MODULE");

export const isTracingDeliveryBox = () => getBoolSetting("PSP_TRACING_DELIVERY");