import React from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import IntlMessages from "util/IntlMessages";
import { Button } from "@material-ui/core";
import { callApi } from "api/apiList";
import { downloadFile } from "services/fileService";


const DownloadReportFile = ({ dateRange, actionApi, className="col-lg-12 col-md-12 col-xs-12 text-left mt-2 mb-4" }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));

  const downloadReport = () => {
    if(!actionApi)
      return;
    callApi(
      () => actionApi(dateRange.startDate, dateRange.endDate, true),
      (data) => downloadFile(data),
      fnSetLoader
    );
  };
  return (
    <>
      <div className={className}>
        <Button
          onClick={downloadReport}
          variant="contained"
          color="secondary"
          disabled={dateRange === null}
        >
          <IntlMessages id="core.download" />
        </Button>
      </div>
    </>
  );
};

export default DownloadReportFile;
