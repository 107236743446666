import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tooltip } from "@material-ui/core";
import IntlMessages from "util/IntlMessages";
import { DialogConfirmAsync } from "components/Form/Controls/DialogConfirm";
import { setLoader } from "actions/Base";
import {
  callApiAsync,
  postAdverseEventNullify,
  getPrescriptions
} from "api/apiList";
import { loadPrescriptions, setSelectedPrescription } from "actions/Management";
import { AdverseEventStateEnum } from "constants/Enums";


const BtnNullify = ({ adverseEvent }) => {
  const dispatch = useDispatch();  
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const { selectedMenuItem } = useSelector(({ management }) => management);

  const [openDlg, setOpenDlg] = useState(false);

  const onNullifyAsync = async () => {
    await callApiAsync(
      () => postAdverseEventNullify({ adverseEventId: adverseEvent.id }),
      async () => {
        await callApiAsync(
          ()=> getPrescriptions(selectedMenuItem), 
          (data)=>{
              dispatch(loadPrescriptions(data, selectedMenuItem));
              dispatch(setSelectedPrescription(null));
          }
        );
      },
      fnSetLoader
    );
  };

  return (
    <>
      { adverseEvent && adverseEvent.status === AdverseEventStateEnum.pending &&
        <>
          <Tooltip title={<IntlMessages id="adverseEvent.nullify.dlgTitle" />} placement="bottom-end">
            <Button
              onClick={() => setOpenDlg(true)}
              variant="contained"
              className="ml-1 jr-btn bg-red text-white"
            >
              <i className="zmdi zmdi-delete zmdi-hc-fw" />
            </Button>
          </Tooltip>
          <DialogConfirmAsync
            openDialog={openDlg}
            title={<IntlMessages id="adverseEvent.nullify.dlgTitle" />}
            text={<IntlMessages id="adverseEvent.nullify.dlgText" />}
            handleRequestClose={() => setOpenDlg(false)}
            onAcceptClickAsync={onNullifyAsync}
          />
        </>
      }
    </>
  );
};

export default BtnNullify;
