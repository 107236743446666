import { IntlGetText } from "util/IntlMessages";
import Highcharts from "highcharts";
import { currencyFormat } from "services/formatNumber";

const formatCurrency = (total, decimals) => {
  let value = total;
  if (value == 0) return "$ 0";

  let unit = "";
  if (value < 1000000) {
    unit = "K";
    value = value / 1000;
  } else if (value < 1000000000000) {
    unit = "M";
    value = value / 1000000;
  } else {
    unit = "bn";
    value = value / 1000000000000;
  }

  value = Math.round(value, decimals);
  return `$ ${value}${unit}`;
};

export const enrollChart = (locale, enrollData) => {
  const titleText = IntlGetText("dashboarPA.chartEnrollTitle");
  const yAxisTitle = IntlGetText("core.patients");
  const serie1Title = IntlGetText("chartEnroll.created");
  const serie2Title = IntlGetText("chartEnroll.registered");

  return {
    credits: { enabled: false },
    title: { text: titleText },
    // subtitle: {
    //   text: totalText + ': '+ currencyFormat(locale,total),
    //   align: 'right',
    //   x: -10
    // },
    yAxis: { title: { text: yAxisTitle } },
    xAxis: { categories: enrollData.categories },

    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:10px">Mes {point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    series: [
      {
        name: serie1Title,
        data: enrollData.created,
      },
      {
        name: serie2Title,
        data: enrollData.registered,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };
};

export const pieChart = (
  titleTextKey,
  tooltipKey,
  locale,
  keyValuePairData
) => {
  const titleText = IntlGetText(titleTextKey);
  const stateTypeText = IntlGetText(tooltipKey);
  const totalizationText = IntlGetText("core.amount");
  const totalText = IntlGetText("core.total");

  const data = !keyValuePairData
    ? []
    : keyValuePairData.map((x) => [x.Key, x.Value]);
  const total = !keyValuePairData
    ? 0
    : keyValuePairData
        .map((item) => item.Value)
        .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: "pie" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ": " + currencyFormat(locale, total),
      align: "right",
      x: -10,
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          //format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          formatter: function() {
            return (
              "<b>" +
              currencyFormat(locale, this.y) +
              " (" +
              Highcharts.numberFormat(this.percentage, 1) +
              "%)" +
              "</b>"
            );
          },
        },
      },
    },
    // tooltip: {
    //   pointFormat:
    //     "Instilación: <b>{point.y}</b><br/>EVA: {point.x}",
    // },
    tooltip: {
      formatter: function() {
        return (
          stateTypeText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>" +
          currencyFormat(locale, this.y) +
          " (" +
          Highcharts.numberFormat(this.percentage, 2) +
          " %)" +
          "</b>"
        );
      },
    },
    series: [{ name: stateTypeText, data: data }],
  };
};

export const totalChart = (titleTextKey, tooltipKey, locale, info) => {
  const titleText = IntlGetText(titleTextKey);
  const stateTypeText = IntlGetText(tooltipKey);
  const totalizationText = IntlGetText("core.amount");
  const totalText = IntlGetText("core.total");

  const keyValuePairData = info.data;
  const data = !keyValuePairData
    ? []
    : keyValuePairData.map((x) => [x.Key, x.Value]);
  const total = info.patientCount;

  return {
    credits: { enabled: false },
    chart: { type: "pie" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ": " + currencyFormat(locale, total),
      align: "right",
      x: -10,
    },
    plotOptions: {
      pie: {
        innerSize: 150,
        depth: 45,
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          //format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          formatter: function() {
            return (
              "<b>" +
              currencyFormat(locale, this.y) +
              " (" +
              Highcharts.numberFormat(this.percentage, 1) +
              "%)" +
              "</b>"
            );
          },
        },
      },
    },
    // tooltip: {
    //   pointFormat:
    //     "Instilación: <b>{point.y}</b><br/>EVA: {point.x}",
    // },
    tooltip: {
      formatter: function() {
        return (
          stateTypeText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>" +
          currencyFormat(locale, this.y) +
          " (" +
          Highcharts.numberFormat(this.percentage, 2) +
          " %)" +
          "</b>"
        );
      },
    },
    series: [{ name: stateTypeText, data: data }],
  };
};

export const eficaciaDetailChart = (locale, keyValuePairData) => {
  const titleText = IntlGetText("dashboardMigration.eficaciaRecoveries");
  const stateTypeText = IntlGetText("core.stateType");
  const totalizationText = IntlGetText("dashboard.totalization");
  const totalText = IntlGetText("core.total");

  const data = keyValuePairData.map((x) => [x.Key, x.Value]);
  const total = keyValuePairData
    .map((item) => item.Value)
    .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: "pie" },
    title: { text: titleText },
    subtitle: {
      text: totalText + " $" + currencyFormat(locale, total),
      align: "right",
      x: -10,
    },
    plotOptions: {
      pie: {
        innerSize: 150,
        depth: 45,
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          //format: "<b>{point.name}</b>: {point.y} ({point.percentage:.1f} %)",
          formatter: function() {
            return (
              "<b>$ " +
              currencyFormat(locale, this.y) +
              " (" +
              Highcharts.numberFormat(this.percentage, 1) +
              "%)" +
              "</b>"
            );
          },
        },
      },
    },
    // tooltip: {
    //   pointFormat:
    //     "Instilación: <b>{point.y}</b><br/>EVA: {point.x}",
    // },
    tooltip: {
      formatter: function() {
        return (
          stateTypeText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale, this.y) +
          " (" +
          Highcharts.numberFormat(this.percentage, 2) +
          " %)" +
          "</b>"
        );
      },
    },
    series: [{ name: stateTypeText, data: data }],
  };
};

export const columnChart = (
  titleTextKey,
  tooltipKey,
  locale,
  keyValuePairData,
  yAxisTitleKey = "core.patients"
) => {
  const titleText = IntlGetText(titleTextKey);
  const causalText = IntlGetText(tooltipKey);
  const totalizationText = IntlGetText("core.amount");
  const totalText = IntlGetText("core.total");
  const yAxisTitle = IntlGetText(yAxisTitleKey);

  const categories = !keyValuePairData
    ? []
    : keyValuePairData.map((x) => x.Key);
  const data = !keyValuePairData ? [] : keyValuePairData.map((x) => x.Value);
  const total = !keyValuePairData
    ? 0
    : keyValuePairData
        .map((item) => item.Value)
        .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: "column" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ": " + currencyFormat(locale, total),
      align: "right",
      x: -10,
    },
    xAxis: [{ categories: categories }],
    yAxis: {
      min: 0,
      title: { text: yAxisTitle },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function() {
        return (
          causalText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>" +
          currencyFormat(locale, this.y) +
          "</b>"
        );
      },
    },
    series: [
      {
        name: "",
        type: "column",
        data: data,
        dataLabels: {
          enabled: true,
          formatter: function() {
            return this.y;
          },
          y: 0,
        },
      },
    ],
  };
};

export const recoveriesChart = (
  titleTextKey,
  tooltipKey,
  locale,
  keyValuePairData
) => {
  const titleText = IntlGetText("dashboardMigration.recoveries");
  const causalText = IntlGetText("core.causal");
  const totalizationText = IntlGetText("patientDetail.value");
  const totalText = IntlGetText("core.total");

  const categories = keyValuePairData.map((x) => x.Key);
  const data = keyValuePairData.map((x) => x.Value);
  const total = keyValuePairData
    .map((item) => item.Value)
    .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: "bar" },
    title: { text: titleText },
    subtitle: {
      text: totalText + " $" + currencyFormat(locale, total),
      align: "right",
      x: -10,
    },
    xAxis: [{ categories: categories }],
    yAxis: { visible: false },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function() {
        return (
          causalText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale, this.y) +
          "</b>"
        );
      },
    },
    series: [
      {
        name: "",
        type: "column",
        data: data,
        dataLabels: {
          enabled: true,
          formatter: function() {
            return formatCurrency(this.y, 0);
          },
          y: 0,
        },
      },
    ],
  };
};

export const QuestionChart = (titleTextKey, locale, data) => {
  const titleText = IntlGetText(titleTextKey);
  const totalText = IntlGetText("core.total");
  const instillationText = IntlGetText("role.visitor");

  const categories = !data ? [] : data.categories;
  const series = !data ? [] : data.series;
  const patientCount = !data ? [] : data.count;

  return {
    credits: { enabled: false },
    chart: { type: "column" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ": " + currencyFormat(locale, patientCount),
      align: "right",
      x: -10,
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      title: { text: instillationText },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Cantidad",
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:10px">' +
        instillationText +
        ": {point.key}</span><table>",
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
      series: {
        dataLabels: {
            enabled: true,
            inside: false
        }
      }
    },
    series: series,
  };
};

export const MultiLineChart = (titleTextKey, data, locale) => {
  const titleText = IntlGetText(titleTextKey);
  const categories = !data || data.length === 0 ? undefined : data.categories;

  var dataChart = !data || data.length === 0 ? undefined : data.series;
  var serie1 = [];
  var name1 = " ";
  var serie2 = [];
  var name2 = " ";
  var serie3 = [];
  var name3 = " ";

  if (dataChart) {
    serie1 = dataChart[0].data;
    name1 = dataChart[0].name;
    serie2 = dataChart[1].data;
    name2 = dataChart[1].name;
    serie3 = dataChart[2].data;
    name3 = dataChart[2].name;
  }

  return {
    chart: { type: "line" },
    title: { text: titleText },
    xAxis: {
      categories: categories,
      accessibility: {
        description: "Meses",
      },
    },
    legend: {
      enabled: false,
      reversed: false,
    },
    yAxis: {
      title: {
        text: "Número de pacientes",
      },
    },
    plotOptions: {
      line: {
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1,
        },
        showInLegend: true,
      },
      series: {
        dataLabels: {
            enabled: true,
            inside: false
        }
      },
      showInLegend: true,
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    series: [
      {
        name: name1,
        marker: {
          fillColor: '#FFFFFF',
          symbol: "circle",
          lineColor: '#ffff01'
        },
        data: serie1,
        lineColor: '#ffff01'
      },
      {
        name: name2,
        marker: {
          fillColor: '#FFFFFF',
          symbol: "diamond",
          lineColor: '#7ec134'
        },
        data: serie2,
        lineColor: '#7ec134'
      },
      {
        name: name3,
        marker: {
          fillColor: '#FFFFFF',
          symbol: "square",
          lineColor: '#006fc0'
        },
        data: serie3,
        lineColor: '#006fc0'
      },
    ],

    credits: {
      enabled: false,
    },
  };
};

export const MultiColumnChart = (titleTextKey, data) => {
  const dataChart = !data || data.length === 0 ? [] : data.series;
  const monthChart = !data || data.length === 0 ? [] : data.categories;
  return {
    chart: {
      type: "column",
    },
    title: {
      text: IntlGetText(titleTextKey),
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: monthChart,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    credits: {
      enabled: false,
    },
    series: dataChart,
  };
};
