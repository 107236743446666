import {
    SET_PRESCRIPTION_ID,
  } from "constants/ActionTypes";

  const INIT_STATE = {
    prescriptionId:7,
  };

  export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_PRESCRIPTION_ID: {
          return {
            ...state,
            prescriptionId: action.payload,
          };
        }
    default:
        return state;
    }
  };
  