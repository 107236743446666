import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages from "util/IntlMessages";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete, { getCboValue } from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { RemodulinPumpStateList } from "constants/Enums";

const FormCreateUpdate = ({ openCRU, handleRequestClose, impactId, obj, handleCreateUpdate, readOnly}) => {
  const remodulinPumpStateList = RemodulinPumpStateList();

  const defaultValues = {    
    lotNumber: "",
    serial: "",
    model: "",
    state: null
  };
  const validationSchema = { 
    lotNumber: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    serial: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    model: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    state: yup
    .object()
    .shape({ value: yup.string().required() })
    .required(<IntlMessages id="core.fieldRequired" />)
    .nullable()
  };

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, reset, errors } = methods;

  const loadData = () => {
    setValue("lotNumber", !obj || !obj.lotNumber ? "" : obj.lotNumber);
    setValue("serial", !obj || !obj.serial ? "" : obj.serial);
    setValue("model", !obj || !obj.model ? "" : obj.model);
    setValue("state", !obj || (!obj.state && obj.state !== 0) ? "" : getCboValue(remodulinPumpStateList, obj.state));
  };

  useEffect(()=>{
    if(openCRU){
      setTimeout(loadData,150);
    }
  },[openCRU]);


  const onSubmit = async (d, e) => {
    var item = {
      id: !obj ? 0 : obj.id,
      impactId: impactId,
      lotNumber: d.lotNumber,
      serial: d.serial,
      model: d.model,
      state: Number(d.state.value)
    };
    await handleCreateUpdate(item);
  };

  const disabled = readOnly;
  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!obj ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <FormInput
                    name="lotNumber"
                    label={<IntlMessages id="medicineDelivery.lotNumber" />}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <FormInput
                    name="serial"
                    label={<IntlMessages id="core.serial" />}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <FormInput
                    name="model"
                    label={<IntlMessages id="core.model" />}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormSelectAutoComplete
                    name="state"
                    label={<IntlMessages id="core.state" />}
                    options={remodulinPumpStateList}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          { !disabled &&
            <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
              <ButtonAsync onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
              >
                {<IntlMessages id="core.save" />}
              </ButtonAsync>
            </div>
          }
        </div>  
      </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>  
  );
};
export default FormCreateUpdate;
