import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import ProcessTable from "./ProcessTable";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import DialogReject from "./DialogReject";
import DialogCausality from "./DialogCausality";
import { setLoader } from "actions/Base";
import {
  callApi,
  callApiAsync,
  getProcessDataByAdverseEvent,
  postAdverseEventUpdateState,
  postAdverseEventGenerateFormat
} from "api/apiList";
import { 
  AdverseEventStateEnum, 
  FormatTypeEnum,
  AdverseEventTypeEnum,
  QueryAdverseEventStateEnum
} from "constants/Enums";


const getBtnFormatName = (adverseEvent) => {
  return adverseEvent.adverseEventTypeId === AdverseEventTypeEnum.pharmacovigilance ? 
    "adverseEvent.btnGenerateFormat.foream" : "adverseEvent.btnGenerateFormat.foreia";
};

const getFormatType = (adverseEvent) => {
  return adverseEvent.adverseEventTypeId === AdverseEventTypeEnum.pharmacovigilance ? FormatTypeEnum.foream : FormatTypeEnum.foreia;
};

const getLastStates = (processData) => {
  if(!processData || !processData.adverseEventState)
    return [];
  return !processData.lastStateVerificationId ? [] 
    : processData.adverseEventState.filter(x=>x.id > processData.lastStateVerificationId);
};

const showBtnGenerateFormat =(lastStates, currentState, type)=>{
  if(currentState < AdverseEventStateEnum.verified)
    return false;
  return ((lastStates.find(x=>x.state === AdverseEventStateEnum.generatedFoream) === undefined && type === AdverseEventTypeEnum.pharmacovigilance)
      || (lastStates.find(x=>x.state === AdverseEventStateEnum.generatedForeia) === undefined && type === AdverseEventTypeEnum.technovigilance));
};
const showBtnSendToRegulator =(lastStates)=>{
  return ((lastStates.find(x=>x.state === AdverseEventStateEnum.generatedFoream) !== undefined 
      || lastStates.find(x=>x.state === AdverseEventStateEnum.generatedForeia) !== undefined)
    && lastStates.find(x=>x.state === AdverseEventStateEnum.sentToRegulator) === undefined);
};
const showBtnCausality =(lastStates)=>{
  return (lastStates.find(x=>x.state === AdverseEventStateEnum.sentToRegulator) !== undefined
    && lastStates.find(x=>x.state === AdverseEventStateEnum.generatedCioms) !== undefined
    && lastStates.find(x=>x.state === AdverseEventStateEnum.registeredCausality) === undefined);
};
const showBtnGenerateCioms =(lastStates, currentState)=>{
  return (currentState >= AdverseEventStateEnum.verified
    && lastStates.find(x=>x.state === AdverseEventStateEnum.generatedCioms) === undefined);
};
const showBtnSendToPartner =(lastStates)=>{
  return (lastStates.find(x=>x.state === AdverseEventStateEnum.generatedCioms) !== undefined
    && lastStates.find(x=>x.state === AdverseEventStateEnum.sentToPartner) === undefined);
};


const Dlg = ({ open, closeDialog, adverseEvent, adverseEventRefresh, reloadQueries, queryAdverseEventList }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));

  const [processData, setProcessData] = useState(null);
  const [openDlgReject, setOpenDlgReject] = useState(false);
  const [openDlgCausality, setOpenDlgCausality] = useState(false);
  const [lastStates, setLastStates] = useState([]);

  const loadData = (adverseEventId) => {
    callApi(
      ()=>getProcessDataByAdverseEvent(adverseEventId),
      (data)=>{
        setProcessData(data);
        setLastStates(getLastStates(data))
      },
      fnSetLoader
    );
  };

  const refreshData = () => {
    adverseEventRefresh();
    loadData(adverseEvent.id);
  };

  useEffect(()=>{
    if(open && adverseEvent)
      loadData(adverseEvent.id);
  },[open]);


  const onUpdateState = async (state) => {
    var obj = {      
      id: adverseEvent.id,
      state: state
    };

    await callApiAsync(
      ()=> postAdverseEventUpdateState(obj),
      refreshData,
      fnSetLoader
    );
  }

  const onAproveAsync = async () => {
    await onUpdateState(AdverseEventStateEnum.verified);
  };

  const onGenerateFormatAsync = async () => {
    var obj = {      
      adverseEventId: adverseEvent.id,
      formatType: getFormatType(adverseEvent)
    };

    await callApiAsync(
      ()=> postAdverseEventGenerateFormat(obj),
      refreshData
    );
  };
  const onGenerateCiomsAsync = async () => {
    var obj = {      
      adverseEventId: adverseEvent.id,
      formatType: FormatTypeEnum.cioms
    };

    await callApiAsync(
      ()=> postAdverseEventGenerateFormat(obj),
      refreshData
    );
  };
  
  
  const onSentToRegulatorAsync = async () => {
    await onUpdateState(AdverseEventStateEnum.sentToRegulator);
  };

  const onSentToPartnerAsync = async () => {
    await onUpdateState(AdverseEventStateEnum.sentToPartner);
  };

  const GetPendingQuery = () => {
    if(!queryAdverseEventList)
      return false;
    const result = queryAdverseEventList.find(x=>x.state !== QueryAdverseEventStateEnum.approved);
    return result !== undefined;
  };
  const anyPendingQuery = GetPendingQuery();

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      disableBackdropClick={true}
      onClose={closeDialog}
    >
      <DialogTitle><IntlMessages id="core.process" /></DialogTitle>
      <DialogContent className="new-patient-dialog-content">
        { adverseEvent && !anyPendingQuery && adverseEvent.status === AdverseEventStateEnum.pendingToVerify &&
          <React.Fragment>
            <ButtonAsync onClick={onAproveAsync}
              variant="contained" 
              color="primary">
              {<IntlMessages id="core.approve" />}
            </ButtonAsync>
            <Button onClick={()=>setOpenDlgReject(true)}
              className="ml-3"
              variant="contained" 
              color="primary">
              {<IntlMessages id="core.reject" />}
            </Button>
            <DialogReject
              open={openDlgReject}
              closeDialog={()=>setOpenDlgReject(false)}
              callBack={()=>{
                refreshData();
                reloadQueries();
              }}
              adverseEvent={adverseEvent}
            />
          </React.Fragment>
        }
        { adverseEvent && !anyPendingQuery && showBtnGenerateFormat(lastStates, adverseEvent.status, adverseEvent.adverseEventTypeId) &&
          <ButtonAsync onClick={onGenerateFormatAsync}
            className="mr-3"
            variant="contained" 
            color="primary">
            <IntlMessages id={getBtnFormatName(adverseEvent)} />
          </ButtonAsync>
        }
        { adverseEvent && !anyPendingQuery && 
          showBtnSendToRegulator(lastStates) &&
          <ButtonAsync onClick={onSentToRegulatorAsync}
            className="mr-3"
            variant="contained" 
            color="primary">
            <IntlMessages id="adverseEvent.btnSentToRegulator" />
          </ButtonAsync>
        }
        { adverseEvent && !anyPendingQuery && 
          ( adverseEvent.adverseEventTypeId === AdverseEventTypeEnum.pharmacovigilance && showBtnCausality(lastStates)) &&
          <React.Fragment>
            <Button onClick={()=>setOpenDlgCausality(true)}
              className="mr-3"
              variant="contained" 
              color="primary">
              {<IntlMessages id="adverseEvent.registerCausality" />}
            </Button>
            <DialogCausality
              open={openDlgCausality}
              closeDialog={()=>setOpenDlgCausality(false)}
              callBack={refreshData}
              adverseEvent={adverseEvent}
            />
          </React.Fragment>
        }
        { adverseEvent && !anyPendingQuery && 
          (adverseEvent.adverseEventTypeId === AdverseEventTypeEnum.pharmacovigilance && showBtnGenerateCioms(lastStates, adverseEvent.status)) &&
          <ButtonAsync onClick={onGenerateCiomsAsync}
            className="mr-3"
            variant="contained"
            color="primary">
            <IntlMessages id="adverseEvent.btnGenerateFormat.cioms" />
          </ButtonAsync>
        }
        { adverseEvent && !anyPendingQuery && 
          (adverseEvent.adverseEventTypeId === AdverseEventTypeEnum.pharmacovigilance && showBtnSendToPartner(lastStates)) &&
          <ButtonAsync onClick={onSentToPartnerAsync}
            className="mr-3"
            variant="contained" 
            color="primary">
            <IntlMessages id="adverseEvent.btnSentToPartner" />
          </ButtonAsync>
        }
        <ProcessTable processData={processData}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="secondary">
          {<IntlMessages id="core.close" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Dlg;