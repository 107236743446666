import axios from "axios";
import { BlockBlobClient } from "@azure/storage-blob";

const SAS_GENERATOR_URL = process.env.REACT_APP_SAS_GENERATOR;

const upload = async (file, callback) => {
    const extension = file.name.substr(file.name.lastIndexOf("."));
    const originalName = file.name;
    let fileName = new Date().valueOf() + "_" + Math.random().toFixed(16).substring(2) + extension; 

    let codeSas = await getSas();
    codeSas = codeSas.replace("{0}", fileName).replace("http", "https");

    if (!codeSas || !fileName) return;
  
    const blockBlobClient = new BlockBlobClient(
      `${codeSas}` // A SAS should start with "?"
    );
        
    const blobResult = await blockBlobClient.upload(file, file.size, {
      maxSingleShotSize: 4 * 1024 * 1024,
    });

    if (callback)
        await callback(fileName, originalName);

    return true;
  };

  const getSas = async () => {
    const url = `${SAS_GENERATOR_URL}`;
    const objToSend = JSON.stringify({
        connectionStringSecret: "coresasecret",
        containerName: "temp-files",
        permissionRequired: "blobUpload",
        permissionMinutes: 10
    });
    const { data } = await axios.post(url, objToSend);
    return data;
  }
  
  export default upload;