import React from "react";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button, Tooltip } from "@material-ui/core";
import Table, {
  minimalOptions,
  defaultCol,
  dateColumn,
} from "components/Form/Table";

const PatientStateTable = ({dataList, setCurrentRowObj, setOpenDlg, currentImpactId}) => {
  const getObj = (tableMeta) =>
    dataList.find((x) => x.patientStatusId === tableMeta.rowData[0]);

  const columns = [
    {
      name: "patientStatusId",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    dateColumn("creationDate", "core.date"),
    defaultCol("stateText", "core.state"),
    defaultCol("comment", "core.comment"),
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          if (rowObj.impactId !== currentImpactId) return "";
          
          var bottonIcon = "zmdi zmdi-edit";

          return (
            <div>
              <Tooltip
                title={<IntlMessages id={"core.edit"} />}
                placement="bottom-end"
              >
                <Button
                  onClick={() => {
                    setCurrentRowObj(rowObj);
                    setOpenDlg(true);
                  }}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className={bottonIcon}></i>
                </Button>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  return (
    <Table
      className="border-dialog-card mt-2 mb-2"
      data={dataList}
      columns={columns}
      options={minimalOptions()}
    />
  );
};

export default PatientStateTable;
