import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormDatePicker from "components/Form/Controls/datepicker";
import ButtonAsync from "components/Form/Controls/ButtonAsync";

const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const FormCreateUpdate = ({ openCRU, handleRequestClose, impactId, obj, handleCreateUpdate, medicineList, readOnly}) => {
  const [currentMedicine, setCurrentMedicine] = useState(null);

  const getCurrent = (realConsumption, medicationLoss) => {
    let current = 0;
    current += !Number(realConsumption) ? 0 : Number(realConsumption);
    current += !Number(medicationLoss) ? 0 : Number(medicationLoss);
    return current;
  };
  const openingDateRequired = (medicineDeliveryDetailId) => {
    if(!medicineDeliveryDetailId) return false;
    const medicine = medicineList.find(x=>x.id === Number(medicineDeliveryDetailId.value));
    return medicine && medicine.realConsumption === 0 && medicine.medicationLoss === 0;
  };
  const endDateRequired = (medicineDeliveryDetailId, realConsumption, medicationLoss) => {
    if(!medicineDeliveryDetailId) return false;
    const medicine = medicineList.find(x=>x.id === Number(medicineDeliveryDetailId.value));
    if(!medicine) return false;

    const current = getCurrent(realConsumption, medicationLoss);
    return medicine.pharmaceuticalQuantity === (medicine.realConsumption + medicine.medicationLoss + current);
  };
  const realConsumptionInvalid = (medicineDeliveryDetailId) => {
    if(!medicineDeliveryDetailId) return false;
    const medicine = medicineList.find(x=>x.id === Number(medicineDeliveryDetailId.value));
    if(!medicine) return false;

    const current = getCurrent(realConsumptionValue, medicationLossValue);
    return medicine.pharmaceuticalQuantity < (medicine.realConsumption + medicine.medicationLoss + current);
  };
  const getConsumptionMax = () => {
    if(!currentMedicine) return 0;
    const medicine = currentMedicine;

    return medicine.pharmaceuticalQuantity - (medicine.realConsumption + medicine.medicationLoss);
  };

  const mgsMax = IntlGetText("medicineDelivery.consumptionValidation");

  const defaultValues = {    
    medicineDeliveryDetailId : null,
    realConsumption: "",
    medicationLoss: null,
    comment : "",
    openingDate: null,
    endDate: null,
  };
  const validationSchema = { 
    medicineDeliveryDetailId: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    realConsumption:  yup.number()
      .typeError(IntlGetText("yup.numericValidation"))
      .min(1, IntlGetText("yup.numericValidation"))
      .max(50000, IntlGetText("yup.invalidInput"))
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable()
      .when("medicineDeliveryDetailId", {
        is: (medicineDeliveryDetailId) => realConsumptionInvalid(medicineDeliveryDetailId),
        then: yup.number()
          .typeError(IntlGetText("yup.numericValidation"))
          .required(<IntlMessages id="core.fieldRequired" />)
          .max(getConsumptionMax(), IntlGetText("medicineDelivery.consumptionValidation")+" ${max}")
          .nullable()    
      }),
    medicationLoss:  yup.number()
      .typeError(IntlGetText("yup.numericValidation"))
      .min(0, IntlGetText("yup.numericValidation"))
      .nullable().transform((v, o) => o === '' ? null : v)
      .when("medicineDeliveryDetailId", {
        is: (medicineDeliveryDetailId) => realConsumptionInvalid(medicineDeliveryDetailId),
        then: yup.number()
          .typeError(IntlGetText("yup.numericValidation"))
          .nullable().transform((v, o) => o === '' ? null : v)
          .max(getConsumptionMax(), IntlGetText("medicineDelivery.consumptionValidation")+" ${max}")
      }),
    comment: yup.string().when("medicationLoss", {
      is: medicationLoss => medicationLoss,
      then: yup.string().required(<IntlMessages id="core.fieldRequired" />)
    }),
    openingDate: yup.date().nullable().when("medicineDeliveryDetailId", {
      is: medicineDeliveryDetailId => openingDateRequired(medicineDeliveryDetailId),
      then: yup.date()
        .nullable(true)
        .typeError(IntlGetText("yup.InvalidDate"))
        .required(<IntlMessages id="core.fieldRequired" />)
    }),
    endDate: yup.date().nullable().when(["medicineDeliveryDetailId","realConsumption","medicationLoss"], {
      is: (medicineDeliveryDetailId, realConsumption, medicationLoss) => endDateRequired(medicineDeliveryDetailId, realConsumption, medicationLoss),
      then: yup.date()
        .nullable()
        .min(yup.ref("openingDate"), IntlGetText("medicineDelivery.endDateInvalid"))
        .typeError(IntlGetText("yup.InvalidDate"))
        .required(<IntlMessages id="core.fieldRequired" />)
    })
  };

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, errors } = methods;
  
  const medicineDeliveryDetailIdValue = watch("medicineDeliveryDetailId");
  const realConsumptionValue = watch("realConsumption");
  const medicationLossValue = watch("medicationLoss");

  useEffect(() => {
    if(!medicineDeliveryDetailIdValue){
      setValue("openingDate", null);
      setValue("endDate", null);
       return;
    }

    const item = medicineList.find(x=>x.id === medicineDeliveryDetailIdValue.value);
    setCurrentMedicine(item);    
    setValue("openingDate", !item || !item.openingDate ? null : dayjs(item.openingDate));
    setValue("endDate", !item || !item.endDate ? null : dayjs(item.endDate));
  },[medicineDeliveryDetailIdValue]);

  const loadData = () => {
    setValue("medicineDeliveryDetailId", !obj || !obj.medicineDeliveryDetailId ? "" : getCboValue(medicineList,obj.medicineDeliveryDetailId));
    setValue("realConsumption", !obj || !obj.realConsumption ? "" : obj.realConsumption);
    setValue("medicationLoss", !obj || !obj.medicationLoss ? "" : obj.medicationLoss);
    setValue("comment", !obj || !obj.comment ? "" : obj.comment);
    setValue("openingDate", !obj || !obj.openingDate ? null : dayjs(obj.openingDate));
    setValue("endDate", !obj || !obj.endDate ? null : dayjs(obj.endDate));
  };

  useEffect(()=>{
    if(openCRU){
      setTimeout(loadData,150);
    }
  },[openCRU]);

  const onSubmit = async (d, e) => {
    if(realConsumptionInvalid(d.medicineDeliveryDetailId)){
      const max = getConsumptionMax();
      var msg = mgsMax + max;
      NotificationManager.warning(msg);
      return;
    }
    var item = {
      id: !obj || !obj.id ? 0 : obj.id,
      impactId: impactId,
      medicineDeliveryDetailId : Number(d.medicineDeliveryDetailId.value),
      realConsumption: Number(d.realConsumption),
      medicationLoss: !d.medicationLoss ? null : Number(d.medicationLoss),
      comment : !d.comment ? null : d.comment,
      openingDate: !d.openingDate ? null : dayjs(d.openingDate).format("YYYY-MM-DDTHH:mm:ss"),
      endDate: !d.endDate ? null : dayjs(d.endDate).format("YYYY-MM-DDTHH:mm:ss")
    };
    await handleCreateUpdate(item);
  };

  const allowEdit = () => {
    if (!obj || !obj.id) return true;

    if (readOnly) return false;

    return obj.impactId == impactId;
  };
  const disabled = !allowEdit();
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!obj ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <FormSelectAutoComplete
                    name="medicineDeliveryDetailId"
                    label={<IntlMessages id="core.product" />}
                    options={medicineList}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormInput
                    name="realConsumption"
                    label={<IntlMessages id="medicineDelivery.realConsumption" />}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormInput
                    name="medicationLoss"
                    label={<IntlMessages id="medicineDelivery.medicationLoss" />}
                    margin="normal"
                    disabled={disabled}
                    errorobj={errors}
                  />
                </Grid>
              
                  <Grid item xs={12} md={6} lg={6}>
                    <FormDatePicker
                      name="openingDate"
                      label={<IntlMessages id="medicineDelivery.openingDate" />}
                      format="YYYY-MM-DD"
                      margin="normal"
                      disableFuture={true}
                      disabled={disabled || !openingDateRequired(medicineDeliveryDetailIdValue)}
                      required={true}
                      errorobj={errors}
                    />                
                  </Grid>
                
                <Grid item xs={12} md={6} lg={6}>
                  <FormDatePicker
                    name="endDate"
                    label={<IntlMessages id="medicineDelivery.endDate" />}
                    format="YYYY-MM-DD"
                    margin="normal"                    
                    //disablePast={true}
                    disableFuture={true}
                    disabled={disabled || !endDateRequired(medicineDeliveryDetailIdValue,realConsumptionValue, medicationLossValue)}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    name="comment"
                    label={<IntlMessages id="core.comment" />}
                    margin="normal"
                    multiline
                    rows={3}
                    variant="outlined"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          { !disabled &&
            <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
              <ButtonAsync onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
              >
                {<IntlMessages id="core.save" />}
              </ButtonAsync>
            </div>
          }
        </div>  
      </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>  
  );
};
export default FormCreateUpdate;
