import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button, Tooltip } from "@material-ui/core";
import Table, {
  minimalOptions,
  defaultCol,
  dateColumn,
} from "components/Form/Table";
import DialogGeneric from "components/Form/DialogGeneric";
import AddComment from "./addComment";
import { callApi, getPatientCommentList } from "api/apiList";

const DlgComments = ({
  openDlg,
  requestCloseDlg,
  patient,
  isRep,
  isConsult,
}) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [openDlgAddComment, setOpenDlgAddComment] = useState(false);
  const [commentList, setCommentList] = useState([]);

  const [currentComment, setCurrentComment] = useState(null);

  const loadData = () => {
    callApi(
      () => getPatientCommentList(patient.id),
      setCommentList,
      fnSetLoader
    );
  };

  useEffect(() => {
    if (openDlg && patient) {
      loadData(patient.id);
      return;
    }
    setCommentList([]);
  }, [openDlg, patient]);

  const getObj = (tableMeta) =>
    commentList.find((x) => x.id === tableMeta.rowData[0]);

  let columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    dateColumn("creationDate", "core.creationDate", "YYYY/MM/DD hh:mm a"),
    defaultCol("user", "patientPA.commentBy"),
    defaultCol("comment", "core.comment"),
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          if (
            !rowObj.allowEdit ||
            dayjs(rowObj.creationDate).format("YYYY-MM-DD") !==
              dayjs().format("YYYY-MM-DD")
          )
            return "";
          return (
            <div>
              <Tooltip
                title={<IntlMessages id="core.edit" />}
                placement="bottom-end"
              >
                <Button
                  onClick={() => {
                    setCurrentComment(rowObj);
                    setOpenDlgAddComment(true);
                    loadData();
                  }}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className="zmdi zmdi-edit"></i>
                </Button>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];
  // if(isRep)
  // {
  //   columns.push({
  //     name: "actions",
  //     label: IntlGetText("core.actions"),
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (value, tableMeta, updateValue) => {
  //         var rowObj = getObj(tableMeta);
  //         if(dayjs(rowObj.creationDate).format("YYYY-MM-DD") !== dayjs().format("YYYY-MM-DD"))
  //           return "";
  //         return (
  //           <div>
  //             <Tooltip title={<IntlMessages id='core.edit' />} placement="bottom-end">
  //               <Button
  //                 onClick={() => {
  //                   setCurrentComment(rowObj);
  //                   setOpenDlgAddComment(true);
  //                   loadData();
  //                 }}
  //                 variant="contained"
  //                 className="jr-btn jr-btn-xs"
  //               >
  //                 <i className="zmdi zmdi-edit"></i>
  //               </Button>
  //             </Tooltip>
  //           </div>
  //         );
  //       },
  //     },
  //   });
  //}
  const allowEdit = true;
  return (
    <DialogGeneric
      open={openDlg}
      closeDialog={requestCloseDlg}
      titleText={<IntlMessages id="dashboard.comments" />}
      maxWidth="md"
    >
      {/* {isRep &&  */}
      {!isConsult && (
        <div className="text-right mt-1 mb-1">
          <Button
            onClick={() => setOpenDlgAddComment(true)}
            variant="contained"
            color="primary"
            className="ml-2"
          >
            {<IntlMessages id="core.add" />}
          </Button>
        </div>
      )}

      <AddComment
        openDlg={openDlgAddComment}
        requestCloseDlg={() => {
          setOpenDlgAddComment(false);
          setCurrentComment(null);
        }}
        callBack={() => {
          setOpenDlgAddComment(false);
          setCurrentComment(null);
          loadData();
        }}
        patientComment={currentComment}
        patient={patient}
      />
      <Table
        className="border-dialog-card mt-2 mb-2"
        data={commentList}
        columns={columns}
        options={minimalOptions()}
      />
    </DialogGeneric>
  );
};

export default DlgComments;
