import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AttachedFile from "./AttachedFile";
import RadFileDialog from "./RadFileDialog";

let id = 0;

function createData(date, detail, status, actions) {
  id += 1;
  return { id, date, detail, status, actions };
}

const data = [
  createData(
    "2020/05/20",
    <div class=" badge text-uppercase text-white bg-light-blue">Nuevo</div>,
    "Aprobado"
  ),
  createData(
    "2020/05/22",
    <div class=" badge text-uppercase text-white bg-green">Méd. Autorizó</div>,
    "Aprobado"
  ),
  createData(
    "2020/05/25",
    <div class=" badge text-uppercase text-white bg-green">Pac. Autorizó</div>,
    "Aprobado"
  ),
  createData(
    "2020/05/26",
    <div class=" badge text-uppercase text-white bg-green">Primer Impacto</div>,
    "Aprobado",
    <AttachedFile />
  ),
  createData(
    "2020/05/27",
    <div class=" badge text-uppercase text-white bg-red">
      Form. con errores
    </div>,
    "Aprobado"
  ),
  createData(
    "2020/05/29",
    <div class=" badge text-uppercase text-white bg-green">Formulado</div>,
    "Aprobado"
  ),
  createData(
    "2020/06/01",
    <div class=" badge text-uppercase text-white bg-green">Rad. EPS</div>,
    "Aprobado",
    <RadFileDialog />
  ),
  createData(
    "2020/06/22",
    <div class=" badge text-uppercase text-white bg-green">Autorizado</div>,
    "Aprobado"
  ),
  createData(
    "2020/06/25",
    <div class=" badge text-uppercase text-white bg-green">Rad. OLF</div>,
    "Aprobado",
    <RadFileDialog />
  ),
  createData(
    "2020/07/02",
    <div class=" badge text-uppercase text-white bg-green">Entrega</div>,
    "Aprobado"
  ),
  createData(
    "2020/07/03",
    <div class=" badge text-uppercase text-white bg-green">Activo</div>,
    "Aprobado"
  ),
];

function FollowUpTable() {
  return (
    <div className="table-responsive-material">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell align="center">Detalle</TableCell>
            <TableCell align="center">Estado</TableCell>
            <TableCell align="center">Adjuntos</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((n) => {
            return (
              <TableRow key={n.id}>
                <TableCell>{n.date}</TableCell>
                <TableCell align="center">{n.status}</TableCell>
                <TableCell align="center">{n.detail}</TableCell>
                <TableCell align="center">{n.actions}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default FollowUpTable;
