import React from 'react';
import { useFormContext, Controller } from "react-hook-form";
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IntlMessages from "util/IntlMessages";

//https://jumbo.g-axon.work/components/mui/switch

const AntSwitch = withStyles(theme => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
        backgroundColor: theme.palette.background.paper,
    },
    checked: {},
  }))(Switch);

export function SwitchYesNo(props) {
  const { control } = useFormContext();
  let { name, label, className, ...swProps } = props;
  className = !className ? "ml-3" : className;

  return (
    <FormControlLabel
      control={
        <Controller
          control={control}
          name={name}
          defaultValue={false}
          render={({ onChange, value }) => (
            <Typography component="div" className={className}>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item><strong><IntlMessages id="button.no" /></strong></Grid>
              <Grid item>                
                <AntSwitch
                  name={name} 
                  onChange={(e) => onChange(e.target.checked)}
                  checked={value}
                  {...swProps}
                />
              </Grid>
              <Grid item><strong><IntlMessages id="core.yes" /></strong></Grid>
            </Grid>
          </Typography>
          )}
        />
      }
    />
  );
};

export default SwitchYesNo;