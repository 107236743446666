import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";

const UserDetailCell = ({ data }) => {
  const { id, name, detail, about, color, product, color2, redirectTo } = data;
  return (
    <tr tabIndex={-1} key={id}>
      <td className="border-bottom border-top-0">
        <div className="user-profile d-flex flex-row align-items-center">
          <div className="user-detail">
            <h5 className="user-name">{name}</h5>
            <p className="user-description">{about}</p>
          </div>
        </div>
      </td>
      <td className="text-right border-bottom border-top-0">
        <span className={`jr-link badge text-white text-uppercase bg-${color}`}>
          {detail}
        </span>
      </td>
      <td className="text-right border-bottom border-top-0">
        <span
          className={`jr-link badge text-white text-uppercase bg-${color2}`}
        >
          {product}
        </span>
      </td>

      <td className="text-right border-bottom border-top-0">
        <IconButton
          component={Link}
          to={redirectTo ?? "/app/management"}
          className="icon-btn text-light p-1"
        >
          <i className="zmdi zmdi-arrow-forward" style={{ fontSize: "19px" }} />
        </IconButton>
      </td>
    </tr>
  );
};

export default UserDetailCell;
