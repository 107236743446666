import React, { useEffect, useState } from "react";
import * as dayjs from "dayjs";
import { IntlGetText } from "util/IntlMessages";
import Table, { getTextLabels } from "components/Form/Table";
import { DownloadButton2 } from "components/DownloadButton/index";
import { AdverseEventStateList } from "constants/Enums";

const getStates = (processData) => {
  if(!processData || !processData.adverseEventState)
    return [];
  return processData.adverseEventState;
};

const round = (number, decimalPlaces) => {
  const factorOfTen = Math.pow(10, decimalPlaces)
  return Math.round(number * factorOfTen) / factorOfTen
};

const CommentTable = ({ processData }) =>{
  const [data, setData] = useState([]);

  const adverseEventStateList = AdverseEventStateList();
  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: getTextLabels(),
    pagination:false
  };

  const getObj = (tableMeta) => data.find(x=>x.id === tableMeta.rowData[0]);

  useEffect(()=>{
    if(processData)
      setData(getStates(processData));
  },[processData]);

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display:"excluded"
      }
    },
    {
      name: "creationDate",
      label: IntlGetText("core.date"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD hh:mm a") : "";
        },
      },
    },
    {
      name: "time",
      label: IntlGetText("core.time"),
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "state",
      label: IntlGetText("core.state"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          const state = adverseEventStateList.find(x=>x.id === rowObj.state);
          return (
            <div className={`badge text-uppercase text-white bg-${state.color}`}>
              {state.name}
            </div>
          );
        }
      }
    },
    {
      name: "file",
      label: IntlGetText("adverseEventProcess.files"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          if(value){
            return (
              <DownloadButton2
                variant="contained"
                className="jr-btn jr-btn-xs"
                color="primary"
                fileLink={value}
              >
                <i className="zmdi zmdi-download"></i>
              </DownloadButton2>
            );
          }

          return "";
        },
      },
    }
  ];

  return (
    <React.Fragment>
      <Table
        className="border-dialog-card mt-2 mb-2"
        data={data}
        columns={columns}
        options={options} />
    </React.Fragment>   
  );
};

export default CommentTable;