import {
    SET_CONSENT_PATH,
    SET_CONSENT_URL,
    LOAD_INFO_IMPACT,
    SET_IMPACT_START_DATE,
    SET_CLINICAL_HISTORY_STATE,
    SET_HISTORY_DATA,
    SET_FORM_FORMULA_DONE,
    SET_FORMULA_MEDICINE_QUANTITY,
    ON_START_IMPACT,
    CALL_RELOAD_ALERTS,
    CALL_RELOAD_PATIENT_DATA,
    CALL_RELOAD_CONSUMPTION_TRACING,
    SET_HEALTH_DATA
  } from "../constants/ActionTypes";

  export const setConsentPath = (consentPath) => {
    return {
      type: SET_CONSENT_PATH,
      payload: consentPath,
    };
  };
  export const setConsentUrl = (consentPath) => {
    return {
      type: SET_CONSENT_URL,
      payload: consentPath,
    };
  };
  export const loadInfoImpact = (infoImpact) => {
    return {
      type: LOAD_INFO_IMPACT,
      payload: infoImpact,
    };
  };
  export const setImpactStartDate = (impactStartDate) => {
    return {
      type: SET_IMPACT_START_DATE,
      payload: impactStartDate,
    };
  };
  export const setClinicalHistoryState = (clinicalHistoryState) => {
    return { type: SET_CLINICAL_HISTORY_STATE, payload : clinicalHistoryState };
  };
  export const sethistoryData = (historyData) => {
    return { type: SET_HISTORY_DATA, payload : historyData };
  };
  export const setFormFormulaDone = (expeditionDateFormula) => {
    return { type: SET_FORM_FORMULA_DONE, payload : expeditionDateFormula };
  };
  export const setFormulaMedicineQuantity = (medicineQuantity) => {
    return { type: SET_FORMULA_MEDICINE_QUANTITY, payload : medicineQuantity };
  };
  export const callStartImpact = (startImpact) => {
    return { type: ON_START_IMPACT, payload : startImpact };
  };
  export const callReloadAlerts = (reloadAlerts) => {
    return { type: CALL_RELOAD_ALERTS, payload : reloadAlerts };
  };
  export const callReloadPatientData = (reload) => {
    return { type: CALL_RELOAD_PATIENT_DATA, payload : reload };
  };
  export const callReloadConsumptionTracing = (reload) => {
    return { type: CALL_RELOAD_CONSUMPTION_TRACING, payload : reload };
  };
  export const setHealthData = (healthData) => {
    return { type: SET_HEALTH_DATA, payload : healthData };
  };