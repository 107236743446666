import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import IntlMessages from "util/IntlMessages";
import FormInput from "components/Form/Controls/input";
import ButtonAsync from "components/Form/Controls/ButtonAsync";

const DialogEndImpact = ({ open, handleRequestClose, callEndImpact }) => {
  const defaultValues = { nursingNote: "" };
  const validationSchema = {
    nursingNote: yup
      .string()
      .required(<IntlMessages id="core.fieldRequired" />),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, errors } = methods;

  const onSubmit = async (data, e) => {
    await callEndImpact(data.nursingNote);
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="xs"
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>{<IntlMessages id="impact.endImpactText" />}</DialogTitle>
      <DialogContent className="new-patient-dialog-content">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item md={12}>
                <FormInput
                  name="nursingNote"
                  label={<IntlMessages id="impact.NursingNote" />}
                  multiline
                  rows={5}
                  variant="outlined"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          {<IntlMessages id="core.cancel" />}
        </Button>
        <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
          {<IntlMessages id="core.save" />}
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  );
};

export default DialogEndImpact;
