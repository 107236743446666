import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import FormCheckbox from "components/Form/Controls/checkbox";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  callApiAsync,
  postCreateUpdateQueryAdverseEvent
} from "api/apiList";
import { QueryAdverseEventTypeList, QueryAdverseEventTypeEnum } from "constants/Enums";


const DialogAdd = ({ open, dialogTitle, callBackAsync, closeDialog }) => {
  const queryAdverseEventTypeList = QueryAdverseEventTypeList().filter(x=>x.id !== QueryAdverseEventTypeEnum.pharmacovigilance);

  const defaultValues = {
    type: null,
    description: "",
    assignedToNurse: false
  };

  const validationSchema = {
    type: yup .object()
    .shape({ value: yup.string().required() })
    .required(<IntlMessages id="core.fieldRequired" />)
    .nullable(),
    description: yup.string().required(<IntlMessages id="core.fieldRequired" />)
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, errors } = methods;

  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);

  const onSubmit = async (d, e) => {
    var obj = {
      type: Number(d.type.value),
      description: d.description,
      assignedToNurse: d.assignedToNurse
    };
    await callBackAsync(obj);
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      disableBackdropClick={true}
      onClose={closeDialog}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent className="new-patient-dialog-content">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid item xs={12} md={12} lg={12}>
              <Grid item xs={12} md={6} lg={6}>
                <FormSelectAutoComplete
                  name="type"
                  label={<IntlMessages id="core.type" />}
                  options={queryAdverseEventTypeList}
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormInput
                  label={<IntlMessages id="core.description" />}
                  name="description"
                  multiline
                  rows={4}
                  variant="outlined"
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormCheckbox
                  name="assignedToNurse"
                  label={<IntlMessages id="queryAdverseEvent.assignedToNurse" />}
                  onChange={chkHandleChange("assignedToNurse")}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="secondary">
          {<IntlMessages id="core.cancel" />}
        </Button>
        <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
          {<IntlMessages id="core.save" />}
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  );
};

const BtnAddQuery = ({ adverseEvent, loadQueries }) =>{  
  const [openDlg, setOpenDlg] = useState(false);

  const callBackAsync = async (query) => {
    var item = {
      ...query,
      adverseEventId: adverseEvent.id
    };
    await callApiAsync(
      ()=>postCreateUpdateQueryAdverseEvent(item),
      ()=>{
        setOpenDlg(false);
        loadQueries();
      }
    );
  };

  return (
    <div>
      <div className="text-right mt-1 mb-1">
      <Button
        onClick={()=>setOpenDlg(true)}
        variant="contained"
        color="primary"
        className="ml-2"
      >
        {<IntlMessages id="core.add" />}
      </Button>
      </div>
      <DialogAdd
        open={openDlg}
        dialogTitle={<IntlMessages id="adverseEvent.addQuery" />}
        callBackAsync={callBackAsync}
        closeDialog={()=>setOpenDlg(false)}
      />
    </div>
  );
};

export default BtnAddQuery;