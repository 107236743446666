export const data = [
  { name: "Jan-20", value: 21 },
  { name: "Feb-20", value: 13 },
  { name: "Mar-20", value: 10 },
  { name: "Apr-20", value: 35 },
  { name: "May-20", value: 41 },
];

export const data1 = [
  { name: "DEFINITIVA", uv: 1 },
  { name: "IMPROBABLE", uv: 2 },
  { name: "NA", uv: 2 },
  { name: "POSIBLE", uv: 16 },
  { name: "PROBABLE", uv: 20 },
];

export const data2 = [
  { name: "Enero", uv: 3.1 },
  { name: "Febrero", uv: 3.4 },
  { name: "Marzo", uv: 3.2 },
  { name: "Abril", uv: 6 },
  { name: "Mayo", uv: 5.3 },
  { name: "Promedio General", uv: 4.2 },
];

export const data3 = [
  { name: "Enero", b1: 45, b2: 50, l1: 72, l2: 202, l3: 316 },
  { name: "Febrero", b1: 92, b2: 104, l1: 63, l2: 237, l3: 247 },
  { name: "Marzo", b1: 56, b2: 75, l1: 60, l2: 192, l3: 318 },
  { name: "Abril", b1: 13, b2: 105, l1: 270, l2: 299, l3: 640 },
  { name: "Mayo", b1: 25, b2: 120, l1: 184, l2: 295, l3: 543 },
];

export const dataActivePatients = [
  { name: "15 mg diarios", uv: 9 },
  { name: "30 mg diarios", uv: 33 },
  { name: "45 mg diarios", uv: 29 },
];

export const data7 = [
  { name: "REMODULIN", uv: 32 },
  { name: "KALBITOR", uv: 1 },
  { name: "ICLUSIG", uv: 7 },
  { name: "GALAFORD", uv: 1 },
];
