import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import AppNotification from "../../../../components/AppNotification";
import CardHeader from "components/dashboard/Common/CardHeader/index";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import Menu from "./TopNav/Menu";
import UserInfoPopup from "components/UserInfo/UserInfoPopup";
import NewPatientDialog from "app/routes/psp/patient/Components/NewPatientDialog";
import { toggleCollapsedNav } from "actions/Setting";
import PasswordChangeForm from 'components/UserInfo/PasswordChangeForm';
import { postChangePassword, callApiAsync } from "api/apiList";
import { NotificationManager } from "react-notifications";
import { getRoleUser, getUserInfo } from "app/routes/psp/services/userService";
import { RoleEnum, ShardKeyEnum } from "constants/Enums";
import { useTheme } from '@material-ui/core/styles';

const InsideNavHeader = (props) => {
  const [profileLogo, setProfileLogo] = useState(require("assets/images/psp/logos/psp_profile_logo-default.png"));
  const theme = useTheme();
  const { userInfo } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const [uinfo, setUserInfo] = useState(false);
  const [appNotification, setAppNotification] = useState(false);

  const [openCPM, setOpenCPM] = useState(false);
  const handleOpenCPM = () => setOpenCPM(true);
  const handleCloseCPM = () => setOpenCPM(false);

  const isNurseProfile = () =>{
    const userLogued = getRoleUser();
    const role = userLogued.role.toUpperCase();
    return role === RoleEnum.nurse;
  };

  const onAppNotificationSelect = () => {
    setAppNotification(!appNotification);
  };

  const onUserInfoSelect = () => {
    setUserInfo(!uinfo);
  };
  const onToggleCollapsedNav = (e) => {
    dispatch(toggleCollapsedNav());
  };
  const messages = [];
  messages["success"] = IntlGetText("changePasswordModal.success");
  messages["PasswordRequiresLower"] = IntlGetText("PasswordValidation.PasswordRequiresLower");
  messages["PasswordTooShort"] = IntlGetText("PasswordValidation.PasswordTooShort");
  messages["PasswordRequiresDigit"] = IntlGetText("PasswordValidation.PasswordRequiresDigit");
  messages["PasswordMismatch"] = IntlGetText("PasswordValidation.PasswordMismatch");
  
  const handleChangePassword = async (currentPassword, newPassword) => {
    await callApiAsync(
      () => postChangePassword({currentPassword, newPassword}),
      (response) => {
        if (!response.succeeded){
          response.errors.forEach(e => 
            {
              NotificationManager.error(messages[e]);
            });
        }
        else
        {
          NotificationManager.success(messages["success"]);
          handleCloseCPM();
        }
      }
    );
  };

  useEffect(()=>{
    const userInfo = getUserInfo();
    if(!userInfo)
      return;

    if(userInfo.customProfileLogo)
      setProfileLogo(userInfo.customProfileLogo);
  },[]);

  return (
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false}>
        <div
          className="d-block d-md-none pointer mr-3"
          onClick={onToggleCollapsedNav}
        >
          <span className="jr-menu-icon">
            <span className="menu-icon" />
          </span>
        </div>

        <Link className="app-logo mr-2 d-none d-sm-block" to="/">
          <img
            src={theme.imgLogin.src}
            alt={theme.imgLogin.alt}
            title={theme.imgLogin.title}
          />
        </Link>

        <Menu />

        <ul className="header-notifications list-inline ml-auto">
          {isNurseProfile() && getUserInfo() && getUserInfo().shardKey !== ShardKeyEnum.ialuril && getUserInfo().shardKey !== ShardKeyEnum.seranestcr &&
            <li className="list-inline-item app-tour">
              <NewPatientDialog />
            </li>
          }
          {userInfo.role === "nurse" && (
            <li className="list-inline-item app-tour">
              <Dropdown
                className="quick-menu"
                isOpen={appNotification}
                toggle={onAppNotificationSelect}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-notifications-none icon-alert animated infinite wobble" />
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  <CardHeader
                    styleName="align-items-center"
                    heading={<IntlMessages id="appNotification.title" />}
                  />
                  <AppNotification />
                </DropdownMenu>
              </Dropdown>
            </li>
          )}
          <li className="list-inline-item user-nav">
            <Dropdown
              className="quick-menu"
              isOpen={uinfo}
              toggle={onUserInfoSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <IconButton className="icon-btn size-30">
                  <Avatar
                    alt="..."
                    src={profileLogo}
                    className="size-30"
                  />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right>
                <UserInfoPopup 
                  handleOpenCPM = {handleOpenCPM}
                />
              </DropdownMenu>
            </Dropdown>
          </li>
        </ul>

        <div className="ellipse-shape" />
      </Toolbar>
      <PasswordChangeForm 
        openCPM={openCPM}
        handleCloseCPM={handleCloseCPM}
        handleChangePassword={handleChangePassword}/>
    </AppBar>
  );
};

export default withRouter(InsideNavHeader);
