import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import FormDateTimePicker from "components/Form/Controls/dateTimePicker";
import FormInput from "components/Form/Controls/input";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { 
    callApi,
    callApiAsync,
    postImpactStartImpact,
    postImpactEndImpact,
    getPrescriptions
} from "api/apiList"; 
import {  
    setSelectedPrescription, 
    loadPrescriptions,
    updateSelectedPrescription 
} from "actions/Management";
import { 
    setFormFormulaDone, 
    setImpactStartDate, 
    callStartImpact as callStartImpactAction
} from "actions/Impact";
import { PrescripStateEnum } from "constants/Enums";

function GetNewState(prescription){
    const prescriptionState = prescription.stateId;
    switch(prescriptionState){
        case PrescripStateEnum.fristImpact: return PrescripStateEnum.formulated;
        case PrescripStateEnum.radicacionEPS: return PrescripStateEnum.authorized;
        case PrescripStateEnum.authorized: return PrescripStateEnum.radicacionOLF;
        case PrescripStateEnum.radicacionOLF: return PrescripStateEnum.delivered;
    }
    return prescription.stateId;
}

const DialogEndImpact = ({open, handleRequestClose, callEndImpact, impactStartDate }) => {
    const defaultValues = { 
        endDate: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
        nursingNote: ""
    };
    const validationSchema = {  
        nursingNote: yup.string().required(<IntlMessages id="core.fieldRequired" />),
        endDate:yup.date()
            .typeError(IntlGetText("yup.InvalidDate"))
            .min(dayjs(impactStartDate).format("YYYY-MM-DDTHH:mm:ss"),IntlGetText("impact.invalidEndDate"))
            .required(<IntlMessages id="core.fieldRequired" />)
    };

    const methods = useForm({ defaultValues, resolver: yupResolver(yup.object().shape(validationSchema)) });
    const { handleSubmit, setValue, errors } = methods;
        
    useEffect(()=>{
        if(open){
            setTimeout(
                ()=>setValue("endDate",dayjs().format("YYYY-MM-DDTHH:mm:ss")),
                150
            );
        }
    },[open]);

    const onSubmit = async (data, e) => { 
        await callEndImpact(dayjs(data.endDate).format("YYYY-MM-DDTHH:mm:ss"), data.nursingNote); 
    };

    return (
        <Dialog open={open} fullWidth={true} maxWidth="xs" 
            disableBackdropClick={true} onClose={handleRequestClose} >
            <DialogTitle>{<IntlMessages id="impact.endImpactText" />}</DialogTitle>
            <DialogContent className="new-patient-dialog-content">
                <FormProvider {...methods}>
                    <form className="row" noValidate autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                                <FormDateTimePicker
                                    name="endDate"
                                    label={IntlGetText("core.dateAndTime")}  
                                    format="YYYY-MM-DD hh:mm A"  
                                    disableFuture={true}
                                    minDate={dayjs(impactStartDate).format("YYYY-MM-DD")}                       
                                    leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                                    rightArrowIcon={ <i className="zmdi zmdi-arrow-forward" /> }
                                    required={true} 
                                    errorobj={errors}  
                                />
                        </Grid>
                        <Grid item md={12}>
                            <FormInput         
                                name="nursingNote"         
                                label={<IntlMessages id="impact.NursingNote" />}                                
                                multiline
                                rows={5}
                                variant="outlined"
                                required={true}
                                errorobj={errors}
                            />
                        </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRequestClose} color="secondary">
                    {<IntlMessages id="core.cancel" />}
                </Button>
                <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
                    {<IntlMessages id="core.save" />}
                </ButtonAsync>
            </DialogActions>
        </Dialog>
    );
};

const DialogStartImpact = ({open, handleRequestClose, callStartImpact }) => {
    const defaultValues = { startDate: dayjs().format("YYYY-MM-DDTHH:mm:ss")};

    const methods = useForm({ defaultValues });
    const { handleSubmit, setValue, errors } = methods;

    useEffect(()=>{
        if(open){
            setTimeout(
                ()=>setValue("startDate",dayjs().format("YYYY-MM-DDTHH:mm:ss")),
                150
            );
        }
    },[open]);
    
    const onSubmit = async (data, e) => { 
        await callStartImpact(dayjs(data.startDate).format("YYYY-MM-DDTHH:mm:ss") ); 
    };

    return (
        <Dialog open={open} fullWidth={true} maxWidth="xs" 
            disableBackdropClick={true} onClose={handleRequestClose} >
            <DialogTitle>{<IntlMessages id="impact.startImpactText" />}</DialogTitle>
            <DialogContent className="new-patient-dialog-content">
                <FormProvider {...methods}>
                    <form className="row" noValidate autoComplete="off">
                        <Grid item md={12}>
                                <FormDateTimePicker
                                name="startDate"
                                label={IntlGetText("core.dateAndTime")}  
                                format="YYYY-MM-DD hh:mm A"  
                                disableFuture={true}                          
                                leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                                rightArrowIcon={ <i className="zmdi zmdi-arrow-forward" /> }
                                required={true} 
                                errorobj={errors}  
                              />
                        </Grid>
                    </form>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRequestClose} color="secondary">
                    {<IntlMessages id="core.cancel" />}
                </Button>
                <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
                    {<IntlMessages id="core.save" />}
                </ButtonAsync>
            </DialogActions>
        </Dialog>
    );
};

const StartEndImpact = ({ prescription }) => {
    const dispatch = useDispatch();
    const { impactStartDate, impactEndDate, startImpact } = useSelector(({ impact }) => impact);
    const { selectedMenuItem } = useSelector(({ management }) => management);

    const [open, setOpen] = useState(false);

    const msgSuccess = IntlGetText("core.SuccessSave");
    
    const handleRequestClose = () => { 
        setOpen(false);
        if(startImpact){
            dispatch(callStartImpactAction(false));
        }
    };
    const handleImpactStarted = () => { setOpen(true); };
    const handleImpactEnd = () =>{ setOpen(true); };
      
    const callStartImpact = async(startDate) => {
        await callApiAsync(
            ()=>postImpactStartImpact({
                id:prescription.impactId, 
                startDate,
                folderType: selectedMenuItem
            }), 
            (data)=>{
                handleRequestClose();
                NotificationManager.success(msgSuccess);

                if(data){ //new formulation
                    dispatch(updateSelectedPrescription(data));
                    dispatch(setFormFormulaDone(null));
                }

                dispatch(setImpactStartDate(startDate));
            });
    };
    const callEndImpact = async(endDate,nursingNote) => {
        await callApiAsync(
            ()=>postImpactEndImpact({
                id:prescription.impactId, 
                endDate,
                nursingNote,
                newPrescriptionState: GetNewState(prescription),
                folderType: selectedMenuItem
            }), 
            (data)=>{
                handleRequestClose();
                NotificationManager.success(msgSuccess);
                dispatch(setImpactStartDate(null));

                callApi(
                    ()=> getPrescriptions(selectedMenuItem), 
                    (data)=>{
                        dispatch(loadPrescriptions(data, selectedMenuItem));
                        dispatch(setSelectedPrescription(null));
                    });
            });
    };

    useEffect(()=> {
        if(startImpact){
            handleImpactStarted();
        }
    },[startImpact]);

    return (
        <div>
            
        {prescription.stateId >= 3 && !impactStartDate && (
            <div className="mail-header-actions">
                <IntlMessages id="impact.startImpact" />
                <Button
                    onClick={() => {handleImpactStarted();}}
                    variant="contained"
                    className="ml-3 jr-btn bg-green text-white"
                >
                    <i className="zmdi zmdi-thumb-up zmdi-hc-fw" />
                </Button>                    
                <DialogStartImpact open={open} handleRequestClose={handleRequestClose} callStartImpact={callStartImpact}/>
            </div>
        )}

        {prescription.stateId >= 3 && (impactStartDate && !impactEndDate) && (
            <div className="mail-header-actions">
                <IntlMessages id="impact.endImpact" />
                <Button
                    onClick={() => {handleImpactEnd();}}
                    variant="contained"
                    className="ml-3 jr-btn bg-green text-white"
                >
                    <i className="zmdi zmdi-thumb-up zmdi-hc-fw" />
                </Button>
                <DialogEndImpact open={open} handleRequestClose={handleRequestClose} callEndImpact={callEndImpact} impactStartDate={impactStartDate}/>
            </div>
        )}
        </div>
    );
};
export default StartEndImpact;