import React from "react";
import { IntlGetText } from "util/IntlMessages";
import Table, { defaultCol, getTextLabels } from "components/Form/Table";
import DlgNurses from "components/DlgNurses";

const PatientsTable = ({ data, patientStateList, FnActionsColRender }) => {
  const options = {
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    textLabels: getTextLabels(),
  };

  const getObj = (tableMeta) => data.find((x) => x.id === tableMeta.rowData[0]);

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    {
      name: "nurse",
      label: IntlGetText("core.nurse"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return <DlgNurses nurse={value} nurses={rowObj.nurses} />;
        },
      },
    },
    defaultCol("code", "core.code"),
    {
      name: "document",
      label: IntlGetText("core.documentOnly"),
      options: {
        filter: true,
        searchable: true,
        sort: true,
        display: 'excluded',
        customSearch: (value, rowData) => {
          const documentValue = rowData[3];
          return documentValue.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        },
      }
    },
    {
      name: "document",
      label: IntlGetText("core.documentOnly"),
      options: {
        filter: true,
        searchable: true,
        sort: true,
        display: false,
      },
    },
    defaultCol("fullName", "core.name"),
    defaultCol("phone", "core.phone"),
    defaultCol("city", "core.city"),
    defaultCol("insurance", "core.insurance"),
    defaultCol("doctor", "core.doctor"),
    {
      name: "productline",
      label: IntlGetText("core.productLine"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return (
            <div
              className={" badge text-uppercase text-white bg-" + rowObj.color}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "stateText",
      label: IntlGetText("core.state"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          var state = patientStateList.find((x) => x.id == rowObj.state);
          if (!state) return "";

          return (
            <div
              className={" badge text-uppercase text-white bg-" + state.color}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return FnActionsColRender(rowObj);
        },
      },
    },
  ];

  return (
      <Table
        title={IntlGetText("core.patients")}
        className="border-dialog-card"
        data={data}
        columns={columns}
        options={options}
      />
  );
};

export default PatientsTable;
