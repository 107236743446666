import React from "react";

const AppModuleHeader = (props) => {

  const { placeholder, onChange, value } = props;

  return (
    <div className="module-box-header-inner">
      <div className="search-bar right-side-icon bg-transparent d-none d-sm-block">
        <div className="form-group">
          <input
            className="form-control border-0"
            type="search"
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
          <button className="search-icon">
            <i className="zmdi zmdi-search zmdi-hc-lg" />
          </button>
        </div>
      </div>      
    </div>
  );
};

export default AppModuleHeader;

AppModuleHeader.defaultProps = {
  styleName: "",
  value: "",
};
