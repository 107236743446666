import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages from "util/IntlMessages";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormDatePicker from "components/Form/Controls/datepicker";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import FormSelectAutoComplete, { getCboValue } from "components/Form/Controls/select-autocomplete";
import { SciList } from "constants/Enums";

const FormCRU =({ rowObj, openCRU, handleRequestClose, handleCreateUpdateAsync, readOnly })=>{  
    const sciList = SciList();
  
    const defaultValues = {
      sci: null,
      medicine: "",
      indication: "",
      dosage: "",
      measurementUnit: "",
      administrationRoute: "",
      supplyFrequency: "",
      startDate : null,
      endDate: null
    };
  
    const validationSchema = {
      sci: yup .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    };
  
    const methods = useForm({ 
      defaultValues,
      resolver: yupResolver(yup.object().shape(validationSchema)),
    });
    const { handleSubmit, setValue, errors } = methods;
    
    useEffect(()=>{
      if(openCRU){
        setTimeout(loadData,150);
      }
    },[rowObj,openCRU]);
  
    const loadData = () => {
      setValue("sci", !rowObj ? null : getCboValue(sciList,rowObj.sci));
      setValue("activePrinciple", !rowObj ? "" : rowObj.medicine);
      setValue("dosage", !rowObj ? "" : rowObj.dosage);
      setValue("measurementUnit", !rowObj ? "" : rowObj.measurementUnit);
      setValue("administrationRoute", !rowObj ? "" : rowObj.administrationRoute);
      setValue("supplyFrequency", !rowObj ? "" : rowObj.supplyFrequency);
      setValue("startDate", !rowObj ? null : dayjs(rowObj.startDate));
      setValue("endDate", !rowObj ? null : dayjs(rowObj.endDate));
    };
    
    const onSubmit = async (d, e) => {
      var obj = {
        id: !rowObj ? 0 : rowObj.id,
        sci: Number(d.sci.value),
        dosage: d.dosage,
        measurementUnit: d.measurementUnit,
        administrationRoute: d.administrationRoute,
        supplyFrequency: d.supplyFrequency
      };
      
      await handleCreateUpdateAsync(obj);
      handleRequestClose();
    };
  
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openCRU}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id={!rowObj ? "core.add" : "core.edit"} />
        </DialogTitle>
        <DialogContent>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <FormProvider {...methods}>
              <form className="row" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormSelectAutoComplete
                      name="sci"
                      label={<IntlMessages id="concomitantMedication.sci" />}
                      options={sciList}
                      margin="normal"
                      disabled={readOnly}
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormInput
                      name="activePrinciple"
                      label={<IntlMessages id="core.activePrinciple" />}
                      margin="normal"
                      disabled={true}
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormInput
                      name="dosage"
                      label={<IntlMessages id="core.dosage" />}
                      margin="normal"
                      disabled={true}
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormInput
                      name="measurementUnit"
                      label={<IntlMessages id="core.measurementUnit" />}
                      margin="normal"
                      disabled={true}
                      errorobj={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormInput
                      name="administrationRoute"
                      label={<IntlMessages id="formula.administrationRoute" />}
                      margin="normal"
                      disabled={true}
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormInput
                      name="supplyFrequency"
                      label={<IntlMessages id="formula.supplyFrequency" />}
                      margin="normal"
                      disabled={true}
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormDatePicker
                      name="startDate"
                      label={<IntlMessages id="additionalMedications.treatmentStartDate" />}
                      format="YYYY-MM-DD"
                      disableFuture={true}
                      margin="normal"
                      disabled={true}
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormDatePicker
                      name="endDate"
                      label={<IntlMessages id="additionalMedications.treatmentEndDate" />}
                      format="YYYY-MM-DD"
                      margin="normal"
                      disabled={true}
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
            {!readOnly &&
              <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
                <ButtonAsync onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                >
                  {<IntlMessages id="core.save" />}
                </ButtonAsync>
              </div>
            }
          </div>  
        </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color="secondary">
              <IntlMessages id="core.close" />
            </Button>
          </DialogActions>
        </Dialog>  
    );
  };

export default FormCRU;