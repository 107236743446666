import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import Form from "./Form";
import Table from "./Table";
import {
  callApi,
  callApiAsync,
  getMoriskySurveyList,
  postCreateUpdateMoriskySurvey,
  deleteMoriskySurvey,
} from "api/apiList";
import { callStartImpact } from "actions/Impact";

const existItemsInImpact = (impactId, dataList) =>
  dataList.find((x) => x.impactId == impactId) != undefined;

const MoriskySurvey = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentSigned, isCIRequired } = useSelector(({ impact }) => impact);

  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const [morisKySurveyList, setMorisKySurveyList] = useState([]);
  const [currentRowObj, setCurrentRowObj] = useState(null);

  const [open, setOpen] = useState(false);
  const [openCRU, setOpenCRU] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleRequestClose = () => setOpen(false);
  const handleOpenCRU = () => setOpenCRU(true);
  const handleRequestCloseCRU = () => setOpenCRU(false);
  const handleRequestCloseDel = () => setOpenDeleteDialog(false);

  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    if (isCIRequired && !consentSigned) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }
    setOpen(true);
  };

  const loadData = () => {
    callApi(
      () => getMoriskySurveyList(selectedPrescription.id),
      setMorisKySurveyList
    );
  };

  useEffect(() => loadData(), []);

  const handleCreateUpdate = async (data) => {
    await callApiAsync(
      () => postCreateUpdateMoriskySurvey(data),
      () => {
        handleRequestCloseCRU();
        loadData();
      }
    );
  };

  const handleDelete = (data) => {
    callApi(
      () => deleteMoriskySurvey(currentRowObj.id),
      () => {
        handleRequestCloseDel();
        setCurrentRowObj(null);
        loadData();
      }
    );
  };

  var readOnly = false;

  return (
    <div>
      <InfoCard
        data={{
          icon: "assignment",
          title: IntlGetText("morisKySurvey.cardTitle"),
          subTitle: IntlGetText("morisKySurvey.cardSubTitle"),
          color: existItemsInImpact(
            selectedPrescription.impactId,
            morisKySurveyList
          )
            ? "#20c997"
            : "#757575",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="morisKySurvey.dlgTitle" />
        </DialogTitle>
        <DialogContent>
          <Table
            data={morisKySurveyList}
            handleOpenCRU={handleOpenCRU}
            handleOpenDel={() => setOpenDeleteDialog(true)}
            setCurrentRowObj={setCurrentRowObj}
            currentImpact={selectedPrescription.impactId}
            readOnly={readOnly}
          />
          <Form
            obj={currentRowObj}
            impactId={selectedPrescription.impactId}
            openCRU={openCRU}
            handleRequestClose={handleRequestCloseCRU}
            handleCreateUpdate={handleCreateUpdate}
            readOnly={readOnly}
          />
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={openDeleteDialog}
            disableBackdropClick={true}
            onClose={handleRequestCloseDel}
          >
            <DialogTitle>
              <IntlMessages id="additionalMedications.MsgDelConfirmation" />
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleRequestCloseDel} color="secondary">
                <IntlMessages id="core.cancel" />
              </Button>
              <Button onClick={handleDelete} color="primary">
                <IntlMessages id="core.accept" />
              </Button>
            </DialogActions>
          </Dialog>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MoriskySurvey;
