import {
    CURRENT_MENU_ITEM_ID,
    ON_HIDE_LOADER,
    ON_TOGGLE_DRAWER,
    MANAGE_SEARCH_PRESCRIPTIONS,
    LOAD_MANAGE_MODULE,
    SELECT_PRODUCT_LINE,
    SELECT_PRESCRIPTION_STATE,
    SET_PRESCRIPTION_STATE,
    LOAD_PRESCRIPTIONS,
    SELECT_PRESCRIPTION,
    SET_LOADER_MANAGE_MODULE,
    SHOW_ALERT_MESSAGE,
    LOAD_PATIENT_INFO,
    UPDATE_SELECTED_PRESCRIPTION,
    REMOVE_SELECTED_PRESCRIPTION,
    ADD_PRESCRIPTION_GO_TRACING
  } from "constants/ActionTypes";
  
const INIT_STATE = {
    selectedMenuItem: 0,
    loader: false,
    drawerState: false,
    searchText: "",
    prescriptionsByMenuItem:[],
    prescriptionSearchResult:[],
    selectedPrescriptionId:0,
    selectedPrescription:null,
    productLines:[],
    selectedProductLine:-1,
    prescriptionStates:[],
    selectedPrescriptionState:-1,
    alertMessage:null
  };
 
const updateStateInPrescriptionList = (state, stateId) => {
  const { prescriptionsByMenuItem, selectedPrescription } = state;
  let index = prescriptionsByMenuItem.findIndex((x)=> x.id === selectedPrescription.id);
  let data = [...prescriptionsByMenuItem];
  data[index] = {...data[index], stateId};
  return data;
};

const updatePatientInPrescriptionList = (state, patient) => {
  const { prescriptionsByMenuItem, selectedPrescription } = state;
  let index = prescriptionsByMenuItem.findIndex((x)=> x.id === selectedPrescription.id);
  let data = [...prescriptionsByMenuItem];
  data[index] = {...data[index], patient};
  return data;
};

const updatePrescriptionInPrescriptionList = (state, prescription) => {
  const { prescriptionsByMenuItem, selectedPrescription } = state;
  let index = prescriptionsByMenuItem.findIndex((x)=> x.id === selectedPrescription.id);
  let data = [...prescriptionsByMenuItem];
  data[index] = prescription;
  return data;
};

const removePrescriptionInPrescriptionList = (state) => {
  const { prescriptionsByMenuItem, selectedPrescription } = state;
  let index = prescriptionsByMenuItem.findIndex((x)=> x.id === selectedPrescription.id);
  let data = [...prescriptionsByMenuItem];
  data.splice(index,1);
  return data;
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case CURRENT_MENU_ITEM_ID: {
          return { ...state, 
              selectedMenuItem: action.payload.menuItemId
            };
      }
      case ON_HIDE_LOADER: {
          return { ...state, loader: false };
      }
      case ON_TOGGLE_DRAWER: {
          return { ...state, drawerState: !state.drawerState };
      }
      case MANAGE_SEARCH_PRESCRIPTIONS: {
          // let result = state.prescriptionsByMenuItem;

          // if (action.payload && action.payload !== "") {
          //   const text = action.payload.toLowerCase();
          //   result = result.filter((item) => item.doctor.name.toLowerCase().indexOf(text) > -1                
          //         && item.productLineName.toLowerCase().indexOf(text) > -1
          //         && (item.patientName && item.patientName.toLowerCase().indexOf(text) > -1)
          //         && (item.patientCode && item.patientCode.toLowerCase().indexOf(text) > -1)
          //   );
          // }
          
          // return {
          //     ...state,
          //     searchText: action.payload,
          //     prescriptionSearchResult: result,
          //   };
          return { ...state, searchText: action.payload };
      }
      case LOAD_MANAGE_MODULE: {
          return { ...state, 
              productLines: action.payload.productLines,
              prescriptionStates: action.payload.prescriptionStates
            };
      }
      case SELECT_PRODUCT_LINE: {
          return { ...state, selectedProductLine: state.selectedProductLine === action.payload ? -1 : action.payload };
      }
      case SELECT_PRESCRIPTION_STATE: {
          return { ...state, selectedPrescriptionState: state.selectedPrescriptionState === action.payload ? -1 : action.payload };
      }
      case SET_PRESCRIPTION_STATE: {
          const newArray = updateStateInPrescriptionList(state, action.payload);
          return { ...state, prescriptionsByMenuItem: newArray, selectedPrescription: { ...state.selectedPrescription, stateId: action.payload }};
      }
      case LOAD_PRESCRIPTIONS: {
          return { ...state, 
              prescriptionsByMenuItem: action.payload.prescriptions,
              selectedMenuItem: action.payload.menuItemId,
              //selectedPrescriptionId: 0
            };
      }
      case SELECT_PRESCRIPTION: {
          // return { ...state, selectedPrescription: { doctor: action.payload.doctor, patient: action.payload.patient, ...action.payload } };
          return { ...state, selectedPrescription: action.payload };
      }
      case SET_LOADER_MANAGE_MODULE: {
          return { ...state, loader: action.payload };
      }
      case SHOW_ALERT_MESSAGE: {
        return { ...state, alertMessage: action.payload.message, alertType: action.payload.alertType };
      }
      case LOAD_PATIENT_INFO: {
        const newArray = updatePatientInPrescriptionList(state, action.payload);
        return { ...state, prescriptionsByMenuItem: newArray, selectedPrescription: { ...state.selectedPrescription, patient: action.payload }};
      }
      case UPDATE_SELECTED_PRESCRIPTION: {
        const newArray = updatePrescriptionInPrescriptionList(state, action.payload);
        return { ...state, prescriptionsByMenuItem: newArray, selectedPrescription: action.payload};
      }
      case REMOVE_SELECTED_PRESCRIPTION: {
        const newArray = removePrescriptionInPrescriptionList(state);
        return { ...state, prescriptionsByMenuItem: newArray, selectedPrescription: null};
      }
      case ADD_PRESCRIPTION_GO_TRACING: {
        const { prescription, menuItemId } = action.payload;
        const newArray = updatePrescriptionInPrescriptionList(state, prescription);
        return { ...state, prescriptionsByMenuItem: newArray, selectedPrescription: prescription, selectedMenuItem: menuItemId};
      }
      
      default:
        return state;
    }
  };
  