import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {Card, CardHeader} from 'reactstrap';
import { FileUploadWithOriginalName } from "components/Form/file-upload/index";
import DownloadButton from "components/DownloadButton/index";
import { callApiAsync } from "api/apiList";
import { setLoader } from "actions/Base";

const clearFile = () => {
    const elementFile =  document.getElementById("fm-file");
    if (elementFile)
        elementFile.value = "";
};

const CardFileUpload = ({disabled, file, setFile, actionApi, actionApiInfo, reset, showDownload = true }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));

  const [fileResult, setFileResult] = useState({fileName:"", originalName:""});
  const handleFilenameChange = (filenameResult) => { setFileResult(filenameResult); };

  const msgSuccess = IntlGetText("core.SuccessSave");
  const msgFileWarning = IntlGetText("core.fileNotFound");

  const resetControlFile = () =>{
    clearFile();
    setFileResult({fileName:"", originalName:""}); 
  };

  useEffect(()=>{
    if(reset)
      resetControlFile();
  },[reset]);

  const onSaveFileAsync = async () => {
    if (fileResult.fileName) {
      await callApiAsync(
        () => actionApi({ ...actionApiInfo, tempFilename: fileResult.fileName }),
        async (fileResponse) => {
          NotificationManager.success(msgSuccess);
          resetControlFile();    
          setFile(fileResponse);           
        },
        fnSetLoader
      );
    } else {
      NotificationManager.warning(msgFileWarning);
    }
  };
  return (
    <React.Fragment>
    { (!disabled || file) && 
      <Card className="shadow border-0 mt-4">
        <CardHeader>          
          { !disabled && 
            <FileUploadWithOriginalName id="fm-file" setResult={handleFilenameChange} >                
              <Button color="secondary" aria-label="add" component="span" className="mt-1">
                <i className="zmdi zmdi-upload mr-2"></i> <IntlMessages id="core.uploadFile" />
              </Button>
            </FileUploadWithOriginalName>
          }
          <span className="ml-2 mr-2">{fileResult.originalName}</span>
          <ButtonAsync style={{ minWidth: 'auto'}}
              onClick={onSaveFileAsync}
              variant="contained"
              color="primary"
              hidden={disabled}
            >
              {<i className="zmdi zmdi-floppy"></i>}
          </ButtonAsync>  
          {file && showDownload && <DownloadButton
                fileLink={file}
                keyMsg="core.download"
                color="default"
                icon="zmdi-download"
                variant="text"
                classes="float-right"
              />}
        </CardHeader>
      </Card>  
    }
    </React.Fragment>
  );
};

export default CardFileUpload;