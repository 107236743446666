import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as dayjs from "dayjs";
import { setLoader } from "actions/Base";
import { IntlGetText } from "util/IntlMessages";
import { callApi, getEventTrainingReport } from "api/apiList";
import Table, { defaultOptions, defaultCol } from "components/Form/Table";

const FeedbackEventTraining = ({ dateRange }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    if (!dateRange) return;

    callApi(
      () => getEventTrainingReport(dateRange.startDate, dateRange.endDate),
      (data) => setReportData(data),
      fnSetLoader
    );
  }, [dateRange]);

  const columns = [
    {
      name: "trainingDate",
      label: IntlGetText("core.date"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    defaultCol("name", "core.name"),
    defaultCol("description", "core.description"),
    defaultCol("nurse", "core.nurse"),
    defaultCol("comment", "core.comment")
  ];
  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-5 mb-5">
        <Table
          className="border-dialog-card mt-2 mb-2"
          data={reportData}
          columns={columns}
          options={defaultOptions()}
        />
      </div>
    </>
  );
};

export default FeedbackEventTraining;
