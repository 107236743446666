import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormCheckbox from "components/Form/Controls/checkbox";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { MoriskyOptionsQuestion8List, MoriskyOptionsQuestion8Enum } from "constants/Enums";

const getCboValue = (items, id) => {
  if(typeof(id) == "object"){
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const getDefaultQuestions = () => [
  {questionKey: 1, id: 0, moriskySurveyId:0, answer: 0},
  {questionKey: 2, id: 0, moriskySurveyId:0, answer: 0},
  {questionKey: 3, id: 0, moriskySurveyId:0, answer: 0},
  {questionKey: 4, id: 0, moriskySurveyId:0, answer: 0},
  {questionKey: 5, id: 0, moriskySurveyId:0, answer: 0},
  {questionKey: 6, id: 0, moriskySurveyId:0, answer: 0},
  {questionKey: 7, id: 0, moriskySurveyId:0, answer: 0},
  {questionKey: 8, id: 0, moriskySurveyId:0, answer: null}
];

const getItemValue = (item) => {
  if(item.answer === true)
    return true;
  if(item.answer === 1)
    return true;
  return false;
};

const FormCreateUpdate = ({ openCRU, handleRequestClose, impactId, obj, handleCreateUpdate, readOnly}) => {   
  const moriskyOptionsQuestion8List = MoriskyOptionsQuestion8List();

  let defaultValues = {
    comment: !obj || !obj.comment ? "" : obj.comment
  };
  let validationSchema = {
    comment: yup.string().required(<IntlMessages id="core.fieldRequired" />)
  };

  if(!obj){
    obj = { moriskySurveyDetail: getDefaultQuestions() };
  }

  obj.moriskySurveyDetail = obj.moriskySurveyDetail.map(item => {
    return {...item, name : IntlGetText("morisky.question"+item.questionKey)};
  });

  obj.moriskySurveyDetail.map(item => {
    if (item.questionKey === 8) {
      defaultValues["item" + item.questionKey] = null;
      validationSchema["item" + item.questionKey] = yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable();
    } else {
      defaultValues["item" + item.questionKey] = getItemValue(item);
    }
  });

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, errors } = methods;

  const loadData = () => {
    setValue("comment", !obj || !obj.comment ? "" : obj.comment);
    obj.moriskySurveyDetail.map(item => {
      if (item.questionKey === 8) {
        setValue("item" + item.questionKey, 
          getCboValue(moriskyOptionsQuestion8List,item.answer)
        );
      } else {
        setValue("item" + item.questionKey,getItemValue(item));
      }
    });
  };

  useEffect(()=>{
    if(openCRU){
      setTimeout(loadData,150);
    }
  },[openCRU]);

  const getItemAnswer=(d, item, itemData)=>{
    if (itemData.questionKey === 8) {
      return Number(d[item].value);
    } else {
      return d[item] === true ? 1 : 0 ;
    }
  };
  const onSubmit = async (d, e) => {
    var survey = {
      id: !obj || !obj.id ? 0 : obj.id,
      impactId: impactId,
      comment: d.comment
    };

    var questionList = [];
    var objKeys = Object.keys(d);
    objKeys.map((item, index) => {
      if(item === "comment")
        return;

      const itemId = Number(item.substr(4));
      
      let itemData = obj.moriskySurveyDetail.find(x => x.questionKey === itemId);
      questionList.push({
        ...itemData,
        answer: getItemAnswer(d, item, itemData)
      });
    });
    survey.moriskySurveyDetail = questionList;

    await handleCreateUpdate(survey);
  };

  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);

  const allowEdit = () => {
    if (!obj || !obj.id) return true;

    if (readOnly) return false;

    return obj.impactId == impactId;
  };
  const disabled = !allowEdit();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!obj.id ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                {
                  obj.moriskySurveyDetail.map((item, index) => {
                    if (item.questionKey !== 8) {
                      return (
                        //<Grid key={index} item xs={12} md={6} lg={6}>
                        <Grid key={index} item xs={12} md={12} lg={12}>
                          <FormCheckbox
                            name={"item" + item.questionKey}
                            label={item.name}
                            onChange={chkHandleChange("item" + item.questionKey)}
                            disabled={disabled}
                          />
                        </Grid>
                      );
                    }
                  })
                }
                <Grid item xs={12} md={12} lg={12}>
                  <FormSelectAutoComplete
                    name={"item8"}
                    label={obj.moriskySurveyDetail.find(x=>x.questionKey === 8).name}
                    options={moriskyOptionsQuestion8List}
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    name="comment"
                    label={<IntlMessages id="core.comment" />}
                    multiline
                    rows={4}
                    variant="outlined"
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          { !disabled &&
            <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
              <ButtonAsync onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
              >
                {<IntlMessages id="core.save" />}
              </ButtonAsync>
            </div>
          }
        </div>  
      </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>  
  );
};
export default FormCreateUpdate;
