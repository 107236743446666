import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormCheckbox from "components/Form/Controls/checkbox";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  MedicineTypeList,
  BoolList,
  DiagnosticTypeList,
  medicineTypeEnum,
  concomitantTreatmentTypeList
} from "constants/Enums";
import {
  callApi,
  getControlExamSelects,
  getDiagnosticByProduct,
  getGetHealthData,
  getProductlineByProductId,
  getTimeUnitSelect
} from "api/apiList";
import { isDirectTracing } from "app/routes/psp/services/userService";
const FormCRU = ({
  rowObj2,
  impactId,
  openCRU,
  additionalMedicationsList,
  handleRequestClose,
  handleCreateUpdate
}) => {
  const msgSuccess = IntlGetText("core.SuccessSave");

  const [rowObj, setCurrentObj] = useState(null);
  const [product, setproduct] = useState(null);
  const [concomitantValue, setConcomitantValue] = useState(null);
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const medicineTypeList = MedicineTypeList();
  const boolList = BoolList();
  const treatmentTypeList = concomitantTreatmentTypeList();
  const diagnosticTypeList = DiagnosticTypeList();
  const [timeUnitList, setTimeUnitList] = useState([]);
  const [loadForm, setLoadForm] = useState({
    diagnosticListLoaded: false,
    dataLoaded: false
  });
  const [controlExamUnitTypeSelect, setControlExamUnitTypeSelect] = useState(
    null
  );
  const [healthData, setHealthData] = useState({});

  const directTracing = isDirectTracing();

  const defaultValues = {
    activePrinciple: "",
    dosage: "",
    measurementUnit: "",
    productId: null,
    administrationRoute: "",
    supplyFrequency: "",
    treatmentStartDate: null,
    treatmentEndDate: null,
    lifeTime: false,
    diagnosticProductlineId: null,
    concomitant: null,
    concomitantDiagnostic: null,
    concomitantDate: null,
    diagnostStartDate: null,
    diagnostEndDate: null,
    supplyFrequencytimeUnit: null,
    medicineType: null
  };
  //  const validationSchema ={};
  const validationSchema = {
    medicineType: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    concomitant: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    dosage: yup.string()
    .matches(/^[0-9]+$/, IntlGetText("yup.numericValidation"))
    .test("is-valid-range",  IntlGetText("yup.invalidInput"), (value) => {
        const number = Number(value);
        return number > 0 && number < 1001;
    })
    .required("Required"),
    diagnosticProductlineId: yup
      .object()
      .when(["medicineType", "activePrinciple"], {
        is: (medicineType, activePrinciple) => {
          return (
            (medicineType && Number(medicineType.value) === 0) &&
            !activePrinciple
          );
        },
        then: yup
          .object()
          .shape({ value: yup.string().required() })
          .required(<IntlMessages id="core.fieldRequired" />)
          .nullable(),
      })
      .nullable(),
    productId: yup
      .object()
      .when("medicineType", {
        is: (medicineType) => medicineType && Number(medicineType.value) == 1,
        then: yup
          .object()
          .shape({ value: yup.string().required() })
          .required(<IntlMessages id="core.fieldRequired" />)
          .nullable()
      })
      .nullable(),
    supplyFrequencytimeUnit: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    concomitantDiagnostic: yup
      .object()
      .when("concomitant", {
        is: (concomitant) => concomitant && Number(concomitant.value) == 1,
        then: yup
          .object()
          .shape({ value: yup.string().required() })
          .required(<IntlMessages id="core.fieldRequired" />)
          .nullable()
      })
      .nullable(),
    supplyFrequency: yup.string()
      .matches(/^[0-9]+$/, IntlGetText("yup.numericValidation"))
      .test("is-valid-range",  IntlGetText("yup.invalidInput"), (value) => {
          const number = Number(value);
          return number > 0 && number < 1001;
      })
      .required("Required"),
      // cie: yup
      // .string()
      // .when(["concomitantDiagnostic", "concomitant"], {
      //   is: (concomitantDiagnostic, concomitant) =>
      //     concomitantDiagnostic && Number(concomitantDiagnostic.value) == 2 && 
      //     concomitant && Number(concomitant.value) == 1,
      //   then: yup
      //     .object()
      //     .shape({ value: yup.string().required() })
      //     .required(<IntlMessages id="core.fieldRequired" />)
      //     .nullable(),
      // })
      // .nullable(),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema))
  });
  const { handleSubmit, watch, setValue, reset, errors } = methods;
  const lifeTimeValue = watch("lifeTime");

  const cboMedicineTypeChange = (name) => (event) => {
    setValue(name, event);
    setValue("productId", "");
    setValue("activePrinciple", "");
  };

  const cboConcomitantChange = (name) => (event) => {
    setValue(name, event);
    setValue("concomitantDiagnostic", null);
    setValue("concomitantDate", null);
    setConcomitantValue(event ? event.value : null);
  };
  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);
  const cboHandleChange = (name) => (event) => {
    setValue(name, event);
  };

  const medicineTypeValue = watch("medicineType");
  const isOwnMedicine = medicineTypeValue && medicineTypeValue.value == 1;

  const concomitantDiagnosticwatch = watch("concomitantDiagnostic");
  const concomitantDiagnosticValue = concomitantDiagnosticwatch?.value;

  const productIdValue = watch("productId");
  const productIdHasValue = productIdValue && productIdValue.value != null;

  const onSubmit = async (d, e) => {
    var obj = {
      id: !rowObj ? 0 : rowObj.id,
      impactId: !rowObj ? impactId : rowObj.impactId,
      productId: !d.productId ? null : Number(d.productId.value),
      diagnosticProductlineId: !d.diagnosticProductlineId
        ? null
        : Number(d.diagnosticProductlineId.value),
      activePrinciple: isOwnMedicine ? null : d.activePrinciple,
      dosage: d.dosage != null && d.dosage != "" ? Number(d.dosage) : null,
      measurementUnit: d.measurementUnit.value, 
      administrationRoute: d.administrationRoute,
      supplyFrequency: d.supplyFrequency,
      DosageUnitTimeId: !d.supplyFrequencytimeUnit
        ? null
        : Number(d.supplyFrequencytimeUnit.value),
      treatmentStartDate: !d.treatmentStartDate
        ? null
        : dayjs(d.treatmentStartDate).format("YYYY-MM-DDTHH:mm:ss"),
      treatmentEndDate:
        d.lifeTime || !d.treatmentEndDate
          ? null
          : dayjs(d.treatmentEndDate).format("YYYY-MM-DDTHH:mm:ss"),
      lifeTime: d.lifeTime,
      cie: d.cie,
      concomitant: !d.concomitant 
        ? null
        : d.concomitant.value === null
        ? null
        : d.concomitant.value === "1"
        ? true
        : false,
      diagnosticType: !d.concomitantDiagnostic
        ? null
        : Number(d.concomitantDiagnostic.value),
      concomitantDate: !d.concomitantDate
        ? null
        : dayjs(d.concomitantDate).format("YYYY-MM-DDTHH:mm:ss"),
      diagnosticStarDate: !d.diagnostStartDate
        ? null
        : dayjs(d.diagnostStartDate).format("YYYY-MM-DDTHH:mm:ss"),
      diagnosticEndDate: !d.diagnostEndDate
        ? null
        : dayjs(d.diagnostEndDate).format("YYYY-MM-DDTHH:mm:ss")
    };

    await handleCreateUpdate(obj);
    handleRequestClose();
  };

  const getFormulaInfo = () => {
    const idImpact = rowObj?.impactId || impactId;
    callApi(
      () => getGetHealthData(idImpact),
      (data) => {
        setHealthData(data);
        getProductlineByProductIdData(data.productId);
      }
    );

    const getProductlineByProductIdData = (productId) => {
      callApi(
        () => getProductlineByProductId(productId),
        (data) => {
          getDiagnosticByProductData(data);
        }
      );
    };

    const getDiagnosticByProductData = (productLineId) => {
      callApi(
        () => getDiagnosticByProduct(productLineId),
        (data) => {
          setDiagnosticList(data);
        }
      );
    };
  };

  useEffect(() => {
    setCurrentObj(rowObj2);
    if (rowObj2) {
      setDisabled(true);
    }
  }, [rowObj2]);



  useEffect(() => {
    if (
      rowObj &&
      rowObj.diagnosticProductlineId &&
      !loadForm.diagnosticListLoaded &&
      diagnosticList.length > 0
    ) {
      setValue(
        "diagnosticProductlineId",
        getCboValue(diagnosticList, rowObj.diagnosticProductlineId)
      );
      setLoadForm({ ...loadForm, diagnosticListLoaded: true });
      return;
    }
  }, [diagnosticList]);

  const loadData = () => {
    setValue(
      "concomitant",
      getCboValue(
        treatmentTypeList ,!rowObj? null
          : rowObj.concomitant == null? null
          : rowObj.concomitant
          ? 1
          : 0
      )
    );
    setValue(
      "medicineType",
      getCboValue(boolList, !rowObj ? 1 : rowObj.productId == null ? 0: 1)
    );
    setproduct(!rowObj ? null : rowObj.productId);
    setConcomitantValue(!rowObj ? null : rowObj.concomitant ? 1 : 0);
    setValue("activePrinciple", !rowObj ? "" : rowObj.activePrinciple);
    setValue("dosage", !rowObj ? "" : rowObj.dosage);
    //setValue("measurementUnit", !rowObj ? "" : rowObj.measurementUnit);
    setValue(
      "measurementUnit",
      getCboValue(controlExamUnitTypeSelect, !rowObj ? null :rowObj.measurementUnit)
    );
    setValue("administrationRoute", !rowObj ? "" : rowObj.administrationRoute);
    setValue("supplyFrequency", !rowObj ? "" : rowObj.supplyFrequency);
    setValue("cie", !rowObj ? "" : rowObj.cie);
    setValue(
      "supplyFrequencytimeUnit",
      getCboValue(timeUnitList, !rowObj ? null : rowObj.dosageUnitTimeId)
    );
    setValue("treatmentStartDate", !rowObj ? null : rowObj.treatmentStartDate);
    setValue("treatmentEndDate", !rowObj ? null : rowObj.treatmentEndDate);
    setValue("lifeTime", !rowObj ? false : rowObj.lifeTime);
    setValue(
      "productId",
      getCboValue(additionalMedicationsList, !rowObj ? null : rowObj.productId)
    );
    setValue(
      "diagnosticProductlineId",
      getCboValue(diagnosticList, !rowObj ? null : rowObj.diagnosticProductlineId)
    );
    setValue(
      "concomitantDiagnostic",
      getCboValue(diagnosticTypeList, !rowObj ? null : rowObj.diagnosticType)
    );
    setValue("concomitantDate", !rowObj ? null : rowObj.concomitantDate);
    setValue("diagnostStartDate", !rowObj ? null : rowObj.diagnosticStarDate);
    setValue("diagnostEndDate", !rowObj ? null : rowObj.diagnosticEndDate);
  };

  const getCboValue = (items, id) => {
    if (typeof id == "object") {
      return id;
    }
    const result = items.find((x) => x.id === id);
    return result ? { label: result.name, value: result.id } : "";
  };

  const loadSelectData = () => {
    callApi(
      () => getTimeUnitSelect(),
      (data) => {
        setTimeUnitList(data);
      }
    );

    callApi(
      () => getControlExamSelects(),
      (data) => {
        setControlExamUnitTypeSelect(data.controlExamUnitTypeSelect);
      }
    );
  };

  useEffect(() => {
    if (openCRU) {
      setTimeout(loadData, 150);
    }
  }, [rowObj, openCRU]);

  useEffect(() => {
    if (openCRU) {
      setTimeout(loadData, 150);
      return;
    }
    setLoadForm({
      diagnosticListLoaded: false,
      dataLoaded: false
    });
  }, [rowObj, openCRU]);

  useEffect(() => {
    getFormulaInfo();
    loadSelectData();
  }, []);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!rowObj ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="concomitant"
                    label={<IntlMessages id="treatmentType.type" />}
                    options={treatmentTypeList}
                    value={watch("concomitant")}
                    onChange={cboConcomitantChange("concomitant")}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="medicineType"
                    label={<IntlMessages id="core.isOwnTreatment" />}
                    options={boolList}
                    onChange={cboMedicineTypeChange("medicineType")}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                {!isOwnMedicine ? (
                  <>
                    <Grid item xs={12} md={12} lg={6}>
                      <FormSelectAutoComplete
                        name="diagnosticProductlineId"
                        label={<IntlMessages id="core.activePrinciple" />}
                        options={diagnosticList}
                        value={watch("diagnosticProductlineId")}
                        onChange={cboHandleChange("diagnosticProductlineId")}
                        margin="normal"
                        required={true}
                        errorobj={errors}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <FormInput
                        name="activePrinciple"
                        label={<IntlMessages id="core.other" />}
                        margin="normal"
                        errorobj={errors}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} md={12} lg={6}>
                      <FormSelectAutoComplete
                        name="productId"
                        label={<IntlMessages id="core.treatment" />}
                        options={additionalMedicationsList}
                        value={watch("productId")}
                        margin="normal"
                        //disabled={disabled}
                        required={true}
                        errorobj={errors}
                      />
                    </Grid>
                  </>
                )}
                {concomitantValue == 1 && (
                  <>
                    <Grid item xs={12} md={12} lg={6}>
                      <FormSelectAutoComplete
                        name="concomitantDiagnostic"
                        label={<IntlMessages id="core.concomitantDiagnostic" />}
                        options={diagnosticTypeList}
                        value={watch("concomitantDiagnostic")}
                        onChange={cboHandleChange("concomitantDiagnostic")}
                        margin="normal"
                        required={true}
                        errorobj={errors}
                      />
                    </Grid>
                    {concomitantDiagnosticValue === 2 ? (
                      <>
                        <Grid item xs={12} md={12} lg={6}>
                          <FormInput
                            name="cie"
                            label={<IntlMessages id="core.CIE10" />}
                            margin="normal"
                            //required={true}
                            errorobj={errors}
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    <Grid item xs={12} md={12} lg={6}>
                      <FormDatePicker
                        name="concomitantDate"
                        label={
                          <IntlMessages id="core.concomitantUpdateTreatment" />
                        }
                        format="YYYY-MM-DD"
                        disableFuture={true}
                        margin="normal"
                        errorobj={errors}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="dosage"
                    label={<IntlMessages id="core.dosage" />}
                    margin="normal"
                    errorobj={errors}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="measurementUnit"
                    label={<IntlMessages id="core.measurementUnit" />}
                    options={controlExamUnitTypeSelect}
                    value={watch("supplyFrequency")}
                    margin="normal"
                    errorobj={errors}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="supplyFrequency"
                    label={<IntlMessages id="formula.supplyFrequency" />}
                    required={true}
                    margin="normal"
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="supplyFrequencytimeUnit"
                    label={<IntlMessages id="formula.unitTime" />}
                    options={timeUnitList}
                    value={watch("supplyFrequencytimeUnit")}
                    onChange={cboHandleChange("supplyFrequencytimeUnit")}
                    margin="normal"
                    // disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="administrationRoute"
                    label={<IntlMessages id="formula.administrationRoute" />}
                    margin="normal"
                    errorobj={errors}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <FormDatePicker
                    name="treatmentStartDate"
                    label={
                      <IntlMessages id="additionalMedications.treatmentStartDate" />
                    }
                    format="YYYY-MM-DD"
                    disableFuture={true}
                    margin="normal"
                    errorobj={errors}
                  />
                </Grid>
                {!lifeTimeValue && (
                  <Grid item xs={12} md={12} lg={6}>
                    <FormDatePicker
                      name="treatmentEndDate"
                      label={
                        <IntlMessages id="additionalMedications.treatmentEndDate" />
                      }
                      format="YYYY-MM-DD"
                      margin="normal"
                      errorobj={errors}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={12} lg={12}>
                  <FormCheckbox
                    name="lifeTime"
                    label={<IntlMessages id="additionalMedications.lifeTime" />}
                    value={watch("lifeTime")}
                    onChange={chkHandleChange("lifeTime")}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
            <ButtonAsync
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
            >
              {<IntlMessages id="core.save" />}
            </ButtonAsync>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormCRU;
