import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "actions/Base";
import { setInstitutionInfo } from "actions/Institution";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Grid, Button } from "@material-ui/core";
import { SketchField, Tools } from "react-sketch";
import PdfViewer from "components/PdfViewer";
import DialogAsync from "components/Form/DialogAsync";
import CardFileUpload from "components/Form/CardFileUpload";
import { callApi, callApiAsync, getInstitutionInfo, postSignByCompany } from "api/apiList";

const modalState = {
  none: 1,
  sign: 2
};

const ModalConcent = () => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const { ciInstitution, ciSignedDocument } = useSelector(({ institution }) => institution);

  const [openDlgSignCompany, setOpenDlgSignCompany] = useState(false);
  const [sketch, setSketch] = useState(false);
  const [file, setFile] = useState(null);
  const [modalStatus, setModalStatus] = useState(modalState.none);

  const msgSuccess = IntlGetText("core.SuccessSave");
  const msgSignRequired = IntlGetText("consent.validation");

  const closeDialog = () => setOpenDlgSignCompany(false);

  useEffect(() => {
    callApi(getInstitutionInfo,
      (data)=>{
        dispatch(setInstitutionInfo(data));
        if(!data.ciSignedDocument)
          setOpenDlgSignCompany(true);
      });
  }, []);

  const onSubmitAsync = async () => {
    if (!sketch.canUndo()) {
      NotificationManager.warning(msgSignRequired);
      return;
    }

    var obj = { signature: sketch.toDataURL() };
    await callApiAsync(
      () => postSignByCompany(obj),
      (data) => {
        NotificationManager.success(msgSuccess);
        dispatch(setInstitutionInfo(data));
        closeDialog();
      },
      fnSetLoader
    );
  };

  return (
    <DialogAsync
        open={openDlgSignCompany}
        closeDialog={closeDialog}
        onSubmitAsync={onSubmitAsync}
        titleTextId={"institution.consent"}
        allowEdit={modalStatus === modalState.sign}
      >
        {modalStatus === modalState.none &&
          <>
            <Grid item xs={12} md={12} lg={12}>
              <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<i className="zmdi zmdi-edit" />}
                  onClick={() => setModalStatus(modalState.sign)}
                >
                <IntlMessages id="ci.sign" />
              </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <CardFileUpload
                disabled={false}
                file={file}
                setFile={(file) => {
                  setFile(file);
                  dispatch(setInstitutionInfo(file));
                  closeDialog();
                }}
                actionApi={postSignByCompany}
                actionApiInfo={{ }}
                showDownload = {false}
              />
            </Grid>
          </>
        }
        {modalStatus === modalState.sign &&
          <>
            <PdfViewer src={ciInstitution} height="400" width="100%"/>
            <div
                className="row"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <div
                      className="text-center"
                      style={{ border: "1px solid lightgray" }}
                    >
                      <SketchField
                        ref={(c) => setSketch(c)}
                        width="100%"
                        height="180px"
                        tool={Tools.Pencil}
                        lineColor="black"
                        lineWidth={2}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </>
          }
      </DialogAsync>
  );
};

export default ModalConcent;
