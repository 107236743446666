import React, { useState } from "react";
import * as dayjs from "dayjs";
import { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import Table, { getTextLabels } from "components/Form/Table";
import FormEdit from "./FormEdit";
import BtnAddQuery from "./btnAdd";
import BtnAddFollowUp from "./btnAddFollowUp";
import { 
  QueryAdverseEventTypeList,
  QueryAdverseEventStateList, 
  RoleEnum,
  AdverseEventStateEnum,
  QueryAdverseEventStateEnum,
  AdverseEventTypeEnum
} from "constants/Enums";
import { getRoleUser } from "app/routes/psp/services/userService";

const allowAdd=(dataList)=>{
  if(dataList.length === 0)
    return true;
  const result = dataList.find(x=>x.state !== QueryAdverseEventStateEnum.approved);
  return !result;
};

const QueryAdverseEvent = ({adverseEvent, queryAdverseEventList, reloadQueries}) =>{ 
  const [currentRowObj, setCurrentRowObj] = useState(null);
  const [openCRU, setOpenCRU] = useState(false);

  const queryAdverseEventStateList = QueryAdverseEventStateList();
  const queryAdverseEventTypeList = QueryAdverseEventTypeList();
  const userInfo = getRoleUser();
  const isPharmacovigilanceProfile = () =>{
    const role = userInfo.role.toUpperCase();
    return role === RoleEnum.pharmacovigilance;
  };

  const notAllowEdit = (rowObj) => {
    return (rowObj && 
      ((rowObj.state === QueryAdverseEventStateEnum.approved || (rowObj.state === QueryAdverseEventStateEnum.resolved && !isPharmacovigilanceProfile()))
      || (!isPharmacovigilanceProfile() && !rowObj.assignedToNurse))
      );
  };
  
  const handleRequestCloseCRU = () => setOpenCRU(false);

  const readOnly = false;
  
  const getObj = (tableMeta) => queryAdverseEventList.find(x=>x.id === tableMeta.rowData[0]);

  const handleClick = (tableMeta) => {
    setOpenCRU(true);
    setCurrentRowObj(!tableMeta ? null : getObj(tableMeta));
  };


  const showBtnFollowUp = (adverseEvent, queryAdverseEventList) => {
    if(!allowAdd(queryAdverseEventList))
      return false;
    if(adverseEvent.status > AdverseEventStateEnum.verified)
      return true;
    return false;
  };

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: getTextLabels(),
    pagination:false
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display:"excluded"
      }
    },
    {
      name: "type",
      label: IntlGetText("core.type"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const type = queryAdverseEventTypeList.find(x=>x.id == value); 
          return !type ? "" : type.name;
        }
      }
    },
    {
      name: "state",
      label: IntlGetText("core.state"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const state = queryAdverseEventStateList.find(x=>x.id == value); 
          return !state ? "" : state.name;
        }
      }
    },
    {
      name: "description",
      label: IntlGetText("core.description"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "creationDate",
      label: IntlGetText("core.startDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD hh:mm a") : "";
        },
      },
    },
    {
      name: "endDate",
      label: IntlGetText("core.endDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD hh:mm a") : "";
        },
      },
    },
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowObj = getObj(tableMeta);
          if (readOnly || notAllowEdit(rowObj)){//rowObj.state === QueryAdverseEventStateEnum.resolved || rowObj.state === QueryAdverseEventStateEnum.approved) {
            return (
              <div>
                <Button
                  onClick={() => handleClick(tableMeta)}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className="zmdi zmdi-eye"></i>
                </Button>
              </div>
            );
          }

          return (
            <div>
              <Button
                onClick={() => handleClick(tableMeta)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-edit"></i>
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <React.Fragment>
      <FormEdit
        query={currentRowObj}
        openCRU={openCRU}
        handleRequestClose={handleRequestCloseCRU}
        readOnly={readOnly || notAllowEdit(currentRowObj)} //(currentRowObj && (currentRowObj.state === QueryAdverseEventStateEnum.resolved || currentRowObj.state === QueryAdverseEventStateEnum.approved))}
        reloadQueries={reloadQueries}
        setCurrentRowObj={setCurrentRowObj}
      />
      { isPharmacovigilanceProfile() && adverseEvent.status >= AdverseEventStateEnum.sentToRegulator && allowAdd(queryAdverseEventList) &&
        <BtnAddQuery 
        adverseEvent={adverseEvent}
        loadQueries={reloadQueries}/>
      }
      { isPharmacovigilanceProfile() && adverseEvent.adverseEventTypeId === AdverseEventTypeEnum.pharmacovigilance &&
        showBtnFollowUp(adverseEvent, queryAdverseEventList) &&
        <BtnAddFollowUp adverseEvent={adverseEvent}/>
      }
      <Table
        className="border-dialog-card mt-2 mb-2"
        data={queryAdverseEventList}
        columns={columns}
        options={options} />
    </React.Fragment>   
  );
};

export default QueryAdverseEvent