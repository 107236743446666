import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import * as dayjs from "dayjs";
import IntlMessages from "util/IntlMessages";
import { Grid } from "@material-ui/core";
import { setLoader } from "actions/Base";
import CardFileUpload from "components/Form/CardFileUpload";
import FormInput from "components/Form/Controls/input";
import DialogGeneric from "components/Form/DialogGeneric";
import { callApi, getFormulaReadOnly } from "api/apiList";
import {
  FormulaType,
  ProductBehavior
} from "constants/Enums";

const FormulaInfo = ({
  currentPrescriptionId,
  openDlg,
  handleRequestClose,
}) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  
  const [file, setFile] = useState(null);
  const [productBehavior, setProductBehavior] = useState(-1);
  const [formulaType, setFormulaType] = useState(FormulaType.MIPRES);

  var defaultValues = {
    formulaTypeText:"",
    prescriptionTypeText:"",
    stateProvince: "",
    city: "",
    expeditionDate: null,
    code: "",
    company: "",
    doctor: "",
    attentionScope: "",
    disease: "",
    product: "",

    medicineAmount: "",
    durationTreatment: "",
    pharmaceuticalQuantity:"",
    pharmaceuticalUnit: "",
    administrationRoute: "",
    additionalGuidelines: "",

    dosageUnit: "",
    dosageQuantity: "",
    dosageInfusionRate: "",
    dosageSupplyFrequency: ""    
  };

  const methods = useForm({ defaultValues });
  const { setValue } = methods;

  const setText = (obj, field) => setValue(field, !obj[field] ? "" : obj[field]);

  useEffect(() => {
    if (!currentPrescriptionId || !openDlg) return;

    callApi(
      () => getFormulaReadOnly(currentPrescriptionId),
      (data) => {
        setText(data,"formulaTypeText");
        setText(data,"prescriptionTypeText");
        setText(data,"stateProvince");
        setText(data,"city");
        //setText(data,"expeditionDate"); //*
        setText(data,"code");
        setText(data,"company");
        setText(data,"doctor");
        setText(data,"attentionScope");
        setText(data,"disease");
        setText(data,"product");

        setText(data,"medicineAmount");
        setText(data,"durationTreatment");
        setText(data,"pharmaceuticalQuantity");
        setText(data,"pharmaceuticalUnit");
        setText(data,"administrationRoute");
        setText(data,"additionalGuidelines");

        setText(data,"dosageUnit");
        setText(data,"dosageQuantity");
        setText(data,"dosageInfusionRate");
        setText(data,"dosageSupplyFrequency");
        
        if(data.expeditionDate)
          setValue("expeditionDate",dayjs(data.expeditionDate).format("YYYY-MM-DD"));

        setFormulaType(data.formulaType);
        setProductBehavior(data.CustomBehaviorId);
        if(data.file)
          setFile(data.file);
      },
      fnSetLoader
    );
  }, [currentPrescriptionId,openDlg]);


  const disabled = true;
  return (
    <DialogGeneric
      open={openDlg}
      closeDialog={handleRequestClose}
      titleText={<IntlMessages id="dashboard.comments" />}
      maxWidth="md"
    >
      
      <div className="col-xl-12 col-lg-12 col-md-12 col-12">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="formulaTypeText"
                  label={<IntlMessages id="formula.formulaType" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="prescriptionTypeText"
                  label={<IntlMessages id="formula.prescriptionType" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="stateProvince"
                  label={<IntlMessages id="core.stateProvince" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="city"
                  label={<IntlMessages id="core.city" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="expeditionDate"
                  label={<IntlMessages id="core.expeditionDate" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              {formulaType == FormulaType.MIPRES && (
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="code"
                    label={<IntlMessages id="formula.FormulaCode" />}
                    margin="normal"
                    disabled={disabled}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="company"
                  label={<IntlMessages id="core.institution" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="doctor"
                  label={<IntlMessages id="core.doctor" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              {formulaType == FormulaType.MIPRES && (
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="attentionScope"
                    label={<IntlMessages id="formula.attentionScope" />}
                    margin="normal"
                    disabled={disabled}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="disease"
                  label={<IntlMessages id="formula.diagnostic" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="product"
                  label={<IntlMessages id="formula.product" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="medicineAmount"
                  label={<IntlMessages id="formula.medicineAmount" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="dosageQuantity"
                  label={<IntlMessages id="core.dosage" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="dosageUnit"
                  label={<IntlMessages id="formula.dosageUnit" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              {(productBehavior == -1 ||
                productBehavior == ProductBehavior.remodulin || productBehavior == ProductBehavior.dacepton) && (
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                      name="infusionRate"
                      label={<IntlMessages id="formula.infusionRate" />}
                    margin="normal"
                    disabled={disabled}
                  />
                </Grid>
              )}
              { (productBehavior == -1 || (formulaType == FormulaType.MIPRES &&
                productBehavior != ProductBehavior.remodulin &&
                productBehavior != ProductBehavior.dacepton)) &&
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="dosageSupplyFrequency"
                    label={<IntlMessages id="formula.supplyFrequency" />}
                    margin="normal"
                    disabled={disabled}
                  />
                </Grid>  
              }
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="durationTreatment"
                  label={<IntlMessages id="formula.durationTreatment" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="pharmaceuticalQuantity"
                  label={<IntlMessages id="formula.pharmaceuticalQuantity" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="pharmaceuticalUnit"
                  label={<IntlMessages id="formula.pharmaceuticalUnit" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="administrationRoute"
                  label={<IntlMessages id="formula.administrationRoute" />}
                  margin="normal"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="additionalGuidelines"
                  label={<IntlMessages id="formula.additionalGuidelines" />}
                  margin="normal"                  
                  multiline
                  rows={3}
                  disabled={disabled}
                />
              </Grid>
            </Grid> 
          </form>
        </FormProvider>
        { file &&
          <CardFileUpload 
            disabled = { disabled }
            file={file} 
            setFile={()=>{}}
            actionApi={()=>{}}
            actionApiInfo={{ }}
          />
        }
      </div>
    </DialogGeneric>
  );
};

export default FormulaInfo;
