import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import RadicadoInsuranceTable from "./RadicadoInsuranceTable";
import InsuranceAuthorizationTable from "./InsuranceAuthorizationTable";
import RadicadoOLFTable from "./RadicadoOLFTable";
import DeliveryTable from "./DeliveryTable";
import { setLoader } from "actions/Base";
import { callApi, getGetProcessDetailPatient } from "api/apiList";

const ProcessDetail = ({
  currentPrescriptionId,
  openDlg,
  handleRequestClose,
}) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));

  const [radicadoInsuranceList, setRadicadoInsuranceList] = useState([]);
  const [authorizationList, setAuthorizationList] = useState([]);
  const [radicadoOLFList, setRadicadoOLFList] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);

  useEffect(() => {
    if (!currentPrescriptionId) return;

    callApi(
      () => getGetProcessDetailPatient(currentPrescriptionId),
      (detail) => {
        setRadicadoInsuranceList(detail.radicadoInsurance);
        setAuthorizationList(detail.authorization);
        setRadicadoOLFList(detail.radicadoOLF);
        setDeliveryList(detail.delivery);
      },
      fnSetLoader
    );
  }, [currentPrescriptionId]);

  return (
    <React.Fragment >
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openDlg}
        disableBackdropClick={true}
        onClose={handleRequestClose}
        //style={{overflow:"hidden"}}
      >
        <DialogTitle>
          <IntlMessages id="patient.processDetail" />
        </DialogTitle>
        <DialogContent >
          <div className="mt-5 text-uppercase text-grey">
            <h3>
              <IntlMessages id="radicacionInsurance.DialogTitle" />
            </h3>
          </div>
          <RadicadoInsuranceTable data={radicadoInsuranceList} />
          <div className="mt-5 text-uppercase text-grey">
            <h3>
              <IntlMessages id="insuranceAuthorization.tableTitle" />
            </h3>
          </div>
          <InsuranceAuthorizationTable data={authorizationList} />
          <div className="mt-5 text-uppercase text-grey">
            <h3>
              <IntlMessages id="alertType.radicadoOLF" />
            </h3>
          </div>
          <RadicadoOLFTable data={radicadoOLFList} />
          <div className="mt-5 text-uppercase text-grey">
            <h3>
              <IntlMessages id="insuranceAuthorization.deliveries" />
            </h3>
          </div>
          <DeliveryTable data={deliveryList} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ProcessDetail;
