import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle
} from "@material-ui/core";
import UploadFileForm from "components/Form/UploadFileForm";
//import Form from "./Form";
import {
    callActionApi,
    getImpactFileList,
    postAddImpactFile,
    deleteImpactFile
  } from "api/apiList";
import Table from "./Table";
import { ImpactFileTypeEnum } from "constants/Enums";

const PhotographicRecord = ({currentObj}) => {
  const { selectedPrescription } = useSelector(({ management }) => management);

  const [fileList, setFileList] = useState([]);
  const [currentRowObj, setCurrentRowObj] = useState(null);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleRequestCloseDel = () => setOpenDeleteDialog(false);
    
  const callApi = (apiFunc, setData) => {
    callActionApi(
      apiFunc,
      setData,
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  };

  const loadData = () => {
    callApi(
      () => getImpactFileList({
          impactId: currentObj.impactId,
          impactFileType: [
            ImpactFileTypeEnum.remodulinCatheter,
            ImpactFileTypeEnum.remodulinPump,
            ImpactFileTypeEnum.visit
          ]
      }),
      setFileList
    );
  };

  useEffect(() => loadData(), []);
  
  const callBackAddFile = (file) => loadData();
  
  const handleDelete = (data) => {
    callApi(
      () => deleteImpactFile(currentRowObj.id),
      () => {
        handleRequestCloseDel();
        setCurrentRowObj(null);
        loadData();
      }
    );
  };

  var readOnly = false;
  return (
    <div>
      {currentObj.impactId === selectedPrescription.impactId &&
        <UploadFileForm
          callBack={(file)=>callBackAddFile(file)}
          actionApi={postAddImpactFile}
          actionApiInfo={{ impactId: selectedPrescription.impactId }}
        />
      }
      <Table
        data={fileList}
        handleOpenDel={() => setOpenDeleteDialog(true)}
        setCurrentRowObj={setCurrentRowObj}
        currentImpact={selectedPrescription.impactId}
        readOnly={readOnly}
      />
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openDeleteDialog}
        disableBackdropClick={true}
        onClose={handleRequestCloseDel}
      >
        <DialogTitle>
          <IntlMessages id="additionalMedications.MsgDelConfirmation" />
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleRequestCloseDel} color="secondary">
            <IntlMessages id="core.cancel" />
          </Button>
          <Button onClick={handleDelete} color="primary">
            <IntlMessages id="core.accept" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PhotographicRecord;