import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { diagnosisFamilyHistoryList } from "constants/Enums";
import TableFamiliar from "./tableFamiliar.js";
import { callApiAsync, saveFamilyHistoryIds, DeleteFamilyHistoryCommand } from "api/apiList.js";
import { NotificationManager } from "react-notifications";

const FormFamiliar = ({
  rowObj2,
  impactId,
  openFamiliar,
  handleRequestClose
}) => {
  const msgSuccess = IntlGetText("core.SuccessSave");

  const [rowObj, setCurrentObj] = useState(null);
  //Selects
  const [familyHistory, setFamilyHistory] = useState(null);
  const [familiarTable, setFamiliarTable] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [currentRowObj, setCurrentRowObj] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const defaultValues = {
    orphanDisease: false,
    diagnosisConfirmed: false,
    diagnosisDate: null
  };

  const validationSchema = {
    familyHistory: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable()
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema))
  });
  const { handleSubmit, watch, setValue, reset, errors } = methods;

  const familyHistoryChange = (name) => (event) => {
    setFamilyHistory(event ? event.value : null);
  };

  const watchFamilyHistory = watch("familyHistory");


  const onSubmit = async (d, e) => {
    if (!watchFamilyHistory) {
      NotificationManager.warning("Debe seleccionar familiar");
      return;
    }

    var existRecords=0;
    let updatedList = [];

    watchFamilyHistory.forEach((item) => {
      const alreadyExists = familiarTable.some(
        (entry) => entry.id === item.value
      );
      if (!alreadyExists) {
        const newEntry = {
          id: item.value,
          familyHistoryTx: item.label
        };
        updatedList.push(item.value);

        setFamiliarTable((prev) => [...prev, newEntry]);
      }else{
        existRecords++;
      }
    });
    setFamilyHistory(null);
    setValue("familyHistory", null);
    if(existRecords>0){
      if(existRecords == watchFamilyHistory.length){
        NotificationManager.warning(
          "Las opciones seleccionadas ya est�n registradas"
        );
        return;
      }
      NotificationManager.warning(
        "Algunas de las opciones seleccionadas ya est�n registradas. Solo se a�adir�n las nuevas opciones seleccionadas"
      );
    }

    var dataToSend = {
      familyHistoryIds: updatedList,
      diagnosisRecordId: rowObj.id
    };
    await callApiAsync(
      () => saveFamilyHistoryIds(dataToSend),
      (response) => {
        NotificationManager.success(msgSuccess);
        setFamiliarTable(response);
      },
    );
  };

  useEffect(() => {
    setCurrentObj(rowObj2);
    if (rowObj2) {
      setDisabled(true);
    }
  }, [rowObj2]);

  const loadData = () => {
    setFamiliarTable(rowObj ? rowObj.familyHistoryTable : []);
  };

  const handleRequestCloseDel = () => {
    setOpenDeleteDialog(false);
  };

  useEffect(() => {
    if (openFamiliar) {
      setTimeout(loadData, 150);
    }
  }, [rowObj, openFamiliar]);

  const handleDelete = async (d, e) => {
    await callApiAsync(() => DeleteFamilyHistoryCommand(currentRowObj.id));
    setFamiliarTable((prev) =>
        prev.filter((item) => item.id !== currentRowObj.id)
    );
  
    handleRequestCloseDel();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openFamiliar}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={"Diagnosis.FamilyHistory"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={10} lg={10}>
                  <FormSelectAutoComplete
                    name="familyHistory"
                    label={<IntlMessages id="Diagnosis.FamilyHistory" />}
                    options={diagnosisFamilyHistoryList()}
                    value={watch("familyHistory")}
                    onChange={familyHistoryChange("familyHistory")}
                    margin="normal"
                    isMulti
                    required={true}
                    errorobj={errors}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  lg={2}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <div className="text-center">
                    <Button
                      onClick={() => onSubmit()}
                      variant="contained"
                      color="primary"
                    >
                      {<IntlMessages id="core.add" />}
                    </Button>
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <TableFamiliar
                    data={familiarTable}
                    handleOpenDel={() => setOpenDeleteDialog(true)}
                    setCurrentRowObj={setCurrentRowObj}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </div>

        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={openDeleteDialog}
          disableBackdropClick={true}
          onClose={handleRequestCloseDel}
        >
          <DialogTitle>
            <IntlMessages id="Diagnosis.MsgDelFamiliarConfirmation" />
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleRequestCloseDel} color="secondary">
              <IntlMessages id="core.cancel" />
            </Button>
            <Button onClick={handleDelete} color="primary">
              <IntlMessages id="core.accept" />
            </Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormFamiliar;
