export const downloadFile = (fileDto) => {
    const link = document.createElement("a");

    link.href = fileDto.file;
    link.setAttribute("download", fileDto.fileName);
    link.setAttribute("target", "_self");
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}