import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import Table from "./Table";
import Dlg from "./Dlg";
import {
  callApi,
  callApiAsync,
  getRemodulinTrackingList,
  postCreateUpdateRemodulinTracking,
  deleteRemodulinTracking,
} from "api/apiList";
import { callStartImpact } from "actions/Impact";
import { setLoader } from "actions/Base";
import { getUserInfo } from "app/routes/psp/services/userService";
import { ShardKeyEnum } from "constants/Enums";

const existItemsInImpact = (impactId, dataList) => {
  return dataList.find((x) => x.impactId == impactId) != undefined;
};

const InfusionPumps = ({ isRemodulin }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentSigned, isCIRequired } = useSelector(
    ({ impact }) => impact
  );

  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const [remodulinTrackingList, setRemodulinTrackinList] = useState([]);
  const [currentRowObj, setCurrentRowObj] = useState(null);

  const [open, setOpen] = useState(false);
  const [openCRU, setOpenCRU] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleRequestClose = () => setOpen(false);
  const handleOpenCRU = () => setOpenCRU(true);
  const handleRequestCloseCRU = () => setOpenCRU(false);
  const handleRequestCloseDel = () => setOpenDeleteDialog(false);

  const isBoidor = getUserInfo().shardKey === ShardKeyEnum.boidor;

  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    if (isCIRequired && !consentSigned) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }
    setOpen(true);
  };

  const loadData = () => {
    callApi(
      () => getRemodulinTrackingList(selectedPrescription.id),
      setRemodulinTrackinList,
      fnSetLoader
    );
  };

  useEffect(() => loadData(), []);

  const handleCreateUpdate = async (data) => {
    await callApiAsync(
      () => postCreateUpdateRemodulinTracking(data),
      () => {
        handleRequestCloseCRU();
        loadData();
      }
    );
  };

  const handleDelete = (data) => {
    callApi(
      () => deleteRemodulinTracking(currentRowObj.id),
      () => {
        handleRequestCloseDel();
        setCurrentRowObj(null);
        loadData();
      }
    );
  };

  var readOnly = false;

  return (
    <div>
      <InfoCard
        data={{
          icon: "assignment-check",
          title: IntlGetText(
            isBoidor
              ? "Lemax"
              : isRemodulin
              ? "infusionTracking.cardTitle"
              : "infusionTracking.cardTitle.device"
          ),
          subTitle: IntlGetText("infusionTracking.cardSubTitle"),
          color: existItemsInImpact(
            selectedPrescription.impactId,
            remodulinTrackingList
          )
            ? "#20c997"
            : "#757575",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages
            id={
              isRemodulin
                ? "infusionTracking.dlgTitle.device"
                : "infusionTracking.dlgTitle.device"
            }
          />
        </DialogTitle>
        <DialogContent>
          <Table
            data={remodulinTrackingList}
            handleOpenCRU={handleOpenCRU}
            handleOpenDel={() => setOpenDeleteDialog(true)}
            setCurrentRowObj={setCurrentRowObj}
            readOnly={readOnly}
            currentImpact={selectedPrescription.impactId}
            showAddButton={
              !existItemsInImpact(
                selectedPrescription.impactId,
                remodulinTrackingList
              )
            }
          />
          <Dlg
            obj={currentRowObj}
            impactId={selectedPrescription.impactId}
            openCRU={openCRU}
            handleRequestClose={handleRequestCloseCRU}
            handleCreateUpdate={handleCreateUpdate}
            readOnly={readOnly}
          />
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={openDeleteDialog}
            onClose={handleRequestCloseDel}
          >
            <DialogTitle>
              <IntlMessages id="additionalMedications.MsgDelConfirmation" />
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleRequestCloseDel} color="secondary">
                <IntlMessages id="core.cancel" />
              </Button>
              <Button onClick={handleDelete} color="primary">
                <IntlMessages id="core.accept" />
              </Button>
            </DialogActions>
          </Dialog>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InfusionPumps;
