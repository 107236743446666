import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { NotificationManager } from "react-notifications";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import dayjs from "dayjs";
import { getUserInfo } from "app/routes/psp/services/userService";
import { ShardKeyEnum } from "constants/Enums";

const FormCreateUpdate = (props) => {
  const {
    openCRU,
    handleRequestClose,
    obj,
    handleCreateUpdate,
    stateProvinceList,
    companyList,
    oplList,
    prescriptionId,
    currentObjEdit,
    getCitiesByStateProvinceId,
    callApi,
    pharmaAmount,
    boolList,
    deliverHealthData,
    barrier
  } = props;

  const isBehringer = getUserInfo().shardKey === ShardKeyEnum.atulado;
  const oplLabelId = isBehringer ? "deliveryTracing.oplSell" : "deliveryTracing.oplBuy";

  const getCboValue = (items, id) => {
    if (typeof id == "object") {
      return id;
    }
    const result = items.find((x) => x.id === id);
    return result ? { label: result.name, value: result.id } : "";
  };

  const defaultValues = {
    estimatedDeliveryDate: null,
    deliveryDate: null,
    marketchainId: null,
    marketchainDeliveryId: null,
    stateProvinceId: null,
    cityId: null,
    companyId: null,
    deliveryPoint: "",
    comment: "",
    amount: "",
    amountDelivery: "",
    amountPharmaceutical: "",
    delivery: null,
    rrdc: null,
    psp: null,
    deliveryNumber: "",
    pendingFormatAmount: "",
    pendingFormatDate: null,
    pendingFormatEndDate: null,
    barrier: null
  };

  let validationSchema = {
    stateProvinceId: yup
      .object()
      .when("delivery", {
        is: (delivery) => delivery && Number(delivery.value) === 1,
        then: yup
          .object()
          .shape({ value: yup.string().required() })
          .required(<IntlMessages id="core.fieldRequired" />)
          .nullable()
      })
      .nullable(),
    cityId: yup
      .object()
      .when("delivery", {
        is: (delivery) => delivery && Number(delivery.value) === 1,
        then: yup
          .object()
          .shape({ value: yup.string().required() })
          .required(<IntlMessages id="core.fieldRequired" />)
          .nullable()
      })
      .nullable(),
    marketchainId: yup
      .object()
      .when("delivery", {
        is: (delivery) => delivery && Number(delivery.value) === 1,
        then: yup
          .object()
          .shape({ value: yup.string().required() })
          .required(<IntlMessages id="core.fieldRequired" />)
          .nullable()
      })
      .nullable(),
    delivery: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    estimatedDeliveryDate: yup
      .date()
      .nullable()
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    deliveryNumber:  yup
      .number()
      .typeError(IntlGetText("yup.numericValidation"))
      .min(1, IntlGetText("yup.numericValidation"))
      .max(100, IntlGetText("yup.invalidInput"))
      .required(<IntlMessages id="core.fieldRequired" />)
  };

  if(!isBehringer){
    validationSchema.rrdc= yup
    .object()
    .when("delivery", {
      is: (delivery) => delivery && Number(delivery.value) === 1,
      then: yup
        .object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable()
    })
    .nullable();
    validationSchema.psp= yup
    .object()
    .when("delivery", {
      is: (delivery) => delivery && Number(delivery.value) === 1,
      then: yup
        .object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable()
    })
    .nullable();
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema))
  });

  const { handleSubmit, watch, setValue, errors } = methods;
  const deliveryValue = watch("delivery");

  const stateProvinceIdValue = watch("stateProvinceId");
  const cityIdValue = watch("cityId");
  const amountPharmaceuticalValue = watch("amountPharmaceutical");
  const amountDeliveryPharmaceuticalValue = watch("amountDeliveryPharmaceutical");
  const [isDisabled, setIsDisabled] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [loadForm, setLoadForm] = useState({
    stateProvinceListLoaded: false,
    cityListLoaded: false,
    dataLoaded: false
  });

  const cboHandleDeliveryChange = (name) => (event) => {
    setValue(name, event);
  };

  useEffect(() => {
    const numericAmountValue = parseFloat(amountPharmaceuticalValue) || 1;
    let finalAmount = numericAmountValue/pharmaAmount.pharmaAmount ;
    setValue("amount", finalAmount);
  }, [amountPharmaceuticalValue]);

  useEffect(() => {
    const numericAmountValue = parseFloat(amountDeliveryPharmaceuticalValue) || 1;
    let finalAmount = numericAmountValue/pharmaAmount.pharmaAmount;
    setValue("amountDelivery", finalAmount);
  }, [amountDeliveryPharmaceuticalValue]);
  useEffect(() => {
    setValue("cityId", null);
    if (!stateProvinceIdValue || !stateProvinceIdValue.value) {
      setCityList([]);
      return;
    }
    callApi(
      () => getCitiesByStateProvinceId(stateProvinceIdValue.value),
      setCityList
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProvinceIdValue]);

  useEffect(() => {
    if (!loadForm.cityListLoaded && cityList.length > 0) {
      setValue(
        "cityId",
        getCboValue(cityList, obj != null ? obj.cityId : null)
      );
      setLoadForm({ ...loadForm, cityListLoaded: true });
    }
  }, [stateProvinceList, cityList]);

  const loadData = () => {
    setValue(
      "stateProvinceId",
      !obj || !obj.stateProvinceId
        ? ""
        : getCboValue(stateProvinceList, obj.stateProvinceId)
    );
    setValue(
      "marketchainDeliveryId",
      !obj || !obj.marketChainDeliveryId
        ? ""
        : getCboValue(oplList, obj.marketChainDeliveryId)
    );
    setValue(
      "estimatedDeliveryDate",
      !obj || !obj.estimatedDeliveryDate
        ? null
        : dayjs(obj.estimatedDeliveryDate)
    );
    setValue(
      "deliveryDate",
      !obj || !obj.deliveryDate ? null : dayjs(obj.deliveryDate)
    );
    setValue(
      "marketchainId",
      !obj || !obj.marketChainId ? "" : getCboValue(oplList, obj.marketChainId)
    );
    setValue(
      "companyId",
      !obj || !obj.companyId ? "" : getCboValue(companyList, obj.companyId)
    );
    setValue(
      "deliveryNumber",
      !obj || !obj.deliveryNumber ? "" : obj.deliveryNumber
    );

    setValue("comment", !obj || !obj.comment ? "" : obj.comment);
    setValue(
      "deliveryPoint",
      !obj || !obj.deliveryPoint ? "" : obj.deliveryPoint
    );
    setValue("amount", !obj || !obj.amount ? "" : obj.amount);
    setValue(
      "amountPharmaceutical",
      !obj || (!obj.amount && !obj.phamaceuticalQuantity)
        ? ""
        : obj.amount * obj.phamaceuticalQuantity
    );
    setValue(
      "amountDelivery",
      !obj || !obj.amountDelivery ? "" : obj.amountDelivery
    );
    setValue(
      "amountDeliveryPharmaceutical",
      !obj || (!obj.amountDelivery && !obj.phamaceuticalQuantity)
        ? ""
        : obj.amountDelivery * obj.phamaceuticalQuantity
    );

    setValue(
      "delivery",
      !obj || obj.record === null
        ? ""
        : getCboValue(boolList, obj.record ? 1 : 0)
    );
    setValue(
      "rrdc",
      !obj || obj.rrdc === null ? "" : getCboValue(boolList, obj.rrdc ? 1 : 0)
    );
    setValue(
      "psp",
      !obj || obj.psp === null ? "" : getCboValue(boolList, obj.psp ? 1 : 0)
    );
    setValue(
      "cityId",
      !obj || !obj.cityId ? "" : getCboValue(cityList, obj.cityId)
    );
    setValue(
      "barrier",
      !obj || !obj.stateProvinceId
        ? ""
        : getCboValue(barrier, obj.barrierOptionsId)
    );
    setValue(
      "pendingFormatDate",
      !obj || !obj.pendingFormatDate ? null : dayjs(obj.pendingFormatDate)
    );
    setValue(
      "pendingFormatEndDate",
      !obj || !obj.pendingFormatDate ? null : dayjs(obj.pendingFormatEndDate)
    );
    setValue(
      "pendingFormatAmount",
      !obj || !obj.pendingFormatAmount ? "" : obj.pendingFormatAmount
    );
  };

  useEffect(() => {
    if (openCRU) {
      setTimeout(loadData, 150);
      return;
    }
    setLoadForm({
      stateProvinceListLoaded: false,
      cityListLoaded: false,
      dataLoaded: false
    });
  }, [openCRU]);

  const handleRequestCloseTmp = () => {
    setLoadForm({
      stateProvinceListLoaded: false,
      cityListLoaded: false,
      dataLoaded: false
    });
    handleRequestClose();
  };

  const onSubmit = async (d, e) => {
    if (deliverHealthData < d.deliveryNumber) {
      NotificationManager.warning(
        "El número de entrega no puede ser mayor que el número registrado en la fórmula médica."
      );
      return;
    }

    const item = {
      id: obj?.id || 0,
      prescriptionId: prescriptionId,
      estimatedDeliveryDate: d.estimatedDeliveryDate ? dayjs(d.estimatedDeliveryDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null,
      deliveryDate: d.deliveryDate ? dayjs(d.deliveryDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null,
      record: d.delivery?.value == 1 ? true : false,
      deliveryNumber: !d.deliveryNumber ? null : parseInt(d.deliveryNumber),
      marketChainId: parseInt(d.marketchainId?.value) ,
      marketChainDeliveryId:
         d.marketchainDeliveryId?.value
          ? parseInt(d.marketchainDeliveryId.value)
          : null,
      companyId:
         d.companyId?.value ? parseInt(d.companyId.value) : null,
      cityId:  parseInt(d.cityId?.value) ,
      deliveryPoint:  d.deliveryPoint || null,
      comment:  d.comment || null ,
      amount: d.amount ? parseFloat(d.amount) : null,
      amountDelivery:
         d.amountDelivery ? parseFloat(d.amountDelivery) : null,
      rrdc: d.rrdc?.value == null ? null : d.rrdc.value == 1 ? true : false,
      psp: d.psp?.value == null ? null : d.psp.value == 1 ? true : false,
      barrierOptionsId: d.barrier?.value
      ? parseInt(d.barrier.value)
      : null,
      pendingFormatDate: d.pendingFormatDate ? dayjs(d.pendingFormatDate).format("YYYY-MM-DDTHH:mm:ss") : null,
      pendingFormatEndDate: d.pendingFormatEndDate ? dayjs(d.pendingFormatEndDate).format("YYYY-MM-DDTHH:mm:ss") : null,
      pendingFormatAmount:
        d.pendingFormatAmount
          ? parseFloat(d.pendingFormatAmount)
          : null
    };

    await handleCreateUpdate(item);
  };

  const allowEdit = () => {
    if (obj && obj.record == true) return true;
  };

  const disabled = allowEdit();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestCloseTmp}
    >
      <DialogTitle>
        <IntlMessages
          id={!obj ? "core.add" : currentObjEdit ? "core.edit" : "sidebar.view"}
        />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <FormDatePicker
                    name="estimatedDeliveryDate"
                    label={
                      <IntlMessages id="deliveryTracing.estimatedDeliveryDate" />
                    }
                    format="YYYY-MM-DD"
                    margin="normal"
                    disableFuture={false}
                    disabled={!currentObjEdit}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormDatePicker
                    name="deliveryDate"
                    label={<IntlMessages id="deliveryTracing.deliveryDate" />}
                    format="YYYY-MM-DD"
                    margin="normal"
                    disableFuture={false}
                    disabled={!currentObjEdit}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="delivery"
                    label={<IntlMessages id="deliveryTracing.delivery" />}
                    onChange={cboHandleDeliveryChange("delivery")}
                    options={boolList}
                    margin="normal"
                    disabled={!currentObjEdit || disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="deliveryNumber"
                    label={<IntlMessages id="deliveryTracing.deliveryNumber" />}
                    margin="normal"
                    rows={3}
                    variant="outlined"
                    disabled={!currentObjEdit}
                    errorobj={errors}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="stateProvinceId"
                    label={<IntlMessages id="core.stateProvince" />}
                    options={stateProvinceList}
                    margin="normal"
                    disabled={!currentObjEdit}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="cityId"
                    label={<IntlMessages id="core.city" />}
                    options={cityList}
                    margin="normal"
                    disabled={!currentObjEdit}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={isBehringer ? 12 : 6}>
                  <FormSelectAutoComplete
                    name="marketchainId"
                    label={<IntlMessages id={oplLabelId} />}
                    options={oplList}
                    margin="normal"
                    disabled={!currentObjEdit}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                {!isBehringer && (
                  <Grid item xs={12} md={12} lg={6}>
                    <FormSelectAutoComplete
                      name="marketchainDeliveryId"
                      label={<IntlMessages id="deliveryTracing.oplSell" />}
                      options={oplList}
                      margin="normal"
                      disabled={!currentObjEdit}
                      errorobj={errors}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="companyId"
                    label={<IntlMessages id="deliveryTracing.company" />}
                    options={companyList}
                    margin="normal"
                    disabled={!currentObjEdit}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="deliveryPoint"
                    label={<IntlMessages id="core.deliveryPoint" />}
                    margin="normal"
                    rows={3}
                    variant="outlined"
                    disabled={!currentObjEdit}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="amountPharmaceutical"
                    label={
                      <IntlMessages id="deliveryTracing.amountPharmaceutical" />
                    }
                    margin="normal"
                    rows={3}
                    variant="outlined"
                    disabled={false}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="amount"
                    label={<IntlMessages id="deliveryTracing.amount" />}
                    margin="normal"
                    rows={3}
                    variant="outlined"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="amountDeliveryPharmaceutical"
                    label={
                      <IntlMessages id="deliveryTracing.amountDeliveryPharmaceutical" />
                    }
                    margin="normal"
                    rows={3}
                    variant="outlined"
                    disabled={false}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="amountDelivery"
                    label={
                      <IntlMessages id="deliveryTracing.amountDelivery" />
                    }
                    margin="normal"
                    rows={3}
                    variant="outlined"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                
                {!isBehringer && (
                  <>
                    <Grid item xs={12} md={12} lg={6}>
                      <FormSelectAutoComplete
                        name="rrdc"
                        label={<IntlMessages id="deliveryTracing.rrdc" />}
                        options={boolList}
                        margin="normal"
                        disabled={!currentObjEdit}
                        required={true}
                        errorobj={errors}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <FormSelectAutoComplete
                        name="psp"
                        label={<IntlMessages id="deliveryTracing.psp" />}
                        options={boolList}
                        margin="normal"
                        disabled={!currentObjEdit}
                        required={true}
                        errorobj={errors}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="barrier"
                    label={<IntlMessages id="barrier.barrier" />}
                    options={barrier}
                    margin="normal"
                    disabled={!currentObjEdit}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="pendingFormatAmount"
                    label={
                      <IntlMessages id="deliveryTracing.pendingFormatAmount" />
                    }
                    margin="normal"
                    rows={3}
                    variant="outlined"
                    disabled={!currentObjEdit}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormDatePicker
                    name="pendingFormatDate"
                    label={
                      <IntlMessages id="deliveryTracing.pendingFormatDate" />
                    }
                    format="YYYY-MM-DD"
                    margin="normal"
                    disableFuture={false}
                    disabled={!currentObjEdit}
                    errorobj={errors}
                  />
                </Grid><Grid item xs={12} md={6} lg={6}>
                  <FormDatePicker
                    name="pendingFormatEndDate"
                    label={
                      <IntlMessages id="deliveryTracing.pendingFormatEndDate" />
                    }
                    format="YYYY-MM-DD"
                    margin="normal"
                    disableFuture={false}
                    disabled={!currentObjEdit}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    name="comment"
                    label={<IntlMessages id="core.comment" />}
                    margin="normal"
                    multiline
                    rows={3}
                    variant="outlined"
                    disabled={!currentObjEdit}
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          {currentObjEdit && (
            <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
              <ButtonAsync
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
              >
                {<IntlMessages id="core.save" />}
              </ButtonAsync>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestCloseTmp} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FormCreateUpdate;
