import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import * as dayjs from "dayjs";
import DialogGeneric from "components/Form/DialogGeneric";
import ScrollContent from "../../psp/management/scrollContent";
import StartEndImpact from "./startEndImpact";
import PatientBox from "./patientBox";
import DeliveryBox from "./deliveryBox";
import PrescriptionBox from "./prescriptionBox";
import PrescriptionStateBox from "./prescriptionStateBox";
import ImpactBox from "./impactBox";
import { callApi, getImpactInfo } from "api/apiList";

const ImpactDetail = ({
  openDlg,
  requestCloseDlg,
  impactId,
  prescription,
  isManager
}) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [currentImpact, setCurrentImpact] = useState(null);
  const patientName = IntlGetText("core.newPatient");

  const loadImpactInfo = (impactId) => {
    callApi(()=>getImpactInfo(impactId), setCurrentImpact, fnSetLoader);
  };

  useEffect(()=>{
    if(!impactId){
      setCurrentImpact(null);
      return;
    }
    loadImpactInfo(impactId);
  },[impactId]);

  const getPatientName = (impact) => {
    if(!impact) return "";
    return !impact.patient
      ? patientName
      : impact.document + " - "+ impact.patient;
  };

  const getProp = (obj, prop) => {
    if (!obj) return "";
    return obj[prop];
  };
  const getDate = (obj, prop) => {
    if (!obj && !obj[prop]) return "";
    return dayjs(obj[prop]).format("YYYY/MM/DD hh:mm a");
  };

  const closeDialog = () => {
    setCurrentImpact(null);
    requestCloseDlg();
  };

  const allowEdit = (impact) => !isManager && impact && impact.startDate ? true : false;

  return (
    <DialogGeneric
      open={openDlg}
      closeDialog={closeDialog}
      maxWidth="md"
    >
      <ScrollContent
        width={window.innerWidth}
        heightMax="calc(100vh - 200px)"
        heightMin="calc(100vh - 177px)"
      >
        <div className="mail-header">
          <div className="mail-header-content col pl-0">
            <div className="subject" style={{ paddingTop: 20 }}>
              <strong>
                <IntlMessages id="core.mipres" />:{" "}
              </strong>{" "}
              {getProp(currentImpact, "prescriptionCode") || getProp(prescription, "code")}
              <strong>
                {" "}
                | <IntlMessages id="core.state" />:{" "}
              </strong>{" "}
              {getProp(currentImpact, "status") || getProp(prescription, "status")}              
            </div>
            <div className="subject">
              <strong><IntlMessages id="core.patient" />:{" "}
              </strong>{" "}
              {getPatientName(currentImpact) || getPatientName(prescription)}
              <strong>
                {" "}
                | <IntlMessages id="core.product" />:{" "}
              </strong>{" "}
              {getProp(currentImpact, "product") || getProp(prescription, "product")}
            </div>
            {currentImpact && 
              <div className="subject">
                <strong><IntlMessages id="impact.ImpactType" />:{" "}
                </strong>{" "}
                {getProp(currentImpact, "impactType")}
                <strong>
                  {" "}
                  | <IntlMessages id="core.startDate" />:{" "}
                </strong>{" "}
                {getDate(currentImpact, "startDate")}
              </div>
            }
          </div>
          {!isManager && 
            <StartEndImpact 
              prescriptionId={getProp(prescription, "id")} 
              currentImpact={currentImpact}
              setCurrentImpact={setCurrentImpact} 
              requestCloseDlg={closeDialog}/>
          }
        </div>
        <hr />
        <div className="row first-impact-box-margins" style={{ padding: 20 }}>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <ImpactBox prescriptionId={getProp(prescription, "id")} />
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <DeliveryBox 
              prescriptionId={getProp(prescription, "id")}
              impact={currentImpact}
              medicineAmount= {getProp(currentImpact, "medicineAmount") || getProp(prescription, "quantity")}
              marketchainId= {getProp(currentImpact, "marketchainId") || getProp(prescription, "marketchainId")}              
              closeDialog={()=>loadImpactInfo(currentImpact && currentImpact.id)}
              numberDeliveries={getProp(prescription, "numberDeliveries")}
              allowEdit={allowEdit(currentImpact)}/>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <PrescriptionStateBox 
              prescriptionId={getProp(prescription, "id")}
              statusId = {getProp(currentImpact, "statusId") || getProp(prescription, "statusId")}
              allowEdit={allowEdit(currentImpact)}
              callBack={()=>loadImpactInfo(currentImpact && currentImpact.id)}
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <PrescriptionBox
              prescriptionId={getProp(prescription, "id")} 
              allowEdit={allowEdit(currentImpact)}
              callBack={()=>loadImpactInfo(currentImpact && currentImpact.id)}
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <PatientBox
              patientId={currentImpact && currentImpact.patientId ? currentImpact.patientId : getProp(prescription, "patientId")}
              allowEdit={allowEdit(currentImpact)}
              callBack={()=>loadImpactInfo(currentImpact && currentImpact.id)}
            />
          </div>
        </div>
      </ScrollContent>
    </DialogGeneric>
  );
};

export default ImpactDetail;
