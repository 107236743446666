import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ClinicHistoryFile from "./ClinicHistoryFile";

let id = 0;

function createData(date, detail) {
  id += 1;
  return { id, date, detail };
}

const data = [
  createData("2020/05/20", "Fórmula Médica"),
  createData("2020/05/22", "Estudios Paraclínicos"),
  createData("2020/05/26", "Antecedentes Alérgicos"),
  createData("2020/05/29", "Antecedentes Patológicos"),
];

function ClinicHistoryTable() {
  return (
    <div className="table-responsive-material">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell align="center">Detalle</TableCell>
            <TableCell align="center">Archivos</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((n) => {
            return (
              <TableRow key={n.id}>
                <TableCell>{n.date}</TableCell>
                <TableCell align="center">{n.detail}</TableCell>
                <TableCell align="center">
                  <ClinicHistoryFile />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default ClinicHistoryTable;
