import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import { Button, Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions 
} from "@material-ui/core";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import FormDateTimePicker from "components/Form/Controls/dateTimePicker";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete, { getCboValue } from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import Resources from "./resources"
import { 
  callApi,
  getProductsAndStates
} from "api/apiList";
import { TrainingStateEnum, TrainingStateList } from "constants/Enums"

const DlgEventTraining = ({open, handleRequestClose, event, readOnly, handleEventTraining }) => { 
  const [ productLineList, setProductLineList ] = useState([]);

  const trainingStateList = TrainingStateList();
  const msgStateValidation = IntlGetText("eventTraining.stateValidation");
  
    const defaultValues = {      
      trainingDate: null,
      name: "",
      description: "",
      productlineId: null,
      state:null
    };

    const validationSchema = {
      trainingDate: yup.date()
        .nullable(true)
        .typeError(IntlGetText("yup.InvalidDate"))
        .required(<IntlMessages id="core.fieldRequired" />),
      name: yup.string().required(<IntlMessages id="core.fieldRequired" />),
      description: yup.string().required(<IntlMessages id="core.fieldRequired" />),
      productlineId: yup.object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable()
    };

    const methods = useForm({ defaultValues, resolver: yupResolver(yup.object().shape(validationSchema)) });
    const { handleSubmit,  setValue, errors } = methods;

    const loadData = () => {
      setValue("trainingDate", !event ? null : dayjs(event.planningDate));
      setValue("name", !event || !event.name ? "" : event.name);
      setValue("description", !event || !event.description ? "" : event.description);
      setValue("productlineId", !event || !event.productLineId ? null : getCboValue(productLineList,event.productLineId));
      setValue("state", !event || (!event.state && event.state !== 0) ? null : getCboValue(trainingStateList,event.state));
    };

    useEffect(() => {
      callApi(
        getProductsAndStates,
        ({productLines})=>setProductLineList(productLines.map(x=>({
          name:x.title,
          ...x
        })))
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
      if(open){
        setTimeout(loadData,150);
      }
    },[open]);
    
    const onSubmit = async (data, e) => {
      const obj = {
        id: !event || !event.id ? 0 : event.id,
        trainingDate: dayjs(data.trainingDate).format("YYYY-MM-DDTHH:mm:ss"),
        name: data.name,
        description: data.description,
        productlineId: Number(data.productlineId.value),
        state: event && event.id > 0 ? Number(data.state.value) : TrainingStateEnum.planned
      };
      if(obj.state == TrainingStateEnum.done && dayjs(data.trainingDate) > dayjs()){
        NotificationManager.warning(msgStateValidation)
        return;
      }

      await handleEventTraining(obj)
    };

    const isDone = () => event && event.state === TrainingStateEnum.done;
    const disabled = !readOnly ? isDone() : readOnly;
  
    return (
        <Dialog open={open} fullWidth={true} maxWidth="sm" 
          disableBackdropClick={true} onClose={handleRequestClose} >
            <DialogTitle>{<IntlMessages id="impact.rescheduleImpact" />}</DialogTitle>
            <DialogContent className="new-patient-dialog-content">
                <FormProvider {...methods}>
                    <form className="row" noValidate autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <FormDateTimePicker
                                name="trainingDate"
                                label={IntlGetText("core.dateAndTime")}  
                                format="YYYY-MM-DD hh:mm A"  
                                disablePast={true}
                                required={true} 
                                errorobj={errors}  
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <FormInput         
                            name="name"         
                            label={<IntlMessages id="core.name" />}
                            variant="outlined"
                            required={true}
                            errorobj={errors}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <FormInput         
                            name="description"         
                            label={<IntlMessages id="core.description" />}                                
                            multiline
                            rows={5}
                            variant="outlined"
                            required={true}
                            errorobj={errors}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <FormSelectAutoComplete
                            name="productlineId"
                            label={<IntlMessages id="core.productLine" />}
                            options={productLineList}
                            margin="normal"
                            disabled={disabled}
                            errorobj={errors}
                          />
                        </Grid>
                        {event && event.id > 0 &&
                          <Grid item xs={12} md={12} lg={12}>
                            <FormSelectAutoComplete
                              name="state"
                              label={<IntlMessages id="core.state" />}
                              options={trainingStateList}
                              margin="normal"
                              isClearable={false}
                              disabled={disabled}
                              errorobj={errors}
                            />
                          </Grid>
                        }
                      </Grid>
                    </form>
                </FormProvider>
                {event && event.id > 0 &&
                  <Resources eventTraining={event} readOnly={readOnly}/>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRequestClose} color="secondary">
                    {<IntlMessages id="core.cancel" />}
                </Button>
                {!disabled &&
                  <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
                      {<IntlMessages id="core.save" />}
                  </ButtonAsync>
                }
            </DialogActions>
        </Dialog>
    );
  };

  export default DlgEventTraining;