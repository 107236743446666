import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import DayjsUtils from "@date-io/dayjs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker } from "@material-ui/pickers";

const MuiDatePicker = (props) => {
  const { className, name, required, errorobj } = props;

  let isError = false;
  let errorMessage = "";
  if (errorobj && errorobj.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errorobj[name].message;
  }

  return (
    <React.Fragment>
      <KeyboardDatePicker
        autoOk={true}
        inputVariant="outlined"
        margin="dense"
        size="small"
        format="DD-MM-YYYY"
        fullWidth={true}
        InputLabelProps={{
          className: required ? "required-label" : "",
          required: required || false,
        }}
        error={isError}
        helperText={errorMessage}
        className={className}
        okLabel = ""
        cancelLabel = ""
        {...props}
      />
    </React.Fragment>
  );
};

function FormDatePicker(props) {
  const { control } = useFormContext();
  const {
    name,
    defaultValue,
    value,
    label,
    onChange,
    className,
    ...dateProps
  } = props;

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          value={value}
          render={({ onBlur, onChange, value }) => (
            <MuiDatePicker
              name={name}
              label={label}
              onBlur={onBlur}
              className = {className}
              onChange={(value) => {
                onChange(value);
                if (props.onChange) props.onChange(value);
              }}
              value={value}
              {...dateProps}
            />
          )}
        />
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
}

export default FormDatePicker;
