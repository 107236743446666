import { getAllPatient } from "../../../../api/apiPatient";

import React, { useState, useEffect } from "react";

import CardBox from "components/CardBox/index";

import MUIDataTable from "mui-datatables";
import * as dayjs from "dayjs";

import { datatableConfig } from "./../data";

const PatientList2 = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const columns = [
    { name: "name", label: "Nombre", options: { filter: true, sort: true } },
    {
      name: "lastName",
      label: "Apellido",
      options: { filter: true, sort: true },
    },
    {
      name: "address",
      label: "Dirección",
      options: { filter: true, sort: true },
    },
    {
      name: "cityName",
      label: "Ciudad",
      options: { filter: true, sort: true },
    },
    {
      name: "birthday",
      label: "Fecha Nacimiento",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    {
      name: "isHospitalized",
      label: "Hospitalizado",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? "Si" : "No";
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    textLabels: datatableConfig.textLabels,
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await getAllPatient();    

        setData(data);
      } catch (e) {
        setError(e.message || "Unexpected error");
      }

      setLoading(false);
    }

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading</div>;
  }

  if (error) {
    return <div style={{ color: "red" }}>ERROR: {error}</div>;
  }

  return (
    <div className="app-wrapper">
      <div className="row">
        <CardBox styleName="col-12" cardStyle="p-0">
          <MUIDataTable
            title={"Pacientes"}
            data={data}
            columns={columns}
            options={options}
          />
        </CardBox>
      </div>
    </div>
  );
};

export default PatientList2;
// const Home = (props) => {
//   return (
//     <div className="app-wrapper">
//       <div className="dashboard animated slideInUpTiny animation-duration-3">
//         <ContainerHeader
//           match={props.match}
//           title={<IntlMessages id="psp.home.title" />}
//         />

//         <div className="row">
//           <div className="col-lg-4 col-sm-6 col-12">
//             <ReportBox
//               styleName="bg-orange text-white p-3"
//               icon="accounts-alt"
//               price="3"
//               detail={<IntlMessages id="psp.home.newPatients" />}
//               subHeadingColor="text-white"
//             >
//               <YearlyProfitChart
//                 centerText="54"
//                 height={100}
//                 chartType="newsDoughnut"
//                 backgroundColor={["#607d8b", "#FFF"]}
//                 borderColor={["#607d8b", "#FFF"]}
//                 hoverBorderColor={["#607d8b", "#FFF"]}
//                 hoverBorderWidth={[2, 2]}
//                 textColor="#fff"
//                 rotation={50}
//               />
//             </ReportBox>
//           </div>

//           <div className="col-lg-4 col-sm-6 col-12">
//             <ReportBox
//               styleName="bg-pink text-white p-3"
//               price="25"
//               icon="hourglass-alt"
//               detail={<IntlMessages id="psp.home.inProgressPatients" />}
//               subHeadingColor="text-white"
//             >
//               <ResponsiveContainer width="100%" height={100}>
//                 <BarChart
//                   data={chartData}
//                   maxBarSize={4}
//                   margin={{ left: 0, right: 0, top: 10, bottom: 10 }}
//                 >
//                   <Bar dataKey="amt" fill="white" />
//                 </BarChart>
//               </ResponsiveContainer>
//             </ReportBox>
//           </div>

//           <div className="col-lg-4 col-sm-6 col-12">
//             <ReportBox
//               styleName="bg-primary text-white p-3"
//               icon="folder"
//               price="26"
//               detail={<IntlMessages id="psp.home.treatmentPatients" />}
//               subHeadingColor="text-white"
//             >
//               <CustomLineChart
//                 chartData={newArticlesData.chartData}
//                 labels={newArticlesData.labels}
//                 borderColor="#FFF"
//                 borderWidth={3}
//                 pointBackgroundColor="#FFF"
//                 pointBorderWidth={2}
//                 pointRadius={0}
//                 height={100}
//                 pointHoverBorderColor="#F29100"
//                 lineTension={0}
//                 gridLinesDisplay={true}
//                 gridLineWidth={3}
//                 shadowColor="rgba(0,0,0,0.6)"
//               />
//             </ReportBox>
//           </div>
//         </div>

//         <div className="row">
//           <div className="col-xl-4 col-lg-5 col-12">
//             <div className="jr-card jr-full-card">
//               <CardHeader
//                 heading={<IntlMessages id="psp.home.doctorTableTitle" />}
//               />

//               <div className="jr-card-body">
//                 <UserDetailTable data={doctors} />
//               </div>
//             </div>
//           </div>
//           <div className="col-xl-4 col-lg-5 col-12">
//             <div className="jr-card jr-full-card">
//               <CardHeader
//                 heading={<IntlMessages id="psp.home.patienInProgressTitle" />}
//               />

//               <div className="jr-card-body">
//                 <UserDetailTable data={patientsInProgress} />
//               </div>
//             </div>
//           </div>
//           <div className="col-xl-4 col-lg-5 col-12">
//             <div className="jr-card jr-full-card">
//               <CardHeader
//                 heading={<IntlMessages id="psp.home.patienTreatmentTitle" />}
//               />

//               <div className="jr-card-body">
//                 <UserDetailTable data={patientsInTreatment} />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
