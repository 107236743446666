import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormCheckbox from "components/Form/Controls/checkbox";
import TableComments from "./TableComments";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { updateSelectedPrescription } from "actions/Management";
import { isOnlyTracing, isJaasModule } from "app/routes/psp/services/userService";
import {
  callApi,
  callApiAsync,
  getCommentsByClinicalFinding,
  postAddCommentToClinicalFinding,
  getPatientStateList
} from "api/apiList";
import { 
  ClinicalFindingStateList,
  ClinicalFindingStateEnum,
  PatientStateList,
  PatientStateEnum,
  MedicalSuspensionReasonList,
  ProductBehavior
} from "constants/Enums";

const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const FormDetail = ({ rowObj, impactId, openCRU, reloadData, readOnly, selectedPrescription }) => {
  const dispatch = useDispatch();
  const [commentList, setCommentList] = useState([]);
  const [currentComment, setCurrentComment] = useState(null);
  const [reloadWhenClosing, setReloadWhenClosing] = useState(false);
  const [patientStates, setPatientStates] = useState([]);

  const clinicalFindingStateList = ClinicalFindingStateList();

  const isJaasModuleConfig = isJaasModule();
  const isOnlyTrace = isOnlyTracing();
  let patientStateList = [];

  useEffect(() => {
    callApi(
      getPatientStateList,
      (data)=>{
        setPatientStates(data.states);
      }
    );
  }, []);

  if(isJaasModuleConfig){
    patientStateList = patientStates;
  }else {
    patientStateList = PatientStateList().filter(x=>
      x.id === PatientStateEnum.medicalSuspension || x.id === PatientStateEnum.death
      || x.id === PatientStateEnum.active || x.id === PatientStateEnum.suspension
      || x.id === PatientStateEnum.voluntarySuspension || x.id === PatientStateEnum.dropout || x.id === PatientStateEnum.formulated
      || x.id === PatientStateEnum.process
    );

    if(!isOnlyTrace)
      patientStateList = patientStateList.filter(x=>x.id !== PatientStateEnum.formulated);
  }

  let medicalSuspensionReasonList = MedicalSuspensionReasonList();
  
  const defaultValues = {
    comment: "",
    state: null,
    endDate: null,
    newComment: "",
    contactDoctor: false,
    patientState: null,
    medicalSuspensionReason : null
  };

  const validationSchema = {
    state: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    newComment: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    endDate: yup.date()
      .nullable(true)
      .when("state", {
        is: (state) => state && Number(state.value) === ClinicalFindingStateEnum.closed,
        then: yup.date()
          .nullable(true)
          .min( !rowObj ? dayjs() : dayjs(rowObj.startDate), IntlGetText("clinicalFindingState.DetailEndDateInvalid"))
          .typeError(IntlGetText("yup.InvalidDate"))
          .required(<IntlMessages id="core.fieldRequired" />)
      }),
    patientState: yup.object()
      .when("contactDoctor", {
        is: (contactDoctor) => selectedPrescription.patient.state === PatientStateEnum.active,
        then: yup.object()
          .shape({ value: yup.string().required() })
          .required(<IntlMessages id="core.fieldRequired" />)
          .nullable()
      })      
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, reset, setValue, errors } = methods;

  const contactDoctorValue = watch("contactDoctor");

  const setEditComment = (objComment) => {
    setCurrentComment(objComment);
    setValue("newComment", !objComment ? "" : objComment.comment);
    setValue("contactDoctor", !objComment ? false : objComment.contactDoctor);
  };

  const loadComments = (id) => {
    if (!id) return;
    callApi(() => getCommentsByClinicalFinding(id), setCommentList);
  };

  const loadData = () => {
    setValue("comment", !rowObj || !rowObj.comment ? "" : rowObj.comment);
    setValue("endDate", !rowObj || !rowObj.endDate ? null : dayjs(rowObj.endDate));
    setValue("state", !rowObj ? null : getCboValue(clinicalFindingStateList, rowObj.state));
    setValue("patientState", !selectedPrescription ? null : getCboValue(patientStateList, selectedPrescription.patient.state));
  };
  const medicalSuspensionReasonValue = watch("medicalSuspensionReason");
  useEffect(() => {
    if (openCRU) {
      setTimeout(loadData, 150);
    }
    if(!openCRU && reloadWhenClosing){
      reloadData();
      setReloadWhenClosing(false);
    }
    if(openCRU && !medicalSuspensionReasonValue){
      setTimeout(()=>
        setValue("medicalSuspensionReason", !selectedPrescription ? null : getCboValue(medicalSuspensionReasonList, selectedPrescription.patient.medicalSuspensionReason)),
        150
      );
    }
  }, [openCRU]);

  useEffect(() => loadComments(!rowObj ? null : rowObj.id), [rowObj]);

  const onSubmit = async (d, e) => {
    var obj = {
      id: currentComment ? currentComment.id : 0,
      impactId: impactId,
      clinicalFindingId: rowObj.id,
      comment: d.newComment,
      contactDoctor: d.contactDoctor,

      state: Number(d.state.value),
      endDate: !d.endDate ? null : dayjs(d.endDate).format("YYYY-MM-DDTHH:mm:ss"),
      patientState: !d.patientState ? null : Number(d.patientState.value)
    };
    await callApiAsync(
      () => postAddCommentToClinicalFinding(obj),
      () => {
        loadComments(rowObj.id);
        reset({ ...d, newComment: "", contactDoctor: false });
        setCurrentComment(null);
        if(obj.patientState === PatientStateEnum.medicalSuspension || obj.patientState === PatientStateEnum.death
          || obj.patientState === PatientStateEnum.suspension || obj.patientState === PatientStateEnum.voluntarySuspension 
          || obj.patientState === PatientStateEnum.dropout || obj.patientState === PatientStateEnum.formulated 
          || obj.patientState === PatientStateEnum.process){
          const prescription = {
            ...selectedPrescription,
            patient:{
              ...selectedPrescription.patient,
              state: obj.patientState
            }
          }
          dispatch(updateSelectedPrescription(prescription));
        }
        setReloadWhenClosing(true);
      }
    );
  };

  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);

  const disabled = (rowObj && rowObj.state === ClinicalFindingStateEnum.closed) || readOnly;

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
      <FormProvider {...methods}>
        <form className="row" noValidate autoComplete="off">
          <Grid container spacing={2}>  
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="comment"
                label={<IntlMessages id="core.description" />}
                disabled={true}
                multiline
                rows={2}
                variant="outlined"
                margin="normal"
                errorobj={errors}
              />
            </Grid>            
            <Grid item xs={12} md={12} lg={12}>
              <FormSelectAutoComplete
                name="patientState"
                label={<IntlMessages id="core.patientState" />}
                options={patientStateList}
                disabled={ (isOnlyTrace || isJaasModuleConfig) ? false : (disabled || selectedPrescription.patient.state !== PatientStateEnum.active) }
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            { !isJaasModuleConfig &&  (watch("patientState") && (watch("patientState").value === PatientStateEnum.medicalSuspension)) && (
                  <Grid item xs={12} md={12} lg={12}>
                    <FormSelectAutoComplete
                      name="medicalSuspensionReason"
                      label={<IntlMessages id="core.medicalSuspensionReason" />}
                      options={medicalSuspensionReasonList}
                      margin="normal"
                      disabled={ disabled || selectedPrescription.patient.state !== PatientStateEnum.active }
                      required={watch("patientState") && (watch("patientState").value === PatientStateEnum.medicalSuspension)}
                      errorobj={errors}
                    />
                  </Grid>
                )}
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="state"
                label={<IntlMessages id="core.state" />}
                options={clinicalFindingStateList}
                margin="normal"
                disabled={disabled}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormDatePicker
                name="endDate"
                label={<IntlMessages id="clinicalFinding.endDate" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={disabled}
                errorobj={errors}
              />
            </Grid>
            { !disabled &&
              <Grid item xs={12} md={12} lg={12}>
                <FormInput
                  label={<IntlMessages id="core.comment" />}
                  name="newComment"
                  required={true}
                  multiline
                  rows={3}
                  variant="outlined"
                  margin="normal"
                  errorobj={errors}
                />
              </Grid>
            }
            { !disabled &&
              <Grid item xs={12} md={12} lg={12}>
                <FormCheckbox
                  name="contactDoctor"
                  label={<IntlMessages id="clinicalFindingState.contactDoctor" />}
                  value={watch("contactDoctor")}
                  onChange={chkHandleChange("contactDoctor")}
                />
              </Grid>
            }
          </Grid>
        </form>
      </FormProvider>
      { !disabled &&
        <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-3">
          <ButtonAsync onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
          >
            {<IntlMessages id="core.save" />}
          </ButtonAsync>
          { currentComment &&
            <Button
              onClick={()=>setEditComment(null)}
              variant="contained"
              className="jr-btn ml-2"
            >
              <i className="zmdi zmdi-undo"></i>
            </Button>
          }
        </div>
      }
      <TableComments
        data={commentList}
        setCurrentRowObj={setEditComment}
        currentImpact={impactId}
        readOnly={disabled}
      />
    </div>
  );
};

export default FormDetail;
