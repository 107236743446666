import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { Link } from "react-router-dom";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button, Tooltip, Grid } from "@material-ui/core";
import CardBox from "components/CardBox/index";
import {
  callApi,
  getPatientList,
  postActivatePatientFromDropOut,
} from "api/apiList";
import { PatientStateList, RoleEnum, PatientStateEnum } from "constants/Enums";
import { setLoader } from "actions/Base";
import { DialogConfirmAsync } from "../../../../components/Form/Controls/DialogConfirm";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import DialogAsync from "components/Form/DialogAsync";
import PatientsTable from "./patientTable";
import PatientListTable from "components/JaasModule/PatientList";

import { isRole, isJaasModule } from "../services/userService";

const Table = ({ data, loadData }) => {
  const patientStateList = PatientStateList();
  const [openDlg, setOpenDlg] = useState(false);
  const [openDlgState, setOpenDlgState] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [newState, setNewState] = useState(null);
  const [dlgConfirmInfo, setDlgConfirmInfo] = useState({title: '', text:''});
  const isConsultant = isRole(RoleEnum.nursemanagerconsultant);
  const isNurse = isRole(RoleEnum.nurse);

  let msgActivateText = IntlGetText("patientPA.activatePatientMsg");
  const msgActivateTitle =  IntlGetText("patient.activatePatient");
  const msgChangeStateTitle =  IntlGetText("core.changeState");
  const msgChangeStateConfirm =  IntlGetText("core.changeStateConfirmation");

  const validationSchema = {
    state: yup
        .object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable()
  };
  const methods = useForm({
    defaultValues: {state: null},
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, errors } = methods;

  const getSateList=(row)=>{
    if(!row){
      setValue('state',null);
      return [];
    }
    
    let stateList = patientStateList.filter(x => x.id == PatientStateEnum.death);
    if(row.state === PatientStateEnum.dropout)
      stateList = [...stateList, patientStateList.find(x => x.id == PatientStateEnum.active)];      

    return stateList;
  };

  const onSubmitState = handleSubmit(async (d) => {
    const state = Number(d.state.value);

    let stateItem = patientStateList.find(x => x.id == state);
    let dlgInfo = {
      title:msgChangeStateTitle,
      text:msgChangeStateConfirm.replace("{0}", stateItem && stateItem.name)
        .replace("{1}", currentRow && currentRow.fullName)
    };
    if(state === PatientStateEnum.active){
      dlgInfo = {
        title: msgActivateTitle, 
        text: msgActivateText.replace("[0]", currentRow && currentRow.fullName)
      }
    }
    setDlgConfirmInfo(dlgInfo);

    setNewState(state);
    setOpenDlgState(false);
    setOpenDlg(true);
  });

  const FnActionsColRender = (rowObj)=>{
    if (isConsultant) {
      return;
    }
    return (
      <div>
        <Tooltip title={<IntlMessages id="core.seeDetail" />} placement="bottom-end">
          <Button
            component={Link}
            to={{
              pathname: "/app/patient/detail",
              prescriptionId: rowObj.prescriptionId,
            }}
            color="primary"
            className="jr-btn"
          >
            <i className="zmdi zmdi-eye"></i>
          </Button>
        </Tooltip>
        {((isNurse && rowObj.state !== PatientStateEnum.death) || rowObj.state == PatientStateEnum.dropout) && (
          <Tooltip title={<IntlMessages id="core.changeState" />} placement="bottom-end">
            <Button
              onClick={() => {
                setOpenDlgState(true);
                setCurrentRow(rowObj);
              }}
              color="primary"
              className="jr-btn"
            >
              <i className="zmdi zmdi-edit"></i>
            </Button>
          </Tooltip>
        )}
      </div>
    );
  };
  return (
    <>
      <PatientsTable
        data={data}
        patientStateList={patientStateList}
        FnActionsColRender={FnActionsColRender}
        />
      <DialogConfirmAsync
        openDialog={openDlg}
        title={dlgConfirmInfo.title}
        text={dlgConfirmInfo.text}
        handleRequestClose={() => setOpenDlg(false)}
        onAcceptClickAsync={async () => {
          await postActivatePatientFromDropOut({
            prescriptionId: currentRow.prescriptionId,
            state: newState
          });
          loadData();
          setOpenDlg(false);
        }}
      />
      <DialogAsync
        open={openDlgState}
        closeDialog={() => setOpenDlgState(false)}
        onSubmitAsync={onSubmitState}
        titleTextId={"core.changeState"}
        allowEdit={true}
      >
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <FormSelectAutoComplete
                  name="state"
                  label={<IntlMessages id="core.state" />}
                  options={getSateList(currentRow)}
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogAsync>
    </>
  );
};

const PatientList = (props) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [data, setData] = useState([]);

  const isJaasModuleConfig = isJaasModule();

  const loadData = () => {
    callApi(getPatientList, setData, fnSetLoader);
  };

  useEffect(() => loadData(), []);

  return (
    <div className="app-wrapper">
      <div className="row">
        <CardBox styleName="col-12" cardStyle="p-0">
          <>
          {!isJaasModuleConfig && <Table data={data} loadData={loadData} />}
          {isJaasModuleConfig && <PatientListTable data={data} loadData={loadData} />}
          </>
        </CardBox>
      </div>
    </div>
  );
};

export default PatientList;
