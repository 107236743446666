import React from "react";
import { IntlGetText } from "util/IntlMessages";
import MUIDataTable from "mui-datatables";
import * as dayjs from "dayjs";

export const dateColumn = (name, textId, format = "YYYY/MM/DD", filter=false) => (  
  {
    name: name,
    label: IntlGetText(textId),
    options: {
      filter: filter,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return value ? dayjs(value).format(format) : "";
      },
    },
  }
);

export const defaultCol = (name, textId) => (  
  {
    name: name,
    label: IntlGetText(textId),
    options: {
      filter: true,
      sort: true,
    },
  }
);
export const numberCol = (name, textId, locale, fnFormat) => (  
  {
    name: name,
    label: IntlGetText(textId),
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return value ? fnFormat(locale,value) : "";
      },
    },
  }
);

export const defaultOptions = () => ({
  filterType: "dropdown",
  download: false,
  print: false,
  selectableRows: "none",
  textLabels: getTextLabels(),
});
export const minimalOptions = () => ({
  filter: false,
  download: false,
  print: false,
  selectableRows: "none",
  search: false,
  viewColumns: false,
  textLabels: getTextLabels(),
  pagination: false,
});

export const getTextLabels = () => {
  return {
    body: {
      noMatch: IntlGetText("core.datatable.body.noMatch"),
      toolTip: IntlGetText("core.datatable.body.toolTip"),
      columnHeaderTooltip: (column) => IntlGetText("core.datatable.body.columnHeaderTooltip") + `${column.label}`,
    },
    pagination: {
        next: IntlGetText("core.datatable.pagination.next"),
        previous: IntlGetText("core.datatable.pagination.previous"),
        rowsPerPage: IntlGetText("core.datatable.pagination.rowsPerPage"),
        displayRows: IntlGetText("core.datatable.pagination.displayRows"),
    },
    toolbar: {
        search: IntlGetText("core.datatable.toolbar.search"),
        downloadCsv: IntlGetText("core.datatable.toolbar.downloadCsv"),
        print: IntlGetText("core.datatable.toolbar.print"),
        viewColumns: IntlGetText("core.datatable.toolbar.viewColumns"),
        filterTable: IntlGetText("core.datatable.toolbar.filterTable"),
    },
    filter: {
        all: IntlGetText("core.datatable.filter.all"),
        title: IntlGetText("core.datatable.filter.title"),
        reset: IntlGetText("core.datatable.filter.reset"),
    },
    viewColumns: {
        title: IntlGetText("core.datatable.viewColumns.title"),
        titleAria: IntlGetText("core.datatable.viewColumns.titleAria"),
    },
    selectedRows: {
        text: IntlGetText("core.datatable.selectedRows.text"),
        delete: IntlGetText("core.datatable.selectedRows.delete"),
        deleteAria: IntlGetText("core.datatable.selectedRows.deleteAria"),
    }
  };
};

const Table = (props) =>{
  return (
    <MUIDataTable {...props}/>
  );
};

export default Table;