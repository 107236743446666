import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as dayjs from "dayjs";
import {
  setSelectedPrescription,
  setSelectedMenuItem,
} from "actions/Management";
import { FolderType, AdverseEventStateList } from "constants/Enums";

const TableBoxRow = ({data, folderType }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { productLines, prescriptionStates } = useSelector(({ home }) => home);
  
  const adverseEventStateList = AdverseEventStateList();

  const {
    id,
    doctor,
    patient,
    creationDate,
    firstImpactDate,
    productLineId,
    stateId,
  } = data;

  const date = firstImpactDate ? firstImpactDate : creationDate;
  const productLine = productLines.find((x) => x.id === productLineId);
  const prescriptionState = folderType === FolderType.tracing
    ? prescriptionStates.find((x) => x.type === folderType && x.id === data.impactTypeId)
    : prescriptionStates.find((x) => x.type === folderType && x.id === stateId);
  let adverseEventState;
  if(folderType === FolderType.adverseEvents && data.adverseEvent){
    adverseEventState = adverseEventStateList.find(x=>x.id === data.adverseEvent.state);
  }

  const handleClick = (prescription) => {
    if ((folderType === FolderType.inProcess || folderType === FolderType.tracing) && !prescription.impactId) {
      return;
    }

    dispatch(setSelectedMenuItem(folderType));
    dispatch(setSelectedPrescription(prescription));
    history.push("/app/management");
  };

  return (
    <tr tabIndex={-1} 
      style={{cursor: "pointer"}}
      onClick={() => {
        handleClick(data);
      }}
    >
      <td className="border-bottom border-top-0">
        <div className="user-profile d-flex flex-row align-items-center">
          <div className="user-detail">
            {folderType !== FolderType.trainingsAndEvents &&
              <>
                <h5 className="user-name">{doctor.name}</h5>
                {patient && (
                  <p className="user-description">
                    {!patient.code ? "" : patient.code + " - "}
                    {patient.name} {patient.lastName}
                  </p>
                )}
                <p className="user-description">
                  {dayjs(date).format("YYYY/MM/DD")}
                </p>
              </>
            }
            {folderType === FolderType.trainingsAndEvents &&
              <>
                <h5 className="user-name">{data.eventTraining.name}</h5>
                <p className="user-description">
                  {dayjs(data.eventTraining.trainingDate).format("YYYY/MM/DD")}
                </p>
              </>
            }
          </div>
        </div>
      </td>
      <td className="text-right border-bottom border-top-0">
        {productLine && (
          <span
            className={`jr-link badge text-white text-uppercase bg-${productLine.color}`}
          >
            {productLine.title}
          </span>
        )}
      </td>
      <td className="text-left border-bottom border-top-0">
        {prescriptionState && (
          <span
            className={`jr-link badge text-white text-uppercase bg-${prescriptionState.color}`}
          >
            {prescriptionState.title}
          </span>
        )}
        {adverseEventState && (
          <span
            className={`jr-link badge text-white text-uppercase bg-${adverseEventState.color}`}
          >
            {adverseEventState.name}
          </span>
        )}
      </td>
    </tr>
  );
};

export default TableBoxRow;
