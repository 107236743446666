import React, { useState ,useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button, Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions 
} from "@material-ui/core";
import FormDateTimePicker from "components/Form/Controls/dateTimePicker";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
    callApi,
    getImpactTypeList
} from "api/apiList";
import { isRole } from "app/routes/psp/services/userService";
import {  FolderType, RoleEnum } from "constants/Enums";


const DialogPlanningImpact = ({open, handleRequestClose, prescriptionList, callPlanImpact }) => {  
    const [impactTypeList, setImpactTypeList] = useState([]);

    const defaultValues = {
        prescriptionId: "",
        impactTypeId: "",
        planningDate: null
    };
    const validationSchema = {
        prescriptionId: yup
            .object()
            .shape({ value: yup.string().required() })
            .required(<IntlMessages id="core.fieldRequired" />)
            .nullable(),
        impactTypeId: yup
            .object()
            .shape({ value: yup.string().required() })
            .required(<IntlMessages id="core.fieldRequired" />)
            .nullable(),
        planningDate: yup
            .date()
            .nullable(true)
            .typeError(IntlGetText("yup.InvalidDate"))
            .required(<IntlMessages id="core.fieldRequired" />)
    };

    const isNurseProcedure = isRole(RoleEnum.nurse_procedure);
    const methods = useForm({ defaultValues,  resolver: yupResolver(yup.object().shape(validationSchema)) });
    const { handleSubmit, setValue, errors } = methods;
    
    // const loadData = () => {
    //     setValue("impactTypeId", !prescription ? "" : getCboValue(impactTypeList, prescription.impactTypeId));
    //     setValue("planningDate", !prescription || !prescription.planningDate ? null : prescription.planningDate);
    // };

    useEffect(()=>{
        callApi(getImpactTypeList, 
            (data) => {
                if(isNurseProcedure){
                    data = data.filter( x => x.folderType == FolderType.inProcess);
                }
                setImpactTypeList(data)
            }
        );
    },[]);

    // useEffect(()=>{
    //     if(open){
    //         setTimeout(loadData,150);
    //     }
    // },[open]);

    const onSubmit = async (data, e) => {  
      const obj = {
        prescriptionId: Number(data.prescriptionId.value),
        planningDate: dayjs(data.planningDate).format("YYYY-MM-DDTHH:mm:ss"),
        impactTypeId: Number(data.impactTypeId.value)
      };
      await callPlanImpact(obj);
    };
    return (
        <Dialog open={open} fullWidth={true} maxWidth="xs" 
            disableBackdropClick={true} onClose={handleRequestClose} >
            <DialogTitle>{<IntlMessages id="impact.PlanningImpactTitle" />}</DialogTitle>
            <DialogContent className="new-patient-dialog-content">
                <FormProvider {...methods}>
                    <form className="row" noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormSelectAutoComplete
                                name="impactTypeId"
                                label={<IntlMessages id="impact.ImpactType" />}
                                options={impactTypeList}
                                margin="normal"
                                required={true}
                                errorobj={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormSelectAutoComplete
                                name="prescriptionId"
                                label={<IntlMessages id="core.patient" />}
                                options={prescriptionList}
                                margin="normal"
                                required={true}
                                errorobj={errors}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormDateTimePicker
                                    name="planningDate"
                                    label={IntlGetText("core.dateAndTime")}  
                                    format="YYYY-MM-DD hh:mm A"  
                                    disablePast={true}
                                    required={true} 
                                    errorobj={errors}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRequestClose} color="secondary">
                    {<IntlMessages id="core.cancel" />}
                </Button>
                <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
                    {<IntlMessages id="core.save" />}
                </ButtonAsync>
            </DialogActions>
        </Dialog>
    );
  };

  export default DialogPlanningImpact;