// Customizer const
export const TOGGLE_COLLAPSED_NAV = "toggle_collapse_menu";
export const DRAWER_TYPE = "drawer_type";
export const FIXED_DRAWER = "fixed_drawer";
export const COLLAPSED_DRAWER = "collapsible";
export const MINI_DRAWER = "mini_drawer";
export const WINDOW_WIDTH = "window-width";
export const SWITCH_LANGUAGE = "switch-language";

export const HORIZONTAL_NAVIGATION = "horizontal_navigation";

export const HORIZONTAL_MENU_POSITION = "horizontal_menu_position";
export const INSIDE_THE_HEADER = "inside_the_header";


export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";


export const ON_TOGGLE_DRAWER = "on_toggle_drawer";
export const HANDLE_REQUEST_CLOSE = "handle_request_close";
export const HIDE_CONTACT_LOADER = "hide_contact_loader";

//Auth const
export const SIGNIN_USER = "signin_user";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNOUT_USER = "signout_user";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";
export const INIT_URL = "init_url";
export const SIGNIN_RELOGIN = "signin_relogin";


export const ON_SHOW_LOADER = "on_show_loader";
export const ON_HIDE_LOADER = "on_hide_loader";


//// Mail Module const

export const ADD_LABEL = "ADD_LABEL";
export const GET_ALL_MAIL = "get_all_mail";
export const FETCH_ALL_MAIL = "fetch_all_mail";
export const FETCH_ALL_MAIL_SUCCESS = "fetch_all_mail_success";
export const GET_IMPORTANT_MAIL = "get_important_mail";
export const GET_NAV_FILTERS = "get_nav_filters";
export const GET_NAV_LABELS = "get_nav_labels";
export const GET_NAV_FOLDER = "get_nav_folder";
export const GET_READ_MAIL = "get_read_mail";
export const GET_STARRED_MAIL = "get_starred_mail";
export const GET_UNIMPORTANT_MAIL = "get_unimportant_mail";
export const GET_UNREAD_MAIL = "get_unread_mail";
export const GET_UNSELECTED_ALL_MAIL = "get_unselected_all_mail";
export const GET_UNSTARRED_MAIL = "get_unstarred_mail";
export const ON_ALL_MAIL_SELECT = "on_all_mail_select";
export const ON_FOLDER_MENU_ITEM_SELECT = "on_folder_menu_item_select";
export const ON_FOLDER_SELECT = "on_folder_select";
export const ON_IMPORTANT_SELECT = "on_important_select";
export const ON_LABEL_MENU_ITEM_SELECT = "on_label_menu_item_select";
export const GET_MARK_AS_START = "get_mark_as_start";
export const ON_LABEL_SELECT = "on_label_select";
export const ON_MAIL_CHECKED = "on_mail_checked";
export const ON_MAIL_SELECT = "on_mail_select";
export const ON_MAIL_SEND = "on_mail_send";
export const ON_OPTION_MENU_ITEM_SELECT = "on_option_menu_item_select";
export const ON_OPTION_MENU_SELECT = "on_option_menu_select";
export const ON_START_SELECT = "on_start_select";
export const SEARCH_MAIL = "search_mail";
export const ON_DELETE_MAIL = "on_delete_mail";
export const SET_CURRENT_MAIL_NULL = "set_current_mail_null";
export const ON_COMPOSE_MAIL = "on_compose_mail";

//// PHARMA

export const PHARMA_ADD_LABEL = "pharma_ADD_LABEL";
export const PHARMA_GET_ALL_MAIL = "pharma_get_all_mail";
export const PHARMA_FETCH_ALL_MAIL = "pharma_fetch_all_mail";
export const PHARMA_FETCH_ALL_MAIL_SUCCESS = "pharma_fetch_all_mail_success";
export const PHARMA_GET_IMPORTANT_MAIL = "pharma_get_important_mail";
export const PHARMA_GET_NAV_FILTERS = "pharma_get_nav_filters";
export const PHARMA_GET_NAV_LABELS = "pharma_get_nav_labels";
export const PHARMA_GET_NAV_FOLDER = "pharma_get_nav_folder";
export const PHARMA_GET_READ_MAIL = "pharma_get_read_mail";
export const PHARMA_GET_STARRED_MAIL = "pharma_get_starred_mail";
export const PHARMA_GET_UNIMPORTANT_MAIL = "pharma_get_unimportant_mail";
export const PHARMA_GET_UNREAD_MAIL = "pharma_get_unread_mail";
export const PHARMA_GET_UNSELECTED_ALL_MAIL = "pharma_get_unselected_all_mail";
export const PHARMA_GET_UNSTARRED_MAIL = "pharma_get_unstarred_mail";
export const PHARMA_ON_ALL_MAIL_SELECT = "pharma_on_all_mail_select";
export const PHARMA_ON_FOLDER_MENU_ITEM_SELECT =
  "pharma_on_folder_menu_item_select";
export const PHARMA_ON_FOLDER_SELECT = "pharma_on_folder_select";
export const PHARMA_ON_IMPORTANT_SELECT = "pharma_on_important_select";
export const PHARMA_ON_LABEL_MENU_ITEM_SELECT =
  "pharma_on_label_menu_item_select";
export const PHARMA_GET_MARK_AS_START = "pharma_get_mark_as_start";
export const PHARMA_ON_LABEL_SELECT = "pharma_on_label_select";
export const PHARMA_ON_MAIL_CHECKED = "pharma_on_mail_checked";
export const PHARMA_ON_MAIL_SELECT = "pharma_on_mail_select";
export const PHARMA_ON_MAIL_SEND = "pharma_on_mail_send";
export const PHARMA_ON_OPTION_MENU_ITEM_SELECT =
  "pharma_on_option_menu_item_select";
export const PHARMA_ON_OPTION_MENU_SELECT = "pharma_on_option_menu_select";
export const PHARMA_ON_START_SELECT = "pharma_on_start_select";
export const PHARMA_SEARCH_MAIL = "pharma_search_mail";
export const PHARMA_ON_DELETE_MAIL = "pharma_on_delete_mail";
export const PHARMA_SET_CURRENT_MAIL_NULL = "pharma_set_current_mail_null";
export const PHARMA_ON_COMPOSE_MAIL = "pharma_on_compose_mail";
export const PHARMA_PATIENT_CONTACT_SAVE = "pharma_on_patient_save";
export const PHARMA_PROCESS_STATE_CHANGE = "pharma_on_patient_state";
export const PHARMA_HANDLE_REQUEST_CLOSE = "pharma_handle_request_close";

export const UPDATE_SEARCH = "update_search";

// Manage PSP

export const PROCESS_STATE_CHANGE = "process_state_change";
export const PATIENT_CONTACT_SAVE = "patient_contact_save";

//Management

export const CURRENT_MENU_ITEM_ID = "current_menu_item_id";
export const MANAGE_SEARCH_PRESCRIPTIONS = "manage_search_prescriptions";
export const LOAD_MANAGE_MODULE = "load_manage_module";
export const SELECT_PRODUCT_LINE = "select_product_line";
export const SELECT_PRESCRIPTION_STATE = "select_prescription_state";
export const SET_PRESCRIPTION_STATE = "set_prescription_state";
export const LOAD_PRESCRIPTIONS = "load_prescriptions";
export const SELECT_PRESCRIPTION = "select_prescription";
export const SET_LOADER_MANAGE_MODULE = "set_loader_manage_module";
export const SHOW_ALERT_MESSAGE = "show_alert_message";
export const LOAD_PATIENT_INFO = "load_patient_info";
export const UPDATE_SELECTED_PRESCRIPTION = "update_selected_prescription";
export const REMOVE_SELECTED_PRESCRIPTION = "remove_selected_prescription";
export const ADD_PRESCRIPTION_GO_TRACING = "add_prescription_go_tracing";

//
export const SET_PRESCRIPTION_ID = "set_prescription_id";

// Base

export const SET_LOADER = "set_loader";

//Home
export const LOAD_NEW_PATIENTS = "load_new_patients";
export const LOAD_HOME = "load_home";

//Impact

export const SET_CONSENT_PATH = "set_conset_path";
export const SET_CONSENT_URL = "set_conset_url";
export const LOAD_INFO_IMPACT = "load_info_impact";
export const SET_IMPACT_START_DATE = "set_impact_start_date";
export const SET_CLINICAL_HISTORY_STATE = "set_clinical_history_state";
export const SET_FORM_FORMULA_DONE = "set_form_formula_done";
export const SET_FORMULA_MEDICINE_QUANTITY = "set_formula_medicine_quantity";
export const ON_START_IMPACT = "on_start_impact";
export const CALL_RELOAD_ALERTS = "call_reload_alerts";
export const CALL_RELOAD_PATIENT_DATA = "call_reload_patient_data";
export const CALL_RELOAD_CONSUMPTION_TRACING = "call_reload_consumtion_tracing";
export const SET_HISTORY_DATA = "set_history_data"   /* ================ */
export const SET_HEALTH_DATA = "set_health_data"   /* ================ */

//Institution context 
export const INSTITUTION_SET_CI_INFO = "institution_set_ci_info";
//End Institution context