import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setLoader } from "actions/Base";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import { callApi, getImpactReport, getImpactReportFile } from "api/apiList";
import { downloadFile } from "services/fileService";
import Table, { defaultOptions, defaultCol } from "components/Form/Table";

const ImpactReport = ({ dateRange }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [reportData, setReportData] = useState([]);

  const downloadReport = () => {
    callApi(
      () => getImpactReportFile(dateRange.startDate, dateRange.endDate),
      (data) => downloadFile(data),
      fnSetLoader
    );
  };

  useEffect(() => {
    if (!dateRange) return;

    callApi(
      () => getImpactReport(dateRange.startDate, dateRange.endDate),
      (data) => setReportData(data),
      fnSetLoader
    );
  }, [dateRange]);

  const getObj = (tableMeta) =>
    reportData.find((x) => x.impactId === tableMeta.rowData[0]);

  const columns = [
    {
      name: "impactId",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("impactType", "core.activity"),
    defaultCol("year", "core.year"),
    defaultCol("month", "core.timeFrame"),
    defaultCol("fortnight", "core.q"),
    {
      name: "startDate",
      label: IntlGetText("core.startDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD hh:mm a") : "";
        },
      },
    },
    {
      name: "endDate",
      label: IntlGetText("core.endDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD hh:mm a") : "";
        },
      },
    },
    defaultCol("productline", "core.line"),
    defaultCol("nurse", "role.nurse"),
    defaultCol("city", "core.city"),
    {
      name: "patientCode",
      label: IntlGetText("core.code"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return (
            <Link
              to={{
                pathname: "/app/patient/detail",
                prescriptionId: rowObj.prescriptionId,
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    defaultCol("nursingNote", "impact.NursingNote"),
  ];
  return (
    <>
      <div className="col-lg-12 col-md-12 col-xs-12 text-left mt-2 mb-4">
        <Button
          onClick={downloadReport}
          variant="contained"
          color="secondary"
          disabled={dateRange === null}
        >
          <IntlMessages id="core.download" />
        </Button>
      </div>

      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-5 mb-5">
        <Table
          className="border-dialog-card mt-2 mb-2"
          data={reportData}
          columns={columns}
          options={defaultOptions()}
        />
      </div>
    </>
  );
};

export default ImpactReport;
