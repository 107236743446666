import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import IntlMessages from "util/IntlMessages";
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget";
import { Tabs, Tab, Button } from "@material-ui/core";
import PatientInfo from "./PatientInfo";
import HousingInfo from "./HousingInfo";
import TutorInfo from "./TutorInfo";
import ClinicHistory from "./ClinicHistory";
import PatientFollowUp from "./PatientFollowUp";
import {
  callActionApi,
  callApi,
  getAllStateProvince,
  getAllDocumentType,
  getPatientInfo,
  getInsuranceList,
  getPatientStateList,
} from "api/apiList";
import { PatientStateList } from "constants/Enums";
import { isJaasModule } from "app/routes/psp/services/userService";

const Container = ({ children, ...props }) => {
  const { patientName, consentPath } = props;

  return (
    <div className="app-wrapper">
      <Auxiliary>
        <div className="jr-profile-banner">
          <div
            className="jr-profile-container"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              className="jr-profile-banner-top"
              style={{ marginBottom: "0px" }}
            >
              <div className="jr-profile-banner-top-left">
                <div className="jr-profile-banner-avatar-info">
                  <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                    {patientName}
                  </h2>
                </div>
              </div>
            </div>
            {consentPath && (
              <a href={consentPath} download="filename">
                <Button className="mt-2" variant="contained" color="primary">
                  <i className="zmdi zmdi-download"></i>
                </Button>
              </a>
            )}
          </div>
        </div>
        <div className="jr-profile-content">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              {children}
            </div>
          </div>
        </div>
      </Auxiliary>
    </div>
  );
};

const PatientDetail = (props) => {
  const { prescriptionId } = props.location;
  let history = useHistory();

  const [currentTab, setCurrentTab] = useState(0);
  const [stateProvinceList, setStateProvinceList] = useState([]);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [insuranceList, setInsuranceList] = useState([]);

  const [patientData, setPatientData] = useState({});
  const [insuranceData, setInsuranceData] = useState({});
  const [tutorData, setTutorData] = useState({});
  const [housingData, setHousingData] = useState({});
  const [patientStateList, setPatientStateList] = useState([]);

  const isJaasModuleConfig = isJaasModule();
  const patientStateList2 = PatientStateList();

  useEffect(() => {
    if (isJaasModuleConfig) {
      callApi(getPatientStateList, (data) => {
        setPatientStateList(data.states);
      });
    } else {
      setPatientStateList(patientStateList2);
    }
  }, []);

  const getStateText = (data) => {
    var state = patientStateList.find((x) => x.id == data);
    return !state ? "" : state.name;
  };

  const callApi = (apiFunc, setData) => {
    callActionApi(
      apiFunc,
      setData,
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  };

  useEffect(() => {
    if (!prescriptionId) return;

    callApi(
      () => getPatientInfo(prescriptionId),
      (data) => {
        setPatientData(data.patient);
        setInsuranceData(!data.insuranceInfo ? {} : data.insuranceInfo);
        setHousingData(!data.housing ? {} : data.housing);
        setTutorData(!data.tutor ? {} : data.tutor);
      }
    );
    callApi(getAllStateProvince, setStateProvinceList);
    callApi(getAllDocumentType, setDocumentTypeList);
    callApi(getInsuranceList, setInsuranceList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, value) => setCurrentTab(value);

  if (!prescriptionId) {
    history.push("/app/patient");
    return <></>;
  }

  return (
    <Container
      patientName={
        !patientData.name
          ? ""
          : patientData.name +
            " " +
            patientData.lastName +
            " - " +
            getStateText(patientData.state)
      }
      consentPath={!patientData.consentPath ? "" : patientData.consentPath}
    >
      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
        <div className="card-header"></div>
        <div className="jr-tabs-classic">
          <Tabs
            className="jr-tabs-up"
            value={currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              className="jr-tabs-label"
              label={<IntlMessages id="patientData.data" />}
            />
            <Tab
              className="jr-tabs-label"
              label={<IntlMessages id="patientData.sociodemographicInfo" />}
            />
            <Tab
              className="jr-tabs-label"
              label={<IntlMessages id="patientData.tutor" />}
            />
            <Tab className="jr-tabs-label" label="Historia Clínica" />
            <Tab className="jr-tabs-label" label="Seguimiento" />
          </Tabs>
          <div className="jr-tabs-content jr-task-list">
            <div className="row">
              {currentTab === 0 && (
                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                  <div className="mt-2">
                    <PatientInfo
                      patientData={patientData}
                      insuranceData={insuranceData}
                      stateProvinceList={stateProvinceList}
                      documentTypeList={documentTypeList}
                      insuranceList={insuranceList}
                    />
                  </div>
                </div>
              )}
              {currentTab === 1 && (
                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                  <div className="mt-2">
                    <HousingInfo housingData={housingData} />
                  </div>
                </div>
              )}
              {currentTab === 2 && (
                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                  <div className="mt-2">
                    <TutorInfo
                      stateProvinceList={stateProvinceList}
                      documentTypeList={documentTypeList}
                      tutorData={tutorData}
                    />
                  </div>
                </div>
              )}
              {currentTab === 3 && (
                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                  <div className="mt-2">
                    <ClinicHistory
                      patientId={
                        patientData && patientData.id ? patientData.id : 0
                      }
                    />
                  </div>
                </div>
              )}
              {currentTab === 4 && (
                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                  <div className="mt-2">
                    <PatientFollowUp patient={patientData} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Widget>
    </Container>
  );
};

export default PatientDetail;
