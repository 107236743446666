import React from "react";
import { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import Table, { getTextLabels, defaultCol } from "components/Form/Table";
import { TrainingResourceTypeEnum } from "constants/Enums";

const onClick = (fileLink, type) => () => {
  const link = document.createElement("a");
  link.href = fileLink;
  link.setAttribute("target", type === TrainingResourceTypeEnum.video ? "_blank": "_self");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getData = (eventTrainingInfo) => {
  if(!eventTrainingInfo || !eventTrainingInfo.eventTrainingFile)
    return [];
  return eventTrainingInfo.eventTrainingFile;
}

const CustomTable = ({eventTrainingInfo,handleOpenCRU,
  handleOpenDel, setCurrentRowObj, readOnly}) => {
  const getObj = (tableMeta) => getData(eventTrainingInfo).find(x=>x.id === tableMeta.rowData[0]);

  const handleClick = (obj) => {
    handleOpenCRU();
    setCurrentRowObj(obj);
  };
  
  const handleClickDelete = (obj) => {
    handleOpenDel();
    setCurrentRowObj(obj);
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display:"excluded"
      },
    },
    defaultCol('name','core.name'),
    defaultCol('description','core.description'),
    {
      name: "url",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return (
            <>
              <Button
                onClick={onClick(rowObj.url, rowObj.type)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className={"zmdi zmdi-"+ (rowObj.type === TrainingResourceTypeEnum.video ? "link" : "download") }></i>
              </Button>
              {!readOnly &&
                <>
                  <Button
                    onClick={() => handleClick(rowObj)}
                    variant="contained"
                    className="jr-btn jr-btn-xs"
                  >
                    <i className="zmdi zmdi-edit"></i>
                  </Button>
                  <Button
                    onClick={() => handleClickDelete(rowObj)}
                    variant="contained"
                    className="jr-btn jr-btn-xs"
                  >
                    <i className="zmdi zmdi-delete"></i>
                  </Button>
                </>
              }
            </>
          )
        }
      },
    },
  ];

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: getTextLabels(),
  };

  return (
    <div>
      <Table
        className="border-dialog-card"
        data={getData(eventTrainingInfo)}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default CustomTable;
