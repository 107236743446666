import React from "react";
import { useForm } from "react-hook-form";

import Auxiliary from "../../../../util/Auxiliary";
import Widget from "components/Widget";
import TextField from "@material-ui/core/TextField";

const PatientAdd = () => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => console.log(data);

  return (
    <div className="app-wrapper">
      <Auxiliary>
        <div className="jr-profile-banner">
          <div className="jr-profile-container">
            <div
              className="jr-profile-banner-top"
              style={{ marginBottom: "0px" }}
            >
              <div className="jr-profile-banner-top-left">
                <div className="jr-profile-banner-avatar-info">
                  <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                    Crear Paciente
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="jr-profile-content">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
                <div className="card-header">Nuevo Paciente</div>
                <div className="jr-tabs-classic">
                  <div className="jr-tabs-content jr-task-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                        <form
                          className="row"
                          onSubmit={handleSubmit(onSubmit)}
                          autoComplete="off"
                        >
                          <div className="col-md-6 col-6">
                            <TextField
                              error={errors.example}
                              helperText={
                                errors.example && "example is required"
                              } //{errors.example}
                              id="idType"
                              label="Tipo de Documento"
                              margin="normal"
                              fullWidth
                              name="example"
                              //defaultValue="test"
                              inputRef={register({ required: true })}
                            />
                          </div>
                          <div className="col-md-6 col-6">
                            <TextField
                              inputRef={register({ required: true })}
                              error={errors.lastName}
                              helperText={
                                errors.lastName && "Last name is required"
                              }
                              label="Last Name"
                              name="lastName"
                              margin="normal"
                              fullWidth
                            />
                          </div>
                          <div className="col-md-6 col-6">
                            <TextField
                              inputRef={register({ required: true })}
                              error={errors.address}
                              helperText={
                                errors.address && "Address is required"
                              }
                              label="Address"
                              id="address"
                              name="address"
                              margin="normal"
                              fullWidth
                            />
                          </div>
                          <input type="submit" />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Widget>
            </div>
          </div>
        </div>
      </Auxiliary>
    </div>
  );
};

export default PatientAdd;
