import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { Grid } from "@material-ui/core";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import FormInput from "components/Form/Controls/input";
import DialogAsync from "components/Form/DialogAsync";
import FormDateTimePicker from "components/Form/Controls/dateTimePicker";
import {
  callApiAsync,
  postIntitutionStartImpact
} from "api/apiList";

const AssistanceForm = ({ openDlg, closeDialog, callBack, impactInfo, setImpactInfo, allowEdit = true }) => {
  const msgSuccess = IntlGetText("core.SuccessSave");

  const defaultValues = {
    startDateAndTime: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
    attendingNurse: "",
  };

  const validationSchema = {
    startDateAndTime: yup
      .string()
      .required(<IntlMessages id="core.fieldRequired" />),
    attendingNurse: yup
      .string()
      .required(<IntlMessages id="core.fieldRequired" />),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, errors } = methods;

  useEffect(()=>{
    if(!openDlg)
      return;
    
    setTimeout(() => {
      setValue("startDateAndTime", !impactInfo || !impactInfo.startDate ? dayjs().format("YYYY-MM-DDTHH:mm:ss") : dayjs(impactInfo.startDate));
      setValue("attendingNurse", !impactInfo || !impactInfo.nurse ? "" : impactInfo.nurse);
    }, 200);
  },[openDlg, impactInfo]);

  const onSubmit = handleSubmit(async (d) => {
    var obj = {
      impactId: impactInfo.id,
      startDate: dayjs(d.startDateAndTime).format("YYYY-MM-DDTHH:mm:ss"),
      nurse: d.attendingNurse,
    };

    await callApiAsync(
      ()=>postIntitutionStartImpact(obj),
      ()=>{
        NotificationManager.success(msgSuccess);
        setImpactInfo({...impactInfo, ...obj})
        if(callBack)
          callBack();
        closeDialog();
      }
    );
  });

  return (
    <div>
      <DialogAsync
        open={openDlg}
        closeDialog={closeDialog}
        onSubmitAsync={onSubmit}
        titleTextId={"institution.assistance"}
        allowEdit={allowEdit}
      >
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <FormDateTimePicker
                  name="startDateAndTime"
                  label={IntlGetText("core.startDateAndTime")}
                  format="YYYY-MM-DD hh:mm A"
                  disableFuture={true}
                  leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                  rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                  required={true}
                  errorobj={errors}
                  disabled={ !allowEdit ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormInput
                  name="attendingNurse"
                  label={<IntlMessages id="core.attendingNurse" />}
                  margin="normal"
                  errorobj={errors}
                  disabled={ !allowEdit ? true : false}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogAsync>
    </div>
  );
};

export default AssistanceForm;
