import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import IntlMessages from "util/IntlMessages";
import { useForm, FormProvider } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import FormSelectAutoComplete, {getCboValue} from "components/Form/Controls/select-autocomplete";
import RadicadoInsuranceTable from "./RadicadoInsuranceTable";
import InsuranceAuthorizationTable from "./InsuranceAuthorizationTable";
import RadicadoOLFTable from "./RadicadoOLFTable";
import DeliveryTable from "./DeliveryTable";
import { setLoader } from "actions/Base";
import {
  callApi,
  getPrescriptionByPatient,
  getGetProcessDetailPatient,
} from "api/apiList";

const ProcessDetail = ({patientId, openDlg, handleRequestClose}) => {
  const dispatch = useDispatch();  
  const fnSetLoader = (data) => dispatch(setLoader(data));

  const [prescriptionList, setPrescriptionList] = useState([]);
  const [currentPrescriptionId, setCurrentPrescriptionId] = useState(null);
  const [radicadoInsuranceList, setRadicadoInsuranceList] = useState([]);
  const [authorizationList, setAuthorizationList] = useState([]);
  const [radicadoOLFList, setRadicadoOLFList] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);

  const handleCloseDlg = () => {
    setRadicadoInsuranceList([]);
    setAuthorizationList([]);
    setRadicadoOLFList([]);
    setDeliveryList([]);
    handleRequestClose();
  }
  
  const defaultValues = { prescriptionId: "" };
  const methods = useForm({ defaultValues });
  const { handleSubmit, getValues, setValue } = methods;

  useEffect(() => {
    if(patientId === null){
      setPrescriptionList([]);
      return;
    }

    callApi(
      ()=>getPrescriptionByPatient(patientId),
      (data)=>{
        setPrescriptionList(data);
        if(data.length === 1){
          setValue('prescriptionId', getCboValue(data,data[0].id));
          setCurrentPrescriptionId(data[0].id);
        }
      }
    );
  }, [patientId]);

  useEffect(()=>{
    if(!currentPrescriptionId)
      return;

    callApi(
      () => getGetProcessDetailPatient(currentPrescriptionId),
      (detail)=>{
        setRadicadoInsuranceList(detail.radicadoInsurance);
        setAuthorizationList(detail.authorization);
        setRadicadoOLFList(detail.radicadoOLF);
        setDeliveryList(detail.delivery);
      },
      fnSetLoader
    );
  },[currentPrescriptionId]);


  const onSubmit = async (d, e) => {
    if(!d.prescriptionId){
      setCurrentPrescriptionId(null);
      return;
    }
    setCurrentPrescriptionId(d.prescriptionId.value);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openDlg}
        disableBackdropClick={true}
        onClose={handleCloseDlg}
      >
        <DialogTitle>
          <IntlMessages id="patient.processDetail"/>
        </DialogTitle>
        <DialogContent>
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={8}>
                  <FormSelectAutoComplete
                    name="prescriptionId"
                    label={<IntlMessages id="core.prescription" />}
                    options={prescriptionList}
                    onChange={handleSubmit(onSubmit)}
                    margin="normal"
                    className="mr-2"
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          <div className="mt-5 text-uppercase text-grey">
            <h3><IntlMessages id="radicacionInsurance.DialogTitle" /></h3>
          </div>
          <RadicadoInsuranceTable data={radicadoInsuranceList}/>
          <div className="mt-5 text-uppercase text-grey">
            <h3><IntlMessages id="insuranceAuthorization.tableTitle" /></h3>
          </div>
          <InsuranceAuthorizationTable data={authorizationList}/>
          <div className="mt-5 text-uppercase text-grey">
            <h3><IntlMessages id="alertType.radicadoOLF" /></h3>
          </div>
          <RadicadoOLFTable data={radicadoOLFList}/>
          <div className="mt-5 text-uppercase text-grey">
            <h3><IntlMessages id="insuranceAuthorization.deliveries" /></h3>
          </div>
          <DeliveryTable data={deliveryList}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDlg} color="secondary">
            <IntlMessages id="core.close"/>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};    

export default ProcessDetail;