import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
} from "@material-ui/core";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import FormSelectAutoComplete, {
  getCboValue,
} from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import DialogGeneric from "components/Form/DialogGeneric";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  callApiAsync,
  callApi,
  getBarrierList,
  postUpdateBarrier,
} from "api/apiList";
import { BarrierFlagEnum, PatientJaasModuleEnum } from "constants/Enums";

const BarriersModal = ({
  openDlg,
  closeDialog,
  currentImpactId,
  callBack,
  lastState,
  newState,
}) => {
  const [barrierList, setBarrierList] = useState([]);
  const [currentBarrierId, setCurrentBarrierId] = useState(null);
  const [currentBarrierComment, setCurrentBarrierComment] = useState("");
  const [currentBarrierName, setCurrentBarrierName] = useState("");
  const [openBridgeModal, setOpenBridgeModal] = useState(false);
  const [textBridgeModal, setTextBridgeModal] = useState(false);
  const [barrierFlag, setBarrierFlag] = useState(false);
  const [openBarrierModal, setOpenBarrierModal] = useState(false);

  const textCommentBarrier = IntlGetText("barrier.inactiveInactive");

  const handleOpenBridgeModal = () => {
    setOpenBridgeModal(true);
  };
  const handleCloseBridgeModal = () => {
    setOpenBridgeModal(false);
    closeDialog();
  };
  const handleOpenBarrierModal = () => {
    setOpenBarrierModal(true);
  };
  const handleCloseBarrierModal = () => {
    setOpenBarrierModal(false);
  };

  const defaultValues = {
    barrierId: null,
    comment: "",
  };

  const validationSchema = {
    barrierId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, errors } = methods;

  useEffect(() => {
    if (!openDlg) return;
    if (
      (lastState == null &&
        (newState == PatientJaasModuleEnum.SUSPENDED ||
          newState == PatientJaasModuleEnum.IMPOSSIBLE_CONTACT ||
          newState == PatientJaasModuleEnum.RETIRED)) ||
      (lastState == PatientJaasModuleEnum.ACTIVE &&
        (newState == PatientJaasModuleEnum.SUSPENDED ||
          newState == PatientJaasModuleEnum.IMPOSSIBLE_CONTACT ||
          newState == PatientJaasModuleEnum.RETIRED))
    ) {
      setBarrierFlag(BarrierFlagEnum.ACTIVE_INACTIVE);
      setTextBridgeModal(<IntlMessages id="barrier.activeInactive" />);
      handleOpenBridgeModal();
      return;
    }
    if (
      newState == PatientJaasModuleEnum.ACTIVE &&
      (lastState == PatientJaasModuleEnum.SUSPENDED ||
        lastState == PatientJaasModuleEnum.IMPOSSIBLE_CONTACT ||
        lastState == PatientJaasModuleEnum.RETIRED)
    ) {
      if (currentBarrierId == null) {
        callBack();
        return;
      }
      setBarrierFlag(BarrierFlagEnum.INACTIVE_ACTIVE);
      setTextBridgeModal(<IntlMessages id="barrier.inactiveActive" />);
      handleOpenBridgeModal();
      return;
    }
    if (
      (newState == PatientJaasModuleEnum.SUSPENDED ||
        newState == PatientJaasModuleEnum.IMPOSSIBLE_CONTACT ||
        newState == PatientJaasModuleEnum.RETIRED) &&
      (lastState == PatientJaasModuleEnum.SUSPENDED ||
        lastState == PatientJaasModuleEnum.IMPOSSIBLE_CONTACT ||
        lastState == PatientJaasModuleEnum.RETIRED)
    ) {
      if (currentBarrierId !== null) {
        setBarrierFlag(BarrierFlagEnum.INACTIVE_INACTIVE);
        setTextBridgeModal(textCommentBarrier + " " + currentBarrierName);
      } else {
        setBarrierFlag(BarrierFlagEnum.ACTIVE_INACTIVE);
        setTextBridgeModal(<IntlMessages id="barrier.activeInactive" />);
      }
      handleOpenBridgeModal();
      return;
    }
    callBack();
  }, [openDlg]);

  useEffect(() => {
    callApi(
      () => getBarrierList(currentImpactId),
      (data) => {
        setBarrierList(data.barrierList);
        setCurrentBarrierId(data.idSelected);
        setCurrentBarrierName(data.barrierName);
        setCurrentBarrierComment(data.comment);
      }
    );
  }, []);

  const onClickNo = () => {
    callBack();
    handleCloseBridgeModal();
  };

  const onClickYes = () => {
    switch (barrierFlag) {
      case BarrierFlagEnum.ACTIVE_INACTIVE:
        handleOpenBarrierModal();
        break;
      case BarrierFlagEnum.INACTIVE_ACTIVE:
        var obj = {
          impactId: currentImpactId,
          barrierId: null,
          comment: "",
        };
        updateBarrierFunction(obj);
        break;
      case BarrierFlagEnum.INACTIVE_INACTIVE:
        handleOpenBarrierModal();
        if (currentBarrierId != null) {
          setTimeout(() => {
            setValue("barrierId", getCboValue(barrierList, currentBarrierId));
            setValue("comment", currentBarrierComment);
          }, 100);
        }
        break;
      default:
    }
  };

  const onSubmit = handleSubmit(async (d) => {
    var obj = {
      impactId: currentImpactId,
      barrierId: Number(d.barrierId.value),
      comment: d.comment,
    };
    await updateBarrierFunction(obj);
  });

  const updateBarrierFunction = async (obj) => {
    if (callBack) await callBack();

    await callApiAsync(
      () => postUpdateBarrier(obj),
      () => {
        closeDialog();
      }
    );
  };

  return (
    <>
      <Dialog
        open={openBridgeModal}
        disableBackdropClick={true}
        onClose={handleCloseBridgeModal}
      >
        <DialogTitle>
          <IntlMessages id={"institution.patientData"} />
        </DialogTitle>
        <DialogContent className="new-patient-dialog-content">
          {textBridgeModal}
        </DialogContent>
        <DialogActions className="modal-actions">
          <Button onClick={handleCloseBridgeModal} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
          {((BarrierFlagEnum.INACTIVE_INACTIVE == barrierFlag &&
            currentBarrierId != null) ||
            BarrierFlagEnum.ACTIVE_INACTIVE == barrierFlag) && (
            <Button onClick={onClickNo} color="secondary">
              <IntlMessages id="button.no" />
            </Button>
          )}
          <Button onClick={onClickYes} color="primary">
            <IntlMessages id="core.yes" />
          </Button>
        </DialogActions>
      </Dialog>
      <DialogGeneric
        open={openBarrierModal}
        closeDialog={handleCloseBarrierModal}
        titleTextId={"barrier.title"}
      >
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <FormSelectAutoComplete
                    name="barrierId"
                    label={<IntlMessages id="barrier.label" />}
                    options={barrierList}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    name="comment"
                    label={<IntlMessages id="barrier.commment" />}
                    multiline
                    rows={2}
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4 mb-4">
            <ButtonAsync onClick={onSubmit} variant="contained" color="primary">
              {<IntlMessages id="core.save" />}
            </ButtonAsync>
          </div>
        </div>
      </DialogGeneric>
    </>
  );
};

export default BarriersModal;
