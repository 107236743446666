import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import {
  callApi,
  callApiAsync,
  getAdditionalMedications,
  getDiagnosisRecordByPersonId,
  SaveDiagnosisRecord,
  saveDiagnosisRecordConfirmationTests,
  saveFamilyHistoryIds
} from "api/apiList";
import { callStartImpact } from "actions/Impact";
import TableD from "./TableD";
import FormD from "./FormD";
import FormTestDisease from "./formTestDisease";
import FormFamiliar from "./formFamiliar";

export const getNameEnumById = (id) => {
  const confirmationTypes = [
    { id: 1, name: IntlGetText("Diagnosis.clinic") },
    { id: 2, name: IntlGetText("Diagnosis.laboratory") }
  ];
  const confirmation = confirmationTypes.find((item) => item.id === id);
  return confirmation ? confirmation.name : undefined;
};

const Diagnosis = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentSigned, isCIRequired } = useSelector(
    ({ impact }) => impact
  );

  const [open, setOpen] = useState(false);
  const [openD, setOpenD] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openFamiliarDialog, setOpenFamiliarDialog] = useState(false);
  const [openTestDiseaseDialog, setOpenTestDiseaseDialog] = useState(false);
  const [currentRowObj, setCurrentRowObj] = useState(null);
  const [diagnosisTableData, setDiagnosisTableData] = useState([]);

  const loadTable = () =>
    callApi(
      () => getDiagnosisRecordByPersonId(selectedPrescription.patient.id),
      (data) => setDiagnosisTableData(data)
    );
  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    if (isCIRequired &&  !consentSigned) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }
    setOpen(true);
  };
  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleOpenCRU = () => {
    setOpenD(true);
  };
  const handleRequestCloseCRU = () => {
    setOpenD(false);
  };
  const handleRequestCloseDel = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseFamiliar = () => {
    loadTable();
    setOpenFamiliarDialog(false);
  };

  const handleCloseTestDisease = () => {
    loadTable();
    setOpenTestDiseaseDialog(false);
  };

  useEffect(() => {
    loadTable();
  }, []);

  const handleCreateUpdate = async (data) => {
    await callApiAsync(() => SaveDiagnosisRecord(data));
    loadTable();
  };

  const handleCreateUpdateTestDisease = async (data) => {
    await callApiAsync(() => saveDiagnosisRecordConfirmationTests(data));
  };

  const handleDelete = () => {
    console.log("AQUI ELIMINAMOS UN REGISTRO");
  };

  return (
    <div>
      <InfoCard
        data={{
          icon: "file-text",
          title: IntlGetText("Diagnosis.Diagnosis"),
          subTitle: "",
          color: "#757575"
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="Diagnosis.Diagnosis" />
        </DialogTitle>
        <DialogContent>
          <TableD
            data={diagnosisTableData}
            handleOpenCRU={handleOpenCRU}
            handleOpenDel={() => setOpenDeleteDialog(true)}
            setCurrentRowObj={setCurrentRowObj}
            handleOpenFamiliar={() => setOpenFamiliarDialog(true)}
            handleOpenTestDisease={() => setOpenTestDiseaseDialog(true)}
          />
          <FormFamiliar
            openFamiliar={openFamiliarDialog}
            handleRequestClose={handleCloseFamiliar}
            rowObj2={currentRowObj}
            impactId={selectedPrescription.impactId}
          />
          <FormTestDisease
            openTestDisease={openTestDiseaseDialog}
            handleRequestClose={handleCloseTestDisease}
            rowObj2={currentRowObj}
            impactId={selectedPrescription.impactId}
            handleCreateUpdate={handleCreateUpdateTestDisease}
            loadDiagnosisTable={loadTable}
          />
          <FormD
            openD={openD}
            handleRequestClose={handleRequestCloseCRU}
            rowObj2={currentRowObj}
            impactId={selectedPrescription.impactId}
            handleCreateUpdate={handleCreateUpdate}
          />
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={openDeleteDialog}
            disableBackdropClick={true}
            onClose={handleRequestCloseDel}
          >
            <DialogTitle>
              <IntlMessages id="Diagnosis.MsgDelConfirmation" />
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleRequestCloseDel} color="secondary">
                <IntlMessages id="core.cancel" />
              </Button>
              <Button onClick={handleDelete} color="primary">
                <IntlMessages id="core.accept" />
              </Button>
            </DialogActions>
          </Dialog>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Diagnosis;
