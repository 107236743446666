import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormDatePicker from "components/Form/Controls/datepicker";
import CardFileUpload from "components/Form/CardFileUpload";
import DeliveryDetail from "./DeliveryDetail";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { postAddFileMedicineDelivery } from "api/apiList";
import { PrescripStateEnum } from "constants/Enums";

const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const getItemList = (data) => data.map((x) => ({ id: x.id, name: x.code }));

const getMedicineAmount = (radicadoOLFList, radicadoOLFId, deliveryList) => {
  var radicado = radicadoOLFList.find((x) => x.id == radicadoOLFId);
  var delivered = deliveryList
    .filter((x) => x.radicadoLogisticOperatorId == radicadoOLFId)
    .map((item) => item.medicineAmount)
    .reduce((prev, curr) => prev + curr, 0);

  return radicado.medicineAmount - delivered;
};

const FormCRU = ({
  rowObj,
  impactId,
  openCRU,
  handleRequestClose,
  radicadoOLFList,
  handleCreateUpdate,
  deliveryList,
  setDeliveryList,
  readOnly,
}) => {
  const { selectedPrescription } = useSelector(({ management }) => management);

  const [file, setFile] = useState(null);
  const [incompleteDeliveryAlert, setIncompleteDeliveryAlert] = useState({
    isAlert: false,
    radicadoAmount: 0,
    deliveryAmount: 0,
  });
  const [deliveryDetailList, setDeliveryDetailList] = useState([]);

  const msgFileValidation = IntlGetText("medicineDelivery.fileValidation");
  const msgMedicineAmountValid = IntlGetText("medicineDelivery.medicineAmountValid");

  const defaultValues = {
    deliveryDate: null,
    radicadoLogisticOperatorId: "",
    medicineAmount: "",
    commentAlert: "",
  };

  const validationSchema = {
    deliveryDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    radicadoLogisticOperatorId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    medicineAmount: yup
      .number()
      .typeError(IntlGetText("yup.numericValidation"))
      .min(1, IntlGetText("yup.numericValidation"))
      .max(50000, IntlGetText("yup.invalidInput"))
      .required(<IntlMessages id="core.fieldRequired" />),
    commentAlert: yup
      .string()
      .when("radicadoLogisticOperatorId", (radicadoId) => {
        if (incompleteDeliveryAlert.isAlert) {
          return yup
            .string()
            .required(<IntlMessages id="core.fieldRequired" />);
        }
      }),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, watch, errors } = methods;

  const radicadoLogisticOperatorIdValue = watch("radicadoLogisticOperatorId");
  const medicineAmountValue = watch("medicineAmount");

  const incompleteAlert = () => {
    var radicadoAmount = 0;
    if (
      radicadoLogisticOperatorIdValue &&
      radicadoLogisticOperatorIdValue.value
    ) {
      var radicado = radicadoOLFList.find(
        (x) => x.id == radicadoLogisticOperatorIdValue.value
      );
      radicadoAmount = radicado.medicineAmount;
    }

    var deliveryAmount = 0;
    if (medicineAmountValue) deliveryAmount = medicineAmountValue;

    const isEdit = rowObj && rowObj.medicineAmount == deliveryAmount;
    setIncompleteDeliveryAlert({
      radicadoAmount,
      deliveryAmount,
      isAlert:
        radicadoAmount > 0 && deliveryAmount > 0 && !isEdit
          ? deliveryAmount < radicadoAmount
          : false,
    });
  };

  const cboHandleChange = (name) => (event) => {
    if (!event) {
      setValue(name, null);
      return;
    }
    setValue(name, event);
    if (!rowObj || rowObj.id == 0) {
      setValue(
        "medicineAmount",
        getMedicineAmount(radicadoOLFList, event.value, deliveryList)
      );
    }
  };

  useEffect(() => {
    if (openCRU) {
      setTimeout(loadData, 150);
    }
  }, [openCRU, rowObj]);

  useEffect(() => incompleteAlert(), [
    radicadoLogisticOperatorIdValue,
    medicineAmountValue,
    rowObj,
  ]);

  const loadData = () => {
    setValue("deliveryDate", !rowObj || !rowObj.deliveryDate ? null : dayjs(rowObj.deliveryDate));
    setValue(
      "radicadoLogisticOperatorId",
      !rowObj
        ? ""
        : getCboValue(
            getItemList(radicadoOLFList),
            rowObj.radicadoLogisticOperatorId
          )
    );
    setValue("medicineAmount", !rowObj ? "" : rowObj.medicineAmount);
    setDeliveryDetailList(!rowObj ? [] : rowObj.medicineDeliveryDetail);
    setFile(!rowObj ? null : rowObj.filePath);
  };

  const updateFileInObj = (objId,file) =>{
    let index = deliveryList.findIndex((x)=> x.id === objId);
    let data = [...deliveryList];
    data[index] = {...data[index], filePath: file};

    setDeliveryList(data);    
  };

  const resetIdsNewItems = (dataList) =>{
    return dataList.map(x=>{
      if(!Number(x.id)){
        x.id = 0;
      }
      return x;
    });
  };
  const onSubmit = async (d, e) => {
    const elementFile =  document.getElementById("fm-file");
    if(elementFile && elementFile.value){
      NotificationManager.warning(msgFileValidation);
      return;
    }
    if(Number(d.medicineAmount) !== deliveryDetailList.length){
      NotificationManager.warning(msgMedicineAmountValid);
      return;
    }

    var obj = {
      id: !rowObj ? 0 : rowObj.id,
      impactId: impactId,
      deliveryDate: !d.deliveryDate ? null : dayjs(d.deliveryDate).format("YYYY-MM-DDTHH:mm:ss"),
      radicadoLogisticOperatorId: Number(d.radicadoLogisticOperatorId.value),
      medicineAmount: Number(d.medicineAmount),
      commentAlert: d.commentAlert,
      medicineDeliveryDetail: resetIdsNewItems(deliveryDetailList)
    };
    await handleCreateUpdate(obj);
  };

  const allowEdit = () => {
    if (!rowObj || !rowObj.id) return true;

    if (readOnly) return false;

    return rowObj.impactId == impactId;
  };
  const disabled = !allowEdit();

  const handleRequestClose2 = () => {
    setIncompleteDeliveryAlert({
      isAlert: false,
      radicadoAmount: 0,
      deliveryAmount: 0,
    });
    handleRequestClose();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose2}
    >
      <DialogTitle>
        <IntlMessages id={!rowObj ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6}>
                  <FormDatePicker
                    name="deliveryDate"
                    label={<IntlMessages id="radicadoOLF.deliveryDate" />}
                    format="YYYY-MM-DD"
                    disableFuture={true}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="radicadoLogisticOperatorId"
                    label={<IntlMessages id="radicadoOLF.code" />}
                    options={getItemList(radicadoOLFList)}
                    value={radicadoLogisticOperatorIdValue}
                    onChange={cboHandleChange("radicadoLogisticOperatorId")}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="medicineAmount"
                    label={
                      <IntlMessages id="medicineDelivery.medicineAmount" />
                    }
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                {incompleteDeliveryAlert.isAlert && (
                  <Grid item xs={12} md={12} lg={12}>
                    <h4 className="mt-4">
                      <label>
                        <IntlMessages id="medicineDelivery.incompleteDeliveryAlert" />{" "}
                        {"(" +
                          incompleteDeliveryAlert.deliveryAmount +
                          "/" +
                          incompleteDeliveryAlert.radicadoAmount +
                          ")"}
                      </label>
                    </h4>
                  </Grid>
                )}
                {incompleteDeliveryAlert.isAlert && (
                  <Grid item xs={12} md={12} lg={12}>
                    <FormInput
                      name="commentAlert"
                      label={
                        <IntlMessages id="medicineDelivery.commentAlert" />
                      }
                      required={true}
                      multiline
                      rows={3}
                      variant="outlined"
                      margin="normal"
                      errorobj={errors}
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          </FormProvider>
          {rowObj && (
            <CardFileUpload
              disabled={selectedPrescription.stateId == PrescripStateEnum.incompleteDelivery && !file
                ? false : disabled}
              file={file}
              setFile={(file)=>{
                setFile(file);
                updateFileInObj(rowObj.id, file);
              }}
              actionApi={postAddFileMedicineDelivery}
              actionApiInfo={{ impactId, id: rowObj.id }}
            />
          )}
          {!disabled && (
            <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
              <ButtonAsync onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
              >
                <IntlMessages id="core.save" />
              </ButtonAsync>
            </div>
          )}
        </div>
        <DeliveryDetail 
          impactId={impactId}
          deliveryDetailList={deliveryDetailList}
          setDeliveryDetailList={setDeliveryDetailList}
          readOnly={disabled}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormCRU;
