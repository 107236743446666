import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AppModuleHeader from "components/AppModuleHeader/index";
import { IconButton } from "@material-ui/core";
import { IntlGetText } from "util/IntlMessages";
import {
    onToggleDrawer,
    updateManageSearch
 } from "actions/Management";
 import BtnCancelFormula from "./BtnCancelFormula";
 import BtnActivatePatient from "./BtnActivatePatient"; 
 import BtnCreatePrescription from "./BtnCreatePrescription"; 
 import BtnDropout from "./btnDropout"; 
 import { PrescripStateEnum, FolderType } from "constants/Enums";
import { isOnlyTracing } from "app/routes/psp/services/userService";
 

const SearchHeader = () => {
    const dispatch = useDispatch();
    const { selectedMenuItem, searchText, selectedPrescription } = useSelector(({ management }) => management);
    const { isOnlyTracingPatient } = useSelector(({ impact }) => impact);


    const handleToggleDrawer = () => {dispatch(onToggleDrawer());};
    const updateSearch = (evt) => {dispatch(updateManageSearch(evt.target.value));};

    if(selectedPrescription){
      return (
        <div className="module-box-header">          
          <IconButton
            className="drawer-btn d-block d-xl-none"
            aria-label="Menu"
            onClick={handleToggleDrawer}
          >
            <i className="zmdi zmdi-menu" />
          </IconButton>
          
          <div style={{"flexDirection": "row", "justifyContent": "left"}}>
            <div className="text-right">
            { selectedMenuItem == FolderType.inProcess && <BtnCancelFormula stateToAssign={PrescripStateEnum.cancelled}/> }
            { selectedMenuItem == FolderType.inProcess && <BtnCancelFormula stateToAssign={PrescripStateEnum.forcedTermination}/> }
            { (selectedMenuItem == FolderType.tracing && isOnlyTracingPatient) && 
              <BtnCreatePrescription/>
            }
            <BtnDropout/>
            </div>
          <BtnActivatePatient/>
          </div>
        </div>
      );
    }

    return (
      <div className="module-box-header">
        <IconButton
          className="drawer-btn d-block d-xl-none"
          aria-label="Menu"
          onClick={handleToggleDrawer}
        >
          <i className="zmdi zmdi-menu" />
        </IconButton>
        <AppModuleHeader
          placeholder={IntlGetText("core.search")}
          onChange={updateSearch}
          value={searchText}
        />
      </div>
    );
};

export default SearchHeader;