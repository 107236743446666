export default [
  {
    id: 0,
    handle: "inbox",
    title: "Nuevos eventos",
    icon: "accounts-alt",
  },
  {
    id: 1,
    handle: "sent",
    title: "En Tramite",
    icon: "hourglass-alt",
  },
  {
    id: 2,
    handle: "drafts",
    title: "Suspención/Fallecidos",
    icon: "folder",
  },
  {
    id: 3,
    handle: "spam",
    title: "Cerrados",
    icon: "alert-octagon",
  },
];
