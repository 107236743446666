import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { IntlGetText } from "util/IntlMessages";
import { callStartImpact, setImpactStartDate } from "actions/Impact";
import {  
  setSelectedPrescription, 
  loadPrescriptions,
} from "actions/Management";
import InfoCard from "components/InfoCard/index";
import { callApi, getPrescriptions } from "api/apiList";
import FormEdit from "./formEdit";


const IsOnlyTracingBox = () => {
  const dispatch = useDispatch();
  const { impactStartDate, consentSigned, isCIRequired } = useSelector(({ impact }) => impact);
  const { selectedPrescription, selectedMenuItem } = useSelector(({ management }) => management);

  const [openDlg, setOpenDlg] = useState(false);
  
  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    if (isCIRequired && !consentSigned) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }
    setOpenDlg(true);
  };

  const callBack = () => {
    dispatch(setImpactStartDate(null));

    callApi(
      ()=> getPrescriptions(selectedMenuItem), 
      (data)=>{
          dispatch(loadPrescriptions(data, selectedMenuItem));
          dispatch(setSelectedPrescription(null));
      }
    );
  };

  return (
    <>
      <InfoCard
        data={{
          icon: "settings",
          title: IntlGetText("core.setting"),
          subTitle: IntlGetText("core.patient"),
          color: "#757575"
        }}
        onClickBox={handleClickOpen}
      />
      <FormEdit
        openDlg={openDlg} 
        closeDialog={()=>setOpenDlg(false)}
        selectedPrescription= {selectedPrescription}
        callBack={callBack}/>
    </>
  );
};

export default IsOnlyTracingBox;