import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getToken, revokeToken, relogin } from "../api/api";

import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNIN_RELOGIN
} from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
} from "actions/Auth";

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await getToken(email, password)
    .then((infoAuth) => infoAuth)
    .catch((error) => error);

const signReloginRequest = async (username) =>
  await relogin(username)
    .then((infoAuth) => infoAuth)
    .catch((error) => error);

const signOutRequest = async () =>
  await revokeToken()
    .then((authUser) => undefined)
    .catch((error) => error);

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const authInfo = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password
    );
    authInfo.userName = authInfo.userName ?? "User";
    if (authInfo.token) {
      localStorage.setItem("user_id", authInfo.userName);
      localStorage.setItem("userInfo", JSON.stringify(authInfo));
      yield put(
        userSignInSuccess({
          authUser: authInfo.userName ?? "User",
          userInfo: authInfo
        })
      );
    } else {
      //authInfo.message ? authInfo.message : authInfo.error
      yield put(showAuthMessage("401"));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInReloginWithEmail({ payload }) {
  const { username } = payload;
  try {
    const authInfo = yield call(
      signReloginRequest,
      username
    );

    if (authInfo.token) {
      localStorage.setItem("user_id", authInfo.userName);
      localStorage.setItem("userInfo", JSON.stringify(authInfo));
      yield put(
        userSignInSuccess({
          authUser: authInfo.userName,
          userInfo: authInfo
        })
      );
    } else {
      //authInfo.message ? authInfo.message : authInfo.error
      yield put(showAuthMessage("401"));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem("user_id");
      localStorage.removeItem("userInfo");
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* signInRelogin() {
  yield takeEvery(SIGNIN_RELOGIN, signInReloginWithEmail);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signOutUser),
    fork(signInRelogin),
  ]);
}
