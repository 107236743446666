import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
const passwordMustMatch = <IntlMessages id="changePasswordModal.passwordMustMatch" />;
const PasswordChangeForm =({ openCPM, handleCloseCPM, handleChangePassword })=>{
    
    const defaultValues = {
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    };
  
    const validationSchema = {
      currentPassword: yup.string()
        .required(<IntlMessages id="core.fieldRequired" />),
      newPassword: yup.string()
        .matches(/.{4,}/g,  IntlGetText("PasswordValidation.PasswordTooShort"))
        .required(<IntlMessages id="core.fieldRequired" />),
      confirmPassword: yup.string()
        .oneOf([yup.ref('newPassword'), null], passwordMustMatch)
        .required(<IntlMessages id="core.fieldRequired" />)
    };
  
    const methods = useForm({ 
      defaultValues,
      resolver: yupResolver(yup.object().shape(validationSchema)),
    });
    const { handleSubmit, errors } = methods;

    useEffect(()=>{
        if(openCPM){
          setTimeout(150);
        }
      },[openCPM]);

    const onSubmit = async (d, e) => {
      await handleChangePassword(d.currentPassword, d.newPassword);
    };

    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openCPM}
        onClose={handleCloseCPM}
      >
        <DialogTitle>
          <IntlMessages id="changePasswordModal.changePasswordModalTitle" />
        </DialogTitle>
        <DialogContent>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <FormProvider {...methods}>
              <form className="row" noValidate autoComplete="off">
                <Grid container alignItems="center">
                  <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center">
                      <Grid item xs={4} md={4} lg={4}>
                          <FormInput
                          name="currentPassword"
                          type="password"
                          label={<IntlMessages id="changePasswordModal.currentPasswordLabel" />}
                          margin="normal"
                          required={true}
                          errorobj={errors}
                          />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center">
                      <Grid item xs={4} md={4} lg={4}>
                          <FormInput
                          name="newPassword"
                          type="password"
                          label={<IntlMessages id="changePasswordModal.newPasswordLabel" />}
                          margin="normal"
                          required={true}
                          errorobj={errors}
                          />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center">
                      <Grid item xs={4} md={4} lg={4}>
                          <FormInput
                          name="confirmPassword"
                          type="password"
                          label={<IntlMessages id="changePasswordModal.reEnterPasswordLabel" />}
                          margin="normal"
                          required={true}
                          errorobj={errors}
                          />
                      </Grid>
                    </Grid>
                </Grid>
              </form>
            </FormProvider>
            { !false &&
              <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
                <Button
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                >
                  {<IntlMessages id="changePasswordModal.submit" />}
                </Button>
              </div>
            }
          </div>  
        </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCPM} color="secondary">
              <IntlMessages id="core.close" />
            </Button>
          </DialogActions>
        </Dialog>  
    );
  };

  export default PasswordChangeForm;