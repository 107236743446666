import React, { useState } from "react";
import IntlMessages from "util/IntlMessages";
import { Tabs, Tab } from "@material-ui/core";
import PhotographicRecord from "./PhotographicRecord/index.js"
import Form from "./Form";

const TabComponent = (props) => {
  const { obj } = props;

  const [currentTab, setCurrentTab] = useState(0);  
  const handleChange = (event, value) => setCurrentTab(value);

  return (
    <div>
      <Tabs          
          value={currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
        <Tab
          className="jr-tabs-label"
          label={<IntlMessages id="catheterStatus.DetailTab1" />}
        />
        {obj && obj.id &&
          <Tab
            className="jr-tabs-label"
            label={<IntlMessages id="catheterStatus.DetailTab2" />}
          />
        }
      </Tabs>
      <div className="jr-tabs-content jr-task-list">
        <div className="row">
          {currentTab === 0 && (
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="mt-2">
                <Form {...props} />
              </div>
            </div>
          )}
          {currentTab === 1 && (
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="mt-2">
                <PhotographicRecord currentObj={obj}/>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TabComponent;