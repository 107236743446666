import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import {
  isJaasModule,
  isDirectTracing,
} from "app/routes/psp/services/userService";
import IsOnlyTracingBox from "components/JaasModule/IsOnlyTracingBox";
import ConsentDialog from "./Consent";
import FormulaDialog from "./Formula";
import ClinicalHistory from "./ClinicalHistory";
import PatientInfo from "./PatientInfo";
import Education from "./Education";
import NursePlan from "./NursePlan/index";
import AdditionalMedications from "./AdditionalMedications";
import { loadInfoImpact } from "actions/Impact";
import { callActionApi, getInfoImpact } from "api/apiList";

const ShowBox = ({ boxName, children }) => {
  const isDirectTracingConfig = isDirectTracing();

  const uiResult = (
    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">{children}</div>
  );

  if (isDirectTracingConfig) {
    if (boxName === "ConsentDialog" || boxName === "PatientInfo") {
      return uiResult;
    } else return null;
  }

  if (boxName === "IsOnlyTracingBox"){   
    const isJaasModuleConfig = isJaasModule(); 
    return isJaasModuleConfig ? uiResult : null;
  }

  return uiResult;
};

const FirstImpact = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);

  useEffect(() => {
    callActionApi(
      () => getInfoImpact(selectedPrescription.impactId),
      (data) => dispatch(loadInfoImpact(data)),
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  }, []);

  return (
    <div>
      <div className="row first-impact-box-margins">
        <ShowBox boxName="ConsentDialog">
          <ConsentDialog />
        </ShowBox>
        <ShowBox boxName="PatientInfo">
          <PatientInfo />
        </ShowBox>
        <ShowBox boxName="FormulaDialog">
          <FormulaDialog />
        </ShowBox>
        <ShowBox boxName="ClinicalHistory">
          <ClinicalHistory />
        </ShowBox>
        <ShowBox boxName="NursePlan">
          <NursePlan />
        </ShowBox>
        <ShowBox boxName="Education">
          <Education />
        </ShowBox>
        <ShowBox boxName="AdditionalMedications">
          <AdditionalMedications />
        </ShowBox>
        <ShowBox boxName="IsOnlyTracingBox">
          <IsOnlyTracingBox />
        </ShowBox>
      </div>
    </div>
  );
};

export default FirstImpact;
