import React, { useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { 
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogActions 
} from "@material-ui/core";
import CustomTable from "./table"
import FormCreateUpdate from "./form"
import { setLoader } from "actions/Base";
import {
  callApi,
  callApiAsync,
  getEventTrainingDetail,
  postAddUpdateResource,
  deleteEventTrainingResource
} from "api/apiList";

const Resources = ({eventTraining, readOnly}) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));

  const [eventTrainingInfo, setEventTrainingInfo] = useState(null);
  const [currentRowObj, setCurrentRowObj] = useState(null);
  const [openCRU, setOpenCRU] = useState(false);
  const [openDlgDelete, setOpenDlgDelete] = useState(false);
  
  const handleRequestCloseCRU = () => {
    setOpenCRU(false);
    setCurrentRowObj(null);
  }
  const handleRequestCloseDel = () => {
    setOpenDlgDelete(false);
    setCurrentRowObj(null);
  }

  const refreshEventTrainingInfo = () => {
    callApi(
      ()=>getEventTrainingDetail(eventTraining.id), 
      setEventTrainingInfo,
      fnSetLoader)
  }

  useEffect(()=>{
    if(!eventTraining)
      return;

    refreshEventTrainingInfo();
  },[eventTraining])

  const handleCreateUpdate = async (data) => {
    await callApiAsync(
      () => postAddUpdateResource(data),
      () => {
        handleRequestCloseCRU();
        refreshEventTrainingInfo();
      }
    );
  };

  const handleDelete = () => {
    callApi(
      () => deleteEventTrainingResource(currentRowObj.id),
      () => {
        handleRequestCloseDel();
        refreshEventTrainingInfo();
      }
    );
  };

  return (
    <div>
      {!readOnly &&
        <IconButton
          type="button"
          className="icon-btn"
          color="primary"
          onClick={()=>setOpenCRU(true)}
        >
          <i className="zmdi zmdi-plus-square"/>
        </IconButton>
      }
      <CustomTable 
        eventTrainingInfo={eventTrainingInfo}
        handleOpenCRU={() => setOpenCRU(true)}
        handleOpenDel={() => setOpenDlgDelete(true)}
        setCurrentRowObj={setCurrentRowObj}
        readOnly={readOnly}
      />
      <FormCreateUpdate 
        obj={currentRowObj}
        eventId={!eventTrainingInfo ? 0 : eventTrainingInfo.id}
        openCRU={openCRU}
        handleRequestClose={handleRequestCloseCRU}
        handleCreateUpdate={handleCreateUpdate}
        readOnly={readOnly}
        />
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openDlgDelete}
        disableBackdropClick={true}
        onClose={handleRequestCloseDel}
      >
        <DialogTitle>
          <IntlMessages id="additionalMedications.MsgDelConfirmation" />
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleRequestCloseDel} color="secondary">
            <IntlMessages id="core.cancel" />
          </Button>
          <Button onClick={handleDelete} color="primary">
            <IntlMessages id="core.accept" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Resources;
