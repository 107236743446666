import React from "react";
import { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { DownloadButton2 } from "components/DownloadButton/index";
import CardBox from "components/CardBox/index";
import { ImpactFileTypeList } from "constants/Enums";

const TableFiles = ({
  data,
  handleOpenDel,
  setCurrentRowObj,
  currentImpact,
  readOnly,
}) => {
  const impactFileTypeList = ImpactFileTypeList();

  const datatableConfig = {
    textLabels: {
      body: {
        noMatch: "No hay resultados para la búsqueda actual",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordernar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Items por pagina:",
        displayRows: "de",
      },
      toolbar: {
        search: "Busqueda",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtro",
      },
      filter: {
        all: "Todos",
        title: "FILTRO",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas",
      },
      selectedRows: {
        text: "item(s) seleccionados",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    },
  };

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: datatableConfig.textLabels,
    pagination:false
  };

  const handleClickDelete = (tableMeta) => {
    handleOpenDel();
    setCurrentRowObj(tableMeta.tableData[tableMeta.rowIndex]);
  };

  const getFileType = (value) => {    
    if(!value && value !== 0)
      return "";
    var status = impactFileTypeList.find(x=>x.id === value); 
    return !status ? "" : status.name;
  };

  const columns = [
    {
      name: "impactFileType",
      label: IntlGetText("core.type"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => getFileType(value)
      },
    },
    {
      name: "comment",
      label: IntlGetText("core.comment"),
      options: {
        filter: false,
        sort: false
      },
    },
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = tableMeta.tableData[tableMeta.rowIndex];
          if (rowObj.impactId != currentImpact || readOnly) {
            return (
              <DownloadButton2                
                variant="contained"
                className="jr-btn jr-btn-xs"
                color="primary"
                fileLink={rowObj.file}
              >
                <i className="zmdi zmdi-download"></i>
              </DownloadButton2>
            );
          }

          return (
            <div>
              <DownloadButton2                
                variant="contained"
                className="jr-btn jr-btn-xs"
                color="primary"
                fileLink={rowObj.file}
              >
                <i className="zmdi zmdi-download"></i>
              </DownloadButton2>
              <Button
                onClick={() => handleClickDelete(tableMeta)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-delete"></i>
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="row">
      <CardBox styleName="col-12" cardStyle="p-0 border-dialog-card">
        <MUIDataTable
          className="border-dialog-card"
          data={data}
          columns={columns}
          options={options}
        />
      </CardBox>
    </div>
  );
};

export default TableFiles;
