import React from "react";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import ButtonAsync from "components/Form/Controls/ButtonAsync";

const DialogConfirm = ({openDialog, handleRequestClose, title, text, onAcceptClick}) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={openDialog}
            disableBackdropClick={true}
            onClose={handleRequestClose}
          >
            <DialogTitle>
              {title}
            </DialogTitle>
            <DialogContent className="new-patient-dialog-content">
                <div className="row">
                    {text}
                </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRequestClose} color="secondary">
                <IntlMessages id="core.cancel" />
              </Button>
              <Button onClick={onAcceptClick} color="primary">
                <IntlMessages id="core.accept" />
              </Button>
            </DialogActions>
        </Dialog>
    );
};

export const DialogConfirmAsync = ({openDialog, handleRequestClose, title, text, onAcceptClickAsync}) => {
  return (
      <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={openDialog}
          disableBackdropClick={true}
          onClose={handleRequestClose}
        >
          <DialogTitle>
            {title}
          </DialogTitle>
          <DialogContent className="new-patient-dialog-content">
              <div className="row">
                  {text}
              </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color="secondary">
              <IntlMessages id="core.cancel" />
            </Button>
            <ButtonAsync onClick={onAcceptClickAsync} color="primary">
              <IntlMessages id="core.accept" />
            </ButtonAsync>
          </DialogActions>
      </Dialog>
  );
};
export const DlgConfirmCustomAsync = ({children, ...props}) => {
  const { 
    open, 
    handleRequestClose,
    titleTextId, 
    titleText,
    onAcceptClickAsync,
    maxWidth="sm"
     } = props;

  return (
    <Dialog
        open={open}
        fullWidth={true}
        maxWidth={maxWidth}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        {(titleTextId || titleText) && 
          <DialogTitle>{titleTextId && <IntlMessages id={titleTextId} />}{!titleTextId &&titleText}</DialogTitle>
        }
        <DialogContent className="new-patient-dialog-content">
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.cancel" />
          </Button>
          <ButtonAsync onClick={onAcceptClickAsync} color="primary">
            <IntlMessages id="core.accept" />
          </ButtonAsync>
        </DialogActions>
    </Dialog>
  );
};
export default DialogConfirm;