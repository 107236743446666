import React from "react";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";

const DlgGeneric = ({children, ...props}) => {
  const { open, closeDialog, titleTextId, titleText,
    maxWidth="sm" } = props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={maxWidth}
        disableBackdropClick={true}
        onClose={closeDialog}
      >
        {(titleTextId || titleText) && 
          <DialogTitle>{titleTextId && <IntlMessages id={titleTextId} />}{!titleTextId &&titleText}</DialogTitle>
        }
        <DialogContent className="new-patient-dialog-content">
          {children}
        </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="secondary">
          {<IntlMessages id="core.close" />}
        </Button>
      </DialogActions>
    </Dialog>
    );
};

export default DlgGeneric;