import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadPrescriptions, setSelectedPrescription } from "actions/Management";
import { Button, Tooltip } from "@material-ui/core";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { getUserInfo } from "app/routes/psp/services/userService";
import { DlgConfirmCustomAsync } from "components/Form/Controls/DialogConfirm";
import DialogEndImpact from "./dialogEndImpact";
import { FolderType, ShardKeyEnum } from "constants/Enums";
import { callApiAsync, postSetDropoutState, getPrescriptions, } from "api/apiList";


const BtnDropout = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, impactEndDate } = useSelector(({ impact }) => impact);
  
  const [openDlg, setOpenDlg] = useState(false);
  const [openDlgEndImpact, setOpenDlgEndImpact] = useState(false);

  const isUllkupuy = getUserInfo().shardKey === ShardKeyEnum.ullkupuy;
  const isPhg= getUserInfo().shardKey === ShardKeyEnum.phg;

  const closeDialog = () => setOpenDlg(false);

  const postDropoutAsync = async (nursingNote) => {
    await callApiAsync(
      () =>
        postSetDropoutState({
          impactId: selectedPrescription.impactId,
          nursingNote
        }),
      async () => {
        await callApiAsync(
          () => getPrescriptions(FolderType.inProcess),
          (data) => {
            closeDialog();
            dispatch(loadPrescriptions(data, FolderType.inProcess));
            dispatch(setSelectedPrescription(null));
          }
        );
      }
    );
  };

  return (
    <>
      { selectedPrescription && (isUllkupuy || isPhg)  && 
        <>
          <Tooltip
            title={IntlGetText("patientState.setDropout")}
            placement="bottom-end">
            <Button
              onClick={() => setOpenDlg(true)}
              variant="contained"
              className="ml-1 jr-btn bg-red lighten-1 text-white">
              <i className="zmdi zmdi-delete zmdi-hc-fw" />
            </Button>
          </Tooltip>
          <DlgConfirmCustomAsync
            open={openDlg}
            titleTextId="patientState.setDropout"
            handleRequestClose={closeDialog}
            onAcceptClickAsync={async ()=>{
              if(impactStartDate && !impactEndDate){
                setOpenDlgEndImpact(true);
                return;
              }
              await postDropoutAsync("");
            }}
          >
            <div className="row">
              <IntlMessages id="patientState.setDropoutConfirmText"/>
            </div>
          </DlgConfirmCustomAsync>
          <DialogEndImpact 
            open={openDlgEndImpact} 
            handleRequestClose={() => setOpenDlgEndImpact(false)} 
            callEndImpact={postDropoutAsync}/>
        </>
      }
    </>
  );
};

export default BtnDropout;
