import React, { useState, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import IntlMessages from "util/IntlMessages";
import { Grid } from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import { 
  callApi, 
  getInfoConsent,
  getCitiesByStateProvinceId,
  getDiseasesByProductId
 } from "api/apiList";


const getCboValue = (items, id) => {
    if(typeof(id) == "object"){
      return id;
    }
    const result = items.find((x) => x.id === id);
    return result ? { label: result.name, value: result.id } : "";
  };

export const defaultValues = {
    name:"",
    lastName:"",
    documentTypeId: null,
    document:"",
    cellphone:"",
    phone:"",
    email:"",
    stateProvinceId: null,
    cityId: null,
    doctor:"",
    productId: null,
    diseaseId: null,
    tutorName:"",
    tutorLastName:"",
    tutorDocumentTypeId: null,
    tutorDocument:""
};

export const validationSchema = yup.object().shape({
    name: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    lastName: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    documentTypeId: yup.object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable(),
    document: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    //cellphone: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    phone: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    email: yup.string().email(<IntlMessages id="yup.emailInvalid" />),
    stateProvinceId: yup.object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable(),
    cityId: yup.object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable(),
    diseaseId: yup.object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable(),
    productId: yup.object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable(),
    tutorName: yup.string()
    .when(["tutorLastName", "tutorDocumentTypeId", "tutorDocument"], {
      is: (tutorLastName, tutorDocumentTypeId, tutorDocument) => tutorLastName || tutorDocumentTypeId || tutorDocument,
      then: yup
          .string()
          .required(<IntlMessages id="core.fieldRequired" />)
      }
    ),
    tutorLastName: yup.string()
      .when("tutorName", {
        is: (name) => name,
        then: yup
            .string()
            .required(<IntlMessages id="core.fieldRequired" />)
        }
      ),
    tutorDocumentTypeId: yup.object().nullable()
      .when("tutorName", {
        is: (name) => name,
        then:  yup.object()
            .shape({ value: yup.string().required() })
            .required(<IntlMessages id="core.fieldRequired" />)
            .nullable()
        }
      ),
    tutorDocument: yup.string()
        .when("tutorName", {
            is: (name) => name,
            then: yup
                .string()
                .required(<IntlMessages id="core.fieldRequired" />)
            }
        )
}, [["tutorName", "tutorLastName"], ["tutorName","tutorDocumentTypeId"], ["tutorName","tutorDocument"]]);

export const getDataForm = (d) => {
    return  {
        name: d.name,
        lastName: d.lastName,
        documentTypeId: Number(d.documentTypeId.value),
        document:d.document,
        cellphone:d.cellphone,
        phone:d.phone,
        email:d.email,
        cityId: Number(d.cityId.value),
        productId: Number(d.productId.value),
        diseaseId: Number(d.diseaseId.value),
        tutorName: !d.tutorName ? null : d.tutorName,
        tutorLastName: !d.tutorLastName ? null : d.tutorLastName,
        tutorDocumentTypeId: !d.tutorDocumentTypeId ? null : Number(d.tutorDocumentTypeId.value),
        tutorDocument: !d.tutorDocument ? null : d.tutorDocument
    };
};

const CIForm = ({ methods, prescriptionId }) => {    
    const [infoConsent, setInfoConsent] = useState(null);
    const [cityList, setCityList] = useState([]);
    const [diseaseList, setDiseaseList] = useState([]);
    const [loadForm, setLoadForm] = useState({
        loadedCities: false,
        loadedDiseases: false
    });
    
    const { watch, setValue, errors } = methods;
    
    const stateProvinceIdValue = watch("stateProvinceId");
    const productIdValue = watch("productId");

    const loadInfo = (info) => {
        setValue("name", !info.name ? "" : info.name);
        setValue("lastName", !info.lastName ? "" : info.lastName);
        setValue("documentTypeId", !info.documentTypeId ? null : getCboValue(info.documentTypeList,info.documentTypeId));
        setValue("document", !info.document ? "" : info.document);
        setValue("cellphone", !info.cellphone ? "" : info.cellphone);
        setValue("phone", !info.phone ? "" : info.phone);
        setValue("email", !info.email ? "" : info.email);
        setValue("stateProvinceId", !info.stateProvinceId ? null : getCboValue(info.stateProvinceList,info.stateProvinceId));
        //city
        setValue("doctor", info.doctor);
        setValue("productId", !info.productId ? null : getCboValue(info.productList,info.productId));
        //Disease
        setValue("tutorName", !info.tutorName ? "" : info.tutorName);
        setValue("tutorLastName", !info.tutorLastName ? "" : info.tutorLastName);
        setValue("tutorDocumentTypeId", !info.tutorDocumentTypeId ? null : getCboValue(info.documentTypeList,info.tutorDocumentTypeId));
        setValue("tutorDocument", !info.tutorDocument ? "" : info.tutorDocument);
    };
    
    const loadCities = (stateProvinceId) => {
        callApi(
            () => getCitiesByStateProvinceId(stateProvinceId),
            (data) => {
                setCityList(data);
                if(!loadForm.loadedCities){
                    setValue("cityId", !infoConsent.cityId ? null : getCboValue(data,infoConsent.cityId));
                    setLoadForm({loadedCities: true, ...loadForm});
                }
            }
        );
    };

    const loadDiseases = (productId) => {
        callApi(
            () => getDiseasesByProductId(productId),
            (data) => {
                setDiseaseList(data);
                if(!loadForm.loadedDiseases){
                    setValue("diseaseId", !infoConsent.diseaseId ? null : getCboValue(data,infoConsent.diseaseId));
                    setLoadForm({loadedDiseases: true, ...loadForm});
                }
            }
        );
    };

    useEffect(()=>{
        callApi(
            () => getInfoConsent(prescriptionId),
            (data)=>{
                setInfoConsent(data);
                loadInfo(data);
            }
          );
    },[]);

    useEffect(() => {
        setValue("cityId", null);
        if (!stateProvinceIdValue || !stateProvinceIdValue.value) {
            setCityList([]);
            return;
        }
        loadCities(stateProvinceIdValue.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateProvinceIdValue]);

    useEffect(() => {
        setValue("diseaseId", null);
        if (!productIdValue || !productIdValue.value) {
            setDiseaseList([]);
            return;
        }
        loadDiseases(productIdValue.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productIdValue]);
    
    return (
        <FormProvider {...methods}>
        <form className="row" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="name"
                label={<IntlMessages id="core.name" />}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="lastName"
                label={<IntlMessages id="core.lastName" />}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="documentTypeId"
                label={<IntlMessages id="core.documentType" />}
                options={!infoConsent ? [] : infoConsent.documentTypeList}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="document"
                label={<IntlMessages id="core.document" />}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="phone"
                label={<IntlMessages id="core.phone" />}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="cellphone"
                label={<IntlMessages id="core.cellphone" />}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="email"
                label={<IntlMessages id="core.email" />}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="stateProvinceId"
                label={<IntlMessages id="core.stateProvince" />}
                options={!infoConsent ? [] : infoConsent.stateProvinceList}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="cityId"
                label={<IntlMessages id="core.city" />}
                options={cityList}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="doctor"
                label={<IntlMessages id="core.doctor" />}
                disabled={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                    name="diseaseId"
                    label={<IntlMessages id="formula.diagnostic" />}
                    options={diseaseList}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                    name="productId"
                    label={<IntlMessages id="formula.product" />}
                    options={!infoConsent ? [] : infoConsent.productList}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="tutorName"
                label={<IntlMessages id="ci.tutorName" />}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="tutorLastName"
                label={<IntlMessages id="ci.tutorLastName" />}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="tutorDocumentTypeId"
                label={<IntlMessages id="ci.tutorDocumentType" />}
                options={!infoConsent ? [] : infoConsent.documentTypeList}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="tutorDocument"
                label={<IntlMessages id="ci.tutorDocument" />}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    );
};

export default CIForm;