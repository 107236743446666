import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import FormSelectAutoComplete, { getCboValue }  from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import FormDatePicker from "components/Form/Controls/datepicker";
import DialogAsync from "components/Form/DialogAsync";
import AddPatient from "../addPatient";
import { 
  callApi, 
  callApiAsync, 
  getPrescriptionInfo, 
  postPrescriptionAdd
} from "api/apiList";

const AddPrescription = ({openDlg, closeDialog, callBack, prescriptionId, allowEdit=true}) =>{ 
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [dlgAddPatient, setDlgAddPatient] = useState(false);
  const [statusList, setStatusList] = useState([]);

  const defaultValues = {
    patientId:null,
    code:"",
    expeditionDate:null,
    expirationDate:null,
    insuranceId:null,
    productId:null,
    medicineAmount:"",
    unitValue:"",
    cityId:null,
    marketchainId:null,
    numberDeliveries:"",
    statusId: null
  };

  const [prescriptionInfo, setPrescriptionInfo] = useState({
    ...defaultValues,
    patients: [],
    products: [],
    cities: [],
    marketchains: [],
    insurances: [],
    prescriptionStatusList: []
  });

  function transformNumber(value, originalValue) {
    if(!originalValue || originalValue.trim() === '') return 0;
    
    const n = Number(originalValue);
    return (n !== 0 && !n) ? -1 : n;
  }
  const validationSchema = {
    patientId: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    code: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    insuranceId: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    productId: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    cityId: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    marketchainId: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    medicineAmount: yup.number()
      .typeError(IntlGetText("yup.invalidInput"))
      .required(<IntlMessages id="core.fieldRequired" />)
      .min(1, IntlGetText("yup.invalidInput")).nullable(),
    numberDeliveries: yup.number()
      .typeError(IntlGetText("yup.invalidInput"))
      .required(<IntlMessages id="core.fieldRequired" />)
      .min(1, IntlGetText("yup.invalidInput")).nullable(),
    // unitValue: yup.number()
    //   .transform(transformNumber)
    //   .min(0, IntlGetText("yup.invalidInput"))
    //   .nullable()
    // unitValue: yup.number().when("code", (code) => {
    //   return yup.number()
    //     .transform(transformNumber)
    //     .min(0, IntlGetText("yup.invalidInput"))
    //     .nullable()
    // }),
  };
  if(!prescriptionId){
    validationSchema.statusId = yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable();
  }

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, errors } = methods;
  
  const setString = (obj, prop) =>
    setValue(prop, !obj || !obj[prop] ? "" : obj[prop]);
  const setDate = (obj, prop) =>
    setValue(prop, !obj || !obj[prop] ? null : dayjs(obj[prop]));
  const setCboValue = (obj, prop, list) => setValue(prop, !obj ? null : getCboValue(list, obj[prop]));
    
  const loadData=(obj)=>{
    setCboValue(obj, "patientId", obj.patients);    
    setString(obj, "code");
    setDate(obj,"expeditionDate");
    setDate(obj,"expirationDate");
    setCboValue(obj, "insuranceId", obj.insurances);
    setCboValue(obj, "productId", obj.products);
    setString(obj, "medicineAmount");
    setString(obj, "numberDeliveries");
    //setString(obj, "unitValue");
    //setValue("unitValue", !obj || !obj["unitValue"] ? "" : Number(obj["unitValue"]))
    
    setUnitValue(obj.productId, obj.products);
    setCboValue(obj, "cityId", obj.cities);
    setCboValue(obj, "marketchainId", obj.marketchains);

    if(!prescriptionId)
      setValue("statusId",getCboValue(obj.prescriptionStatusList, 1));
  };

  const reloadPatients = () => {
    callApi(
      ()=>getPrescriptionInfo(prescriptionId),
      (data)=>{
        setPrescriptionInfo({...prescriptionInfo, patients: data.patients});
        var maxId = Math.max(...data.patients.map((x) => x.id));
        setValue("patientId", getCboValue(data.patients, maxId));
      },
      fnSetLoader
    );
  };

  useEffect(() => {
    if(!openDlg)
      return;
    
    callApi(
      ()=>getPrescriptionInfo(prescriptionId),
      (data)=>{
        setPrescriptionInfo(data);
        setTimeout(() => loadData(data), 130);
      },
      fnSetLoader
    );
  }, [openDlg]);

  const setUnitValue =(productId, productList)=>{
    const product = productList.find(x=>x.id === productId);
    if(!product) return;

    setValue("unitValue", product.unitValue ? product.unitValue : "");
  }

  const cboHandleChange = (name) => (d) => {
    setValue(name, d);
    if(!d)
      return;
    setUnitValue(d.value, prescriptionInfo.products);
  };

  const onSubmitAsync = handleSubmit(async (d)=>{
    const obj = {
      id:prescriptionId,
      patientId:Number(d.patientId.value),
      code:d.code,
      expeditionDate:!d.expeditionDate ? null : dayjs(d.expeditionDate).format("YYYY-MM-DDTHH:mm:ss"),
      expirationDate:!d.expirationDate ? null : dayjs(d.expirationDate).format("YYYY-MM-DDTHH:mm:ss"),
      insuranceId:Number(d.insuranceId.value),
      productId:Number(d.productId.value),
      medicineAmount:Number(d.medicineAmount),
      numberDeliveries:Number(d.numberDeliveries),
      cityId:Number(d.cityId.value),
      marketchainId:Number(d.marketchainId.value),
      statusId: !d.statusId ? 0 : Number(d.statusId.value)
    };

    await callApiAsync(
      ()=>postPrescriptionAdd(obj),
      ()=>{
        if(callBack)
          callBack();
        closeDialog();
      }
    );
  });

  const disabled = !allowEdit;

  return (
    <div>
      <DialogAsync
        open={openDlg}
        closeDialog={closeDialog}
        onSubmitAsync={onSubmitAsync}
        titleTextId={prescriptionId ? "core.EditPrescription" : "core.AddPrescription"}
        allowEdit={allowEdit}
      >
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <FormSelectAutoComplete
                  name="cityId"
                  label={<IntlMessages id="core.city" />}
                  options={prescriptionInfo.cities}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={10} lg={10}>
                <FormSelectAutoComplete
                  name="patientId"
                  label={<IntlMessages id="core.patient" />}
                  options={prescriptionInfo.patients}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              {!disabled &&
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <div className="mt-3"> 
                    <Tooltip title={<IntlMessages id="core.add" />} placement="bottom-end">
                      <IconButton
                        type="button"
                        className="icon-btn"
                        color="primary"
                        onClick={() => setDlgAddPatient(true)}
                      >
                        <i className="zmdi zmdi-plus-circle zmdi-hc-fw" />
                      </IconButton>
                    </Tooltip>
                    <AddPatient 
                      openDlg={dlgAddPatient} 
                      setOpenDlg={setDlgAddPatient}                    
                      closeDialog = {()=>setDlgAddPatient(false)}
                      callBack={reloadPatients}
                      patientId={0}/>
                  </div>
                </Grid>
              }
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="code"
                  label={<IntlMessages id="core.mipres" />}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormDatePicker
                  name="expeditionDate"
                  label={<IntlMessages id="core.expeditionDate" />}
                  format="YYYY-MM-DD"
                  margin="normal"
                  disableFuture={true}
                  disabled={disabled}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormDatePicker
                  name="expirationDate"
                  label={<IntlMessages id="core.expirationDateFormula" />}
                  format="YYYY-MM-DD"
                  margin="normal"
                  //disableFuture={true}
                  disabled={disabled}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormSelectAutoComplete
                  name="insuranceId"
                  label={<IntlMessages id="core.insurance" />}
                  options={prescriptionInfo.insurances}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormSelectAutoComplete
                  name="marketchainId"
                  label={<IntlMessages id="core.client" />}
                  options={prescriptionInfo.marketchains}
                  margin="normal"
                  disabled={disabled}
                  //required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="productId"
                  label={<IntlMessages id="core.product" />}
                  options={prescriptionInfo.products}                  
                  value={watch("productId")}
                  onChange={cboHandleChange("productId")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="unitValue"
                  label={<IntlMessages id="core.unitValue" />}
                  margin="normal"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="numberDeliveries"
                  label={<IntlMessages id="insuranceAuthorization.deliveryNumber" />}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="medicineAmount"
                  label={<IntlMessages id="core.totalAmount" />}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              {!prescriptionId && 
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="statusId"
                    label={<IntlMessages id="core.state" />}
                    options={prescriptionInfo.prescriptionStatusList}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
              }
            </Grid>
          </form>
        </FormProvider>            
      </DialogAsync>
    </div>
  );
};

export default AddPrescription;