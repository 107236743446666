import React from "react";
import * as dayjs from "dayjs";
import { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CardBox from "components/CardBox/index";
import { AlertStateList, AlertTypeList, AlertStateEnum } from "constants/Enums";

const TableAM = ({ data, handleOpenCRU, setCurrentRowObj }) => {
  const alertTypeList = AlertTypeList();
  const alertStateList = AlertStateList();

  const datatableConfig = {
    textLabels: {
      body: {
        noMatch: "No hay resultados para la búsqueda actual",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordernar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Items por pagina:",
        displayRows: "de",
      },
      toolbar: {
        search: "Busqueda",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtro",
      },
      filter: {
        all: "Todos",
        title: "FILTRO",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas",
      },
      selectedRows: {
        text: "item(s) seleccionados",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    },
  };

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    search: false,
    viewColumns: false,
    textLabels: datatableConfig.textLabels,
  };

  const handleClick = (tableMeta) => {
    handleOpenCRU();
    setCurrentRowObj(
      !tableMeta ? null : tableMeta.tableData[tableMeta.rowIndex]
    );
  };

  const getAlertType = (item) => {
    if (!item) return "";
    return item.name;
  };

  const getColorByState = (state) => {
    if(state == AlertStateEnum.done)
      return "bg-green text-white";
    else if(state == AlertStateEnum.pending)
      return "bg-red text-white";

    return "";
  };

  const columns = [
    {
      name: "creationDate",
      label: IntlGetText("core.creationDate"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    {
      name: "alertType",
      label: IntlGetText("alert.alertType"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value == undefined || value == null
            ? ""
            : getAlertType(alertTypeList.find((x) => x.id == value));
        },
      },
    },
    {
      name: "state",
      label: IntlGetText("alert.state"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value == undefined || value == null
            ? ""
            : alertStateList.find((x) => x.id == value).name;
        },
      },
    },
    {
      name: "comment",
      label: IntlGetText("alert.comment"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "userName",
      label: IntlGetText("alert.userName"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = tableMeta.tableData[tableMeta.rowIndex];
          return (
            <div>
              <Button
                onClick={() => handleClick(tableMeta)}
                variant="contained"
                className={"jr-btn jr-btn-xs "+getColorByState(rowObj.state)}
              >
                <i className="zmdi zmdi-eye"></i>
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="row">
      <CardBox styleName="col-12" cardStyle="p-0 border-dialog-card">
        <MUIDataTable
          className="border-dialog-card"
          data={data}
          columns={columns}
          options={options}
        />
      </CardBox>
    </div>
  );
};

export default TableAM;
