import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import * as dayjs from "dayjs";
import { IntlGetText } from "util/IntlMessages";
import LabelsSection from "../labelsSection";
import DialogRescheduleImpact from "./DialogRescheduleImpact";
import DialogPlanningImpact from "./DialogPlanningImpact";
import { setSelectedPrescription, loadPrescriptions } from "actions/Management";
import {
  callApi,
  callApiAsync,
  GetCommentsByType,
  postRescheduleImpact,
  getPrescriptions,
  postPlanImpact
} from "api/apiList";
import { FolderType, PrescripStateEnum } from "constants/Enums";

const PrescriptionList = () => {
  const dispatch = useDispatch();
  const {
    prescriptionsByMenuItem,
    searchText,
    selectedProductLine,
    selectedPrescriptionState,
    selectedMenuItem
  } = useSelector(({ management }) => management);

  const [prescriptionsList, setPrescriptionsList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDlgPlanning, setOpenDlgPlanning] = useState(false);

  const [commentsList, setCommentsList] = useState([]);
  const [prescriptionReschedule, setPrescriptionReschedule] = useState({});

  const patientName = IntlGetText("core.newPatient");
  const msgNoPlanned = IntlGetText("impact.NoPlanned");

  const filterPrescriptions = (prescriptions) => {
    if (searchText && searchText.length > 0) {
      if (selectedMenuItem === 4) {
        prescriptions = prescriptions.filter(
          (item) =>
            item.eventTraining.name
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) > -1
        );
      } else {
        prescriptions = prescriptions.filter(
          (item) =>
            item.doctor.name.toLowerCase().indexOf(searchText.toLowerCase()) >
              -1 ||
            (item.patient &&
              item.patient.name
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1) ||
            (item.patient &&
              item.patient.code &&
              item.patient.code
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1) ||
            (item.patient &&
              item.patient.document &&
              item.patient.document
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1) ||
            (item.patient &&
              item.patient.lastName
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1) ||
            (item.patient &&
              item.patient.phone &&
              item.patient.phone
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1)
        );
      }
    }
    if (selectedProductLine && selectedProductLine >= 0) {
      prescriptions = prescriptions.filter(
        (item) => item.productLineId === selectedProductLine
      );
    }
    if (selectedPrescriptionState && selectedPrescriptionState >= 0) {
      if (selectedMenuItem === FolderType.tracing) {
        prescriptions = prescriptions.filter(
          (item) => item.impactTypeId === selectedPrescriptionState
        );
      } else if (selectedMenuItem === FolderType.adverseEvents) {
        prescriptions = prescriptions.filter(
          (item) =>
            item.adverseEvent.adverseEventTypeId === selectedPrescriptionState
        );
      } else {
        prescriptions = prescriptions.filter(
          (item) => item.stateId === selectedPrescriptionState
        );
      }
    }
    setPrescriptionsList(prescriptions);
  };

  useEffect(() => {
    callApi(() => GetCommentsByType(0), setCommentsList);
  }, []);

  useEffect(() => {
    filterPrescriptions(prescriptionsByMenuItem);
  }, [
    prescriptionsByMenuItem,
    searchText,
    selectedProductLine,
    selectedPrescriptionState
  ]);

  const handleRequestClose = () => {
    setOpen(false);
  };
  const handleRequestCloseDlgPlanning = () => setOpenDlgPlanning(false);

  const callRescheduleImpact = (obj) => {
    callApi(
      () => postRescheduleImpact(obj),
      (data) => {
        handleRequestClose();
        callApi(
          () => getPrescriptions(FolderType.newPatients),
          (data) => {
            dispatch(loadPrescriptions(data, 0));
            //filterPrescriptions(data);
          }
        );
      }
    );
  };

  const callPlanImpact = async (obj) => {
    await callApiAsync(
      () => postPlanImpact(obj),
      (data) => {
        handleRequestCloseDlgPlanning();
        callApi(
          () => getPrescriptions(selectedMenuItem),
          (data) => {
            dispatch(loadPrescriptions(data, selectedMenuItem));
            //filterPrescriptions(data);
          }
        );
      }
    );
  };

  const onSelectItem = (e, prescription) => {
    if (
      prescription.stateId >= PrescripStateEnum.fristImpact &&
      !prescription.firstImpactDate &&
      selectedMenuItem !== FolderType.adverseEvents
    ) {
      NotificationManager.warning(msgNoPlanned);
      return;
    }
    dispatch(setSelectedPrescription(prescription));
  };

  const onClickDate = (e, prescription) => {
    // e.preventDefault();
    // e.stopPropagation();
    // e.nativeEvent.stopImmediatePropagation();

    if (
      prescription.stateId < PrescripStateEnum.fristImpact ||
      selectedMenuItem === FolderType.adverseEvents
    ) {
      return;
    }

    if (
      !(
        prescription.stateId == PrescripStateEnum.fristImpact &&
        prescription.firstImpactDate
      )
    ) {
      if (!prescription.firstImpactDate) {
        setPrescriptionReschedule(prescription);
        setOpenDlgPlanning(true);
      }
      return;
    }

    setPrescriptionReschedule(prescription);
    setOpen(true);
  };

  const getPatientName = (prescription) => {
    if (prescription.patient)
      return `${prescription.patient.code} - ${prescription.patient.name} ${
        prescription.patient.lastName
      } ${
        prescription.patient.phone ? " - " + prescription.patient.phone : ""
      } `;

    if (prescription.eventTraining)
      return prescription.eventTraining.name.toUpperCase();
    return patientName;
  };

  return !prescriptionsList.map ? (
    ""
  ) : (
    <div>
      <DialogRescheduleImpact
        open={open}
        handleRequestClose={handleRequestClose}
        commentsList={commentsList}
        prescription2={prescriptionReschedule}
        callRescheduleImpact={callRescheduleImpact}
      />
      <DialogPlanningImpact
        open={openDlgPlanning}
        handleRequestClose={handleRequestCloseDlgPlanning}
        prescription={prescriptionReschedule}
        callPlanImpact={callPlanImpact}
      />
      {prescriptionsList.map((prescription, index) => {
        return (
          <div key={index} className="module-list-item mail-cell">
            <div className="mail-user-info">
              <div
                className="bg-cyan avatar rounded-circle size-40 text-white text-center"
                style={{ fontSize: 16 }}
              >
                {prescription.patient
                  ? prescription.patient.name.charAt(0).toUpperCase()
                  : patientName.charAt(0).toUpperCase()}
              </div>
            </div>
            <div
              className="module-list-info"
              // onClick={(e) => {
              //   onSelectItem(e,prescription);
              // }}
            >
              <div className="module-list-content">
                <div className="mail-user-info mb-2">
                  <span
                    className="sender-name text-dark"
                    onClick={(e) => {
                      onSelectItem(e, prescription);
                    }}
                  >
                    {getPatientName(prescription)}
                  </span>

                  {selectedMenuItem === FolderType.trainingsAndEvents &&
                    prescription.eventTraining.typeId !== 2 && (
                      <span className="toolbar-separator" />
                    )}

                  <span
                    className="d-inline-block text-truncate text-dark"
                    style={{ maxWidth: "calc(100% - 220px)" }}
                    onClick={(e) => {
                      onSelectItem(e, prescription);
                    }}
                  >
                    {prescription.doctor && prescription.doctor.name}
                  </span>
                  {/* {mail.hasAttachments && <i className="zmdi zmdi-attachment" />} */}

                  <div
                    className="time"
                    onClick={(e) => {
                      onClickDate(e, prescription);
                    }}
                  >
                    {prescription.firstImpactDate &&
                      dayjs(prescription.firstImpactDate).format("YYYY/MM/DD")}

                    {!prescription.firstImpactDate &&
                      (selectedMenuItem == FolderType.inProcess ||
                        selectedMenuItem == FolderType.tracing) && (
                        <i className="zmdi zmdi-calendar-alt animated infinite wobble text-cyan zmdi-hc-lg" />
                      )}

                    {selectedMenuItem === FolderType.adverseEvents &&
                      dayjs(prescription.adverseEvent.creationDate).format(
                        "YYYY/MM/DD"
                      )}

                    {selectedMenuItem === FolderType.trainingsAndEvents &&
                      prescription.eventTraining.typeId !== 2 &&
                      dayjs(prescription.eventTraining.trainingDate).format(
                        "YYYY/MM/DD"
                      )}
                  </div>
                </div>
                <div
                  onClick={(e) => {
                    onSelectItem(e, prescription);
                  }}
                >
                  <LabelsSection prescription={prescription} />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PrescriptionList;
