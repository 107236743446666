import React from "react";
import IntlMessages from "util/IntlMessages";
import { Button, Grid, Card, CardContent, Typography } from "@material-ui/core";

const CardTotal = ({title, subtitleId}) => {
  return (
    
    // <div className={`jr-card jr-hr-chart-card p-0`}>
    //   <div className="row no-gutters align-items-center">
    //     <div className="col-12 p-3">
    //       <div className="jr-hr-chart-content" align="center">
    //         <span className="d-block jr-fs-xxl font-weight-medium mb-1">
    //           {"price"}
    //         </span>
    //         <span className="d-block jr-fs-13">{"detail"}</span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="jr-card">
      <Typography component="div" variant="h4" align="center">
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        component="div"
        align="center"
      >
        <IntlMessages id={subtitleId} />
      </Typography>
    </div>
  );
};

export default CardTotal;
