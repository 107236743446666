import React from "react";
import IntlMessages, { IntlGetText }  from "util/IntlMessages";
import * as dayjs from "dayjs";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import Table, { getTextLabels, defaultCol } from "components/Form/Table";


const DeliveryDetail = ({data, openDlg, handleRequestClose}) => {
  const getObj = (tableMeta) => data.find(x=>x.id === tableMeta.rowData[0]);

  const columns =  [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display:"excluded"
      },
    },
    defaultCol("lotNumber", "medicineDelivery.lotNumber"),
    {
      name: "expirationDate",
      label: IntlGetText("medicineDelivery.expirationDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
  ];
  
  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    pagination:false,
    textLabels: getTextLabels()
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openDlg}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="core.deliveryDetail"/>
        </DialogTitle>
        <DialogContent>
          <Table
            className="border-dialog-card"
            data={data}
            columns={columns}
            options={options} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close"/>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};    

export default DeliveryDetail;