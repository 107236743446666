import React from "react";
import { useTheme } from '@material-ui/core/styles';

const Footer = () => {
  const theme = useTheme();
  return (
    <footer className="app-footer">
      <span className="d-inline-block">{theme.footerText}</span>
    </footer>
  );
};
export default Footer;
