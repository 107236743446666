export const dataProgramAdherence = [
  { name: "Aug", uv: 65.7 },
  { name: "Sep", uv: 78.2 },
  { name: "Oct", uv: 96.7 },
  { name: "Nov", uv: 93.3 },
  { name: "Dec", uv: 94.5 },
  { name: "Ene", uv: 90.7 },
  { name: "Feb", uv: 90.6 },
  { name: "Mar", uv: 93.84 },
  { name: "Apr", uv: 92.6 },
  { name: "May", uv: 93.8 },
];

export const dataPharmaceuticalAdherence = [
  { name: "Aug-19", uv: 37 },
  { name: "Sep-19", uv: 41 },
  { name: "Oct-19", uv: 48 },
  { name: "Nov-19", uv: 52 },
  { name: "Dec-19", uv: 60 },
  { name: "Jan-19", uv: 58 },
  { name: "Feb-19", uv: 58 },
  { name: "Mar-19", uv: 63 },
  { name: "Apr-19", uv: 67 },
  { name: "May-19", uv: 71 },
];

export const dataActivePatients = [
  { name: "15 mg diarios", uv: 9 },
  { name: "30 mg diarios", uv: 33 },
  { name: "45 mg diarios", uv: 29 },
];

export const data = [
  { name: "Especial", value: 4.5 },
  { name: "Subsidiado", value: 29.9 },
  { name: "Contributivo", value: 65.7 },
];
