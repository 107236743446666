import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Input from '@material-ui/core/Input';
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Button from "@material-ui/core/Button";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import { hideMessage, showAuthLoader, userSignIn } from "actions/Auth";
import { useTheme } from "@material-ui/core/styles";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

const SignIn = (props) => {
  const theme = useTheme();
  const msgLoginInvalid = IntlGetText("login.loginInvalid");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      props.history.push("/");
    }
  }, [showMessage, authUser, props.history, dispatch]);

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="Jambo">
            <img
              style={{ width: "100%" }}
              src={theme.imgLogin.src}
              alt={theme.imgLogin.alt}
              title={theme.imgLogin.title}
            />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>
              <IntlMessages id="appModule.login" />
            </h1>
          </div>

          <div className="app-login-form">
            <form>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  onChange={(event) => setEmail(event.target.value)}
                  defaultValue={email}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <Input
                  type={showPassword ? "text" : "password"}
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  onChange={(event) => setPassword(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  className="mt-1 my-sm-3"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button
                    onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userSignIn({ email, password }));
                    }}
                    variant="contained"
                    color="primary"
                  >
                    <IntlMessages id="appModule.signIn" />
                  </Button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
      {showMessage &&
        NotificationManager.error(
          alertMessage === "401" ? msgLoginInvalid : alertMessage
        )}
      {/* <NotificationContainer /> */}
    </div>
  );
};

export default SignIn;
