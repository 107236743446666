import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { setLoader } from "actions/Base";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages from "util/IntlMessages";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import NurseTable from "./nurseTable";
import { DialogConfirmAsync } from "components/Form/Controls/DialogConfirm";
import {
  callApi,
  callApiAsync,
  getNursesByPrescription,
  postAddNurseToPrescription,
  deleteNurseToPrescription,
} from "api/apiList";

const DlgChangeNurse = ({ openCRU, handleRequestClose, nurseList, obj}) => {  
  const dispatch = useDispatch();  
  const fnSetLoader = (data) => dispatch(setLoader(data));

  const [currentNurses, setCurrentNurses] = useState([]);
  const [nurses,setNurses] = useState([]);
  const [openDeleteDlg, setOpenDeleteDlg] = useState(false);
  const [currentRowObj, setCurrentRowObj] = useState(null);

  const defaultValues = {
    nurseId: null,
  };
  const validationSchema = { 
    nurseId: yup
    .object()
    .shape({ value: yup.string().required() })
    .required(<IntlMessages id="core.fieldRequired" />)
    .nullable()
  };

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, errors } = methods;

  const loadData = () => {
    setValue("patient", !obj || !obj.patient ? "" : obj.patientCode +" - "+ obj.patient);
  };

  useEffect(()=>{
    if(openCRU){
      setNurses(nurseList.filter(x=> currentNurses.find(y=>y.id === x.id) === undefined))
      setTimeout(loadData,150);
    }
  },[openCRU,currentNurses]);


  const onSubmit = async (d, e) => {
    var item = {
      prescriptionId: !obj ? 0 : obj.prescriptionId,
      nurseId: d.nurseId.value
    };

    await callApiAsync(
      () => postAddNurseToPrescription(item),
      (data)=>{
        setCurrentNurses(data);
        setValue("nurseId", null);
      },
      fnSetLoader
    );
  };

  useEffect(()=>{
    if(!openCRU) return;

    callApi(
      ()=>getNursesByPrescription(obj.prescriptionId),
      setCurrentNurses,
      fnSetLoader
    )
  },[openCRU]);

  const onDeleteAsync = async () => {
    await callApiAsync(
      () => deleteNurseToPrescription(currentRowObj.nursePrescriptionId),
      (data) => {
        setCurrentNurses(data);
        setOpenDeleteDlg(false);
      },
      fnSetLoader
    );
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id="core.reassignPatient" />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <FormInput
                    name="patient"
                    label={<IntlMessages id="core.patient" />}
                    margin="normal"
                    disabled
                    />
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <FormSelectAutoComplete
                    name="nurseId"
                    label={<IntlMessages id="role.nurse" />}
                    options={nurses}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <ButtonAsync onClick={handleSubmit(onSubmit)}
                    className="mt-3"
                    variant="contained"
                    color="primary"
                  >
                    {<IntlMessages id="core.save" />}
                  </ButtonAsync>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          <NurseTable
            data={currentNurses}
            handleOpenDel={() => setOpenDeleteDlg(true)}
            setCurrentRowObj={setCurrentRowObj}/>
          <DialogConfirmAsync
            openDialog={openDeleteDlg}
            title={<IntlMessages id="changeNurse.dlgDeleteTitle" />}
            text={<IntlMessages id="changeNurse.dlgDeleteText" />}
            handleRequestClose={() => setOpenDeleteDlg(false)}
            onAcceptClickAsync={onDeleteAsync}
          />
        </div>  
      </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>  
  );
};
export default DlgChangeNurse;
