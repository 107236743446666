import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormInput from "components/Form/Controls/input";
import DialogConfirm from "components/Form/Controls/DialogConfirm";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  callApi,
  callApiAsync,
  postApproveFormula,
  postCancelFormula,
} from "api/apiList";
import { PrescripStateEnum } from "constants/Enums";
import {
  setPrescriptionState,
  updateSelectedPrescription,
} from "actions/Management";
import { setFormFormulaDone } from "actions/Impact";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const DialogReject = ({ open, dialogTitle, callBack, closeDialog }) => {
  const validationSchema = yup.object().shape({
    comment: yup.string().required(<IntlMessages id="core.fieldRequired" />),
  });

  const defaultValues = { comment: "" };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, errors, reset } = methods;

  const onSubmit = async (data, e) => {
    await callBack(data.comment);
  };

  const handleRequestClose = () => {
    reset(defaultValues);
    closeDialog();
  };
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent className="new-patient-dialog-content">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                label={<IntlMessages id="core.comment" />}
                name="comment"
                required={true}
                multiline
                rows={5}
                variant="outlined"
                margin="normal"
                errorobj={errors}
              />
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          {<IntlMessages id="core.cancel" />}
        </Button>
        <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
          {<IntlMessages id="core.save" />}
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  );
};

const ApproveFormula = ({ formulaData, reloadFormulaData }) => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);

  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogReject, setOpenDialogReject] = useState(false);

  const handleRequestClose = () => setOpenDialog(false);
  const handleRequestCloseReject = () => setOpenDialogReject(false);

  const onApproveClick = () => setOpenDialog(true);
  const onRejectClick = () => setOpenDialogReject(true);
  const onCancelClick = () => setOpenDialog(true);

  const approve = () => {
    callApi(
      () =>
        postApproveFormula({
          prescriptionId: formulaData.id,
          approved: true,
          commentRejected: null,
        }),
      () => {
        handleRequestClose();
        dispatch(setPrescriptionState(PrescripStateEnum.radicacionEPS));
      }
    );
  };

  const reject = async (commentRejected) => {
    await callApiAsync(
      () =>
        postApproveFormula({
          prescriptionId: formulaData.id,
          approved: false,
          commentRejected,
        }),
      () => {
        handleRequestCloseReject();
        dispatch(setPrescriptionState(PrescripStateEnum.formulatedWithErrors));
      }
    );
  };

  const cancel = () => {
    callApi(
      () =>
        postCancelFormula({
          prescriptionId: formulaData.id,
          impactId: selectedPrescription.impactId,
        }),
      (data) => {
        handleRequestClose();
        dispatch(updateSelectedPrescription(data));
        dispatch(setFormFormulaDone(null));
        reloadFormulaData();
      }
    );
  };

  if (selectedPrescription.stateId == PrescripStateEnum.fristImpact) {
    return "";
  }

  if (selectedPrescription.stateId == PrescripStateEnum.formulated) {
    const disabled = !(
      formulaData &&
      formulaData.expeditionDate //&& formulaData.file
    );
    return (
      <div className="col-12 mt-1 mb-4 text-right">
        <Tooltip title={IntlGetText("core.approve")} placement="bottom-end">          
          <Button
            style={{ minWidth: "auto" }}
            onClick={onApproveClick}
            variant="contained"
            className="jr-btn bg-green text-white"
            //   className={classes.margin}
            disabled={disabled}
          >
            <i className="zmdi zmdi-thumb-up zmdi-hc-fw"></i>
          </Button>
        </Tooltip>
        <Tooltip title={IntlGetText("core.reject")} placement="bottom-end">
          <Button
            style={{ minWidth: "auto" }}
            onClick={onRejectClick}
            variant="contained"
            className="jr-btn bg-red text-white"
            //   className={classes.margin}
            disabled={disabled}
          >
            <i className="zmdi zmdi-thumb-down zmdi-hc-fw"></i>
          </Button>
        </Tooltip>
        <DialogConfirm
          openDialog={openDialog}
          title={<IntlMessages id="approveFormula.dialogConfirmTitle" />}
          text={<IntlMessages id="approveFormula.approve" />}
          handleRequestClose={handleRequestClose}
          onAcceptClick={approve}
        />
        <DialogReject
          open={openDialogReject}
          dialogTitle={<IntlMessages id="approveFormula.dialogRejectTitle" />}
          callBack={reject}
          closeDialog={handleRequestCloseReject}
        />
      </div>
    );
  }

  if (selectedPrescription.stateId == PrescripStateEnum.formulatedWithErrors) {
    return (
      <div className="col-xl-12 col-lg-12 col-md-12 col-12">
        <div className="row">
          <Tooltip title={IntlGetText("formula.cancel")} placement="bottom-end">
            <Button
              style={{ minWidth: "auto" }}
              onClick={onCancelClick}
              variant="contained"
              color="primary"
              className={classes.margin}
            >
              <IntlMessages id="core.cancel" />
            </Button>
          </Tooltip>
          <DialogConfirm
            openDialog={openDialog}
            title={<IntlMessages id="approveFormula.dialogConfirmTitle" />}
            text={<IntlMessages id="approveFormula.cancel" />}
            handleRequestClose={handleRequestClose}
            onAcceptClick={cancel}
          />
        </div>
      </div>
    );
  }

  return ""; //Approved
};

export default ApproveFormula;
