import React, { PureComponent } from "react";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
} from "recharts";

import {
  dataProgramAdherence,
  dataPharmaceuticalAdherence,
  dataActivePatients,
} from "./data";

import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import CardBox from "components/CardBox";

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text
        x={x}
        y={y}
        dy={-10}
        fill={stroke}
        fontSize={10}
        textAnchor="middle"
      >
        {value + "%"}
      </text>
    );
  }
}

class CustomizedLabel2 extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text
        x={x}
        y={y}
        dy={-10}
        fill={stroke}
        fontSize={10}
        textAnchor="middle"
      >
        {value}
      </text>
    );
  }
}

const remodulin = (props) => {
  return (
    <div className="app-wrapper">
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <ContainerHeader
          match={props.match}
          title={<IntlMessages id="kpi.remodulin" />}
        />
        <div className="row">
          <CardBox styleName="col-md-4 text-center">
            <ResponsiveContainer width="100%" height={240}>
              <img
                className=""
                src={require("assets/images/psp/kpi/remodulin-img1.PNG")}
                alt="Remodulin"
                title="Romodulin"
              />
            </ResponsiveContainer>
          </CardBox>

          <CardBox
            heading={<IntlMessages id="kpi.remodulin.programGrowth" />}
            styleName="col-md-8 text-center"
          >
            <ResponsiveContainer width="100%" height={200}>
              <LineChart
                data={dataPharmaceuticalAdherence}
                margin={{ top: 20, right: 0, left: -25, bottom: 0 }}
              >
                <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="uv"
                  stroke="#3367d6"
                  fill="#3367d6"
                  name="Pacientes en el programa"
                  activeDot={{ r: 8 }}
                  label={<CustomizedLabel2 />}
                />
                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#00bcd4"
                  fill="#00bcd4"
                  name="Drop-out"
                />
                <Line
                  type="monotone"
                  dataKey="amt"
                  stroke="#ffc658"
                  fill="#ffc658"
                  name="Drop-in"
                />
              </LineChart>
            </ResponsiveContainer>
          </CardBox>
        </div>
        <div className="row">
          <CardBox
            heading={
              <IntlMessages id="kpi.remodulin.chartActivePatients.title" />
            }
            styleName="col-md-4 text-center"
          >
            <ResponsiveContainer width="100%" height={200}>
              <BarChart
                data={dataActivePatients}
                margin={{ top: 20, right: 0, left: -25, bottom: 0 }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Bar dataKey="uv" fill="#3367d6" name="Pacientes Activos">
                  <LabelList dataKey="uv" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </CardBox>
          <CardBox
            heading={
              <IntlMessages id="kpi.remodulin.pharmaceuticalAdherence" />
            }
            styleName="col-md-8 text-center"
          >
            <ResponsiveContainer width="100%" height={200}>
              <LineChart
                data={dataProgramAdherence}
                margin={{ top: 20, right: 0, left: -25, bottom: 0 }}
              >
                <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="uv"
                  stroke="#3367d6"
                  fill="#3367d6"
                  name="Pacientes Activos"
                  label={<CustomizedLabel />}
                />
              </LineChart>
            </ResponsiveContainer>
          </CardBox>
        </div>
      </div>
    </div>
  );
};

export default remodulin;
