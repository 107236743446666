import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import MUIDataTable from "mui-datatables";
import CardBox from "components/CardBox/index";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoCard from "components/InfoCard/index";
import { callActionApi, getEducationalMaterial } from "api/apiList";
import { callStartImpact } from "actions/Impact";

const processData = (data) => {
  const onClick = (fileLink) => () => {
    const link = document.createElement("a");
    link.href = fileLink;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return data.map((x) => {
    return {
      type: x.type,
      name: x.name,
      description: x.description,
      actions: (
        <Button
          onClick={onClick(x.filePath)}
          variant="contained"
          className="jr-btn jr-btn-xs"
        >
          <i className="zmdi zmdi-download"></i>
        </Button>
      ),
    };
  });
};

const ConsentDialog = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentSigned, isCIRequired } = useSelector(({ impact }) => impact);

  const [open, setOpen] = useState(false);
  const [documents, setDocuments] = useState([]);

  const msgStartImpactRequired = IntlGetText("impact.startImpactRequired");
  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    if (isCIRequired && !consentSigned) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }
    setOpen(true);
  };
  const handleRequestClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    callActionApi(
      () => getEducationalMaterial(selectedPrescription.id),
      setDocuments,
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  }, []);

  const datatableConfig = {
    textLabels: {
      body: {
        noMatch: "No hay resultados para la búsqueda actual",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordernar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Items por pagina:",
        displayRows: "de",
      },
      toolbar: {
        search: "Busqueda",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtro",
      },
      filter: {
        all: "Todos",
        title: "FILTRO",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas",
      },
      selectedRows: {
        text: "item(s) seleccionados",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    },
  };

  const columns = [
    {
      name: "type",
      label: "Tipo",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Documento",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "description",
      label: "Descripción",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "actions",
      label: "Descargar",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: datatableConfig.textLabels,
  };

  return (
    <div>
      <InfoCard
        data={{
          icon: "book",
          title: IntlGetText("core.education"),
          subTitle: IntlGetText("core.patient"),
          color: "#757575",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="eduMaterial.dialogTitle" />
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <CardBox styleName="col-12" cardStyle="p-0 border-dialog-card">
              <MUIDataTable
                className="border-dialog-card"
                // title={IntlGetText("core.patients")}
                data={processData(documents)}
                columns={columns}
                options={options}
              />
            </CardBox>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConsentDialog;
