import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import IntlMessages from "util/IntlMessages";
import ClinicHistoryTable from "./ClinicHistoryTable";
import FileTable from "./FileTable";
import AdditionalMedicationsTable from "./AdditionalMedicationsTable";
import { callActionApi, getCHPatientDetail } from "api/apiList";


const ClinicalHistoryDetail = ({patientId}) => {  
  const [data, setData] = useState(null);

  const datatableConfig = {
    textLabels: {
      body: {
        noMatch: "No hay resultados para la búsqueda actual",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordernar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Items por pagina:",
        displayRows: "de",
      },
      toolbar: {
        search: "Busqueda",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtro",
      },
      filter: {
        all: "Todos",
        title: "FILTRO",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas",
      },
      selectedRows: {
        text: "item(s) seleccionados",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    },
  };

  const options = {
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    textLabels: datatableConfig.textLabels,
  };

  useEffect(() => {
    callActionApi(
      ()=>getCHPatientDetail(patientId),
      setData,
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  }, []);

  return (
    <React.Fragment>
      <ClinicHistoryTable
        data={data && data.paraclinicList ? data.paraclinicList : []}      
        options={options}
      />
      <div className="mt-5 text-uppercase text-grey">
        <h3><IntlMessages id="core.attachedFiles" /></h3>
      </div>
      <FileTable
        data={data && data.variableGroupFileList ? data.variableGroupFileList : []}      
        options={options}
      />
      <div className="mt-5 text-uppercase text-grey">
        <h3><IntlMessages id="additionalMedications.dialogTitle" /></h3>
      </div>
      <AdditionalMedicationsTable
        lastPrescriptionId={data && data.lastPrescriptionId ? data.lastPrescriptionId : 0}      
        options={options}
      />
    </React.Fragment>
  );
};

export default ClinicalHistoryDetail;