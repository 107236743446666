import React, { useEffect} from "react";
import { useDispatch } from "react-redux";
import * as dayjs from "dayjs";
import { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import Table, { getTextLabels, defaultCol } from "components/Form/Table";
import { callApi, getAllExternalReports, deleteExternalReport } from "api/apiList";
import { setLoader } from "actions/Base";

const onClick = (url) => () => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const CustomTable = ({handleOpenCRU, data, setData, setCurrentRowObj}) => {
  const getObj = (tableMeta) => data.find(x=>x.id === tableMeta.rowData[0]);
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const handleDelete = (id) => {
    callApi(
      () => deleteExternalReport(id),
      (data)=>setData(data)
    );
  };
  useEffect(() => {
    callApi(
      ()=>getAllExternalReports(),
      (data)=> setData(data),
      fnSetLoader);
  }, []);
  
  const handleClick = (obj) => {
    handleOpenCRU();
    setCurrentRowObj(obj);
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display:"excluded"
      },
    },
    {
      name: "date",
      label: IntlGetText("core.date"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("DD-MM-YYYY") : "";
        },
      },
    },
    defaultCol('description','core.description'),
    {
      name: "url",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return (
            <>
              <Button
                onClick={onClick(rowObj.link)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className={"zmdi zmdi-link"}></i>
              </Button>
              <Button
                onClick={() => handleClick(rowObj)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-edit"></i>
              </Button>
              <Button
                onClick={() => handleDelete(rowObj.id)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-delete"></i>
              </Button>
            </>
          )
        }
      },
    },
  ];

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: getTextLabels(),
  };

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-1">
      <Table
        className="border-dialog-card mt-2 mb-2"
        data={data}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default CustomTable;
