import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

class AddEventDialog extends React.Component {
  state = {
    open: false,
    theme: "",
    name: "hai",
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <Button
          variant="contained"
          onClick={this.handleClickOpen}
          className="jr-btn jr-btn-md text-white bg-cyan"
        >
          EVENTO NUEVO
          <i className={`zmdi zmdi-file-text zmdi-hc-fw`} />
        </Button>
        <Dialog open={this.state.open} onClose={this.handleRequestClose}>
          <DialogTitle>Agendar Evento</DialogTitle>
          <DialogContent>
            <form className="row" noValidate autoComplete="off">
              <div className="col-md-6 col-6">
                <TextField
                  required
                  id="name"
                  label="Nombre"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="col-md-6 col-6">
                <FormControl className="w-100 MuiFormControl-marginNormal MuiFormControl-fullWidth">
                  <InputLabel htmlFor="theme-helper">Tema</InputLabel>
                  <Select
                    value={this.state.theme}
                    onChange={this.handleChange("theme")}
                    input={<Input id="theme-helper" />}
                  >
                    <MenuItem value="">
                      <em>Ninguno</em>
                    </MenuItem>
                    <MenuItem value={10}>Remodulin</MenuItem>
                    <MenuItem value={20}>Iclusig</MenuItem>
                    <MenuItem value={30}>PSP</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-12 col-12">
                <TextField
                  required
                  multiline
                  id="comment"
                  label="Descripción"
                  margin="normal"
                  fullWidth
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.handleRequestClose} color="primary">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AddEventDialog;
