import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import * as dayjs from "dayjs";
import { IntlGetText } from "util/IntlMessages";
import CustomScrollbars from 'util/CustomScrollbars';
import Table, { minimalOptions, defaultCol } from "components/Form/Table";
import DlgDetail from './dlgDoctorDetail';
import { callApi, getDoctorDetail } from "api/apiList";

const TopDoctorTable = ({ data, minDate, maxDate }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [openDlg, setOpenDlg] = useState(null);
  const [detailData, setDetailData] = useState([]);

  const closeDialog = ()=>{
    setOpenDlg(false);
    setDetailData([]);
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display:"excluded"
      },
    },
    {
      name: '#',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, meta) => {
            return (
                meta.rowIndex + 1
            );
        }
      }
    },
    defaultCol("doctor", "core.doctor"),
    defaultCol("prescriptions", "core.prescriptions"),
    defaultCol("patients", "psp.menu.patients")
  ];

  const onRowClick = (rowData,rowMeta) => { 
    var obj= {
      doctorId: rowData[0],
      minDate: dayjs(minDate).format("YYYY-MM-DDTHH:mm:ss"),
      maxDate: dayjs(maxDate).format("YYYY-MM-DDTHH:mm:ss")
    };
    callApi(() => 
      getDoctorDetail(obj),
      (data)=>{
        setDetailData(data);
        setOpenDlg(true);
      }, 
      fnSetLoader);

  };

  const options = {
    ...minimalOptions(),
    onRowClick: onRowClick
  };

  return (
    <>
      <DlgDetail 
        openDlg={openDlg}
        closeDialog={closeDialog}
        data={detailData}
      />
      <CustomScrollbars className="module-list-scroll scrollbar" style={{height: 400}}>
      <Table
        title={IntlGetText("dashboard.nurse.topDoctors.title")}
        className="border-dialog-card mt-2 mb-2"
        data={data}
        columns={columns}
        options={options}
      />
      </CustomScrollbars>
    </>
  );
};

export default TopDoctorTable;