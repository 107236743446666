import React from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { Grid } from "@material-ui/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import FormInput from "components/Form/Controls/input";
import FormDateTimePicker from "components/Form/Controls/dateTimePicker";
import FormCheckbox from "components/Form/Controls/checkbox";
import Widget from "components/Widget";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import IntlMessages, { IntlGetText }  from "util/IntlMessages";
import { isOnlyTracing } from "app/routes/psp/services/userService";
import { updateSelectedPrescription, addPrescriptionAndGoToTracing } from "actions/Management";
import { callApiAsync, postPlanningFirstImpact } from "api/apiList";
import { FolderType } from "constants/Enums";

const ContactPlanning = ({ prescriptionId }) => {
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    planningDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />)
  });

  const defaultValues = {
    planningDate: null,
    isHospitalized: false,
    address: "",
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, errors, watch, setValue } = methods;

  const isHospitalized = watch("isHospitalized");

  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);

  const onSubmit = async (data, e) => {
    const planningDate = dayjs(data.planningDate).format("YYYY-MM-DDTHH:mm:ss");
    const dataForm = { ...data, planningDate, prescriptionId };

    await callApiAsync(
      () => postPlanningFirstImpact(dataForm),
      (data) => {
        if(!isOnlyTracing())
          dispatch(updateSelectedPrescription(data));
        else
          dispatch(addPrescriptionAndGoToTracing(data, FolderType.tracing));
      }
    );
  };

  return (
    <div className="row first-impact-box-margins">
      <div className="col-xl-4 col-lg-4 col-md-12 col-10">
        <Widget title="Fecha primer impacto" styleName="jr-card-profile-sm">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <FormDateTimePicker
                    name="planningDate"
                    label="Fecha"
                    format="YYYY-MM-DD hh:mm A"
                    //disablePast={true}
                    leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                    rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormCheckbox
                    name="isHospitalized"
                    label="Hospitalizado"
                    value={isHospitalized}
                    onChange={chkHandleChange("isHospitalized")}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    label="Lugar"
                    name="Address"
                    required={true}
                    margin="normal"
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
              <div className="col-12 text-center">
                <ButtonAsync
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  className="jr-btn bg-green text-white mt-2"
                >
                  {<IntlMessages id="core.save" />}
                </ButtonAsync>
              </div>
            </form>
          </FormProvider>
        </Widget>
      </div>
    </div>
  );
};

export default ContactPlanning;
