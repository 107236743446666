import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from "@material-ui/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import FormInput from "components/Form/Controls/input";
import IntlMessages from "util/IntlMessages";

const DialogDoctorAuthorization = ({open, dialogTitle , sendDoctorAuth, handleDoctorAuthDialog}) => {
    const validationSchema = yup.object().shape({
        comment: yup.string().required(<IntlMessages id="core.fieldRequired" />)
    });    

    const defaultValues = {comment:""};

    const methods = useForm({ defaultValues, resolver: yupResolver(validationSchema) });
    const { handleSubmit, errors, reset } = methods;
    
    const onSubmit = async (data, e) => { sendDoctorAuth(false, data.comment); };

    const handleRequestClose = () => {
        reset(defaultValues);
        handleDoctorAuthDialog(false);
    };
    return (
        <Dialog open={open} 
            disableBackdropClick={true} onClose={handleRequestClose}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <FormProvider {...methods}>
                    <form className="row" noValidate autoComplete="off">
                        <Grid item md={12}>
                            <FormInput
                            label={<IntlMessages id="core.comment" />}
                            name="comment"
                            required={true}
                            multiline
                            rows={5}
                            variant="outlined"
                            margin="normal"
                            errorobj={errors}
                            />
                        </Grid>
                    </form>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRequestClose} color="secondary">
                    {<IntlMessages id="core.cancel" />}
                </Button>
                <Button onClick={handleSubmit(onSubmit)} color="primary">
                    {<IntlMessages id="core.save" />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogDoctorAuthorization;
