import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Grid, Button, Tooltip } from "@material-ui/core";
import { ResponsiveContainer } from "recharts";
import FormInput from "components/Form/Controls/input";
import DialogAsync from "components/Form/DialogAsync";
import CardFileUpload from "components/Form/CardFileUpload";
import {DlgConfirmCustomAsync} from "components/Form/Controls/DialogConfirm";
import { 
  callApi, 
  callApiAsync, 
  postIntitutionSaveProcess, 
  getInstitutionImpactFiles,
  postAddInstitutionImpactFile,
  deleteInstitutionImpactFile
} from "api/apiList";
import ImageGallery from "react-image-gallery";

const ProcedureForm = ({ openDlg, closeDialog, impactInfo, setImpactInfo, allowEdit = true }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [file, setFile] = useState(null);
  const [galleryObj, setGalleryObj] = useState(null);

  const [imageList, setImageList] = useState([]);
  const [currentImg, setCurrentImg] = useState(null);
  const [openDlgDelete, setOpenDlgDelete] = useState(false);

  const msgSuccess = IntlGetText("core.SuccessSave");

  const defaultValues = {
    caseDescription: "",
    evidencePhoto: "",
  };

  const validationSchema = {
    caseDescription: yup
      .string()
      .required(<IntlMessages id="core.fieldRequired" />)
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, errors } = methods;
  
  useEffect(()=>{
    if(!openDlg)
      return;
    
    callApi(()=>getInstitutionImpactFiles(impactInfo.id), setImageList);
    setTimeout(() => {
      setValue("caseDescription", !impactInfo || !impactInfo.process ? "" : impactInfo.process);
    }, 200);
  },[openDlg, impactInfo]);

  const onSubmit = handleSubmit(async (d) => {
      var obj = {
        impactId: impactInfo.id,
        process: d.caseDescription
    };

    await callApiAsync(
      () => postIntitutionSaveProcess(obj),
      () => {
        NotificationManager.success(msgSuccess);
        setImpactInfo({...impactInfo, process: obj.process});
        closeDialog();
      },
      fnSetLoader
    );
  });


  const renderCustomControls = () => {
    return (
      <Tooltip title={<IntlMessages id="core.deleteImage" />} placement="right-end">
        <Button
          onClick={()=>{
            setCurrentImg(imageList[galleryObj.getCurrentIndex()]);
            setOpenDlgDelete(true);
          }}
          variant="contained"
          className="mb-2 bg-red text-white"
        >
          <i className="zmdi zmdi-delete"></i>
        </Button>
      </Tooltip>
    );
  };

  return (
    <div>
      <DialogAsync
        open={openDlg}
        closeDialog={closeDialog}
        onSubmitAsync={onSubmit}
        titleTextId={"institution.procedure"}
        allowEdit={allowEdit}
      >
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <FormInput
                  label={<IntlMessages id="institution.caseDescription" />}
                  name="caseDescription"
                  required={true}
                  multiline
                  rows={7}
                  variant="outlined"
                  margin="normal"
                  errorobj={errors}
                  disabled={ !allowEdit}
                />
              </Grid>
              {allowEdit && 
                <Grid item xs={12} md={12} lg={12}>
                  <CardFileUpload
                    disabled={false}
                    file={file}
                    setFile={(response) => {
                      setFile(response);
                      setImageList(response);
                    }}
                    actionApi={postAddInstitutionImpactFile}
                    actionApiInfo={{ institutionImpactId: impactInfo && impactInfo.id }}
                    showDownload = {false}
                  />
                </Grid>
              }
              {imageList.length > 0 && 
                <>
                <Grid item xs={12} md={12} lg={12}>
                  <ImageGallery
                    ref={(c) => setGalleryObj(c)}
                    items={imageList}
                    renderCustomControls={allowEdit ? renderCustomControls : undefined}
                  />
                </Grid>
                <DlgConfirmCustomAsync
                  open={openDlgDelete}
                  titleTextId="core.deleteImage"
                  handleRequestClose={() => setOpenDlgDelete(false)}
                  onAcceptClickAsync={async () => {
                    await callApiAsync(
                      () => deleteInstitutionImpactFile(currentImg.id),
                      () => {
                        NotificationManager.success(msgSuccess);

                        let index = imageList.findIndex((x)=> x.id === currentImg.id);
                        let data = [...imageList];
                        data.splice(index,1);
                        setImageList(data);

                        setCurrentImg(null);
                        setOpenDlgDelete(false);
                      },
                      fnSetLoader
                    );
                  }}
                >
                  <div className="row">
                    <IntlMessages id="core.deleteImageConfirm" />

                  </div>
                  <div className="row mt-2">
                    <ResponsiveContainer width="100%" height={240}>
                      <img
                        className=""
                        src={!currentImg ? "" : currentImg.original}
                        alt=""
                        title=""
                      />
                    </ResponsiveContainer>
                  </div>
                </DlgConfirmCustomAsync>
                </>
              }
            </Grid>
          </form>
        </FormProvider>
      </DialogAsync>
    </div>
  );
};

export default ProcedureForm;
