import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete, {
  getCboValue,
} from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  adverseReactionOutcomeEnum,
  adverseReactionOutcomesEnum,
  adverseReactionTypeEnum,
} from "constants/Enums";
import { useSelector } from "react-redux";

const FormAdverseReaction = ({
  rowObj2,
  openForm,
  handleRequestClose,
  handleCreateUpdate,
}) => {
  const { selectedPrescription } = useSelector(({ management }) => management);
  const adverseReactionTypes = adverseReactionTypeEnum();
  const adverseReactionOutcomes = adverseReactionOutcomeEnum();
  const [isEditable, setIsEditable] = useState(false);

  const validationSchema = {
    typeId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    reactionDate: yup
      .date()
      .nullable()
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    description: yup
      .string()
      .max(500, "La descripción no puede tener más de 500 caracteres")
      .required(<IntlMessages id="core.fieldRequired" />),
    outcomeId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
  };

  const defaultValues = {
    reactionDate: null,
    typeId: null,
    outcomeId: null,
    description: "",
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, reset, errors } = methods;

  useEffect(() => {
    if (openForm && rowObj2 != null) {
      setTimeout(() => {
        loadData();
      }, 150);
      return;
    }
    setIsEditable(false)
  }, [rowObj2, openForm]);

  const loadData = () => {
    if (rowObj2.outcomeId == adverseReactionOutcomesEnum.RECOVERED) {
      setIsEditable(true);
    }
    setValue(
      "reactionDate",
      !rowObj2 || !rowObj2.eventDate ? null : dayjs(rowObj2.eventDate)
    );
    setValue("outcomeId", !rowObj2 ? null : rowObj2.outcomeId);
    setValue(
      "outcomeId",
      !rowObj2 || !rowObj2.outcomeId
        ? ""
        : getCboValue(adverseReactionOutcomes, rowObj2.outcomeId)
    );
    setValue(
      "typeId",
      !rowObj2 || !rowObj2.typeId
        ? ""
        : getCboValue(adverseReactionTypes, rowObj2.typeId)
    );
    setValue("description", !rowObj2 ? "" : rowObj2.description);
  };

  const onSubmit = async (d, e) => {
    var obj = {
      id: !rowObj2 ? 0 : rowObj2.id,
      PrescriptionId: selectedPrescription.id,
      EventDate: !d.reactionDate
        ? null
        : dayjs(d.reactionDate).format("YYYY-MM-DDTHH:mm:ss"),
      Description: d.description,
      TypeId: parseInt(d.typeId.value),
      OutcomeId: parseInt(d.outcomeId.value),
    };
    await handleCreateUpdate(obj);
    reset();
    handleRequestClose();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openForm}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!rowObj2 ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="typeId"
                    label={<IntlMessages id="AdverseReaction.type" />}
                    options={adverseReactionTypes}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                    disabled={isEditable}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormDatePicker
                    name="reactionDate"
                    label={<IntlMessages id="AdverseReaction.date" />}
                    format="YYYY-MM-DD"
                    disableFuture={true}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                    disabled={isEditable}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="description"
                    label={<IntlMessages id="AdverseReaction.description" />}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                    disabled={isEditable}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="outcomeId"
                    label={<IntlMessages id="AdverseReaction.outcome" />}
                    options={adverseReactionOutcomes}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                    disabled={isEditable}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
            <ButtonAsync
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
              disabled={isEditable}
            >
              {<IntlMessages id="core.save" />}
            </ButtonAsync>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormAdverseReaction;
