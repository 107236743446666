import React from "react";
import { useSelector } from "react-redux";

const Progress = ({className}) => 
{  
  const { loader } = useSelector(({ base }) => base);

  return (loader && <div className={`loader ${className ?? ''}`} style={{zIndex: '9999'}}>
    <svg className="circular" viewBox="25 25 50 50" style={{height: '90px'}}>
      <circle className="path" cx="50" cy="50" r="15" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
    </svg>
  </div>)
};
export default Progress;
