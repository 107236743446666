import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages from "util/IntlMessages";
import { Grid, IconButton } from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import DialogAsync from "components/Form/DialogAsync";
import {
  callApi,
  callApiAsync,
  postAddPatientComment
} from "api/apiList";

const AddComment = ({
  openDlg,
  requestCloseDlg,
  callBack,
  patientComment,
  patient
}) => {
  const defaultValues = { comment: "" };
  const validationSchema = {
      comment: yup
      .string()
      .required(<IntlMessages id="core.fieldRequired" />)
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, errors } = methods;

  const setString = (obj, prop) =>
    setValue(prop, !obj || !obj[prop] ? "" : obj[prop]);
  
  useEffect(() => {
    if(openDlg && patientComment){
      setTimeout(()=>{
        setString(patientComment, "comment");
      },130);
    }
  }, [openDlg,patientComment]);

  const onSubmitAsync = handleSubmit(async (d) => {
    const obj = {
      id: patientComment ? patientComment.id : 0,
      patientId: patient.id,
      comment: d.comment
    };

    await callApiAsync(
      () => postAddPatientComment(obj),
      () => {
        if (callBack) callBack();
        requestCloseDlg();
      }
    );
  });

  const disabled = false;

  return (
    <div>

      <DialogAsync
        open={openDlg}
        closeDialog={requestCloseDlg}
        onSubmitAsync={onSubmitAsync}
        titleTextId="core.add"
      >
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <FormInput
                  name="comment"
                  label={<IntlMessages id="core.comment" />}
                  multiline
                  rows={4}
                  variant="outlined"
                  margin="normal"
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogAsync>
    </div>
  );
};

export default AddComment;
