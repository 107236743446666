import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import IntlMessages from "util/IntlMessages";
import { Grid } from "@material-ui/core";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import FormCheckbox from "components/Form/Controls/checkbox";
import {
  HousingTypeList,
  SupportNetList
} from "constants/Enums";

const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const HousingForm = ({ housingData }) => {  
  const housingTypeList = HousingTypeList();
  const supportNetList = SupportNetList();

    const defaultValues = {
      housingType: !housingData
        ? 0
        : getCboValue(housingTypeList, housingData.housingType),
      aqueduct: !housingData.aqueduct ? false : housingData.aqueduct,
      sewerage: !housingData.sewerage ? false : housingData.sewerage,
      electricity: !housingData.electricity ? false : housingData.electricity,
      toilet: !housingData.toilet ? false : housingData.toilet,
      wasteCollection: !housingData.wasteCollection ? false : housingData.wasteCollection,
      cookingArea: !housingData.cookingArea ? false : housingData.cookingArea,
      rooms: !housingData.rooms ? 0 : housingData.rooms,
      bathRoom: !housingData.bathRoom ? false : housingData.bathRoom,
      laundryArea: !housingData.laundryArea ? false : housingData.laundryArea,
      supportNet: !housingData.supportNet
        ? 0
        : getCboValue(supportNetList, housingData.supportNet),
      petsInUnsuitableConditions: !housingData.petsInUnsuitableConditions
        ? false
        : housingData.petsInUnsuitableConditions,
      animalType: !housingData.animalType ? "" : housingData.animalType,
      petsNumber: !housingData.petsNumber ? 0 : housingData.petsNumber,
    };
    
    const methods = useForm({defaultValues});
    const { watch, setValue, errors } = methods;
    
    const loadData = (housingData) =>{
      //const housingData = !housingInfo.housingType ? null : housingInfo.housingType;
      setValue("housingType", !housingData ? null : getCboValue(housingTypeList, housingData.housingType));
      setValue("aqueduct", !housingData ? false : housingData.aqueduct);
      setValue("sewerage", !housingData ? false : housingData.sewerage);
      setValue("electricity", !housingData ? false : housingData.electricity);
      setValue("toilet", !housingData ? false : housingData.toilet);
      setValue("wasteCollection", !housingData ? false : housingData.wasteCollection);
      setValue("cookingArea", !housingData ? false : housingData.cookingArea);
      setValue("rooms", !housingData ? 0 : housingData.rooms);
      setValue("bathRoom", !housingData ? false : housingData.bathRoom);
      setValue("laundryArea", !housingData ? false : housingData.laundryArea);
      setValue("supportNet", !housingData ? null : getCboValue(supportNetList, housingData.supportNet));
      setValue("petsInUnsuitableConditions", !housingData ? false : housingData.petsInUnsuitableConditions);
      setValue("animalType", !housingData ? "" : housingData.animalType);
      setValue("petsNumber", !housingData ? 0 : housingData.petsNumber);
    };
    
    useEffect(() => {
      if(housingData.id)
        loadData(housingData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [housingData]);
  
    const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);
    const cboHandleChange = (name) => (e) => {
      setValue(name, e);
    };
    
    var disabled = true;
    return (
      <div className="col-xl-12 col-lg-12 col-md-12 col-12">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={4} style={{ marginTop: 2 }}>
                <FormSelectAutoComplete
                  name="housingType"
                  label={<IntlMessages id="sociodemographicInfo.housingType" />}
                  options={HousingTypeList()}
                  value={watch("housingType")}
                  onChange={cboHandleChange("housingType")}
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4} style={{ marginTop: 16 }}>
                <FormInput
                  name="rooms"
                  label={<IntlMessages id="sociodemographicInfo.rooms" />}
                  disabled={disabled}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4} style={{ marginTop: 2 }}>
                <FormSelectAutoComplete
                  name="supportNet"
                  label={<IntlMessages id="sociodemographicInfo.supportNet" />}
                  options={SupportNetList()}
                  value={watch("supportNet")}
                  onChange={cboHandleChange("supportNet")}
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormCheckbox
                  name="aqueduct"
                  label={<IntlMessages id="sociodemographicInfo.aqueduct" />}
                  value={watch("aqueduct")}
                  onChange={chkHandleChange("aqueduct")}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormCheckbox
                  name="sewerage"
                  label={<IntlMessages id="sociodemographicInfo.sewerage" />}
                  value={watch("sewerage")}
                  onChange={chkHandleChange("sewerage")}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormCheckbox
                  name="electricity"
                  label={<IntlMessages id="sociodemographicInfo.electricity" />}
                  value={watch("electricity")}
                  onChange={chkHandleChange("electricity")}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormCheckbox
                  name="toilet"
                  label={<IntlMessages id="sociodemographicInfo.toilet" />}
                  value={watch("toilet")}
                  onChange={chkHandleChange("toilet")}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormCheckbox
                  name="wasteCollection"
                  label={
                    <IntlMessages id="sociodemographicInfo.wasteCollection" />
                  }
                  value={watch("wasteCollection")}
                  onChange={chkHandleChange("wasteCollection")}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormCheckbox
                  name="cookingArea"
                  label={<IntlMessages id="sociodemographicInfo.cookingArea" />}
                  value={watch("cookingArea")}
                  onChange={chkHandleChange("cookingArea")}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormCheckbox
                  name="bathRoom"
                  label={<IntlMessages id="sociodemographicInfo.bathRoom" />}
                  value={watch("bathRoom")}
                  onChange={chkHandleChange("bathRoom")}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormCheckbox
                  name="laundryArea"
                  label={<IntlMessages id="sociodemographicInfo.laundryArea" />}
                  value={watch("laundryArea")}
                  onChange={chkHandleChange("laundryArea")}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormCheckbox
                  name="petsInUnsuitableConditions"
                  label={
                    <IntlMessages id="sociodemographicInfo.petsInUnsuitableConditions" />
                  }
                  value={watch("petsInUnsuitableConditions")}
                  onChange={chkHandleChange("petsInUnsuitableConditions")}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="animalType"
                  label={<IntlMessages id="sociodemographicInfo.animalType" />}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="petsNumber"
                  label={<IntlMessages id="sociodemographicInfo.petsNumber" />}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
    );
  };

  export default HousingForm;