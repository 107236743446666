import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userSignOut } from "actions/Auth";
import IntlMessages from "util/IntlMessages";
import { getUserInfo } from "app/routes/psp/services/userService";
import { RoleList } from "constants/Enums";

const UserInfoPopup = ({ handleOpenCPM }) => {
  const [profileLogo, setProfileLogo] = useState(require("assets/images/psp/logos/psp_profile_logo-default.png"));
  const dispatch = useDispatch();

  const userInfo = getUserInfo();

  const getRoleName = () =>{
    var result = RoleList().find(x=>userInfo.roles.find(y=>y===x.id));
    if(result) return result.name;
    return "notification.error";
  };

  useEffect(()=>{
    if(!userInfo)
      return;
      
    if(userInfo.customProfileLogo)
      setProfileLogo(userInfo.customProfileLogo);
  },[]);
  
  return (
    <div>
      <div className="user-profile">
        <img
          className="user-avatar border-0 size-40 rounded-circle mb-2"
          src={profileLogo}
          alt="User"
        />
        <div className="user-detail ml-2">
          <h4 className="user-name mb-0">{userInfo.userName}</h4>
          <small>{getRoleName()}</small>
        </div>
      </div>
      <span 
      className="jr-link dropdown-item text-muted"
      onClick={handleOpenCPM}>
        <i className="zmdi zmdi-key zmdi-hc-fw mr-1" />
        <IntlMessages id="popup.changePassword" />
      </span>
      <span
        className="jr-link dropdown-item text-muted"
        onClick={() => {
          dispatch(userSignOut());
        }}
      >
        <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1" />
        <IntlMessages id="popup.logout" />
      </span>
    </div>
  );
};

export default UserInfoPopup;
