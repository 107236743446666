import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { setLoader } from "actions/Base";
import { Button, Tooltip } from "@material-ui/core";
import { isRole, isJaasModule } from "app/routes/psp/services/userService";
import CardBox from "components/CardBox/index";
import DlgNurses from "components/DlgNurses";
import ChangeNurse from "./ChangeNurse";
import DlgActivatePatient from "./btnActivatePatient";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  callApi,
  getPatientListManagement,
  getUserByRoles,
  postActivatePatientByPrescriptionId
} from "api/apiList";
import Table, { defaultOptions, defaultCol } from "components/Form/Table";
import { RoleEnum, PatientStateEnum } from "constants/Enums";

const getNurses = (nurses) => {
  return nurses.map(x=>x.name + " " + x.lastName).join(", ");
}

const PatientManagement = () => {
    const dispatch = useDispatch();
    const fnSetLoader = (data) => dispatch(setLoader(data));

    const [patients, setPatients] = useState([]);
    const [nurseList, setNurseList] = useState([]);
    const [currentRowObj, setCurrentRowObj] = useState(null);
    const [openDlgNurse, setOpenDlgNurse] = useState(false);
    const [openDlgActivate, setOpenDlgActivate] = useState(false);
    
    const msgSuccess = IntlGetText("core.SuccessSave");
    const isPSPCoordinator = isRole(RoleEnum.psp_coordinator);
    const isJaasModuleConfig = isJaasModule();

    const handleCloseDlgNurse = () => {
      setOpenDlgNurse(false);
      setCurrentRowObj(null);      
      loadData();
    }
    const handleChangeNurseClick = (obj) => {
      setOpenDlgNurse(true);
      setCurrentRowObj(obj);
    };

    const handleCloseDlgActivate = () => {
      setOpenDlgActivate(false);
      setCurrentRowObj(null);
    }
    const handleActivateClick = (obj) => {
      setOpenDlgActivate(true);
      setCurrentRowObj(obj);
    };

    const loadData = () => {
      callApi(
          getPatientListManagement,
          (data)=> setPatients(data),
          fnSetLoader)
    }

    const loadNurse = () => {
      callApi(
        ()=>getUserByRoles({
          roles: [RoleEnum.nurse, RoleEnum.nurse_procedure]
        }
          ),
        (data) => {
            setNurseList(data.map(x => ({
              ...x,
              name: x.fullName
            })));
        }
      );
    }

    useEffect(()=>{
      loadData()
      loadNurse()
    },[])

    const getObj = (tableMeta) => patients.find(x=>x.patientCode === tableMeta.rowData[0]);

    const isSuspended = (item) => {
      return (item.patientStateId === PatientStateEnum.medicalSuspension
        || item.patientStateId === PatientStateEnum.suspension 
        || item.patientStateId === PatientStateEnum.voluntarySuspension
        || item.patientStateId === PatientStateEnum.process);
    }

    const columns = [
        {
            name: "patientCode",
            label: IntlGetText("core.code"),
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                var rowObj = getObj(tableMeta);
                return (
                  <Link
                    to={{ pathname: "/app/patient/detail", prescriptionId: rowObj.prescriptionId }} 
                    >
                      {value}
                  </Link>
                )
              }
            }
        },
        defaultCol('patientState','core.status'),
        {
          name: "document",
          label: IntlGetText("core.documentOnly"),
          options: {
            filter: true,
            searchable: true,
            sort: true,
            display: 'excluded',
            customSearch: (value, rowData) => {
              const documentValue = rowData[3];
              return documentValue.toLowerCase().indexOf(value.toLowerCase()) !== -1;
            },
          }
        },
        {
          name: "document",
          label: IntlGetText("core.documentOnly"),
          options: {
            filter: true,
            searchable: true,
            sort: true,
            display: false,
          },
        },
        defaultCol('patient','core.patient'),
        defaultCol('formulationDate','core.formulationDate'),
        defaultCol('formulaDuration','core.formulaDuration'),
        defaultCol('prescriptionState','core.prescriptionState'),
        defaultCol('city','core.city'),    
        {
          name: "nurse",
          label: IntlGetText("role.nurse"),
          options: {
            filter: false,
            sort: false,
            searchable: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              var rowObj = getObj(tableMeta);
              return <DlgNurses nurse={value} nurses={rowObj.nurses}/>;
            }
          },
        },
        defaultCol('doctor','core.attendingDoctor'),
        defaultCol('eps','core.insurance'),
        defaultCol('ips','core.institution'),
        {
          name: "actions",
          label: IntlGetText("core.actions"),
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              if(isPSPCoordinator)
                return <></>;
              var rowObj = getObj(tableMeta);
              return (
                <>
                  { !isJaasModuleConfig && isSuspended(rowObj) && 
                    <Tooltip title={<IntlMessages id="patient.activatePatient" />} placement="bottom-end">
                      <Button onClick={() => handleActivateClick(rowObj)}
                        className="jr-btn jr-btn-md"
                        color="primary">
                          <i className="zmdi zmdi-drink zmdi-hc-fw"></i>
                      </Button>
                    </Tooltip>
                  }
                  <Tooltip title={<IntlMessages id="core.reassignPatient" />} placement="bottom-end">
                    <Button onClick={() => handleChangeNurseClick(rowObj)}
                      className="jr-btn jr-btn-md"
                      color="primary">
                        <i className="zmdi zmdi-accounts-list-alt"></i>
                    </Button>
                  </Tooltip>
                </>
              );
            }
          }
        }
    ];
    
    const onActivePatient = (data) => {
      callApi(
        ()=>postActivatePatientByPrescriptionId(data),
        ()=>{
          NotificationManager.success(msgSuccess);
          handleCloseDlgActivate();
          loadData();
        }
      );
    }

    return (
      <div className="app-wrapper">
        <div className="row">
          <CardBox styleName="col-12" cardStyle="p-0">
            <Table 
              className="border-dialog-card mt-2 mb-2"
              data={patients}
              columns={columns}
              options={defaultOptions()} />
          </CardBox>
          <ChangeNurse
            openCRU={openDlgNurse}
            handleRequestClose={handleCloseDlgNurse}
            nurseList={nurseList}
            obj={currentRowObj}/>
          <DlgActivatePatient
            openDlg={openDlgActivate}
            handleRequestClose={handleCloseDlgActivate}
            obj={currentRowObj}
            onActivePatient={onActivePatient}/>
        </div>
      </div>
    );
}

export default PatientManagement;