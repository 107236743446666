import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { callStartImpact } from "actions/Impact";
import { Button } from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import DialogGeneric from "components/Form/DialogGeneric";
import Table from "./table";
import FormAdd from "./formAdd";
import { callApi, getPatientStateLog } from "api/apiList";

const existItemsInImpact = (impactId, dataList) =>
  dataList.find((x) => x.impactId == impactId) != undefined;

const PatientStateBox = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentSigned, isCIRequired } = useSelector(
    ({ impact }) => impact
  );

  const [openDlg, setOpenDlg] = useState(false);
  const [openDlgAdd, setOpenDlgAdd] = useState(false);
  const [patientStateLogList, setPatientStateLogList] = useState([]);
  const [currentRowObj, setCurrentRowObj] = useState({});
  const [lastState, setLastState] = useState(null);

  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    if (isCIRequired && !consentSigned) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }
    setOpenDlg(true);
  };

  const loadData = () => {
    callApi(
      () => getPatientStateLog(selectedPrescription.patient.id),
      (data) => {
        setPatientStateLogList(data);
        if (data && data.length > 0) {
          setLastState(data[0].state);
        } else {
          setLastState(null);
        }
      }
    );
  };

  useEffect(() => {
    if (!openDlg) return;

    loadData();
  }, [openDlg]);

  return (
    <>
      <InfoCard
        data={{
          icon: "layers",
          title: IntlGetText("core.states"),
          subTitle: IntlGetText("core.patient"),
          color: existItemsInImpact(
            selectedPrescription.impactId,
            patientStateLogList
          )
            ? "#20c997"
            : "#757575",
          }}
          onClickBox={handleClickOpen}
      />
      <DialogGeneric
        open={openDlg}
        closeDialog={() => setOpenDlg(false)}
        titleTextId="jaasModule.patientStates"
        >
        <Table
          dataList={patientStateLogList}
          setCurrentRowObj={setCurrentRowObj}
          setOpenDlg={setOpenDlgAdd}
          currentImpactId={selectedPrescription.impactId}
        />
        <FormAdd
          openDlg={openDlgAdd}
          closeDialog={() => setOpenDlgAdd(false)}
          currentImpactId={selectedPrescription.impactId}
          callBack={loadData}
          currentRowObj={currentRowObj}
          lastState={lastState}
        />
        <div className="col-lg-12 col-md-12 col-xs-12 mt-4 text-center">
          <Button
            onClick={() => {
              setOpenDlgAdd(true);
              setCurrentRowObj(null);
            }}
            variant="contained"
            color="primary"
            disabled={existItemsInImpact(
              selectedPrescription.impactId,
              patientStateLogList
            )}
          >
            {<IntlMessages id="core.add" />}
          </Button>
        </div>
      </DialogGeneric>
    </>
  );
};

export default PatientStateBox;
