import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { Button, Tooltip } from "@material-ui/core";
import PatientTable from "app/routes/psp/patient3/patientTable";
import { RoleEnum } from "constants/Enums";
import { isRole } from "app/routes/psp/services/userService";
import { callApi, getPatientStateList } from "api/apiList";

const PatientList = ({ data, loadData }) => {
  const [patientStateList, setPatientStateList] = useState([]);
  const isConsultant = isRole(RoleEnum.nursemanagerconsultant);

  useEffect(() => {
    callApi(getPatientStateList, (data) => {
      setPatientStateList(data.states);
    });
  }, []);

  const FnActionsColRender = (rowObj) => {
    if (isConsultant) {
      return;
    }

    return (
      <div>
        <Tooltip
          title={<IntlMessages id="core.seeDetail" />}
          placement="bottom-end"
        >
          <Button
            component={Link}
            to={{
              pathname: "/app/patient/detail",
              prescriptionId: rowObj.prescriptionId,
            }}
            color="primary"
            className="jr-btn"
          >
            <i className="zmdi zmdi-eye"></i>
          </Button>
        </Tooltip>
      </div>
    );
  };

  return (
    <PatientTable
      data={data}
      patientStateList={patientStateList}
      FnActionsColRender={FnActionsColRender}
    />
  );
};

export default PatientList;
