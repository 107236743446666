import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  callApi,
  callApiAsync,
  postAdverseEventRegisterCausality,
  getAllCausality
} from "api/apiList";

const DialogCausality = ({ open, closeDialog, callBack, adverseEvent }) => {
  const [causalities, setCausalities] = useState([])

  useEffect(()=>callApi(getAllCausality,setCausalities),[])

  const defaultValues = { causality: "" };
  const validationSchema = {
    causality: yup.object()
    .shape({ value: yup.string().required() })
    .required(<IntlMessages id="core.fieldRequired" />)
    .nullable()
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, errors } = methods;

  const onSubmit = async (d, e) => {
    var obj = {
      adverseEventId: adverseEvent.id,
      causalityId: Number(d.causality.value)
    };
    await callApiAsync(
      ()=> postAdverseEventRegisterCausality(obj),
      ()=> {
        callBack();
        closeDialog();
      }
    );
  };  

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      disableBackdropClick={true}
      onClose={closeDialog}
    >
      <DialogTitle><IntlMessages id="adverseEvent.causality" /></DialogTitle>
      <DialogContent className="new-patient-dialog-content">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid item xs={12} md={12} lg={12}>
              <Grid item xs={12} md={12} lg={12}>
                <FormSelectAutoComplete
                  name="causality"
                  label={<IntlMessages id="adverseEvent.causality" />}
                  options={causalities}
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="secondary">
          {<IntlMessages id="core.cancel" />}
        </Button>
        <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
          {<IntlMessages id="core.save" />}
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCausality;