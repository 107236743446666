import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "actions/Base";
import { useForm, FormProvider } from "react-hook-form";
import * as dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import AppLocale from "lngProvider";
import { Button, Grid } from "@material-ui/core";
import CustomScrollbars from 'util/CustomScrollbars';
import FormSelectAutoComplete, {getCboValue} from "components/Form/Controls/select-autocomplete";
import CustomChart from "components/CustomChart";
import CardTotal from "components/CardTotal";
import { currencyFormat } from "services/formatNumber";
import {
  eficaciaDetailChart,
  totalChart,
  causalChart,
  operatorChart,
  productLineChart,
  recoveriesChart
} from "./optionsCharts";
import CausalTable from "./causalTable";
import { 
  callApi, 
  postDashboardMigration, 
  getDashboardMigrationFilters,
  dashboardGetEficaciaRecoveries,
  dashboardGetTotal,
  dashboardGetByCausal,
  dashboardGetByProductLine,
  dashboardGetTotalMIPRES,
  dashboardGetByOperator,
  dashboardGetImpactsInfo
} from "api/apiList";

const getYears =()=>{
  var max = dayjs().get('year');
  var min = 2020;
  var years = [];

  for (var i = min; i <= max; i++) {
    years.push(i);
  }
  return years.map(x=>({id:x, name:x}));
};

const getMonths =()=>{
  return [
    {id:1, name:'Enero'},
    {id:2, name:'Febrero'},
    {id:3, name:'Marzo'},
    {id:4, name:'Abril'},
    {id:5, name:'Mayo'},
    {id:6, name:'Junio'},
    {id:7, name:'Julio'},
    {id:8, name:'Agosto'},
    {id:9, name:'Septiembre'},
    {id:10, name:'Octubre'},
    {id:11, name:'Noviembre'},
    {id:12, name:'Diciembre'}
  ]
};

const getProp = (obj, prop) => {
  if (!obj) return "";
  return obj[prop];
};

const getPropArray = (obj, prop) => {
  if (!obj) return [];
  return obj[prop];
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  let totalFiniched = 0;
  const fnSetLoader2 = (data) => {
    if(data === true){
      dispatch(setLoader(data));
      return;
    }
    totalFiniched += 1;
    if(totalFiniched === 7)
      dispatch(setLoader(data));
  }
  const { locale } = useSelector(({ settings }) => settings);
  const currentAppLocale = AppLocale[locale.locale];
  const currentLocale = currentAppLocale.locale;

  const yearList = getYears();
  const monthList = getMonths();

  const [totalInfo, setTotalInfo] = useState(null);
  const [totalMIPRES, setTotalMIPRES] = useState("");
  const [eficaciaRecovery, setEficaciaRecovery] = useState([]);
  const [byCausal, setByCausal] = useState([]);
  const [byOperator, setByOperator] = useState([]);
  const [byProductLine, setByProductLine] = useState([]);
  const [chartTotalData, setChartTotalData] = useState([]);
  const [impactsInfo, setImpactsInfo] = useState(null);
  
  const [olfList, setOlfList] = useState([]);
  const [insuranceList, setInsuranceList] = useState([]);
  const [stateList, setStateList] = useState([]);  
  const [executiveActionList, setExecutiveActionList] = useState([]);
  const [agentList, setAgentList] = useState([]);

  const recoveriesDefaultText = IntlGetText('dashboardMigration.recoveries.default');
  const recoveriesEficaciaText = IntlGetText('dashboardMigration.recoveries.eficacia');
  const recoveriesUnrecoverableText = IntlGetText('dashboardMigration.recoveries.unrecoverable');

  const recoveryFilterList = [
    {id:0,name:recoveriesDefaultText},
    {id:1,name:recoveriesEficaciaText},
    {id:2,name:recoveriesUnrecoverableText},
  ];
  
  const defaultValues = {
    year: null,
    month: null,
  };
  const validationSchema = {
    year: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });

  const { handleSubmit, errors, setValue } = methods;

  useEffect(() => {
    setValue("year", getCboValue(yearList, dayjs().get('year')));
    //onFilter();
    callApi(getDashboardMigrationFilters, (filters) => {
      setOlfList(filters.marketchains);
      setInsuranceList(filters.insurances);
      setStateList(filters.states);
      setExecutiveActionList(filters.executiveActions);
      setAgentList(filters.agentList);
      onFilter();
    });
  }, []);

  const onFilter = handleSubmit((d) => {
    var obj = {
      year: Number(d.year.value),
      month: !d.month ? null : Number(d.month.value),
      marketchainIds: !d.olfId ? [] : d.olfId.map((x) => Number(x.value)),
      insuranceIds: !d.insuranceId
        ? [] : d.insuranceId.map((x) => Number(x.value)),
      stateIds: !d.stateId ? [] : d.stateId.map((x) => x.value),
      causalIds: !d.executiveActionId
        ? [] : d.executiveActionId.map((x) => x.value),
      agentId: !d.agentId ? null : d.agentId.value,
      recoveryFilterIds: !d.recoveryFilterId ?  [] : d.recoveryFilterId.map((x) => x.value)
    };

    //callApi(() => postDashboardMigration(obj), setTotalInfo, fnSetLoader);
    callApi(() => dashboardGetTotalMIPRES(obj), setTotalMIPRES, fnSetLoader2);
    callApi(() => dashboardGetEficaciaRecoveries(obj), setEficaciaRecovery, fnSetLoader2);
    callApi(() => dashboardGetByCausal(obj), setByCausal, fnSetLoader2);
    callApi(() => dashboardGetByOperator(obj), setByOperator, fnSetLoader2);
    callApi(() => dashboardGetByProductLine(obj), setByProductLine, fnSetLoader2);
    callApi(() => dashboardGetTotal(obj), setChartTotalData, fnSetLoader2);
    callApi(() => dashboardGetImpactsInfo(obj), setImpactsInfo, fnSetLoader2);
  });

  //const chartTotalData = getPropArray(totalInfo, "chartTotal");
  const getTotalDataItem = (item)=>{
    if(item === "TOTAL"){
      const total = chartTotalData.map((item) => item.Value)
        .reduce((prev, curr) => prev + curr, 0);
      return "$ " + currencyFormat(currentLocale, total);
    }

    var a = chartTotalData.find(x=>x.Key === item);
    if(!a) return 0;
    return "$ " + currencyFormat(currentLocale,a.Value);
  };
  
  const chartRecoverableData = () => {
    return chartTotalData.filter(x=>x.Key !== recoveriesEficaciaText);
  };
  
  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
      <FormProvider {...methods}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={2}>
            <FormSelectAutoComplete
              name="year"
              label={<IntlMessages id="core.year" />}
              options={yearList}
              margin="normal"
              required={true}
              errorobj={errors}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <FormSelectAutoComplete
              name="month"
              label={<IntlMessages id="calendar.messages.month" />}
              options={monthList}
              margin="normal"
            />            
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormSelectAutoComplete
              name="agentId"
              label={<IntlMessages id="core.executive" />}
              options={agentList}
              margin="normal"
            />
            </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormSelectAutoComplete
              name="olfId"
              label={<IntlMessages id="radicadoOLF.cardSubTitle" />}
              options={olfList}
              margin="normal"
              isMulti
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormSelectAutoComplete
              name="insuranceId"
              label={<IntlMessages id="core.insurance" />}
              options={insuranceList}
              margin="normal"
              isMulti
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <FormSelectAutoComplete
              name="stateId"
              label={<IntlMessages id="core.state" />}
              options={stateList}
              margin="normal"
              isMulti
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <FormSelectAutoComplete
              name="recoveryFilterId"
              label={<IntlMessages id="dashboardMigration.recoveries" />}
              options={recoveryFilterList}
              margin="normal"
              isMulti
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormSelectAutoComplete
              name="executiveActionId"
              label={<IntlMessages id="core.executiveAction" />}
              options={executiveActionList}
              margin="normal"
              isMulti
            />
          </Grid>
          <Grid container justify="center">
          <Button
              onClick={onFilter}
              className="mt-2"
              variant="contained"
              color="primary"
            >
              <IntlMessages id="core.filter" />
            </Button>
          </Grid>
        </Grid>
      </FormProvider>

      <div className="row mt-3">
        <div className="col-xl-6 col-lg-6 col-12">
        <CardTotal
          title={getProp(impactsInfo, "totalContactCalls")}
          subtitleId="dashboard.totalContactCalls"
        />
        </div>
        <div className="col-xl-6 col-lg-6 col-12">
        <CardTotal
          title={getProp(impactsInfo, "totalContactsLoadData")}
          subtitleId="dashboard.totalContactsLoadData"
        />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-xl-6 col-lg-6 col-12">
        <CardTotal
          title={getTotalDataItem("TOTAL")}
          subtitleId="core.total"
        />
        </div>
        <div className="col-xl-6 col-lg-6 col-12">
        <CardTotal
          //title={currencyFormat(currentLocale, getProp(totalInfo, "totalMIPRES"))}
          title={currencyFormat(currentLocale, totalMIPRES)}          
          subtitleId="core.totalMipres"
        />
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4 col-lg-4 col-12">
          <CardTotal
            title={getTotalDataItem(recoveriesEficaciaText)}
            subtitleId="dashboardMigration.recoveries.eficacia"
          />
        </div>
        <div className="col-xl-4 col-lg-4 col-12">
          <CardTotal
            title={getTotalDataItem(recoveriesDefaultText)}
            subtitleId="dashboardMigration.recoveries.default"
          />
        </div>
        <div className="col-xl-4 col-lg-4 col-12">
          <CardTotal
            title={getTotalDataItem(recoveriesUnrecoverableText)}
            subtitleId="dashboardMigration.recoveries.unrecoverable"
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart options={totalChart(currentLocale, chartTotalData)} />
        </div>
        <div className="col-xl-6 col-lg-6 col-12">        
          <CustomChart options={eficaciaDetailChart(currentLocale, eficaciaRecovery)} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-8 col-lg-8 col-12">        
          <CustomChart options={causalChart(currentLocale, byCausal)} />
        </div>
        <div className="col-xl-4 col-lg-4 col-12">
          <CustomScrollbars className="module-list-scroll scrollbar" style={{height: 400}}>
            <CausalTable data={byCausal} currentLocale={currentLocale}/>
          </CustomScrollbars>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart options={productLineChart(currentLocale, byProductLine)} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart options={recoveriesChart(currentLocale, chartRecoverableData())} />
        </div>
        <div className="col-xl-6 col-lg-6 col-12">         
          <CustomChart options={operatorChart(currentLocale, byOperator)} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;