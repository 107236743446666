import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as dayjs from "dayjs";
import { setLoader } from "actions/Base";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button, Tooltip } from "@material-ui/core";
import { callApi, callApiAsync, getImpactPAList, postAddUpdateImpactPA } from "api/apiList";
import Table, { getTextLabels } from "components/Form/Table";
import Form from "./form";
import { isRole } from "../../../../../routes/psp/services/userService";
import { RoleEnum } from "../../../../../../constants/Enums";

const onClickLink = (url) => () => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

const ImpactList = ({patientData}) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [data, setData] = useState([]);
  const [currentRowObj, setCurrentRowObj] = useState(null);
  const [openCRU, setOpenCRU] = useState(false);

  const handleRequestCloseCRU = () => {
      setOpenCRU(false);
      setCurrentRowObj(null);
  }

  const loadData = () => callApi(()=>getImpactPAList(patientData.id), setData, fnSetLoader);

  useEffect(()=>{
    if(!patientData) return;
    loadData();
  }, [patientData]);

  const getObj = (tableMeta) =>
    data.find(x => x.id === tableMeta.rowData[0]);

  const handleClick = (rowObj) => {
    setOpenCRU(true);
    setCurrentRowObj(rowObj);
  };


  const isConsult = isRole(RoleEnum.consultation);

  const handleCreateUpdate = async (data) => {
    await callApiAsync(
        () => postAddUpdateImpactPA(data),
        ()=>{
            handleRequestCloseCRU();
            loadData();
        },
        fnSetLoader
    );
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    {
      name: "planningDate",
      label: IntlGetText("psp.menu.impactPlanning"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD hh:mm a") : "";
        },
      },
    },
    {
      name: "startDate",
      label: IntlGetText("core.startDate"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD hh:mm a") : "";
        },
      },
    },
    {
      name: "endDate",
      label: IntlGetText("core.endDate"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD hh:mm a") : "";
        },
      },
    },
    {
      name: "nursingNote",
      label: IntlGetText("core.comment"),
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return (
            <>
              { rowObj.link && 
                <Tooltip title={<IntlMessages id="patientPA.link" />} placement="bottom-end">
                    <Button
                        onClick={onClickLink(rowObj.link)}
                        variant="contained"
                        color="primary"
                        className="jr-btn jr-btn-xs"
                    >
                        <i className={"zmdi zmdi-link"}></i>
                    </Button>
                </Tooltip>
              }
            
            <Tooltip title={<IntlMessages id={!rowObj.endDate ? "core.edit" : "sidebar.view"} />} placement="bottom-end">
                <Button
                    onClick={() => handleClick(rowObj)}
                    variant="contained"
                    className="jr-btn jr-btn-xs">
                    <i className={!rowObj.endDate ? "zmdi zmdi-edit" : "zmdi zmdi-eye"}></i>
                </Button>
            </Tooltip>            
            </>
          );
        },
      }
    }
  ];
  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    textLabels: getTextLabels(),
  };
  
  const readOnly = false;
  return (
    <>
        <div className={ isConsult ? "noDisplay" : "text-right mt-1 mb-1" }>
            <Tooltip title={<IntlMessages id="core.add" />} placement="bottom-end">
                <Button variant="contained"
                        color="primary" 
                        onClick={()=>{
                    setOpenCRU(true);
                    setCurrentRowObj(null);
                }}> 
                    <i className="zmdi zmdi-plus"></i>
                </Button>
            </Tooltip>
        </div>
        <Table
          className="border-dialog-card mt-2 mb-2"
          data={data}
          columns={columns}
          options={options}
        />
        <Form 
            openCRU={openCRU}
            handleRequestClose={handleRequestCloseCRU}
            obj={currentRowObj}
            prescriptionId={patientData.prescriptionId}
            handleCreateUpdate={handleCreateUpdate}
            readOnly={readOnly}/>
    </>
  );
};

export default ImpactList;
