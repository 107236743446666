import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Grid, IconButton } from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete, { getCboValue, getCboValues } from "components/Form/Controls/select-autocomplete";
import FormCheckbox from "components/Form/Controls/checkbox";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { DownloadButton2 } from "components/DownloadButton/index";
import ConcomitantMedication from "components/AdverseEvent/ConcomitantMedication";
import { DialogConfirmAsync } from "components/Form/Controls/DialogConfirm";
import BtnNullify from "components/AdverseEvent/BtnNullify";
import AddknownAdverseEvent from "./addknownAdverseEvent";
import { setLoader } from "actions/Base";
import {
  callApi,
  callApiAsync,
  getEventsFollowUp,
  getKnownAdverseEvent,
  postAdverseEventSave,
  postAdverseEventUpdateState,
  getPrescriptions,
  getConcomitantMedicationList
} from "api/apiList";
import {  
  setSelectedPrescription, 
  loadPrescriptions
} from "actions/Management";
import {  
  ProductBehavior,
  FormulaType,
  ForeamSeriousnessList,
  ForeamDenouementList,
  YesNoNotKnowList,
  YesNoNAList,
  genderList,
  CiomsTypeList,
  AdverseEventStateEnum,
  RoleEnum,
  CiomsTypeEnum,
  QueryAdverseEventStateEnum,
  QueryAdverseEventTypeEnum
} from "constants/Enums";
import { getRoleUser } from "app/routes/psp/services/userService";

const GetQuestionYesNo = () =>{
  const questionKeys = [1, 2, 3, 4, 5];
  return questionKeys.map(x => ({
    id: x,
    answer: null,
    name: "questionYesNo"+x,
    label: IntlGetText("foream.questionYesNo.q"+x)
  }));
};

const getReactionList = () =>{
  const reactionKeys = [1, 2, 3, 4, 5, 6];
  return reactionKeys.map(x => ({
    id: x,
    answer: false,
    name: "reaction"+x,
    label: IntlGetText("cioms.reaction"+x)
  }));
};

const Farmacovigilance = ({adverseEvent, reloadAdverseEvent}) =>{   
  const dispatch = useDispatch();  
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const { selectedMenuItem } = useSelector(({ management }) => management);

  const [knownAdverseEvents,setKnownAdverseEvents] = useState([]);
  const [followUpList,setFollowUpList] = useState([]);
  const [concomitantMedicationList,setConcomitantMedicationList] = useState([]);
  const [dlgConfirm, setDlgConfirm] = useState(false);
  const [objSendFormat, setObjSendFormat] = useState(null);

  const foreamSeriousnessList = ForeamSeriousnessList();
  const foreamDenouementList = ForeamDenouementList();
  const yesNoNotKnowList = YesNoNotKnowList();
  const yesNoNAList = YesNoNAList();
  const questionsYesNo = GetQuestionYesNo();
  const reactionList = getReactionList();
  const genderrList = genderList();
  const ciomsTypeList = CiomsTypeList();
  const userInfo = getRoleUser();
  const isNurseProfile = () =>{
    const role = userInfo.role.toUpperCase();
    return role === RoleEnum.nurse;
  };

  const msgSuccess = IntlGetText("core.SuccessSave");
  const msgConcomitantsNotValid = IntlGetText("concomitantMedication.notValid");

  let defaultValues = {
    adverseEventType: "",
    creationDate: null,
    reportSource: "", //(stateProvince - city)
    //status,
    
    dosage: "",
    infusionRate: "",
    supplyFrequency: "",

    country: null,
    patientBirthDate: null,
    age: "", // age (unitAge)
    documentType: "",
    document: "",
    initialsPatientName: "",
    gender: "",
    weight: "",
    size: "",

    disease: "",
    healthRegisterHolder:"",
    healthRegister:"",
    medicine:"", // tradename (genericName)
    medicineLotNumber:"",
    medicineExpirationDate : null,

    adverseEventStartDate: null,
    knownAdverseEvents: null,
    descriptionAdverseEvent: "",
    denouement: null,
    seriousness: null,
    //questionsYesNo:

    //adverseReaction:
    reactionDecreased:null,
    reactionReappeared:null,
    administrationRoute:"",
    anotherRelevantStory:"",
    startTherapy:null,
    endTherapy:null,
    therapyDays:null,
    studyIdentificationCode: "",
    reportType: "",
    type:null,
    followUpId: null,
  };
  questionsYesNo.map((x)=>{
    defaultValues[x.name] = x.answer;
  });
  reactionList.map((x)=>{
    defaultValues[x.name] = x.answer;
  });

  const validationSchema = {
    weight: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    size: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    medicineLotNumber: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    medicineExpirationDate: yup.date()
      .nullable()
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    adverseEventStartDate: yup.date()
      .nullable()
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    descriptionAdverseEvent: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    denouement: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    seriousness: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),     
    reactionDecreased: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    reactionReappeared: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    anotherRelevantStory: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    type: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),    
    followUpId: yup.object().when("type",{
        is: type => type && Number(type.value) === CiomsTypeEnum.tracing,
        then: yup.object().shape({ value: yup.string().required() })
          .required(<IntlMessages id="core.fieldRequired" />)
          .nullable()
      }).nullable()
  };
  questionsYesNo.map((x)=>{
    validationSchema[x.name] = yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable();
  });

  const methods = useForm({ defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema))
  });
  const { handleSubmit, setValue, watch, errors, getValues, clearErrors } = methods;

  const typeValue = watch("type");
  const knownAdverseEventsValue = watch("knownAdverseEvents");  

  const setString = (obj, prop) => setValue(prop, !obj || !obj[prop] ? "" : obj[prop]);
  const setDate = (obj, prop) => setValue(prop, !obj || !obj[prop] ? null : dayjs(obj[prop]));
  const setCboValue = (obj, prop, list) => setValue(prop, !obj ? null : getCboValue(list, obj[prop]));

  const loadData = (adverseEvent) => {
    setValue("adverseEventType", !adverseEvent || !adverseEvent.adverseEventType? "" : adverseEvent.adverseEventType);
    setDate(adverseEvent,"creationDate");
    
    if(adverseEvent){
      setValue("medicine",`${adverseEvent.tradename} (${adverseEvent.genericName })`);
      setValue("reportSource",`${adverseEvent.stateProvince} - ${adverseEvent.city}`);
      setValue("age",`${adverseEvent.age} (${adverseEvent.unitAge})`);
    }

    const pharmacovigilance = !adverseEvent ? null : adverseEvent.pharmacovigilance;

    setString(adverseEvent,"dosage");
    setString(pharmacovigilance,"administrationRoute");
    setString(adverseEvent,"infusionRate");
    setString(adverseEvent,"supplyFrequency");

    setString(adverseEvent,"country");
    setDate(adverseEvent,"patientBirthDate");
    setString(adverseEvent,"documentType");
    setString(adverseEvent,"document");
    setString(adverseEvent,"initialsPatientName");
    setCboValue(adverseEvent,"gender",genderrList);
    setString(pharmacovigilance,"weight");
    setString(pharmacovigilance,"size");

    setString(adverseEvent,"disease");    
    setString(pharmacovigilance,"healthRegisterHolder");
    setString(pharmacovigilance,"healthRegister");
    setString(adverseEvent,"medicineLotNumber");
    setDate(adverseEvent,"medicineExpirationDate");

    setDate(adverseEvent,"adverseEventStartDate");
    setString(adverseEvent,"descriptionAdverseEvent");
    setCboValue(pharmacovigilance,"denouement",foreamDenouementList);
    setCboValue(pharmacovigilance,"seriousness",foreamSeriousnessList);
    if(pharmacovigilance && pharmacovigilance.questionsYesNo){
      pharmacovigilance.questionsYesNo.map((x)=>{
        setValue("questionYesNo"+x.id, getCboValue(yesNoNotKnowList, x.answer));
      });
    }


    if(pharmacovigilance && pharmacovigilance.adverseReaction){
      pharmacovigilance.adverseReaction.map((x)=>{
        setValue("reaction"+x, true);
      });
    }
    setCboValue(pharmacovigilance,"reactionDecreased",yesNoNAList);
    setCboValue(pharmacovigilance,"reactionReappeared",yesNoNAList);
    setString(pharmacovigilance,"anotherRelevantStory");
    setDate(pharmacovigilance,"startTherapy");
    setDate(pharmacovigilance,"endTherapy");
    setString(pharmacovigilance,"therapyDays");    
    setString(pharmacovigilance,"studyIdentificationCode");
    setString(pharmacovigilance,"reportType");
    setCboValue(pharmacovigilance,"type",ciomsTypeList);
  };

  const loadConcomitants = () => {
    callApi(
      ()=>getConcomitantMedicationList(adverseEvent.id),
      setConcomitantMedicationList
    );
  };

  useEffect(()=>{
    if(adverseEvent){
      callApi(
        ()=>getKnownAdverseEvent(adverseEvent.id),
        setKnownAdverseEvents
      );

      loadData(adverseEvent);
      loadConcomitants();
      callApi(
        ()=>getEventsFollowUp(adverseEvent.id),
        setFollowUpList
      );
    }
  },[adverseEvent]);
    
  useEffect(()=>{
    if(knownAdverseEvents){
      setValue("knownAdverseEvents",getCboValues(knownAdverseEvents, adverseEvent.knownAdverseEvents));
    }
  },[knownAdverseEvents]);
  useEffect(()=>{
    if(followUpList){
      setCboValue(adverseEvent,"followUpId",followUpList);
    }
  },[followUpList]);

  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);

  const areMedicationsValid = () => {
    var item = concomitantMedicationList.find(x=> x.sci === undefined || x.sci === null);
    return item === undefined;
  };

  const onSubmit = async (d, e) => {
    if(adverseEvent && adverseEvent.status !== AdverseEventStateEnum.pending && !areMedicationsValid()){
      NotificationManager.warning(msgConcomitantsNotValid);
      return;
    }

    if(!d)
      d = getValues();

    var result = {
      id: adverseEvent.id,
      adverseEventTypeId : adverseEvent.adverseEventTypeId,
      adverseEventStartDate: !d.adverseEventStartDate ? null : dayjs(d.adverseEventStartDate).format("YYYY-MM-DDTHH:mm:ss"),
      descriptionAdverseEvent: d.descriptionAdverseEvent,
      followUpId: !d.followUpId ? null : Number(d.followUpId.value),
      knownAdverseEvents: !d.knownAdverseEvents ? [] : d.knownAdverseEvents.map(x=>Number(x.value)),
      medicineLotNumber: d.medicineLotNumber,
      medicineExpirationDate: !d.medicineExpirationDate ? null : dayjs(d.medicineExpirationDate).format("YYYY-MM-DDTHH:mm:ss")
    };

    let pharmacovigilance = {};
    pharmacovigilance.weight = !d.weight ? null : Number(d.weight);
    pharmacovigilance.size = !d.size ? null : Number(d.size);
    pharmacovigilance.denouement = !d.denouement && d.denouement !== 0 ? null : Number(d.denouement.value);
    pharmacovigilance.seriousness = !d.seriousness && d.seriousness !== 0 ? null : Number(d.seriousness.value);
    const questionsYesNoAnswered = questionsYesNo.map((x)=>({
      id: x.id,
      answer: !d[x.name] ? null : Number(d[x.name].value)
    }));
    pharmacovigilance.questionsYesNo = questionsYesNoAnswered.filter(x=>x.answer !== null);

    const reactionsSelected = reactionList.map((x)=>{
      if(d[x.name])
        return x.id;
    });
    pharmacovigilance.adverseReaction = reactionsSelected.filter(x=>x !== undefined);
    pharmacovigilance.reactionDecreased = !d.reactionDecreased && d.reactionDecreased !== 0 ? null : Number(d.reactionDecreased.value);
    pharmacovigilance.reactionReappeared = !d.reactionReappeared && d.reactionReappeared !== 0 ? null : Number(d.reactionReappeared.value);
    pharmacovigilance.anotherRelevantStory = d.anotherRelevantStory;
    pharmacovigilance.endTherapy = !d.endTherapy ? null : dayjs(d.endTherapy).format("YYYY-MM-DDTHH:mm:ss");
    pharmacovigilance.type = !d.type ? null : Number(d.type.value);

    result.pharmacovigilance = pharmacovigilance;
    clearErrors();
    await callApiAsync(
      ()=> postAdverseEventSave(result),
      ()=> {
        reloadAdverseEvent();
        NotificationManager.success(msgSuccess);
      },
      fnSetLoader
    );
  };

  const callSentFormatAsync = async (obj) => {
    await callApiAsync(
      ()=> postAdverseEventUpdateState(obj),
      ()=> {
        callApi(
          ()=> getPrescriptions(selectedMenuItem), 
          (data)=>{
              dispatch(loadPrescriptions(data, selectedMenuItem));
              dispatch(setSelectedPrescription(null));
          }
        );
      },
      fnSetLoader
    );
  };

  const onBtnSendSubmit = async (d, e) => {
    if(!areMedicationsValid()){
      await onSubmit();
      NotificationManager.warning(msgConcomitantsNotValid);
      return;
    }

    var obj = {      
      id: adverseEvent.id,
      state: AdverseEventStateEnum.pendingToVerify
    };

    await onSubmit();

    if(isNurseProfile()){
      setDlgConfirm(true);
      setObjSendFormat(obj);
      return;
    }

    await callSentFormatAsync(obj);
  };

  const isDisabled =()=>{
    if(adverseEvent && isNurseProfile()){
      if(adverseEvent.status === AdverseEventStateEnum.pendingQuery)
        return !(adverseEvent.pendingQuery && adverseEvent.pendingQuery.state !== QueryAdverseEventStateEnum.resolved && adverseEvent.pendingQuery.assignedToNurse)
      else
        return !(adverseEvent.status === AdverseEventStateEnum.pending || adverseEvent.status === AdverseEventStateEnum.inconsistentReport);
    }
    return true;
  };
  const isForeamDisabled =()=>{
    if(adverseEvent && isNurseProfile()){
      if(adverseEvent.status === AdverseEventStateEnum.pendingQuery)
        return !(adverseEvent.pendingQuery && adverseEvent.pendingQuery.state !== QueryAdverseEventStateEnum.resolved 
          && adverseEvent.pendingQuery.assignedToNurse && adverseEvent.pendingQuery.type === QueryAdverseEventTypeEnum.regulator)
      else
        return !(adverseEvent.status === AdverseEventStateEnum.pending || adverseEvent.status === AdverseEventStateEnum.inconsistentReport);
    }
    return true;
  };
  const isCiomsDisabled =()=>{
    if(adverseEvent && isNurseProfile()){
      if(adverseEvent.status === AdverseEventStateEnum.pendingQuery)
        return !(adverseEvent.pendingQuery && adverseEvent.pendingQuery.state !== QueryAdverseEventStateEnum.resolved 
          && adverseEvent.pendingQuery.assignedToNurse && adverseEvent.pendingQuery.type === QueryAdverseEventTypeEnum.partner)
      else
        return !(adverseEvent.status === AdverseEventStateEnum.pending || adverseEvent.status === AdverseEventStateEnum.inconsistentReport);
    }
    return true;
  };
  const disabled = isDisabled();
  const foreamDisabled = isForeamDisabled();
  const ciomsDisabled = isCiomsDisabled();

  const handleAddknownAdverseEvent = (data) =>{
    setKnownAdverseEvents([...knownAdverseEvents,data]);
    setValue("knownAdverseEvents",[...knownAdverseEventsValue, { label: data.name, value: data.id }]);
  }

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
      {adverseEvent && adverseEvent.pharmacovigilance && adverseEvent.pharmacovigilance.foreamLetterFile &&
        <div className="text-right mt-1 mb-1">
          <DownloadButton2
            variant="contained"
            className="jr-btn jr-btn-xs"
            color="primary"
            fileLink={adverseEvent.pharmacovigilance.foreamLetterFile}
            startIcon={<i className="zmdi zmdi-download" />}
          >
            <IntlMessages id="foream.reporterLetter" />
          </DownloadButton2>
        </div>
      }
      <div className="text-right">
        <BtnNullify adverseEvent={adverseEvent}/>
      </div>
      <FormProvider {...methods}>
        <form className="row" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <FormDatePicker
                name="creationDate"
                label={<IntlMessages id="core.date" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <FormInput
                name="country"
                label={<IntlMessages id="core.country" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <FormInput
                name="reportSource"
                label={<IntlMessages id="pharmacovigilance.reportSource" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <FormInput
                name="dosage"
                label={<IntlMessages id="core.dosage" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <FormInput
                name="administrationRoute"
                label={<IntlMessages id="formula.administrationRoute" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            {/* {(!adverseEvent || adverseEvent.productBehaviorId === ProductBehavior.remodulin ) &&
              <Grid item xs={12} md={6} lg={6}>
                <FormInput
                  name="infusionRate"
                  label={<IntlMessages id="formula.infusionRate" />}
                  variant="outlined"
                  margin="normal"
                  disabled={true}
                />
              </Grid>
            } */}
            { (!adverseEvent || (adverseEvent.formulaType === FormulaType.MIPRES &&
              adverseEvent.productBehaviorId !== ProductBehavior.remodulin &&
              adverseEvent.productBehaviorId !== ProductBehavior.dacepton)) &&
              <Grid item xs={12} md={4} lg={4}>
                <FormInput
                  name="supplyFrequency"
                  label={<IntlMessages id="formula.supplyFrequency" />}
                  variant="outlined"
                  margin="normal"
                  disabled={true}
                />
              </Grid>
            }

            {/* Patient info */}
            <Grid item xs={12} md={3} lg={3}>
              <FormDatePicker
                name="patientBirthDate"
                label={<IntlMessages id="adverseEvent.patientBirthDate" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormInput
                name="age"
                label={<IntlMessages id="core.age" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormInput
                name="documentType"
                label={<IntlMessages id="core.documentType" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormInput
                name="document"
                label={<IntlMessages id="adverseEvent.document" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormInput
                name="initialsPatientName"
                label={<IntlMessages id="adverseEvent.initialsPatientName" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormSelectAutoComplete
                name="gender"
                label={<IntlMessages id="adverseEvent.gender" />}
                options={genderrList}
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormInput
                name="weight"
                label={<IntlMessages id="core.weight" />}
                variant="outlined"
                margin="normal"
                disabled={foreamDisabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormInput
                name="size"
                label={<IntlMessages id="core.size" />}
                variant="outlined"
                margin="normal"
                disabled={foreamDisabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            {/* End patient info */}
                     
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="disease"
                label={<IntlMessages id="adverseEvent.principalDiagnostic" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="healthRegisterHolder"
                label={<IntlMessages id="adverseEvent.healthRegisterHolder" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="healthRegister"
                label={<IntlMessages id="adverseEvent.healthRegister" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="medicine"
                label={<IntlMessages id="core.product" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>            
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="medicineLotNumber"
                label={<IntlMessages id="medicineDelivery.lotNumber" />}
                variant="outlined"
                margin="normal"
                disabled={foreamDisabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormDatePicker
                name="medicineExpirationDate"
                label={<IntlMessages id="foream.medicineExpirationDate" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={foreamDisabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            


            <Grid item xs={12} md={5} lg={5}>
              <FormDatePicker
                name="adverseEventStartDate"
                label={<IntlMessages id="adverseEvent.adverseEventStartDate" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={10} md={6} lg={6}>
              <FormSelectAutoComplete
                name="knownAdverseEvents"
                label={<IntlMessages id="foream.knownAdverseEvent" />}
                isMulti
                options={knownAdverseEvents}
                margin="normal"
                disabled={foreamDisabled}
              />
            </Grid>
            {!foreamDisabled && 
              <Grid item xs={1} md={1} lg={1}>
                <div className="mt-3">
                  <AddknownAdverseEvent 
                    adverseEventId={!adverseEvent ? 0 : adverseEvent.id} 
                    callBack={handleAddknownAdverseEvent}/>
                </div>
              </Grid>
            }
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="descriptionAdverseEvent"
                label={<IntlMessages id="adverseEvent.descriptionFOREAM" />}
                variant="outlined"
                multiline
                rows={4}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="denouement"
                label={<IntlMessages id="foream.denouement" />}
                options={foreamDenouementList}
                margin="normal"
                disabled={foreamDisabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="seriousness"
                label={<IntlMessages id="foream.seriousness" />}
                options={foreamSeriousnessList}
                margin="normal"
                disabled={foreamDisabled}
                required={true}
                errorobj={errors}
              />
            </Grid>     
            {questionsYesNo.map((x, index) => {
              return (
                <Grid key={index} item xs={12} md={6} lg={6}>
                  <FormSelectAutoComplete
                    name={x.name}
                    label={x.label}
                    options={yesNoNotKnowList}
                    margin="normal"
                    disabled={foreamDisabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
              );
            })}

            <Grid item xs={12} md={12} lg={12}>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <label><strong><IntlMessages id="cioms.adverseReaction" /></strong></label>  
            </Grid>   
            {reactionList.map((x, index) => {
              return (
                <Grid key={index} item xs={12} md={6} lg={6}>
                  <FormCheckbox
                    name={x.name}
                    label={x.label}
                    onChange={chkHandleChange(x.name)}
                    disabled={ciomsDisabled}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12} md={12} lg={12}>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="reactionDecreased"
                label={<IntlMessages id="cioms.reactionDecreased" />}
                options={yesNoNAList}
                margin="normal"
                disabled={ciomsDisabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="reactionReappeared"
                label={<IntlMessages id="cioms.reactionReappeared" />}
                options={yesNoNAList}
                margin="normal"
                disabled={ciomsDisabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <FormDatePicker
                name="startTherapy"
                label={<IntlMessages id="pharmacovigilance.startTherapy" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <FormDatePicker
                name="endTherapy"
                label={<IntlMessages id="pharmacovigilance.endTherapy" />}
                format="YYYY-MM-DD"
                margin="normal"
                disabled={ciomsDisabled}
              />
            </Grid>            
            <Grid item xs={12} md={4} lg={4}>
              <FormInput
                name="therapyDays"
                label={<IntlMessages id="cioms.durationOfTherapy" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="anotherRelevantStory"
                label={<IntlMessages id="cioms.anotherRelevantStory" />}
                variant="outlined"
                multiline
                rows={4}
                margin="normal"
                disabled={ciomsDisabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="studyIdentificationCode"
                label={<IntlMessages id="pharmacovigilance.studyIdentificationCode" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormSelectAutoComplete
                name="type"
                label={<IntlMessages id="core.type"/>}
                options={ciomsTypeList}
                margin="normal"
                disabled={ciomsDisabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            {(typeValue === null || typeValue.value === CiomsTypeEnum.tracing) &&
              <Grid item xs={12} md={6} lg={6}>
                <FormSelectAutoComplete
                  name="followUpId"
                  label={<IntlMessages id="cioms.followUp" />}
                  options={followUpList}
                  margin="normal"
                  disabled={ciomsDisabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
            }
            <Grid item xs={12} md={6} lg={6}>
              <FormInput
                name="reportType"
                label={<IntlMessages id="pharmacovigilance.reportType" />}
                variant="outlined"
                margin="normal"
                disabled={true}
              />
            </Grid>            
          </Grid>
        </form>
      </FormProvider>
      <ConcomitantMedication
        readOnly={disabled}
        concomitantMedicationList={concomitantMedicationList}
        loadConcomitants={loadConcomitants}/>
      {!disabled &&
        <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4 mb-3">
          <ButtonAsync onClick={ adverseEvent && adverseEvent.status === AdverseEventStateEnum.pending ? onSubmit : handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
          >
            {<IntlMessages id="core.save" />}
          </ButtonAsync>
          {adverseEvent.status === AdverseEventStateEnum.pending &&
          <React.Fragment>
            <ButtonAsync onClick={handleSubmit(onBtnSendSubmit)}
              className="ml-3"
              variant="contained"
              color="primary"
            >
              {<IntlMessages id="adverseEvent.btnSendToPharmacovigilance" />}
            </ButtonAsync>
            <DialogConfirmAsync
              openDialog={dlgConfirm}
              title={<IntlMessages id="adverseEvent.dlgSendFormat.title" />}
              text={<IntlMessages id="adverseEvent.dlgSendFormat.confirmText" />}
              handleRequestClose={() => setDlgConfirm(false)}
              onAcceptClickAsync={async () => {
                await callSentFormatAsync(objSendFormat);
                setDlgConfirm(false);
              }}
            />
          </React.Fragment>
          }
        </div>
      }
    </div>      
  );
};

export default Farmacovigilance