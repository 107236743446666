import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button, Tooltip } from "@material-ui/core";
import Table, { minimalOptions, defaultCol, dateColumn } from "components/Form/Table";
import DialogGeneric from "components/Form/DialogGeneric";
import AddDelivery from "./AddDelivery";
import { callApi, getMedicineDeliveryListV2 } from "api/apiList";

const getDeliveriesCount = (deliveryList) => {
  return deliveryList.filter(x=>x.deliveryDate)
    .map(x=>x.medicineAmount)
    .reduce((prev, curr) => prev + curr, 0);
};

const DlgDeliveries = ({openDlg, requestCloseDlg, prescriptionId, medicineAmount, impact, marketchainId, numberDeliveries, allowEdit}) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [openDlgAddDelivery, setOpenDlgAddDelivery] = useState(false);
  const [deliveryList, setDeliveryList] = useState([]);
  const [currentDelivery, setCurrentDelivery] = useState(null);
  const dlgTitleText = IntlGetText("insuranceAuthorization.deliveries");
  const medicineText = IntlGetText("medicineDelivery.cardSubTitle");

  const loadData =()=>{
    if(!prescriptionId){
      setDeliveryList([]);
      return;
    }

    callApi(
      ()=>getMedicineDeliveryListV2(prescriptionId),
      setDeliveryList,
      fnSetLoader
    );
  };

  useEffect(loadData, [openDlg]);

  const getObj = (tableMeta) =>
    deliveryList.find((x) => x.id === tableMeta.rowData[0]);

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("dispensingPoint", "core.dispensingPoint"),
    defaultCol("deliveryModeText", "core.deliveryMode"),
    defaultCol("medicineAmount", "medicineDelivery.medAmountDlvr"),
    dateColumn("planningDate", "core.deliveryScheduledDate"),
    dateColumn("deliveryDate", "radicadoOLF.dlvrDate"),
    defaultCol("comment", "core.comment"),
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          if(!allowEdit)
            return "";
          return (
            <div>
              <Tooltip title={<IntlMessages id='core.edit' />} placement="bottom-end">
                <Button
                  onClick={() => {
                    setCurrentDelivery(rowObj);
                    setOpenDlgAddDelivery(true);
                    loadData();
                  }}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className="zmdi zmdi-edit"></i>
                </Button>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  return(
    <DialogGeneric
      open={openDlg}
      closeDialog={requestCloseDlg}
      titleText={dlgTitleText + " "+ `${deliveryList.filter(x=>x.deliveryDate).length}/${numberDeliveries ?? 0}`}
      maxWidth="md"
    >
      <h4>{medicineText + ` ${getDeliveriesCount(deliveryList)}/${medicineAmount}`}</h4>
      {allowEdit && 
        <div className="text-right mt-1 mb-1">
          <Button
            onClick={()=>setOpenDlgAddDelivery(true)}
            variant="contained"
            color="primary"
            className="ml-2"
          >
            {<IntlMessages id="core.add" />}
          </Button>
        </div>
      }
      <AddDelivery
        openDlg={openDlgAddDelivery}
        requestCloseDlg={()=>{
          setOpenDlgAddDelivery(false);
          setCurrentDelivery(null);
        }}
        callBack={loadData}
        impact={impact}
        marketchainId={marketchainId}
        delivery={currentDelivery}
      />
      <Table
        className="border-dialog-card mt-2 mb-2"
        data={deliveryList}
        columns={columns}
        options={minimalOptions()}
      />
    </DialogGeneric>
  );
};

export default DlgDeliveries;