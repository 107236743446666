import {
    LOAD_NEW_PATIENTS,
    LOAD_HOME,
  } from "../constants/ActionTypes";

  export const loadNewPatients = (newPatients) => {
    return {
      type: LOAD_NEW_PATIENTS,
      payload: newPatients,
    };
  };
  export const loadHome = (productLines, prescriptionStates) => {
    return {
      type: LOAD_HOME,
      payload: {
          productLines,
          prescriptionStates
        }
    };
  };