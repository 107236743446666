import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

let id = 0;

function createData(date, stage, status) {
  id += 1;
  return { id, date, stage, status };
}

const data = [
  createData(
    "2020/05/22",
    <div className=" badge text-uppercase text-white bg-light-blue">
      Formulación
    </div>,
    <div className=" badge text-uppercase text-white bg-success">Aprobado</div>
  ),
  createData(
    "2020/06/07",
    <div className=" badge text-uppercase text-white bg-light-blue">
      Radicación EPS
    </div>,
    <div className=" badge text-uppercase text-white bg-success">Aprobado</div>
  ),
  createData(
    "2020/06/25",
    <div className=" badge text-uppercase text-white bg-light-blue">
      Autorización
    </div>,
    <div className=" badge text-uppercase text-white bg-success">Aprobado</div>
  ),
  createData(
    "2020/07/14",
    <div className=" badge text-uppercase text-white bg-light-blue">
      Radicación OLF
    </div>,
    <div className=" badge text-uppercase text-white bg-amber">Pendiente</div>
  ),
  createData(
    "-/-/-",
    <div className=" badge text-uppercase text-white bg-teal">Entrega</div>,
    <div className=" badge text-uppercase text-white bg-grey">-</div>
  ),
];

function ManageStatus() {
  return (
    <div className="table-responsive-material">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell align="center">Estado</TableCell>
            <TableCell align="center">Etapa</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((n) => {
            return (
              <TableRow key={n.id}>
                <TableCell>{n.date}</TableCell>
                <TableCell align="center">{n.status}</TableCell>
                <TableCell align="center">{n.stage}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default ManageStatus;
