import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import { diagnosisConfirmation } from "constants/Enums";
import TableTestDisease from "./tableTestDisease.js";
import {
  callApi,
  callApiAsync,
  getDiagnosisSelects,
  saveDiagnosisRecordConfirmationTests,
  DeleteDiagnosticRecordConfirmationTestCommand,
  GetDiagnosisRecordConfirmationTestByDiagnosisRecordId
} from "api/apiList.js";
import { NotificationManager } from "react-notifications";

const FormTestDisease = ({
  rowObj2,
  impactId,
  openTestDisease,
  handleRequestClose,
  loadDiagnosisTable
}) => {
  const [rowObj, setCurrentObj] = useState(null);
  //Selects
  const [testDisease, setTestDisease] = useState(null);
  const [testDiseaseTable, setTestDiseaseTable] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [currentRowObj, setCurrentRowObj] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [diagnosisConfirmationMode, setDiagnosisConfirmationMode] = useState(
    null
  );
  const [
    diseaseConfirmationTestSelect,
    setDiseaseConfirmationTestSelect
  ] = useState([]);

  const defaultValues = {
    orphanDisease: false,
    diagnosisConfirmed: false,
    diagnosisDate: null
  };

  const loadSelect = () => {
    callApi(getDiagnosisSelects, (data) =>
      setDiseaseConfirmationTestSelect(data.diseaseConfirmationTestSelect)
    );
  };

  const msgSuccess = IntlGetText("core.SuccessSave");

  const validationSchema = {
    // testDisease: yup
    //   .object()
    //   .shape({ value: yup.string().required() })
    //   .required(<IntlMessages id="core.fieldRequired" />)
    //   .nullable()
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema))
  });
  const { handleSubmit, watch, setValue, reset, errors } = methods;

  const testDiseaseChange = (name) => (event) => {
    setTestDisease(event ? event.value : null);
  };

  const diagnosisConfirmationModeChange = (name) => (event) => {
    setDiagnosisConfirmationMode(event ? event.value : null);
  };

  const watchTestDisease = watch("testDisease");
  const diagnosisConfirmationModeList = diagnosisConfirmation();

  const onSubmit = async (d, e) => {
    if (!watchTestDisease) {
      NotificationManager.warning(
        "Debe seleccionar prueba confirmatoria de la enfermedad"
      );
      return;
    }

    if (!diagnosisConfirmationMode) {
      NotificationManager.warning(
        "Debe seleccionar modo de confirmación del diagnóstico"
      );
      return;
    }
    var existRecords = 0;
    let updatedList = [];

    for (const item of watchTestDisease) {
      const alreadyExists = testDiseaseTable.some(
        (entry) => entry.diseaseConfirmationTestId === item.value
      );
      if (!alreadyExists) {
        const newEntry = {
          diseaseConfirmationTestId: item.value,
          diseaseConfirmationTestTx: item.label,
          diagnosisConfirmationTypeId: diagnosisConfirmationMode
        };
        setTestDiseaseTable((prev) => [...prev, newEntry]);

        const dataToSend = {
          diseaseConfirmationTestId: item.value,
          diagnosisConfirmationTypeId: diagnosisConfirmationMode
        };

        // Agregar a la lista temporal
        updatedList.push(dataToSend);
      } else {
        existRecords++;
      }
    }

    setTestDisease(null);
    setValue("testDisease", null);

    setDiagnosisConfirmationMode(null);
    setValue("diagnosisConfirmationMode", null);
    if (existRecords > 0) {
      if (existRecords == watchTestDisease.length) {
        NotificationManager.warning(
          "Las opciones seleccionadas ya están registradas"
        );
        return;
      }
      NotificationManager.warning(
        "Algunas de las opciones seleccionadas ya están registradas. Solo se añadirán las nuevas opciones seleccionadas"
      );
    }

    var data = {
      diagnosisRecordConfirmationTests: updatedList,
      diagnosisRecordId: rowObj.id
    };
    await callApiAsync(
      () => saveDiagnosisRecordConfirmationTests(data),
      (response) => {
        NotificationManager.success(msgSuccess);
        setTestDiseaseTable(response);
      }
    );
  };

  useEffect(() => {
    loadSelect();
  }, []);

  useEffect(() => {
    setCurrentObj(rowObj2);
    if (rowObj2) {
      setDisabled(true);
    }
  }, [rowObj2]);

  const loadData = () => {
    callApi(
      () => GetDiagnosisRecordConfirmationTestByDiagnosisRecordId(rowObj2?.id),
      (data) => setTestDiseaseTable(data)
    );
  };

  const handleRequestCloseDel = () => {
    setOpenDeleteDialog(false);
  };

  useEffect(() => {
    if (openTestDisease) {
      setTimeout(loadData, 150);
    }
  }, [rowObj, openTestDisease]);

  const handleDelete = async (d, e) => {
    await callApiAsync(() =>
      DeleteDiagnosticRecordConfirmationTestCommand(currentRowObj[0])
    );
    setTestDiseaseTable((prev) =>
      prev.filter((item) => item.diseaseConfirmationTestId !== currentRowObj[2])
    );

    handleRequestCloseDel();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openTestDisease}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={"Diagnosis.confirmatoryTestDisease"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={5} lg={5}>
                  <FormSelectAutoComplete
                    name="testDisease"
                    label={
                      <IntlMessages id="Diagnosis.confirmatoryTestDisease" />
                    }
                    options={diseaseConfirmationTestSelect}
                    value={watch("testDisease")}
                    onChange={testDiseaseChange("testDisease")}
                    margin="normal"
                    isMulti
                    required={true}
                    errorobj={errors}
                  />
                </Grid>

                <Grid item xs={12} md={5} lg={5}>
                  <FormSelectAutoComplete
                    name="diagnosisConfirmationMode"
                    label={
                      <IntlMessages id="Diagnosis.DiagnosisConfirmationMode" />
                    }
                    options={diagnosisConfirmation()}
                    value={watch("diagnosisConfirmationMode")}
                    onChange={diagnosisConfirmationModeChange(
                      "diagnosisConfirmationMode"
                    )}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  lg={2}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <div className="text-center">
                    <Button
                      onClick={() => {
                        onSubmit();
                      }}
                      variant="contained"
                      color="primary"
                    >
                      {<IntlMessages id="core.add" />}
                    </Button>
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <TableTestDisease
                    data={testDiseaseTable}
                    handleOpenDel={() => setOpenDeleteDialog(true)}
                    setCurrentRowObj={setCurrentRowObj}
                    loadTable={loadData}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </div>

        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={openDeleteDialog}
          disableBackdropClick={true}
          onClose={handleRequestCloseDel}
        >
          <DialogTitle>
            <IntlMessages id="Diagnosis.MsgDelTestConfirmation" />
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleRequestCloseDel} color="secondary">
              <IntlMessages id="core.cancel" />
            </Button>
            <Button onClick={handleDelete} color="primary">
              <IntlMessages id="core.accept" />
            </Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormTestDisease;
