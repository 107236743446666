import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages from "util/IntlMessages";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";

const DialogAlert = ({open, dialogTitle , callBack, closeDialog}) => {
    const validationSchema = yup.object().shape({
        comment: yup.string().required(<IntlMessages id="core.fieldRequired" />)
    });    

    const defaultValues = { comment:"" };

    const methods = useForm({ defaultValues, resolver: yupResolver(validationSchema) });
    const { handleSubmit, errors, reset } = methods;
    
    const onSubmit = async (data, e) => { callBack(data.comment); };

    const handleRequestClose = () => {
        reset(defaultValues);
        closeDialog();
    };
    return (
        <Dialog open={open}
            fullWidth={true}
            maxWidth="sm"
            disableBackdropClick={true}
            onClose={handleRequestClose}
        >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent className="new-patient-dialog-content">
                <FormProvider {...methods}>
                    <form className="row" noValidate autoComplete="off">
                        <Grid item xs={12} md={12} lg={12}>
                            <FormInput
                            label={<IntlMessages id="core.comment" />}
                            name="comment"
                            required={true}
                            multiline
                            rows={5}
                            variant="outlined"
                            margin="normal"
                            errorobj={errors}
                            />
                        </Grid>
                    </form>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRequestClose} color="secondary">
                    {<IntlMessages id="core.cancel" />}
                </Button>
                <Button onClick={handleSubmit(onSubmit)} color="primary">
                    {<IntlMessages id="core.save" />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogAlert;