import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as dayjs from "dayjs";
import { setLoader } from "actions/Base";
import { IntlGetText } from "util/IntlMessages";
import { callApi, getReportBCRHema } from "api/apiList";
import Table, { defaultOptions, defaultCol } from "components/Form/Table";

const BCRHemogramaReport = ({ dateRange }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    if (!dateRange) return;

    callApi(
      () => getReportBCRHema(dateRange.startDate, dateRange.endDate),
      (data) => setReportData(data),
      fnSetLoader
    );
  }, [dateRange]);

  const getObj = (tableMeta) =>
    reportData.find((x) => x.code === tableMeta.rowData[0]);

  const columns = [
    {
      name: "code",
      label: IntlGetText("core.code"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return (
            <Link
              to={{
                pathname: "/app/patient/detail",
                prescriptionId: rowObj.prescriptionId,
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    defaultCol("patient", "core.patient"),
    defaultCol("patientState", "core.status"),
    {
      name: "formulationDate",
      label: IntlGetText("core.formulationDate"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    defaultCol("prescriptionState", "core.prescriptionState"),
    defaultCol("city", "core.city"),
    defaultCol("nurse", "core.activity"),
    defaultCol("doctor", "core.attendingDoctor"),
    defaultCol("eps", "core.insurance"),
    defaultCol("bcrDateTake", "reportBCRHemograma.bcrDateTake"),
    defaultCol("hemaDateTake", "reportBCRHemograma.hemaDateTake"),
  ];
  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-5 mb-5">
        <Table
          className="border-dialog-card mt-2 mb-2"
          data={reportData}
          columns={columns}
          options={defaultOptions()}
        />
      </div>
    </>
  );
};

export default BCRHemogramaReport;
