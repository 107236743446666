import React from "react";
import { currencyFormat } from "services/formatNumber";
import Table, {
  minimalOptions,
  defaultCol
} from "components/Form/Table";

const CausalTable = ({data, currentLocale}) => {
  const dataFormated = data.map((x) => ({
    Key: x.Key, 
    Value: ' $' + currencyFormat(currentLocale,x.Value)
  })); 
  const columns = [
    defaultCol("Key", "core.causal"),
    defaultCol("Value", "patientDetail.value"),
  ];
  return (
    <Table
      className="border-dialog-card mt-2 mb-2"
      data={dataFormated}
      columns={columns}
      options={minimalOptions()}
    />
  );
};

export default CausalTable;
