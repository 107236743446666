import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoCard from "components/InfoCard/index";

class ClinicalHistory extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <InfoCard
          data={{
            icon: "city-alt",
            title: "Historia",
            subTitle: "Clinica",
            color: "#FFA071",
          }}
          onClickBox={this.handleClickOpen}
        />
        <Dialog
          maxWidth="md"
          open={this.state.open}
          onClose={this.handleRequestClose}
        >
          <DialogTitle>
            Liberación de Información y Consentimiento Informado
          </DialogTitle>
          <DialogContent>
            <DialogTitle className="text-grey">
              Paraclinicos ultimos 6 meses
            </DialogTitle>
            <DialogTitle className="text-grey">
              Ordenes médica vigentes
            </DialogTitle>
            <DialogTitle className="text-grey">
              Uso de otros médicamentos
            </DialogTitle>
            <DialogTitle className="text-grey">
              Antecedentes patologicos
            </DialogTitle>
            <DialogTitle className="text-grey">
              Antecedentes alergicos
            </DialogTitle>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.handleRequestClose} color="primary">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ClinicalHistory;
