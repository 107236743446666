import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import FormCheckbox from "components/Form/Controls/checkbox";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete, {
  getCboValue
} from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { companyTestConfirmationEnum } from "constants/Enums";
import { callApi, getDiagnosisSelects } from "api/apiList";
import { useDispatch, useSelector } from "react-redux";

const FormD = ({ rowObj2, openD, handleRequestClose, handleCreateUpdate }) => {
  const { selectedPrescription } = useSelector(({ management }) => management);

  const [rowObj, setCurrentObj] = useState(null);
  //Selects
  const [familyHistory, setFamilyHistory] = useState("1");
  const [testInstitution, setTestInstitution] = useState("1");
  const [diagnosisSubDiagnosis, setDiagnosisSubDiagnosis] = useState(null);

  const [disabled, setDisabled] = useState(false);

  const testConfitmationList = companyTestConfirmationEnum();

  const defaultValues = {
    orphanDisease: false,
    diagnosisConfirmed: false,
    diagnosisDate: null
  };

  const validationSchema = {
    diagnosisSubDiagnosis: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    diagnosisDate: yup
      .date()
      .nullable()
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    companyTestConfirmation: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable()
  };

  const loadSelect = () => {
    callApi(getDiagnosisSelects, (data) =>
      setDiagnosisSubDiagnosis(data.diseasesSelect)
    );
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema))
  });
  const { handleSubmit, watch, setValue, reset, errors } = methods;

  const familyHistoryChange = (name) => (event) => {
    setFamilyHistory(event ? event.value : null);
  };

  const testInstitutionChange = (name) => (event) => {
    setTestInstitution(event ? event.value : null);
  };

  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);

  const onSubmit = async (d, e) => {
    var obj = {
      id: !rowObj ? 0 : rowObj.id,
      diseaseId: parseInt(d.diagnosisSubDiagnosis.value),
      personId: selectedPrescription.patient.id,
      diagnosisDate: !d.diagnosisDate
        ? null
        : dayjs(d.diagnosisDate).format("YYYY-MM-DDTHH:mm:ss"), 
      orphanDisease: d.orphanDisease,
      isConfirmed: d.diagnosisConfirmed,
      companyTestConfirmationId: parseInt(testInstitution)
    };
    await handleCreateUpdate(obj);
    handleRequestClose();
  };

  const loadData = () => {
    setValue("diagnosisDate", !rowObj ? null : rowObj.diagnosisDate);
    setValue(
      "diagnosisSubDiagnosis",
      getCboValue(diagnosisSubDiagnosis, !rowObj ? 0 : rowObj.diseaseId)
    );
    setValue(
      "companyTestConfirmation",
      getCboValue(
        testConfitmationList,
        !rowObj ? 0 : rowObj.companyTestConfirmationId
      )
    );
    setValue("orphanDisease", !rowObj ? false : rowObj.orphanDisease);
    setValue("diagnosisConfirmed", !rowObj ? false : rowObj.isConfirmed);
  };

  useEffect(() => {
    loadSelect();
  }, []);

  useEffect(() => {
    setCurrentObj(rowObj2);
    if (rowObj2) {
      setDisabled(true);
    }
  }, [rowObj2]);

  useEffect(() => {
    if (openD) {
      setTimeout(loadData, 150);
    }
  }, [rowObj, openD]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openD}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!rowObj ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6}>
                  <FormDatePicker
                    name="diagnosisDate"
                    label={<IntlMessages id="Diagnosis.DiagnosisDate" />}
                    format="YYYY-MM-DD"
                    disableFuture={true}
                    required={true}
                    margin="normal"
                    errorobj={errors}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="diagnosisSubDiagnosis"
                    label={
                      <IntlMessages id="Diagnosis.diagnosisSubDiagnosis" />
                    }
                    options={diagnosisSubDiagnosis}
                    value={watch("diagnosisSubDiagnosis")}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
  
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="companyTestConfirmation"
                    label={
                      <IntlMessages id="Diagnosis.companyTestConfirmation" />
                    }
                    options={testConfitmationList}
                    value={watch("companyTestConfirmation")}
                    onChange={testInstitutionChange("companyTestConfirmation")}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}></Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <FormCheckbox
                    name="orphanDisease"
                    label={<IntlMessages id="Diagnosis.OrphanDisease" />}
                    value={watch("orphanDisease")}
                    onChange={chkHandleChange("orphanDisease")}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <FormCheckbox
                    name="diagnosisConfirmed"
                    label={<IntlMessages id="Diagnosis.DiagnosisConfirmed" />}
                    value={watch("diagnosisConfirmed")}
                    onChange={chkHandleChange("diagnosisConfirmed")}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
            <ButtonAsync
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
            >
              {<IntlMessages id="core.save" />}
            </ButtonAsync>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormD;
