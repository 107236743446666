import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import InfoCard from "components/InfoCard/index";

import labels from "app/routes/psp/managepharma/data/labels";
import CustomScrollbars from "util/CustomScrollbars";

class MailDetail extends React.Component {
  state = {
    anchorEl: undefined,
    open: false,
    showDetail: false,
  };

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleClickCIBox = () => {
    console.log("CI CLICKED");
  };

  handleClickFMBox = () => {
    console.log("FM CLICKED");
  };

  render() {
    const {
      mail,
      onStartSelect,
      onImportantSelect,
      onProcessStateChange,
      width,
    } = this.props;
    const options = ["Reply", "Forward", "Print"];

    // Nuevo paciente
    if (mail.folder === 0) {
      return (
        <div className="module-detail mail-detail">
          <CustomScrollbars
            className="module-list-scroll scrollbar"
            style={{
              height:
                width >= 1200 ? "calc(100vh - 309px)" : "calc(100vh - 288px)",
            }}
          >
            <div className="mail-header">
              <div className="mail-header-content col pl-0">
                <div className="subject">
                  {mail.hasPatient &&
                    "Paciente: " +
                      mail.from.name +
                      " " +
                      mail.from.lastname +
                      " | "}
                  {mail.from.doctorName}
                </div>

                <div className="labels">
                  {labels.map((label, index) => {
                    return (
                      mail.labels.includes(label.id) && (
                        <div
                          key={index}
                          className={`badge text-uppercase text-white bg-${label.color}`}
                        >
                          {label.title}
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
              {/* Medico autoriza */}
              {mail.states.length === 0 && (
                <div className="mail-header-actions">
                  ¿Verificar?
                  <Button
                    onClick={() => {
                      onProcessStateChange(1);
                    }}
                    variant="contained"
                    className="ml-3 jr-btn bg-green text-white"
                  >
                    <i className="zmdi zmdi-thumb-up zmdi-hc-fw" />
                  </Button>
                  <Button
                    variant="contained"
                    className="jr-btn bg-red text-white"
                  >
                    <i className="zmdi zmdi-thumb-down zmdi-hc-fw" />
                  </Button>
                </div>
              )}
              {/* Paciente autoriza */}
              {mail.labels.includes(7) &&
                !mail.states.includes(8) &&
                mail.hasPatient && (
                  <div className="mail-header-actions">
                    ¿Paciente Autoriza?
                    <Button
                      onClick={() => {
                        onProcessStateChange(7);
                        onProcessStateChange(8);
                      }}
                      variant="contained"
                      className="ml-3 jr-btn bg-green text-white"
                    >
                      <i className="zmdi zmdi-thumb-up zmdi-hc-fw" />
                    </Button>
                    <Button
                      variant="contained"
                      className="jr-btn bg-red text-white"
                    >
                      <i className="zmdi zmdi-thumb-down zmdi-hc-fw" />
                    </Button>
                  </div>
                )}
            </div>
            <hr />

            {!mail.states.includes(0) && (
              <div className="row">
                <div className="col-sm-6 col-12">
                  <InfoCard
                    data={{
                      icon: "search-in-file",
                      title: "Detalle",
                      subTitle: "Evento",
                      color: "#00bcd4",
                    }}
                    onClickBox={() => {
                      return false;
                    }}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <InfoCard
                    data={{
                      icon: "attachment-alt",
                      title: "Adjuntos",
                      subTitle: "",
                      color: "#3f51b5",
                    }}
                    onClickBox={() => {
                      return false;
                    }}
                  />
                </div>
              </div>
            )}
          </CustomScrollbars>
        </div>
      );
    }

    if (mail.folder === 1 || mail.folder === 2) {
      return (
        <div className="module-detail mail-detail">
          <CustomScrollbars
            className="module-list-scroll scrollbar"
            style={{
              height:
                width >= 1200 ? "calc(100vh - 309px)" : "calc(100vh - 288px)",
            }}
          >
            <div className="mail-header">
              <div className="mail-header-content col pl-0">
                <div className="subject">{mail.subject}</div>

                <div className="labels">
                  {labels.map((label, index) => {
                    return (
                      mail.labels.includes(label.id) && (
                        <div
                          key={index}
                          className={`badge text-white bg-${label.color}`}
                        >
                          {label.title}
                        </div>
                      )
                    );
                  })}
                </div>
              </div>

              <div className="mail-header-actions"></div>
            </div>
            <hr />

            <div className="row">
              <div className="col-sm-6 col-12">
                <InfoCard
                  data={{
                    icon: "file",
                    title: "CIOMS",
                    subTitle: "",
                    color: "#ffc107",
                  }}
                  onClickBox={() => {
                    return false;
                  }}
                />
              </div>
              <div className="col-sm-6 col-12">
                <InfoCard
                  data={{
                    icon: "file",
                    title: "Tecnovigilancia",
                    subTitle: "",
                    color: "#A2ADB8",
                  }}
                  onClickBox={() => {
                    return false;
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-12">
                <InfoCard
                  data={{
                    icon: "file",
                    title: "FOREAM",
                    subTitle: "",
                    color: "#2196F3",
                  }}
                  onClickBox={() => {
                    return false;
                  }}
                />
              </div>
            </div>
          </CustomScrollbars>
        </div>
      );
    }

    return (
      <div className="module-detail mail-detail">
        <CustomScrollbars
          className="module-list-scroll scrollbar"
          style={{
            height:
              width >= 1200 ? "calc(100vh - 309px)" : "calc(100vh - 288px)",
          }}
        >
          <div className="mail-header">
            <div className="mail-header-content col pl-0">
              <div className="subject">{mail.subject}</div>

              <div className="labels">
                {labels.map((label, index) => {
                  return (
                    mail.labels.includes(label.id) && (
                      <div
                        key={index}
                        className={`badge text-white bg-${label.color}`}
                      >
                        {label.title}
                      </div>
                    )
                  );
                })}
              </div>
            </div>

            <div className="mail-header-actions">
              <IconButton
                type="button"
                className="icon-btn"
                onClick={() => {
                  onStartSelect(mail);
                }}
              >
                {mail.starred ? (
                  <i className="zmdi zmdi-star" />
                ) : (
                  <i className="zmdi zmdi-star-outline" />
                )}
              </IconButton>
              <IconButton
                type="button"
                className="icon-btn"
                onClick={() => {
                  onImportantSelect(mail);
                }}
              >
                {mail.important ? (
                  <i className="zmdi zmdi-label-alt" />
                ) : (
                  <i className="zmdi zmdi-label-alt-outline" />
                )}
              </IconButton>
            </div>
          </div>
          <hr />

          <div className="mail-user-info">
            {mail.from.avatar === "" ? (
              <div
                className="bg-blue avatar rounded-circle size-40 text-white text-center"
                style={{ fontSize: 16 }}
              >
                {" "}
                {mail.from.name.charAt(0).toUpperCase()}
              </div>
            ) : (
              <img
                className="rounded-circle avatar size-40"
                alt="Alice Freeman"
                src={mail.from.avatar}
              />
            )}

            <div className="sender-name">
              {mail.from.name}
              <div className="send-to text-grey">to me</div>
            </div>

            <IconButton
              aria-label="More"
              aria-owns={this.state.open ? "long-SidenavContent.js" : null}
              aria-haspopup
              onClick={this.handleClick}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              id="long-menu"
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              onClose={this.handleRequestClose}
              MenuListProps={{
                style: {
                  width: 200,
                },
              }}
            >
              {options.map((option) => (
                <MenuItem key={option} onClick={this.handleRequestClose}>
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>

          <div
            className="show-detail"
            onClick={() => {
              this.setState({ showDetail: !this.state.showDetail });
            }}
          >
            {this.state.showDetail ? "Hide Detail" : "Show Detail"}
          </div>
          {this.state.showDetail && (
            <div>
              <div>
                <strong>From: </strong>
                {mail.from.email}
              </div>
              <div>
                <strong> To: </strong>
                {mail.to.map((to, index) => (
                  <span>
                    {index > 0 && ", "} {to.email}
                  </span>
                ))}
              </div>
              <div>
                <strong>Date: </strong>
                {mail.time}{" "}
              </div>
            </div>
          )}

          <p className="message">{mail.message}</p>

          {mail.hasAttachments && (
            <div className="attachment-block">
              <h3>Attachments ({mail.hasAttachments.length})</h3>
              <div className="row">
                {mail.attachments.map((attachment, index) => (
                  <div className="col-3" key={index}>
                    <img
                      className="size-100"
                      src={attachment.preview}
                      alt={attachment.fileName}
                    />
                    <div className="size">{attachment.size}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </CustomScrollbars>
      </div>
    );
  }
}

export default MailDetail;
