import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { Grid } from "@material-ui/core";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import FormInput from "components/Form/Controls/input";
import DialogAsync from "components/Form/DialogAsync";
import FormDateTimePicker from "components/Form/Controls/dateTimePicker";
import { callApiAsync, postAddIntitutionImpact } from "api/apiList";

const NextFollowUpForm = ({ openDlg, closeDialog, impactInfo, setImpactInfo, followInfo }) => {
  const msgSuccess = IntlGetText("core.SuccessSave");

  const defaultValues = {
    startDateAndTime: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
    attendingNurseNew: "",
    procedureToMake: "",
  };

  const validationSchema = {
    startDateAndTime: yup.date()
    .nullable(true)
    .typeError(IntlGetText("yup.InvalidDate"))
    .required(<IntlMessages id="core.fieldRequired" />),
    attendingNurseNew: yup
      .string()
      .required(<IntlMessages id="core.fieldRequired" />),
    procedureToMake: yup
      .string()
      .required(<IntlMessages id="core.fieldRequired" />),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, errors, setValue } = methods;

  useEffect(()=>{
    if(!openDlg || !followInfo)
      return;
    setTimeout(() => {
      setValue("startDateAndTime", !followInfo || !followInfo.planingDate ? null : dayjs(followInfo.planingDate));
      setValue("attendingNurseNew", !followInfo || !followInfo.nurse ? "" : followInfo.nurse);
      setValue("procedureToMake", !followInfo || !followInfo.process ? "" : followInfo.process);
    }, 200);

  },[openDlg]);

  const onSubmit = handleSubmit(async (d) => {
    var obj = {
      id: !followInfo ? 0 : followInfo.id,
      patientId: !followInfo ? impactInfo.patientId : followInfo.patientId,
      planningDate: dayjs(d.startDateAndTime).format("YYYY-MM-DDTHH:mm:ss"),
      nurse: d.attendingNurseNew,
      process: d.procedureToMake,
    };

    await callApiAsync(
      ()=>postAddIntitutionImpact(obj),
      ()=>{
        NotificationManager.success(msgSuccess);
        if(setImpactInfo)
          setImpactInfo({...impactInfo, anyPlanned: true});
        closeDialog();
      }
    );
  });

  return (
    <div>
      <DialogAsync
        open={openDlg}
        closeDialog={closeDialog}
        onSubmitAsync={onSubmit}
        titleTextId={"institution.nextFollowUp"}
        allowEdit={true}
      >
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <FormDateTimePicker
                  name="startDateAndTime"
                  label={IntlGetText("core.nextProcedureDateAndTime")}
                  format="YYYY-MM-DD hh:mm A"
                  disablePast={true}
                  leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                  rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormInput
                  name="attendingNurseNew"
                  label={<IntlMessages id="core.attendingNurse" />}
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormInput
                  label={<IntlMessages id="institution.procedureTo" />}
                  name="procedureToMake"
                  multiline
                  rows={3}
                  variant="outlined"
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogAsync>
    </div>
  );
};

export default NextFollowUpForm;
