import cyan from '@material-ui/core/colors/cyan';
import green from '@material-ui/core/colors/green';

const baseTheme = {
  palette: {
    primary: {
      light: cyan[300],
      main: cyan[500],
      dark: cyan[700],
      contrastText: '#fff'
    },
    secondary: {
      light: green[300],
      main: green['A200'],
      dark: green[700],
      contrastText: '#fff'
    }
  },
  status: {
    danger: 'orange',
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    },
  },
  
};

export const genericTheme = {
  ...baseTheme,
  imgLogin:{
    src:require("assets/images/logo.png"),
    alt:"intelcare",
    title:"intelcare"
  },
  pageTitle:'PSP - Intelcare',
  footerText:'Intelcare @ '+new Date().getFullYear(),
  favicon: "/favicon.ico"
};

export const spintPlusTheme = {
  ...baseTheme,
  imgLogin:{
    src:require("assets/images/logo_spintplus.png"),
    alt:"Pint Acompaña",
    title:"Pint Acompaña"
  },
  pageTitle:'PSP - PintPharma',
  footerText:'PintPharma @ '+new Date().getFullYear(),
  favicon: "/favicon_pint.ico"
};