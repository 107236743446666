import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as dayjs from "dayjs";
import { setLoader } from "actions/Base";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import {
  callApi,
  getRemodulinPumpReport,
  getRemodulinPumpReportFile,
} from "api/apiList";
import { downloadFile } from "services/fileService";
import Table, { defaultOptions, defaultCol } from "components/Form/Table";

const RemodulinPumpsReport = ({ dateRange }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [reportData, setReportData] = useState([]);

  const downloadReport = () => {
    callApi(
      () => getRemodulinPumpReportFile(dateRange.startDate, dateRange.endDate),
      (data) => downloadFile(data),
      fnSetLoader
    );
  };

  useEffect(() => {
    if (!dateRange) return;

    callApi(
      () => getRemodulinPumpReport(dateRange.startDate, dateRange.endDate),
      (data) => setReportData(data),
      fnSetLoader
    );
  }, [dateRange]);

  const getObj = (tableMeta) =>
    reportData.find((x) => x.code === tableMeta.rowData[0]);

  const columns = [
    {
      name: "code",
      label: IntlGetText("core.code"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getObj(tableMeta);
          return (
            <Link
              to={{
                pathname: "/app/patient/detail",
                prescriptionId: rowObj.prescriptionId,
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    defaultCol("nurse", "core.activity"),
    defaultCol("city", "core.city"),
    {
      name: "impactDate",
      label: IntlGetText("core.impactDate"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    defaultCol("lotNumber", "medicineDelivery.lotNumber"),
    defaultCol("serial", "core.serial"),
    defaultCol("model", "core.model"),
    defaultCol("state", "core.state"),
  ];
  return (
    <>
      <div className="col-lg-12 col-md-12 col-xs-12 text-left mt-2 mb-4">
        <Button
          onClick={downloadReport}
          variant="contained"
          color="secondary"
          disabled={dateRange === null}
        >
          <IntlMessages id="core.download" />
        </Button>
      </div>

      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-5 mb-5">
        <Table
          className="border-dialog-card mt-2 mb-2"
          data={reportData}
          columns={columns}
          options={defaultOptions()}
        />
      </div>
    </>
  );
};

export default RemodulinPumpsReport;
