import React, { PureComponent } from "react";
import {
  Pie,
  PieChart,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
} from "recharts";

import { dataProgramAdherence, data } from "./data";

import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import CardBox from "components/CardBox";
import YearlyProfitChart from "components/dashboard/eCommerce/YearlyProfitChart";
import { Link } from "react-router-dom";

const COLORS = ["#00C49F", "#0088FE", "#3367d6", "#FFBB28", "#FF8042"];

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text
        x={x}
        y={y}
        dy={-10}
        fill={stroke}
        fontSize={10}
        textAnchor="middle"
      >
        {value + "%"}
      </text>
    );
  }
}

const remodulin = (props) => {
  return (
    <div className="app-wrapper">
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <ContainerHeader
          match={props.match}
          title={<IntlMessages id="kpi.productLine" />}
        />
        <div className="row">
          <CardBox
            heading={<IntlMessages id="kpi.productLine.programAdherence" />}
            styleName="col-md-8 text-center"
          >
            <ResponsiveContainer width="100%" height={250}>
              <LineChart
                data={dataProgramAdherence}
                margin={{ top: 10, right: 0, left: -25, bottom: 0 }}
              >
                <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                <YAxis />
                <CartesianGrid strokeDasharray="1 1" />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="uv"
                  stroke="#3367d6"
                  fill="#3367d6"
                  name="Pacientes Activos"
                  label={<CustomizedLabel />}
                />
              </LineChart>
            </ResponsiveContainer>
          </CardBox>

          <CardBox
            heading={<IntlMessages id="kpi.productLine.proportionPatients" />}
            styleName="col-md-4 text-center"
          >
            <div>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart>
                  <Legend />
                  <Pie
                    dataKey="value"
                    data={data}
                    cx="50%"
                    cy="50%"
                    innerRadius={35}
                    outerRadius={55}
                    fill="#3367d6"
                    label
                  >
                    {data.map((entry, index) => (
                      <Cell key={index} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </CardBox>
        </div>

        <div className="row">
          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 col-12">
            <Link to="/app/productline/remodulin" title="Remodulin">
              <div className="jr-card">
                <ResponsiveContainer width="95%" height={70}>
                  <img
                    className=""
                    src={require("assets/images/psp/kpi/remodulin.PNG")}
                    alt="Remodulin"
                    title="Remodulin"
                  />
                </ResponsiveContainer>
                <ResponsiveContainer width="100%" height={200}>
                  <YearlyProfitChart
                    chartId="remodulin"
                    centerText="57"
                    height={100}
                    chartType="newsDoughnut"
                    backgroundColor={["#0088FE", "#eee"]}
                    borderColor={["#0088FE", "#eee"]}
                    hoverBorderColor={["#0088FE", "#eee"]}
                    hoverBorderWidth={[1, 1]}
                    textColor="#413ea0"
                    labels={["Nuevos", "Existentes"]}
                    dataControl={[57, 43]}
                    rotation={50}
                    cutoutPercentage={90}
                    fontSize={5}
                  />
                </ResponsiveContainer>
              </div>
            </Link>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 col-12">
            <Link to="/app/productline/iclusig" title="Iclusig">
              <div className="jr-card">
                <ResponsiveContainer width="95%" height={70}>
                  <img
                    className=""
                    src={require("assets/images/psp/kpi/iclusig.PNG")}
                    alt="Iclusig"
                    title="Iclusig"
                  />
                </ResponsiveContainer>
                <ResponsiveContainer width="100%" height={200}>
                  <YearlyProfitChart
                    chartId="iclusig"
                    height={100}
                    chartType="newsDoughnut"
                    backgroundColor={["#0088FE", "#eee"]}
                    borderColor={["#0088FE", "#eee"]}
                    hoverBorderColor={["#0088FE", "#eee"]}
                    hoverBorderWidth={[1, 1]}
                    textColor="#413ea0"
                    labels={["Nuevos", "Existentes"]}
                    centerText="71"
                    dataControl={[71, 29]}
                    cutoutPercentage={90}
                    fontSize={5}
                  />
                </ResponsiveContainer>
              </div>
            </Link>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 col-12">
            <div className="jr-card">
              <ResponsiveContainer width="95%" height={70}>
                <img
                  className=""
                  src={require("assets/images/psp/kpi/kalbitor.PNG")}
                  alt="Kalbitor"
                  title="Kalbitor"
                />
              </ResponsiveContainer>
              <ResponsiveContainer width="100%" height={200}>
                <YearlyProfitChart
                  chartId="kalbitor"
                  centerText="5"
                  height={100}
                  chartType="newsDoughnut"
                  backgroundColor={["#0088FE", "#eee"]}
                  borderColor={["#0088FE", "#eee"]}
                  hoverBorderColor={["#0088FE", "#eee"]}
                  hoverBorderWidth={[1, 1]}
                  textColor="#413ea0"
                  labels={["", ""]}
                  dataControl={[5, 95]}
                  cutoutPercentage={90}
                  fontSize={5}
                />
              </ResponsiveContainer>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 col-12">
            <div className="jr-card">
              <ResponsiveContainer width="95%" height={70}>
                <img
                  className=""
                  src={require("assets/images/psp/kpi/galafold.PNG")}
                  alt="Galafold"
                  title="Galafold"
                />
              </ResponsiveContainer>
              <ResponsiveContainer width="100%" height={200}>
                <YearlyProfitChart
                  chartId="galafold"
                  centerText="5"
                  height={100}
                  chartType="newsDoughnut"
                  backgroundColor={["#0088FE", "#eee"]}
                  borderColor={["#0088FE", "#eee"]}
                  hoverBorderColor={["#0088FE", "#eee"]}
                  hoverBorderWidth={[1, 1]}
                  textColor="#413ea0"
                  labels={["", ""]}
                  dataControl={[5, 95]}
                  cutoutPercentage={90}
                  fontSize={5}
                />
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default remodulin;
