import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormDateTimePicker from "components/Form/Controls/dateTimePicker";
import ButtonAsync from "components/Form/Controls/ButtonAsync";

const FormCRU = ({
  radicado,
  impactId,
  openCRU,
  handleRequestClose,
  handleCreateUpdate,
}) => {
  const msgSuccess = IntlGetText("core.SuccessSave");

  const defaultValues = {
    radicacionDate: null,
    code: "",
    radicacionPoint: "",
    attentionScopeText: "",
  };
  const validationSchema = {
    radicacionDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate")),
      //.required(<IntlMessages id="core.fieldRequired" />),
    code: yup
      .string()
      .matches(/^[a-z0-9_-]+$/i, { message: IntlGetText("yup.alphanumericValidation"), excludeEmptyString: true }),
    radicacionPoint: yup
      .string()
      .required(<IntlMessages id="core.fieldRequired" />),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, errors } = methods;

  useEffect(() => {
    if (openCRU) {
      setTimeout(loadData, 150);
    }
  }, [openCRU]);

  const loadData = () => {
    setValue(
      "radicacionDate",
      !radicado || !radicado.radicacionDate ? null : dayjs(radicado.radicacionDate)
    );
    setValue("code", !radicado ? "" : radicado.code);
    setValue("radicacionPoint", !radicado ? "" : radicado.radicacionPoint);
    setValue(
      "attentionScopeText",
      !radicado || !radicado.attentionScopeText
        ? ""
        : radicado.attentionScopeText
    );
  };

  const onSubmit = async (d, e) => {
    const obj = {
      id: !radicado ? 0 : radicado.id,
      impactId,
      radicacionDate: !d.radicacionDate ? null : dayjs(d.radicacionDate).format("YYYY-MM-DDTHH:mm:ss"),
      code: d.code,
      radicacionPoint: d.radicacionPoint,
    };
    await handleCreateUpdate(obj);
  };

  const disabled = false;

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!radicado ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6}>
                  <FormDateTimePicker
                    name="radicacionDate"
                    label={
                      <IntlMessages id="radicacionInsurance.RadicacionDate" />
                    }
                    format="YYYY-MM-DD hh:mm A"
                    disableFuture={true}
                    margin="normal"
                    disabled={radicado && radicado.hasAuthorizations}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="code"
                    label={<IntlMessages id="radicacionInsurance.Code" />}
                    margin="normal"
                    disabled={radicado && radicado.hasAuthorizations}
                    required={false}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="radicacionPoint"
                    label={
                      <IntlMessages id="radicacionInsurance.radicacionPoint" />
                    }
                    margin="normal"
                    disabled={radicado && radicado.hasAuthorizations}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                {radicado && radicado.attentionScopeText && (
                  <Grid item xs={12} md={12} lg={6}>
                    <FormInput
                      name="attentionScopeText"
                      label={<IntlMessages id="formula.attentionScope" />}
                      margin="normal"
                      disabled={radicado ? true : true}
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          </FormProvider>
          <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
            <ButtonAsync onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
            >
              {<IntlMessages id="core.save" />}
            </ButtonAsync>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormCRU;
