import React from "react";
import { IntlGetText } from "util/IntlMessages";
import * as dayjs from "dayjs";
import MUIDataTable from "mui-datatables";
import { VariableGroupTypeList, ControlTypeEnum } from "constants/Enums";

const ClinicHistoryTable = ({ options, data }) => { 
    const variableGroupTypeList = VariableGroupTypeList();
    const textYes = IntlGetText("core.yes");
    const textNo = IntlGetText("button.no");
    
    const getObj = (tableMeta) => data.find(x=>x.id === tableMeta.rowData[0]);
  
    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          searchable: false,
          display:"excluded"
        }
      },
      {
        name: "variableGroupType",
        label: IntlGetText("core.type"),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const type = variableGroupTypeList.find(x=>x.id === value);
            return !type ? value : type.name;
          },
        }
      },
      {
        name: "variableGroup",
        label: IntlGetText("core.variableGroup"),
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "date",
        label: IntlGetText("core.impact"),
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value ? dayjs(value).format("YYYY/MM/DD") : "";
          },
        },
      },
      {
        name: "name",
        label: IntlGetText("patientDetail.property"),
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "value",
        label: IntlGetText("patientDetail.value"),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            var rowObj = getObj(tableMeta);
  
            switch(rowObj.controlType){
              case ControlTypeEnum.dateTime: return value ? dayjs(value).format("YYYY/MM/DD") : "";
              case ControlTypeEnum.checkbox: return (!value || value === "false") ? textNo : textYes;
            }
  
            return value;
          },
        },
      }
    ];
  
    return (
      <MUIDataTable
        className="border-dialog-card"
        data={data}
        columns={columns}
        options={options}
      />
    );
  };

  export default ClinicHistoryTable;