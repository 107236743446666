import enLang from './entries/en-US';
import esLang from './entries/es_ES';


const AppLocale = {
  en: enLang,
  es: esLang,
};

export default AppLocale;
