import {
    LOAD_NEW_PATIENTS,
    LOAD_HOME
  } from "constants/ActionTypes";
  
const INIT_STATE = {
    newPatients:[],
    productLines:[],
    prescriptionStates:[],
  };
 

  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case LOAD_NEW_PATIENTS: {
          return { ...state, newPatients: action.payload };
      }
      case LOAD_HOME: {
          return { ...state, 
              productLines: action.payload.productLines,
              prescriptionStates: action.payload.prescriptionStates
            };
      }
      default:
        return state;
    }
  };
  