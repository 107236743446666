import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import DayjsUtils from "@date-io/dayjs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePicker } from "@material-ui/pickers";

const MuiDateTimePicker = (props) => {
  const { name, required, errorobj } = props;

  let isError = false;
  let errorMessage = "";
  if (errorobj && errorobj.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errorobj[name].message;
  }

  return (
    <React.Fragment>
      <DateTimePicker
        inputVariant="outlined"
        margin="dense"
        size="small"
        fullWidth={true}
        hideTabs
        InputLabelProps={{
          className: required ? "required-label" : "",
          required: required || false,
        }}
        error={isError}
        helperText={errorMessage}
        {...props}
      />
    </React.Fragment>
  );
};

function FormDateTimePicker(props) {
  const { control } = useFormContext();
  const {
    name,
    label,
    defaultValue,
    value,    
    onChange,
    ...dateProps
  } = props;

  // const {
  //   className,
  //   name,
  //   label,
  //   defaultValue,
  //   format,
  //   required,
  //   errorobj,
  //   value,
  //   margin,
  //   disableFuture,
  //   disablePast,
  //   minDate,
  // } = props;

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
         <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          value={value}
          render={({ onBlur, onChange, value }) => (
            <MuiDateTimePicker
              name={name}
              label={label}
              onChange={(value) => {
                onChange(value);
                if (props.onChange) props.onChange(value);
              }}
              value={value}
              {...dateProps}
            />
          )}
        />
        {/* <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          value={value}
          render={({ onBlur, onChange, value }) => (
            <MuiDateTimePicker
              name={name}
              label={label}
              onChange={(value) => {
                onChange(value);
                if (props.onChange) props.onChange(value);
              }}
              format={format}
              required={required}
              errorobj={errorobj}
              value={value}
              margin={margin}
              disableFuture={disableFuture}
              disablePast={disablePast}
              minDate={minDate}
              className={className}
            />
          )}
        /> */}
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
}

export default FormDateTimePicker;
