import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages from "util/IntlMessages";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete, { getCboValue } from "components/Form/Controls/select-autocomplete";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import InputFile from "components/Form/inputFile";
import { TrainingResourceTypeList, TrainingResourceTypeEnum } from "constants/Enums";

const FormCreateUpdate = ({ openCRU, handleRequestClose, eventId, obj, handleCreateUpdate, readOnly}) => {  
  const [fileResult, setFileResult] = useState({fileName:"", originalName:""});

  const trainingResourceTypeList = TrainingResourceTypeList();

  const defaultValues = {
    name: "",
    description: "",
    link: "",
    type: null
  };
  const validationSchema = { 
    name: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    description: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    link: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    type: yup
    .object()
    .shape({ value: yup.string().required() })
    .required(<IntlMessages id="core.fieldRequired" />)
    .nullable()
  };

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, reset, errors, getValues } = methods;
  const typeValue = watch("type")
  const linkValue = watch("link")

  const loadData = () => {
    setValue("name", !obj || !obj.name ? "" : obj.name);
    setValue("description", !obj || !obj.description ? "" : obj.description);
    setValue("link", !obj || !obj.url ? "" : obj.url);
    setValue("type", !obj || (!obj.type && obj.type !== 0) ? "" : getCboValue(trainingResourceTypeList, obj.type));
  };

  useEffect(()=>{
    if(openCRU){
      setTimeout(loadData,150);
    }
  },[openCRU]);


  const onSubmit = async (d, e) => {
    var item = {
      id: !obj ? 0 : obj.id,
      eventTrainingId: eventId,
      name: d.name,
      description: d.description,
      url: d.link,
      type: Number(d.type.value)
    };
    await handleCreateUpdate(item);
  };

  const disabled = readOnly;
  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!obj ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <FormInput
                    name="name"
                    label={<IntlMessages id="core.name" />}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <FormInput
                    name="description"
                    label={<IntlMessages id="core.description" />}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormSelectAutoComplete
                    name="type"
                    label={<IntlMessages id="core.type" />}
                    options={trainingResourceTypeList}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                      <FormInput
                      name="link"
                      label={<IntlMessages id="core.url" />}
                      margin="normal"
                      disabled={disabled}
                      required={true}
                      errorobj={errors}
                      hidden={(typeValue && typeValue.value !== TrainingResourceTypeEnum.video)}
                      />
                    {typeValue && typeValue.value === TrainingResourceTypeEnum.file &&
                      <InputFile
                        name="link"
                        file={linkValue}
                        fileResult={fileResult}
                        setFileResult={(f)=>{
                          setFileResult(f)
                          setValue("link",f.fileName)
                        }}
                        errors={errors}/>
                    }
                  </Grid>
              </Grid>
            
            </form>
          </FormProvider>
          { !disabled &&
            <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
              <ButtonAsync onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
              >
                {<IntlMessages id="core.save" />}
              </ButtonAsync>
            </div>
          }
        </div>  
      </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>  
  );
};
export default FormCreateUpdate;
