import React, { useState} from "react";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import CardBox from "components/CardBox/index";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import Table, { getTextLabels, defaultCol } from "components/Form/Table";
import { TrainingResourceTypeEnum } from "constants/Enums";

const processData = (eventTrainingInfo) => {
  if(!eventTrainingInfo || !eventTrainingInfo.eventTrainingFile)
    return [];

  const onClick = (fileLink, type) => () => {
    const link = document.createElement("a");
    link.href = fileLink;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return eventTrainingInfo.eventTrainingFile.map((x) => {
    return {
      ...x,
      url: (
        <Button
          onClick={onClick(x.url, x.type)}
          variant="contained"
          className="jr-btn jr-btn-xs"
        >
          <i className={"zmdi zmdi-"+ (x.type === TrainingResourceTypeEnum.video ? "link" : "download") }></i>
        </Button>
      ),
    };
  });
};

const Resources = ({eventTrainingInfo, typeId}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true)
  const handleRequestClose = () => setOpen(false)

  const columns = [
    defaultCol('name','core.name'),
    defaultCol('description','core.description'),
    {
      name: "url",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: getTextLabels(),
  };

  return (
    <div>
      <InfoCard
        data={{
          icon: "collection-video",
          title:  IntlGetText("sidebar.view"),
          subTitle: typeId !== 2 ? IntlGetText("core.presentation"): IntlGetText("core.material"),
          color: "#757575",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="eventTraining.resourcesTile" />
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <CardBox styleName="col-12" cardStyle="p-0 border-dialog-card">
              <Table
                className="border-dialog-card"
                data={processData(eventTrainingInfo)}
                columns={columns}
                options={options}
              />
            </CardBox>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Resources;
