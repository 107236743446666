import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import FormDatePicker from "components/Form/Controls/datepicker";

const BtnActivatePatient = ({openDlg, handleRequestClose, obj, onActivePatient}) => {
  const defaultValues = { 
    dosageStartDate: null
  };
  const validationSchema = {
    dosageStartDate: yup.date()
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />)
  };
  const methods = useForm({ defaultValues, resolver: yupResolver(yup.object().shape(validationSchema)) });
  const { handleSubmit, errors } = methods;

  const onSubmit = (d) => {
    const item = {
      prescriptionId: !obj ? 0 : obj.prescriptionId,
      dosageStartDate: dayjs(d.dosageStartDate).format("YYYY-MM-DDTHH:mm:ss")
    };
    onActivePatient(item);
  };
  
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={openDlg}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id="patient.activatePatient" />
      </DialogTitle>
      <DialogContent>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <FormDatePicker
                  name="dosageStartDate"
                  label={IntlGetText("patient.dosageStartDate")}  
                  format="YYYY-MM-DD"  
                  disableFuture={true}
                  required={true}
                  errorobj={errors}  
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.cancel" />
        </Button>
        <Button onClick={handleSubmit(onSubmit)} color="primary">
          <IntlMessages id="core.accept" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BtnActivatePatient;