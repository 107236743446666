import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as dayjs from "dayjs";
import { Bar, BarChart, ResponsiveContainer } from "recharts";
import IntlMessages from "util/IntlMessages";
import ReportBox from "components/ReportBox/index";
import CardHeader from "components/dashboard/Common/CardHeader/index";
import CustomLineChart from "components/CustomLineChart/index";
import { showErrorMessage } from "actions/Management";
import { isOnlyTracing, isJaasModule } from "app/routes/psp/services/userService";
import {
  callActionApi,
  getProductsAndStates,
  getPrescriptions,
} from "api/apiList";
import { loadHome, loadNewPatients } from "actions/Home";
import { setLoader } from "actions/Base";
import TableBox from "./components/TableBox";
import TableBoxRow from "./components/TableBoxRow";
import NewPatientIndicator from "./components/NewPatientIndicator";
import {
  chartData,
  newArticlesData,
} from "./data"; 
import { FolderType, AdverseEventStateEnum } from "constants/Enums";


const TableHome = ({dataList, folderType}) => {
  return (
    <TableBox>
      {dataList.map((data, index) => {
        return <TableBoxRow key={index} data={data} folderType={folderType}/>;
      })}
    </TableBox>
  );
};

const NewPatientsBox = () => {
  const { newPatients } = useSelector(({ home }) => home);
  return (
    <TableHome dataList={newPatients} folderType={FolderType.newPatients}/>
  );
};

const Home = () => {
  const dispatch = useDispatch();

  const [inProcessDataList, setInProcessDataList] = useState([]);
  const [tracingDataList, setTracingDataList] = useState([]);
  const [adverseEventList, setAdverseEventList] = useState([]);
  const [eventTrainingList, setEventTrainingList] = useState([]);
  
  const isOnlyTrace = isOnlyTracing();
  const isJaasModuleConfig = isJaasModule();

  //const { loader, drawerState } = useSelector(({ home }) => home);

  const callApi = (apiFunc, setData) => {
    callActionApi(
      apiFunc,
      setData,
      (error) => {
        if (typeof error === "string") dispatch(showErrorMessage(error));
      },
      setLoader
    );
  };

  const getNewPatients = () => {
    callApi(
      () => getPrescriptions(FolderType.newPatients),
      (data) => dispatch(loadNewPatients(data))
    );
  };
  const loadInfoInProcess = () => {
    if(!isOnlyTrace) 
      return;
    callApi(
      () => getPrescriptions(FolderType.inProcess),
      setInProcessDataList
    );
  };
  const loadInfoTracing = () => {
    callApi(
      () => getPrescriptions(FolderType.tracing),
      setTracingDataList
    );
  };
  const loadAverseEvent = () => {
    if(!isOnlyTrace) 
      return;
    callApi(
      () => getPrescriptions(FolderType.adverseEvents),
      (data)=>setAdverseEventList(
        data.filter(x=>x.adverseEvent.state === AdverseEventStateEnum.pending
          || x.adverseEvent.state === AdverseEventStateEnum.inconsistentReport
          || x.adverseEvent.state === AdverseEventStateEnum.pendingQuery)
        )
    );
  };
  const loadEventTraining = () => {
    callApi(
      () => getPrescriptions(FolderType.trainingsAndEvents),
      (data)=>setEventTrainingList(
        data.filter(x=>dayjs(x.eventTraining.trainingDate) > dayjs())
        )
    );
  };

  useEffect(() => {
    callApi(
      getProductsAndStates,
      ({ productLines, prescriptionStates }) => {
        dispatch(loadHome(productLines, prescriptionStates));
        getNewPatients();
        loadInfoInProcess();
        loadAverseEvent();
        loadInfoTracing();
        loadEventTraining();
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-wrapper">
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <NewPatientIndicator />
          </div>
          {!isOnlyTrace && 
            <div className="col-lg-2 col-md-6 col-sm-12">
              <ReportBox
                styleName="bg-amber text-white p-3"
                price={inProcessDataList.length}
                icon="hourglass-alt"
                detail={<IntlMessages id="psp.home.inProgressPatients" />}
                subHeadingColor="text-white"
              >
                <ResponsiveContainer width="100%" height={100}>
                  <BarChart
                    data={chartData}
                    maxBarSize={4}
                    margin={{ left: 0, right: 0, top: 10, bottom: 10 }}
                  >
                    <Bar dataKey="amt" fill="white" />
                  </BarChart>
                </ResponsiveContainer>
              </ReportBox>
            </div>
          }

          <div className="col-lg-2 col-md-6 col-sm-12">
            <ReportBox
              styleName="bg-light-green text-white p-3"
              icon="folder"
              price={tracingDataList.length}
              detail={<IntlMessages id="psp.home.treatmentPatients" />}
              subHeadingColor="text-white"
            >
              <CustomLineChart
                chartData={newArticlesData.chartData}
                labels={newArticlesData.labels}
                borderColor="#FFF"
                borderWidth={3}
                pointBackgroundColor="#FFF"
                pointBorderWidth={2}
                pointRadius={0}
                height={100}
                pointHoverBorderColor="#F29100"
                lineTension={0}
                gridLinesDisplay={true}
                gridLineWidth={3}
                shadowColor="rgba(0,0,0,0.6)"
              />
            </ReportBox>
          </div>
          {!isOnlyTrace && !isJaasModuleConfig && 
            <div className="col-lg-2 col-md-6 col-sm-12">
              <ReportBox
                styleName="bg-pink text-white p-3"
                price={adverseEventList.length}
                icon="alert-octagon"
                detail={<IntlMessages id="psp.home.adverseEvents" />}
                subHeadingColor="text-white"
              >
                <ResponsiveContainer width="100%" height={100}>
                  <BarChart
                    data={chartData}
                    maxBarSize={4}
                    margin={{ left: 0, right: 0, top: 10, bottom: 10 }}
                  >
                    <Bar dataKey="amt" fill="white" />
                  </BarChart>
                </ResponsiveContainer>
              </ReportBox>
            </div>
          }
          <div className="col-lg-2 col-md-6 col-sm-12">
            <ReportBox
              styleName="bg-purple text-white p-3"
              icon="book"
              price={eventTrainingList.length}
              detail={<IntlMessages id="psp.home.trainings" />}
              subHeadingColor="text-white"
            >
              <CustomLineChart
                chartData={newArticlesData.chartData}
                labels={newArticlesData.labels}
                borderColor="#FFF"
                borderWidth={3}
                pointBackgroundColor="#FFF"
                pointBorderWidth={2}
                pointRadius={0}
                height={100}
                pointHoverBorderColor="#F29100"
                lineTension={0}
                gridLinesDisplay={true}
                gridLineWidth={3}
                shadowColor="rgba(0,0,0,0.6)"
              />
            </ReportBox>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-lg-6 col-12">
            <div className="jr-card jr-full-card">
              <CardHeader
                heading={<IntlMessages id="psp.home.doctorTableTitle" />}
              />

              <div className="jr-card-body">
                <NewPatientsBox />
              </div>
            </div>
          </div>
          {!isOnlyTrace &&
            <div className="col-xl-4 col-lg-6 col-12">
              <div className="jr-card jr-full-card">
                <CardHeader
                  heading={<IntlMessages id="psp.home.patienInProgressTitle" />}
                />
                <div className="jr-card-body">
                  <TableHome dataList={inProcessDataList} folderType={FolderType.inProcess} />
                </div>
              </div>
            </div>
          }
          <div className="col-xl-4 col-lg-6 col-12">
            <div className="jr-card jr-full-card">
              <CardHeader
                heading={<IntlMessages id="psp.home.patienTreatmentTitle" />}
              />

              <div className="jr-card-body">
                <TableHome dataList={tracingDataList} folderType={FolderType.tracing} />
              </div>
            </div>
          </div>
          {!isOnlyTrace && !isJaasModuleConfig &&
            <div className="col-xl-4 col-lg-6 col-12">
              <div className="jr-card jr-full-card">
                <CardHeader
                  heading={<IntlMessages id="psp.home.adverseReports" />}
                />
                <div className="jr-card-body">
                  <TableHome dataList={adverseEventList} folderType={FolderType.adverseEvents} />
                </div>
              </div>
            </div>
          }
          <div className="col-xl-4 col-lg-6 col-12">
            <div className="jr-card jr-full-card">
              <CardHeader
                heading={<IntlMessages id="psp.home.trainingsEvents" />}
              />

              <div className="jr-card-body">
                <TableHome dataList={eventTrainingList} folderType={FolderType.trainingsAndEvents} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
