import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Grid } from "@material-ui/core";
import FormDatePicker from "components/Form/Controls/datepicker";
import DialogAsync from "components/Form/DialogAsync";
import { 
  callApi, 
  callApiAsync,
  postScheduleImpact
} from "api/apiList";

const ScheduleImpact = ({openDlg, closeDialog, callBack, prescriptionId, idPlannedImpact, datePlanningImpact}) =>{
  const defaultValues = {
    planningDate: datePlanningImpact
  };

  const validationSchema = {
    planningDate: yup
        .date()
        .nullable(true)
        .typeError(IntlGetText("yup.InvalidDate"))
        .required(<IntlMessages id="core.fieldRequired" />)
  };

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, errors, setValue } = methods;

  useEffect(() => {
    if(!openDlg)
      return;
    
      setTimeout(() => {
        setValue("planningDate", !datePlanningImpact ? null : dayjs(datePlanningImpact))
      }, 200);
  }, [openDlg]);

  const onSubmitAsync = handleSubmit(async (d)=>{
    const obj = {
      prescriptionId: prescriptionId,
      impactId: idPlannedImpact,
      planningDate: !d.planningDate ? null : dayjs(d.planningDate).format("YYYY-MM-DDTHH:mm:ss"),
    };
 
    await callApiAsync(
      ()=>postScheduleImpact(obj),
      ()=>{
        if(callBack)
          callBack();
        closeDialog();
      }
    );
  });

  return (
    <div>
      <DialogAsync
        open={openDlg}
        closeDialog={closeDialog}
        onSubmitAsync={onSubmitAsync}
        titleTextId={"impact.PlanningImpactTitle"}
        allowEdit={true}
        maxWidth="xs"
      >
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <FormDatePicker
                  name="planningDate"
                  label={<IntlMessages id="core.dateAndTime" />}
                  format="YYYY-MM-DD"
                  margin="normal"
                  disablePast={true}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>            
      </DialogAsync>
    </div>
  );
};

export default ScheduleImpact;