import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as dayjs from "dayjs";
import { FaMapMarkerAlt } from "react-icons/fa";
import IntlMessages from "util/IntlMessages";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import FormCheckbox from "components/Form/Controls/checkbox";
import FormDatePicker from "components/Form/Controls/datepicker";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  genderList,
  educationalLevelList,
  CivilStatusList,
  RegimeList,
  AffiliationTypeList,
  StratumList,
} from "constants/Enums";
import { callActionApi, getCitiesByStateProvinceId } from "api/apiList";
import PatientMap from "components/patientMap";
import { TrendingUpRounded } from "@material-ui/icons";

const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const calculateAge = (brithday) =>
  !brithday || !dayjs(brithday).isValid()
    ? ""
    : dayjs().diff(dayjs(brithday), "year");

const PatientInfo = ({
  stateProvinceList,
  documentTypeList,
  insuranceList,
  patientData,
  insuranceData,
}) => {
  const [birthCityList, setBirthCityList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [openMap, setopenMap] = useState(false);
  const [geoInfo, setGeoInfo] = useState({
    lat: 0,
    lng: 0,
  });

  const [loadForm, setLoadForm] = useState({
    documentTypeListLoaded: false,
    stateProvinceListLoaded: false,
    birthCityListLoaded: false,
    cityListLoaded: false,
    insuranceListLoaded: false,
  });

  const genderItems = genderList();
  const educationalLevelItems = educationalLevelList();
  const stratumItems = StratumList();
  const affiliationTypeItems = AffiliationTypeList();
  const regimeList = RegimeList();

  const civilStatusList = CivilStatusList();

  const callApi = (apiFunc, setData) => {
    callActionApi(
      apiFunc,
      setData,
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  };

  const defaultValues = {
    code: "",
    name: "",
    lastName: "",
    birthday: null,
    age: "",
    birthstateProvinceId: null,
    birthCityId: null,
    gender: null,
    documentTypeId: null,
    document: "",
    phone: "",
    cellphone: "",
    email: "",
    stateProvinceId: null,
    cityId: null,
    address: "",
    educationalLevel: null,
    profession: "",
    civilStatus: null,
    stratum: null,
    hospitalized: false,

    insuranceId: null,
    regime: null,
    affiliationType: null,
  };

  const methods = useForm({ defaultValues });
  const { watch, setValue, errors } = methods;

  const documentTypeIdValue = watch("documentTypeId");
  const birthstateProvinceValue = watch("birthstateProvinceId");
  const stateProvinceIdValue = watch("stateProvinceId");
  const birthCityIdValue = watch("birthCityId");
  const cityIdValue = watch("cityId");
  const ageValue = watch("age");
  const insuranceIdValue = watch("insuranceId");

  const loadData = (patientData) => {
    setGeoInfo({
      lat: patientData.geoDataLat ? patientData.geoDataLat : 0,
      lng: patientData.geoDataLng ? patientData.geoDataLng : 0,
    });
    setValue("code", patientData.code);
    setValue("name", patientData.name);
    setValue("lastName", patientData.lastName);
    setValue(
      "birthday",
      patientData.birthday ? dayjs(patientData.birthday) : null
    );
    setValue(
      "age",
      calculateAge(patientData.birthday ? dayjs(patientData.birthday) : dayjs())
    );
    setValue("birthstateProvinceId", patientData.birthstateProvinceId);
    setValue(" birthCityId", patientData.birthCityId);
    setValue(
      "gender",
      isNaN(parseInt(patientData.gender))
        ? 0
        : getCboValue(genderItems, patientData.gender)
    );
    setValue("documentTypeId", patientData.documentTypeId);
    setValue("document", !patientData.document ? "" : patientData.document);
    setValue("phone", !patientData.phone ? "" : patientData.phone);
    setValue("cellphone", !patientData.cellphone ? "" : patientData.cellphone);
    setValue("email", !patientData.email ? "" : patientData.email);
    setValue("stateProvinceId", patientData.stateProvinceId);
    setValue("cityId", patientData.cityId);
    setValue("address", !patientData.address ? "" : patientData.address);
    setValue(
      "educationalLevel",
      isNaN(parseInt(patientData.educationalLevel))
        ? 0
        : getCboValue(educationalLevelItems, patientData.educationalLevel)
    );
    setValue(
      "profession",
      !patientData.profession ? "" : patientData.profession
    );
    setValue(
      "civilStatus",
      isNaN(parseInt(patientData.civilStatus))
        ? 0
        : getCboValue(civilStatusList, patientData.civilStatus)
    );
    setValue(
      "stratum",
      getCboValue(stratumItems, !patientData ? "" : patientData.stratum)
    );
    setValue(
      "hospitalized",
      patientData.hospitalized ? patientData.hospitalized : false
    );
  };

  useEffect(() => {
    if (patientData.code) loadData(patientData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData]);

  useEffect(() => {
    if (insuranceData.insuranceId) {
      setValue("insuranceId", insuranceData.insuranceId);
      setValue(
        "regime",
        isNaN(parseInt(insuranceData.regime))
          ? 0
          : getCboValue(regimeList, insuranceData.regime)
      );
      setValue(
        "affiliationType",
        isNaN(parseInt(insuranceData.affiliationType))
          ? 0
          : getCboValue(affiliationTypeItems, insuranceData.affiliationType)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceData]);

  useEffect(() => {
    if (
      !loadForm.stateProvinceListLoaded &&
      stateProvinceList.length > 0 &&
      birthstateProvinceValue
    ) {
      setValue(
        "birthstateProvinceId",
        getCboValue(stateProvinceList, birthstateProvinceValue)
      );
      setValue(
        "stateProvinceId",
        getCboValue(stateProvinceList, stateProvinceIdValue)
      );
      setLoadForm({ ...loadForm, stateProvinceListLoaded: true });
      return;
    }
    if (!loadForm.cityListLoaded && cityList.length > 0 && patientData.code) {
      setValue("cityId", getCboValue(cityList, patientData.cityId));
      setLoadForm({ ...loadForm, cityListLoaded: true });
      return;
    }
    if (
      !loadForm.birthCityListLoaded &&
      birthCityList.length > 0 &&
      patientData.code
    ) {
      setValue(
        "birthCityId",
        getCboValue(birthCityList, patientData.birthCityId)
      );
      setLoadForm({ ...loadForm, birthCityListLoaded: true });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityList, birthCityList, stateProvinceList, patientData]);

  useEffect(() => {
    if (
      !loadForm.documentTypeListLoaded &&
      documentTypeList.length > 0 &&
      documentTypeIdValue
    ) {
      setValue(
        "documentTypeId",
        getCboValue(documentTypeList, documentTypeIdValue)
      );
      setLoadForm({ ...loadForm, documentTypeListLoaded: true });
      return;
    }
  }, [documentTypeList, patientData]);

  useEffect(() => {
    if (
      !loadForm.insuranceListLoaded &&
      insuranceList.length > 0 &&
      insuranceData.insuranceId
    ) {
      setValue(
        "insuranceId",
        getCboValue(insuranceList, insuranceData.insuranceId)
      );
      setLoadForm({ ...loadForm, insuranceListLoaded: true });
      return;
    }
  }, [insuranceList, insuranceData]);

  useEffect(() => {
    setValue("birthCityId", null);
    if (!birthstateProvinceValue || !birthstateProvinceValue.value) {
      setBirthCityList([]);
      return;
    }
    callApi(
      () => getCitiesByStateProvinceId(birthstateProvinceValue.value),
      setBirthCityList
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthstateProvinceValue]);
  useEffect(() => {
    setValue("cityId", null);
    if (!stateProvinceIdValue || !stateProvinceIdValue.value) {
      setCityList([]);
      return;
    }
    callApi(
      () => getCitiesByStateProvinceId(stateProvinceIdValue.value),
      setCityList
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProvinceIdValue]);

  const cboHandleChange = (name) => (event) => {
    setValue(name, event);
  };
  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);
  const dtpHandleChange = (name) => (e) => {
    setValue(name, e);
    setValue("age", calculateAge(e));
  };

  var disabled = true;

  const handleMapRequestClose = () => {
    setopenMap(false);
  };

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
      <FormProvider {...methods}>
        <form className="row" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="code"
                label={<IntlMessages id="core.code" />}
                disabled={disabled}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="name"
                label={<IntlMessages id="core.name" />}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="lastName"
                label={<IntlMessages id="core.lastName" />}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormDatePicker
                name="birthday"
                label={<IntlMessages id="core.birthday" />}
                format="YYYY-MM-DD"
                disableFuture={true}
                onChange={dtpHandleChange("birthday")}
                value={watch("birthday")}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="age"
                label={<IntlMessages id="core.age" />}
                value={ageValue}
                onChange={(value) => {
                  setValue("age", value);
                }}
                disabled={disabled}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="birthstateProvinceId"
                label={<IntlMessages id="core.birthstateProvince" />}
                options={stateProvinceList}
                value={birthstateProvinceValue}
                onChange={cboHandleChange("birthstateProvinceId")}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="birthCityId"
                label={<IntlMessages id="core.birthCity" />}
                options={birthCityList}
                value={birthCityIdValue}
                onChange={cboHandleChange("birthCityId")}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="gender"
                label={<IntlMessages id="core.gender" />}
                options={genderItems}
                value={watch("gender")}
                onChange={cboHandleChange("gender")}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="civilStatus"
                label={<IntlMessages id="core.civilStatus" />}
                options={civilStatusList}
                value={watch("civilStatus")}
                onChange={cboHandleChange("civilStatus")}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="documentTypeId"
                label={<IntlMessages id="core.documentType" />}
                options={documentTypeList}
                value={documentTypeIdValue}
                onChange={cboHandleChange("documentTypeId")}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="document"
                label={<IntlMessages id="core.document" />}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="phone"
                label={<IntlMessages id="core.phone" />}
                margin="normal"
                disabled={disabled}
                required={false}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="cellphone"
                label={<IntlMessages id="core.cellphone" />}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="email"
                label={<IntlMessages id="core.email" />}
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              style={{ display: "flex", alignItems: "baseline" }}
            >
              <FormInput 
                name="address"
                label={<IntlMessages id="core.address" />}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
              <Button
                onClick={() => setopenMap(true)}
                style={{
                  background:
                    geoInfo.lat || geoInfo.lat ? "#20c997" : "#f6bb43",
                  color: "white",
                  height: "40px",
                  margin: "0 0 0 5px",
                }}
              >
                <FaMapMarkerAlt />
              </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="stateProvinceId"
                label={<IntlMessages id="core.stateProvince" />}
                options={stateProvinceList}
                value={stateProvinceIdValue}
                onChange={cboHandleChange("stateProvinceId")}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="cityId"
                label={<IntlMessages id="core.city" />}
                options={cityList}
                value={cityIdValue}
                onChange={cboHandleChange("cityId")}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="stratum"
                label={<IntlMessages id="core.stratum" />}
                options={stratumItems}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="educationalLevel"
                label={<IntlMessages id="core.educationalLevel" />}
                options={educationalLevelItems}
                value={watch("educationalLevel")}
                onChange={cboHandleChange("educationalLevel")}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="profession"
                label={<IntlMessages id="core.profession" />}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="insuranceId"
                label={<IntlMessages id="core.insurance" />}
                options={insuranceList}
                value={insuranceIdValue}
                onChange={cboHandleChange("insuranceId")}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="regime"
                label={<IntlMessages id="core.regime" />}
                options={regimeList}
                value={watch("regime")}
                onChange={cboHandleChange("regime")}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="affiliationType"
                label={<IntlMessages id="core.affiliationType" />}
                options={affiliationTypeItems}
                value={watch("affiliationType")}
                onChange={cboHandleChange("affiliationType")}
                margin="normal"
                disabled={disabled}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormCheckbox
                name="hospitalized"
                label={<IntlMessages id="core.hospitalized" />}
                value={watch("hospitalized")}
                onChange={chkHandleChange("hospitalized")}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <Dialog
        maxWidth="md"
        open={openMap}
        disableBackdropClick={true}
        onClose={handleMapRequestClose}
        className="mapDialog"
      >
        <DialogTitle>{<IntlMessages id="core.map" />}</DialogTitle>
        <DialogContent>
          <PatientMap
            geoInfo={geoInfo}
            setGeoInfo={setGeoInfo}
            queryView={true}
          />
          <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center mt-4"></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleMapRequestClose()} color="secondary">
            {<IntlMessages id="core.close" />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PatientInfo;
