import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Grid } from "@material-ui/core";
import FormDateTimePicker from "components/Form/Controls/dateTimePicker";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import DialogAsync from "components/Form/DialogAsync";
import { callApi, callApiAsync, getNewPatientList, postAddIntitutionImpact } from "api/apiList";

const DlgAddNewPatient = ({openDlg, closeDialog, callBack}) =>{
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [newPatientList, setNewPatientList] = useState([]);
  
  const defaultValues = {
    patientId: null,
    planningDate: null,
    nurse: "",
    process: ""
  };

  const validationSchema = {
    patientId: yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    planningDate: yup.date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />)
  };
  
  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, errors } = methods;

  const onSubmitAsync = handleSubmit(async (d)=>{
    const impact = {
      patientId: Number(d.patientId.value),
      planningDate: dayjs(d.planningDate).format("YYYY-MM-DDTHH:mm:ss"),
      nurse: d.nurse,
      process: d.process,
    };

    await callApiAsync(
      ()=>postAddIntitutionImpact(impact),
      ()=>{
        if(callBack)
          callBack();
        closeDialog();
      }
    );
  });

  useEffect(() => {
    if(!openDlg) return;

    callApi( getNewPatientList,
      setNewPatientList,
      fnSetLoader
    );
  }, [openDlg]);

  return(
    <DialogAsync
      open={openDlg}
      closeDialog={closeDialog}
      onSubmitAsync={onSubmitAsync}
      titleTextId={"core.newPatient"}
      allowEdit={true}
    >
    <FormProvider {...methods}>
      <form className="row" noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <FormSelectAutoComplete
              name="patientId"
              label={<IntlMessages id="core.patient" />}
              options={newPatientList}
              margin="normal"
              required={true}
              errorobj={errors}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <FormDateTimePicker
              name="planningDate"
              label={<IntlMessages id="core.nextProcedureDateAndTime"/>}
              format="YYYY-MM-DD hh:mm A"
              disablePast={true}
              leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
              rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
              required={true}
              errorobj={errors}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <FormInput
              name="nurse"
              label={<IntlMessages id="core.attendingNurse" />}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <FormInput
              label={<IntlMessages id="institution.procedureTo" />}
              name="process"
              multiline
              rows={3}
              variant="outlined"
              margin="normal"
            />
          </Grid>
        
        </Grid>
        </form>
      </FormProvider>
    </DialogAsync>
  );
};

export default DlgAddNewPatient;