import React, { Component } from "react";
import CustomScrollbars from "util/CustomScrollbars";
import Navigation from "../../components/Navigation";
import { getUserInfo } from "app/routes/psp/services/userService";
import { getMenuItems } from "constants/setupMenu";


const getMenuList = (userInfo) => {
  return getMenuItems(userInfo).map(x=>({
    name: x.name,
    link: x.link,
    type: "item"
  }));
}

class SideBarContent extends Component {
  constructor() {
    super();
    this.state = {
      role: "",
      navigationMenus: [],
    };
  }

  async componentDidMount() {
    const navigationMenus = [
      {
        name: "core.menu",//"Pint Acompaña",
        type: "section",
        children: getMenuList(getUserInfo())
      },
    ];

    this.setState({ navigationMenus: navigationMenus });

    // let navigationMenus = [];
    // const userInfo = getUserInfo();
    // const isRole = (role) => userInfo.roles.indexOf(role) !== -1;

    // console.log("menu-userInfo",userInfo);
    // console.log("menu-userInfo.roles",userInfo.roles);
    // console.log("menu-userInfo.roles-isKam",userInfo.roles.indexOf(RoleEnum.kam) !== -1);
    // console.log("menu-userInfo.roles-isPspManager",userInfo.roles.indexOf(RoleEnum.psp_manager) !== -1);
    
    // if(getUserInfo() && getUserInfo().shardKey === ShardKeyEnum.ialuril){
    //   navigationMenus = [
    //     {
    //       name: "Pint Acompaña",
    //       type: "section",
    //       children: [
    //         {
    //           name: "psp.menu.patients",
    //           type: "item",
    //           link: "/app/patientPA",
    //         },
    //         {
    //           name: "psp.menu.impactPlanning",
    //           type: "item",
    //           link: "/app/impactPlanning",
    //         },
    //       ],
    //     },
    //   ];
    // }
    // else if (role === "nurse") {
    //   navigationMenus = [
    //     {
    //       name: "Pint Acompaña",
    //       type: "section",
    //       children: [
    //         {
    //           name: "psp.menu.home",
    //           type: "item",
    //           link: "/app/home",
    //         },
    //         {
    //           name: "psp.menu.manage",
    //           type: "item",
    //           link: "/app/management",
    //         },
    //         {
    //           name: "psp.menu.patients",
    //           type: "item",
    //           link: "/app/patient",
    //         },
    //         {
    //           name: "psp.menu.doctors",
    //           type: "item",
    //           link: "/app/doctor",
    //         },
    //       ],
    //     },
    //   ];
    // } else if (role === "pharma") {
    //   navigationMenus = [
    //     {
    //       name: "Pint Acompaña",
    //       type: "section",
    //       children: [
    //         {
    //           name: "psp.menu.home",
    //           type: "item",
    //           link: "/app/pharmacovigilant",
    //         },
    //         {
    //           name: "psp.menu.manage",
    //           type: "item",
    //           link: "/app/managepharma",
    //         },
    //       ],
    //     },
    //   ];
    // } else if (role === "psp_manager") {
    //   navigationMenus = [
    //     {
    //       name: "Pint Acompaña",
    //       type: "section",
    //       children: [
    //         {
    //           name: "psp.menu.patients",
    //           type: "item",
    //           link: "/app/patient",
    //         },
    //         {
    //           name: "psp.menu.impactPlanning",
    //           type: "item",
    //           link: "/app/impactPlanning",
    //           },
    //         {
    //           name: "menu.reports",
    //           type: "item",
    //           link: "/app/reports",
    //         },
    //         {
    //           name: "menu.patientManagement",
    //           type: "item",
    //           link: "/app/patient/management",
    //         }
    //       ],
    //     },
    //   ];
    // } else if (role === "manager") {
    //   navigationMenus = [
    //     {
    //       name: "Pint Acompaña",
    //       type: "section",
    //       children: [
    //         {
    //           name: "kpi.title",
    //           type: "collapse",
    //           icon: "network",
    //           children: [
    //             {
    //               name: "kpi.productLine",
    //               type: "item",
    //               link: "/app/kpi/productLine",
    //             },
    //             {
    //               name: "kpi.impacts",
    //               type: "item",
    //               link: "/app/kpi/impacts",
    //             },
    //             {
    //               name: "kpi.impacts.PharmacoVigilance",
    //               type: "item",
    //               link: "/app/kpi/pharmacoVigilance",
    //             },
    //           ],
    //         },
    //         {
    //           name: "Médicos",
    //           type: "item",
    //           link: "/app/doctor",
    //         },
    //         {
    //           name: "Pacientes",
    //           type: "item",
    //           link: "/app/patient/",
    //         },
    //         {
    //           name: "Calendario",
    //           type: "item",
    //           link: "/app/manager-calendar/",
    //         },
    //       ],
    //     },
    //   ];
    // }
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <Navigation menuItems={this.state.navigationMenus} />
      </CustomScrollbars>
    );
  }
}

export default SideBarContent;
