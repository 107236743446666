
import React, { useState, useEffect } from "react";
import * as dayjs from "dayjs";
import { IntlGetText } from "util/IntlMessages";
import Table, { getTextLabels, defaultCol } from "components/Form/Table";
import { callApi, getProductsAndStates } from "api/apiList";


const ImpactTable = ({ impacts }) => {
  const [impactTypeList,setImpactTypeList] = useState([]);

  useEffect(()=>{
    callApi(
      getProductsAndStates,
      (data)=>{ 
        setImpactTypeList(data.prescriptionStates.filter(x=>x.isImpactType));
      }
    );
  },[]);

  const getObj = (tableMeta) => impacts.find(x=>x.id === tableMeta.rowData[0]);  

  var columns =  [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display:"excluded"
      },
    },
    {
      name: "impactTypeId",
      label: IntlGetText("core.type"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const state = impactTypeList.find(x=>x.id === value);
          if(!state) return "";
          return (
            <div className={`badge text-uppercase text-white bg-${state.color}`}>
              {state.title}
            </div>
          );
        }
      }
    },
    {
      name: "startDate",
      label: IntlGetText("core.startDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD hh:mm a") : "";
        },
      },
    },
    {
      name: "endDate",
      label: IntlGetText("core.endDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD hh:mm a") : "";
        },
      },
    },
    defaultCol("nursingNote", "impact.NursingNote")
  ];
  
  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: getTextLabels()
  };

  return (
    <Table
      className="border-dialog-card"
      data={impacts}
      columns={columns}
      options={options} />
  );
};

export default ImpactTable;