import { IntlGetText } from "util/IntlMessages";
import Highcharts from "highcharts";
import { currencyFormat } from "services/formatNumber";

const formatCurrency = (total, decimals) => {
  let value = total;
  if (value == 0)
      return "$ 0";

  let unit = "";
  if (value < 1000000)
  {
      unit = "K";
      value = value / 1000;
  }
  else if (value < 1000000000000)
  {
      unit = "M";
      value = value / 1000000;
  }
  else
  {
      unit = "bn";
      value = value / 1000000000000;
  }

  value = Math.round(value, decimals);
  return `$ ${value}${unit}`;
};

export const enrollChart = (locale, enrollData) => {
  const titleText = IntlGetText("dashboarPA.chartEnrollTitle");
  const yAxisTitle = IntlGetText("core.patients");
  const serie1Title = IntlGetText("chartEnroll.created");
  const serie2Title = IntlGetText("chartEnroll.registered");
  
  return {
    credits: { enabled: false },
    title: { text: titleText },
    // subtitle: {
    //   text: totalText + ': '+ currencyFormat(locale,total),
    //   align: 'right',
    //   x: -10
    // },
    yAxis: { title: { text: yAxisTitle } },
    xAxis: { categories: enrollData.categories },

    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">Mes {point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    series: [
      {
        name: serie1Title,
        data: enrollData.created,
      },
      {
        name: serie2Title,
        data: enrollData.registered,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };
};

export const pieChart = (titleTextKey, tooltipKey, locale, keyValuePairData) => {
  const titleText = IntlGetText(titleTextKey);
  const stateTypeText = IntlGetText(tooltipKey);
  const totalizationText = IntlGetText("core.amount");
  const totalText = IntlGetText("core.total");

  const data = keyValuePairData.map((x) => [x.Key, x.Value]);
  const total = keyValuePairData.map((item) => item.Value)
  .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: "pie" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ': '+ currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          //format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          formatter: function() {
            return "<b>" +
              currencyFormat(locale,this.y) +
              " (" +
              Highcharts.numberFormat(this.percentage, 1) +
              "%)" +
              "</b>";
          },
        },
      },
    },
    // tooltip: {
    //   pointFormat:
    //     "Instilación: <b>{point.y}</b><br/>EVA: {point.x}",
    // },
    tooltip: {
      formatter: function() {
        return (
          stateTypeText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>" +
          currencyFormat(locale,this.y) +
          " (" +
          Highcharts.numberFormat(this.percentage, 2) +
          " %)" +
          "</b>"
        );
      },
    },
    series: [{ name: stateTypeText, data: data }],
  };
};

export const totalChart = (titleTextKey, tooltipKey, locale, keyValuePairData) => {
  const titleText = IntlGetText(titleTextKey);
  const stateTypeText = IntlGetText(tooltipKey);
  const totalizationText = IntlGetText("core.amount");
  const totalText = IntlGetText("core.total");

  const data = keyValuePairData.map((x) => [x.Key, x.Value]);
  const total = keyValuePairData.map((item) => item.Value)
  .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: "pie" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ': '+ currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    plotOptions: {
      pie: {
        innerSize: 150,
        depth: 45,
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          //format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          formatter: function() {
            return "<b>" +
              currencyFormat(locale,this.y) +
              " (" +
              Highcharts.numberFormat(this.percentage, 1) +
              "%)" +
              "</b>";
          },
        },
      },
    },
    // tooltip: {
    //   pointFormat:
    //     "Instilación: <b>{point.y}</b><br/>EVA: {point.x}",
    // },
    tooltip: {
      formatter: function() {
        return (
          stateTypeText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>" +
          currencyFormat(locale,this.y) +
          " (" +
          Highcharts.numberFormat(this.percentage, 2) +
          " %)" +
          "</b>"
        );
      },
    },
    series: [{ name: stateTypeText, data: data }],
  };
};

export const eficaciaDetailChart = (locale, keyValuePairData) => {
  const titleText = IntlGetText("dashboardMigration.eficaciaRecoveries");
  const stateTypeText = IntlGetText("core.stateType");
  const totalizationText = IntlGetText("dashboard.totalization");
  const totalText = IntlGetText("core.total");

  const data = keyValuePairData.map((x) => [x.Key, x.Value]);
  const total = keyValuePairData.map((item) => item.Value)
  .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: "pie" },
    title: { text: titleText },
    subtitle: {
      text: totalText + ' $' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    plotOptions: {
      pie: {
        innerSize: 150,
        depth: 45,
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          //format: "<b>{point.name}</b>: {point.y} ({point.percentage:.1f} %)",
          formatter: function() {
            return "<b>$ " +
              currencyFormat(locale,this.y) +
              " (" +
              Highcharts.numberFormat(this.percentage, 1) +
              "%)" +
              "</b>";
          },
        },
      },
    },
    // tooltip: {
    //   pointFormat:
    //     "Instilación: <b>{point.y}</b><br/>EVA: {point.x}",
    // },
    tooltip: {
      formatter: function() {
        return (
          stateTypeText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale,this.y) +
          " (" +
          Highcharts.numberFormat(this.percentage, 2) +
          " %)" +
          "</b>"
        );
      },
    },
    series: [{ name: stateTypeText, data: data }],
  };
};

export const columnChart = (titleTextKey, tooltipKey, locale, keyValuePairData, yAxisTitleKey = 'core.patients') => {
  const titleText = IntlGetText(titleTextKey);
  const causalText = IntlGetText(tooltipKey);
  const totalizationText = IntlGetText("core.amount");
  const totalText = IntlGetText("core.total");
  const yAxisTitle = IntlGetText(yAxisTitleKey);

  const categories = keyValuePairData.map((x) => x.Key);
  const data = keyValuePairData.map((x) => x.Value);
  const total = keyValuePairData.map((item) => item.Value)
    .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: 'column' },
    title: { text: titleText },
    subtitle: {
      text: totalText + ': ' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    xAxis: [{ categories: categories }],
    yAxis:{ 
      min: 0,
      title: { text: yAxisTitle } 
    },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function() {
        return (
          causalText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>" +
          currencyFormat(locale,this.y) +
          "</b>"
        );
      },
    },
    series: [{
        name: '',
        type: 'column',
        data: data,
        dataLabels: {
          enabled: true,
          formatter: function() {
            return this.y;
          },
          y: 0,
      },
    }],
  };
};

export const recoveriesChart = (titleTextKey, tooltipKey, locale, keyValuePairData) => {
  const titleText = IntlGetText("dashboardMigration.recoveries");
  const causalText = IntlGetText("core.causal");
  const totalizationText = IntlGetText("patientDetail.value");
  const totalText = IntlGetText("core.total");

  const categories = keyValuePairData.map((x) => x.Key);
  const data = keyValuePairData.map((x) => x.Value);
  const total = keyValuePairData.map((item) => item.Value)
    .reduce((prev, curr) => prev + curr, 0);

  return {
    credits: { enabled: false },
    chart: { type: 'bar' },
    title: { text: titleText },
    subtitle: {
      text: totalText + ' $' + currencyFormat(locale,total),
      align: 'right',
      x: -10
    },
    xAxis: [{ categories: categories }],
    yAxis:{ visible: false },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function() {
        return (
          causalText +
          ": <b>" +
          this.key +
          "<br><br/></b> " +
          totalizationText +
          ": <b>$ " +
          currencyFormat(locale,this.y) +
          "</b>"
        );
      },
    },
    series: [{
        name: '',
        type: 'column',
        data: data,
        dataLabels: {
          enabled: true,
          formatter: function() {
            return formatCurrency(this.y,0);
          },
          y: 0,
      },
    }],
  };
};