import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import DialogAlert from "components/Form/DialogAlert";
import Form from "./Form";
import Table from "./Table";
import {
  callApi,
  callApiAsync,
  getInsuranceAuthorization,
  postCreateUpdateInsuranceAuthorization,
  deleteInsuranceAuthorization,
  getMarketChainList,
  postGenerateAlert,
} from "api/apiList";
import { setPrescriptionState } from "actions/Management";
import { callStartImpact, callReloadAlerts } from "actions/Impact";
import { PrescripStateEnum } from "constants/Enums";

const existAuthorizationsInImpact = (impactId, authorizations) =>
  authorizations.find((x) => x.impactId == impactId) != undefined;

const InsuranceAuthorizationDialog = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentSigned, isCIRequired } = useSelector(({ impact }) => impact);

  const [open, setOpen] = useState(false);
  const [openCRU, setOpenCRU] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentRowObj, setCurrentRowObj] = useState({});
  const [marketChains, setMarketChains] = useState([]);
  const [authorizations, setAuthorizations] = useState([]);

  const [openDialogAlert, setOpenDialogAlert] = useState(false);
  const [openDlgAlertInconsistences, setOpenDlgAlertInconsistences] = useState(
    false
  );

  const msgSuccess = IntlGetText("core.SuccessSave");
  const msgStartImpactRequired = IntlGetText("impact.startImpactRequired");
  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const handleClickOpen = () => {
    if(!impactStartDate){
      dispatch(callStartImpact(true));
      return;
    }
    if(isCIRequired &&  !consentSigned){
        NotificationManager.warning(msgConsentRequired);
        return;
    }

    setOpen(true);
  };
  const handleRequestClose = () => setOpen(false);
  const handleOpenCRU = () => setOpenCRU(true);
  const handleRequestCloseCRU = () => setOpenCRU(false);
  const handleRequestCloseDel = () => setOpenDeleteDialog(false);

  useEffect(() => {
    callApi(
      () => getInsuranceAuthorization(selectedPrescription.id),
      setAuthorizations
    );
    callApi(() => getMarketChainList(), setMarketChains);
  }, []);

  const handleCreateUpdate = async (data) => {
    const dataId = data.id;
    await callApiAsync(
      () => postCreateUpdateInsuranceAuthorization(data),
      (insuranceAuthorizationList) => {
        setAuthorizations(insuranceAuthorizationList);
        var objId =
          dataId > 0
            ? dataId
            : Math.max(...insuranceAuthorizationList.map((x) => x.id));
        setCurrentRowObj(insuranceAuthorizationList.find((x) => x.id == objId));
        NotificationManager.success(msgSuccess);
        //handleRequestCloseCRU();
      }
    );
  };

  const removeItem = (list, itemId) => {
    let index = list.findIndex((x) => x.id === itemId);
    let data = [...list];
    data.splice(index, 1);
    return data;
  };  
  const handleDelete = () => {
    callApi(
      () => deleteInsuranceAuthorization(currentRowObj.id),
      () => {
        const data = removeItem(authorizations, currentRowObj.id);
        setAuthorizations(data);
        handleRequestCloseDel();
      }
    );
  };

  const handleClickAuthorizationRefusal = () => setOpenDialogAlert(true);
  const handleRequestCloseDlgAlert = () => setOpenDialogAlert(false);
  const handleAuthorizationRefusal = (comment) => {
    callApi(
      () =>
        postGenerateAlert({
          prescriptionId: selectedPrescription.id,
          prescriptionState: PrescripStateEnum.noAuthorized,
          comment,
        }),
      () => {
        handleRequestCloseDlgAlert();
        handleRequestClose();
        dispatch(setPrescriptionState(PrescripStateEnum.noAuthorized));
        dispatch(callReloadAlerts(true));
      }
    );
  };

  const handleClickInconsistences = () => setOpenDlgAlertInconsistences(true);
  const handleRequestCloseDlgInconsistences = () =>
    setOpenDlgAlertInconsistences(false);
  const handleInconsistences = (comment) => {
    callApi(
      () =>
        postGenerateAlert({
          prescriptionId: selectedPrescription.id,
          prescriptionState: PrescripStateEnum.authorizationWithInconsistencies,
          comment,
        }),
      () => {
        handleRequestCloseDlgAlert();
        handleRequestClose();
        dispatch(
          setPrescriptionState(
            PrescripStateEnum.authorizationWithInconsistencies
          )
        );
        dispatch(callReloadAlerts(true));
      }
    );
  };

  return (
    <div>
      <InfoCard
        data={{
          icon: "check-square",
          title: IntlGetText("insuranceAuthorization.cardTitle"),
          subTitle: IntlGetText("insuranceAuthorization.cardSubTitle"),
          color: existAuthorizationsInImpact(selectedPrescription.impactId, authorizations) ? "#20c997" : "#E53935",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="insuranceAuthorization.tableTitle" />
        </DialogTitle>
        <DialogContent>
          {selectedPrescription.stateId != PrescripStateEnum.noAuthorized &&
          selectedPrescription.stateId !=
            PrescripStateEnum.authorizationWithInconsistencies && ( //authorizations.length == 0) &&
              <div>
                {!existAuthorizationsInImpact(
                  selectedPrescription.impactId,
                  authorizations
                ) && (
                  <div className="col-lg-12 col-md-12 col-xs-12 text-right mb-4">
                    <Tooltip
                      title={IntlGetText(
                        "insuranceAuthorization.authorizationRefusalEPS"
                      )}
                      placement="bottom-end"
                    >
                      <Button
                        onClick={handleClickAuthorizationRefusal}
                        variant="contained"
                        className="jr-btn bg-red text-white"
                      >
                        {" "}
                        <i className="zmdi zmdi-comment-alert zmdi-hc-fw" />
                      </Button>
                    </Tooltip>
                    <DialogAlert
                      open={openDialogAlert}
                      dialogTitle={
                        <IntlMessages id="insuranceAuthorization.authorizationRefusal" />
                      }
                      callBack={handleAuthorizationRefusal}
                      closeDialog={handleRequestCloseDlgAlert}
                    />
                  </div>
                )}
                {existAuthorizationsInImpact(
                  selectedPrescription.impactId,
                  authorizations
                ) && (
                  <div className="col-lg-12 col-md-12 col-xs-12 mb-4 text-right">
                    <Tooltip
                      title={IntlGetText(
                        "insuranceAuthorization.authWithInconsistencies"
                      )}
                      placement="bottom-end"
                    >
                      <Button
                        onClick={handleClickInconsistences}
                        variant="contained"
                        className="jr-btn bg-red text-white"
                      >
                        <i className="zmdi zmdi-comment-alert zmdi-hc-fw" />
                      </Button>
                    </Tooltip>
                    <DialogAlert
                      open={openDlgAlertInconsistences}
                      dialogTitle={
                        <IntlMessages id="insuranceAuthorization.authorizationWithInconsistencies" />
                      }
                      callBack={handleInconsistences}
                      closeDialog={handleRequestCloseDlgInconsistences}
                    />
                  </div>
                )}
              </div>
            )}

          {selectedPrescription.stateId != PrescripStateEnum.noAuthorized && (
            <Table
              data={authorizations}
              handleOpenCRU={handleOpenCRU}
              handleOpenDel={() => setOpenDeleteDialog(true)}
              setCurrentRowObj={setCurrentRowObj}
            />
          )}
          <Form
            rowObj={currentRowObj}
            impactId={selectedPrescription.impactId}
            openCRU={openCRU}
            handleRequestClose={handleRequestCloseCRU}
            marketChainList={marketChains}
            handleCreateUpdate={handleCreateUpdate}
            authorizations={authorizations}
            setAuthorizations={setAuthorizations}
          />
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={openDeleteDialog}
            disableBackdropClick={true}
            onClose={handleRequestCloseDel}
          >
            <DialogTitle>
              <IntlMessages id="additionalMedications.MsgDelConfirmation" />
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleRequestCloseDel} color="secondary">
                <IntlMessages id="core.cancel" />
              </Button>
              <Button onClick={handleDelete} color="primary">
                <IntlMessages id="core.accept" />
              </Button>
            </DialogActions>
          </Dialog>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InsuranceAuthorizationDialog;
