import React, { useState } from "react";
import PatientDataForm from "../modalForm/patientDataForm";
import InfoCard from "components/InfoCard/index";
import IntlMessages, { IntlGetText } from "util/IntlMessages";

const PatientData = (props) => {
  const [openDlgPatient, setOpenDlgPatient] = useState(false);
  return (
    <>
      {/* comment-alert 
    assignment-check
    assignment
    */}
      <InfoCard
        data={{
          icon: "account-box",
          title: IntlGetText("institution.patientData"),
          /*   color:
            !eventTrainingInfo || !eventTrainingInfo.comment
              ? "#757575"
              : "#20c997", */
          color: "#60c1d7",
        }}
        onClickBox={() => setOpenDlgPatient(true)}
      />

      <PatientDataForm
        openDlg={openDlgPatient}
        setOpenDlg={setOpenDlgPatient}
        closeDialog={() => setOpenDlgPatient(false)}
        {...props}
      />
    </>
  );
};

export default PatientData;
