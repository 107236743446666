import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormDatePicker from "components/Form/Controls/datepicker";
import CardFileUpload from "components/Form/CardFileUpload";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { postAddFileRadicadoOLF } from "api/apiList";
import { RadicadoInsuranceState, RadicadoLogisticOperatorState } from "constants/Enums";


const getCboValue = (items, id) => {
    if(typeof(id) == "object"){
      return id;
    }
    const result = items.find((x) => x.id === id);
    return result ? { label: result.name, value: result.id } : "";
  };

const getItemAuthorizations = (data) => !data ? [] : data.map(x=>({id:x.id,name:x.code}));

const getMedicineAmount = (authorizations, authorizationId, radicacionList) => {
  var authorization = authorizations.find(x=>x.id == authorizationId);
  var radicadas = radicacionList.filter(x=>x.insuranceAuthorizationId == authorizationId && (!x.state || x.state != RadicadoLogisticOperatorState.deliveryNotMade))
    .map(item => item.deliveries > 0 ? item.deliveries : item.medicineAmount)
    .reduce((prev, curr) => prev + curr, 0);

  return authorization.medicineAmount - radicadas;
};

const getAuthorizations = (authorizations) => {
  return authorizations.filter(x=>x.radicadoInsuranceState !== RadicadoInsuranceState.authorizationWithInconsistencies
    && x.radicadoInsuranceState !== RadicadoInsuranceState.noAuthorized);
};

const FormCRU =({ rowObj, impactId, openCRU, handleRequestClose, authorizations, handleCreateUpdate, radicadoOLFList, setRadicadoOLFList, readOnly })=>{
    const msgSuccess = IntlGetText("core.SuccessSave");
  
    const [file, setFile] = useState(null);
  
    const defaultValues = {
        insuranceAuthorizationId: "",
        radicadoDate: null,
        medicineAmount: "",
        code: "",
        deliveryDate: null
    };
  
    const validationSchema = {
      insuranceAuthorizationId: yup .object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable(),
      radicadoDate: yup.date()
        .nullable(true)
        .typeError(IntlGetText("yup.InvalidDate")),
        //.required(<IntlMessages id="core.fieldRequired" />),
      medicineAmount: yup.number()
        .typeError(IntlGetText("yup.numericValidation"))
        .min(1, IntlGetText("yup.numericValidation"))
        .max(50000, IntlGetText("yup.invalidInput"))
        .required(<IntlMessages id="core.fieldRequired" />),
      code: yup.string().required(<IntlMessages id="core.fieldRequired" />)
        .matches(/^[a-z0-9_-]+$/i, IntlGetText("yup.alphanumericValidation")),
      deliveryDate: yup.date()
        .nullable(true)
        .typeError(IntlGetText("yup.InvalidDate"))
        //.required(<IntlMessages id="core.fieldRequired" />)
    };
  
    const methods = useForm({ 
      defaultValues,
      resolver: yupResolver(yup.object().shape(validationSchema)),
    });
    const { handleSubmit, watch, setValue, reset, errors } = methods;
  
    const insuranceAuthorizationIdValue = watch("insuranceAuthorizationId");  

    const cboHandleChange = (name) => (event) => {
      setValue(name, event);
      if(!rowObj || rowObj.id == 0){
        setValue("medicineAmount", getMedicineAmount(authorizations, event.value, radicadoOLFList));
      }
    }

    useEffect(()=>{
        if(openCRU){
          setTimeout(loadData,150);
        }
      },[openCRU]);

    const loadData = () => {
        setValue("insuranceAuthorizationId", !rowObj ? "" : getCboValue(getItemAuthorizations(authorizations), rowObj.insuranceAuthorizationId));
        setValue("radicadoDate", !rowObj || !rowObj.radicadoDate ? null : dayjs(rowObj.radicadoDate));
        setValue("medicineAmount", !rowObj ? "" : rowObj.medicineAmount);
        setValue("code", !rowObj ? "" : rowObj.code);
        setValue("deliveryDate", !rowObj || !rowObj.deliveryDate ? null : dayjs(rowObj.deliveryDate));
        setFile(!rowObj ? null : rowObj.filePath);
    };
  
    const updateFileInObj = (objId,file) =>{
      let index = radicadoOLFList.findIndex((x)=> x.id === objId);
      let data = [...radicadoOLFList];
      data[index] = {...data[index], filePath: file};
  
      setRadicadoOLFList(data);    
    };

    const onSubmit = async (d, e) => {
      var obj = {
        id: !rowObj ? 0 : rowObj.id,
        impactId: impactId,
        insuranceAuthorizationId: Number(d.insuranceAuthorizationId.value),
        radicadoDate:  !d.radicadoDate ? null : dayjs(d.radicadoDate).format("YYYY-MM-DDTHH:mm:ss"),
        medicineAmount: Number(d.medicineAmount),
        code: d.code,
        deliveryDate: !d.deliveryDate ? null : dayjs(d.deliveryDate).format("YYYY-MM-DDTHH:mm:ss")
      };
      await handleCreateUpdate(obj);
    };

    readOnly = readOnly ? readOnly : (rowObj && rowObj.state === RadicadoLogisticOperatorState.deliveryNotMade);
    const disabled = readOnly || (rowObj && rowObj.hasDeliveries);

    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openCRU}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id={!rowObj ? "core.add" : "core.edit"} />
        </DialogTitle>
        <DialogContent>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <FormProvider {...methods}>
              <form className="row" noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={6}>
                        <FormSelectAutoComplete
                            name="insuranceAuthorizationId"
                            label={<IntlMessages id="radicadoOLF.authorizationCode" />}
                            options={getItemAuthorizations(getAuthorizations(authorizations))}
                            value={insuranceAuthorizationIdValue}
                            onChange={cboHandleChange("insuranceAuthorizationId")}
                            margin="normal"
                            disabled={disabled}
                            required={true}
                            errorobj={errors}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                        <FormDatePicker
                            name="radicadoDate"
                            label={<IntlMessages id="radicadoOLF.radicadoDate" />}
                            format="YYYY-MM-DD"
                            disableFuture={true}
                            margin="normal"
                            disabled={disabled}
                            errorobj={errors}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                        <FormInput
                        name="medicineAmount"
                        label={<IntlMessages id="insuranceAuthorization.medicineAmount" />}
                        margin="normal"
                        disabled={disabled}
                        required={true}
                        errorobj={errors}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                        <FormInput
                        name="code"
                        label={<IntlMessages id="radicadoOLF.code" />}
                        margin="normal"
                        disabled={disabled}
                        required={true}
                        errorobj={errors}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                        <FormDatePicker
                            name="deliveryDate"
                            label={<IntlMessages id="radicadoOLF.deliveryDate" />}
                            format="YYYY-MM-DD"
                            //disablePast={true}
                            margin="normal"
                            disabled={disabled}
                            errorobj={errors}
                        />
                    </Grid>
                </Grid>
              </form>
            </FormProvider>
            { !disabled &&
              <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
                <ButtonAsync onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                >
                  {<IntlMessages id="core.save" />}
                </ButtonAsync>
              </div>
            }
            { rowObj && 
              <CardFileUpload 
                disabled = { (readOnly && file === null) ? false : readOnly }
                file={file} 
                setFile={(file)=>{
                  setFile(file);
                  updateFileInObj(rowObj.id, file);
                }}
                actionApi={postAddFileRadicadoOLF}
                actionApiInfo={{ impactId, id: rowObj.id }}
              />
            }
          </div>  
        </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color="secondary">
              <IntlMessages id="core.close" />
            </Button>
          </DialogActions>
        </Dialog>  
    );
  };

  export default FormCRU;