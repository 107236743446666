import React, { useState } from "react";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import ImpactList from "./impactList";

const PatientInfo = (props) => {
  const [open, setOpen] = useState(false);
  const handleRequestClose = () => setOpen(false);

  return (
    <>
      <InfoCard
        data={{
          icon: "book",
          title: IntlGetText("kpi.impacts"),
          subTitle: IntlGetText("core.patient"),
          color: "#757575",
        }}
        onClickBox={()=>setOpen(true)}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="kpi.impacts" />
        </DialogTitle>
        <DialogContent>
          <ImpactList {...props}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PatientInfo;
