export const data1 = [
  { name: "Enero", uv: 685 },
  { name: "Febrero", uv: 743 },
  { name: "Marzo", uv: 701 },
  { name: "Abril", uv: 1327 },
  { name: "Mayo", uv: 1167 },
];

export const data2 = [
  { name: "Enero", uv: 3.1 },
  { name: "Febrero", uv: 3.4 },
  { name: "Marzo", uv: 3.2 },
  { name: "Abril", uv: 6 },
  { name: "Mayo", uv: 5.3 },
  { name: "Promedio General", uv: 4.2 },
];

export const data3 = [
  { name: "Enero", b1: 45, b2: 50, l1: 72, l2: 202, l3: 316 },
  { name: "Febrero", b1: 92, b2: 104, l1: 63, l2: 237, l3: 247 },
  { name: "Marzo", b1: 56, b2: 75, l1: 60, l2: 192, l3: 318 },
  { name: "Abril", b1: 13, b2: 105, l1: 270, l2: 299, l3: 640 },
  { name: "Mayo", b1: 25, b2: 120, l1: 184, l2: 295, l3: 543 },
];
