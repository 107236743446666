import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormDatePicker from "components/Form/Controls/datepicker";
import TableComments from "./TableComments";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  callApi,
  callApiAsync,
  getCommentsByAlert,
  postAddCommentToAlert,
} from "api/apiList";
import { AlertStateList, AlertTypeList } from "constants/Enums";

const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const FormCRU = ({ rowObj, openCRU, handleRequestClose }) => {
  const alertStateList = AlertStateList();
  const alertTypeList = AlertTypeList();

  const [alertCommentList, setAlertCommentList] = useState([]);
  const [currentComment, setCurrentComment] = useState(null);

  const defaultValues = {
    userName: "",
    creationDate: null,
    alertType: "",
    comment: null,
    newComment: "",
    state: "",
  };

  const validationSchema = {
    state: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    newComment: yup.string().required(<IntlMessages id="core.fieldRequired" />),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, reset, errors } = methods;

  const setEditComment = (objComment) => {
    setCurrentComment(objComment);
    setValue("newComment", !objComment ? "" : objComment.comment);
  };

  const loadComments = (alertId) => {
    if (!alertId) return;
    callApi(() => getCommentsByAlert(alertId), setAlertCommentList);
  };

  const loadData = () => {
    setValue("userName", !rowObj ? "" : rowObj.userName);
    setValue("creationDate", !rowObj ? null : dayjs(rowObj.creationDate));
    setValue(
      "alertType",
      !rowObj ? "" : getCboValue(alertTypeList, rowObj.alertType)
    );
    setValue("comment", !rowObj ? "" : rowObj.comment);
    setValue("state", !rowObj ? "" : getCboValue(alertStateList, rowObj.state));
  };

  useEffect(() => {
    if (openCRU) {
      setTimeout(loadData, 150);
    }
  }, [openCRU]);

  useEffect(() => loadComments(rowObj.id), [rowObj]);


  const onSubmit = async (d, e) => {
    var obj = {
      id: currentComment ? currentComment.id : 0,
      alertId: rowObj.id,
      comment: d.newComment,
      alertState: Number(d.state.value),
    };
    await callApiAsync(
      () => postAddCommentToAlert(obj),
      () => {
        loadComments(rowObj.id);
        reset({ ...d, newComment: "" });
        setCurrentComment(null);
      }
    );
  };

  const disabled = false;

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id="alert.DlgDetailTitle" />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    label={<IntlMessages id="alert.CommentAlert" />}
                    name="comment"
                    disabled={true}
                    multiline
                    rows={2}
                    variant="outlined"
                    margin="normal"
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormSelectAutoComplete
                    name="alertType"
                    label={<IntlMessages id="alert.alertType" />}
                    options={alertTypeList}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormInput
                    name="userName"
                    label={<IntlMessages id="alert.userName" />}
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormDatePicker
                    name="creationDate"
                    label={<IntlMessages id="core.creationDate" />}
                    format="YYYY-MM-DD"
                    margin="normal"
                    disabled={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormSelectAutoComplete
                    name="state"
                    label={<IntlMessages id="alert.state" />}
                    options={alertStateList}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    label={<IntlMessages id="core.comment" />}
                    name="newComment"
                    required={true}
                    multiline
                    rows={5}
                    variant="outlined"
                    margin="normal"
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4 mb-4">
            <ButtonAsync
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
            >
              {<IntlMessages id="core.save" />}
            </ButtonAsync>
          </div>
          <TableComments
            data={alertCommentList}
            setCurrentRowObj={setEditComment}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormCRU;
