export default [
  {
    id: 0,
    handle: "first-impact",
    title: "Nuevo",
    color: "badge-first-impact",
    type: "new-patient",
  },
  {
    id: 1,
    handle: "inhospital-visit",
    title: "Verificado",
    color: "green",
    type: "new-patient",
  },
  {
    id: 2,
    handle: "home-visit",
    title: "Reporte Incompleto",
    color: "orange",
    type: "new-patient",
  },
  {
    id: 3,
    handle: "follow-up",
    title: "Reportado ER",
    color: "green",
    type: "state",
  },
  {
    id: 4,
    handle: "accompaniment",
    title: "ER no efectivo",
    color: "orange",
    type: "state",
  },
  {
    id: 5,
    handle: "remodulin",
    title: "REMODULIN",
    color: "badge-remodulin",
    type: "product-line",
  },
  {
    id: 6,
    handle: "iclusig",
    title: "ICLUSIG",
    color: "badge-iclusig",
    type: "product-line",
  },
  {
    id: 7,
    handle: "doctorok",
    title: "PARTNER",
    color: "red",
    type: "state",
  },
  {
    id: 8,
    handle: "pacienteok",
    title: "Partner no efectivo",
    color: "red",
    type: "state",
  },
  {
    id: 9,
    handle: "query",
    title: "Query",
    color: "amber",
    type: "state",
  },
  {
    id: 10,
    handle: "suspended",
    title: "SUSPENSIÓN",
    color: "orange",
    type: "suspended",
  },
  {
    id: 11,
    handle: "dead",
    title: "FALLECIDO",
    color: "red",
    type: "suspended",
  },
];
