import React, { PureComponent } from "react";
import {
  Bar,
  BarChart,
  Area,
  AreaChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  CartesianGrid,
  LabelList,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
} from "recharts";

import {
  dataProgramAdherence,
  dataPharmaceuticalAdherence,
  dataActivePatients,
  data,
} from "./data";

import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import CardBox from "components/CardBox";

const COLORS = ["#00C49F", "#0088FE", "#3367d6", "#FFBB28", "#FF8042"];

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text
        x={x}
        y={y}
        dy={-10}
        fill={stroke}
        fontSize={10}
        textAnchor="middle"
      >
        {value + "%"}
      </text>
    );
  }
}

class CustomizedLabel2 extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text
        x={x}
        y={y}
        dy={-10}
        fill={stroke}
        fontSize={10}
        textAnchor="middle"
      >
        {value}
      </text>
    );
  }
}

const remodulin = (props) => {
  return (
    <div className="app-wrapper">
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <ContainerHeader
          match={props.match}
          title={<IntlMessages id="kpi.iclusig" />}
        />
        <div className="row">
          <CardBox styleName="col-md-4 text-center">
            <ResponsiveContainer width="100%" height={240}>
              <img
                className=""
                src={require("assets/images/psp/kpi/iclusig-img1.PNG")}
                alt="Iclusig"
                title="Iclusig"
              />
            </ResponsiveContainer>
          </CardBox>

          <CardBox
            heading={
              <IntlMessages id="kpi.remodulin.pharmaceuticalAdherence" />
            }
            styleName="col-md-8 text-center"
          >
            <ResponsiveContainer width="100%" height={200}>
              <LineChart
                data={dataProgramAdherence}
                margin={{ top: 20, right: 0, left: -25, bottom: 0 }}
              >
                <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="uv"
                  stroke="#3367d6"
                  fill="#3367d6"
                  name="Pacientes Activos"
                  label={<CustomizedLabel />}
                />
              </LineChart>
            </ResponsiveContainer>
          </CardBox>
        </div>
        <div className="row">
          <CardBox
            heading={
              <IntlMessages id="kpi.remodulin.chartActivePatients.title" />
            }
            styleName="col-md-3 text-center"
          >
            <ResponsiveContainer width="100%" height={250}>
              <PieChart>
                <Legend />
                <Pie
                  dataKey="value"
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={35}
                  outerRadius={55}
                  fill="#3367d6"
                  paddingAngle={1}
                  label
                >
                  {data.map((entry, index) => (
                    <Cell key={index} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </CardBox>
          <CardBox
            heading={<IntlMessages id="kpi.remodulin.programGrowth" />}
            styleName="col-md-6 text-center"
          >
            <ResponsiveContainer width="100%" height={250}>
              <AreaChart
                data={dataPharmaceuticalAdherence}
                margin={{ top: 10, right: 0, left: -25, bottom: 0 }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="2 2" />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="#3367d6"
                  fill="#3367d6"
                  name={<IntlMessages id="psp.activePatients" />}
                  label={<CustomizedLabel2 />}
                />
              </AreaChart>
            </ResponsiveContainer>
          </CardBox>
          <CardBox
            heading={<IntlMessages id="kpi.iclusig.ActivePatientsDose.title" />}
            styleName="col-md-3 text-center"
          >
            <ResponsiveContainer width="100%" height={250}>
              <BarChart
                data={dataActivePatients}
                margin={{ top: 10, right: 0, left: -25, bottom: 0 }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="uv"
                  fill="#3367d6"
                  name={<IntlMessages id="psp.activePatients" />}
                >
                  <LabelList dataKey="uv" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </CardBox>
        </div>
      </div>
    </div>
  );
};

export default remodulin;
