import React from "react";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button, Tooltip } from "@material-ui/core";
import Table, { getTextLabels, defaultCol } from "components/Form/Table";

const NurseTable = ({
  data,
  handleOpenDel,
  setCurrentRowObj}) =>{

  const getObj = (tableMeta) =>
    data.find((x) => x.id === tableMeta.rowData[0]);

  const handleClickDelete = (tableMeta) => {
    handleOpenDel();
    setCurrentRowObj(getObj(tableMeta));
  };

  const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          searchable: false,
          display: "excluded",
        },
      },
      defaultCol("name", "core.name"),
      defaultCol("lastName", "core.lastName"),
      defaultCol("email", "core.email"),
      //defaultCol("city", "core.city"),
      {
        name: "actions",
        label: IntlGetText("core.actions"),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {  
            return (
              <div>
                <Tooltip title={<IntlMessages id="core.delete" />} placement="bottom-end">
                  <Button
                    onClick={() => handleClickDelete(tableMeta)}
                    variant="contained"
                    className="jr-btn jr-btn-xs"
                  >
                    <i className="zmdi zmdi-delete"></i>
                  </Button>
                </Tooltip>
              </div>
            );
          },
        },
      },
    ];

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: getTextLabels(),
    pagination:false
  };

  return (
    <Table
      className="border-dialog-card mt-2 mb-2"
      data={data}
      columns={columns}
      options={options}
    />
  );
};

export default NurseTable;

